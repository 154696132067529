import React ,{ useState ,useEffect} from 'react';
import { Offline, Online } from 'react-detect-offline';
import "../../../../assets/css/masterdata.css";
import { useNavigate } from 'react-router-dom';
import {resetValidation, setInputFieldData} from "../../../../redux/slice/MSAMBMaster/MSAMBMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import { initializeConnect } from 'react-redux/es/components/connect';


export const MSAMBContactInformation = (props) =>{ 
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const msambMaster = useSelector(state => state.msambMaster);
    const [render, setRender] = useState(0);

    const PriceIncr = [
        {
            'id': 1,
            'Status_mode': 'Yes'
        },
        {
            'id': 2,
            'Status_mode': 'No'
        }
      ]
      const ContractExten = [
        {
            'id': 1,
            'Status_mode': 'Yes'
        },
        {
            'id': 2,
            'Status_mode': 'No'
        }
      ]
    const handleFieldContractNum = (event) => {
        dispatch(setInputFieldData({ fieldName: 'contract_no', value:event.target.value }));
    }
    const handleFieldCompTender = (event) => {
        dispatch(setInputFieldData({ fieldName: 'component', value:event.target.value }));
    }
    const handleFieldsupplier_contractor= (event) => {
        dispatch(setInputFieldData({ fieldName: 'supplier_contractor_name', value:event.target.value }));
    }
    const handleFieldcontract_amount= (event) => {
        dispatch(setInputFieldData({ fieldName: 'contract_amount', value:event.target.value }));
    }
    const handleFieldcontract_award_date= (event) => {
        dispatch(setInputFieldData({ fieldName: 'contract_award_date', value:event.target.value }));
    }
    const handleFieldcompletion_date= (event) => {
        dispatch(setInputFieldData({ fieldName: 'completion_date', value:event.target.value }));
    }
    const handleFieldextension_date= (event) => {
        dispatch(setInputFieldData({ fieldName: 'extension_date', value:event.target.value }));
    }

    const handleFieldincreased_amount= (event) => {
        dispatch(setInputFieldData({ fieldName: 'increased_amount', value:event.target.value }));
    }
    const handleFielddisbursed_amount= (event) => {
        dispatch(setInputFieldData({ fieldName: 'total_disbursed_amount', value:event.target.value }));
    }
    // const handleFieldcontract_extension= (event) => {
    //     dispatch(setInputFieldData({ fieldName: 'contract_extension', value:event.target.value }));
    // }
    // const handleFieldprice_increase= (event) => {
    //     dispatch(setInputFieldData({ fieldName: 'price_increase', value:event.target.value }));
    // }
    const handleFieldcontract_extension = (event) => {
        const selectedOption = ContractExten.find(option => option.Status_mode === event.target.value);
        dispatch(
          setInputFieldData({
            fieldName: 'contract_extension',
            value: selectedOption ? selectedOption.Status_mode : "",
          })
        );
        setRender(prevRender => prevRender + 1); 
    }
    
      
      const handleFieldprice_increase = (event) => {
        const selectedOption = PriceIncr.find(option => option.Status_mode === event.target.value);
        dispatch(
          setInputFieldData({
            fieldName: 'price_increase',
            value: selectedOption ? selectedOption.Status_mode : "",
          })
        );
        setRender(prevRender => prevRender + 1); 

      }

      
   const handleResetFocus = () =>{
    dispatch(resetValidation())
}
useEffect(() => {
    if (props.initialValues) {
        const initialValues = props.initialValues;

        const selectedContractExtension = ContractExten.find(option => option.Status_mode === initialValues.contract_extension);
        if (selectedContractExtension) {
            dispatch(setInputFieldData({ fieldName: 'contract_extension', value: selectedContractExtension.Status_mode }));
        }

        const selectedPriceIncrease = PriceIncr.find(option => option.Status_mode === initialValues.price_increase);
        if (selectedPriceIncrease) {
            dispatch(setInputFieldData({ fieldName: 'price_increase', value: selectedPriceIncrease.Status_mode }));
        }
    }
}, [dispatch, props.initialValues]);


  useEffect(() => {
  
    console.log('Contrat extension', msambMaster.contract_extension);
    console.log('price incresed', msambMaster.price_increase);
   
  }, [msambMaster.contract_extension, msambMaster.price_increase]);

    return(<>   
    {/* <Online> */}
       <div >
            <form class="row g-3" >
                {/* Contract Number */}
                <div className='contactinfo'>
                    <label htmlFor="ContractNumber">Contract Number</label>
                    <input type="text" name="ContractNumber" 
                        value={msambMaster.contract_no}
                        onChange={handleFieldContractNum} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.contract_no}
                        helperText={msambMaster?.validationErrors.contract_no}
                        onKeyDown={(e) => {
                            const regex = /^[a-zA-Z0-9]*$/;
                            if (!regex.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        className="custom" 
                        style={{ width: "30%" }}  
                    />
                </div>

                {/* Components as per accepted Tender */}
                <div className='contactinfo' style={{display: "flex"}}>
                    <label htmlFor="ContractNumber" style={{marginTop:'22px'}}>Components as per accepted Tender</label>
                    <textarea name="ContractNumber" 
                        value={msambMaster.component}
                        onChange={handleFieldCompTender} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.component}
                        helperText={msambMaster?.validationErrors.component}
                        className="customInput" 
                        // style={{width:"50%",height:'60px', resize: 'none'}} 
                    />
                </div>
                {/* Supplier / Contractor Name */}
                <div className='contactinfo'>
                    <label htmlFor="SupplierContractor">Supplier / Contractor Name</label>
                    <input type="text" name="SupplierContractor" 
                        value={msambMaster.supplier_contractor_name}
                        onChange={handleFieldsupplier_contractor} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.supplier_contractor_name}
                        helperText={msambMaster?.validationErrors.supplier_contractor_name}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                {/* Contract Amount */}
                <div className='contactinfo'>
                    <label htmlFor="ContractAmount">Contract Amount</label>
                    <input type="number" name="ContractAmount" 
                        value={msambMaster.contract_amount}
                        onChange={handleFieldcontract_amount} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.contract_amount}
                        helperText={msambMaster?.validationErrors.contract_amount}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                {/* Contract Award Date */}
                <div className='contactinfo'>
                    <label htmlFor="ContractAwardDate">Contract Award Date</label>
                    <input type="date" name="ContractAwardDate" 
                        value={msambMaster.contract_award_date}
                        onChange={handleFieldcontract_award_date} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.contract_award_date}
                        helperText={msambMaster?.validationErrors.contract_award_date}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                {/* Completion Date */}
                <div className='contactinfo'>
                    <label htmlFor="CompilationDate">Completion Date</label>
                    <input type="date" name="CompilationDate" 
                        value={msambMaster.completion_date}
                        onChange={handleFieldcompletion_date} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.completion_date}
                        helperText={msambMaster?.validationErrors.completion_date}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                {/* Extension Date */}
                <div className='contactinfo'>
                    <label htmlFor="ExtensionDate">Extension Date</label>
                    <input type="date" name="ExtensionDate" 
                        value={msambMaster.extension_date}
                        onChange={handleFieldextension_date} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.extension_date}
                        helperText={msambMaster?.validationErrors.extension_date}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                   
                {/* Contract Extension */}
                <div className='contactinfo'>
                <Box>
                    <label htmlFor="Contract Extension">Contract Extension</label>
                    <FormControl style={{width:'50%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="Contract Extension" 
                        className="customSelect" 
                        style={{width:"40%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={msambMaster.contract_extension}
                        onChange={handleFieldcontract_extension} 
                        onFocus={handleResetFocus}   
                        error={!!msambMaster?.validationErrors.contract_extension}
                        helperText={msambMaster?.validationErrors.contract_extension}                                      
                    >
                    {ContractExten.map((e) => (
                        <MenuItem value={e.Status_mode}>{e.Status_mode}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Box>
                </div>

                {/* Price Increase */}
                <div className='contactinfo'>
                <Box>
                    <label htmlFor="Price Increase">Price Increase</label>
                    <FormControl style={{width:'50%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="Price Increase" 
                        className="customSelect" 
                        style={{width:"40%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={msambMaster.price_increase}
                        onChange={handleFieldprice_increase} 
                        onFocus={handleResetFocus}   
                        error={!!msambMaster?.validationErrors.price_increase}
                        helperText={msambMaster?.validationErrors.price_increase}                                      
                    >
                   {PriceIncr.map((e) => (
                        <MenuItem value={ e.Status_mode}>{e.Status_mode}</MenuItem>
                    ))}

                    </Select>
                    </FormControl>
                </Box>
                </div>

                {/* Increased Amount (added in total Contract Amount) */}
                <div className='contactinfo'>
                    <label htmlFor="IncreasedAmount ">Increased Amount (added in total Contract Amount)</label>
                    <input type="number" name="IncreasedAmount" 
                        value={msambMaster.increased_amount}
                        onChange={handleFieldincreased_amount} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.increased_amount}
                        helperText={msambMaster?.validationErrors.increased_amount}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                {/* Total Disbursed amount on Contract */}
                <div className='contactinfo'>
                    <label htmlFor="DisbursedAmount ">Total Disbursed amount on Contract</label>
                    <input type="number" name="DisbursedAmount" 
                        value={msambMaster.total_disbursed_amount}
                        onChange={handleFielddisbursed_amount} 
                        onFocus={handleResetFocus}
                        error={!!msambMaster?.validationErrors.total_disbursed_amount}
                        helperText={msambMaster?.validationErrors.total_disbursed_amount}
                        className="customInput" 
                        style={{width:"30%"}} 
                    />
                </div>
                
            </form>
        </div>     
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

