/* eslint-disable default-case */
import "../assets/css/inputForm.css";
import { InputField } from "../subcomponents/InputField";
import pdf from "../assets/PDF_file_icon.svg.png";
import imgIcon from "../assets/image_icon-icons.com_50366.png";
import React, { useState } from "react";
import {
  updateApiDataToNursery,
  resetDataNursery,
  setValidationErrorsNursery,
} from "../redux/slice/InputOne/NurseryDevelopment/NurseryDevSlice";
import {
  updateApiDataToTissue,
  resetDataTissue,
  setValidationErrorsTissue,
} from "../redux/slice/InputOne/TissueCulture/TissueCultureSlice";
import {
  updateApiDataToSupportTech,
  resetDataSupportTech,
  setValidationErrorsSupportTech,
} from "../redux/slice/InputOne/SupportTechnology/SupportTechSlice";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect } from "react";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setInputFieldData } from "../redux/slice/InputTwo/MasterSubSlice";
import { NurseryMasterForm } from "../subcomponents/Input1/Input1NurseryMaster/NurseryMasterForm";
import { MultiSelect } from "../subcomponents/MultiSelect";
import { TissueCulture } from "../subcomponents/Input1/Input1TissueCulture/TissueCulture";
import { SupportTechnology } from "../subcomponents/Input1/Input1Support/SupportTechnology";

export const EoiBasedForm = ({ quarter, magentCrp }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("2");
  const [quarters, setQuarter] = useState([]);
  const [selectQuarter, setSelectedQuarters] = useState("");
  const [selectedGAP, setSelectedGAP] = useState("");
  const [component, setComponents] = useState([]);
  const [selectNurseryComponent, setSelectNurseryComponent] = useState("");
  const [selectTissueComponent, setSelectTissueComponent] = useState("");
  const [selectSupportComponent, setSelectSupportComponent] = useState("");
  const [magentCrop, setMagentCrop] = useState([]);
  const [nurseryFlagEdit, setNurseryFlagEdit] = useState(false);
  const [nurseryIdEdit, setNurseryIdEdit] = useState("");
  const [beniFiApplicant, setBeniApplicant] = useState([]);
  const [beniFiApplicants, setBeniApplicants] = useState([]);
  const [isMatchingGrant, setIsMatchingGrant] = useState("");
  const [taluka, setTaluka] = useState([]);
  const [selectbeniFiApplicant, setSelectBeniApplicant] = useState("");
  const [selectbeniFiApplicants, setSelectBeniApplicants] = useState("");
  const [errorSelectBeniApplicant, setErrorSelectBeniApplicant] = useState("");

  // Files State
  const [editInput1Files, setEditInput1Files] = useState([]);
  const [selectCapacityFile, setSelectCapacityFile] = useState([]);
  const [errorCapacityFile, setErrorCapacityFile] = useState("");

  // setting beneficiary
  const [nurseryTissueBeneficiary, setNurseryTissueBeneficiary] = useState([]);
  const [permission, setPermission] = useState([]);

  const dd = useSelector(setInputFieldData);

  const checkFPOSelect = () => {
    if (
      selectbeniFiApplicant.length === 0 ||
      selectbeniFiApplicants.length === 0
    ) {
      setErrorSelectBeniApplicant("Please Select Applicant");
    } else {
      return true;
    }
    return false;
  };

  const resetAllState = () => {
    setSelectNurseryComponent("");
    setSelectTissueComponent("");
    setSelectBeniApplicant("");
    setErrorSelectBeniApplicant("");
    setSelectedGAP("");
    setNurseryTissueBeneficiary([]);
    setEditInput1Files([]);
    setNurseryFlagEdit(false);
  };

  const handleChange = async (event, newValue) => {
    resetAllState();
    
    try {
      setValue(newValue);
      if (newValue === "2") {
        const component = await axios.get(
          `${apiUrl}api/get-tech-component-by-formid?form_id=4`,
          getConfigWithToken()
        );
        const componentData = component.data["data"].map((e) => ({
          id: e["id"],
          technical_component: e["technical_component"],
        }));
        setComponents(componentData);
        setSelectBeniApplicant("");
      }
      if (newValue === "3") {
        const component = await axios.get(
          `${apiUrl}api/get-tech-component-by-formid?form_id=5`,
          getConfigWithToken()
        );
        const componentData = component.data["data"].map((e) => ({
          id: e["id"],
          technical_component: e["technical_component"],
        }));
        setComponents(componentData);
      }
      if (newValue === "19") {
        const component = await axios.get(
          `${apiUrl}api/get-tech-component-by-formid?form_id=26`,
          getConfigWithToken()
        );
        const componentData = component.data["data"].map((e) => ({
          id: e["id"],
          technical_component: e["technical_component"],
        }));
        setComponents(componentData);
      }
    } catch (err) {}
  };

  const handleMultiFilesGAPChange = (event) => {
    setSelectCapacityFile(event.target.files);
  };

  const handleTechComponentChangeChange = async (event) => {
    try {
      if (checkFPOSelect()) {
        if (value === "2") {
          setSelectNurseryComponent(event.target.value);
          const getComponentData = await axios.get(
            `${apiUrl}api/getAllDetailsNurseryDev?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&component_id=${event.target.value}&form_id=4`,
            getConfigWithToken()
          );
          if (getComponentData.data["status"] === true) {
            const data = getComponentData.data["data"][0];

            setIsMatchingGrant(data.is_matching_grant);
            setEditInput1Files(getComponentData.data["remarks"]);
            data["payment_tranche"] = data["tranche"];
            getComponentData.data["flag"]
              ? setNurseryFlagEdit(true)
              : setNurseryFlagEdit(false);
            setNurseryIdEdit(data["id"]);
            dispatch(updateApiDataToNursery(data));
          } else {
            setNurseryFlagEdit(false);
            setEditInput1Files([]);
            dispatch(resetDataNursery());
            const getDataFromBeneficiary = await axios.get(
              `${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=4`,
              getConfigWithToken()
            );
            const data = getDataFromBeneficiary.data["data"];
            setIsMatchingGrant(data.is_matching_grant);

            data["payment_tranche"] = data["tranch"]["payment_tranche"];
            data["tranche_id"] = data["tranch"]["tranche_id"];

            dispatch(updateApiDataToNursery(data));
          }
        }
        if (value === "3") {
          setSelectTissueComponent(event.target.value);
          const getComponentData = await axios.get(
            `${apiUrl}api/getAllDetailsTissueCulture?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&component_id=${event.target.value}&form_id=5`,
            getConfigWithToken()
          );
          if (getComponentData.data["status"] === true) {
            const data = getComponentData.data["data"][0];
            console.log(data, "dataData");
            setIsMatchingGrant(data.is_matching_grant);
            setEditInput1Files(getComponentData.data["remarks"]);
            data["payment_tranche"] = data["tranche"];
            getComponentData.data["flag"]
              ? setNurseryFlagEdit(true)
              : setNurseryFlagEdit(false);
            setNurseryIdEdit(data["id"]);
            dispatch(updateApiDataToTissue(data));
          } else {
            setNurseryFlagEdit(false);
            setEditInput1Files([]);
            dispatch(resetDataTissue());
            const getDataFromBeneficiary = await axios.get(
              `${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=5`,
              getConfigWithToken()
            );
            const data = getDataFromBeneficiary.data["data"];
            setIsMatchingGrant(data.is_matching_grant);
            console.log(data, "DDData");
            data["payment_tranche"] = data["tranch"]["payment_tranche"];
            data["tranche_id"] = data["tranch"]["tranche_id"];
            dispatch(updateApiDataToTissue(data));
          }
        }
        if (value === "19") {
          setSelectSupportComponent(event.target.value);
          const getComponentData = await axios.get(
            `${apiUrl}api/support-new-technology-edit?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicants}&new_technology_id=${event.target.value}&form_id=26&`,
            getConfigWithToken()
          );
          if (getComponentData.data["status"] === true) {
            const data = getComponentData.data["data"][0];

            setIsMatchingGrant(data.is_matching_grant);
            setEditInput1Files(data["remarks"]);
            data["payment_tranche"] = data["tranche"];
            getComponentData.data["flag"]
              ? setNurseryFlagEdit(true)
              : setNurseryFlagEdit(false);
            setNurseryIdEdit(data["id"]);
            dispatch(updateApiDataToSupportTech(data));
          } else {
            setNurseryFlagEdit(false);
            setEditInput1Files([]);
            dispatch(resetDataSupportTech());
            const getDataFromBeneficiary = await axios.get(
              `${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=26`,
              getConfigWithToken()
            );
            const data = getDataFromBeneficiary.data["data"];
            console.log(data, "datta supp");
            data["total_technology_cost"] = data["total_project_cost"];
            data["payment_tranche"] = data["tranch"]["payment_tranche"];
            data["tranche_id"] = data["tranch"]["tranche_id"];
            console.log(data, "datttaaaa supppp");
            setIsMatchingGrant(data.is_matching_grant);
            dispatch(updateApiDataToSupportTech(data));
          }
        }
      }
    } catch (err) {}
  };

  const handleDeleteFile = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/delete-file-input-one?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
      setEditInput1Files(newRemarks);
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };
  const handleDeleteSupNewTech = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/delete-file-input-one-others?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
      setEditInput1Files(newRemarks);
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };
  
  // Change the API of Applicant with the Form Id and Beneficiary Id
  const handleApplicantDataByFormId = async (applicantId, form_id) => {
    try {
      const beneficiary_by_id = await axios.get(
        `${apiUrl}api/get_primarydata_by_benificiary?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,
        getConfigWithToken()
      );
      const data = await beneficiary_by_id.data["data"];
      data["magnet_crop_id"]
        ? (data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
            (e) => e.id
          ))
        : (data["magnet_crop_id"] = []);
      setNurseryTissueBeneficiary(data);
      return await data;
    } catch (err) {}
  };

  const handleApplicantDataWithoutComponent = async (applicantId, form_id) => {
    try {
      const beneficiary_by_id = await axios.get(
        `${apiUrl}api/get_primary_data_for_non_cat_compo_forms?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,
        getConfigWithToken()
      );
      const data = await beneficiary_by_id.data["data"];
      data["magnet_crop_id"]
        ? (data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
            (e) => e.id
          ))
        : (data["magnet_crop_id"] = []);
      setNurseryTissueBeneficiary(data);
      return await data;
    } catch (err) {}
  };



  const handleApplicantChangeESIFPO = async (event) => {
    setErrorSelectBeniApplicant("");
    const applicantId = event.target.value;

    setSelectBeniApplicant(applicantId);
    setSelectBeniApplicants(applicantId);
    setErrorSelectBeniApplicant("");
    setSelectedGAP("");
    setSelectSupportComponent("");
    try {
     
      switch (value) {
        case "1":
          handleApplicantDataByFormId(applicantId, 3);
          break;
        case "2":
          setSelectNurseryComponent("");
          handleApplicantDataByFormId(applicantId, 4);
          break;
        case "3":
          setSelectTissueComponent("");
          handleApplicantDataByFormId(applicantId, 5);
          break;
        case "5":
          handleApplicantDataByFormId(applicantId, 6);
          break;
        case "6":
          handleApplicantDataByFormId(applicantId, 2);
          break;
        case "8":
          handleApplicantDataByFormId(applicantId, 17);
          break;
        case "12":
          handleApplicantDataByFormId(applicantId, 23);
          break;
        case "13":
          handleApplicantDataByFormId(applicantId, 15);
          break;
        case "18":
          handleApplicantDataByFormId(applicantId, 16);
          break;
        case "19":
          handleApplicantDataByFormId(applicantId, 26);
          break;
      }

    } catch (error) {
      Swal.fire(
        "An error occurred while Selecting Benificary Applicants",
        "Please try again later",
        "error"
      );
    }
  };

  const refershdata = () => {
    setSelectBeniApplicant("");
    setNurseryTissueBeneficiary([]);
    setSelectNurseryComponent("");
    setSelectTissueComponent("");
    setSelectSupportComponent("");
    dispatch(resetDataNursery({}));
    setSelectBeniApplicants("");
    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const handleQuarterChange = async (event) => {
    refershdata();
    setNurseryFlagEdit("")
    setSelectedQuarters(event.target.value);
  };

  const handleDeleteNurDev = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/nursery-development-delete?id=${nurseryIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          dispatch(resetDataNursery());
          setNurseryFlagEdit("");
          setLoading(false);
        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };

  const handleDeleteTissCulture = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/tissue-culture-delete?id=${nurseryIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          setNurseryFlagEdit("");
          dispatch(resetDataTissue());
          setLoading(false);

        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };

  const handleDeleteNewTech = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/support-new-technology-delete?id=${nurseryIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          dispatch(resetDataTissue());
          setNurseryFlagEdit("");
          setLoading(false);

        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
};

  const handleNurserySubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["nurseryDev"];
      const errorMessages = {
      
      };

      
      for (const field in errorMessages) {
        const value = formData[field];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsNursery(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (checkFPOSelect() && validateForm()) {
      try {
        setLoading(true);
        const NurseryDev = dd["payload"]["nurseryDev"];

        const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"]
          .map((id) => {
            const matchingObject = magentCrop.find((item) => item.id === id);
            if (matchingObject) {
              return {
                id: matchingObject.id,
                name: matchingObject.crop,
              };
            }
            return null; // Handle cases where there's no matching ID
          })
          .filter((item) => item !== null); // Remove any null entries

        const resultMagnetCrop = { data: jsonMagnetCrop };

        const formData = new FormData();
        for (const key in NurseryDev) {
          formData.append(key, NurseryDev[key] !== null ? NurseryDev[key] : "");
        }

        formData.append("quarter", selectQuarter);
        formData.append("beneficiary_id", selectbeniFiApplicant);
        formData.append(
          "district_id",
          nurseryTissueBeneficiary.district.district_id
        );
        formData.append(
          "division_id",
          nurseryTissueBeneficiary.divisions.division_id
        );
        formData.append("taluka_id", nurseryTissueBeneficiary.taluka.taluka_id);
        formData.append(
          "beneficiary_type_id",
          nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id
        );
        formData.append("component_id", selectNurseryComponent);
        formData.append(
          "magnet_crop_id",
          JSON.stringify(resultMagnetCrop["data"])
        );
        formData.append(
          "capacity_total_plants",
          nurseryTissueBeneficiary.capacity_total_plants
            ? nurseryTissueBeneficiary.capacity_total_plants
            : ""
        );
        formData.append(
          "area",
          nurseryTissueBeneficiary.area ? nurseryTissueBeneficiary.area : ""
        );
        formData.append(
          "location_taluka",
          nurseryTissueBeneficiary.location["location_taluka_id"]
            ? nurseryTissueBeneficiary.location["location_taluka_id"]
            : ""
        );
        formData.append("form_id", "4");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!nurseryFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitNurseryDevData = await axios.post(
              `${apiUrl}api/nursery-development-create`,
              formData,
              getConfigWithToken()
            );
            if (submitNurseryDevData.data["status"] === true) {
              Swal.fire(
                `${submitNurseryDevData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataNursery());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitNurseryDevData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", nurseryIdEdit);
            const submitNurseryData = await axios.post(
              `${apiUrl}api/nursery-development-update`,
              formData,
              getConfigWithToken()
            );

            if (submitNurseryData.data["status"] === true) {
              Swal.fire(
                `${submitNurseryData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataNursery());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitNurseryData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleTissueSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["tissueCulture"];
      const errorMessages = {
        
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsTissue(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (checkFPOSelect() && validateForm()) {
      try {
        setLoading(true);
        const TissueCulture = dd["payload"]["tissueCulture"];

        const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"]
          .map((id) => {
            const matchingObject = magentCrop.find((item) => item.id === id);
            if (matchingObject) {
              return {
                id: matchingObject.id,
                name: matchingObject.crop,
              };
            }
            return null; // Handle cases where there's no matching ID
          })
          .filter((item) => item !== null); // Remove any null entries

        const resultMagnetCrop = { data: jsonMagnetCrop };

        const formData = new FormData();
        for (const key in TissueCulture) {
          formData.append(
            key,
            TissueCulture[key] !== null ? TissueCulture[key] : ""
          );
        }

        formData.append("quarter", selectQuarter);
        formData.append("beneficiary_id", selectbeniFiApplicant);
        formData.append(
          "district_id",
          nurseryTissueBeneficiary.district.district_id
        );
        formData.append(
          "division_id",
          nurseryTissueBeneficiary.divisions.division_id
        );
        formData.append("taluka_id", nurseryTissueBeneficiary.taluka.taluka_id);
        formData.append(
          "beneficiary_type_id",
          nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id
        );
        formData.append("component_id", selectTissueComponent);
        formData.append(
          "magnet_crop_id",
          JSON.stringify(resultMagnetCrop["data"])
        );
        formData.append(
          "capacity_total_plants",
          nurseryTissueBeneficiary.capacity_total_plants
            ? nurseryTissueBeneficiary.capacity_total_plants
            : ""
        );
        formData.append(
          "area",
          nurseryTissueBeneficiary.area ? nurseryTissueBeneficiary.area : ""
        );
        formData.append(
          "location_taluka",
          nurseryTissueBeneficiary.location["location_taluka_id"]
            ? nurseryTissueBeneficiary.location["location_taluka_id"]
            : ""
        );
        formData.append("form_id", "5");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!nurseryFlagEdit) {
            // if(true){
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitNurseryDevData = await axios.post(
              `${apiUrl}api/tissue-culture-create`,
              formData,
              getConfigWithToken()
            );
            if (submitNurseryDevData.data["status"] === true) {
              Swal.fire(
                `${submitNurseryDevData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTissue());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitNurseryDevData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", nurseryIdEdit);
            const submitNurseryData = await axios.post(
              `${apiUrl}api/tissue-culture-update`,
              formData,
              getConfigWithToken()
            );

            if (submitNurseryData.data["status"] === true) {
              Swal.fire(
                `${submitNurseryData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTissue());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitNurseryData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleSupportTechSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["supportTech"];
      const errorMessages = {
        
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsSupportTech(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (checkFPOSelect() && validateForm()) {
      try {
        setLoading(true);
        const supportTech = dd["payload"]["supportTech"];
        console.log(supportTech, "sup tech");
        const formData = new FormData();
        for (const key in supportTech) {
          formData.append(
            key,
            supportTech[key] !== null ? supportTech[key] : ""
          );
        }

        formData.append("quarter", selectQuarter);
        formData.append("beneficiary_id", selectbeniFiApplicants);
        formData.append(
          "district_id",
          nurseryTissueBeneficiary.district.district_id
        );
        formData.append(
          "division_id",
          nurseryTissueBeneficiary.divisions.division_id
        );
        formData.append("taluka_id", nurseryTissueBeneficiary.taluka.taluka_id);
        formData.append(
          "beneficiary_type_id",
          nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id
        );
        formData.append("new_technology_id", selectSupportComponent);
        formData.append("form_id", "26");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!nurseryFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              return;
            }
            const submitSupportTechData = await axios.post(
              `${apiUrl}api/support-new-technology-create`,
              formData,
              getConfigWithToken()
            );
            if (submitSupportTechData.data["status"] === true) {
              Swal.fire(
                `${submitSupportTechData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataSupportTech());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitSupportTechData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", nurseryIdEdit);
            const submitSupportTechData = await axios.post(
              `${apiUrl}api/support-new-technology-update`,
              formData,
              getConfigWithToken()
            );

            if (submitSupportTechData.data["status"] === true) {
              Swal.fire(
                `${submitSupportTechData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataSupportTech());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitSupportTechData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  useEffect(() => {
    const quarterData = async () => {
      try {
        const storedArrayAsString = localStorage.getItem("permission");
        setPermission(storedArrayAsString);

        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        const component = await axios.get(
          `${apiUrl}api/get-tech-component-by-formid?form_id=4`,
          getConfigWithToken()
        );
        const componentData = component.data["data"].map((e) => ({
          id: e["id"],
          technical_component: e["technical_component"],
        }));
        setComponents(componentData);
        setQuarter(quarter);
        const selectedValues = quarter
          .filter((item) => item.selected === "Selected")
          .map((item) => item.id);
        setSelectedQuarters(selectedValues[0]);
        setMagentCrop(magentCrp);
        setTaluka(taluka.data["data"]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const applicantData = async () => {
      try {
        const applicant = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1&status=1`,
          getConfigWithToken()
        );
        const applicantData = applicant.data["data"].map((e) => ({
          id: e["id"],
          beneficiary_name: e["beneficiary_name"],
        }));
        setBeniApplicant(applicantData); // Pass applicantData to setApplicant

        const applicants = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1`,
          getConfigWithToken()
        );
        const applicantDatas = applicants.data["data"].map((e) => ({
          id: e["id"],
          beneficiary_name: e["beneficiary_name"],
        }));
        setBeniApplicants(applicantDatas); // Pass applicantDatas to setApplicant

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    applicantData();
    quarterData();

    setLoading(false);
  }, [quarter, magentCrp]);

  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div id="exTab3" class="contain">
              <div class="card">
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    bgcolor: "background.paper",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        value={value}
                        onChange={handleChange}
                        sx={{ width: "100%", overflowX: "auto" }}
                      >
                        <Tab
                          label="Nursery Development"
                          value="2"
                          className={`${value === "2" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Tissue Culture"
                          value="3"
                          className={`${value === "3" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Support New Technology"
                          value="19"
                          className={`${
                            value === "19" ? "activeClickCSS" : ""
                          }`}
                        />
                      </Tabs>
                    </Box>

                    {/* Nursery Development */}
                    <TabPanel value="2">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleNurserySubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectbeniFiApplicant}
                                  label="FPO/VCO"
                                  onChange={handleApplicantChangeESIFPO}
                                >
                                  {beniFiApplicant.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["beneficiary_name"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Division"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.divisions
                                ? nurseryTissueBeneficiary.divisions[
                                    "divisions"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="District"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.district
                                ? nurseryTissueBeneficiary.district[
                                    "district"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Taluka"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.taluka
                                ? nurseryTissueBeneficiary.taluka[
                                    "taluka"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Beneficiary Type"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.beneficiary_type
                                ? nurseryTissueBeneficiary.beneficiary_type[
                                    "beneficiary_type"
                                  ].toString()
                                : ""
                            }
                          />
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <MultiSelect
                              label="MAGNET Crops"
                              data={magentCrop}
                              value={
                                nurseryTissueBeneficiary &&
                                nurseryTissueBeneficiary.magnet_crop_id
                                  ? nurseryTissueBeneficiary.magnet_crop_id
                                  : []
                              }
                              readOnly={true}
                            />
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="demo-simple-select-label">
                                  Location (Taluka)
                                </InputLabel>
                                <Select
                                  readOnly={true}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={
                                    nurseryTissueBeneficiary &&
                                    nurseryTissueBeneficiary.location
                                      ? nurseryTissueBeneficiary.location[
                                          "location_taluka_id"
                                        ]
                                      : ""
                                  }
                                  label="Location (Taluka)"
                                >
                                  {taluka.map((e, key) => (
                                    <MenuItem value={e.id} key={key}>
                                      {e.taluka}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Area of Nursery in (Ha)"
                            type="text"
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.area
                                ? nurseryTissueBeneficiary.area
                                : ""
                            }
                            readOnly={true}
                          />
                          <InputField
                            label="Capacity (Total Plants)"
                            type="text"
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.capacity_total_plants
                                ? nurseryTissueBeneficiary.capacity_total_plants
                                : ""
                            }
                            readOnly={true}
                          />
                          <br />
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Sanctioned cost break-up{" "}
                              {selectNurseryComponent &&
                                `for ${
                                  component.find(
                                    (e) => e["id"] === selectNurseryComponent
                                  ).technical_component
                                }`}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Component
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectNurseryComponent}
                                    onChange={handleTechComponentChangeChange}
                                    label="Comp"
                                  >
                                    {component.map((e) => (
                                      <MenuItem key={e.id} value={e.id}>
                                        {e["technical_component"]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />

                          {selectNurseryComponent && (
                            <div>
                              <NurseryMasterForm
                                compName={
                                  selectNurseryComponent &&
                                  component.find(
                                    (e) => e["id"] === selectNurseryComponent
                                  ).technical_component
                                }
                                tranche={
                                  nurseryTissueBeneficiary &&
                                  nurseryTissueBeneficiary.payment_tranche
                                    ? nurseryTissueBeneficiary.payment_tranche
                                    : ""
                                }
                                matchingGrant={isMatchingGrant}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  class="col-md-6"
                                  style={{
                                    position: "relative",
                                    right: "15px",
                                    bottom: "10px",
                                  }}
                                >
                                  <label
                                    for="inputNumber"
                                    class="col-lg col-form-label"
                                  >
                                    Upload supporting document. (e.g QPR, Photos
                                    etc.)
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="formFile"
                                      name="remarks"
                                      multiple
                                      onChange={handleMultiFilesGAPChange}
                                      onFocus={() => {
                                        setErrorCapacityFile("");
                                      }}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errorCapacityFile}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  {(editInput1Files || []).map((e) => (
                                    <div className="icon-container">
                                      <a
                                        href={`${fileUrl}${e.file_name}`}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <img
                                          src={e.type === "pdf" ? pdf : imgIcon}
                                          alt=""
                                          height="30px"
                                          width="26px"
                                        />
                                      </a>
                                      <i
                                        class="fa-regular fa-circle-xmark"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteFile(e.id)}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Nursery-Dev-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn"  disabled={loading}>
                                  {nurseryFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {nurseryFlagEdit === true && (
                                  <button type="button" className="btn submitbtn" disabled={loading}  onClick={handleDeleteNurDev}>
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Tissue Culture */}
                    <TabPanel value="3">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleTissueSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectbeniFiApplicant}
                                  label="FPO/VCO"
                                  onChange={handleApplicantChangeESIFPO}
                                >
                                  {beniFiApplicant.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["beneficiary_name"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Division"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.divisions
                                ? nurseryTissueBeneficiary.divisions[
                                    "divisions"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="District"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.district
                                ? nurseryTissueBeneficiary.district[
                                    "district"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Taluka"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.taluka
                                ? nurseryTissueBeneficiary.taluka[
                                    "taluka"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Beneficiary Type"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.beneficiary_type
                                ? nurseryTissueBeneficiary.beneficiary_type[
                                    "beneficiary_type"
                                  ].toString()
                                : ""
                            }
                          />
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <MultiSelect
                              label="MAGNET Crops"
                              data={magentCrop}
                              value={
                                nurseryTissueBeneficiary &&
                                nurseryTissueBeneficiary.magnet_crop_id
                                  ? nurseryTissueBeneficiary.magnet_crop_id
                                  : []
                              }
                              readOnly={true}
                            />
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="demo-simple-select-label">
                                  Location (Taluka)
                                </InputLabel>
                                <Select
                                  readOnly={true}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={
                                    nurseryTissueBeneficiary &&
                                    nurseryTissueBeneficiary.location
                                      ? nurseryTissueBeneficiary.location[
                                          "location_taluka_id"
                                        ]
                                      : ""
                                  }
                                  label="Location (Taluka)"
                                >
                                  {taluka.map((e, key) => (
                                    <MenuItem value={e.id} key={key}>
                                      {e.taluka}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Area of Nursery in (Ha)"
                            type="text"
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.area
                                ? nurseryTissueBeneficiary.area
                                : ""
                            }
                            readOnly={true}
                          />
                          <InputField
                            label="Capacity (Total Plants)"
                            type="text"
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.capacity_total_plants
                                ? nurseryTissueBeneficiary.capacity_total_plants
                                : ""
                            }
                            readOnly={true}
                          />
                          <br />
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Sanctioned cost break-up{" "}
                              {selectTissueComponent &&
                                `for ${
                                  component.find(
                                    (e) => e["id"] === selectTissueComponent
                                  ).technical_component
                                }`}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Component
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectTissueComponent}
                                    onChange={handleTechComponentChangeChange}
                                    label="Comp"
                                  >
                                    {component.map((e) => (
                                      <MenuItem key={e.id} value={e.id}>
                                        {e["technical_component"]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />

                          {selectTissueComponent && (
                            <div>
                              <TissueCulture
                                compName={
                                  selectTissueComponent &&
                                  component.find(
                                    (e) => e["id"] === selectTissueComponent
                                  ).technical_component
                                }
                                tranche={
                                  nurseryTissueBeneficiary &&
                                  nurseryTissueBeneficiary.payment_tranche
                                    ? nurseryTissueBeneficiary.payment_tranche
                                    : ""
                                }
                                matchingGrant={isMatchingGrant}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  class="col-md-6"
                                  style={{
                                    position: "relative",
                                    right: "15px",
                                    bottom: "10px",
                                  }}
                                >
                                  <label
                                    for="inputNumber"
                                    class="col-lg col-form-label"
                                  >
                                    Upload supporting document. (e.g QPR, Photos
                                    etc.)
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="formFile"
                                      name="remarks"
                                      multiple
                                      onChange={handleMultiFilesGAPChange}
                                      onFocus={() => {
                                        setErrorCapacityFile("");
                                      }}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errorCapacityFile}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  {(editInput1Files || []).map((e) => (
                                    <div className="icon-container">
                                      <a
                                        href={`${fileUrl}${e.file_name}`}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <img
                                          src={e.type === "pdf" ? pdf : imgIcon}
                                          alt=""
                                          height="30px"
                                          width="26px"
                                        />
                                      </a>
                                      <i
                                        class="fa-regular fa-circle-xmark"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteFile(e.id)}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Tissue-Culture-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn"  disabled={loading}>
                                  {nurseryFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {nurseryFlagEdit === true && (
                                  <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteTissCulture}>
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Support New Technology */}
                    <TabPanel value="19">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleSupportTechSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectbeniFiApplicants}
                                  label="FPO/VCO"
                                  onChange={handleApplicantChangeESIFPO}
                                >
                                  {beniFiApplicants.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["beneficiary_name"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Division"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.divisions
                                ? nurseryTissueBeneficiary.divisions[
                                    "divisions"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="District"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.district
                                ? nurseryTissueBeneficiary.district[
                                    "district"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Taluka"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.taluka
                                ? nurseryTissueBeneficiary.taluka[
                                    "taluka"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Beneficiary Type"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.beneficiary_type
                                ? nurseryTissueBeneficiary.beneficiary_type[
                                    "beneficiary_type"
                                  ].toString()
                                : ""
                            }
                          />
                          <br />
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Sanctioned cost break-up{" "}
                              {selectSupportComponent &&
                                `for ${
                                  component.find(
                                    (e) => e["id"] === selectSupportComponent
                                  ).technical_component
                                }`}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Component
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectSupportComponent}
                                    onChange={handleTechComponentChangeChange}
                                    label="Comp"
                                  >
                                    {component.map((e) => (
                                      <MenuItem key={e.id} value={e.id}>
                                        {e["technical_component"]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />

                          {selectSupportComponent && (
                            <div>
                              <SupportTechnology
                                compName={
                                  selectSupportComponent &&
                                  component.find(
                                    (e) => e["id"] === selectSupportComponent
                                  ).technical_component
                                }
                                tranche={
                                  nurseryTissueBeneficiary &&
                                  nurseryTissueBeneficiary.payment_tranche
                                    ? nurseryTissueBeneficiary.payment_tranche
                                    : ""
                                }
                                matchingGrant={isMatchingGrant}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  class="col-md-6"
                                  style={{
                                    position: "relative",
                                    right: "15px",
                                    bottom: "10px",
                                  }}
                                >
                                  <label
                                    for="inputNumber"
                                    class="col-lg col-form-label"
                                  >
                                    Upload supporting document. (e.g QPR, Photos
                                    etc.)
                                  </label>
                                  <div class="col-sm-10">
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="formFile"
                                      name="remarks"
                                      multiple
                                      onChange={handleMultiFilesGAPChange}
                                      onFocus={() => {
                                        setErrorCapacityFile("");
                                      }}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errorCapacityFile}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  {(editInput1Files || []).map((e) => (
                                    <div className="icon-container">
                                      <a
                                        href={`${fileUrl}${e.file_name}`}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <img
                                          src={e.type === "pdf" ? pdf : imgIcon}
                                          alt=""
                                          height="30px"
                                          width="26px"
                                        />
                                      </a>
                                      <i
                                        class="fa-regular fa-circle-xmark"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteSupNewTech(e.id)}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Support-New-Tech-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn"  disabled={loading}>
                                  {nurseryFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {nurseryFlagEdit === true && (
                                  <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteNewTech}>
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
      
    </>
  );
};

