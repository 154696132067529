import '../assets/css/inputForm.css'
import { InputField } from "../subcomponents/InputField"
import pdf from '../assets/PDF_file_icon.svg.png'
import imgIcon from '../assets/image_icon-icons.com_50366.png'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setInputFieldData } from '../redux/slice/InputTwo/MasterSubSlice'; // Import your action creator
import { setValidationErrorsPhysicalFin, resetDataPhyscialFin, updatePhysicalFinForm, updateThreeFormData } from '../redux/slice/InputThree/Input3PhysicalFinance/PhysicalFinanceSlice'
import { setValidationErrorsEmpFullPartTime, resetDataFullPart, updateFullPartTime} from '../redux/slice/InputThree/EmpGenInputThree/FullPartTimeSlice'
import { resetDataFullPartOper ,setValidationErrorsEmpFullPartTimeOper ,updateFullPartTimeOper} from '../redux/slice/InputThree/EmpGenOperInpuThree/FullPartTimeOperSlice';
import { setValidationErrorsGender, resetDataGenderForm, updateGenderForm} from '../redux/slice/InputThree/GenderEqualityInputThree/GenderEqualitySlice'
import { setValidationErrorsEnvSafeGuard, resetDataEnvSafeGuard, updateEnvSafeGuardForm} from '../redux/slice/InputThree/SafeGuardInputThree/EnvSafeguardSlice'
import { setValidationErrorsFacilities, resetDataFacilities, updateFacilitiesGESIForm} from '../redux/slice/InputThree/FacilityInputThree/ProgressFacilitySlice'
import axios from 'axios';
import { FullTime } from '../subcomponents/Input3/Input3EmpGen/FullTime';
import {PartTime} from '../subcomponents/Input3/Input3EmpGen/PartTime';
import {ProgressFacilities} from '../subcomponents/Input3/Input3ProgressInFacilities/ProgressFacilities';
import { getConfigWithToken } from '../utils/Config/Config';
import { FormHelperText } from '@mui/material';
import { MultiSelect } from '../subcomponents/MultiSelect';
import { PhysicalFinance } from '../subcomponents/Input3/Input3PhysicalFinance/PhysicalFinance';
import Swal from 'sweetalert2';
import { Input3TabThree } from '../subcomponents/Input3/Input3TabForm/InputThreeTabThree'
import { InputThreeSafeGuard } from '../subcomponents/Input3/Input3TabSafeGuard/InputThreeSafeGuard'
import { FullTimeOper } from '../subcomponents/Input3/Input3EmpGenOpr/FullTimeOper'
import { PartTimeOper } from '../subcomponents/Input3/Input3EmpGenOpr/PartTimeOper'

export const InputForm13 = ({quarter, magentCrp, facili, powerSou}) => {
    const dd = useSelector(setInputFieldData);
    const apiUrl = process.env.REACT_APP_API_URL;
    const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;

    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [quarters, setQuarter] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectQuarter, setSelectedQuarters] = useState("")
    const [value, setValue] = useState('0');
    const [workPackage, setWorkPackage] = useState([])
    const [powerSource, setPowerSource] = useState([])
    const [selectWorkPackage, setselectedworkPackage] = useState("")
    const [MSAMBData, setMSAMBData] = useState([])
    const [selectMSAMB, setSelectedMSAMB] = useState("")
    const [magentCrop, setMagentCrop] = useState([])
    const [empType, setEmpType] = useState('Full Time Employment');
    const [empOperType, setEmpOperType] = useState('Full Time Employment');

    const [physicalFinanceFlagEdit, setPhysicalFinanceFlagEdit] = useState(false)
    const [physicalFinanceIdEdit, setPhysicalFinanceIdEdit] = useState("")
    const [empGenFlagEdit, setEmpGenFlagEdit] = useState(false)
    const [empGenIdEdit, setEmpGenIdEdit] = useState("")
    const [gesiFlagEdit, setGesiFlagEdit] = useState(false)
    const [gesiIdEdit, setGesiIdEdit] = useState("")
    const [envSafeGuardFlagEdit, setEnvSafeGuardFlagEdit] = useState(false)
    const [envSafeGuardIdEdit, setEnvSafeGuardIdEdit] = useState("")
    const [facilitiesFlagEdit, setFacilitiesFlagEdit] = useState(false)
    const [facilitiesIdEdit, setFacilitiesIdEdit] = useState("")

    const [editInput1Files, setEditInput1Files] = useState([])
    const [selectCapacityFile, setSelectCapacityFile] = useState([])
    const [errorCapacityFile, setErrorCapacityFile] = useState("")

    const [getApplicantData, setGetApplicantData] = useState({})
    const [errorWorkpackage, setErrorWorkPackage] = useState("")
    const [errorMSAMBFacilites, setErrorMSAMBFacilites] = useState("")
    const [permission, setPermission] = useState([])

    const checkWorkPackageSelect = () =>{
        if(selectWorkPackage.length === 0){
            setErrorWorkPackage("Please Select WorkPackage")
        }else{
            return true
        }
        return false
    }

    const checkMSMBSelect = () =>{
        if(selectMSAMB.length === 0){
            setErrorMSAMBFacilites("Please Select MSAMB")
        }else{
            return true
        }
        return false
    }

    const resetAllState = () => {
        setGetApplicantData({})
        setSelectedMSAMB("")
      setEnvSafeGuardFlagEdit("");
      setFacilitiesFlagEdit("");
      setGesiFlagEdit("");
      setEmpGenFlagEdit("");
      setPhysicalFinanceFlagEdit("");
        setselectedworkPackage("")
        setGesiFlagEdit(false)
        dispatch(resetDataEnvSafeGuard())
        dispatch(resetDataFullPart())
        dispatch(resetDataGenderForm())
        dispatch(resetDataPhyscialFin())
        dispatch(resetDataFacilities())
    }

  
    const handleDeleteFile = async (id) =>{
        const deleteFile = await axios.get(`${apiUrl}api/delete-file-input-three?id=${id}`,getConfigWithToken())
        if(deleteFile.data["status"]){
            const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
            setEditInput1Files(newRemarks);
            Swal.fire(
                `File deleted Successfully`,
                '',
                'success'
            )
        }
    }
    const handleChange = async (event, newValue) => {
        setValue(newValue);
        setEditInput1Files([])
        resetAllState()
    }

    const handleEmpChange = (event) =>{
        setEmpType(event.target.value)
    }

    const handleEmpOperChange = (event) =>{
        setEmpOperType(event.target.value)
    }

    const refershdata = () => {
        setselectedworkPackage(''); // Reset the workpackage selection
        setSelectedMSAMB('');// Reset msamb name
        setGetApplicantData({}); // Reset prefill data
        setPhysicalFinanceFlagEdit("");
        setEmpGenFlagEdit("");
        setEnvSafeGuardFlagEdit("");
        setGesiFlagEdit("");
        dispatch(resetDataPhyscialFin({}));
        dispatch(resetDataFullPart({}));
        dispatch(resetDataFullPartOper({}));
        dispatch(resetDataGenderForm({}));
        dispatch(resetDataEnvSafeGuard({}));
        setEditInput1Files([]);
        setSelectCapacityFile([]);
        setErrorCapacityFile('');
    }
    const handleQuarterChange = async (event) =>{
        refershdata()
        setPhysicalFinanceFlagEdit("")
        setEmpGenFlagEdit("")
        setEnvSafeGuardFlagEdit("")
        setGesiFlagEdit("")
        setSelectedQuarters(event.target.value)
    }

    const handleMultiFilesGAPChange = (event) => {
        setSelectCapacityFile(event.target.files)
    }

    const handleApplicantChangeWorkPackage = async (event) => {
        setEditInput1Files([]);
        setSelectCapacityFile([]);
        setErrorCapacityFile('');
        setGetApplicantData({})
        setEditInput1Files([])
        setselectedworkPackage(event.target.value)
        setErrorWorkPackage("")
        setSelectedMSAMB([])
        setPhysicalFinanceFlagEdit("");
        setEmpGenFlagEdit("");
        setEnvSafeGuardFlagEdit("");
        setGesiFlagEdit("");
        dispatch(resetDataPhyscialFin())
        dispatch(resetDataFullPart())
        dispatch(resetDataFullPartOper())
        const msambData = await axios.get(`${apiUrl}api/get-msamb-facility-by-work-package?work_package_id=${event.target.value}`,getConfigWithToken())
        if(msambData.data["status"] === true){
            setMSAMBData(msambData.data["data"][0])
        }
    }

    const getBeneficiaryData = async (value) => {
        const getDataByMSMB = await axios.get(`${apiUrl}api/get-primary-detaisls-by-msambid?msamb_facility_id=${value}&work_package_id=${selectWorkPackage}`,getConfigWithToken())
        const data = await getDataByMSMB.data["data"][0][0]
        data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
        setGetApplicantData(data)
        return await data
    }


    const handleMSAMBSelectOption = async (event) => {
        setSelectedMSAMB(event.target.value)
        setEditInput1Files([])
        dispatch(resetDataPhyscialFin())
        dispatch(resetDataFullPart())
        setPhysicalFinanceFlagEdit("");
        setEmpGenFlagEdit("");
        setEnvSafeGuardFlagEdit("");
        setGesiFlagEdit("");
        setErrorMSAMBFacilites("")
        if(value === '0'){
            const getData = await axios.get(`${apiUrl}api/physical-finance-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const getTData = await axios.get(`${apiUrl}api/getPhysicalFinanceInThreeTillDataData?msamb_facility_id=${event.target.value}&quarter=${selectQuarter}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
                // console.log(getTData.data.data[0],"mmmmmmmmmmmmmmmmmmm")
                if(getTData.data.data[0]){
                    dispatch(updateThreeFormData(getTData.data.data[0]))
                }
                const data = getData.data["data"][0]
                getData.data["flag"]?setPhysicalFinanceFlagEdit(true):setPhysicalFinanceFlagEdit(false)
                setPhysicalFinanceIdEdit(data["id"])
                setEditInput1Files(data["remarks"])
                data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
                setGetApplicantData(data)
                const {civil_progress_till_date, electro_mechanical_progress_till_date, ...newObjs} = data
                dispatch(updatePhysicalFinForm(newObjs))
            }else{
                const data = await getBeneficiaryData(event.target.value)
                const getTData = await axios.get(`${apiUrl}api/getPhysicalFinanceInThreeTillDataData?msamb_facility_id=${event.target.value}&quarter=${selectQuarter}&work_package_id=${selectWorkPackage}`, getConfigWithToken())
                // console.log(getTData.data.data[0],"mmmmmmmmmmmmmmmmmmm")
                if(getTData.data.data[0]){
                    dispatch(updateThreeFormData(getTData.data.data[0]))
                }
                const {civil_progress_till_date, electro_mechanical_progress_till_date, ...newObjs} = data
                dispatch(updatePhysicalFinForm(newObjs))
            }
        }
        if(value === '1'){
            const getData = await axios.get(`${apiUrl}api/emp-gen-const-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const data = getData.data["data"][0]
                getData.data["flag"]?setEmpGenFlagEdit(true):setEmpGenFlagEdit(false)
                setEmpGenIdEdit(data["id"])
                setEditInput1Files(data["remarks"])
                data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
                setGetApplicantData(data)
                dispatch(updateFullPartTime(data))
            }else{
                getBeneficiaryData(event.target.value)
            }
        }
        if(value === '5'){
            const getData = await axios.get(`${apiUrl}api/emp-gen-opr-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const data = getData.data["data"][0]
                getData.data["flag"]?setEmpGenFlagEdit(true):setEmpGenFlagEdit(false)
                setEmpGenIdEdit(data["id"])
                setEditInput1Files(data["remarks"])
                data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
                setGetApplicantData(data)
                dispatch(updateFullPartTimeOper(data))
            }else{
                getBeneficiaryData(event.target.value)
            }
        }
        if(value === '2'){
            dispatch(resetDataGenderForm())
            setGesiFlagEdit(false)
            const getData = await axios.get(`${apiUrl}api/enforcement-process-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const data = getData.data["data"][0]
                getData.data["flag"]?setGesiFlagEdit(true):setGesiFlagEdit(false)
                setGesiIdEdit(data["id"])
                data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
                setGetApplicantData(data)
                dispatch(updateGenderForm(data))
            }else{
                getBeneficiaryData(event.target.value)
            }
        }
        if(value === '3'){
            dispatch(resetDataEnvSafeGuard())
            setEnvSafeGuardFlagEdit(false)
            const getData = await axios.get(`${apiUrl}api/msamb-safeguard-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const data = getData.data["data"][0]
                getData.data["flag"]?setEnvSafeGuardFlagEdit(true):setEnvSafeGuardFlagEdit(false)
                setEnvSafeGuardIdEdit(data["id"])
                data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
                setGetApplicantData(data)
                dispatch(updateEnvSafeGuardForm(data))
            }else{
                getBeneficiaryData(event.target.value)
            }
        }
        if(value === '4'){
            const getData = await axios.get(`${apiUrl}api/changes-fac-gesi-input-three-edit?msamb_facility_id=${event.target.value}&work_package_id=${selectWorkPackage}&quarter=${selectQuarter}`, getConfigWithToken())
            if(getData.data["status"] === true){
                const data = getData.data["data"][0]
                getData.data["flag"]?setFacilitiesFlagEdit(true):setFacilitiesFlagEdit(false)
                setFacilitiesIdEdit(data["id"])
                setGetApplicantData(data)
                dispatch(updateFacilitiesGESIForm(data))
            }else{
                getBeneficiaryData(event.target.value)
            }
        }
       
    }

    const handlePhysicalFinanceSubmit = async (e) => {
        e.preventDefault()
        console.log(dd["payload"]["physicalFinance"],"hhhhhhhhhhhhhhhhhhv")
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["physicalFinance"];
            const errorMessages = {
                status_of_work_id: 'Status Work is Required',
                exp_incurred: 'Expenditure is Required',
                civil_progress_pct: 'Civil Progress is Required',
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsPhysicalFin(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            setLoading(true);
            try{
                const physicalFinance = dd["payload"]["physicalFinance"]

                const jsonMagnetCrop = getApplicantData["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === id);
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };

                const formData = new FormData();
                 for (const key in physicalFinance) {
                    formData.append(key, physicalFinance[key]!==null?physicalFinance[key]:"");
                  }

                formData.append('quarter', selectQuarter);
                formData.append('work_package_id', selectWorkPackage);
                formData.append('msamb_facility_id', selectMSAMB);
                formData.append('district_id', getApplicantData.district_id);
                formData.append('division_id', getApplicantData.division_id);
                formData.append('taluka_id', getApplicantData.taluka_id);
                formData.append('magnet_crop_id', JSON.stringify(resultMagnetCrop["data"]));
                formData.append('form_id','27')

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }


                try{
                    if(!physicalFinanceFlagEdit){
                    
                        const submitPhyFinData = await axios.post(`${apiUrl}api/physical-finance-input-three-create`, formData, getConfigWithToken())
                        if(submitPhyFinData.data["status"] === true){
                            Swal.fire(
                                `${submitPhyFinData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataPhyscialFin())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitPhyFinData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", physicalFinanceIdEdit);
                        const submitPhyFinData = await axios.post(`${apiUrl}api/physical-finance-input-three-update`, formData, getConfigWithToken())
                        
                        if(submitPhyFinData.data["status"] === true){
                            Swal.fire(
                                `${submitPhyFinData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataPhyscialFin())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitPhyFinData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleEmpGenSubmit = async (e) => {
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["fullPartTime"];
            const errorMessages = {
                full_time_emp_num_male: 'Full-Time Employment (Male) is Required',
                full_time_emp_num_female: 'Full-Time Employment (Female) is Required',
                full_time_emp_num_obc_minority_open_male: 'Full-Time Employment OBC Minority Open (Male) is Required',
                full_time_emp_num_obc_minority_open_female: 'Full-Time Employment OBC Minority Open (Female) is Required',

                part_time_emp_num_male: 'Part-Time Employment (Male) is Required',
                part_time_emp_num_female: 'Part-Time Employment (Female) is Required',
                part_time_emp_num_obc_minority_open_male: 'Part-Time Employment OBC Minority Open (Male) is Required',
                part_time_emp_num_obc_minority_open_female: 'Part-Time Employment OBC Minority Open (Female) is Required',
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (value==="" || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsEmpFullPartTime(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            try{
                setLoading(true)
                const empGenFullPart = dd["payload"]["fullPartTime"]

                const formData = new FormData();
                 for (const key in empGenFullPart) {
                    formData.append(key, empGenFullPart[key]!==null?empGenFullPart[key]:"");
                  }

                formData.append('quarter', selectQuarter);
                formData.append('work_package_id', selectWorkPackage);
                formData.append('msamb_facility_id', selectMSAMB);
                formData.append('district_id', getApplicantData.district_id);
                formData.append('division_id', getApplicantData.division_id);
                formData.append('taluka_id', getApplicantData.taluka_id);
                formData.append('form_id','28')

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false)
                    return
                }


                try{
                    if(!empGenFlagEdit){
                    // if(true){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false)
                            return
                        }
                        const submitEmpGenData = await axios.post(`${apiUrl}api/emp-gen-const-input-three-create`, formData, getConfigWithToken())
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullPart())
                                setEditInput1Files([]);
                                setSelectCapacityFile([]);
                                setErrorCapacityFile('');
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", empGenIdEdit);
                        const submitEmpGenData = await axios.post(`${apiUrl}api/emp-gen-const-input-three-update`, formData, getConfigWithToken())
                        
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullPart())
                                setEditInput1Files([]);
                                setSelectCapacityFile([]);
                                setErrorCapacityFile('');
                                setLoading(false);

                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleEmpoperSubmit = async (e) => {
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["FullPartTimeOper"];
            const errorMessages = {
                full_time_emp_num_male: 'Full-Time Employment (Male) is Required',
                full_time_emp_num_female: 'Full-Time Employment (Female) is Required',
                full_time_emp_num_obc_minority_open_male: 'Full-Time Employment OBC Minority Open (Male) is Required',
                full_time_emp_num_obc_minority_open_female: 'Full-Time Employment OBC Minority Open (Female) is Required',

                part_time_emp_num_male: 'Part-Time Employment (Male) is Required',
                part_time_emp_num_female: 'Part-Time Employment (Female) is Required',
                part_time_emp_num_obc_minority_open_male: 'Part-Time Employment OBC Minority Open (Male) is Required',
                part_time_emp_num_obc_minority_open_female: 'Part-Time Employment OBC Minority Open (Female) is Required',
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (value ===""|| (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            console.log(errors,'errors')
            dispatch(setValidationErrorsEmpFullPartTimeOper(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };
console.log(dd["payload"]["FullPartTimeOper"],'payload')
        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            try{
                setLoading(true)
                const empGenFullPart = dd["payload"]["FullPartTimeOper"]

                const formData = new FormData();
                 for (const key in empGenFullPart) {
                    formData.append(key, empGenFullPart[key]!==null?empGenFullPart[key]:"");
                  }

                formData.append('quarter', selectQuarter);
                formData.append('work_package_id', selectWorkPackage);
                formData.append('msamb_facility_id', selectMSAMB);
                formData.append('district_id', getApplicantData.district_id);
                formData.append('division_id', getApplicantData.division_id);
                formData.append('taluka_id', getApplicantData.taluka_id);
                formData.append('form_id','34')
console.log(getApplicantData.district_id,'district')
                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false)
                    return
                }


                try{
                    if(!empGenFlagEdit){
                    // if(true){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false)
                            return
                        }
                        const submitEmpGenData = await axios.post(`${apiUrl}api/emp-gen-opr-input-three-create`, formData, getConfigWithToken())
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullPartOper())
                                setEditInput1Files([]);
                                setSelectCapacityFile([]);
                                setErrorCapacityFile('');
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", empGenIdEdit);
                        const submitEmpGenData = await axios.post(`${apiUrl}api/emp-gen-opr-input-three-update`, formData, getConfigWithToken())
                        
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullPartOper())
                                setEditInput1Files([]);
                                setSelectCapacityFile([]);
                                setErrorCapacityFile('');
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleGenderEqualitySubmit = async (e) => {
        e.preventDefault()

        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["genderEquality"];
            const errorMessages = {
                total_num_of_toilets_men: 'Total Number of Toilets (Men) is Required',
                total_num_of_toilets_women: 'Total Number of Toilets (Women) is Required',
                total_num_of_toilets_disabled: 'Total Number of Toilets (Disabled) is Required',
                ramps_for_disabled: 'Ramps for Disabled is Required',
                work_env_safe_for_women: 'Work Environment Safe for Women is Required',
                separate_changing_rooms: 'Separate Changing Rooms is Required',
                committee_of_women: 'Committee of Women is Required',
                practice_of_forced_labor: 'Practice of Forced Labor is Required',
                compensation_act_enforced: 'Compensation Act Enforced is Required',
                safety_reg_followed: 'Safety Regulations Followed is Required',
                labor_law_followed: 'Labor Laws Followed is Required',
                workplace_clean_and_safe: 'Workplace Clean and Safe is Required',
                equal_pay_for_equal_work_followed: 'Equal Pay for Equal Work Followed is Required',
                dispute_resolved: 'Dispute Resolved is Required',
                bonus_paid: 'Bonus Paid is Required',
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsGender(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            try{
                setLoading(true)
                const genderEquality = dd["payload"]["genderEquality"]
                const objGender = {...genderEquality, 
                    "quarter": selectQuarter,
                    "work_package_id": selectWorkPackage,
                    "msamb_facility_id": selectMSAMB,
                    'district_id': getApplicantData.district_id,
                    'division_id': getApplicantData.division_id,
                    'taluka_id': getApplicantData.taluka_id,
                    'form_id': '31'
                }

                try{
                    if(!gesiFlagEdit){
                        const submitEmpGenData = await axios.post(`${apiUrl}api/enforcement-process-input-three-create`, objGender, getConfigWithToken())
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataGenderForm())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                        
                    }else{
                        // also have to set Id in object
                        objGender["id"] = gesiIdEdit
                        const submitEmpGenData = await axios.post(`${apiUrl}api/enforcement-process-input-three-update`, objGender, getConfigWithToken())
                        
                        if(submitEmpGenData.data["status"] === true){
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataGenderForm())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEmpGenData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
        
    }

    const handleEnvSafeGuardSubmit = async (e) => {
        e.preventDefault()

        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["envSafeGuard"];
            const errorMessages = {
                safe_work: 'Safe Work is Required',
                sanitation: 'Sanitation is Required',
                rainwater_harvesting: 'Rainwater Harvesting is Required',
              
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsEnvSafeGuard(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            try{
                setLoading(true)
                const genderEquality = dd["payload"]["envSafeGuard"]
                const objGender = {...genderEquality, 
                    "quarter": selectQuarter,
                    "work_package_id": selectWorkPackage,
                    "msamb_facility_id": selectMSAMB,
                    'district_id': getApplicantData.district_id,
                    'division_id': getApplicantData.division_id,
                    'taluka_id': getApplicantData.taluka_id,
                    'form_id': '30'
                }

                try{
                    if(!envSafeGuardFlagEdit){
                        const submitEnvSafGuard = await axios.post(`${apiUrl}api/msamb-safeguard-input-three-create`, objGender, getConfigWithToken())
                        if(submitEnvSafGuard.data["status"] === true){
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataEnvSafeGuard())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        objGender["id"] = envSafeGuardIdEdit
                        const submitEnvSafGuard = await axios.post(`${apiUrl}api/msamb-safeguard-input-three-update`, objGender, getConfigWithToken())
                        
                        if(submitEnvSafGuard.data["status"] === true){
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataEnvSafeGuard())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleFacilitiesSubmit = async (e) => {
        e.preventDefault()

        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["progressFacility"];
            const errorMessages = {
                capacity: 'Capacity is Required',
                facility_id: 'Facility is Required',
                progress: 'Progress is Required',
                action_taken: 'Action Taken is Required',
                suggestions_changes_made: 'Suggestion is Required'
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsFacilities(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkWorkPackageSelect() && checkMSMBSelect() && validateForm()){
            try{
                setLoading(true)
                const genderEquality = dd["payload"]["progressFacility"]
                const objGender = {...genderEquality, 
                    "quarter": selectQuarter,
                    "work_package_id": selectWorkPackage,
                    "msamb_facility_id": selectMSAMB,
                    'district_id': getApplicantData.district_id,
                    'division_id': getApplicantData.division_id,
                    'taluka_id': getApplicantData.taluka_id,
                    'form_id': '29'
                }

                try{
                    if(!facilitiesFlagEdit){
                        const submitEnvSafGuard = await axios.post(`${apiUrl}api/changes-fac-gesi-input-three-create`, objGender, getConfigWithToken())
                        if(submitEnvSafGuard.data["status"] === true){
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFacilities())
                                setLoading(false);
                                });
                
                        }else{
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                
                        }
                    }else{
                        // also have to set Id in object
                        objGender["id"] = facilitiesIdEdit
                        const submitEnvSafGuard = await axios.post(`${apiUrl}api/changes-fac-gesi-input-three-update`, objGender, getConfigWithToken())
                        
                        if(submitEnvSafGuard.data["status"] === true){
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFacilities())
                                setLoading(false);
                                });
                
                        }else{
                            Swal.fire(
                                `${submitEnvSafGuard.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }
   

    const handleDeletePhyProgress = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/physical-finance-input-three-delete?id=${physicalFinanceIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setPhysicalFinanceFlagEdit("");
              dispatch(resetDataPhyscialFin());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteEMPGenConst = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/emp-gen-const-input-three-delete?id=${empGenIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setEmpGenFlagEdit("");
              dispatch(resetDataFullPart());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteEMPGenOPR = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/emp-gen-opr-input-three-delete?id=${empGenIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setEmpGenFlagEdit("");
              dispatch(resetDataFullPartOper());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteGESI = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/enforcement-process-input-three-delete?id=${gesiIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setGesiFlagEdit("");
              dispatch(resetDataGenderForm());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteSafeGuard = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/msamb-safeguard-input-three-delete?id=${envSafeGuardIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setEnvSafeGuardFlagEdit("");
              dispatch(resetDataEnvSafeGuard());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
    
    useEffect(()=>{
        const quarterData = async () => {
            try {
                const storedArrayAsString = localStorage.getItem('permission');
                setPermission(storedArrayAsString)
                
                const workPackage = await axios.get(`${apiUrl}api/work-package-listing?all=1`,getConfigWithToken());
                setQuarter(quarter);
                const selectedValues = quarter.filter(item => item.selected === "Selected").map(item => item.id);
                setSelectedQuarters(selectedValues[0])
                setWorkPackage(workPackage.data["data"])
                setMagentCrop(magentCrp)
                setFacilities(facili)
                setPowerSource(powerSou)
            }catch(err){

            }

        }
        quarterData()
    },[quarter, magentCrp, facili, powerSou])

    const EMPType = {
        'Full Time Employment':<FullTime />,
        'Part Time Employment':<PartTime />
    }

    const EMPOperType = {
        'Full Time Employment':<FullTimeOper />,
        'Part Time Employment':<PartTimeOper />
    }


    return(
        <>
        <main id="main" class="main">
        <section class="section">
        <div class="row">
            <div id="exTab3" class="contain">	
            
            <div class="card">
            <Box sx={{ width: '100%', typography: 'body1', bgcolor: 'background.paper' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange}>
                        <Tab label="Project Progress" value="0" className={`${value === '0' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="Job Generated During construction" value="1" className={`${value === '1' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="Job Generated During Operation" value="5" className={`${value === '5' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="GESI" value="2" className={`${value === '2' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="Safegaurd" value="3" className={`${value === '3' ? 'activeClickCSS' : ''}`} />
                    </Tabs>
                    </Box>
                    {/* Physical Finance */}
                    <TabPanel value="0">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handlePhysicalFinanceSubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                        <MultiSelect label="MAGNET Crops" data={magentCrop} value={getApplicantData && getApplicantData.magnet_crop_id ? getApplicantData.magnet_crop_id :[] } readOnly={true}/>
                                    </div>
                                <br/>
                                <div className='word-with-line'>
                                    <span>Details of facility wise information</span>
                                    <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                </div>
                                    <PhysicalFinance data={getApplicantData}/>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {editInput1Files.map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Project-Prog-Inp-Three-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{physicalFinanceFlagEdit === true ? "Update":"Submit"}</button>
                                        {physicalFinanceFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeletePhyProgress}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Employment Generated- During construction - MSAMB Facility */}
                    <TabPanel value="1">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleEmpGenSubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>
                                <br/>
                                <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                        <span>Details of Current Quarter for - {empType} </span>
                                        <div>
                                            {/* <label>Select an Option:</label> */}
                                            <Box sx={{ minWidth: 390 }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Employment Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={empType} onChange={handleEmpChange}
                                                        label="Employm"
                                                    >
                                                    {Object.keys(EMPType).map((option) => (
                                                        <MenuItem value={option}>{option}</MenuItem>
                                                        
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        {empType && (
                                        <div>
                                            {EMPType[empType]}
                                        </div>
                                        )}
                                        <div className='word-with-line' style={{margin: "2rem 0rem"}}>
                                                <span>Total Employment Generated</span>
                                                <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                            </div>
                                        <div className='row' style={{display:"flex"}}>
                                            <InputField  label="Male" type="number" readOnly={true} value={dd["payload"]["fullPartTime"].total_emp_generated_male}/>
                                            <InputField  label="Female" type="number" readOnly={true} value={dd["payload"]["fullPartTime"].total_emp_generated_female}/>
                                            <InputField  label="Total Full time & Part time" type="number" readOnly={true} value={dd["payload"]["fullPartTime"].total_emp_generated_total}/>  
                                        </div>
                                    </div>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {editInput1Files.map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Emp-Gen-Dur-Construction-Inp-Three-view") ? (
                                        <>
                                       <button type="submit" class="btn submitbtn" disabled={loading}>{empGenFlagEdit === true ? "Update":"Submit"}</button>
                                       {empGenFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteEMPGenConst}>
                                            Delete
                                            </button>
                                        )}
                                       </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                        {/* Employment Generated- During Operation - MSAMB Facility */}
                        <TabPanel value="5">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleEmpoperSubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                               <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>
                                <br/>
                                <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                        <span>Details of Current Quarter for - {empOperType} </span>
                                        <div>
                                            <Box sx={{ minWidth: 390 }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Employment Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={empOperType} onChange={handleEmpOperChange}
                                                        label="Employm"
                                                    >
                                                    {Object.keys(EMPOperType).map((option) => (
                                                        <MenuItem value={option}>{option}</MenuItem>
                                                        
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        {empOperType && (
                                        <div>
                                            {EMPOperType[empOperType]}
                                        </div>
                                        )}
                                        <div className='word-with-line' style={{margin: "2rem 0rem"}}>
                                                <span>Total Employment Generated</span>
                                                <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                            </div>
                                        <div className='row' style={{display:"flex"}}>
                                            <InputField  label="Male" type="number" readOnly={true} value={dd["payload"]["FullPartTimeOper"].total_emp_generated_male}/>
                                            <InputField  label="Female" type="number" readOnly={true} value={dd["payload"]["FullPartTimeOper"].total_emp_generated_female}/>
                                            <InputField  label="Total Full time & Part time" type="number" readOnly={true} value={dd["payload"]["FullPartTimeOper"].total_emp_generated_total}/>  
                                        </div>
                                    </div>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {editInput1Files.map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Job-Gen-Dur-Operation-Inp-Input-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{empGenFlagEdit === true ? "Update":"Submit"}</button>
                                        {empGenFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteEMPGenOPR}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Gender Equality and Social Inclusion */}
                    <TabPanel value="2">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleGenderEqualitySubmit}>
                                    <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>
                                    
                                <br/>
                                    <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                        <span>Details of GESI for Current Quarter</span>
                                    </div>
                                    <Input3TabThree/>
                                <br/>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Gen-Equity-Soc-Inclusion-Inp-Three-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{gesiFlagEdit === true ? "Update":"Submit"}</button>
                                        {gesiFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteGESI}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Environmental safegaurd */}
                    <TabPanel value="3">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleEnvSafeGuardSubmit}>
                                <div className='quarterSelect'>
                                    <div className='support'>
                                    </div>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                        <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                        <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                        </Box>
                                    </div>
                                </div>
                                <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>

                                    <div className='word-with-line'>
                                        <span>Details of information for Current Quarter of Safegaurd</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    
                                    <InputThreeSafeGuard powerSource={powerSource}/>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Safeguard-Inp-Three-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{envSafeGuardFlagEdit === true ? "Update":"Submit"}</button>
                                        {envSafeGuardFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteSafeGuard}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Progress in facilities */}
                    <TabPanel value='4'>
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleFacilitiesSubmit}>
                                    <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorWorkpackage !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Work Package</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectWorkPackage}
                                                label="Work Package"
                                                onChange={handleApplicantChangeWorkPackage}
                                        
                                                >
                                                {(workPackage).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["work_package"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorWorkpackage}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorMSAMBFacilites !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">Name of the MSAMB Facility</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectMSAMB}
                                                label="Name of the MSAMB Facility"
                                                onChange={handleMSAMBSelectOption}
                                        
                                                >
                                                {(MSAMBData).map((e)=>(
                                                    <MenuItem value={e.msamb_facility_id} key={e.msamb_facility_id}>{e["msamb_facility_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorMSAMBFacilites}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={getApplicantData && getApplicantData.divisions ? getApplicantData.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={getApplicantData && getApplicantData.district ? getApplicantData.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={getApplicantData && getApplicantData.taluka ? getApplicantData.taluka.toString() : ''}/>
                                    <div className='word-with-line'>
                                        <span>Details of Changes in Facilities for Current Quarter</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                        <ProgressFacilities facilities={facilities}/>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Job-Gen-Dur-Operation-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{facilitiesFlagEdit === true ? "Update":"Submit"}</button>
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </TabPanel>

                </TabContext>
            </Box>
            </div>
        </div>

        </div>
    </section>
    </main>
   
        </>
    )
}