import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswer,
  setField,
  resetValidation,
  setInputField,
} from "../../../redux/slice/InputThree/GenderEqualityInputThree/GenderEqualitySlice";
import { InputField } from "../../InputField";
import { FormControl, FormHelperText } from "@mui/material";

export const Input3TabThree = () => {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.genderEquality);
  const {
    total_num_of_toilets_men,
    total_num_of_toilets_women,
    total_num_of_toilets_disabled,
    validationErrors,
  } = selectedOption;


  // Handle radio button change for a specific question
  const handleOptionChange = (question, answer) => {
    // Dispatch the setAnswer action with the question and answer
    dispatch(setAnswer({ question, answer }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputField({ fieldName, value }));
  };


  return (
    <>
      <InputField
        label="Toilets for men"
        type="number"
        endAr="(in no.)"
        value={total_num_of_toilets_men}
        onChange={(value)=>handleInputChange("total_num_of_toilets_men",value)}
        onFocus={handleResetFocus}
        error={!!validationErrors.total_num_of_toilets_men}
        helperText={validationErrors.total_num_of_toilets_men}
      />

      <InputField
        label="Toilets for women"
        type="number"
        endAr="(in no.)"
        value={total_num_of_toilets_women}
        onChange={(value)=>handleInputChange("total_num_of_toilets_women",value)}
        onFocus={handleResetFocus}
        error={!!validationErrors.total_num_of_toilets_women}
        helperText={validationErrors.total_num_of_toilets_women}
      />

      <InputField
        label="Toilets for disabled"
        type="number"
        endAr="(in no.)"
        value={total_num_of_toilets_disabled}
        onChange={(value)=>handleInputChange("total_num_of_toilets_disabled",value)}
        onFocus={handleResetFocus}
        error={!!validationErrors.total_num_of_toilets_disabled}
        helperText={validationErrors.total_num_of_toilets_disabled}
      />
      {/* Question 1 */}
      <FormControl
        error={!!validationErrors.ramps_for_disabled !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px", marginTop: "23px" }}
        >
          <div className="col-md-8">
            <p>Are there ramps for the disabled ?</p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["ramps_for_disabled"] === "Yes"}
              onChange={() => handleOptionChange("ramps_for_disabled", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["ramps_for_disabled"] === "No"}
              onChange={() => handleOptionChange("ramps_for_disabled", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.ramps_for_disabled}</FormHelperText>
      </FormControl>

      {/* Question 2 */}
      <FormControl
        error={!!validationErrors.work_env_safe_for_women !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Is the work environment safe for women workers (safety measures as
              per labor laws) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["work_env_safe_for_women"] === "Yes"}
              onChange={() =>
                handleOptionChange("work_env_safe_for_women", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["work_env_safe_for_women"] === "No"}
              onChange={() =>
                handleOptionChange("work_env_safe_for_women", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.work_env_safe_for_women}
        </FormHelperText>
      </FormControl>

      {/* Question 3 */}
      <FormControl
        error={!!validationErrors.separate_changing_rooms !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Are there separate changing rooms (if possible) for men and women
              ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["separate_changing_rooms"] === "Yes"}
              onChange={() =>
                handleOptionChange("separate_changing_rooms", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["separate_changing_rooms"] === "No"}
              onChange={() =>
                handleOptionChange("separate_changing_rooms", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.separate_changing_rooms}
        </FormHelperText>
      </FormControl>

      {/* Question 4 */}
      <FormControl
        error={!!validationErrors.committee_of_women !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Is there a committee of women members to report and investigate
              the sexual harassment, exploitation and abuse of women workers.
              (Please report nos of complaints received and acted upon during
              reporting period) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["committee_of_women"] === "Yes"}
              onChange={() => handleOptionChange("committee_of_women", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["committee_of_women"] === "No"}
              onChange={() => handleOptionChange("committee_of_women", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.committee_of_women}</FormHelperText>
      </FormControl>

      {/* Question 5 */}
      <FormControl
        error={
          !!validationErrors.practice_of_forced_labor !== "" ? true : false
        }
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Is the practice of forced labor followed. (For example, do workers
              have to work for a contractor until the loan is repaid) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["practice_of_forced_labor"] === "Yes"}
              onChange={() =>
                handleOptionChange("practice_of_forced_labor", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["practice_of_forced_labor"] === "No"}
              onChange={() =>
                handleOptionChange("practice_of_forced_labor", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.practice_of_forced_labor}
        </FormHelperText>
      </FormControl>

      {/* Question 6 */}
      <FormControl
        error={
          !!validationErrors.compensation_act_enforced !== "" ? true : false
        }
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Is the Compensation Act enforced. (For example, is there any
              compensation paid by the contractor to the worker in case of
              injury while on duty and at work, and to the dependents in case of
              death) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["compensation_act_enforced"] === "Yes"}
              onChange={() =>
                handleOptionChange("compensation_act_enforced", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["compensation_act_enforced"] === "No"}
              onChange={() =>
                handleOptionChange("compensation_act_enforced", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.compensation_act_enforced}
        </FormHelperText>
      </FormControl>

      {/* Question 7 */}
      <FormControl
        error={!!validationErrors.safety_reg_followed !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Are workers' safety regulations followed during construction. (For
              example, workers are provided with a protective cap on their head,
              safety goggles, a doctor at work, high visibility clothing,
              building safety nets, the facility to stay at the construction
              site until construction begins) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["safety_reg_followed"] === "Yes"}
              onChange={() => handleOptionChange("safety_reg_followed", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["safety_reg_followed"] === "No"}
              onChange={() => handleOptionChange("safety_reg_followed", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.safety_reg_followed}</FormHelperText>
      </FormControl>

      {/* Question 8 */}
      <FormControl
        error={!!validationErrors.labor_law_followed !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Are labor laws pertaining to workers followed (For example, 8
              hours work a day, no heavy duty workload for pregnant women) ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["labor_law_followed"] === "Yes"}
              onChange={() => handleOptionChange("labor_law_followed", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["labor_law_followed"] === "No"}
              onChange={() => handleOptionChange("labor_law_followed", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.labor_law_followed}</FormHelperText>
      </FormControl>

      {/* Question 9 */}
      <FormControl
        error={
          !!validationErrors.workplace_clean_and_safe !== "" ? true : false
        }
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>Is the workplace environment clean and safe for workers ?</p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["workplace_clean_and_safe"] === "Yes"}
              onChange={() =>
                handleOptionChange("workplace_clean_and_safe", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["workplace_clean_and_safe"] === "No"}
              onChange={() =>
                handleOptionChange("workplace_clean_and_safe", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.workplace_clean_and_safe}
        </FormHelperText>
      </FormControl>

      {/* Question 10 */}
      <FormControl
        error={
          !!validationErrors.equal_pay_for_equal_work_followed !== ""
            ? true
            : false
        }
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Whether the rule 'equal pay for equal work' is being followed for
              men and women workers ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={
                selectedOption["equal_pay_for_equal_work_followed"] === "Yes"
              }
              onChange={() =>
                handleOptionChange("equal_pay_for_equal_work_followed", "Yes")
              }
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={
                selectedOption["equal_pay_for_equal_work_followed"] === "No"
              }
              onChange={() =>
                handleOptionChange("equal_pay_for_equal_work_followed", "No")
              }
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>
          {validationErrors.equal_pay_for_equal_work_followed}
        </FormHelperText>
      </FormControl>

      {/* Question 11 */}
      <FormControl
        error={!!validationErrors.dispute_resolved !== "" ? true : false}
      >
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>
              Whether the dispute between the contractor and the worker resolved
              as per legal procedures ?
            </p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["dispute_resolved"] === "Yes"}
              onChange={() => handleOptionChange("dispute_resolved", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["dispute_resolved"] === "No"}
              onChange={() => handleOptionChange("dispute_resolved", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.dispute_resolved}</FormHelperText>
      </FormControl>

      {/* Question 12 */}
      <FormControl error={!!validationErrors.bonus_paid !== "" ? true : false}>
        <div
          className="row"
          style={{ alignItems: "center", marginLeft: "2px" }}
        >
          <div className="col-md-8">
            <p>Is bonus paid to workers as per the payment of bonus act ?</p>
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["bonus_paid"] === "Yes"}
              onChange={() => handleOptionChange("bonus_paid", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className="col-md-2">
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["bonus_paid"] === "No"}
              onChange={() => handleOptionChange("bonus_paid", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.bonus_paid}</FormHelperText>
      </FormControl>
    </>
  );
};
