
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";



export const BeneficiaryStakeHoldersMaster = () =>{ 
    const dispatch = useDispatch();
    const mgBeneficiaryMaster = useSelector(state => state.mgBeneficiaryMaster);

    const handleFieldWomenLed = (event) => {
        dispatch(setInputFieldData({ fieldName: 'women_led', value:event.target.value }));
   }
   const handleFieldMale = (event) => {
    dispatch(setInputFieldData({ fieldName: 'male', value:event.target.value }));
    }
    const handleFieldFemale = (event) => {
        dispatch(setInputFieldData({ fieldName: 'female', value:event.target.value }));
    }
    const handleFieldShareholders = (event) => {
        dispatch(setInputFieldData({ fieldName: 'shareholders', value:event.target.value }));
    }
    const handleFieldOthers = (event) => {
        dispatch(setInputFieldData({ fieldName: 'others', value:event.target.value }));
    }
    const handleFieldSC = (event) => {
        dispatch(setInputFieldData({ fieldName: 'sc', value:event.target.value }));
    }
    const handleFieldST = (event) => {
        dispatch(setInputFieldData({ fieldName: 'st', value:event.target.value }));
    }
   const handleResetFocus = () =>{
    dispatch(resetValidation())
   }
    return(<>   
    {/* <Online> */}
        <div className='tab-pane'>
            <form class="row g-3">

                {/* Vulnerable groups Women Led */}
                {/* <div className='primaryTableLabel'>
                    <label htmlFor="women_led">Vulnerable groups Women Led</label>
                    <input type="text" name="women_led" 
                        value={mgBeneficiaryMaster.women_led}
                        onChange={handleFieldWomenLed} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> */}
            
                <div className='primaryTableLabel'>
                    <label htmlFor="women_led">Vulnerable groups Women Led</label>
                    {/* <input type="text" name="women_led" 
                        value={mgBeneficiaryMaster.women_led}
                        onChange={handleFieldWomenLed} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    /> */}
                     <select value={mgBeneficiaryMaster.women_led} onChange={handleFieldWomenLed} style={{width:"30%", height: "60%"}}>
                        <option value="Non">None</option>
                        <option value="Women Led">Women Led</option>
                        <option value="Women Owned">Women Owned</option>
                    </select>
                </div>

                {/* Male */}
                <div className='primaryTableLabel'>
                    <label htmlFor="male">Male</label>
                    <input type="number" name="male" 
                        value={mgBeneficiaryMaster.male}
                        onChange={handleFieldMale} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                                    
                {/* Female */}
                <div className='primaryTableLabel'>
                    <label htmlFor="female">Female</label>
                    <input type="number" name="female" 
                        value={mgBeneficiaryMaster.female}
                        onChange={handleFieldFemale} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                                                    
                {/* Shareholders */}
                <div className='primaryTableLabel'>
                    <label htmlFor="Shareholders">Shareholders</label>
                    <input type="number" name="Shareholders" 
                        value={mgBeneficiaryMaster.shareholders}
                        onChange={handleFieldShareholders} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                                                             
                {/* Others */}
                <div className='primaryTableLabel'>
                    <label htmlFor="Others">Others</label>
                    <input type="number" name="Others" 
                        value={mgBeneficiaryMaster.others}
                        onChange={handleFieldOthers} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

                {/* st */}
                <div className='primaryTableLabel'>
                    <label htmlFor="st">ST</label>
                    <input type="number" name="st" 
                        value={mgBeneficiaryMaster.st}
                        onChange={handleFieldST} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

                 {/* sc */}
                 <div className='primaryTableLabel'>
                    <label htmlFor="sc">SC</label>
                    <input type="number" name="sc" 
                        value={mgBeneficiaryMaster.sc}
                        onChange={handleFieldSC} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
            </form>
        </div>     
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

