import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  division_id: '',
  beneficiary_id: [],
  magnet_crop_id: [],
  from_date: null,
  to_date: null,
  location_address: '',
  country_id: '',
  state_id: '',
  district_id: '',

  purpose: '',
  sc_male: '',
  sc_female: '',
  sc_total: '',

  st_male: '',
  st_female: '',
  st_total: '',

  pwd_male: '',
  pwd_female: '',
  pwd_total: '',

  obc_minority_open_male: '',
  obc_minority_open_female: '',
  obc_minority_open_total: '',

  participants_male: '',
  participants_female: '',
  participants_total: '',

  validationErrors: {
    division_id: '',
    beneficiary_id: '',
    magnet_crop_id: '',
    from_date: '',
    to_date: '',
    location_address: '',
    country_id: '',
    state_id: '',
    district_id: '',

    purpose: '',
    sc_male: '',
    sc_female: '',
    sc_total: '',

    st_male: '',
    st_female: '',
    st_total: '',

    pwd_male: '',
    pwd_female: '',
    pwd_total: '',

    obc_minority_open_male: '',
    obc_minority_open_female: '',
    obc_minority_open_total: '',
  }
};

const StudyTourFarmerSlice = createSlice({
  name: 'StudyTourFarmerSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },

    setTotalSC: (state, action) => {
        state.sc_total = action.payload;
      },
    setTotalST: (state, action) => {
        state.st_total = action.payload;
      },
    setTotalPWD: (state, action) => {
        state.pwd_total = action.payload;
      },
    
    setTotalOBC: (state, action) => {
        state.obc_minority_open_total= action.payload;
      },
    setTotalParticipants: (state, action) => {
        state.participants_total= action.payload;
      },
    
      setTotalFemaleParticipants: (state, action) => {
        state.participants_female= action.payload;
      },
    
    setTotalMaleParticipants: (state, action) => {
        state.participants_male= action.payload;
      },

    // Add other reducers for different input fields if needed
    updateApiDataToFarmer: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataFarmer: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsFarmer: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsFarmer, resetValidation, setInputFieldData, updateApiDataToFarmer, resetDataFarmer, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants, setTotalFemaleParticipants, setTotalMaleParticipants  } = StudyTourFarmerSlice.actions;

export default StudyTourFarmerSlice.reducer;
