import { Offline, Online } from "react-detect-offline"
import { Header } from "../components/Header"
import { LeftNavigation } from "../components/LeftNavigation"
import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import { UserListing } from "../components/User/UserListing"
import { RoleListing } from "../components/User/RoleListing"

export const RoleList = () =>{
    return(<>
        <Header/>
        <LeftNavigation/>
        <main id="main" class="main">
          <section class="section">
            <div class="row">
              <div
                id="exTab3"
                class="contain"
                style={{
                  width: "83%",
                  position: "absolute",
                  right: "0rem",
                  top: "3.5rem",
                }}
              >
                <div
                  class="card"
                  style={{ height: "40rem", backgroundColor: "#F8F8F8" }}
                >
                  {/* Add Beneficiary/Back */}
                  <div style={{display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "1rem 2rem",
                          border: "1.5px solid rgb(232, 232, 232)"
                      }}>
                    <div>
                        <span style={{fontWeight: "bold", fontSize: "2rem"}}>Role List</span>
                    </div>
                    <div>
                        <a href="./masterdata" style={{color: "#4e2683", fontWeight: "bold", }}>
                            <i className="fa-solid fa-arrow-left"
                            style={{
                                marginTop: "0.5rem",
                                marginBottom: "2rem",
                                marginRight: "0.5rem",
                                color: "black",
                            }}
                            ></i>
                            Back
                        </a>
                        <button
                            style={{
                            backgroundColor: "#4e2683",
                            fontWeight: "bold",
                            color: "white",
                            padding: "5px 25px",
                            marginLeft: "15px"
                            }}
                        >
                            <Link to="/addRole" style={{ color: "white" }}>
                            Add Role
                            </Link>
                        </button>
                    </div>
                  </div>
                  {/* Listing Table */}
                  <RoleListing />
                </div>
              </div>
            </div>
          </section>
        </main>
      
    </>)
}