import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { OutputTableEmpDivisionPerformance} from "../components/OutputTwo/OutputTableEmpConcDivisionPerformance";
import { OutputTableEmpOperDivisionPerformance} from "../components/OutputTwo/OutputTableEmpOperDivisionPerformance";
import { OutputTableEmpOperFPOPerformance} from "../components/OutputTwo/OutputTableEmpOperFPOPerformance";
import { OutputTableEmpConcFPOPerformance } from "../components/OutputTwo/OutputTableEmpConcFPOPerformance";
import { OutputTableGESI } from '../components/OutputTwo/OutputTableGESI';
import { OutputTableSafeguards } from '../components/OutputTwo/OutputTableSafeguards';
import { OutputTable } from "../components/OutputTwo/OutputTablePhyOverall";
import { OutputTableFPOVCOPerformance } from "../components/OutputTwo/OutputTablePhyFPOVCOPerformance";
import { OutputTablePerformanceComponent } from "../components/OutputTwo/OutputTablePhyPerformanceComponent";
import { ErrorBoundary } from "react-error-boundary";
import { ServerPage } from "./ErrorPage/ServerPage";

export const OutputPage2 = ({divi, currentQuter, finiYear, currentFiniYear}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [mainTabValue, setMainTabValue] = useState('0');
  const [subTabValue, setSubTabValue] = useState('1');
  const [value, setValue] = useState("1");
  const [permission, setPermission] = useState([])

  const [divisions, setDivisions] = useState([])
  const [currentQuarter, setCurrentQuarter] = useState("")
  const [financialYear, setFinancialYear] = useState([])
  const [currentFinancialYear, setCurrentFinancialYear] = useState("")

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };
  const handleChangeTab = (event,newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    async function getData (){
      setFinancialYear(finiYear)
      setCurrentFinancialYear(currentFiniYear)
      setCurrentQuarter(currentQuter)
      setDivisions(divi);
    }
    getData()
    const storedArrayAsString = localStorage.getItem('permission');
    setPermission(storedArrayAsString)
  },[divi, currentQuter, finiYear, currentFiniYear])
  return (
    <>
    <ErrorBoundary FallbackComponent={<ServerPage/>}>
    {/* <Online> */}
      <section class="section">
          <div class="row">
            <div id="exTab3" class="contain" style={{width: "80%", position: "absolute", right: "0rem", top: "3.5rem"}}>
              <div class="card">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={mainTabValue}>
                    <TabList variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" onChange={handleMainTabChange}>
                    {
                        permission.includes("Project-Prog-Outp-Two-view") ? (
                          <Tab label="Project Progress" value="0" className={`${mainTabValue === '0' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Job-Gen-Dur-Construction-Outp-Two-view") ? (
                          <Tab label="Job Generated During Construction" value="1" className={`${mainTabValue === '1' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Job-Gen-Dur-Operation-Outp-Two-view") ? (
                          <Tab label="Job Generated During Operation" value="2" className={`${mainTabValue === '2' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Gesi-Outp-Two-view") ? (
                          <Tab label="GESI" value="3" className={`${mainTabValue === '3' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Safeguard-Outp-Two-view") ? (
                          <Tab label="Safeguard" value="4" className={`${mainTabValue === '4' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                     
                    </TabList>
                  </TabContext>
                  <br/>
                  {/* Physical Finance Tab */}
                  {
                    permission.includes("Project-Prog-Outp-Two-view") && mainTabValue === '0' && (
                      <TabContext value={subTabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                            <Tab label="Component-Wise Overall Performance" value="1" />
                            <Tab label="Beneficiaries Performance" value="2" />
                            <Tab label="Component-Wise Beneficiaries Performance" value="3" />
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <OutputTable setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                        </TabPanel>
                        <TabPanel value="2">
                          <OutputTableFPOVCOPerformance setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                        </TabPanel>
                        <TabPanel value="3">
                          <OutputTablePerformanceComponent setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                        </TabPanel>
                      </TabContext>
                    )
                  }


                  {/* Job Generation during Construction */}
                  {permission.includes("Job-Gen-Dur-Construction-Outp-Two-view") && mainTabValue === '1' && (
                    <TabContext value={subTabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                          <Tab label="Division Performance" value="1" />
                          <Tab label="Beneficiaries Performance" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <OutputTableEmpDivisionPerformance setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      <TabPanel value="2">
                        <OutputTableEmpConcFPOPerformance setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

                  {/* Job Generation during Operation */}
                  {permission.includes("Job-Gen-Dur-Operation-Outp-Two-view") && mainTabValue === '2' && (
                    <TabContext value={subTabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                        <Tab label="Division Performance " value="1" />
                          <Tab label="Beneficiaries Performance " value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <OutputTableEmpOperDivisionPerformance setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      <TabPanel value="2">
                        <OutputTableEmpOperFPOPerformance setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      
                     
                    </TabContext>
                  )}

                    {/*GESI*/}
                  {permission.includes("Gesi-Outp-Two-view") && mainTabValue === '3' && (
                    <TabContext>
                      <TabPanel>
                        <OutputTableGESI setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

                    {/* Safeguard  */}
                  {permission.includes("Safeguard-Outp-Two-view") && mainTabValue === '4' && (
                    <TabContext value="4">
                      <TabPanel value="4">
                        <OutputTableSafeguards setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      
                    </TabContext>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
        </ErrorBoundary>
       
    </>
  );
};
