import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ///////////

  // Beneficiary Details

  applicant_id: "",
  division_id: "",
  district_id: "",
  taluka_id: "",
  status: "",
  eoi: "",
  entity_type_id: "",
  pan_of_firm: "",
  udyam_reg_number: "",
  fssai_application_number: "",
  incorporation_date: "",
  targeted_magnet_crops: [],
  finished_products: "",
  reg_number_of_applicant: "",

  //Contact Information
  key_contact_person: "",
  email: "",
  mobile_number: "",

  // Fiancials
  paid_up_capital: "",
  turnover: "",
  net_worth: "",
  cash_and_bank: "",
  reserves_and_surplus: "",
  sales_fy1: "",
  variable_cost_fy1: "",
  contribution_fy1: "",
  contribution_pct_fy1: "",
  sales_fy5: "",
  variable_cost_fy5: "",
  contribution_fy5: "",
  contribution_pct_fy5: "",

  // Stakeholders
  women_led: "",
  male: "",
  female: "",
  shareholders: "",
  others: "",
  sc: "",
  st: "",

  // Assets
  land_area: "",
  latitude: "",
  longitude: "",
  land_ownership_status: "",
  lease_period_years: "",
  // Facilities
  cold_storage: "",
  pack_house_tpd: "",
  secondary_processing_tpd: "",
  tertiary_processing_tpd: "",
  key_components: "",
  // Grand Details
  lending_bank_name: "",
  loan_type_id: "",
  financial_year_approval_date: "",
  tranch_released_id: "",
  processing: [],
  due_date_2: "",
  due_date_3: "",
  actual_date_1: "",
  actual_date_2: "",
  actual_date_3: "",
  // table
  total_project_cost: "",
  promoter_equity: "",
  bank_term_loan: "",
  matching_grant: "",
  mg_1st_tranche: "",
  mg_2nd_tranche: "",
  mg_3rd_tranche: "",
  component: [],

  // Validation
  validationErrors: {
    // Beneficiary Details
    applicant_id: "",
    division_id: "",
    district_id: "",
    taluka_id: "",
    status: "",
    eoi: "",
    entity_type_id: "",
    pan_of_firm: "",
    udyam_reg_number: "",
    fssai_application_number: "",
    incorporation_date: null,
    targeted_magnet_crops: [],
    finished_products: "",
    reg_number_of_applicant: "",

    //Contact Information
    key_contact_person: "",
    email: "",
    mobile_number: "",

    // Fiancials
    paid_up_capital: "",
    turnover: "",
    net_worth: "",
    cash_and_bank: "",
    reserves_and_surplus: "",
    sales_fy1: "",
    variable_cost_fy1: "",
    contribution_fy1: "",
    contribution_pct_fy1: "",
    sales_fy5: "",
    variable_cost_fy5: "",
    contribution_fy5: "",
    contribution_pct_fy5: "",

    // Stakeholders
    women_led: "",
    male: "",
    female: "",
    shareholders: "",
    others: "",
    sc: "",
    st: "",

    // Assets
    land_area: "",
    latitude: "",
    longitude: "",
    land_ownership_status: "",
    lease_period_years: "",
    // Facilities
    cold_storage: "",
    pack_house_tpd: "",
    secondary_processing_tpd: "",
    tertiary_processing_tpd: "",
    key_components: "",
    // Grand Details
    lending_bank_name: "",
    loan_type_id: "",
    financial_year_approval_date: null,
    tranch_released_id: "",
    processing: [],
    due_date_2: null,
    due_date_3: null,
    actual_date_2: null,
    actual_date_3: null,
    // table
    total_project_cost: "",
    promoter_equity: "",
    bank_term_loan: "",
    matching_grant: "",
    mg_1st_tranche: "",
    mg_2nd_tranche: "",
    mg_3rd_tranche: "",
  },
};

const MGBeneficiaryMasterSlice = createSlice({
  name: "MGBeneficiaryMasterSlice",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataMGBeneficiaryMaster: (state, action) => {
      const payload = action.payload;
      console.log(payload, "Payload");
      // Update components array if present in payload
      if (payload.component !== undefined) {
        state.component = payload.component;
      }

      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          // Skip updating components as we've already handled it
          if (key !== "components") {
            state[key] = payload[key];
          }
        }
      }
    },

    setInputActivityMG: (state, action) => {
      // console.log(action.payload,"vcbfbgfgbgf")
      // state.components = {...components, action.payload};
      state.components = { ...state.components, someProperty: action.payload };
    },

    setInputActivity: (state, action) => {
      state.component = action.payload;
    },

    resetDataMGBeneficiaryMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsMGBeneficiaryMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setInputActivityMG,
  setInputActivity,
  setValidationErrorsMGBeneficiaryMaster,
  resetValidation,
  setInputFieldData,
  updateApiDataMGBeneficiaryMaster,
  resetDataMGBeneficiaryMaster,
} = MGBeneficiaryMasterSlice.actions;

export default MGBeneficiaryMasterSlice.reducer;
