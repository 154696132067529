import React, { useState } from "react";
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { MSAMBBeneficiaryDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MSAMBMaster/MSAMBBeneficary";
import { MSAMBContactInformation } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MSAMBMaster/MSAMBContact";
import { MSAMBFacility } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MSAMBMaster/MSAMBFacility";
import "../../../../assets/css/masterdata.css";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import axios from "axios";
import {
  setInputFieldData,
  setValidationErrorsMSAMBMaster,
  updateApiDataMSAMBMaster,
  resetDataMsambMaster,
} from "../../../../redux/slice/MSAMBMaster/MSAMBMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

export const AddBeneficiaryMSAMBMaster = ({ isEditing }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dd = useSelector(setInputFieldData);
  const dispatch = useDispatch();
  console.log("Redux Store State after setting magnet_crop_id:", dd);

  const [BeneficiaryFlagEdit, setBeneficiaryFlagEdit] = useState(isEditing);
  const location = useLocation();
  const id = location.state;

  const [BeneficiaryEdit, setBeneficiaryEdit] = useState("");
  const [MsambFacility, setMsambFacility] = useState([]);
  const [WorkPackage, setWorkPackage] = useState([]);
  const [Division, setDivision] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [magnetCrop, setMagentCrop] = useState([]);
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNextForm = () => {
    const newValue = parseInt(value) + 1;
    setValue(String(newValue));
  };

  const handleBackForm = () => {
    const newValue = parseInt(value) - 1;
    setValue(String(newValue));
  };

  const handleMsambFacilityChange = async (event) => {
    const selectedMsambFacilityId = event.target.value;

    // Reset the form data every time the msamb_facility_id changes
    dispatch(resetDataMsambMaster());
    setBeneficiaryFlagEdit(false); // Set to false to ensure it's not in edit mode

    // Set the selected msamb_facility_id in the form data
    dispatch(
      setInputFieldData({
        fieldName: "msamb_facility_id",
        value: selectedMsambFacilityId,
      })
    );

    // Check if data already exists for the selected msamb_facility_id
    const existingDataCheck = await axios.get(
      `${apiUrl}api/primary-data-input-three-edit?msamb_facility_id=${selectedMsambFacilityId}`,
      getConfigWithToken()
    );

    console.log("Existing Data Check:", existingDataCheck.data);

    if (existingDataCheck.data && existingDataCheck.data.status) {
      // Data already exists, set it for pre-filling the form
      const existingData = existingDataCheck.data.data;

      if (existingData && existingData.length > 0) {
        const existingDta = existingData[0];
        console.log("Existing Data for Editing:", existingDta);

        // Pre-fill the form with existing data
        dispatch(updateApiDataMSAMBMaster(existingDta));

        // Set the BeneficiaryFlagEdit to true
        setBeneficiaryFlagEdit(true);

        // Parse magnet_crop_id as an array if it's not already an array
        let parsedMagnetCropId = [];
        if (existingDta.magnet_crop_id) {
          // Parse the stringified JSON array into a JavaScript object
          const magnetCropData = JSON.parse(existingDta.magnet_crop_id);
          parsedMagnetCropId = Array.isArray(magnetCropData)
            ? magnetCropData
            : [magnetCropData];
        }

        // Set the values for magnet_crop_id
        dispatch(
          setInputFieldData({
            fieldName: "magnet_crop_id",
            value: parsedMagnetCropId.map((item) => item.id), 
          })
        );
      }
    }
  };

  const handleMSAMBSaveUpdate = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["msambMaster"];
      const errorMessages = {
        msamb_facility_id: "Please select MSAMB Facility",
        division_id: "Please select Division",
        district_id: "Please select District",
        taluka_id: "Please select Taluka",
        work_package_id: "Please select Work Package",
        male: "Please enter no.of male",
        female: "Please enter no.of female",
        shareholders: "Please enter no.of shareholders",
        magnet_crop_id: "Please select MAGNET Crop",
        contract_no: "Please enter Contract number",
        component: "Please enter Components as per accepted Tender",
        area: "Please enter the Area",
        supplier_contractor_name: "Please enter Supplier/Contractor Name",
        contract_amount: "Please enter Contract Amount",
        contract_award_date: "Please enter Contract Award Date",
        completion_date: "Please enter Completion Date",
        extension_date: "Please Extension Date",
        contract_extension: "Please enter Contract Extension",
        price_increase: "Please enter Price Increase",
        increased_amount: "Please enter Increased Amount",
        total_disbursed_amount: "Please enter Total Disbursed Amount",
      };

      let isEmpty = false;

      for (const field in errorMessages) {
        const value = formData[field];

        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = "Required";
          isEmpty = true;
        }
      }

      dispatch(setValidationErrorsMSAMBMaster(errors));

      if (isEmpty) {
        Swal.fire("All fields are required", "", "warning");
      }

      return !isEmpty;
    };

    try {
      const formData = dd["payload"]["msambMaster"];
      const jsonMagnetCrop = Array.isArray(formData["magnet_crop_id"])
        ? formData["magnet_crop_id"]
            .map((id) => {
              const matchingObject = magnetCrop.find((item) => item.id === id);
              if (matchingObject) {
                return {
                  id: matchingObject.id,
                  name: matchingObject.crop,
                };
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];

      const resultMagnetCrop = { data: jsonMagnetCrop };
      const obj = {
        ...formData,
        magnet_crop_id: JSON.stringify(resultMagnetCrop["data"]),
      };

      // Check if data already exists for the given msamb_facility_id
      const existingDataCheck = await axios.get(
        `${apiUrl}api/primary-data-input-three-edit?msamb_facility_id=${formData.msamb_facility_id}`,
        getConfigWithToken()
      );

      if (existingDataCheck.data && existingDataCheck.data.status) {
        // Data already exists, set it for pre-filling the form
        const existingData = existingDataCheck.data.data;

        if (existingData && existingData.length > 0) {
          const existingDta = existingData[0];
          console.log("Existing Data for Editing:", existingDta);

          // Pre-fill the form with existing data
          dispatch(updateApiDataMSAMBMaster(existingDta));

          // Set the BeneficiaryFlagEdit to true
          setBeneficiaryFlagEdit(true);

          // Perform update operation
          const updateEndpoint = `${apiUrl}api/primary-data-input-three-update?id=${existingDta.id}`;
          console.log("Update URL:", updateEndpoint);
          console.log("Update Data:", obj);

          const checkEdit = await axios.post(
            updateEndpoint,
            obj,
            getConfigWithToken()
          );

          if (checkEdit.data["status"] === true) {
            Swal.fire("Edited Successfully", "", "success");
            dispatch(updateApiDataMSAMBMaster(checkEdit.data["data"][0]));
          } else if (checkEdit.data["message"] === "Record Not Found") {
            Swal.fire(
              "Record Not Found",
              "The record you are trying to edit/update does not exist.",
              "warning"
            );
          } else {
            Swal.fire("Error in editing", "", "warning");
          }

          return; // Exit the function to avoid the create operation
        }
      }

      // Data does not exist, proceed with the create operation
      const checkCreate = await axios.post(
        `${apiUrl}api/primary-data-input-three-create`,
        obj,
        getConfigWithToken()
      );

      if (checkCreate.data["status"] === true) {
        Swal.fire(`${checkCreate.data["message"]}`, "", "success");
        dispatch(resetDataMsambMaster());
      } else {
        Swal.fire(`${checkCreate.data["message"]}`, "", "warning");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const MSAMBFacilityData = async (id) => {
    try {
      const msamb = await axios.get(
        `${apiUrl}api/getMsambFacility?all=1`,
        getConfigWithToken()
      );
      const workpack = await axios.get(
        `${apiUrl}api/work-package-listing?all=1`,
        getConfigWithToken()
      );
      const division = await axios.get(
        `${apiUrl}api/getAllDivisions?all=1`,
        getConfigWithToken()
      );
      const district = await axios.get(
        `${apiUrl}api/get-all-district?all=1`,
        getConfigWithToken()
      );
      const taluka = await axios.get(
        `${apiUrl}api/get-taluka-by-district?district_id=${id}`,
        getConfigWithToken()
      );
      const magnetcrop = await axios.get(
        `${apiUrl}api/getAllCrop?all=1`,
        getConfigWithToken()
      );

      const msambData = msamb.data["data"].map((e) => ({
        id: e["msamb_facility_id"],
        msamb_facility_name: e["msamb_facility_name"],
      }));
      const workpackData = workpack.data["data"].map((e) => ({
        id: e["id"],
        work_package: e["work_package"],
      }));
      const divisionData = division.data["data"].map((e) => ({
        id: e["id"],
        divisions: e["divisions"],
      }));
      const districtData = district.data["data"].map((e) => ({
        id: e["id"],
        district: e["district"],
      }));
      const talukaData = taluka.data["data"].map((e) => ({
        id: e["id"],
        taluka: e["taluka"],
      }));
      const magnetcropData = magnetcrop.data["data"].map((e) => ({
        id: e["id"],
        crop: e["crop"],
      }));

      setMsambFacility(msambData);
      setWorkPackage(workpackData);
      setDivision(divisionData);
      setDistrict(districtData);
      setTaluka(talukaData);
      setMagentCrop(magnetcropData);

      if (id && id.id) {
        const applicantId = id.id;
        console.log("Selected Applicant ID:", applicantId);
        setBeneficiaryFlagEdit(true);

        // Fetch existing data for editing
        const editDataResponse = await axios.get(
          `${apiUrl}api/primary-data-input-three-edit?msamb_facility_id=${applicantId}`,
          getConfigWithToken()
        );

        console.log("Edit Data Response:", editDataResponse.data);

        if (editDataResponse.data && editDataResponse.data.status) {
          const editData = editDataResponse.data.data;

          if (editData && editData.length > 0) {
            const editDta = editData[0];
            console.log("Data for Editing:", editDta);

            // Set the data to Redux store or local state if needed
            dispatch(updateApiDataMSAMBMaster(editDta));

            // Set the MSAMBFlagEdit to true
            setBeneficiaryEdit(true);

            // Set the values for District, Taluka, and Magnet Crop
            dispatch(
              setInputFieldData({
                fieldName: "district_id",
                value: editDta.district_id || "",
              })
            );
            dispatch(
              setInputFieldData({
                fieldName: "taluka_id",
                value: editDta.taluka_id || "",
              })
            );
            dispatch(
              setInputFieldData({
                fieldName: "magnet_crop_id",
                value: Array.isArray(editDta.magnet_crop_id)
                  ? editDta.magnet_crop_id
                  : [editDta.magnet_crop_id],
              })
            );
          } else {
            console.error("No data found for editing");
          }
        } else {
          console.error("Invalid request:", editDataResponse.data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const determineEditMode = () => {
    // Check if there is an id (assuming an id indicates edit mode)
    const isEditMode = Boolean(id);
    setBeneficiaryFlagEdit(isEditMode);
  };

  // Call MSAMBFacilityData on component mount
  useEffect(() => {
    MSAMBFacilityData(id);
    determineEditMode();
  }, [id]);

  return (
    <>
      <Header />
      <LeftNavigation />
      {/* <Online> */}
        <main id="main" className="main">
          <section className="section">
            <div className="row">
              <div id="exTab3" className="contain">
                <div
                  className="cardMaster"
                  id=""
                  style={{ height: "1300px", marginTop: "1rem" }}
                >
                  <form class="row g-3">
                    <Box
                      className="your-component"
                      sx={{ typography: "body1" }}
                    >
                      <div className="words-with-lines">
                        <span>MSAMB - Master</span>
                      </div>
                      <div className="msambstyle">
                        <a href="./MSAMB-Master">
                          <i className="fa-solid fa-arrow-left"></i>Back
                        </a>
                        <button
                          type="submit"
                          className="save-btn"
                          onClick={handleMSAMBSaveUpdate}
                        >
                          Save
                        </button>
                        <button className="dropdown-btn">
                          <i className="fa-solid fa-caret-down"></i>
                        </button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        width: "92%",
                        height: "500px",
                        marginLeft: "5rem",
                        marginTop: "3.5rem",
                        typography: "body1",
                      }}
                    >
                      <TabContext value={value}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            marginBottom: "2rem",
                          }}
                        >
                          <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            value={value}
                            onChange={handleChange}
                          >
                            <Tab
                              label="MSAMB Master Details"
                              value="0"
                              className={`${
                                value === "0" ? "activeClickCSS" : ""
                              }`}
                            />
                            <Tab
                              label="Contract Information"
                              value="1"
                              className={`${
                                value === "1" ? "activeClickCSS" : ""
                              }`}
                            />
                            <Tab
                              label="Facilities"
                              value="2"
                              className={`${
                                value === "2" ? "activeClickCSS" : ""
                              }`}
                            />
                          </Tabs>
                        </Box>

                        {/* MSAMB Deatils */}
                        <TabPanel value="0">
                          <MSAMBBeneficiaryDetails
                            MsambFacility={MsambFacility}
                            WorkPackage={WorkPackage}
                            Division={Division}
                            District={District}
                            Taluka={Taluka}
                            MagnetCrop={magnetCrop}
                            initialValues={BeneficiaryEdit}
                            BeneficiaryFlagEdit={BeneficiaryFlagEdit}
                            onMsambFacilityChange={handleMsambFacilityChange}
                          />
                          <div
                            style={{ textAlign: "right", marginTop: "2rem" }}
                          >
                            <button
                              type="button"
                              onClick={handleNextForm}
                              className="btn submitbtn"
                            >
                              Next
                              <i
                                className="fa-solid fa-angles-right"
                                style={{ color: "white", marginLeft: "0.5rem" }}
                              ></i>
                            </button>
                          </div>
                        </TabPanel>

                        {/* Contact Information */}
                        <TabPanel value="1">
                          <MSAMBContactInformation />
                          <div
                            style={{ textAlign: "right", marginTop: "2rem" }}
                          >
                            <button
                              type="button"
                              onClick={handleBackForm}
                              className="btn submitbtn"
                            >
                              <i
                                className="fa-solid fa-angles-left"
                                style={{
                                  color: "white",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                              Previous
                            </button>
                            <button
                              type="button"
                              onClick={handleNextForm}
                              className="btn submitbtn"
                            >
                              Next
                              <i
                                className="fa-solid fa-angles-right"
                                style={{ color: "white", marginLeft: "0.5rem" }}
                              ></i>
                            </button>
                          </div>
                        </TabPanel>

                        {/* Facility */}
                        <TabPanel value="2">
                          <MSAMBFacility />
                          <div
                            style={{ textAlign: "right", marginTop: "2rem" }}
                          >
                            <button
                              type="button"
                              onClick={handleBackForm}
                              className="btn submitbtn"
                            >
                              <i
                                className="fa-solid fa-angles-left"
                                style={{
                                  color: "white",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                              Previous
                            </button>
                          </div>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
    </>
  );
};
