import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { InputField } from "../../InputField";
import { useEffect, useState } from "react";
import {
  setInputFieldData,
  resetValidation,
  setTotalSC,
  setTotalST,
  setTotalPWD,
  setTotalOBC,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/ValueChain/ValueChainSlice";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/inputForm.css";

export function WomenLedtraining() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const valueChain = useSelector((state) => state.valueChain);
  const [taluka, setTaluka] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    valueChain();
  }, []);

  // Total of SC
  const totalSC =
    parseInt(valueChain.sc_male === "" ? 0 : valueChain.sc_male) +
    parseInt(valueChain.sc_female === "" ? 0 : valueChain.sc_female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(valueChain.st_male === "" ? 0 : valueChain.st_male) +
    parseInt(valueChain.st_female === "" ? 0 : valueChain.st_female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(valueChain.pwd_male === "" ? 0 : valueChain.pwd_male) +
    parseInt(valueChain.pwd_female === "" ? 0 : valueChain.pwd_female);
  dispatch(setTotalPWD(totalPWD));
  
  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      valueChain.obc_minority_open_male === ""
        ? 0
        : valueChain.obc_minority_open_male
    ) +
    parseInt(
      valueChain.obc_minority_open_female === ""
        ? 0
        : valueChain.obc_minority_open_female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));

  // Total of OBC/Minority/Open
  const totalParticipants =
    parseInt(
      valueChain.participants_male === "" ? 0 : valueChain.participants_male
    ) +
    parseInt(
      valueChain.participants_female === "" ? 0 : valueChain.participants_female
    );
  dispatch(setTotalParticipants(totalParticipants));

  const handleFieldNoOfBeneAttend = (value) => {
    dispatch(setInputFieldData({ fieldName: "beneficiary_attended", value }));
  };

  
  const handleFieldRemarks = (value) => {
    dispatch(
      setInputFieldData({
        fieldName: "action_points_recommendation_made",
        value,
      })
    );
  };

  const handleFieldResourcePerson = (value) => {
    dispatch(setInputFieldData({ fieldName: "resource_person", value }));
  };

  const handleFieldLocationAddress = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handleOptionstaluka = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: event.target.value,
      })
    );
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
       
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <Box sx={{ minWidth: "100%" }}>
          <FormControl
            fullWidth
            error={
              valueChain.validationErrors.location_taluka !== "" ? true : false
            }
          >
            <InputLabel htmlFor="demo-simple-select-label">
              Location (Taluka)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={valueChain.location_taluka}
              label="Location (Taluka)"
              onChange={handleOptionstaluka}
            >
              {taluka.map((e, key) => (
                <MenuItem value={e.id} key={key}>
                  {e.taluka}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: "#d32f2f" }}>
            {valueChain.validationErrors.location_taluka}
          </FormHelperText>
        </Box>
      </div>
      <InputField
        label="Location(Address)"
        type="text"
        value={valueChain.location_address}
        onChange={handleFieldLocationAddress}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.location_address}
        helperText={valueChain.validationErrors.location_address}
      />
      <InputField
        label="Resource Person"
        type="text"
        value={valueChain.resource_person}
        onChange={handleFieldResourcePerson}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.resource_person}
        helperText={valueChain.validationErrors.resource_person}
      />
      <InputField
        label="No. of Beneficiaries attended"
        type="number"
        value={valueChain.beneficiary_attended}
        onChange={handleFieldNoOfBeneAttend}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.beneficiary_attended}
        helperText={valueChain.validationErrors.beneficiary_attended}
      />
      <div className="word-with-line"></div>

      <div className="col-md-3 input1Heading">
        <span>SC</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={valueChain.sc_male}
        onChange={(value)=>handleInputChange("sc_male",value)}
        onFocus={handleResetFocus}
        
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={valueChain.sc_female}
        onChange={(value)=>handleInputChange("sc_female",value)}
        onFocus={handleResetFocus}
        
      />
      <InputField
        col="3"
        label="Total SC"
        type="number"
        value={valueChain.sc_total}
        readOnly={true}
      />

      <div className="col-md-3 input1Heading">
        <span>ST</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={valueChain.st_male}
        onChange={(value)=>handleInputChange("st_male",value)}
        onFocus={handleResetFocus}
       
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={valueChain.st_female}
        onChange={(value)=>handleInputChange("st_female",value)}
        onFocus={handleResetFocus}
       
      />
      <InputField
        col="3"
        label="Total ST"
        type="number"
        value={valueChain.st_total}
        readOnly={true}
      />

      <div className="col-md-3 input1Heading">
        <span>General (Open, OBC and others)</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={valueChain.obc_minority_open_male}
        onChange={(value)=>handleInputChange("obc_minority_open_male",value)}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.obc_minority_open_male}
        helperText={valueChain.validationErrors.obc_minority_open_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={valueChain.obc_minority_open_female}
        onChange={(value)=>handleInputChange("obc_minority_open_female",value)}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.obc_minority_open_female}
        helperText={valueChain.validationErrors.obc_minority_open_female}
      />
      <InputField
        col="3"
        label="Total OBC/ Minority/ Open"
        type="number"
        value={valueChain.obc_minority_open_total}
        readOnly={true}
      />

      <div className="col-md-3 input1Heading">
        <span>Participants</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={valueChain.participants_male}
        onChange={(value)=>handleInputChange("participants_male",value)}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.participants_male}
        helperText={valueChain.validationErrors.participants_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={valueChain.participants_female}
        onChange={(value)=>handleInputChange("participants_female",value)}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.participants_female}
        helperText={valueChain.validationErrors.participants_female}
      />
      <InputField
        col="3"
        label="Total Participants"
        type="number"
        value={valueChain.participants_total}
        readOnly={true}
      />

      <div style={{ marginTop: "3rem" }} class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>PWD</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={valueChain.pwd_male}
          onChange={(value)=>handleInputChange("pwd_male",value)}
          onFocus={handleResetFocus}
         
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={valueChain.pwd_female}
          onChange={(value)=>handleInputChange("pwd_female",value)}
          onFocus={handleResetFocus}
        
        />
        <InputField
          col="3"
          label="Total PWD"
          type="number"
          value={valueChain.pwd_total}
          readOnly={true}
        />
      </div>
      <InputField
        col="6"
        label="Action points/ Recommendation made"
        type="text"
        value={valueChain.action_points_recommendation_made}
        onChange={handleFieldRemarks}
        onFocus={handleResetFocus}
        error={!!valueChain.validationErrors.action_points_recommendation_made}
        helperText={
          valueChain.validationErrors.action_points_recommendation_made
        }
      />
    </>
  );
}
