import {
  resetValidation,
  setInputFieldData,
} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import React, { useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "../../../../assets/css/masterdata.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { getConfigWithToken } from "../../../../utils/Config/Config";

export const BeneficiaryDetailsMaster = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { Applicant } = props;

  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const dispatch = useDispatch();
  const mgBeneficiaryMaster = useSelector((state) => state.mgBeneficiaryMaster);

  const Beneficiary_Status = [
    {
      id: 1,
      Status_mode: "Active",
    },
    {
      id: 2,
      Status_mode: "Inactive",
    },
  ];

  const Finished_Products = [
    {
      id: 1,
      Status_mode: "Yes",
    },
    {
      id: 2,
      Status_mode: "No",
    },
  ];

  const [isUpdating, setIsUpdating] = useState(false);

  // Function to handle clicking on the edit icon
  const handleEditClick = () => {
    setIsUpdating(true);
    // Rest of your code...
  };

  // Function to handle updating
  const handleUpdate = () => {
    // Rest of your code...
    setIsUpdating(false);
  };

  const handleFieldApplicant = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "applicant_id",
        value: event.target.value,
      })
    );
    props.onMsambFacilityChange(event);
  };

  const handleFieldDivision = (event) => {
    const divisionId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "division_id", value: divisionId })
    );

    // Fetch districts based on the selected division
    axios
      .get(
        `${apiUrl}api/district-by-division?division_id=${divisionId}`,
        getConfigWithToken()
      )
      .then((response) => {
        setDistricts(response.data.data);
        setTalukas([]); // Reset talukas when division changes
      })
      .catch((error) => {
        console.error("Error fetching districts:", error);
      });
  };

  const handleFieldDistrict = (event) => {
    const districtId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "district_id", value: districtId })
    );

    // Fetch talukas based on the selected district
    axios
      .get(
        `${apiUrl}api/get-taluka-by-district?district_id=${districtId}`,
        getConfigWithToken()
      )
      .then((response) => {
        setTalukas(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching talukas:", error);
      });
  };
  const handleFieldTaluka = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "taluka_id", value: event.target.value })
    );
  };
  const handleFieldMAGNETCrop = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "targeted_magnet_crops",
        value: event.target.value,
      })
    );
  };
  const handleFieldEntityType = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "entity_type_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldEOI = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "eoi", value: event.target.value })
    );
  };
  const handleFieldStatus = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "status", value: event.target.value })
    );
  };
  const handleFieldPANFirm = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "pan_of_firm", value: event.target.value })
    );
  };
  const handleFieldUDYAM = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "udyam_reg_number",
        value: event.target.value,
      })
    );
  };
  const handleFieldDate = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "incorporation_date",
        value: event.target.value,
      })
    );
  };
  const handleFieldFSSAI = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "fssai_application_number",
        value: event.target.value,
      })
    );
  };
  const handleFieldRegApplicant = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "reg_number_of_applicant",
        value: event.target.value,
      })
    );
  };
  const handleFieldFinProduct = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "finished_products",
        value: event.target.value,
      })
    );
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  useEffect(() => {
    async function getDta() {
      console.log("kjdncjnds000000000000000000000000000000000");
      const district = await axios.get(
        `${apiUrl}api/get-all-district?all=1`,
        getConfigWithToken()
      );
      setDistricts(district.data.data);
      const taluka = await axios.get(
        `${apiUrl}api/get-all-taluka-with-village?all=1`,
        getConfigWithToken()
      );
      setTalukas(taluka.data.data);
    }
    if (props.editFlag) {
      getDta();
    }
  }, [props.editFlag]);
  return (
    <>
      {/* <Online> */}
        <div className="tab-pane">
          <form class="row g-3">
            {/* Name of the MSAMB Facility */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="MSAMBFacility">Name of the Applicant</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  {isUpdating ? (
                    // If in update mode, render the msamb_facility_name as read-only
                    <input
                      type="text"
                      value={
                        Applicant.find(
                          (facility) =>
                            facility.id === mgBeneficiaryMaster.applicant_id
                        )?.applicant_name || ""
                      }
                      readOnly
                    />
                  ) : (
                    // If not in edit mode, render the msamb_facility_id as a normal, editable input
                    <Select
                      name="MSAMBFacility"
                      className="customSelect"
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mgBeneficiaryMaster.applicant_id}
                      onChange={handleFieldApplicant}
                      onFocus={handleResetFocus}
                    >
                      {Applicant.map((e) => (
                        <MenuItem value={e.id} key={e.id}>
                          {e.applicant_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
            </div>
            {/* <div className='primaryTableLabel'> 
                <Box>
                    <label htmlFor="MSAMBFacility">Name of the Applicant</label>                        
                    <FormControl style={{width:'45%'}} >
                        <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="MSAMBFacility"   
                            className="customSelect" 
                            style={{width:"100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={mgBeneficiaryMaster.applicant_id}
                            onChange={handleFieldApplicant} 
                            onFocus={handleResetFocus}                                                              
                        >                     
                        {(props.Applicant).map((e)=>(       
                             <MenuItem value={e.id} key={e.id}>{e.applicant_name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Box>        
                </div>
                 */}
            {/* Division */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Division">Division</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Division"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.division_id}
                    onChange={handleFieldDivision}
                    onFocus={handleResetFocus}
                  >
                    {props.Division.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.divisions}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* District */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="District">District</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="District"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.district_id}
                    onChange={handleFieldDistrict}
                    onFocus={handleResetFocus}
                  >
                    <MenuItem value="">Select District</MenuItem>
                    {districts.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.district}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Taluka */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Taluka">Taluka</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Taluka"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.taluka_id}
                    onChange={handleFieldTaluka}
                    onFocus={handleResetFocus}
                  >
                    {talukas.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.taluka}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Status */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Status">Status</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Status"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.status}
                    onChange={handleFieldStatus}
                    onFocus={handleResetFocus}
                  >
                    {Beneficiary_Status.map((e) => (
                      <MenuItem value={e.Status_mode}>{e.Status_mode}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* EOI */}
            <div className="primaryTableLabel">
              <label htmlFor="EOI">EOI</label>
              <input
                type="number"
                name="EOI"
                value={mgBeneficiaryMaster.eoi}
                onChange={handleFieldEOI}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* Entity Type */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="entity type">Beneficiary Type</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="entity type"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.entity_type_id}
                    onChange={handleFieldEntityType}
                    onFocus={handleResetFocus}
                  >
                    {props.EntityType.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.entity_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* PAN of Firm */}
            <div className="primaryTableLabel">
              <label htmlFor="PAN">PAN of Firm</label>
              <input
                type="text"
                name="PAN"
                value={mgBeneficiaryMaster.pan_of_firm}
                onChange={handleFieldPANFirm}
                onFocus={handleResetFocus}
                onKeyDown={(e) => {
                  const regex = /^[a-zA-Z0-9]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                maxLength={12}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* incorporation_date */}
            <div className="primaryTableLabel">
              <label htmlFor="incorporation_date">Incorporation Date </label>
              <input
                type="date"
                name="incorporation_date"
                value={mgBeneficiaryMaster.incorporation_date}
                onChange={handleFieldDate}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* UDYAM */}
            <div className="primaryTableLabel">
              <label htmlFor="UDYAM">UDYAM Reg.no.</label>
              <input
                type="text"
                name="PAN"
                value={mgBeneficiaryMaster.udyam_reg_number}
                onChange={handleFieldUDYAM}
                onFocus={handleResetFocus}
                onKeyDown={(e) => {
                  const regex = /^[a-zA-Z0-9]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* FSSAI */}
            <div className="primaryTableLabel">
              <label htmlFor="FSSAI">FSSAI Application number</label>
              <input
                type="text"
                name="FSSAI"
                value={mgBeneficiaryMaster.fssai_application_number}
                onChange={handleFieldFSSAI}
                onFocus={handleResetFocus}
                onKeyDown={(e) => {
                  const regex = /^[a-zA-Z0-9]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* reg no.of applicant */}
            <div className="primaryTableLabel">
              <label htmlFor="reg no.of applicant">
                Reg number of Applicant
              </label>
              <input
                type="text"
                name="reg_no_applicant"
                value={mgBeneficiaryMaster.reg_number_of_applicant}
                onChange={handleFieldRegApplicant}
                onFocus={handleResetFocus}
                onKeyDown={(e) => {
                  const regex = /^[a-zA-Z0-9]*$/;
                  if (!regex.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* targeted_magnet_crops */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="MAGNET">Targeted MAGNET Crops</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="MAGNET"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      Array.isArray(mgBeneficiaryMaster.targeted_magnet_crops)
                        ? mgBeneficiaryMaster.targeted_magnet_crops
                        : [mgBeneficiaryMaster.targeted_magnet_crops]
                    }
                    //  value={mgBeneficiaryMaster.targeted_magnet_crops}
                    onChange={handleFieldMAGNETCrop}
                    onFocus={handleResetFocus}
                    multiple
                  >
                    {props.MagnetCrop.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.crop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Finished products */}
            {/* <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Status">Finished products</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Finished_products"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.finished_products}
                    onChange={handleFieldFinProduct}
                    onFocus={handleResetFocus}
                  >
                    {Finished_Products.map((e) => (
                      <MenuItem value={e.Status_mode}>{e.Status_mode}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div> */}
            
            <div className='primaryTableLabel'>
                    <label htmlFor="Finished products ">Finished products </label>
                    <input type="text" name="Finished products " 
                        value={mgBeneficiaryMaster.finished_products}
                        onChange={handleFieldFinProduct} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}  
                        placeholder="Yes/No" 
                    />
                </div>
          </form>
        </div>
      {/* </Online>
      <Offline>You're Offline, Please Check your Connection</Offline> */}
    </>
  );
};
