import React, { useEffect, useState } from "react";
import '../../../assets/css/modal.css'
import { Box, Pagination} from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";
import Select from 'react-select';
import '../../../assets/css/masterdata.css'


export const TechnicalComponentAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({})
  const [techCompData, setTechCompData] = useState([])
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false); // State for controlling the modal
  const [newTechnicalAttribute, setNewTechnicalAttribute] = useState("");
  const [editingAttribute, setEditingAttribute] = useState(null); // State to track the attribute being edited
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMatchingGrant, setIsMatchingGrant] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleAddOption = () => {
    setEditingAttribute(null)
    setNewTechnicalAttribute("");
    setSelectedOptions([]);
    setIsMatchingGrant(false);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSaveAttribute = async () => {
    if (!newTechnicalAttribute || selectedOptions.length === 0) {
      Swal.fire("Please fill in all required fields", "", "warning");
      return;
    }

    try {
      let response;

      if (editingAttribute) {
        response = await axios.post(
          `${apiUrl}api/tech-componets-update`,
          {
            technical_component: newTechnicalAttribute,
            id: editingAttribute.id,
            form_id: selectedOptions.map((e) => e.value).join(','),
            is_matching_grant: isMatchingGrant
          },
          getConfigWithToken()
        );

        if (response.data["status"] === true) {
          Swal.fire("Attribute Edited Successfully Refresh the page to see the changes.", "", "success");
        }

        const updatedFacilities = attributeOptions.map((facility) => {
          if (facility.id === editingAttribute.id) {
            return { ...facility, technical_component: newTechnicalAttribute };
          }
          return facility;
        });

        setAttributeOptions(updatedFacilities);
      } else {
        response = await axios.post(
          `${apiUrl}api/tech-componets-create`,
          {
            technical_component: newTechnicalAttribute,
            form_id: selectedOptions.map((e) => e.value).join(','),
            is_matching_grant: isMatchingGrant
          },
          getConfigWithToken()
        );

        if (response.data["status"] === true) {
          Swal.fire("Attribute Added Successfully", "", "success");

          const updatedAttribute = [
            ...attributeOptions,
            response.data.data,
          ];
          setAttributeOptions(updatedAttribute);

          const getWorkpack = await axios.get(`${apiUrl}api/get-all-tech-components?page=${page}`, getConfigWithToken());
          const workpackage = getWorkpack.data["data"]["data"].map((e) => ({'id':e.id, 'technical_component': e.technical_component, 'form': e.form, 'form_id': e.form_id, 'isMatchingGrant': e.is_matching_grant}))
          setAttributeOptions(workpackage);
          setGetAllData(getWorkpack.data.data);
        }
      }

      setOpen(false);
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };



  const handleEditFunction = async (attribute) => {
    setEditingAttribute(attribute);
    setNewTechnicalAttribute(attribute.technical_component);
    setSelectedOptions([{ value: attribute.form_id, label: attribute.form }]);
    setIsMatchingGrant(attribute.isMatchingGrant);
    setOpen(true);
  }

  const handleDeleteFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteAttribute = await axios.get(`${apiUrl}api/tech-componets-delete?id=${id}`, getConfigWithToken());
          if (deleteAttribute.data["status"] === true) {
            Swal.fire({
              title: "Deleted!",
              text: "Attribute has been deleted.",
              icon: "success"
            });
          }
          const updatedAttribute = attributeOptions.filter((e) => (e.id !== id))
          setAttributeOptions(updatedAttribute)
        } catch (error){
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      }
    });
  }

  const handleChange = async (event, value) => {
    setPage(value);
    try {
      const response = await axios.get(`${apiUrl}api/get-all-tech-components?page=${value}`, getConfigWithToken());
      if (response.data && response.data.data && response.data.data.data) {
        const workpackage = response.data.data.data.map((e) => ({'id':e.id, 'technical_component': e.technical_component, 'form': e.form, 'form_id': e.form_id, 'isMatchingGrant': e.is_matching_grant}));
        setAttributeOptions(workpackage);
        setGetAllData(response.data.data.data);
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    async function getFacilityType() {
      try {
        const getfacility = await axios.get(`${apiUrl}api/get-all-tech-components?page=1`, getConfigWithToken());
        const getTechComponent = await axios.get(`${apiUrl}api/get-technical-component-forms`, getConfigWithToken());
        const techComp = getTechComponent.data.data[0].map((e) => ({ value: e.id, label: e.form }));
        setTechCompData(techComp)
        if (getfacility.data && getfacility.data.data.data) {
          const facility = getfacility.data.data.data.map((e) => ({ 'id': e.id, 'technical_component': e.technical_component, 'form': e.form, 'form_id': e.form_id, 'isMatchingGrant': e.is_matching_grant, }));
          setAttributeOptions(facility);
          setGetAllData(getfacility.data.data.data);
        } else {
          console.error('Unexpected response structure:', getfacility.data);
        }
      } catch (error) {
        console.error('Error fetching facility type:', error);
      }
    }
    getFacilityType();
  }, []); 

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div id="exTab3" className="contain" style={{ width: '100%', overflow: 'hidden' }}> 
            <div className="cardMaster" id="" style={{ width: '100%', marginTop: '0.5rem' }}>
              <form className="row g-3" style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}>
                <Box className="your-component" sx={{ typography: 'body1', width: "88%" }}>
                <div className="words-with-lines">
                      <span>Technical Component</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata"  style={{marginRight:'1rem'}}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>
                  <Box className="your-box" sx={{typography: "body1"}}>
                    <div>
                      <span>Manage Options (Value of your Attribute)</span>
                    </div>
                  </Box>
                  <div className="your-button-container">
                    <button type="button"  style={{width:'30%'}} onClick={handleAddOption}>
                      <i className="fa-duotone fa-plus"></i> Add Attributes
                      Options{" "}
                    </button>
                  </div>
                  <Box className='custom-box'  style={{marginLeft:'2rem'}}>
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Attribute Options</th>
                          <th>Form Name</th>
                          <th style={{width:'15%'}}>Validation Required</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="your-tbody">
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{border: "1px solid black"}}>
                              <input type="text" value={option.technical_component}  disabled/>
                            </td>
                            <td style={{border: "1px solid black"}}>
                              <input type="text" value={option.form}  disabled/>
                            </td>
                            <td style={{border: "1px solid black"}}>
                              <input type="text" value={option.isMatchingGrant} style={{width:"85%"}} disabled/>
                            </td>
                            <td>
                              <i
                                className="fa-solid fa-pen-to-square"
                                onClick={()=>handleEditFunction(option)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={()=> handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                      />
                    </table>
                  </Box>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
     

      {open && (
        <div className="overlay">
          <div className="custom-modal">
            <div className="modal-content">
              <span className="close" onClick={handleModalClose}>&times;</span>
              <h2>{editingAttribute ? 'Edit Attribute' : 'Add Attribute'}</h2>

              <label htmlFor="technicalComponent">Technical Component Attribute:</label>
              <input
                type="text"
                id="technicalComponent"
                value={newTechnicalAttribute}
                onChange={(e) => setNewTechnicalAttribute(e.target.value)}
              />

              <label>Form Options:</label>
              <Select
                options={techCompData}
                isMulti
                value={selectedOptions}
                onChange={(selectedOptions) => setSelectedOptions(selectedOptions)}
              />
              <div>
                <label>Validation Required:</label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={isMatchingGrant === "Yes"}
                      onChange={() => setIsMatchingGrant("Yes")}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={isMatchingGrant === "No"}
                      onChange={() => setIsMatchingGrant("No")}
                    />
                    No
                  </label>
                </div>
              </div>
              <div style={{textAlign: 'center'}}>
                <button className="button-trigger" onClick={handleSaveAttribute}>
                  {editingAttribute ? 'Save Changes' : 'Save'}
                </button>
                <button className="button-trigger" style={{ backgroundColor: "grey" }} onClick={handleModalClose}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
