
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  beneficiary_id:'',
  division_id:'',
  district_id:'',
  taluka_id:'',
  beneficiary_type_id:'',
    };

const EntityMasterCapacityBuildingSlice = createSlice({
  name: 'EntityMasterCapacityBuildingSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataEntityMaster: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"Paylpadssssssssssssssssssss")
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataEntityMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsEntityMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldData, updateApiDataEntityMaster, resetDataEntityMaster } = EntityMasterCapacityBuildingSlice.actions;

export default EntityMasterCapacityBuildingSlice.reducer;
