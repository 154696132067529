// radioSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    safe_work: '',
    sanitation: '',
    rainwater_harvesting: '',
    power_source_id: '',
    total_power_augumented_in_grid: '',
    power_bill_saved: '',
    m_cub_of_rain_water_harvested: '',
    estimated_bill: '',

    validationErrors:{
      safe_work: '',
      sanitation: '',
      rainwater_harvesting: '',
      power_source_id: '',
      total_power_augumented_in_grid: '',
      power_bill_saved: '',
      m_cub_of_rain_water_harvested: '',
      estimated_bill: '',
    }
};

const envSafeGurard = createSlice({
  name: 'EnvSafeGuard',
  initialState,
  reducers: {
    setAnswer(state, action) {
      const { question, answer } = action.payload;
      state[question] = answer;
    },
    setValidationErrorsEnvSafeGuard: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    setField: (state, action) => {
        const { field, value } = action.payload;
        // Update the state with the new value for the specified field
        state[field] = value;
        // state[action.payload.fieldName] = action.payload.value;
      },
      setInputField: (state, action) => {
        state[action.payload.fieldName] = action.payload.value;
      },
    resetDataEnvSafeGuard: (state) => {
        // Reset all fields to their initial values
        return { ...initialState };
      },
    resetValidation: (state, action) =>{
        state.validationErrors = { ...initialState.validationErrors };
      },
    updateEnvSafeGuardForm: (state, action) => {
      return {
        ...state,
        safe_work: action.payload.safe_work,
        sanitation: action.payload.sanitation,
        rainwater_harvesting: action.payload.rainwater_harvesting,
        power_source_id: action.payload.power_source_id,
        total_power_augumented_in_grid: action.payload.total_power_augumented_in_grid,
        power_bill_saved: action.payload.power_bill_saved,
        m_cub_of_rain_water_harvested: action.payload.m_cub_of_rain_water_harvested,
        estimated_bill: action.payload.estimated_bill
      };
    }
      
  },
});

export const {updateEnvSafeGuardForm, resetValidation, setValidationErrorsEnvSafeGuard,setInputField, resetDataEnvSafeGuard, setAnswer, setField } = envSafeGurard.actions;
export default envSafeGurard.reducer;
