// radioSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    facility_id: '',
    capacity: '',
    progress: '',
    action_taken: '',
    suggestions_changes_made: '',
    validationErrors: {
      capacity: '',
      facility_id: '',
      progress: '',
      action_taken: '',
      suggestions_changes_made: '',
    },
  
};

const progressFacilitySlice = createSlice({
  name: 'progressFacilitySlice',
  initialState,
  reducers: {
    setAnswer(state, action) {
      const { question, answer } = action.payload;
      state[question] = answer;
    },
    setValidationErrorsFacilities: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
    setField: (state, action) => {
        const { field, value } = action.payload;
        // Update the state with the new value for the specified field
        state[field] = value;
      },
    resetDataFacilities: (state) => {
        // Reset all fields to their initial values
        return { ...initialState };
      },
    updateFacilitiesGESIForm: (state, action) =>{
        return {
          ...state,
          facility_id: action.payload.facility_id,
          capacity: action.payload.capacity,
          progress: action.payload.progress,
          action_taken: action.payload.action_taken,
          suggestions_changes_made: action.payload.suggestions_changes_made
        };
          
      }
  },
});

export const {resetValidation, setValidationErrorsFacilities, updateFacilitiesGESIForm, resetDataFacilities, setAnswer, setField } = progressFacilitySlice.actions;
export default progressFacilitySlice.reducer;
