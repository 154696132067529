import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePickInput3 } from "../../DatePickInput3";
import { InputField } from "../../InputField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  resetValidation,
  setInputFieldData,
} from "../../../redux/slice/InputThree/Input3PhysicalFinance/PhysicalFinanceSlice";
import { getConfigWithToken } from "../../../utils/Config/Config";

export const PhysicalFinance = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const phyFinance = useSelector((state) => state.physicalFinance);

  const [statusWork, setStatusWork] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {
        const status = await axios.get(
          `${apiUrl}api/getStatusOfWork`,
          getConfigWithToken()
        );

        setStatusWork(status.data["data"][0]);
      } catch (error) {}
    };

    valueChain();
  }, []);

  const handleFieldStatusWork = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "status_of_work_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldExpIncc = (value) => {
    dispatch(setInputFieldData({ fieldName: "exp_incurred", value }));
  };
  const handleFieldCivilProgress = (value) => {
    dispatch(setInputFieldData({ fieldName: "civil_progress_pct", value }));
  };
  const handleFieldElectroMechanical = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "electro_mechanical_progress_pct", value })
    );
  };
  const handleFieldActionPoints = (value) => {
    dispatch(setInputFieldData({ fieldName: "action_points", value }));
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  
  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      <InputField
        readOnly={true}
        label="Contract No"
        type="text"
        value={phyFinance.contract_no ? phyFinance.contract_no.toString() : ""}
      />
      <InputField
        readOnly={true}
        label="Component as per accepted Tender"
        type="text"
        value={phyFinance.component ? phyFinance.component.toString() : ""}
      />
      <InputField
        readOnly={true}
        label="Area"
        type="number"
        endAr="(in Sqm)"
        value={phyFinance.area ? phyFinance.area.toString() : ""}
      />
      <InputField
        readOnly={true}
        label="Supplier / Contractor Name"
        type="text"
        value={
          phyFinance.supplier_contractor_name
            ? phyFinance.supplier_contractor_name.toString()
            : ""
        }
      />
      <InputField
        readOnly={true}
        label="Contract Amount"
        type="number"
        endAr="(in lakhs)"
        value={
          phyFinance.contract_amount
            ? phyFinance.contract_amount.toString()
            : ""
        }
      />
      <DatePickInput3
        readOnly={true}
        label="Contract Award Date"
        className="custom-date-picker"
        disbrushDate={phyFinance.contract_award_date}
      />
      <DatePickInput3
        readOnly={true}
        label="Completion Date"
        className="custom-date-picker"
        disbrushDate={phyFinance.completion_date}
      />

      <DatePickInput3
        readOnly={true}
        label="Extension Date"
        className="custom-date-picker"
        disbrushDate={phyFinance.extension_date}
      />

      <InputField
        readOnly={true}
        label="Time Elapsed (as on date System date) duration"
        type="text"
        value={
          phyFinance.time_elapsed ? phyFinance.time_elapsed.toString() : ""
        }
      />
      <InputField
        readOnly={true}
        label="Contract Extension "
        type="text"
        value={
          phyFinance.contract_extension
            ? phyFinance.contract_extension.toString()
            : ""
        }
      />
      <InputField
        readOnly={true}
        label="Price Increase "
        type="text"
        value={
          phyFinance.price_increase ? phyFinance.price_increase.toString() : ""
        }
      />
      <InputField
        readOnly={true}
        label="Increased Amount (added in Total Contract Amount)"
        type="number"
        endAr="(in lakhs)"
        value={
          phyFinance.increased_amount
            ? phyFinance.increased_amount.toString()
            : ""
        }
      />
      <InputField
        readOnly={true}
        label="Total Disbursed amount on Contract"
        type="number"
        endAr="(in lakhs)"
        value={
          phyFinance.total_disbursed_amount
            ? phyFinance.total_disbursed_amount.toString()
            : ""
        }
      />
      <InputField
        readOnly={true}
        label="Total Undisbursed Amount"
        type="number"
        endAr="(in Lakhs)"
        value={phyFinance.total_undisbursed_amount}
      />
      <InputField
        readOnly={true}
        label="Total expenditure till date"
        type="number"
        endAr="(in lakhs)"
        value={phyFinance.total_exp_till_date}
      />

      <InputField
        readOnly={true}
        label="Civil Progress till date"
        type="number"
        endAr="(%)"
        value={phyFinance.civil_progress_till_date}
      />
      <InputField
        readOnly={true}
        label="Electro Mechanical Progress till date"
        type="number"
        endAr="(%)"
        value={phyFinance.electro_mechanical_progress_till_date}
      />
      <div class="row g-3">
        <div className="word-with-line">
          <span>
            Details of Expenditure and Physical Progress for Current Quarter
          </span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <div className="col-md-4">
          <Box sx={{ minWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Status of Work
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={phyFinance.status_of_work_id}
                onChange={handleFieldStatusWork}
                label="StatusOfWork"
              >
                {statusWork.map((e) => (
                  <MenuItem value={e.id}>{e.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <InputField readOnly={true} label="Completed Work" type="number" endAr="(%)"/> */}
        <InputField
          label="Expenditure incurred in this quarter"
          type="number"
          endAr="(in lakhs)"
          value={phyFinance.exp_incurred}
          // onChange={handleFieldExpIncc}
          onChange={(value) => handleInputChange("exp_incurred", value)}
          onFocus={handleResetFocus}
          error={!!phyFinance.validationErrors.exp_incurred}
          helperText={phyFinance.validationErrors.exp_incurred}
        />

        {/* <div className='word-with-line'>
            <span>Details of Physical Progress for Current Quarter</span>
            <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
        </div> */}

        <InputField
          label="Civil Progress"
          type="number"
          endAr="(%)"
          value={phyFinance.civil_progress_pct}
          // onChange={handleFieldCivilProgress}
          onChange={(value) => handleInputChange("civil_progress_pct", value)}
          onFocus={handleResetFocus}
          error={!!phyFinance.validationErrors.civil_progress_pct}
          helperText={phyFinance.validationErrors.civil_progress_pct}
        />
        <InputField
          label="Electro Mechanical Progress"
          type="number"
          endAr="(%)"
          value={phyFinance.electro_mechanical_progress_pct}
        //   onChange={handleFieldElectroMechanical}
          onChange={(value) => handleInputChange("electro_mechanical_progress_pct", value)}
          onFocus={handleResetFocus}
          error={!!phyFinance.validationErrors.electro_mechanical_progress_pct}
          helperText={
            phyFinance.validationErrors.electro_mechanical_progress_pct
          }
        />

        <InputField
          label="Action Points"
          type="text"
          value={
            phyFinance.action_points ? phyFinance.action_points.toString() : ""
          }
          onChange={handleFieldActionPoints}
          onFocus={handleResetFocus}
        />
      </div>
    </>
  );
};
