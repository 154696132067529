import "../assets/css/inputForm.css";
// import { SelectField } from "../subcomponents/SelectField"
import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf from "../assets/PDF_file_icon.svg.png";
import imgIcon from "../assets/image_icon-icons.com_50366.png";
import { FormHelperText } from "@mui/material";
import { getConfigWithToken } from "../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { InputField } from "../subcomponents/InputField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BeneficiariesProfit } from "../subcomponents/Input2impactforms/BeneProfits/beneficiaryProfit";
import { BeneficiariesLoss } from "../subcomponents/Input2impactforms/Beneloss/beneficiaryloss";
import { MultiSelect } from "../subcomponents/MultiSelect";
import Swal from "sweetalert2";
import {
  resetDataBeneProfit,
  setInputFieldData,
  setValidationErrorsBeneProfit,
  updateApiDataToBeneProfit,
  updateImpactFormData,
} from "../redux/slice/InputTwoImpactForms/BeneficiaryProfit/BeneficiaryProfitSlice";
import {
  resetBeneLossValidation,
  resetDataBeneLoss,
  setValidationErrorsBeneLoss,
  updateApiDataToBeneLoss,
} from "../redux/slice/InputTwoImpactForms/BeneficiaryLoss/BeneficiaryLossSlice";
import { UtilizationTPDMG } from "../subcomponents/Input2impactforms/UtilizationTPD/UtilizationTPDMG";
import { UtilizationColdStorageMG } from "../subcomponents/Input2impactforms/UtilizationColdStorage/UtilizationColdStorageMG";
import {
  resetDataTPDTwo,
  setValidationErrorsTPDTwo,
  updateApiDataToTPDTwo,
} from "../redux/slice/InputTwoImpactForms/UtilizationTPDTwo/UitlizationTPDTwoSlice";
import {
  resetDataColdStorageTwo,
  setValidationErrorsColdStorageTwo,
  updateApiDataToColdStorageTwo,
} from "../redux/slice/InputTwoImpactForms/UtilizationColdStorageTwo/UitlizationColdStorageTwoSlice";

export const InputForm2ImapctForms = ({
  quarter,
  years,
  divisi,
  magentCrp,
  exisitingmagnetCrp,
  currentFinancialYear,
}) => {
  // const dd = useSelector(setInputFieldData);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;
  const dd = useSelector(setInputFieldData);

  const dispatch = useDispatch();
  const [value, setValue] = useState("0");
  const [Year, setYear] = useState([]);
  const [selectYear, setSelectedYear] = useState("");
  const [loading, setLoading] = useState(false);

  const [quarters, setQuarter] = useState([]);
  const [selectQuarter, setSelectedQuarters] = useState("");
  const [selectKPMGDivision, setSelectKPMGDivision] = useState("");
  const [selectFPOVCOApplicant, setSelectFPOVCOApplicant] = useState("");
  const [division, setDivision] = useState([]);
  const [studyFarmApplicant, setStudyFarmApplicant] = useState([]);
  const [beneStudyTourFarm, setBeneStudyTourFarm] = useState([]);
  const [magentCrop, setMagentCrop] = useState([]);
  const [existingmagentCrop, setExisitingMagentCrop] = useState([]);
  const [postHarvestFlagEdit, setPostHarvestFlagEdit] = useState(false);
  const [postHarvestIdEdit, setPostHarvestIdEdit] = useState("");
  const [getApplicantData, setGetApplicantData] = useState({});

  const [esiTargetCrop, setEsiTargetCrop] = useState("");
  const [esiPackHouse, setEsiPackHouse] = useState("");

  const [validationErrors, setValidationErrors] = useState({});

  const [permission, setPermission] = useState([]);
  const [editInput1Files, setEditInput1Files] = useState([]);
  const [selectCapacityFile, setSelectCapacityFile] = useState([]);
  const [errorCapacityFile, setErrorCapacityFile] = useState("");

  const resetAllState = () => {
    setBeneStudyTourFarm([]);
    setSelectKPMGDivision("");
    setStudyFarmApplicant([]);
    setEsiTargetCrop("");
    setEsiPackHouse("");
    setPostHarvestFlagEdit("");
    dispatch(resetDataBeneLoss({}));
    dispatch(resetDataBeneProfit({}));
    dispatch(resetDataTPDTwo());
    dispatch(resetDataColdStorageTwo({}));

    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setEditInput1Files([]);
    resetAllState();
  };

  const handleFinYearChange = (event) => {
    refershdata();
    setSelectedYear(event.target.value);
  };

  const refershdata = () => {
    setBeneStudyTourFarm([]);
    setSelectKPMGDivision("");
    setStudyFarmApplicant([]);
    setEsiTargetCrop("");
    setEsiPackHouse("");
    dispatch(resetDataBeneLoss({}));
    dispatch(resetDataBeneProfit({}));
    dispatch(resetDataTPDTwo({}));
    dispatch(resetDataColdStorageTwo({}));

    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const handleQuarterChange = async (event) => {
    refershdata();
    setSelectedQuarters(event.target.value);
  };

  const handleDivisionKMPGChange = async (event) => {
    resetAllState();
    const selectedDivision = event.target.value;
    setSelectKPMGDivision(selectedDivision);
    setStudyFarmApplicant([]);
    setBeneStudyTourFarm([]);

    try {
      const beneficiaryByFormDivision = await axios.get(
        `${apiUrl}api/getApplicantByDivisionId?division_id=${selectedDivision}`,
        getConfigWithToken()
      );
      const studyFarm = beneficiaryByFormDivision.data.data[0].map((e) => ({
        id: e.applicant_id,
        name: e.applicant_name,
      }));
      setStudyFarmApplicant(studyFarm);
    } catch (err) {
      console.error("Error fetching beneficiaries", err);
    }
  };

  const handleFPOVCOUtilizationChange = async (event) => {
    const selectedApplicant = event.target.value;
    setBeneStudyTourFarm(selectedApplicant);
    try {
      const DataByApplicantSelection = await axios.get(
        `${apiUrl}api/getMagnetCropByApplicant?applicant_id=${selectedApplicant}`,
        getConfigWithToken()
      );

      const magnetCropData = DataByApplicantSelection.data.data[0];
      const magnetCropIds = magnetCropData.magnet_crop_id
        ? JSON.parse(magnetCropData.magnet_crop_id).map((crop) => crop.id)
        : [];

      const totalCapacityTpd =
        magnetCropData.total_capacity_tpd !== null
          ? magnetCropData.total_capacity_tpd
          : 0;

      setEsiPackHouse(totalCapacityTpd);
      setEsiTargetCrop(magnetCropIds);
      if (value === "2") {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/if-utilisation-facility-tpd-input-mg2beneficiary-two-edit?quarter=${selectQuarter}&applicant_id=${selectedApplicant}&division_id=${selectKPMGDivision}`,
          getConfigWithToken()
        );
        if (getStudyTourEditData.data["status"] === true) {
          getStudyTourEditData.data["flag"]
            ? setPostHarvestFlagEdit(true)
            : setPostHarvestFlagEdit(false);
          const data = getStudyTourEditData.data["data"][0];
          data["magnet_crop_id"] = data["magnet_crop_id"].split(",");
          data["existing_crops"] = data["existing_crops"].split(",");

          console.log(data["magnet_crop_id"], ' data["magnet_crop_id"]');
          console.log(data["existing_crops"], ' data["existing_crops"]');
          // Check if remarks is not null and then set the state
          if (getStudyTourEditData.data["remarks"] !== null) {
            setEditInput1Files(getStudyTourEditData.data["remarks"]);
          }

          setPostHarvestIdEdit(data["id"]);
          dispatch(updateApiDataToTPDTwo(data));
        } else {
          setEditInput1Files([]);
          setPostHarvestFlagEdit(false);
          dispatch(resetDataTPDTwo());
        }
      }
      if (value === "3") {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/if-utilisation-facility-cs-mgbeneficiary-input-two-edit?quarter=${selectQuarter}&applicant_id=${selectedApplicant}&division_id=${selectKPMGDivision}`,
          getConfigWithToken()
        );
        if (getStudyTourEditData.data["status"] === true) {
          getStudyTourEditData.data["flag"]
            ? setPostHarvestFlagEdit(true)
            : setPostHarvestFlagEdit(false);
          const data = getStudyTourEditData.data["data"][0];
          
          if (getStudyTourEditData.data["remarks"] !== null) {
            setEditInput1Files(getStudyTourEditData.data["remarks"]);
          }
        
          setPostHarvestIdEdit(data["id"]);
          dispatch(updateApiDataToColdStorageTwo(data));
        } else {
          setEditInput1Files([]);
          setPostHarvestFlagEdit(false);
          dispatch(resetDataColdStorageTwo());
        }
      }
    } catch (err) {
      console.error("Error fetching data by applicant", err);
    }
  };

  const handleBenefiStudyTourFarmChange = async (event) => {
    setBeneStudyTourFarm(event.target.value);
    setEditInput1Files([]);

    try {
      if (value === "0") {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/if-beneficiary-profit-input-two-edit?financial_year=${selectYear}&applicant_id=${event.target.value}&division_id=${selectKPMGDivision}`,
          getConfigWithToken()
        );
        if (getStudyTourEditData.data["status"] === true) {
          getStudyTourEditData.data["flag"]
            ? setPostHarvestFlagEdit(true)
            : setPostHarvestFlagEdit(false);
          const data = getStudyTourEditData.data["data"][0];
          
          // Check if remarks is not null and then set the state
          if (getStudyTourEditData.data["remarks"] !== null) {
            setEditInput1Files(getStudyTourEditData.data["remarks"]);
          }

          setPostHarvestIdEdit(data["id"]);
          dispatch(updateApiDataToBeneProfit(data));
        } else {
          setEditInput1Files([]);
          setPostHarvestFlagEdit(false);
          dispatch(resetDataBeneProfit());
        }
      }

      if (value === "1") {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/if-beneficiary-loss-input-two-edit?quarter=${selectQuarter}&applicant_id=${event.target.value}&division_id=${selectKPMGDivision}`,
          getConfigWithToken()
        );
        if (getStudyTourEditData.data["status"] === true) {
          getStudyTourEditData.data["flag"]
            ? setPostHarvestFlagEdit(true)
            : setPostHarvestFlagEdit(false);
          const data = getStudyTourEditData.data["data"][0];
          data["magnet_crop_id"] = data["magnet_crop_id"].split(",");

          console.log(data["magnet_crop_id"], ' data["magnet_crop_id"]');
          // Check if remarks is not null and then set the state
          if (getStudyTourEditData.data["remarks"] !== null) {
            setEditInput1Files(getStudyTourEditData.data["remarks"]);
          }

          setPostHarvestIdEdit(data["id"]);
          dispatch(updateApiDataToBeneLoss(data));
        } else {
          setEditInput1Files([]);
          setPostHarvestFlagEdit(false);
          dispatch(resetDataBeneLoss());
        }
      }
    } catch (err) {
      console.error("Error fetching study tour edit data", err);
    }
  };

  const handleMultiFilesGAPChange = (event) => {
    setSelectCapacityFile(event.target.files);
  };

  const handleDeleteFile = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/delete-impact-form-remarks?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
      setEditInput1Files(newRemarks);
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };

  const handleSubmitBeneLoss = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["beneficiaryLossMaster"];
    const validateForm = () => {
      const errors = {};
      const errorMessages = {
        magnet_crop_id: "Magnet Crop ID is required",
        tuc_pack_house: "This field is required",
        tuc_cold_storage: "This field is required",
        tuc_reefer_vans: "This field is required",
        tuc_frozen_storage: "This field is required",
        estimated_losses_pack_house: "This field is required",
        estimated_losses_cold_storage: "This field is required",
        estimated_losses_frozen_storage: "This field is required",
        estimated_losses_reefer_vans: "This field is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsBeneLoss(errors)); // Dispatch errors
      console.log(errors, "errors");
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const postHarvest = dd["payload"]["beneficiaryLossMaster"];

        const formData = new FormData();
        for (const key in postHarvest) {
          if (key === "magnet_crop_id") {
            formData.append(
              "magnet_crop_id",
              postHarvest["magnet_crop_id"].join(",")
            );
          } else {
            formData.append(
              key,
              postHarvest[key] !== null ? postHarvest[key] : ""
            );
          }
        }
        formData.append("quarter", selectQuarter);
        formData.append("division_id", selectKPMGDivision);
        formData.append("applicant_id", beneStudyTourFarm);
        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!postHarvestFlagEdit) {
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-beneficiary-loss-input-two-create`,
              formData,
              getConfigWithToken()
            );
            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataBeneLoss());
                setEditInput1Files([]);
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", postHarvestIdEdit);
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-beneficiary-loss-input-two-update`,
              formData,
              getConfigWithToken()
            );

            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataBeneLoss());
                setEditInput1Files([]);
                setLoading(false);
              });
            
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleBeneficiaryProfitSubmit = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["beneficiaryProfitMaster"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["beneficiaryProfitMaster"];
      const errorMessages = {
        total_revenue: "Total Revenue is Required",
        net_profit: "Net Profit is Required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsBeneProfit(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const postHarvest = dd["payload"]["beneficiaryProfitMaster"];

        const formData = new FormData();
        for (const key in postHarvest) {
          formData.append(
            key,
            postHarvest[key] !== null ? postHarvest[key] : ""
          );
        }
        formData.append("financial_year", selectYear);
        formData.append("division_id", selectKPMGDivision);
        formData.append("applicant_id", beneStudyTourFarm);

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!postHarvestFlagEdit) {
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-beneficiary-profit-input-two-create`,
              formData,
              getConfigWithToken()
            );
            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataBeneProfit());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", postHarvestIdEdit);
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-beneficiary-profit-input-two-update`,
              formData,
              getConfigWithToken()
            );

            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataBeneProfit());
                setEditInput1Files([]);
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleSubmitUtilizationTPD = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["utilizationTPDTwo"];
    const validateForm = () => {
      const errors = {};
      const errorMessages = {
        existing_crops: "This Field is required",
        is_facility_in_opration: "This Field is required",
        total_quantity_handled_tpd: "This Field is required",
        total_capacity_utilised_tpd: "This Field is required",
        capacity_utilised_job_work: "This Field is required",
        capacity_utilised_captive_used: "This Field is required",
        total_amount: "This Field is required",
        service_rental_charges: "This Field is required",
        processing_charges: "This Field is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsTPDTwo(errors)); // Dispatch errors
      console.log(errors, "errors");
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const postHarvest = dd["payload"]["utilizationTPDTwo"];

        const formData = new FormData();
        for (const key in postHarvest) {
          if (key === "existing_crops") {
            formData.append(
              "existing_crops",
              postHarvest["existing_crops"].join(",")
            );
          } else {
            formData.append(
              key,
              postHarvest[key] !== null ? postHarvest[key] : ""
            );
          }
        }
        formData.append("quarter", selectQuarter);
        formData.append("division_id", selectKPMGDivision);
        formData.append("applicant_id", beneStudyTourFarm);
        formData.append("magnet_crop_id", esiTargetCrop);
       
        formData.append(
          "total_capacity_tpd",
          esiPackHouse !== null ? esiPackHouse : 0
        );
        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!postHarvestFlagEdit) {
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-utilisation-facility-tpd-input-mg2beneficiary-two-create`,
              formData,
              getConfigWithToken()
            );
            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTPDTwo());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", postHarvestIdEdit);
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-utilisation-facility-tpd-input-mg2beneficiary-two-update`,
              formData,
              getConfigWithToken()
            );

            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTPDTwo());
                setEditInput1Files([]);
                setLoading(false);
              });
              
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleSubmitUtilizationColdStorage = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["utilizationColdStorageTwo"];
    const validateForm = () => {
      const errors = {};
      const errorMessages = {
        existing_crops: "This Field is required",
        is_facility_in_opration: "This Field is required",
        total_quantity_handled: "This Field is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsColdStorageTwo(errors)); // Dispatch errors
      console.log(errors, "errors");
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const postHarvest = dd["payload"]["utilizationColdStorageTwo"];

        const formData = new FormData();
        for (const key in postHarvest) {
          if (key === "existing_crops") {
            formData.append(
              "existing_crops",
              postHarvest["existing_crops"].join(",")
            );
          } else {
            formData.append(
              key,
              postHarvest[key] !== null ? postHarvest[key] : ""
            );
          }
        }
        formData.append("quarter", selectQuarter);
        formData.append("division_id", selectKPMGDivision);
        formData.append("applicant_id", beneStudyTourFarm);
        formData.append("magnet_crop_id", esiTargetCrop);
        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!postHarvestFlagEdit) {
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-utilisation-facility-cs-mgbeneficiary-input-two-create`,
              formData,
              getConfigWithToken()
            );
            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataColdStorageTwo());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", postHarvestIdEdit);
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/if-utilisation-facility-cs-mgbeneficiary-input-two-update`,
              formData,
              getConfigWithToken()
            );

            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataColdStorageTwo());
                setEditInput1Files([]);
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeleteBeneProfit = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/if-beneficiary-profit-input-two-delete?id=${postHarvestIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(`${response.data.message}`, "", "success").then(() => {
          refershdata();
          setPostHarvestFlagEdit("");
          dispatch(resetDataBeneProfit());
        });
      } else {
        Swal.fire(`${response.data.message}`, "", "warning");
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "warning");
    }
  };
  };

  const handleDeleteBeneLoss = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/if-beneficiary-loss-input-two-delete?id=${postHarvestIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(`${response.data.message}`, "", "success").then(() => {
          refershdata();
          setPostHarvestFlagEdit("");
          dispatch(resetDataBeneLoss());
        });
      } else {
        Swal.fire(`${response.data.message}`, "", "warning");
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "warning");
    }
  };
  };

  const handleDeleteTPD = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-input-mg2beneficiary-two-delete?id=${postHarvestIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(`${response.data.message}`, "", "success").then(() => {
          refershdata();
          setPostHarvestFlagEdit("");
          dispatch(resetDataTPDTwo());
        });
      } else {
        Swal.fire(`${response.data.message}`, "", "warning");
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "warning");
    }
  };
  };

  const handleDeleteColdStorage = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/if-utilisation-facility-cs-mgbeneficiary-input-two-delete?id=${postHarvestIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(`${response.data.message}`, "", "success").then(() => {
          refershdata();
          setPostHarvestFlagEdit("");
          dispatch(resetDataColdStorageTwo());
        });
      } else {
        Swal.fire(`${response.data.message}`, "", "warning");
      }
    } catch (error) {
      Swal.fire(`${error.message}`, "", "warning");
    }
  };
  };
  
  useEffect(() => {
    const quarterData = async () => {
      try {
        const storedArrayAsString = localStorage.getItem("permission");
        setPermission(storedArrayAsString);

        setYear(years);
        setQuarter(quarter);
        setDivision(divisi);
        setMagentCrop(magentCrp);
        setExisitingMagentCrop(exisitingmagnetCrp);
        const defaultQuarter = quarter.find((q) => q.selected === "Selected");
        if (defaultQuarter) {
          setSelectedQuarters(defaultQuarter.id);
        }

        const defaultYear = years.find((y) => y.value === currentFinancialYear);
        if (defaultYear) {
          setSelectedYear(defaultYear.id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    console.log("Current Financial Year:", currentFinancialYear);
    quarterData();
  }, [
    quarter,
    magentCrp,
    exisitingmagnetCrp,
    years,
    divisi,
    currentFinancialYear,
  ]);

  return (
    <>
      {/* <Online> */}
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div id="exTab3" class="contain">
              <div class="card">
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    bgcolor: "background.paper",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        value={value}
                        onChange={handleChange}
                      >
                        <Tab
                          label="Beneficiary Profit"
                          value="0"
                          className={`${value === "0" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Beneficiary Loss"
                          value="1"
                          className={`${value === "1" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Utilisation of Facility-TPD"
                          value="2"
                          className={`${value === "2" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Utilisation of Facility-Cold Storage"
                          value="3"
                          className={`${value === "3" ? "activeClickCSS" : ""}`}
                        />
                      </Tabs>
                    </Box>
                    {/* Beneficiary Profit */}
                    <TabPanel value="0">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleBeneficiaryProfitSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Year
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectYear}
                                    label="year"
                                    onChange={handleFinYearChange}
                                  >
                                    {Year.map((years) => (
                                      <MenuItem value={years.id} key={years.id}>
                                        {years.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectKPMGDivision}
                                  onChange={handleDivisionKMPGChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="beneficiary-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="beneficiary-select-label"
                                  id="beneficiary-select"
                                  value={beneStudyTourFarm}
                                  onChange={handleBenefiStudyTourFarmChange}
                                  label="FPO/VCO"
                                >
                                  {studyFarmApplicant.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <br />

                          <div className="word-with-line">
                            <span>Details of current Year</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <BeneficiariesProfit />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Attached Report (Financial Statements)
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>
                            <div>
                              {Array.isArray(editInput1Files) &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container" key={e.id}>
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      className="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDeleteFile(e.id)}
                                    ></i>
                                  </div>
                                ))}
                            </div>

                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "If-Beneficiary-Profit-Inp-Two-view"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {postHarvestFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {postHarvestFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    disabled={loading}
                                    onClick={handleDeleteBeneProfit}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Beneficiary loss */}
                    <TabPanel value="1">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleSubmitBeneLoss}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectKPMGDivision}
                                  onChange={handleDivisionKMPGChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="beneficiary-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="beneficiary-select-label"
                                  id="beneficiary-select"
                                  value={beneStudyTourFarm}
                                  onChange={handleBenefiStudyTourFarmChange}
                                  label="FPO/VCO"
                                >
                                  {studyFarmApplicant.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <br />
                          <div className="word-with-line">
                            <span>Details of current Quarter</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <BeneficiariesLoss magentCrop={magentCrop} />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Supporting Document
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>

                            <div>
                              {Array.isArray(editInput1Files) &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container" key={e.id}>
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      className="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDeleteFile(e.id)}
                                    ></i>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "If-Beneficiary-Loss-Inp-Two-view"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {postHarvestFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {postHarvestFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    disabled={loading}
                                    onClick={handleDeleteBeneLoss}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Utilization - TPD */}
                    <TabPanel value="2">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleSubmitUtilizationTPD}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectKPMGDivision}
                                  onChange={handleDivisionKMPGChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="beneficiary-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="beneficiary-select-label"
                                  id="beneficiary-select"
                                  value={beneStudyTourFarm}
                                  onChange={handleFPOVCOUtilizationChange}
                                  label="FPO/VCO"
                                >
                                  {studyFarmApplicant.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <MultiSelect
                              label="Target Crops"
                              data={magentCrop}
                              value={esiTargetCrop || []}
                              readOnly={true}
                            />
                          </div>
                          <InputField
                            label="Total Capacity - TPD"
                            type="text"
                            readOnly={true}
                            value={esiPackHouse}
                          />
                          <br />
                          <div className="word-with-line">
                            <span>Details of current Quarter</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <UtilizationTPDMG
                            existingmagentCrop={existingmagentCrop}
                          />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Remarks
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>

                            <div>
                              {Array.isArray(editInput1Files) &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container" key={e.id}>
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      className="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDeleteFile(e.id)}
                                    ></i>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "If-Utilisation-Facility-tpd-Input-Two-view"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {postHarvestFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {postHarvestFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    disabled={loading}
                                    onClick={handleDeleteTPD}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Utilization - Cold Storage */}
                    <TabPanel value="3">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleSubmitUtilizationColdStorage}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectKPMGDivision}
                                  onChange={handleDivisionKMPGChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="beneficiary-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="beneficiary-select-label"
                                  id="beneficiary-select"
                                  value={beneStudyTourFarm}
                                  onChange={handleFPOVCOUtilizationChange}
                                  label="FPO/VCO"
                                >
                                  {studyFarmApplicant.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>

                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <MultiSelect
                              label="Target Crops"
                              data={magentCrop}
                              value={esiTargetCrop || []}
                              readOnly={true}
                            />
                          </div>
                        
                          <br />
                          <div className="word-with-line">
                            <span>Details of current Quarter</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <UtilizationColdStorageMG />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Remarks
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>

                            <div>
                              {Array.isArray(editInput1Files) &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container" key={e.id}>
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      className="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDeleteFile(e.id)}
                                    ></i>
                                  </div>
                                ))}
                            </div>

                          
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "If-Utilisation-Facility-Cold-Storage-Input-Two-view"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {postHarvestFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {postHarvestFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    disabled={loading}
                                    onClick={handleDeleteColdStorage}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
   
    </>
  );
};
