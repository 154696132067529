import React ,{ useState }from 'react';
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import {  Tab, Tabs, Box, Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, colors } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import clsx from 'clsx';
import "../../../../assets/css/masterdata.css";
import { getConfigWithToken } from '../../../../utils/Config/Config';
import axios from 'axios';
import { setInputFieldData } from '../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice';
import { resetDataEntityMaster, updateApiDataEntityMaster } from '../../../../redux/slice/CapacityBuildingMaster/EntityMasterCapacityBuildingSlice'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from "react-router-dom";
import { EntityDetailCapacity } from "../../../../subcomponents/MasterFormTables/PrimaryTables/CapacityBuildingMaster/EntityDetailCapacity";


const MySwal = withReactContent(Swal);

export const AddCapacityBuilding = () =>{
  const location = useLocation()
  const id = location.state

    const dd = useSelector(setInputFieldData);
    const dispatch = useDispatch();
    
    const [value, setValue] = useState('0');
    const [checkedPanels, setCheckedPanels] = useState(Array(7).fill(false));
    const [newCheckValue, setNewCheckValue] = useState([])
    const [prevCheckValue, setPrevCheckValue] = useState([])

    const apiUrl = process.env.REACT_APP_API_URL;
    const [Applicant, setApplicant] = useState([])
    const [editEntityFlag, setEditEntityFlag] = useState(false)
    const [Division, setDivision] = useState([])
    const [MagnetCrop, setMagentCrop] = useState([])
    const [BeneficiaryType, setBeneficiaryType] = useState([])
    const [taluka, setTaluka] = useState([])
    const [LoanType, setLoanType] = useState([])
    const [handleBioWateData, setHandleBioWateData] = useState({})
   
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    function getFormIdFromName(value){
      const panelNamesTron = {
        "Training on GAP": "3",
        "Capacity building on SME Linkages": "7",
        "Capacity building on post harvest management": "2",
        "Value chain accelarion services" : "1",
        "Trade fair and exibition" : "33",
        "Study tour for exposure visit" : "25",
        "Market development and promotion" : "32",
      }

      return panelNamesTron[value]
    }

    const handleCheckboxChange = (panelIndex) => {
        const panelName = panelNames[panelIndex];
        const idForm = getFormIdFromName(panelName)
        if (checkedPanels[panelIndex]) {
          // If the checkbox is currently unchecked, show the confirmation alert
          // If the checkbox is currently checked, remove the corresponding idForm from newCheckValue
          setNewCheckValue((prevArray) => prevArray.filter((id) => id !== idForm));
          MySwal.fire({
            title: 'Are you sure?',
            text: `Do you want to Remove the ${panelName}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Remove it!',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              // User confirmed, update the checkbox state
              updateCheckbox(panelIndex);
            }
          });
        } else {
          // If the checkbox is currently unchecked, add the idForm to newCheckValue
          setNewCheckValue((prevArray) => [...prevArray, idForm]);
          // If the checkbox is currently checked, update the state directly
          updateCheckbox(panelIndex);
        }
      };
    
      const updateCheckbox = (panelIndex) => {
        setCheckedPanels((prev) => {
          const updatedCheckedPanels = [...prev];
          updatedCheckedPanels[panelIndex] = !prev[panelIndex];
          return updatedCheckedPanels;
        });
    
        // Close the SweetAlert (if it is open)
        MySwal.close();
      };


    const handleSaveChange = async (e) => {
      e.preventDefault()
      console.log(newCheckValue,"newCheckValueeee")
      console.log(checkedPanels,"sssssssssssssa")
      console.log(prevCheckValue, "Prev Checkkkkk Valueeeee")
      console.log(dd["payload"]["entityMasterCapacityBuildingMaster"],"ssssssssssssssssssssss")

      const transformedData = prevCheckValue.reduce((acc, formId) => {
        acc[formId] = { ...dd["payload"]["entityMasterCapacityBuildingMaster"] };
        return acc;
      }, {});

      const transformed = newCheckValue.reduce((acc, formId) => {
        acc[formId] = { ...dd["payload"]["entityMasterCapacityBuildingMaster"] };
        return acc;
      }, {});
      
      const newPayload = {
        ...dd["payload"]["entityMasterCapacityBuildingMaster"],
        form_id: transformed,
      };


      const updatedPayload = {
        ...dd["payload"]["entityMasterCapacityBuildingMaster"],
        form_id: transformedData,
        new_entry: transformed,
      };

      if(!editEntityFlag){
        const entityCreate = await axios.post(`${apiUrl}api/input-one-primary-data-create-gap`,newPayload ,getConfigWithToken())
        
        if(entityCreate.data["status"] === true){
            Swal.fire(
                `${entityCreate.data["message"]}`,
                '',
                'success'
            )
        }else{
            Swal.fire(
                `${entityCreate.data["message"]}`,
                '',
                'warning'
            )
        }
        }else{
        const entityCreate = await axios.post(`${apiUrl}api/input-one-primary-data-update-gap`,updatedPayload ,getConfigWithToken())
        
        if(entityCreate.data["status"] === true){
            Swal.fire(
                `${entityCreate.data["message"]}`,
                '',
                'success'
            )
        }else{
            Swal.fire(
                `${entityCreate.data["message"]}`,
                '',
                'warning'
            )
        }
        }
    
    
    }

    
    const panelNames = [
      "Training on GAP",
      "Capacity building on SME Linkages",
      "Capacity building on post harvest management",
      "Value chain accelarion services",
      "Trade fair and exibition",
      "Study tour for exposure visit",
      "Market development and promotion",
      ];
    
      const renderTable = (items) => (
        <TableContainer component={Paper} style={{ margin: '16px 5rem 0rem 5rem', backgroundColor: '#E2E2E2', border: '2px solid #9C9C9C' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'black', fontSize: '18px', fontWeight: '700px', border: '1px solid #9C9C9C' }}>Forms</TableCell>
                <TableCell style={{ color: 'black', fontSize: '18px', fontWeight: '700px', border: '1px solid #9C9C9C' }}>Applicability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((panelName, panelIndex) => (
                <TableRow key={panelIndex}>
                  <TableCell style={{ border: '1px solid #9C9C9C' }}>{panelName}</TableCell>
                  <TableCell style={{ border: '1px solid #9C9C9C', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Checkbox
                      checked={checkedPanels[panelNames.indexOf(panelName)]}
                      onChange={() => handleCheckboxChange(panelNames.indexOf(panelName))}
                     
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );

  useEffect(()=>{
    dispatch(resetDataEntityMaster());
    const MGBeneficiaryData = async () => {
        try {
            const applicant = await axios.get(`${apiUrl}api/get-all-beneficiary-master?all=1`,getConfigWithToken());  
            const division = await axios.get(`${apiUrl}api/getAllDivisions?all=1`,getConfigWithToken()); 
            const beneficiaryType = await axios.get(`${apiUrl}api/get-all-beneficiary-type?all=1`,getConfigWithToken());  
            const taluka = await axios.get(`${apiUrl}api/get-all-taluka-with-village?all=1`, getConfigWithToken())

            const applicantData = applicant.data["data"].map((e)=>({"id":e["id"],"beneficiary_name":e["beneficiary_name"]}));
            const divisionData = division.data["data"].map((e)=>({"id":e["id"],"divisions":e["divisions"]}));
            const beneficiaryTypeData = beneficiaryType.data["data"].map((e)=>({"id":e["id"],"beneficiary_type":e["beneficiary_type"]}));
            
            setApplicant(applicantData);
            setDivision(divisionData);
            setBeneficiaryType(beneficiaryTypeData);
            setTaluka(taluka.data.data)
            
            } catch (error) {
            console.error("Error fetching data:", error);
            }

            if(id){
              const panelNamesTron = {
                "Training on GAP": "3",
                "Capacity building on SME Linkages": "7",
                "Capacity building on post harvest management": "2",
                "Value chain accelarion services" : "1",
                "Trade fair and exibition" : "33",
                "Study tour for exposure visit" : "25",
                "Market development and promotion" : "32",
              }
              const editData = await axios.get(`${apiUrl}api/input-one-primary-data-edit-gap?beneficiary_id=${id}`, getConfigWithToken())
              editData.data["flag"] ? setEditEntityFlag(true) : setEditEntityFlag(false)


              const panelNames = [
                "Training on GAP",
                "Capacity building on SME Linkages",
                "Capacity building on post harvest management",
                "Value chain accelarion services",
                "Trade fair and exibition",
                "Study tour for exposure visit",
                "Market development and promotion",
              ];
              
              const formsData = editData.data.data[0]

              console.log(formsData);
              const eee = Object.keys(formsData.form_id)
              // Remove the existing "3", "2", and "7" keys from the data
              console.log(eee, "dcdsddssdcsddddd99999999999999999")
              setPrevCheckValue(eee)
              const keysFromPanelNamesTron = eee.map((value) => Object.keys(panelNamesTron).find((key) => panelNamesTron[key] === value));

              const indices = keysFromPanelNamesTron.map((key) => panelNames.indexOf(key));
              console.log(indices,"indices")
              const updatedCheckedPanels = checkedPanels.map((value, index) => indices.includes(index) ? true : value);
              console.log(updatedCheckedPanels,"updatedCheckedPanels")
              setCheckedPanels(updatedCheckedPanels);

                // Extracted data
              const extractedData = [];

              // Iterate through the 'form_id' keys
              for (const formId in formsData.form_id) {
                if (formsData.form_id.hasOwnProperty(formId)) {
                  const formData = formsData.form_id[formId];
                  
                  // Check if the required keys are present and not empty
                  if (
                    formData.beneficiary_id &&
                    formData.beneficiary_type_id &&
                    formData.district_id &&
                    formData.division_id &&
                    formData.taluka_id
                  ) {
                    // Create an object with the required data
                    const extractedItem = {
                      beneficiary_id: formData.beneficiary_id,
                      beneficiary_type_id: formData.beneficiary_type_id,
                      district_id: formData.district_id,
                      division_id: formData.division_id,
                      taluka_id: formData.taluka_id
                    };

                    // Add the object to the extracted data array
                    extractedData.push(extractedItem);
                  }
                }
              }

              console.log(extractedData,"extractedData")
              dispatch(updateApiDataEntityMaster(extractedData[0]))


            }
        };
       
    
MGBeneficiaryData();
},[])

    return(<>
    <Header/>
    <LeftNavigation/>
    <main id="main" className="main">
          <section className="section">
            <div className="row">              
              <div id="exTab3" className="contain" >
                <div className="cardMaster" id="" style={{ height: '1500px' }}>
                <form class="row g-3">
                        <Box className="your-component" sx={{ typography: 'body1' }}>
                            <div className="words-with-lines" >
                            <span>Capacity Building - Master</span>
                            </div>
                            <div className="msambstyle">
                            <a href="./capacityBuildingMaster">
                                <i className="fa-solid fa-arrow-left"></i>Back
                            </a>
                            <button type="submit" className="save-btn" onClick={handleSaveChange}>Save</button>
                            <button className="dropdown-btn"><i className="fa-solid fa-caret-down" ></i></button>
                            </div>
                        </Box>
                            {/* Tabs */}
                            <Box sx={{ width: '92%', height:'500px', marginLeft:"5rem", marginTop:"3.5rem", typography: 'body1'}}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs style={{width: "70rem"}} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange}>
                                          <Tab label="Entity Details" value="0" className={clsx(value === '0' && 'activeClickCSS')} style={{border:'2px solid #4e2683',borderRadius:'4px'}}/>
                                        </Tabs>
                                    </Box>
                                  <TabPanel value="0">
                                        <EntityDetailCapacity id={id} editFlag={editEntityFlag} MagnetCrop={MagnetCrop} Applicant={Applicant} Division={Division} BeneficiaryType={BeneficiaryType} />
                                        <br/>
                                    <Grid container spacing={10}>
                                    <Grid item xs={5}>
                                        {renderTable(panelNames.slice(0, 4))}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {renderTable(panelNames.slice(4))}
                                    </Grid>
                                    </Grid>
                                </TabPanel>
                                </TabContext>  
                            </Box>
                       </form>
                        </div>
                    </div> 
                </div> 
            </section>
        </main>
    </>)
}