import React from "react";
import { Box } from "@mui/material";
import { Header } from "../../../Header";
import { LeftNavigation } from "../../../LeftNavigation";
import { Link } from "react-router-dom";
import { MSAMBListing } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MSAMBMaster/MSAMBListing";
import '../../../../assets/css/masterdata.css'
import Button from '@mui/material/Button';

export const MSAMBMasterListing = () => {
  return(<>
    <Header/>
    <LeftNavigation/>
    <main id="main" class="main">
            <section class="section">
                <div class="row">
                    <div id="exTab3" class="contain" >	           
                        <div class="cardMaster" id="" style={{height:'max-content'}}>
                            <div className="words-with-lines" id="Heading" >
                                <br/>
                              <span>MSAMB Master</span>
                            </div>
                            {/* Add FIL/Back */}
                            <Box className='AddHeader' sx={{ typography: 'body1', }}>                
                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end",height:'70px',marginRight:"2rem"}}> 
                                <a href="./masterdata" class="back-link" style={{marginRight:'1rem'}}>
                                    <i class="fa-solid fa-arrow-left"></i>Back
                                </a>
                                <Link to="/addBeneficiaryMSAMBMaster">
                                    <Button class="add-fil-button">
                                        Add MSAMB
                                    </Button>
                                </Link>
                                <button class="caret-button"><i class="fa-solid fa-caret-down"></i></button>
                            </div>
                            </Box>   
                          
                            {/* Listing Table */}
                            <MSAMBListing/>
                        </div>
                    </div> 
                </div> 
            </section>
        </main>
    </>)
};
