import React from "react";
import { Offline, Online } from "react-detect-offline";
import "../../../../assets/css/masterdata.css";
import {
  resetValidation,
  setInputFieldData,
} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";

export const BeneficiaryFacilitiesMaster = () => {
  const dispatch = useDispatch();
  const mgBeneficiaryMaster = useSelector((state) => state.mgBeneficiaryMaster);

  const handleFieldColdStorage = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "cold_storage",
        value: event.target.value,
      })
    );
  };
  const handleFieldPackHouse = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "pack_house_tpd",
        value: event.target.value,
      })
    );
  };
  const handleFieldSecondProcess = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "secondary_processing_tpd",
        value: event.target.value,
      })
    );
  };
  const handleFieldTerTiaryProcess = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "tertiary_processing_tpd",
        value: event.target.value,
      })
    );
  };
  const handleFieldkey_components = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "key_components",
        value: event.target.value,
      })
    );
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  return (
    <>
      {/* <Online> */}
      <div className="tab-pane">
        <form class="row g-3">
          {/* Cold Storage (MT) */}
          <div className="primaryTableLabel">
            <label htmlFor="ColdStorage">Cold Storage (MT)</label>
            <input
              type="number"
              name="ColdStorage"
              value={mgBeneficiaryMaster.cold_storage}
              onChange={handleFieldColdStorage}
              onFocus={handleResetFocus}
              className="custom"
              style={{ width: "30%" }}
            />
          </div>

          {/* Pack House (TPD) */}
          <div className="primaryTableLabel">
            <label htmlFor="PackHouse">Pack House (TPD)</label>
            <input
              type="number"
              name="PackHouse"
              value={mgBeneficiaryMaster.pack_house_tpd}
              onChange={handleFieldPackHouse}
              onFocus={handleResetFocus}
              className="custom"
              style={{ width: "30%" }}
            />
          </div>

          {/* Secondary Processing (TPD) */}
          <div className="primaryTableLabel">
            <label htmlFor="SecProcessing">Secondary Processing (TPD)</label>
            <input
              type="number"
              name="SecProcessing"
              value={mgBeneficiaryMaster.secondary_processing_tpd}
              onChange={handleFieldSecondProcess}
              onFocus={handleResetFocus}
              className="custom"
              style={{ width: "30%" }}
            />
          </div>

          {/* Tertiary Processing (TPD) */}
          <div className="primaryTableLabel">
            <label htmlFor="TerProcessing">Tertiary Processing (TPD)</label>
            <input
              type="number"
              name="TerProcessing"
              value={mgBeneficiaryMaster.tertiary_processing_tpd}
              onChange={handleFieldTerTiaryProcess}
              onFocus={handleResetFocus}
              className="custom"
              style={{ width: "30%" }}
            />
          </div>

          {/*  key_components */}
          <div className="primaryTableLabel">
            <label htmlFor="KeyComponent"> Key Components</label>
            <input
              type="text"
              name="KeyComponent"
              value={mgBeneficiaryMaster.key_components}
              onChange={handleFieldkey_components}
              onFocus={handleResetFocus}
              className="custom"
              style={{ width: "30%" }}
            />
          </div>
        </form>
      </div>
      {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>
  );
};
