import React, { useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "../../../../assets/css/masterdata.css";
import {
  resetValidation,
  setInputFieldData,
} from "../../../../redux/slice/FILMaster/FILMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import axios from "axios";
import { useEffect } from "react";

export const FILMasterDetails = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const dispatch = useDispatch();
  const filMaster = useSelector((state) => state.filMaster);
  const TypeOfAssistance = [
    {
      id: 1,
      Assistance_Type: "Working Capital",
    },
    {
      id: 2,
      Assistance_Type: "Term Loan",
    },
  ];
  const handleFieldApplicant = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "applicant_name",
        value: event.target.value,
      })
    );
  };
  const handleFieldDivision = (event) => {
    const divisionId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "division_id", value: divisionId })
    );

    // Fetch districts based on the selected division
    axios
      .get(
        `${apiUrl}api/district-by-division?division_id=${divisionId}`,
        getConfigWithToken()
      )
      .then((response) => {
        console.log("SOmething: --------", response.data.data);
        setDistricts(response.data.data);
        setTalukas([]); // Reset talukas when division changes
      })
      .catch((error) => {
        console.error("Error fetching districts:", error);
      });
  };

  const handleFieldDistrict = (event) => {
    const districtId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "district_id", value: districtId })
    );

    // Fetch talukas based on the selected district
    axios
      .get(
        `${apiUrl}api/get-taluka-by-district?district_id=${districtId}`,
        getConfigWithToken()
      )
      .then((response) => {
        setTalukas(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching talukas:", error);
      });
  };
  const handleFieldTaluka = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "taluka_id", value: event.target.value })
    );
  };
  const handleFieldBank = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "bank_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldEntityType = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "entity_type_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldMAGNETCrop = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "magnet_crop_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldExisitingCrop = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "existing_crop_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldExisitingInfra = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "existing_infrastructure",
        value: event.target.value,
      })
    );
  };
  const handleFieldAssistanceType = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "type_of_assistance",
        value: event.target.value,
      })
    );
  };
  const handleFieldLoanPurpose = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "purpose_of_loan",
        value: event.target.value,
      })
    );
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  useEffect(() => {
    if (props.initialValues) {
      const initialValues = props.initialValues;

      const existingCropArray = JSON.parse(
        initialValues.existing_crop_id || "[]"
      );
      const magnetCropArray = JSON.parse(initialValues.magnet_crop_id || "[]");

      dispatch(
        setInputFieldData({
          fieldName: "applicant_name",
          value: initialValues.applicant_name || "",
        })
      );
      dispatch(
        setInputFieldData({
          fieldName: "division_id",
          value: initialValues.division_id || "",
        })
      );
      dispatch(
        setInputFieldData({
          fieldName: "district_id",
          value: initialValues.district_id || "",
        })
      );
      dispatch(
        setInputFieldData({
          fieldName: "taluka_id",
          value: initialValues.taluka_id || "",
        })
      );
      dispatch(
        setInputFieldData({
          fieldName: "entity_type_id",
          value: initialValues.entity_type_id || "",
        })
      );

      const formattedExistingCropArray = existingCropArray.map(
        (crop) => crop.id
      );
      const formattedMagnetCropArray = magnetCropArray.map((crop) => crop.id);

      dispatch(
        setInputFieldData({
          fieldName: "existing_crop_id",
          value: formattedExistingCropArray,
        })
      );
      dispatch(
        setInputFieldData({
          fieldName: "magnet_crop_id",
          value: formattedMagnetCropArray,
        })
      );

      axios
        .get(
          `${apiUrl}api/district-by-division?division_id=${initialValues.division_id}`,
          getConfigWithToken()
        )
        .then((response) => {
          setDistricts(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
        });

      axios
        .get(
          `${apiUrl}api/get-taluka-by-district?district_id=${initialValues.district_id}`,
          getConfigWithToken()
        )
        .then((response) => {
          setTalukas(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching talukas:", error);
        });
    }
  }, [props.initialValues]);

  useEffect(() => {
    console.log("taluka id", filMaster.taluka_id);
    console.log("district id", filMaster.district_id);
    console.log("division id", filMaster.division_id);
    console.log("exisiting crop", filMaster.existing_crop_id);
    console.log("magnet crop", filMaster.magnet_crop_id);
  }, [filMaster.taluka_id, filMaster.district_id, filMaster.division_id]);

  return (
    <>
      {/* <Online> */}
        <div className="tab-pane">
          <form class="row g-3">
            {/* Name of the Borrower */}
            <div className="primaryTableLabel">
              <label htmlFor="Borrower">Name of the Borrower</label>
              <input
                type="text"
                name="Borrower"
                value={filMaster.applicant_name}
                onChange={handleFieldApplicant}
                onFocus={handleResetFocus}
                className="customInput"
                style={{ width: "50%" }}
              />
            </div>

            {/* Division */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Division">Division</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Division"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.division_id}
                    onChange={handleFieldDivision}
                    onFocus={handleResetFocus}
                  >
                    {props.Division.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.divisions}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* District */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="District">District</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="District"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.district_id}
                    onChange={handleFieldDistrict}
                    onFocus={handleResetFocus}
                  >
                    <MenuItem value="">Select District</MenuItem>
                    {districts.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.district}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Taluka */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Taluka">Taluka</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Taluka"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.taluka_id}
                    onChange={handleFieldTaluka}
                    onFocus={handleResetFocus}
                  >
                    {talukas.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.taluka}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Entity Type */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="entity type">Entity Type</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="entity type"
                    className="customSelect"
                    style={{ width: "70%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.entity_type_id}
                    onChange={handleFieldEntityType}
                    onFocus={handleResetFocus}
                  >
                    {props.EntityType.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.entity_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Bank */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="bank">Bank</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="bank"
                    className="customSelect"
                    style={{ width: "70%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.bank_id}
                    onChange={handleFieldBank}
                    onFocus={handleResetFocus}
                  >
                    {props.Bank.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.bank_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Existing Magnet Crops */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="ExistingCrop">Existing Magnet Crops</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="ExistingCrop"
                    className="customSelect"
                    style={{ width: "70%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      Array.isArray(filMaster.existing_crop_id)
                        ? filMaster.existing_crop_id
                        : filMaster.existing_crop_id === ""
                        ? []
                        : [filMaster.existing_crop_id]
                    }
                    onChange={handleFieldExisitingCrop}
                    onFocus={handleResetFocus}
                    multiple
                  >
                    {props.ExistingCrop.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.crop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Magnet Crops */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Crop">Magnet Crops</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Crop"
                    className="customSelect"
                    style={{ width: "70%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      Array.isArray(filMaster.magnet_crop_id)
                        ? filMaster.magnet_crop_id
                        : filMaster.magnet_crop_id === ""
                        ? []
                        : [filMaster.magnet_crop_id]
                    }
                    onChange={handleFieldMAGNETCrop}
                    onFocus={handleResetFocus}
                    multiple
                  >
                    {props.MagnetCrop.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.crop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* ExistingInfrastructure */}
            <div className="primaryTableLabel">
              <label htmlFor="ExistingInfrastructure">
                Existing Infrastructure
              </label>
              <input
                type="text"
                name="ExistingInfrastructure"
                value={filMaster.existing_infrastructure}
                onChange={handleFieldExisitingInfra}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "32%" }}
              />
            </div>

            {/* Type of Assistence */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Assistence">Type of Assistence</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Assistence"
                    className="customSelect"
                    style={{ width: "70%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filMaster.type_of_assistance}
                    onChange={handleFieldAssistanceType}
                    onFocus={handleResetFocus}
                  >
                    {TypeOfAssistance.map((e) => (
                      <MenuItem value={e.id}>{e.Assistance_Type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Purpose of Loan */}
            <div className="primaryTableLabel">
              <label htmlFor="loan">Purpose of Loan</label>
              <input
                type="text"
                name="loan"
                value={filMaster.purpose_of_loan}
                onChange={handleFieldLoanPurpose}
                onFocus={handleResetFocus}
                className="customInput"
                style={{ width: "32%" }}
              />
            </div>
          </form>
        </div>
      {/* </Online>
      <Offline>You're Offline, Please Check your Connection</Offline> */}
    </>
  );
};
