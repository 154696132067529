import { InputField } from "../../InputField";
import React from "react";
import "../../../assets/css/inputForm.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
} from "../../../redux/slice/InputTwo/MasterSubSlice";
import pdf from "../../../assets/PDF_file_icon.svg.png";
import imgIcon from "../../../assets/image_icon-icons.com_50366.png";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { DatePickInput3 } from "../../DatePickInput3";
import { DatePick } from "../../DatePick";

export function MasterSub(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const {
    expenditureIncurred,
    totalProjectCost,
    totalPromotersContribution,
    amountDisbrushment,
    disbursement_date,
    tranche,
    totalMatchingGrant,
    
    totalExpenditureTD,
    physicalProgressTD,
   
    totalTermLoan,
    validationErrors,
    physical_progress,
  } = useSelector((state) => state.masterFormData);

 
  const updateExpenditure = (
    termLoan,
    matchingGrantExpenditureInc,
    promoters
  ) => {
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      parseFloat(termLoan) +
      parseFloatOrZero(matchingGrantExpenditureInc) +
      parseFloat(promoters);
    dispatch(
      setInputFieldData({ fieldName: "expenditureIncurred", value: newValue })
    );
  };

  const handleTermLoanChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "totalTermLoanTD", value }));
  };

  const handleTotalExpenditureChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "totalExpenditureTD", value }));
  };

  const handlePhysicalProgressChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "physicalProgressTD", value }));
  };


  const handleTotalProjectCostChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "totalProjectCost", value }));
  };

  const handlePromotersContributionChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "totalpromotersContribution", value })
    );
  };

  const handleMatchingGrantChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "totalMatchingGrant", value }));
  };


  const handlePhysicalFinancialChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "physical_progress", value }));
  };

  const handleExpenditureIncurredChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "expenditureIncurred", value }));
  };

  /*****************  ************ */
  const handleFileChange = (e) => {
   
    console.log(e.target.files[0], "e.target.files[0]");
    props.fileChange(e.target.files);
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  // console.log(props.amountDisbursement)

  const handleDeleteFile = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/remark-delete?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = props.phySelectData.remarks.filter(
        (remark) => remark.file_id !== id
      );
      // Update the state with the new remarks data
      props.setPhySelectData({
        ...props.phySelectData,
        remarks: newRemarks,
      });
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };

  return (
    <>
      <div class="row g-3">
        {/* <p className="subHeading">Current Quarter</p> */}
        <InputField
          label="Total Project Cost"
          type="number"
          endAr="(in lakhs)"
          value={totalProjectCost ? totalProjectCost.toString() : ""}
          onChange={handleTotalProjectCostChange}
          readOnly={true}
        />
        <InputField
          label="Total Promoters Contribution"
          type="number"
          endAr="(in lakhs)"
          value={
            totalPromotersContribution
              ? totalPromotersContribution.toString()
              : ""
          }
          onChange={handlePromotersContributionChange}
          readOnly={true}
        />
        <InputField
          label="Total Matching Grant"
          type="number"
          endAr="(in lakhs)"
          value={totalMatchingGrant ? totalMatchingGrant.toString() : ""}
          onChange={handleMatchingGrantChange}
          readOnly={true}
        />
        <InputField
          label="Total Term Loan"
          type="number"
          endAr="(in lakhs)"
          value={totalTermLoan ? totalTermLoan.toString() : ""}
          onChange={handleTermLoanChange}
          readOnly={true}
        />
      </div>
      <br />
      <div className="word-with-line">
        <span>Component wise Information</span>
        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
      </div>
      <br />
      <div className="componentSub">
        <div class="row g-3">
        
           <InputField
          label="Payment/ Tranche"
          type="text"
          value={tranche ? tranche.toString() : ""}
          readOnly={true}
        />
          <InputField
            label="Amount Disbursement"
            type="number"
            endAr="(in lakhs)"
            value={amountDisbrushment ? amountDisbrushment.toString() : ""}
            readOnly={true}
          />
          <DatePickInput3
            label="Disbursement Date"
            className="custom-date-picker"
            disbrushDate={disbursement_date ? disbursement_date.toString() : ""}
            readOnly={true}
          />

         
          <InputField
            label="Total expenditure till date"
            type="number"
            endAr="(in lakhs)"
            value={totalExpenditureTD ? totalExpenditureTD.toString() : ""}
            onChange={handleTotalExpenditureChange}
            readOnly={true}
          />
          <InputField
            label="Financial Progress till date"
            type="number"
            endAr="%"
            value={physicalProgressTD ? physicalProgressTD.toString() : 0 }
            onChange={handlePhysicalProgressChange}
            readOnly={true}
          />
          
        </div>
        <br />
        <div className="word-with-line">
          <span>
            Details of expenditure for Current Quarter - {props.compName}
          </span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <br />
        <div class="row g-3">
          <div style={{ display: "flex", alignItems: "center" }}>
           
              <InputField
                label="Expenditure incurred"
                type="number"
                endAr="(in lakhs)"
                value={expenditureIncurred ? expenditureIncurred.toString() : ""}
                onChange={handleExpenditureIncurredChange}
                onFocus={handleResetFocus}
                error={!!validationErrors.expenditureIncurred}
                helperText={validationErrors.expenditureIncurred}
              />

            {props.compName === "Technical Civil Works" ? (
              <InputField
                label="Physical Progress"
                type="number"
                endAr="(in %)"
                value={physical_progress ? physical_progress.toString() : ""}
                onChange={handlePhysicalFinancialChange}
                onFocus={handleResetFocus}
                error={!!validationErrors.physical_progress}
                helperText={validationErrors.physical_progress}
              />
            ) : (
              ""
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              class="col-md-6"
              style={{ position: "relative", right: "15px", bottom: "10px" }}
            >
              <label for="inputNumber" class="col-lg col-form-label">
                Upload supporting document. (e.g QPR, Photos etc.)
              </label>
              <div class="col-sm-10">
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="remarks"
                  multiple
                  onChange={handleFileChange}
                />
                <span style={{ color: "red" }}>{props.errorSelectFile}</span>
              </div>
            </div>
            <div>
              {props.phySelectData
                ? props.phySelectData["remarks"]
                  ? props.phySelectData["remarks"].map((e) => (
                      <div className="icon-container">
                        <a
                          href={`${e.file_name}`}
                          rel="noreferrer"
                          target="_blank"
                          style={{ marginRight: "10px" }}
                        >
                          <img
                            src={e.type === "pdf" ? pdf : imgIcon}
                            alt=""
                            height="30px"
                            width="26px"
                          />
                        </a>
                        <i
                          class="fa-regular fa-circle-xmark"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteFile(e.file_id)}
                        ></i>
                      </div>
                    ))
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
