import React, { useEffect, useMemo, useState } from 'react';
import { useTable} from 'react-table';
import '../../assets/css/OutputTable.css'
import { Box, CircularProgress,Button, FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getConfigWithToken } from '../../utils/Config/Config';
import AxiosRetry from 'axios-retry';
import * as XLSX from 'xlsx';

export const OutputTableGenderInclusion = ({setDivision, financialYear, currentFinancialYear, currentQuarter}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([])
  const [selectDivision, setSelectDivision] = useState([])
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024")
  const [selectSubFilter, setSelectSubFilter] = useState("Q3")
  const [tableData, setTableData] = useState([])

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });


  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }else{
      const divisionData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }
  }

  const handleSelectFinancialYear = async (e) =>{
    setSelectFinancialYear(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }else{
      const divisionData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }
  }

  const handleSubFilter = async (e) =>{
    if(selectFinnacialYear.length === 0){
      Swal.fire(
        `Please Select Financial Year`,
        '',
        'warning'
      )
      return
    }
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(e.target.value)){
  
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterData.data.data[0])
      
    }else{
  
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/enforcement-process-input-three-output?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterData.data.data[0])
    }

  }

  useEffect(()=>{
    try{
      async function getData(){
        const empFPOComponentResponse = await axiosInstance.get(`${apiUrl}api/enforcement-process-input-three-output?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`, getConfigWithToken())

        setTableData(empFPOComponentResponse.data.data[0])
        setDivisions(setDivision);
        setSelectFinancialYear(currentFinancialYear)
        setSelectSubFilter(currentQuarter)
        setLoading(false);
      }
      
      setTimeout(() => {
        getData()
        // setLoading(false);
    
      }, 2000);
      
    }catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire(
          'An refresh the Page',
          '',
          'error'
      );
      } else {
        throw new Error('Data Not Found');
      }
    }
     
  },[setDivision, financialYear, currentFinancialYear, currentQuarter])
    
    const columns = useMemo(
        () => [
           {
            Header: 'Work Package', 
            accessor: 'work_package',
          },
          {
            Header: 'Name of MSAMB Facility', 
            accessor: 'msamb_facility_name',
          },
          
          {
            Header: 'Division', 
            accessor: 'divisions',
          },
          {
            Header: 'Total number of toilets for Men',
            accessor: 'total_num_of_toilets_men',
          },
          {
            Header: 'Total number of toilets for Women',
            accessor: 'total_num_of_toilets_women',
          },
          {
            Header: 'Total number of toilets for disabled',
            accessor: 'total_num_of_toilets_disabled',
          },
          {
            Header: 'Are there ramps for the disabled (Yes/ No)',
            accessor: 'ramps_for_disabled',
          },
          {
            Header: 'Is the work environment safe for women workers (safety measures as per labor laws) (Yes/ No)',
            accessor: 'work_env_safe_for_women',
          },
          {
            Header: 'Are there separate changing rooms (if possible) for men and women (Yes/ No)',
            accessor: 'separate_changing_rooms',
          },
          {
            Header: 'Is there a committee of women members to report and investigate the sexual harassment, exploitation and abuse of women workers? (Please report nos of complaints received and acted upon during reporting period)',
            accessor: 'committee_of_women',
          },
          {
            Header: 'Is the practice of forced labor followed? (For example, do workers have to work for a contractor until the loan is repaid?)',
            accessor: 'practice_of_forced_labor',
          },
          {
            Header:'Is the Compensation Act enforced? (For example, is there any compensation paid by the contractor to the worker in case of injury while on duty and at work, and to the dependents in case of death?)',
            accessor:'compensation_act_enforced',
          },
          {
            Header:"Are workers' safety regulations followed during construction? (For example, workers are provided with a protective cap on their head, safety goggles, a doctor at work, high visibility clothing, building safety nets, the facility to stay at the construction site until construction begins?)",
            accessor:'safety_reg_followed',
          },
          {
            Header:'Are labor laws pertaining to workers folowed? (For e.g. 8 hours work a day, no heavy duty workload for pregnant women)',
            accessor:'labor_law_followed',
          },
          {
            Header:'Is the workplace environment clean and safe for workers ?',
            accessor:'workplace_clean_and_safe',
          },
          {
            Header:"Whether the rule 'equal pay for equal work' is being followed for men and women workers ?",
            accessor:'equal_pay_for_equal_work_followed',
          },
          {
            Header:'Whether the dispute between the contractor and the worker resolved as per legal procedures ?',
            accessor:'dispute_resolved',
          },
          {
            Header:'Is bonus paid to workers as per the payment of bonus act?',
            accessor:'bonus_paid',
          },
        ],
        []
      );
  
  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData, 
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc', // Add border style here
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
  };

  const handleExport = () => {
    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
    let formattedData;
  
    if (tableData.length === 0) {
      // Create a single row with only headers if there's no data
      formattedData = [{
        "Financial Year": selectFinnacialYear,
        "Period": selectSubFilter,
        ...(divisionName !== 'All' && { "Division": divisionName })
      }];
  
      columns.forEach(column => {
        formattedData[0][column.Header] = ""; // Empty value for the headers
      });
    } else {
      // Map table data and conditionally add the "Division" column
      formattedData = tableData.map(row => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          "Period": selectSubFilter,
        };
  
        if (divisionName !== 'All') {
          formattedRow["Division"] = divisionName;
        }
  
        columns.forEach(column => {
          formattedRow[column.Header] = row[column.accessor];
        });
  
        return formattedRow;
      });
    }
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");
  
    const fileName = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_MSAMB_GESI.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };
  

  if (loading) {
    return (<>
        <Box sx={{ display: 'flex' }}>
        <CircularProgress />
        </Box>
    </>);
  }
  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
          <div className='col-md-5'>

          </div>
          <div className='col-md-7'>
            <div className='row'>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Financial Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectFinnacialYear}
                      label="Financial Year"
                      onChange={handleSelectFinancialYear}
                    >
                      {
                        financialYear.map((e)=>(
                          <MenuItem value={e}>{e}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-native-select">Period:</InputLabel>
                    <Select defaultValue="" id="grouped-select" label="Period" 
                    value={selectSubFilter} 
                    onChange={handleSubFilter}>
                      <ListSubheader>Half Yearly</ListSubheader>
                      <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                      <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                      <ListSubheader>Quarterly</ListSubheader>
                      <MenuItem value="Q1">Q1</MenuItem>
                      <MenuItem value="Q2">Q2</MenuItem>
                      <MenuItem value="Q3">Q3</MenuItem>
                      <MenuItem value="Q4">Q4</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Division</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectDivision}
                      label="Division"
                      onChange={handleSelectDivision}
                    >
                      {
                        divisions.map((e)=>(
                          <MenuItem value={e.id} key={e.id}>{e.division_name}</MenuItem>
                        ))
                      }
                    </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={handleExport}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "155px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {/* removed the  maxHeight: '5000px' until the header names get shortend */}
      <div style={{ width: '100%', overflowX: 'auto',  }}> 
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                    style={{
                      borderBottom: '2px solid #ccc',
                      background: '#f2f2f2', // Add grey background color
                      fontWeight: 'bold', // Optionally make text bold
                      border: '2px solid #ccc',
                      ...cellStyle // Add border style here
                  }}>
                  {column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          border: '1px solid #ccc',
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          </table>
      </div>
    </>
  );
};


