import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ErrorBoundary } from "react-error-boundary";
import { ServerPage } from "./ErrorPage/ServerPage";
import { OutputTableCapacityBuildingGAP } from "../components/OutputOne/OutputTableCapacityBuildGAP";
import { OutputTableFinAccInfra } from "../components/OutputOne/OutputTableFinAccInfra";
import { OutputTableHDP } from "../components/OutputOne/OutputTableHDP";
import { OutputTableInnovation } from "../components/OutputOne/OutputTableInnovation";
import { OutputTableFarmerSupport } from "../components/OutputOne/OutputTableFarmerSupport";
import { OutputTableMRLTesting } from "../components/OutputOne/OutputTableMRLTesting";
import { OutputTableFruitCareAct } from "../components/OutputOne/OutputTableFruitCare";
import { OutputTableEventVisit } from "../components/OutputOne/OutputTableEventVisit";
import { OutputTableMarketDevAssistence } from "../components/OutputOne/OutputTableMarketDevAssistence";
import { OutputTablePhysicalStatus } from "../components/OutputOne/OutputTablePhysicalStatus";


export const OutputPage1 = ({divi, currentQuter, finiYear, currentFiniYear}) => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [divisions, setDivisions] = useState([])
  const [currentQuarter, setCurrentQuarter] = useState("")
  const [financialYear, setFinancialYear] = useState([])
  const [currentFinancialYear, setCurrentFinancialYear] = useState("")
  const [mainTabValue, setMainTabValue] = useState('5');
  const [subTabValue, setSubTabValue] = useState('1');
  const [value, setValue] = useState("1");

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };
  const handleChangeTab = (event,newValue) => {
    setValue(newValue);
  };
  
  useEffect(()=>{

    async function getData (){
      setFinancialYear(finiYear)
      setCurrentFinancialYear(currentFiniYear)
      setCurrentQuarter(currentQuter)
      setDivisions(divi);
    }
    getData()
  },[divi, currentQuter, finiYear, currentFiniYear])

  return (
    <>
    <ErrorBoundary FallbackComponent={<ServerPage/>}>
      <section class="section">
          <div class="row">
            <div id="exTab3" class="contain" style={{width: "80%", position: "absolute", right: "0rem", top: "3.5rem"}}>
              <div class="card">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={mainTabValue}>
                    <TabList variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" onChange={handleMainTabChange}>
                      <Tab label="Project Progress" value="5" className={`${mainTabValue === '5' ? 'activeClickCSS' : ''}`} />
                      <Tab label="Capacity Building" value="0" className={`${mainTabValue === '0' ? 'activeClickCSS' : ''}`} />
                      <Tab label="Access to Finance for Infrastructure" value="1" className={`${mainTabValue === '1' ? 'activeClickCSS' : ''}`} />
                      <Tab label="Innovation" value="2" className={`${mainTabValue === '2' ? 'activeClickCSS' : ''}`} />
                      <Tab label="Support to Farmers" value="3" className={`${mainTabValue === '3' ? 'activeClickCSS' : ''}`} />
                      <Tab label="Events, Visits" value="4" className={`${mainTabValue === '4' ? 'activeClickCSS' : ''}`} />
                      
                    </TabList>
                  </TabContext>
                  <br/>

                {/*Physical Status*/}
                {mainTabValue === '5' && (
                    <TabContext>
                      <TabPanel>
                        <OutputTablePhysicalStatus financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

               {/*Capacity Building*/}
                 {mainTabValue === '0' && (
                    <TabContext>
                      <TabPanel>
                        <OutputTableCapacityBuildingGAP setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

               {/* Access to Finance for Infrastructure */}
                  {mainTabValue === '1' && (
                    <TabContext value={subTabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                          <Tab label="Access to Finance for Infrastructure" value="1" />
                          <Tab label="Demonstrations on High Density Plantation" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <OutputTableFinAccInfra setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      <TabPanel value="2">
                        <OutputTableHDP setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

                {/*Innovation*/}
                  {mainTabValue === '2' && (
                    <TabContext>
                      <TabPanel>
                        <OutputTableInnovation setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}

                {/* Support to Farmers */}
                  {mainTabValue === '3' && (
                    <TabContext value={subTabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                        <Tab label="Support to Farmers" value="1" />
                          <Tab label="MRL testing" value="2" />
                          <Tab label="Support for fruit care activities" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <OutputTableFarmerSupport setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      <TabPanel value="2">
                        <OutputTableMRLTesting setDivision={divisions} currentQuarter={currentQuarter}  financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                      <TabPanel value="3">
                        <OutputTableFruitCareAct setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                      </TabPanel>
                    </TabContext>
                  )}
 
                {/* Events,Visit */}
                  {mainTabValue === '4' && (
                     <TabContext value={subTabValue}>
                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                       <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                          <Tab label="Events , Visit" value="1" />
                         <Tab label="Events , Visit-Market Development Assistance" value="2" />
                       </TabList>
                     </Box>
                     <TabPanel value="1">
                       <OutputTableEventVisit setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>
                     </TabPanel>
                     <TabPanel value="2">
                     <OutputTableMarketDevAssistence setDivision={divisions} currentQuarter={currentQuarter} financialYear={financialYear} currentFinancialYear={currentFinancialYear}/>

                     </TabPanel>
                   </TabContext>
                  )}
                  
                </Box>
              </div>
            </div>
          </div>
        </section>
        </ErrorBoundary>
   
    </>
  );
};
