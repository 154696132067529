import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import '../../assets/css/OutputTable.css';

export const DashboardOutputTwo = ({ apiData }) => {
  const columns = useMemo(
    () => [
      { Header: 'Entity Type', accessor: 'entity_type' },
      { Header: 'Name of Beneficiary', accessor: 'applicant_name' },
      { Header: 'District', accessor: 'district' },
      { Header: 'Division', accessor: 'divisions' },
      { Header: 'Finished Product', accessor: 'finished_products' },
      { Header: 'Key Components', accessor: 'key_components' },
      { Header: 'Total Project Cost (in_Lakh)', accessor: 'total_project_cost' },
      { Header: 'Matching Grant (in_Lakh)', accessor: 'matching_grant' },
      { Header: 'Physical Progress till date ', accessor: 'physical_progress_td' },
      { Header: 'Financial Progress till date ', accessor: 'financial_progress_td' },
    ],
    []
  );

  const totalWidthForEqualColumns = 800; // Adjust as needed
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: apiData || [],
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc',
    textAlign: 'center',
    verticalAlign: 'middle',
  };

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{
                  borderBottom: '2px solid #4e2683',
                  background: '#4F2D7F',
                  fontWeight: 'bold',
                  color: 'white',
                  padding: '15px'
                }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td {...cell.getCellProps()} style={cellStyle}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
