import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  date: null,
  location_taluka: '',
  resource_person:'',
  location_address: '',
  topic_covered: '',

  sc_male: '',
  sc_female: '',
  sc_total: '',

  st_male: '',
  st_female: '',
  st_total: '',

  pwd_male: '',
  pwd_female: '',
  pwd_total: '',

  obc_minority_open_male: '',
  obc_minority_open_female: '',
  obc_minority_open_total: '',

  beneficiaries_male: '',
  beneficiaries_female: '',
  beneficiaries_total: '',


  validationErrors:{
    sc_male: '',
    sc_female: '',

    st_male: '',
    st_female: '',

    pwd_male: '',
    pwd_female: '',

    obc_minority_open_male: '',
    obc_minority_open_female: '',

    beneficiaries_male: '',
    beneficiaries_female: '',

    date: null,
    location_taluka: '',
    resource_person:'',
    location_address: '',
    topic_covered: '',
  }
};

const smeLinkageSlice = createSlice({
  name: 'smeLinkageSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    setTotalSC: (state, action) => {
        state.sc_total = action.payload;
      },
    setTotalST: (state, action) => {
        state.st_total = action.payload;
      },
    setTotalPWD: (state, action) => {
        state.pwd_total = action.payload;
      },
    // setTotalBPL: (state, action) => {
    //     state.bpl_total = action.payload;
    //   },
    setTotalOBC: (state, action) => {
        state.obc_minority_open_total= action.payload;
      },
    
    setTotalParticipants: (state, action) => {
        state.beneficiaries_total= action.payload;
      },

    setTotalFemaleParticipants: (state, action) => {
        state.beneficiaries_female= action.payload;
      },
    
    setTotalMaleParticipants: (state, action) => {
        state.beneficiaries_male= action.payload;
      },
    // Add other reducers for different input fields if needed
    updateSMELinkageForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataSMELinkage: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsSMELinkage: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsSMELinkage, resetValidation, setInputFieldData, updateSMELinkageForm, resetDataSMELinkage, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants, setTotalFemaleParticipants, setTotalMaleParticipants } = smeLinkageSlice.actions;

export default smeLinkageSlice.reducer;
