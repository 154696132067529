import React ,{useState, useEffect} from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/CapacityBuildingMaster/EntityMasterCapacityBuildingSlice";
import { useDispatch, useSelector } from "react-redux";
import { getConfigWithToken } from '../../../../utils/Config/Config';
import axios from 'axios';

export const EntityDetailCapacity = (props) =>{ 
    const apiUrl = process.env.REACT_APP_API_URL;
    const entityMaster = useSelector(state => state.entityMasterCapacityBuildingMaster);

    const [districts, setDistricts] = useState([]);
    const [talukas, setTalukas] = useState([]);
    const [entityData, setEntityData] = useState([])
    const dispatch = useDispatch();

    const handleFieldApplicant = (event) => {
        // props.entityChangeFunction(event.target.value)
        dispatch(setInputFieldData({ fieldName: 'beneficiary_id', value:event.target.value }));
    }

    const handleFieldDivision = (event) => {
        const divisionId = event.target.value;
        dispatch(setInputFieldData({ fieldName: 'division_id', value: divisionId }));
        axios
          .get(`${apiUrl}api/district-by-division?division_id=${divisionId}`, getConfigWithToken())
          .then((response) => {
            setDistricts(response.data.data);
            setTalukas([]); 
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
          });
      };
    
    const handleFieldDistrict = (event) => {
        const districtId = event.target.value;
        dispatch(setInputFieldData({ fieldName: 'district_id', value: districtId }));
        axios
          .get(`${apiUrl}api/get-taluka-by-district?district_id=${districtId}`, getConfigWithToken())
          .then((response) => {
            setTalukas(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching talukas:", error);
          });
      };
    const handleFieldTaluka = (event) => {
        dispatch(setInputFieldData({ fieldName: 'taluka_id', value:event.target.value }));
    }

    const handleFieldEntityType = (event) => {
        dispatch(setInputFieldData({ fieldName: 'beneficiary_type_id', value:event.target.value }));
    }
    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
    
useEffect(()=>{
    async function getDta(){
        const district = await axios.get(`${apiUrl}api/get-all-district?all=1`, getConfigWithToken())
        setDistricts(district.data.data)
        const taluka = await axios.get(`${apiUrl}api/get-all-taluka-with-village?all=1`, getConfigWithToken())
        setTalukas(taluka.data.data)
        
    }
    if(props.editFlag){
        getDta()
    }

},[props.editFlag])

 return(<>   
    {/* <Online> */}
        <div className='tab-pane'>
            <form class="row g-3">  
              
                {/* Name of the Entity */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="Borrower">Name of the Entity</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="Borrower" 
                        className="customSelect" 
                        style={{width:"50%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={entityMaster.beneficiary_id}
                        onChange={handleFieldApplicant} 
                        onFocus={handleResetFocus}
                        readOnly={props.editFlag && props.id?true:false}                                       
                    >
                     {(props.Applicant).map((e)=>(       
                             <MenuItem value={e.id} key={e.id}>{e.beneficiary_name}</MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div> 

                    {/* Division */}    
                    <div className='primaryTableLabel'>
                    <Box>
                    <label htmlFor="Division">Division</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="Division" 
                            className="customSelect" 
                            style={{width:"30%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={entityMaster.division_id}
                            onChange={handleFieldDivision}  
                            onFocus={handleResetFocus}                                      
                        >
                        {(props.Division).map((e)=>(
                             <MenuItem value={e.id} key={e.id}>{e.divisions}</MenuItem>
                        ))} 
                        </Select>
                    </FormControl>
                </Box>
                </div>

                {/* District */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="District" >District</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="District" 
                        className="customSelect" 
                        style={{width:"30%"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={entityMaster.district_id}
                        onChange={handleFieldDistrict}  
                        onFocus={handleResetFocus}                                 
                    >
                    <MenuItem value=''>Select District</MenuItem>
                        {districts.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                            {e.district}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Box>
                </div>

                {/* Taluka */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="Taluka">Taluka</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="Taluka" 
                        className="customSelect" 
                        style={{width:"30%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={entityMaster.taluka_id}
                        onChange={handleFieldTaluka} 
                        onFocus={handleResetFocus}                                         
                    >
                      {talukas.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                        {e.taluka}
                        </MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div>

                {/* Entity Type */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="entity type">Entity Type</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="entity type" 
                        className="customSelect" 
                        style={{width:"30%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={entityMaster.beneficiary_type_id}
                        onChange={handleFieldEntityType} 
                        onFocus={handleResetFocus}                                         
                    >
                    {(props.BeneficiaryType).map((e)=>(
                         <MenuItem value={e.id} key={e.id}>{e.beneficiary_type}</MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div>
           </form>
          
        </div>
           
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

