
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";


export const BeneficiaryFinancialsMaster = () =>{ 
    const dispatch = useDispatch();
    const mgBeneficiaryMaster = useSelector(state => state.mgBeneficiaryMaster);

    const handleFieldPaidCapital = (event) => {
        dispatch(setInputFieldData({ fieldName: 'paid_up_capital', value:event.target.value }));
   }
   const handleFieldTurnOver = (event) => {
    dispatch(setInputFieldData({ fieldName: 'turnover', value:event.target.value }));
    }
    const handleFieldNetWorth = (event) => {
        dispatch(setInputFieldData({ fieldName: 'net_worth', value:event.target.value }));
    }
    const handleFieldCashBank = (event) => {
        dispatch(setInputFieldData({ fieldName: 'cash_and_bank', value:event.target.value }));
    }
    const handleFieldReservesSurplus = (event) => {
        dispatch(setInputFieldData({ fieldName: 'reserves_and_surplus', value:event.target.value }));
    }
    const handleFieldSalesFY1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'sales_fy1', value:event.target.value }));
    }
    const handleFieldVariableCostFY1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'variable_cost_fy1', value:event.target.value }));
    }
    const handleFieldContributionFY1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'contribution_fy1', value:event.target.value }));
    }
    const handleFieldContributionPctFY1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'contribution_pct_fy1', value:event.target.value }));
    }
    const handleFieldSalesFY5 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'sales_fy5', value:event.target.value }));
    }
    const handleFieldVariableCostFY5 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'variable_cost_fy5', value:event.target.value }));
    }
    const handleFieldContributionFY5 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'contribution_fy5', value:event.target.value }));
    }
    const handleFieldContributionPctFY5 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'contribution_pct_fy5', value:event.target.value }));
    }
    

   const handleResetFocus = () =>{
    dispatch(resetValidation())
   }
    return(<>   
    {/* <Online> */}
    <div className='tab-pane'>
            <form class="row g-3">
                {/* Paid Up Capital */}
                <div className='primaryTableLabel'>
                    <label htmlFor="PaidUpCapital">Paid Up Capital</label>
                    <input type="number" name="PaidUpCapital" 
                        value={mgBeneficiaryMaster.paid_up_capital}
                        onChange={handleFieldPaidCapital} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

                {/* Turnover (FY 2021-22) */}
                <div className='primaryTableLabel'>
                    <label htmlFor="TurnOver">Turnover (FY 2021-22)</label>
                    <input type="number" name="TurnOver" 
                        value={mgBeneficiaryMaster.turnover}
                        onChange={handleFieldTurnOver} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

               {/* Net Worth */}
                <div className='primaryTableLabel'>
                    <label htmlFor="NetWorth">Net Worth</label>
                    <input type="number" name="NetWorth" 
                        value={mgBeneficiaryMaster.net_worth}
                        onChange={handleFieldNetWorth} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>                        
              
               {/* Cash & Bank */}
               <div className='primaryTableLabel'>
                    <label htmlFor="CashBank">Cash & Bank</label>
                    <input type="number" name="CashBank" 
                        value={mgBeneficiaryMaster.cash_and_bank}
                        onChange={handleFieldCashBank} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/* Reserves & Surplus */}
               <div className='primaryTableLabel'>
                    <label htmlFor="Reserves">Reserves & Surplus</label>
                    <input type="number" name="Reserves" 
                        value={mgBeneficiaryMaster.reserves_and_surplus}
                        onChange={handleFieldReservesSurplus} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/*  Sales FY1 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="SalesFY1">Sales FY1</label>
                    <input type="number" name="SalesFY1" 
                        value={mgBeneficiaryMaster.sales_fy1}
                        onChange={handleFieldSalesFY1} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/*Variable Cost FY1 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="VariableCostFY1">Variable Cost FY1</label>
                    <input type="number" name="VariableCostFY1" 
                        value={mgBeneficiaryMaster.variable_cost_fy1}
                        onChange={handleFieldVariableCostFY1} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/*Contribution FY1 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="ContributionFY1">Contribution FY1</label>
                    <input type="number" name="ContributionFY1" 
                        value={mgBeneficiaryMaster.contribution_fy1}
                        onChange={handleFieldContributionFY1} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/* Contribution % FY1 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="Contribution%FY1">Contribution % FY1</label>
                    <input type="number" name="Contribution%FY1" 
                        value={mgBeneficiaryMaster.contribution_pct_fy1}
                        onChange={handleFieldContributionPctFY1} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/* Sales FY5 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="SalesFY5">Sales FY5</label>
                    <input type="number" name="SalesFY5" 
                        value={mgBeneficiaryMaster.sales_fy5}
                        onChange={handleFieldSalesFY5} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/* Variable Cost FY5  */}
               <div className='primaryTableLabel'>
                    <label htmlFor="VariableCostFY5">Variable Cost FY5</label>
                    <input type="number" name="VariableCostFY5" 
                        value={mgBeneficiaryMaster.variable_cost_fy5}
                        onChange={handleFieldVariableCostFY5} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/* Contribution FY5 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="ContributionFY5">Contribution FY5</label>
                    <input type="number" name="ContributionFY5" 
                        value={mgBeneficiaryMaster.contribution_fy5}
                        onChange={handleFieldContributionFY5} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
                              
               {/*Contribution % FY5 */}
               <div className='primaryTableLabel'>
                    <label htmlFor="Contribution%FY5">Contribution % FY5</label>
                    <input type="number" name="Contribution%FY5" 
                        value={mgBeneficiaryMaster.contribution_pct_fy5}
                        onChange={handleFieldContributionPctFY5} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div> 
           </form>         
        </div>      
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

