
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";


export const BeneficiaryContactMaster = () =>{ 
    const dispatch = useDispatch();
    const mgBeneficiaryMaster = useSelector(state => state.mgBeneficiaryMaster);

    const handleFieldContactPerson = (event) => {
        dispatch(setInputFieldData({ fieldName: 'key_contact_person', value:event.target.value }));
   }
   const handleFieldEmail = (event) => {
    dispatch(setInputFieldData({ fieldName: 'email', value:event.target.value }));
    }
    const handleFieldMobileNumber = (event) => {
        dispatch(setInputFieldData({ fieldName: 'mobile_number', value:event.target.value }));
    }
   const handleResetFocus = () =>{
    dispatch(resetValidation())
   }
    return(<>   
    {/* <Online> */}
    <div className='tab-pane'>
            <form class="row g-3">
                {/* Key Contact Person */}
                <div className='primaryTableLabel'>
                    <label htmlFor="KeyContactPerson">Key Contact Person</label>
                    <input type="text" name="KeyContactPerson" 
                        value={mgBeneficiaryMaster.key_contact_person}
                        onChange={handleFieldContactPerson} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

                {/* Email */}
                <div className='primaryTableLabel'>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" 
                        value={mgBeneficiaryMaster.email}
                        onChange={handleFieldEmail} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>

               {/* mobile number */}
               <div className='primaryTableLabel'>
                    <label htmlFor="mobilenumber"> Mobile Number</label>
                    <input
                        type="tel"  
                        name="mobilenumber"
                        value={mgBeneficiaryMaster.mobile_number}
                        onChange={handleFieldMobileNumber}
                        onFocus={handleResetFocus}
                        className="custom"
                        maxLength={10}
                        pattern="[0-9]*" 
                        style={{ width: "30%" }}  
                    />
                </div>                   
              
              
           </form>         
        </div>      
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

