import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/EntityMaster/NurseryDevMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { NurseryDevTable } from "./EntityTables/NurseryDevTable";


export const EntityNurseryDevDetails = (props) =>{

    const nurseryDevMaster = useSelector(state => state.nurseryDevMaster["4"]);
    const dispatch = useDispatch();

    const handleFieldMAGNETCrop = (event) => {
        dispatch(setInputFieldData({ fieldName: 'magnet_crop_id', value:event.target.value }));
    }
    const handleFieldLocationTaluka = (event) => {
        dispatch(setInputFieldData({ fieldName: 'location_taluka', value:event.target.value }));
    }
    const handleFieldAreaPlantation = (event) => {
        dispatch(setInputFieldData({ fieldName: 'area', value:event.target.value }));
    }
    const handleFieldCapacity = (event) => {
        dispatch(setInputFieldData({ fieldName: 'capacity_total_plants', value:event.target.value }));
    }
    
    const actual_date_1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_1', value:event.target.value }));
    }

    const actual_date_2 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_2', value:event.target.value }));
    }
    const actual_date_3 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_3', value:event.target.value }));
    }

    const actual_date_4 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_4', value:event.target.value }));
    }

    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }

    return(<>   
       {/* <Online> */}
        <div className='tab-pane'>
                <form class="row g-3" id='formid'>
                       <div></div>
                    {/* Magnet_crops */}
                    <div className='primaryTableLabel'>
                    <Box>
                        <label htmlFor="MAGNET" style={{width:"290px"}}>MAGNET Crops</label>
                        <FormControl style={{width:'60%'}} >
                        <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="MAGNET" 
                             className="customSelect" 
                             style={{width:"75%" }}
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={nurseryDevMaster.magnet_crop_id}
                             onChange={handleFieldMAGNETCrop}   
                             onFocus={handleResetFocus}      
                             multiple
                                                          
                            >
                        {(props.Magnetcrop).map((e)=>(       
                            <MenuItem value={e.id} key={e.id}>{e.crop}</MenuItem>
                        ))} 
                           
                        </Select>
                        </FormControl>
                    </Box>
                    </div>
    
                {/* Location (Taluka) */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="LocationTaluka" style={{width:"290px"}}>Location (Taluka)</label>
                    <FormControl style={{width:'60%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="LocationTaluka" 
                        className="customSelect" 
                        style={{width:"75%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nurseryDevMaster.location_taluka}
                        onChange={handleFieldLocationTaluka} 
                        onFocus={handleResetFocus}                                         
                    >
                     {(props.taluka).map((e)=>(       
                             <MenuItem value={e.id} key={e.id}>{e.taluka}</MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div> 
                                                                                                   
                    {/* Area under Certification * */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Area " style={{width:"290px"}}>Area of Nursery(Ha)</label>
                        <input type="number" name="Area " 
                            value={nurseryDevMaster.area}
                            onChange={handleFieldAreaPlantation} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"45%"}}  
                        />
                    </div> 
                         
                    {/* Capacity */}
                    <div className='primaryTableLabel' >
                        <label htmlFor="Capacity" style={{width:"290px"}}>Capacity (Total Plants)</label>
                        <input type="number" name="Capacity" 
                            value={nurseryDevMaster.capacity_total_plants}
                            onChange={handleFieldCapacity} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"45%"}}  
                            
                        />
                    </div>
                                                                         
                    {/* Total Project Cost */}
                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="ProjectCost " style={{width:"290px"}}>Total Project Cost</label>
                        <input type="number" name="ProjectCost " 
                            value={nurseryDevMaster.total_project_cost}
                            onChange={handleFieldProjectCost} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div> */}
                                                                                                                                   
                    {/* Total Sanction Grant from MAGNET */}
                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="sanctionGrant " style={{width:"290px"}}>Total MAGNET Grant</label>
                        <input type="number" name="sanctionGrant " 
                            value={nurseryDevMaster.matching_grant}
                            onChange={handleFieldSanctionGrant} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div> */}
                                                                                                                                         
                    {/* Total Terms Loan */}
                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Total Terms Loan</label>
                        <input type="number" name="TermLoan " 
                            value={nurseryDevMaster.bank_term_loan}
                            onChange={handleFieldTermLoan} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div> */}

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T1</label>
                        <input type="date" name="TermLoan " 
                            value={nurseryDevMaster.actual_date_1}
                            onChange={actual_date_1} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T2</label>
                        <input type="date" name="TermLoan " 
                            value={nurseryDevMaster.actual_date_2}
                            onChange={actual_date_2} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T3</label>
                        <input type="date" name="TermLoan " 
                            value={nurseryDevMaster.actual_date_3}
                            onChange={actual_date_3} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T4</label>
                        <input type="date" name="TermLoan " 
                            value={nurseryDevMaster.actual_date_4}
                            onChange={actual_date_4} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div> */}

                
            </form>
            <br/>
           <NurseryDevTable/>
        </div>     
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

