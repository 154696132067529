import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { InputField } from "../../InputField"
import { useDispatch, useSelector } from 'react-redux';
import { setField, resetValidation } from '../../../redux/slice/InputThree/FacilityInputThree/ProgressFacilitySlice'

export const ProgressFacilities = (props) =>{
    const dispatch = useDispatch();
    const {facility_id, capacity, progress, action_taken, suggestions_changes_made, validationErrors} = useSelector((state) => state.progressFacility);
    // const validationErrors = useSelector((state) => state.validationErrors);

    
    const handleFacilitiesOption = (value) => {
        dispatch(setField({field: 'facility_id', value}))
    }
    const setCapacity = (value) => {
        dispatch(setField({field: 'capacity', value}))
    }
   
    
    const setProgress = (value) => {
        dispatch(setField({field: 'progress', value}))
    }
    const setActionTaken = (value) => {
        dispatch(setField({field: 'action_taken', value}))
    }
    const setSuggestionChangeMade = (value) => {
        dispatch(setField({field: 'suggestions_changes_made', value}))
    }

    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
    return (<>
        <div className='col-md-4'>
            <Box sx={{ maxWidth: "100%" }}> 
                <FormControl fullWidth error={validationErrors.facility_id !== ""?true:false}>
                    <InputLabel id="demo-simple-select-label">Types of Facilities</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={facility_id}
                        onChange={(e)=>handleFacilitiesOption(e.target.value)}
                        onFocus={handleResetFocus}
                        label="Types of Facilities"
                    >
                    {props.facilities.map((option) => (
                        <MenuItem value={option.id}>{option.facility_type}</MenuItem>
                    ))}
                   
                    </Select>
                    <FormHelperText>{validationErrors.facility_id}</FormHelperText>
                </FormControl>
            </Box>
        </div>
        <InputField name="Capacity" label="Capacity" type="number" endAr="MTR" value={capacity} onChange={setCapacity} onFocus={handleResetFocus} error={!!validationErrors.capacity} helperText={validationErrors.capacity}/>
        <InputField label="Progress" type="number" endAr="%" value={progress} onChange={setProgress} onFocus={handleResetFocus} error={!!validationErrors.progress} helperText={validationErrors.progress}/>
        <InputField label="Action taken" type="text" value={action_taken} onChange={setActionTaken} onFocus={handleResetFocus} error={!!validationErrors.action_taken} helperText={validationErrors.action_taken}/>
        <InputField label="Suggestions/Changes made" type="text" value={suggestions_changes_made} onChange={setSuggestionChangeMade} onFocus={handleResetFocus} error={!!validationErrors.suggestions_changes_made} helperText={validationErrors.suggestions_changes_made}/>

    </>)
}