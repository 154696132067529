import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  existing_crops: [],
  is_facility_in_opration: "",
  use_by_msamb_type: "",
  total_quantity_handled_tpd: "",
  total_capacity_utilised_tpd: "",
  capacity_utilised_job_work: "",
  capacity_utilised_captive_used: "",
  total_amount: "",
  service_rental_charges: "",
  processing_charges: "",
  number_of_farmers: "",
  members_of_fpo_small: "",
  members_of_fpo_medium: "",
  members_of_fpo_others: "",
  non_members_small: "",
  non_members_medium: "",
  non_members_others: "",

  validationErrors: {
    existing_crops: [],
    is_facility_in_opration: "",
    use_by_msamb_type: "",
    total_quantity_handled_tpd: "",
    total_capacity_utilised_tpd: "",
    capacity_utilised_job_work: "",
    capacity_utilised_captive_used: "",
    total_amount: "",
    service_rental_charges: "",
    processing_charges: "",
    number_of_farmers: "",
   
  },
};

const UtilizationThreeTPDSlice = createSlice({
  name: "utilizationTPDThree",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },
    updateApiDataToTPDThree: (state, action) => {
      const payload = action.payload;
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataTPDThree: (state) => {
      return { ...initialState };
    },
    setValidationErrorsTPDThree: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetTPDThreeValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setValidationErrorsTPDThree,
  resetTPDThreeValidation,
  setInputFieldData,
  updateApiDataToTPDThree,
  resetDataTPDThree,
} = UtilizationThreeTPDSlice.actions;

export default UtilizationThreeTPDSlice.reducer;
