import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";
import "../../../assets/css/masterdata.css";

export const SubCategoryAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({});
  const [page, setPage] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  // Fetching the data
  const fetchData = async () => {
    try {
      const getfacility = await axios.get(
        `${apiUrl}api/subcategory-input-one-listing`,
        getConfigWithToken()
      );
      if (getfacility.data && getfacility.data.data.data) {
        const facility = getfacility.data.data.data.map((e) => ({
          id: e.id,
          sub_category: e.sub_category,
          unit: e.unit,
          target_units: e.target_units || "", // Handle null values
          total_cost: e.total_cost || "", // Handle null values
          category: e.category,
        }));
        console.log(facility, "fac");
        setAttributeOptions(facility);
        setGetAllData(getfacility.data.data);
      } else {
        console.error("Unexpected response structure:", getfacility.data);
      }
    } catch (error) {
      console.error("Error fetching SubCategory:", error);
    }
  };

  // Editing the data
  const handleEditFunction = async (subCategoryData) => {
    try {
      const { value: editValues } = await Swal.fire({
        title: "Edit Sub Category Attribute",
        html: `
          <div class="swal-input-container">
            <div class="swal-label">
              <label for="sub-category-input"  class="swal2-label">Sub Category:</label>
            </div>
            <div class="swal-input">
              <input type="text" id="sub-category-input" class="swal2-input" value="${subCategoryData.sub_category}">
            </div>
          </div>
          <div class="swal-input-container">
            <div class="swal-label">
              <label for="unit-input">Unit:</label>
            </div>
            <div class="swal-input">
              <input type="text" id="unit-input" class="swal2-input" value="${subCategoryData.unit}">
            </div>
          </div>
          <div class="swal-input-container">
            <div class="swal-label">
              <label for="target-units-input">Target Units:</label>
            </div>
            <div class="swal-input">
              <input type="number" id="target-units-input" class="swal2-input" value="${subCategoryData.target_units || ""}">
            </div>
          </div>
          <div class="swal-input-container">
            <div class="swal-label">
              <label for="total-cost-input">Total Cost:</label>
            </div>
            <div class="swal-input">
              <input type="number" id="total-cost-input" class="swal2-input" value="${subCategoryData.total_cost || ""}">
            </div>
          </div>`,
        showCancelButton: true,
        confirmButtonText: "Save",
        preConfirm: () => {
          const subCategory = document.getElementById("sub-category-input").value;
          const unit = document.getElementById("unit-input").value;
          const targetUnits = document.getElementById("target-units-input").value;
          const totalCost = document.getElementById("total-cost-input").value;
  
          return {
            sub_category: subCategory,
            unit: unit,
            target_units: targetUnits,
            total_cost: totalCost,
          };
        },
      });
  
      if (editValues) {
        const response = await axios.post(
          `${apiUrl}api/subcategory-input-one-update`,
          {
            ...editValues,
            id: subCategoryData.id,
          },
          getConfigWithToken()
        );
  
        console.log("API Response:", response.data);
  
        if (response.data && response.data.status === true) {
          Swal.fire(`Attribute Edited Successfully`, "", "success");
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error editing Sub Category attribute:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  
// Deleting the data
  const handleDeleteFunction = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await axios.get(
          `${apiUrl}api/subcategory-input-one-delete?id=${id}`,
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire({
            title: "Deleted!",
            text: "Attribute has been deleted.",
            icon: "success",
          });
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error deleting Division attribute:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // OnChange of Pagination
  const handleChange = async (event, value) => {
    try {
      const getFacilitiesType = await axios.get(
        `${apiUrl}api/subcategory-input-one-listing?page=${value}`,
        getConfigWithToken()
      );
      const facilitiesType = getFacilitiesType.data["data"]["data"].map(
        (e) => ({
          id: e.id,
          sub_category: e.sub_category,
          unit: e.unit,
          target_units: e.target_units || "", // Handle null values
          total_cost: e.total_cost || "", // Handle null values
          category: e.category,
        })
      );
      setAttributeOptions(facilitiesType);
      setGetAllData(getFacilitiesType.data.data);
      setPage(value); // Set the current page in the state
    } catch (error) {
      console.error("Error fetching Division:", error);
    }
  };

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div
              id="exTab3"
              className="contain"
              style={{ width: "100%", overflow: "hidden" }}
            >
              <div
                className="cardMaster"
                id=""
                style={{ width: "100%", marginTop: "0.5rem" }}
              >
                <form
                  className="row g-3"
                  style={{ width: "100%", maxWidth: "100%", margin: "auto" }}
                >
                  <Box
                    className="your-component"
                    sx={{ typography: "body1", width: "88%" }}
                  >
                    <div className="words-with-lines">
                      <span>SubCategory</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata" style={{ marginRight: "1rem" }}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>

                  <Box className="custom-box" style={{ marginLeft: "2rem" }}>
                    <table className="custom-table">
                      <thead>
                        <tr>
                          
                          <th>Category</th>
                          <th>SubCategory</th>
                          <th style={{ width: "15%" }}>Units</th>
                          <th style={{ width: "15%" }}>Target</th>
                          <th style={{ width: "15%" }}>TotalCost</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody
                        className="your-tbody"
                        style={{ marginRight: "8rem" }}
                      >
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.category.category}
                                disabled
                                style={{ margin: "1rem 0rem 1rem 0rem " }}
                              />
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.sub_category}
                                disabled
                                style={{ margin: "1rem 0rem 1rem 0rem " }}
                              />
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.unit}
                                disabled
                                style={{ margin: "1rem 0rem 1rem 0rem " }}
                              />
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.target_units || ""}
                                disabled
                                style={{ margin: "1rem 0rem 1rem 0rem " }}
                              />
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.total_cost || ""}
                                disabled
                                style={{ margin: "1rem 0rem 1rem 0rem " }}
                              />
                            </td>
                            <td>
                              <i
                                class="fa-solid fa-pen-to-square"
                                onClick={() => handleEditFunction(option)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={() => handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                      />
                    </table>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
