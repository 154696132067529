import { CapacityBuildingForm } from "../components/CapacityBuildingForm"
import { EoiBasedForm } from "../components/EoiBasedForm";
import { Header } from "../components/Header"
import { LeftNavigation } from "../components/LeftNavigation"
import { useLocation } from "react-router-dom";
import { MouBasedForm } from "../components/MouBasedForm";
import { InvitationBasedForm } from "../components/InvitationBasedForm";
import { EventsForm } from "../components/EventsForm";
import { InputForm2 } from "../components/InputForm2";
import { InputForm13 } from "../components/InputForm13";
import { PhysicalInputForm } from "../components/PhysicalInputForm";
import { useEffect, useState } from "react";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import { InputForm2ImapctForms } from "../components/Input2Impactforms";
import { InputForm3ImapctForms } from "../components/Input3Impactforms";

export const InputForms = () => {
    
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const [quarters, setQuarter] = useState([])
    const [years, setYears] = useState([])
    const [magentCrop, setMagentCrop] = useState([])
    const [existingmagentCrop, setExisitingMagentCrop] = useState([])
    const [division, setDivision] = useState([]);
    const [facilities, setFacilities] = useState([])
    const [powerSource, setPowerSource] = useState([])
    const [beniFiApplicant, setBeniApplicant] = useState([])
    const [currentFinancialYear, setCurrentFinancialYear] = useState("");
    const [workPackage, setWorkPackage] = useState([]);
    const [selectWorkPackage, setselectedworkPackage] = useState("");

    useEffect(()=>{
        
        async function getCommonData(){
            const response = await axios.get(`${apiUrl}api/getAllFinancialYearsQuarters`,getConfigWithToken());
            const applicant = await axios.get(`${apiUrl}api/get-all-beneficiary-master?all=1`,getConfigWithToken());
            const magnetCrop = await axios.get(`${apiUrl}api/getAllCrop?all=1`,getConfigWithToken())
            const existingmagentCrop = await axios.get(`${apiUrl}api/getAllExistingCrop?all=1`,getConfigWithToken())
            const divisi = await axios.get(`${apiUrl}api/getAllDivisions?all=1`, getConfigWithToken())
            const facility = await axios.get(`${apiUrl}api/getAllFacilityType?all=1`,getConfigWithToken());
            const powerSource = await axios.get(`${apiUrl}api/getAllPowerSources?all=1`,getConfigWithToken());
            const financialYearResponse = await axios.get(`${apiUrl}api/getAllFinancialYears`, getConfigWithToken());

             
            const powerData = powerSource.data["data"].map((e)=>({"id":e["id"],"power_source":e["power_source"]}))
            const facilityData = facility.data["data"].map((e)=>({"id":e["id"],"facility_type":e["facility_type"]}))
            const applicantData = applicant.data["data"].map((e)=>({"id":e["id"],"beneficiary_name":e["beneficiary_name"]}))
            const magnetCropData = magnetCrop.data["data"].map((e)=>({"id":e["id"], "crop":e["crop"]}))
            const existingmagnetCropData = existingmagentCrop.data["data"].map((e)=>({"id":e["id"], "crop":e["crop"]}))
            const quarter = response.data["data"][0]
            const financialYears = financialYearResponse.data.data[0];
            const currentFinancialYear = financialYearResponse.data.currentFinanciYear;

            const workPackage = await axios.get(`${apiUrl}api/work-package-listing?all=1`,getConfigWithToken());
            const workpackageData = workPackage.data["data"].map((e)=>({"id":e["id"], "work_package":e["work_package"]}))
               
            setWorkPackage(workpackageData)
            
            setYears(financialYears.map(year => ({ id: year, value: year })));
           
            setCurrentFinancialYear(currentFinancialYear);

            setMagentCrop(magnetCropData)
            setExisitingMagentCrop(existingmagnetCropData)
            setDivision(divisi.data["data"])
            setFacilities(facilityData)
            setPowerSource(powerData)
            setBeniApplicant(applicantData); // Pass applicantData to setApplicant
            setQuarter(quarter);
        }

        getCommonData()  
    },[])

    return (
        <>
            <Header/>
            <LeftNavigation/>
            {location.pathname === "/inputform1-physicalInput" && <PhysicalInputForm quarter={quarters}/>}
            {location.pathname === "/inputform1-capacityBuilding" && <CapacityBuildingForm quarter={quarters} magentCrp={magentCrop} divisi={division}/>}
            {location.pathname === "/inputform1-eoiBased" && <EoiBasedForm quarter={quarters} magentCrp={magentCrop} applicantDta={beniFiApplicant}/>}
            {location.pathname === "/inputform1-mouBased" && <MouBasedForm quarter={quarters} magentCrp={magentCrop} applicantDta={beniFiApplicant}/>}
            {location.pathname === "/inputform1-invitationBased" && <InvitationBasedForm quarter={quarters} magentCrp={magentCrop} applicantDta={beniFiApplicant}/>}
            {location.pathname === "/inputform1-events" && <EventsForm quarter={quarters} magentCrp={magentCrop} divisi={division}/>}

            {location.pathname === "/inputform2" && <InputForm2 quarter={quarters} facili={facilities} powerSou={powerSource}/>}
            {location.pathname === "/inputform2impactforms" && <InputForm2ImapctForms  years={years} quarter={quarters} divisi={division} magentCrp={magentCrop} exisitingmagnetCrp={existingmagentCrop} currentFinancialYear={currentFinancialYear}/>}
            {location.pathname === "/inputform3impactforms" && <InputForm3ImapctForms  years={years} quarter={quarters} divisi={division} magentCrp={magentCrop} exisitingmagnetCrp={existingmagentCrop} currentFinancialYear={currentFinancialYear}/>}
            {location.pathname === "/inputform3" && <InputForm13 quarter={quarters} magentCrp={magentCrop} facili={facilities} powerSou={powerSource} workPack={workPackage}/>}
        </>
    )
}