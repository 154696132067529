
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "6": {
    magnet_crop_id:[],

    location_taluka:'',
    no_of_farmers:'',
    name_of_certification_agency_lab:'',
    total_project_cost:'',
    matching_grant: '',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',
    training_type_id: "6"
  },

    // Validation
  validationErrors:{  
    magnet_crop_id:[],

    taluka_id:'',
    no_of_farmers:'',
    name_of_certification_exhibition:'',
    total_project_cost:'',
    matching_grant: '',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',
    
        }
    };

const MRLMasterSlice = createSlice({
  name: 'MRLMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["6"][action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataMRLMaster: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"PayloadMRL")
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["6"]) {
        if (payload[key] === null) {
          state["6"][key] = '';
        }else{
          state["6"][key] = payload[key];
        }
      }
    },
    resetDataMRLMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsMRLMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldData, updateApiDataMRLMaster, resetDataMRLMaster } = MRLMasterSlice.actions;

export default MRLMasterSlice.reducer;
