export function getConfigWithToken() {
    const token = localStorage.getItem("token");
  
    if (!token) {
      throw new Error("Token not found in localStorage");
    }
  
    const config = {
      headers: {
        Accept: 'application/ecmascript',
        Authorization: `Bearer ${token}`,
      },
    };
  
    return config;
  }
  