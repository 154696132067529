import { Header } from "../components/Header";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { LeftNavigation } from "../components/LeftNavigation";
import "../assets/css/inputForm.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const labelStyle = {
  width: "190px",
  height: "50px",
  border: "1.5px solid #4e2683",
  marginLeft: "2rem",
  color: "#4e2683",
  padding: "25px, 80px, 25px, 80px",
  fontWeight: "bold",
  backgroundColor: "#FFFFFF",
};

export const MasterDataPage = () => {
  // console.log(dd,"jdsfjdsifdsfS")

  const [permission, setPermission] = useState([]);

  useEffect(() => {
    const storedArrayAsString = localStorage.getItem("permission");

    setPermission(storedArrayAsString);
  }, []);

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div
              id="exTab6"
              class="contain"
              style={{ width: "84%", position: "absolute", top: "3.5rem" }}
            >
              <div
                class="card"
                style={{ height: "1000px", backgroundColor: "#F6F9FF" }}
              >
                <div
                  className="words-with-lines"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "2rem",
                    marginLeft: "5rem",
                    color: "black",
                  }}
                >
                  <span>Attributes</span>
                </div>
                <Box
                  sx={{
                    width: "90%",
                    height: "400px",
                    marginLeft: "5rem",
                    typography: "body1",
                    border: "3px solid #E8E8E8",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <div>
                    <Stack direction="row" spacing={1}>
                      {permission.includes("Components-Master-listing") ? (
                        <Link to="/components">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Components
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Beneficiary-Master-listing") ? (
                        <Link to="/applicantlist">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Beneficiary List
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                     
                      {permission.includes("Magnet-Crop-Master-listing") ? (
                        <Link to="/magnetcrops">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Magnet Crop
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Entity-Type-Master-listing") ? (
                        <Link to="/entitytype">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Beneficiary type
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={1}>
                      {permission.includes(
                        "Beneficiary-Type-Master-listing"
                      ) ? (
                        <Link to="/beneficiary-type">
                          <Button style={labelStyle}>Entity Type</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes(
                        "Technical-Component-Input1-Master-listing"
                      ) ? (
                        <Link to="/technicalcomponent">
                          <Button style={labelStyle}>
                            Technical Component(Input1)
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Facility-Type-Master-listing") ? (
                        <Link to="/facilities">
                          <Button style={labelStyle}>Facility type</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Power-Source-Master-listing") ? (
                        <Link to="/powersource">
                          <Button style={labelStyle}>Power source</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={1}>
                      {permission.includes("Loan-Type-Master-listing") ? (
                        <Link to="/loantype">
                          <Button style={labelStyle}>Loan Type</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Division-Master-listing") ? (
                        <Link to="/division">
                          <Button style={labelStyle}>Division</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("District-Master-listing") ? (
                        <Link to="/district">
                          <Button style={labelStyle}>District</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Taluka-Master-listing") ? (
                        <Link to="/taluka">
                          <Button style={labelStyle}>Taluka</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={1}>
                      {permission.includes("Work-Package-Master-listing") ? (
                        <Link to="/workpackage">
                          <Button style={labelStyle}>Work Package</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Msamb-Facility-Master-listing") ? (
                        <Link to="/msamb">
                          <Button style={labelStyle}>MSAMB Facility</Button>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {permission.includes("Entity-Master-listing") ? (
                        <Link to="/entitylist">
                          <Button style={labelStyle}>Entity List</Button>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {permission.includes("Msamb-Facility-Master-listing") ? (
                        <Link to="/bank">
                          <Button style={labelStyle}>Bank</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                 
                    </Stack>
                    <br />
                    <Stack direction="row" spacing={1}>
                      {permission.includes(
                        "Physical-Input-Category-Master-listing"
                      ) ? (
                        <Link to="/category">
                          <Button style={labelStyle}>Category</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes(
                        "Physical-Input-Sub-Category-Master-listing"
                      ) ? (
                        <Link to="/subcategory">
                          <Button style={labelStyle}>SubCategory</Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Existing-Magnet-Crop-listing") ? (
                        <Link to="/exisitngmagnetcrop">
                          <Button style={labelStyle}>
                            Exisiting Magnet Crop
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("") ? (
                        <Link to="/passwordrecoveryQ">
                          <Button style={labelStyle}>
                            Password Recovery Questions
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </div>
                </Box>

                {/* Primary Table */}
                <div
                  className="words-with-lines"
                  style={{
                    marginTop: "3rem",
                    marginBottom: "2rem",
                    marginLeft: "5rem",
                  }}
                >
                  <span>Primary Table</span>
                </div>
                <Box
                  sx={{
                    width: "90%",
                    marginLeft: "5rem",
                    typography: "body1",
                    border: "3px solid #E8E8E8",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <div>
                    <Stack direction="row" spacing={1}>
                      {permission.includes("Primary-MG-Beneficiary-Master-listing") ? (
                        <Link to="/MG-beneficiary-master">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            MG - Beneficiary Master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {permission.includes("Primary-Input-One-Entity-Master-listing") ? (
                        <Link to="/input1entitymaster">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Input 1- Entity Master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {permission.includes("Primary-Invitationbased-Component-Master-listing") ? (
                        <Link to="/GAP-Master">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            {/* Input 1- GAP Master */}
                            Invitation based component master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {permission.includes("Primary-Capacity-Building-Master-listing") ? (
                        <Link to="/capacityBuildingMaster">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            Capacity Building Master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ marginBottom: "20px" }}
                    >
                      {permission.includes("Primary-MSAMB-Master-listing") ? (
                        <Link to="/MSAMB-Master">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            MSAMB Master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {permission.includes("Primary-FIL-Master-listing") ? (
                        <Link to="/FIL-Master">
                          <Button
                            style={{ ...labelStyle, marginTop: "1.5rem" }}
                          >
                            FIL Master
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
