import * as React from 'react';
import '../assets/css/components.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';


export const DatePickInput3 = (props) => {
  const workShop = useSelector(state => state.workShop);
  
  function handleChange(date) {
    const formattedDate = date.format('YYYY-MM-DD');
    if (props.onChange) {
      props.onChange(formattedDate); // Pass the selected date to the parent component
    }
  }

  return (
    <div className="col-md-4">
      <div className="form-floating">
        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ padding: 0 }}>
          <DemoContainer components={['DatePicker']} style={{ width: '100rem' }}>
            <DatePicker
              label={props.label}
              value={dayjs(props.disbrushDate)} // Use props.disbrushDate here
              readOnly={props.readOnly}
              onChange={handleChange}
              className="custom-date-picker"
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: false, 
                  // error: props.errorText,
                  helperText: workShop.validationErrors.date,
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
};
