import { InputField } from "../../InputField";
import React from "react";
import {
  setInputFieldData,
  resetValidation,
} from "../../../redux/slice/InputOne/MarketAssistence/MarketAssistenceSlice";
import { useDispatch, useSelector } from "react-redux";

import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";

export function MarketAssistence(props) {
  const dispatch = useDispatch(); // Make sure you have Redux set up properly
  const marketAssistence = useSelector((state) => state.marketAssistence);

  const handleDisbursementDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "disbursement_date", value }));
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };
  return (
    <>
      {/* Market Development Assistence */}
      <div class="row g-3">
        <InputField
          label="Name of Exhibition/Fair"
          type="text"
          readOnly={true}
          value={marketAssistence.name_of_exhibition}
        />
        <InputField
          label="Location of Exhibition/Fair"
          type="text"
          readOnly={true}
          value={marketAssistence.location_of_exhibition}
        />
        <InputField
          label="Country of Exhibition"
          type="text"
          readOnly={true}
          value={marketAssistence.country_of_exhibition}
        />
        <InputField
          label="Total Cost"
          type="number"
          endAr="(Lakhs)"
          readOnly={true}
          value={marketAssistence.total_cost}
        />
        <InputField
          label="Total Saction Grant from MAGNET"
          type="text"
          endAr="(Lakhs)"
          readOnly={true}
          value={marketAssistence.total_saction_grant}
        />

        <InputField
          label="Beneficiary Contribution"
          type="number"
          endAR="(Lakhs)"
          readOnly={true}
          value={marketAssistence.beneficiary_contribution}
        />
        <div className="word-with-line">
          <span>Details of Current Quarter - {props.compName}</span>

          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <InputField
          label="Grant Disbursement"
          type="number"
          value={marketAssistence.disbursement_amt}
          onChange={(value)=>handleInputChange("disbursement_amt",value)}
          onFocus={handleResetFocus}
          error={!!marketAssistence.validationErrors.disbursement_amt}
          helperText={marketAssistence.validationErrors.disbursement_amt}
        />
        <DatePick
          label="Disbursement Date"
          className="custom-date-picker"
          disbrushDate={marketAssistence.disbursement_date}
          onChange={handleDisbursementDateChange}
          onFocus={handleResetFocus}
          errorText={!!marketAssistence.validationErrors.disbursement_date}
          helperText={marketAssistence.validationErrors.disbursement_date}
        />
       
      </div>
    </>
  );
}
