import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../InputField";
import {
  setInputFieldFullPartData,
  setTotalPartMaleEmp,
  setTotalPartFemaleEmp,
  setTotalPartTotalEmp,
  setTotalPartSC,
  setTotalPartST,
  setTotalPartPWD,
  resetValidation,
  setTotalPartOBC,
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
} from "../../../redux/slice/InputThree/EmpGenInputThree/FullPartTimeSlice";
import "../../../assets/css/inputForm.css";

export function PartTime(props) {
  const dispatch = useDispatch();
  const fullPartTimeForm = useSelector((state) => state.fullPartTime);

  const calculateTotals = () => {
    const totalSC = (parseInt(fullPartTimeForm.part_time_emp_num_sc_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_sc_female || 0));
    const totalST = (parseInt(fullPartTimeForm.part_time_emp_num_st_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_st_female || 0));
    const totalPWD = (parseInt(fullPartTimeForm.part_time_emp_num_pwd_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_pwd_female || 0));
    const totalOBCMinorityOpen = (parseInt(fullPartTimeForm.part_time_emp_num_obc_minority_open_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_obc_minority_open_female || 0));
    const totalFullTimeEmpMale = (parseInt(fullPartTimeForm.part_time_emp_num_sc_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_st_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_obc_minority_open_male || 0));
    const totalFullTimeEmpFemale = (parseInt(fullPartTimeForm.part_time_emp_num_sc_female || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_st_female || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_obc_minority_open_female || 0));
    const totalFullTimeEmpTotal = (parseInt(fullPartTimeForm.part_time_emp_num_sc_total || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_st_total || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_obc_minority_open_total || 0));
    const totalGenEmpMale = (parseInt(fullPartTimeForm.full_time_emp_num_male || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_male || 0));
    const totalGenEmpFemale = (parseInt(fullPartTimeForm.full_time_emp_num_female || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_female || 0));
    const totalGenEmpTotal = (parseInt(fullPartTimeForm.full_time_emp_num_total || 0) +
                    parseInt(fullPartTimeForm.part_time_emp_num_total || 0));

    dispatch(setTotalPartSC(totalSC));
    dispatch(setTotalPartST(totalST));
    dispatch(setTotalPartPWD(totalPWD));
    dispatch(setTotalPartOBC(totalOBCMinorityOpen));
    dispatch(setTotalPartMaleEmp(totalFullTimeEmpMale));
    dispatch(setTotalPartFemaleEmp(totalFullTimeEmpFemale));
    dispatch(setTotalPartTotalEmp(totalFullTimeEmpTotal));
    dispatch(setTotalGenMaleEmp(totalGenEmpMale));
    dispatch(setTotalGenFemaleEmp(totalGenEmpFemale));
    dispatch(setTotalGenTotalEmp(totalGenEmpTotal));
  };

  useEffect(() => {
    calculateTotals();
  }, [fullPartTimeForm]);

  const handleInputChange = (fieldName, value) => {
    if (value < 0) value = 0;
    dispatch(setInputFieldFullPartData({ fieldName, value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_sc_male}
          onChange={(value) => handleInputChange("part_time_emp_num_sc_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_sc_male}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_sc_female}
          onChange={(value) => handleInputChange("part_time_emp_num_sc_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_sc_female}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_st_male}
          onChange={(value) => handleInputChange("part_time_emp_num_st_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_st_male}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_st_female}
          onChange={(value) => handleInputChange("part_time_emp_num_st_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_st_female}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_st_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_obc_minority_open_male}
          onChange={(value) => handleInputChange("part_time_emp_num_obc_minority_open_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_obc_minority_open_male}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_obc_minority_open_female}
          onChange={(value) => handleInputChange("part_time_emp_num_obc_minority_open_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeForm.validationErrors.part_time_emp_num_obc_minority_open_female}
          helperText={fullPartTimeForm.validationErrors.part_time_emp_num_obc_minority_open_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_obc_minority_open_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_male}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_female}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Part Time"
          type="number"
          value={fullPartTimeForm.part_time_emp_num_total}
          readOnly={true}
        />

        <div style={{ marginTop: "3rem", marginLeft: "1px" }} className="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={fullPartTimeForm.part_time_emp_num_pwd_male}
            onChange={(value) => handleInputChange("part_time_emp_num_pwd_male", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeForm.validationErrors.part_time_emp_num_pwd_male}
            helperText={fullPartTimeForm.validationErrors.part_time_emp_num_pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={fullPartTimeForm.part_time_emp_num_pwd_female}
            onChange={(value) => handleInputChange("part_time_emp_num_pwd_female", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeForm.validationErrors.part_time_emp_num_pwd_female}
            helperText={fullPartTimeForm.validationErrors.part_time_emp_num_pwd_female}
          />
          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={fullPartTimeForm.part_time_emp_num_pwd_total}
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
}
