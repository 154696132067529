import { InputField } from "../../InputField";
import React from "react";
import "../../../assets/css/inputForm.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
} from "../../../redux/slice/InputOne/SupportTechnology/SupportTechSlice";
import { DatePickInput3 } from "../../DatePickInput3";

export function SupportTechnology(props) {
  const dispatch = useDispatch();
  const {
    exp_incurred,
   
    total_technology_cost,
    promoters_contribution,
    matching_grant,
    term_loan,
    validationErrors,
    amount_disbursement,
    disbursement_date,
    payment_tranche,
  } = useSelector((state) => state.supportTech);

  const updateExpenditure = (
    termLoan,
    matching_grant_exp_incurred,
    promoters
  ) => {
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      parseFloat(termLoan) +
      parseFloatOrZero(matching_grant_exp_incurred) +
      parseFloat(promoters);
    dispatch(setInputFieldData({ fieldName: "exp_incurred", value: newValue }));
  };


  const handleTermLoanChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "total_term_loan_exp_till_date", value })
    );
  };

  const handleTotalProjectCostChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "total_project_cost", value }));
  };

  const handlePromotersContributionChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "promoters_contribution", value }));
  };

  const handleMatchingGrantChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "matching_grant", value }));
  };

 
  const handleexp_incurredChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "exp_incurred", value }));
  };
  
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  return (
    <>
      <div class="row g-3">
        {/* <p className="subHeading">Current Quarter</p> */}
        <InputField
          label="Cost of Technology"
          type="number"
          endAr="(in lakhs)"
          value={total_technology_cost ? total_technology_cost.toString() : ""}
          onChange={handleTotalProjectCostChange}
          readOnly={true}
        />
        <InputField
          label="Promoters Contribution"
          type="number"
          endAr="(in lakhs)"
          value={
            promoters_contribution ? promoters_contribution.toString() : ""
          }
          onChange={handlePromotersContributionChange}
          readOnly={true}
        />
        <InputField
          label="Total Grant"
          type="number"
          endAr="(in lakhs)"
          value={matching_grant ? matching_grant.toString() : ""}
          onChange={handleMatchingGrantChange}
          readOnly={true}
        />
        <InputField
          label="Total Loan"
          type="number"
          endAr="(in lakhs)"
          value={term_loan ? term_loan.toString() : ""}
          onChange={handleTermLoanChange}
          readOnly={true}
        />
        {/* <InputField label="Total Progress by the end of this Quarter" type="number" endAr="%" value={progressByEnd} onChange={handleProgressByEndChange} /> */}
      </div>
      <br />
      <div className="word-with-line">
        <span>Component wise Information</span>
        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
      </div>
      <br />
      <div className="componentSub">
        <div class="row g-3">
          <InputField
            label="Payment/ Tranche"
            type="text"
            value={payment_tranche ? payment_tranche.toString() : ""}
            readOnly={true}
          />
          <InputField
            label="Amount Disbursement"
            type="number"
            endAr="(in lakhs)"
            value={amount_disbursement ? amount_disbursement.toString() : ""}
            readOnly={true}
          />
          <DatePickInput3
            label="Disbursement Date"
            className="custom-date-picker"
            disbrushDate={
              disbursement_date ? disbursement_date.toString() : null
            }
            readOnly={true}
          />
         
        </div>
        <br />
        <div className="word-with-line">
          <span>
            Details of expenditure for Current Quarter - {props.compName}
          </span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <br />
        <div class="row g-3">
         
          <div style={{ display: "flex", alignItems: "center" }}>
          
            <InputField
             label="Expenditure incurred"
             type="number"
             endAr="(in lakhs)"
             value={exp_incurred ? exp_incurred.toString() : ""}
             onChange={handleexp_incurredChange}
             onFocus={handleResetFocus}
             error={!!validationErrors.exp_incurred}
             helperText={validationErrors.exp_incurred}
            />
          </div>
        </div>
      </div>
    </>
  );
}
