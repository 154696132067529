import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  existing_infrastructure: "",
  type_of_assistance: "",
  loan_amount: "",
  purpose_of_loan: "",
  interest_rate_pct: "",
  processing_fee_pct: "",
  sanction_date: "",
  disbursed_amount: "",
  outstanding_amount: "",
  disbursement_date: "",
  revised_interest_rate: "",
  addendum_date: "",
};

const filSlice = createSlice({
  name: 'filSlice',
  initialState,
  reducers: {
    updateDataField: (state, action) => {
      const { fieldName, value } = action.payload;
      // Update the corresponding data field
      if (state.hasOwnProperty(fieldName)) {
          state[fieldName] = value;
      }
    },
    resetData: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
  },
});

export const { updateDataField, resetData } = filSlice.actions;

export default filSlice.reducer;
