/* eslint-disable default-case */
import "../assets/css/inputForm.css";
import pdf from "../assets/PDF_file_icon.svg.png";
import imgIcon from "../assets/image_icon-icons.com_50366.png";
import React, { useState } from "react";
import {
  updateApiDataToStudy,
  resetDataStudy,
  setValidationErrorsStudy,
} from "../redux/slice/InputOne/StudyTourExposure/StudyTourExposureSlice";
import {
  updateApiDataToFarmer,
  resetDataFarmer,
  setValidationErrorsFarmer,
} from "../redux/slice/InputOne/StudyTourFarmer/StudyTourFarmerSlice";
import {
  updateApiDataTradeFair,
  resetDataTradeFair,
  setValidationErrorsTradeFair,
} from "../redux/slice/InputOne/TradeFairExhibition/TradeFairSlice";
import {
  updateApiDataMarketProm,
  resetDataMarketProm,
  setValidationErrorsMarketProm,
} from "../redux/slice/InputOne/MarketPromotion/MarketPromotionSlice";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setInputFieldData } from "../redux/slice/InputTwo/MasterSubSlice";
import { MultiSelect } from "../subcomponents/MultiSelect";
import { StudyTourMagnet } from "../subcomponents/Input1/Input1StudyTourMagnet/StudyTourMagnet";
import { StudyTourFarmer } from "../subcomponents/Input1/Input1StudyTourFarmer/StudyTourFarmer";
import { TradeFareExhibition } from "../subcomponents/Input1/Input1TradeFare/TradeFairExhibition";
import { MarketPromotion } from "../subcomponents/Input1/Input1MarketDevProm/MarketPromotion";

export const EventsForm = ({ quarter, magentCrp, divisi }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [StudyExpVisit, setStudyExpVisit] = useState([]);
  const [StudyTourFarm, setStudyTourFarm] = useState([]);
  const [TradeFairExhibition, setTradeFairExhibition] = useState([]);
  const [MarketDevProm, setMarketDevProm] = useState([]);
  const [loadingg, setloadingg] = useState(true);
  const [value, setValue] = useState("21");
  const [quarters, setQuarter] = useState([]);
  const [selectQuarter, setSelectedQuarters] = useState("");
  const [selectPMU, setSelectPMUId] = useState([]);
  const [beneStudyTourFarm, setBeneStudyTourFarm] = useState([]);

  const [selectDivision, setSelectDivision] = useState("");
  const [selectKPMGDivision, setSelectKPMGDivision] = useState("");

  const [selectedStudyTourExpVisit, setSelectedStudyTourExpVisit] =
    useState("");
  const [selectedStudyTourFarmer, setSelectedStudyFarmer] = useState("");
  const [selectedTradeFareExhitbiton, setSelectedTradeFareExhitbiton] =
    useState("");
  const [selectedMarketDevProm, setSelectedMarketDevProm] = useState("");
  const [magentCrop, setMagentCrop] = useState([]);
  const [studyTourFlagEdit, setStudyTourFlagEdit] = useState(false);
  const [studyTourIdEdit, setStudyTourIdEdit] = useState("");

  // Files State
  const [editInput1Files, setEditInput1Files] = useState([]);
  const [selectCapacityFile, setSelectCapacityFile] = useState([]);
  const [errorCapacityFile, setErrorCapacityFile] = useState("");
  const [PMU_Pune, setPMU_PUNE] = useState([]);
  const [studyFarmApplicant, setStudyFarmApplicant] = useState([]);
  const [division, setDivision] = useState([]);
  const [permission, setPermission] = useState([]);

  const dd = useSelector(setInputFieldData);

  const resetAllState = () => {
    setBeneStudyTourFarm([]);
    setEditInput1Files([]);
    setStudyFarmApplicant([]);
    setStudyTourFlagEdit(false);
    setSelectedStudyFarmer("");
    setSelectedStudyTourExpVisit("");
    setSelectedTradeFareExhitbiton("");
    setSelectDivision("");
    dispatch(resetDataMarketProm());
    dispatch(resetDataTradeFair());
    dispatch(resetDataFarmer());
    dispatch(resetDataStudy());
    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setSelectKPMGDivision("")
    resetAllState();
  };

  const handleMultiFilesGAPChange = (event) => {
    setSelectCapacityFile(event.target.files);
  };

  const handleDeleteFile = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/delete-file-input-one-others?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
      setEditInput1Files(newRemarks);
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };
  const handleOptionsStudyTourExpVisit = async (event) => {
    refershdata()
    setSelectedStudyTourExpVisit(event.target.value);
  };

  const handleOptionTradeFare = (event) => {
    refershdata()
    setSelectedTradeFareExhitbiton(event.target.value);
  };

  const handleOptionMarketPromotion = (event) => {
    refershdata()
    setSelectedMarketDevProm(event.target.value);
  };

  const handleDivisionIdChange = (event) => {
    console.log(event.target.value, "Division Value");
    setSelectDivision(event.target.value);
  };

  const handleDivisionKMPGChange = async (event) => {
    setSelectDivision(event.target.value);
    setSelectKPMGDivision(event.target.value);
    setBeneStudyTourFarm([]);
    dispatch(resetDataTradeFair());
    dispatch(resetDataStudy());
    dispatch(resetDataMarketProm());
    dispatch(resetDataFarmer());
    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
    try {
      const beneficiaryByFormDivision = await axios.get(
        `${apiUrl}api/get-beneficiary-by-divisionid?division_id=${event.target.value}`,
        getConfigWithToken()
      );
      const studyFarm = beneficiaryByFormDivision.data.data[0].map((e) => ({
        id: e["beneficiary_id"],
        crop: e["beneficiary_name"],
      }));
      setStudyFarmApplicant(studyFarm);
    } catch (err) {}
  };

  const handlePmuIdChange = async (value) => {
    setSelectPMUId(value);
    const getStudyTourEditData = await axios.get(
      `${apiUrl}api/study-tour-for-magnet-edit?quarter=${selectQuarter}&division_id=${selectDivision}&pmu_id[]=${value.join(
        ","
      )}&training_type_id=${selectedStudyTourExpVisit}&form_id=25`,
      getConfigWithToken()
    );
    if (getStudyTourEditData.data["status"] === true) {
      getStudyTourEditData.data["flag"]
        ? setStudyTourFlagEdit(true)
        : setStudyTourFlagEdit(false);
      const data = getStudyTourEditData.data["data"][0];
      setEditInput1Files(data["remarks"]);
      setStudyTourIdEdit(getStudyTourEditData.data["data"][0]["id"]);
      dispatch(updateApiDataToStudy(data));
    } else {
      setStudyTourFlagEdit(false);
      dispatch(resetDataStudy());
    }
  };

  const handleBenefiStudyTourFarm = async (beneficiary_id) => {
    console.log(beneficiary_id, "beneficiary_idbeneficiary_id");
    setBeneStudyTourFarm(beneficiary_id);
    if (value === "0") {
      const getStudyTourEditData = await axios.get(
        `${apiUrl}api/study-tour-for-farmers-edit?quarter=${selectQuarter}&division_id=${selectDivision}&beneficiary_id=${beneficiary_id.join(
          ","
        )}&training_type_id=${selectedStudyTourFarmer}&form_id=24`,
        getConfigWithToken()
      );
      if (getStudyTourEditData.data["status"] === true) {
        getStudyTourEditData.data["flag"]
          ? setStudyTourFlagEdit(true)
          : setStudyTourFlagEdit(false);
        const data = getStudyTourEditData.data["data"][0];
        data["magnet_crop_id"]
          ? (data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
              (e) => e.id
            ))
          : (data["magnet_crop_id"] = []);
        setEditInput1Files(data["remarks"]);
        setStudyTourIdEdit(data["id"]);
        dispatch(updateApiDataToFarmer(data));
      } else {
        setStudyTourFlagEdit(false);
        dispatch(resetDataFarmer());
      }
    }
    if (value === "21") {
      const getStudyTourEditData = await axios.get(
        `${apiUrl}api/trade-fair-exhibition-input-one-edit?quarter=${selectQuarter}&division_id=${selectKPMGDivision}&beneficiary_id=${beneficiary_id.join(
          ","
        )}&training_type_id=${selectedTradeFareExhitbiton}&form_id=33`,
        getConfigWithToken()
      );
      if (getStudyTourEditData.data["status"] === true) {
        getStudyTourEditData.data["flag"]
          ? setStudyTourFlagEdit(true)
          : setStudyTourFlagEdit(false);
        const data = getStudyTourEditData.data["data"][0];
        setEditInput1Files(data["remarks"]);
        setStudyTourIdEdit(data["id"]);
        dispatch(updateApiDataTradeFair(data));
      } else {
        setEditInput1Files([]);
        setStudyTourFlagEdit(false);
        dispatch(resetDataTradeFair());
      }
    }
    if (value === "22") {
      const getStudyTourEditData = await axios.get(
        `${apiUrl}api/market-dev-promotion-input-one-edit?quarter=${selectQuarter}&beneficiary_id=${beneficiary_id.join(
          ","
        )}&training_type_id=${selectedMarketDevProm}&form_id=32`,
        getConfigWithToken()
      );
      if (getStudyTourEditData.data["status"] === true) {
        getStudyTourEditData.data["flag"]
          ? setStudyTourFlagEdit(true)
          : setStudyTourFlagEdit(false);
        const data = getStudyTourEditData.data["data"][0];
        setEditInput1Files(data["remarks"]);
        setStudyTourIdEdit(data["id"]);
        dispatch(updateApiDataMarketProm(data));
      } else {
        setEditInput1Files([]);
        setStudyTourFlagEdit(false);
        dispatch(resetDataMarketProm());
      }
    }
  };

  const handleOptionsStudyFarmer = async (event) => {
    refershdata()
    setSelectedStudyFarmer(event.target.value);
  };

  const refershdata = () => {
    setSelectKPMGDivision("");
    setBeneStudyTourFarm([]);
    setSelectDivision("");
    setSelectPMUId([]);
    dispatch(resetDataMarketProm({}));
    dispatch(resetDataTradeFair({}));
    dispatch(resetDataFarmer({}));
    dispatch(resetDataStudy({}));

    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };
  const handleQuarterChange = async (event) => {
    refershdata();
    setStudyTourFlagEdit("")
    setSelectedQuarters(event.target.value);
  };

  const handleStudyTourSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["studyTourExpo"];
      const errorMessages = {
        from_date: "Please Select From Date",
        to_date: "Please Select To Date",
        location_address: "Location is Required Field",
        purpose: "Purpose is Required Field",
        participants_male: "Participants Male is Required Field",
        participants_female: "Participants Female is Required Field",
        participants_total: "Participants Total is Required Field",
      };

      if (selectedStudyTourExpVisit === 17) {
        errorMessages["country_id"] = "country is a Required Field";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsStudy(errors));
      console.log(errors, "err");
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };
    console.log(dd["payload"]["studyTourExpo"], "from validate");
    if (validateForm()) {
      console.log(validateForm(), "from validate");

      try {
        setLoading(true)
        const studyTour = dd["payload"]["studyTourExpo"];

        const formData = new FormData();
        for (const key in studyTour) {
          formData.append(key, studyTour[key] !== null ? studyTour[key] : "");
        }

        formData.append("quarter", selectQuarter);
        formData.append("training_type_id", selectedStudyTourExpVisit);
        formData.append("division_id", selectDivision);
        formData.append("pmu_id", selectPMU);
        formData.append("form_id", "25");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!studyTourFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitStudyTourData = await axios.post(
              `${apiUrl}api/study-tour-for-magnet-create`,
              formData,
              getConfigWithToken()
            );
            if (submitStudyTourData.data["status"] === true) {
              Swal.fire(
                `${submitStudyTourData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataStudy());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitStudyTourData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          } else {
            // also have to set Id in object
            formData.append("id", studyTourIdEdit);
            const submitStudyTourData = await axios.post(
              `${apiUrl}api/study-tour-for-magnet-update`,
              formData,
              getConfigWithToken()
            );

            if (submitStudyTourData.data["status"] === true) {
              Swal.fire(
                `${submitStudyTourData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataStudy());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitStudyTourData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleStudyTourFarmerSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["studyTourFarmer"];
      const errorMessages = {
        magnet_crop_id: "Magnet Crop is a Required Field",
        from_date: "From Date is a Required Field",
        to_date: "To Date is a Required Field",
        location_address: "Location Address is a Required Field",
        purpose: "Purpose is a Required Field",
        sc_male: "SC Male is a Required Field",
        sc_female: "SC Female is a Required Field",
        sc_total: "SC Total is a Required Field",
        st_male: "ST Male is a Required Field",
        st_female: "ST Female is a Required Field",
        st_total: "ST Total is a Required Field",
        pwd_male: "PWD Male is a Required Field",
        pwd_female: "PWD Female is a Required Field",
        pwd_total: "PWD Total is a Required Field",
        obc_minority_open_male: "OBC Minority Open Male is a Required Field",
        obc_minority_open_female:
          "OBC Minority Open Female is a Required Field",
        obc_minority_open_total: "OBC Minority Open Total is a Required Field",
      };

      if (selectedStudyTourFarmer === 14) {
        errorMessages["district_id"] = "District is a Required Field";
      } else if (selectedStudyTourFarmer === 15) {
        errorMessages["state_id"] = "State is a Required Field";
      } else if (selectedStudyTourFarmer === 16) {
        errorMessages["country_id"] = "Country is a Required Field";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsFarmer(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true)
        const studyTourFarmer = dd["payload"]["studyTourFarmer"];
        const jsonMagnetCrop = studyTourFarmer["magnet_crop_id"]
          .map((id) => {
            const matchingObject = magentCrop.find((item) => item.id === id);
            if (matchingObject) {
              return {
                id: matchingObject.id,
                name: matchingObject.crop,
              };
            }
            return null; // Handle cases where there's no matching ID
          })
          .filter((item) => item !== null); // Remove any null entries

        const resultMagnetCrop = { data: jsonMagnetCrop };

        const formData = new FormData();
        for (const key in studyTourFarmer) {
          formData.append(
            key,
            studyTourFarmer[key] !== null ? studyTourFarmer[key] : ""
          );
        }

        formData.append("quarter", selectQuarter);
        formData.append("training_type_id", selectedStudyTourFarmer);
        formData.append("division_id", selectDivision);
        formData.append(
          "magnet_crop_id",
          JSON.stringify(resultMagnetCrop["data"])
        );
        formData.append("beneficiary_id", beneStudyTourFarm);
        formData.append("form_id", "24");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!studyTourFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitStudyTourFarmData = await axios.post(
              `${apiUrl}api/study-tour-for-farmers-create`,
              formData,
              getConfigWithToken()
            );
            if (submitStudyTourFarmData.data["status"] === true) {
              Swal.fire(
                `${submitStudyTourFarmData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataFarmer());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitStudyTourFarmData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          } else {
            // also have to set Id in object
            formData.append("id", studyTourIdEdit);
            const submitStudyTourFarmData = await axios.post(
              `${apiUrl}api/study-tour-for-farmers-update`,
              formData,
              getConfigWithToken()
            );

            if (submitStudyTourFarmData.data["status"] === true) {
              Swal.fire(
                `${submitStudyTourFarmData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataFarmer());
                setLoading(false);
                });
              // dispatch(resetDataFarmer());
            } else {
              Swal.fire(
                `${submitStudyTourFarmData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleTradeFairSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["tradeFair"];
      const errorMessages = {
        from_date: "From Date is a Required Field",
        to_date: "To Date is a Required Field",
        location_address: "Location Address is a Required Field",
        name_of_trade_fair_exhibition:
          "Name of Trade Fair Exhibition is a Required Field",
        participants_male: "Participants Male is a Required Field",
        participants_female: "Participants Female is a Required Field",
      };

      if (selectedTradeFareExhitbiton === 20) {
        errorMessages["state_id"] = "State is a Required Field";
      } else if (selectedTradeFareExhitbiton === 21) {
        errorMessages["country_id"] = "Country is a Required Field";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsTradeFair(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };
    if (validateForm()) {
      try {
        setLoading(true)
        const tradeFair = dd["payload"]["tradeFair"];

        const formData = new FormData();
        for (const key in tradeFair) {
          formData.append(key, tradeFair[key] !== null ? tradeFair[key] : "");
        }

        formData.append("quarter", selectQuarter);
        formData.append("training_type_id", selectedTradeFareExhitbiton);
        formData.append("division_id", selectKPMGDivision);
        formData.append("beneficiary_id", beneStudyTourFarm);
        formData.append("form_id", "33");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false)
          return;
        }

        try {
          if (!studyTourFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitTradeFairData = await axios.post(
              `${apiUrl}api/trade-fair-exhibition-input-one-create`,
              formData,
              getConfigWithToken()
            );
            if (submitTradeFairData.data["status"] === true) {
              Swal.fire(
                `${submitTradeFairData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTradeFair());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitTradeFairData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          } else {
            // also have to set Id in object
            formData.append("id", studyTourIdEdit);
            const submitTradeFairData = await axios.post(
              `${apiUrl}api/trade-fair-exhibition-input-one-update`,
              formData,
              getConfigWithToken()
            );

            if (submitTradeFairData.data["status"] === true) {
              Swal.fire(
                `${submitTradeFairData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataTradeFair());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitTradeFairData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleMarketPromSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["marketPromotion"];
      const errorMessages = {
        from_date: "From Date is a Required Field",
        to_date: "To Date is a Required Field",
        location_taluka: "Location Taluka is a Required Field",
        participants_male: "Participants Male is a Required Field",
        participants_female: "Participants Female is a Required Field",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsMarketProm(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true)
        const marketProm = dd["payload"]["marketPromotion"];

        const formData = new FormData();
        for (const key in marketProm) {
          formData.append(key, marketProm[key] !== null ? marketProm[key] : "");
        }

        formData.append("quarter", selectQuarter);
        formData.append("training_type_id", selectedMarketDevProm);
        formData.append("division_id", selectKPMGDivision);
        formData.append("beneficiary_id", beneStudyTourFarm);
        formData.append("form_id", "32");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          return;
        }

        try {
          if (!studyTourFlagEdit) {
            // if(true){
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitMarketPromData = await axios.post(
              `${apiUrl}api/market-dev-promotion-input-one-create`,
              formData,
              getConfigWithToken()
            );
            if (submitMarketPromData.data["status"] === true) {
              Swal.fire(
                `${submitMarketPromData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataMarketProm());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitMarketPromData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          } else {
            // also have to set Id in object
            formData.append("id", studyTourIdEdit);
            const submitMarketPromData = await axios.post(
              `${apiUrl}api/market-dev-promotion-input-one-update`,
              formData,
              getConfigWithToken()
            );

            if (submitMarketPromData.data["status"] === true) {
              Swal.fire(
                `${submitMarketPromData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataMarketProm());
                setLoading(false);
                });
            } else {
              Swal.fire(
                `${submitMarketPromData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
                });
            }
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      } catch (error) {
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  const handleDeleteFarmerStudy= async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
  if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/study-tour-for-farmers-delete?id=${studyTourIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          setStudyTourFlagEdit("");
          dispatch(resetDataStudy());
          setLoading(false);
        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };

  const handleDeleteMagnetStudy= async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
  if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/study-tour-for-magnet-delete?id=${studyTourIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          setStudyTourFlagEdit("");
          dispatch(resetDataStudy());
          setLoading(false);
        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };

  const handleDeleteTradeFair= async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
  if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/trade-fair-exhibition-input-one-delete?id=${studyTourIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          setStudyTourFlagEdit("");
          dispatch(resetDataTradeFair());
          setLoading(false);
        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };

  const handleDeleteMarkDevProm= async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
  if (result.isConfirmed) {
    try {
      const response = await axios.get(
        `${apiUrl}api/market-dev-promotion-input-one-delete?id=${studyTourIdEdit}`,
        getConfigWithToken()
      );

      if (response.data.status === true) {
        Swal.fire(
          `${response.data.message}`,
          "",
          "success"
        ).then(() => {
          refershdata();
          setStudyTourFlagEdit("");
          dispatch(resetDataMarketProm());
          setLoading(false);
        });
      } else {
        Swal.fire(
          `${response.data.message}`,
          "",
          "warning"
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  };
  
  useEffect(() => {
    const quarterData = async () => {
      try {
        const storedArrayAsString = localStorage.getItem("permission");
        setPermission(storedArrayAsString);

        const applicant = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1`,
          getConfigWithToken()
        );

        const pmu = await axios.get(
          `${apiUrl}api/pmu-listing?all=1`,
          getConfigWithToken()
        );
        const expVisit = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=25`,
          getConfigWithToken()
        );
        const tourFarm = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=24`,
          getConfigWithToken()
        );
        const tradeFair = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=33`,
          getConfigWithToken()
        );
        const marketDevProm = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=32`,
          getConfigWithToken()
        );

        const pmuDivision = pmu.data["data"].map((e) => ({
          id: e.id,
          crop: e.pmu_name,
        }));
        const studyFarm = applicant.data["data"].map((e) => ({
          id: e["id"],
          crop: e["beneficiary_name"],
        }));

        setStudyExpVisit(expVisit.data.data[0]);
        setStudyTourFarm(tourFarm.data.data[0]);
        setTradeFairExhibition(tradeFair.data.data[0]);
        setMarketDevProm(marketDevProm.data.data[0]);
        setStudyFarmApplicant(studyFarm);
        setQuarter(quarter);
        const selectedValues = quarter
          .filter((item) => item.selected === "Selected")
          .map((item) => item.id);
        setSelectedQuarters(selectedValues[0]);
        setMagentCrop(magentCrp);
        setDivision(divisi);
        setPMU_PUNE(pmuDivision);
        setloadingg(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    quarterData();

    setloadingg(false);
  }, [quarter, magentCrp, divisi]);


  if (loadingg) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
    
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div id="exTab3" class="contain">
              <div class="card">
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    bgcolor: "background.paper",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        value={value}
                        onChange={handleChange}
                        sx={{ width: "100%", overflowX: "auto" }}
                      >
                        <Tab
                          label="Trade Fair & Exhibition"
                          value="21"
                          className={`${
                            value === "21" ? "activeClickCSS" : ""
                          }`}
                        />
                        <Tab
                          label="Study Tours For Farmers"
                          value="0"
                          className={`${value === "0" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Study Tours, Exposure visits"
                          value="10"
                          className={`${
                            value === "10" ? "activeClickCSS" : ""
                          }`}
                        />
                        <Tab
                          label="Market Development and Promotion"
                          value="22"
                          className={`${
                            value === "22" ? "activeClickCSS" : ""
                          }`}
                        />
                      </Tabs>
                    </Box>

                    {/* Study Tour For Farmers*/}
                    <TabPanel value="0">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleStudyTourFarmerSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>

                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of -{" "}
                              {selectedStudyTourFarmer &&
                                StudyTourFarm.find(
                                  (e) => e["id"] === selectedStudyTourFarmer
                                ).type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedStudyTourFarmer}
                                    onChange={handleOptionsStudyFarmer}
                                    label="Trainin"
                                  >
                                    {StudyTourFarm.map((e) => (
                                      <MenuItem value={e.id}>{e.type}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />

                          <div>
                            {selectedStudyTourFarmer && (
                              <>
                                <div
                                  class="row g-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Division
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectDivision}
                                          onChange={handleDivisionKMPGChange}
                                          label="Division"
                                        >
                                          {division.map((e) => (
                                            <MenuItem value={e.id}>
                                              {e.divisions}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <MultiSelect
                                      label="Beneficiary"
                                      data={studyFarmApplicant}
                                      value={beneStudyTourFarm}
                                      onChange={handleBenefiStudyTourFarm}
                                    />
                                  </div>
                                </div>
                                <StudyTourFarmer
                                  magnetCrop={magentCrop}
                                  compValue={selectedStudyTourFarmer}
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    class="col-md-6"
                                    style={{
                                      position: "relative",
                                      right: "25px",
                                    }}
                                  >
                                    <label
                                      for="inputNumber"
                                      class="col-lg col-form-label"
                                    >
                                      Upload supporting document. (e.g QPR,
                                      Photos etc.)
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="formFile"
                                        name="remarks"
                                        multiple
                                        onChange={handleMultiFilesGAPChange}
                                        onFocus={() => {
                                          setErrorCapacityFile("");
                                        }}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errorCapacityFile}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {(editInput1Files || []).map((e) => (
                                      <div className="icon-container">
                                        <a
                                          href={`${fileUrl}${e.file_name}`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{ marginRight: "10px" }}
                                        >
                                          <img
                                            src={
                                              e.type === "pdf" ? pdf : imgIcon
                                            }
                                            alt=""
                                            height="30px"
                                            width="26px"
                                          />
                                        </a>
                                        <i
                                          class="fa-regular fa-circle-xmark"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDeleteFile(e.id)}
                                        ></i>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Study-Tour-Farmers-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn" disabled={loading}>
                                  {studyTourFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {studyTourFlagEdit === true && (
                                        <button type="button" className="btn submitbtn"  disabled={loading} onClick={handleDeleteFarmerStudy}>
                                            Delete
                                        </button>
                                        )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Study Tours, Exposure visits */}
                    <TabPanel value="10">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleStudyTourSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>

                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of -{" "}
                              {selectedStudyTourExpVisit &&
                                StudyExpVisit.find(
                                  (e) => e["id"] === selectedStudyTourExpVisit
                                ).type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedStudyTourExpVisit}
                                    onChange={handleOptionsStudyTourExpVisit}
                                    label="Trainin"
                                  >
                                    {StudyExpVisit.map((e) => (
                                      <MenuItem value={e.id}>{e.type}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />
                          <div>
                            {selectedStudyTourExpVisit && (
                              <>
                                <div
                                  class="row g-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Division
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectDivision}
                                          onChange={handleDivisionIdChange}
                                          label="Division"
                                        >
                                          {division.map((e) => (
                                            <MenuItem value={e.id}>
                                              {e.divisions}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <MultiSelect
                                      label="PMU_Pune /PIU-Division Name"
                                      data={PMU_Pune}
                                      value={selectPMU}
                                      onChange={handlePmuIdChange}
                                    />
                                  </div>
                                </div>
                                <StudyTourMagnet
                                  compValue={selectedStudyTourExpVisit}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    class="col-md-6"
                                    style={{
                                      position: "relative",
                                      right: "25px",
                                    }}
                                  >
                                    <label
                                      for="inputNumber"
                                      class="col-lg col-form-label"
                                    >
                                      Upload supporting document. (e.g QPR,
                                      Photos etc.)
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="formFile"
                                        name="remarks"
                                        multiple
                                        onChange={handleMultiFilesGAPChange}
                                        onFocus={() => {
                                          setErrorCapacityFile("");
                                        }}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errorCapacityFile}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {(editInput1Files || []).map((e) => (
                                      <div className="icon-container">
                                        <a
                                          href={`${fileUrl}${e.file_name}`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{ marginRight: "10px" }}
                                        >
                                          <img
                                            src={
                                              e.type === "pdf" ? pdf : imgIcon
                                            }
                                            alt=""
                                            height="30px"
                                            width="26px"
                                          />
                                        </a>
                                        <i
                                          class="fa-regular fa-circle-xmark"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDeleteFile(e.id)}
                                        ></i>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Study-Tour-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn" disabled={loading}>
                                  {studyTourFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {studyTourFlagEdit === true && (
                                        <button type="button" className="btn submitbtn"  disabled={loading}  onClick={handleDeleteMagnetStudy}>
                                            Delete
                                        </button>
                                        )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Trade Fair & Exhibition */}
                    <TabPanel value="21">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleTradeFairSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>

                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of -{" "}
                              {selectedTradeFareExhitbiton &&
                                TradeFairExhibition.find(
                                  (e) => e["id"] === selectedTradeFareExhitbiton
                                ).type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedTradeFareExhitbiton}
                                    onChange={handleOptionTradeFare}
                                    label="Trainin"
                                  >
                                    {TradeFairExhibition.map((e) => (
                                      <MenuItem value={e.id}>{e.type}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />
                          <div>
                            {selectedTradeFareExhitbiton && (
                              <>
                                <div
                                  class="row g-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Division
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectKPMGDivision}
                                          onChange={handleDivisionKMPGChange}
                                          label="Division"
                                        >
                                          {division.map((e) => (
                                            <MenuItem value={e.id}>
                                              {e.divisions}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <MultiSelect
                                      label="Beneficiary"
                                      data={studyFarmApplicant}
                                      value={beneStudyTourFarm}
                                      onChange={handleBenefiStudyTourFarm}
                                    />
                                  </div>
                                </div>
                                <TradeFareExhibition
                                  compValue={selectedTradeFareExhitbiton}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    class="col-md-6"
                                    style={{
                                      position: "relative",
                                      right: "25px",
                                    }}
                                  >
                                    <label
                                      for="inputNumber"
                                      class="col-lg col-form-label"
                                    >
                                      Upload supporting document. (e.g QPR,
                                      Photos etc.)
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="formFile"
                                        name="remarks"
                                        multiple
                                        onChange={handleMultiFilesGAPChange}
                                        onFocus={() => {
                                          setErrorCapacityFile("");
                                        }}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errorCapacityFile}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {(editInput1Files || []).map((e) => (
                                      <div className="icon-container">
                                        <a
                                          href={`${fileUrl}${e.file_name}`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{ marginRight: "10px" }}
                                        >
                                          <img
                                            src={
                                              e.type === "pdf" ? pdf : imgIcon
                                            }
                                            alt=""
                                            height="30px"
                                            width="26px"
                                          />
                                        </a>
                                        <i
                                          class="fa-regular fa-circle-xmark"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDeleteFile(e.id)}
                                        ></i>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Trade-Fair-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn" disabled={loading}>
                                  {studyTourFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {studyTourFlagEdit === true && (
                                        <button type="button" className="btn submitbtn"  disabled={loading} onClick={handleDeleteTradeFair}>
                                            Delete
                                        </button>
                                        )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Market Development and Promotion */}
                    <TabPanel value="22">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleMarketPromSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>

                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of -{" "}
                              {selectedMarketDevProm &&
                                MarketDevProm.find(
                                  (e) => e["id"] === selectedMarketDevProm
                                ).type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMarketDevProm}
                                    onChange={handleOptionMarketPromotion}
                                    label="Trainin"
                                  >
                                    {MarketDevProm.map((e) => (
                                      <MenuItem value={e.id}>{e.type}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />
                          <div>
                            {selectedMarketDevProm && (
                              <>
                                <div
                                  class="row g-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Division
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectKPMGDivision}
                                          onChange={handleDivisionKMPGChange}
                                          label="Division"
                                        >
                                          {division.map((e) => (
                                            <MenuItem value={e.id}>
                                              {e.divisions}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <MultiSelect
                                      label="Beneficiary"
                                      data={studyFarmApplicant}
                                      value={beneStudyTourFarm}
                                      onChange={handleBenefiStudyTourFarm}
                                    />
                                  </div>
                                </div>
                                <MarketPromotion
                                  compValue={selectedMarketDevProm}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    class="col-md-6"
                                    style={{
                                      position: "relative",
                                      right: "25px",
                                    }}
                                  >
                                    <label
                                      for="inputNumber"
                                      class="col-lg col-form-label"
                                    >
                                      Upload supporting document. (e.g QPR,
                                      Photos etc.)
                                    </label>
                                    <div class="col-sm-10">
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="formFile"
                                        name="remarks"
                                        multiple
                                        onChange={handleMultiFilesGAPChange}
                                        onFocus={() => {
                                          setErrorCapacityFile("");
                                        }}
                                      />
                                      <span style={{ color: "red" }}>
                                        {errorCapacityFile}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {(editInput1Files || []).map((e) => (
                                      <div className="icon-container">
                                        <a
                                          href={`${fileUrl}${e.file_name}`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{ marginRight: "10px" }}
                                        >
                                          <img
                                            src={
                                              e.type === "pdf" ? pdf : imgIcon
                                            }
                                            alt=""
                                            height="30px"
                                            width="26px"
                                          />
                                        </a>
                                        <i
                                          class="fa-regular fa-circle-xmark"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleDeleteFile(e.id)}
                                        ></i>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Market-Dev-Promo-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button type="submit" class="btn submitbtn" disabled={loading}>
                                  {studyTourFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {studyTourFlagEdit === true && (
                                        <button type="button" className="btn submitbtn"  disabled={loading} onClick={handleDeleteMarkDevProm}>
                                            Delete
                                        </button>
                                        )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
      
    </>
  );
};
