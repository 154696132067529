import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";
import "../../../assets/css/masterdata.css";

export const PasswordRecoveryAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({});
  const [page, setPage] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  // Fetching all the exisiting data
  const fetchData = async () => {
    try {
      const getfacility = await axios.get(
        `${apiUrl}api/questions-listing`,
        getConfigWithToken()
      );
      if (getfacility.data && getfacility.data.data.data) {
        const facility = getfacility.data.data.data.map((e) => ({
          id: e.id,
          question: e.question,
        }));
        console.log(facility, "fac");
        setAttributeOptions(facility);
        setGetAllData(getfacility.data.data);
      } else {
        console.error("Unexpected response structure:", getfacility.data);
      }
    } catch (error) {
      console.error("Error fetching Bank:", error);
    }
  };

  // Adding new attribute
  const handleAddOption = async () => {
    try {
      const { value: enterValue } = await Swal.fire({
        title: "Enter Password Recovery Question",
        input: "text",
        inputLabel: "Password Recovery Question",
        inputValue: "",
        showCancelButton: true,
        confirmButtonText: "Save",
        inputValidator: (value) => {
          if (!value) {
            return "Enter Password Recovery Question";
          }
        },
      });

      if (enterValue) {
        const response = await axios.post(
          `${apiUrl}api/questions-create`,
          {
            question: enterValue,
          },
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire(`Attribute Added Successfully`, "", "success");
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error adding Password Recovery Question:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Editing the attribute
  const handleEditFunction = async (value, id) => {
    try {
      const { value: editValue } = await Swal.fire({
        title: "Edit Password Recovery Question",
        input: "text",
        inputLabel: "Your Password Recovery Question",
        inputValue: value,
        showCancelButton: true,
        confirmButtonText: "Edit",
        inputValidator: (value) => {
          if (!value) {
            return "Enter Password Recovery Question";
          }
        },
      });

      if (editValue) {
        const response = await axios.post(
          `${apiUrl}api/questions-update`,
          {
            question: editValue,
            id: id,
          },
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire(`Attribute Edited Successfully`, "", "success");
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error editing Password Recovery Question:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Delete operation
  const handleDeleteFunction = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        const response = await axios.get(
          `${apiUrl}api/questions-delete?id=${id}`,
          getConfigWithToken()
        );
  
        if (response.data && response.data.status === true) {
          Swal.fire({
            title: "Deleted!",
            text: "Attribute has been deleted.",
            icon: "success",
          });
          await fetchData();
        } else {
          throw new Error(response.data.message || "Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error deleting Password Recovery Question:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };
  
// Onchange of Pagination
  const handleChange = async (event, value) => {
    try {
      const getfacilitiestype = await axios.get(
        `${apiUrl}api/questions-listing?page=${value}`,
        getConfigWithToken()
      );
      const facilitiestype = getfacilitiestype.data["data"]["data"].map(
        (e) => ({
          id: e.id,
          question: e.question,
        })
      );
      setAttributeOptions(facilitiestype);
      setGetAllData(getfacilitiestype.data.data.data);
      setPage(value);
    } catch (error) {
      console.error("Error fetching Bank:", error);
    }
  };

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div id="exTab3" className="contain" style={{ width: "92%" }}>
              <div
                className="cardMaster"
                id=""
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <form class="row g-3">
                  <Box
                    className="your-component"
                    sx={{ typography: "body1", width: "88%" }}
                  >
                    <div className="words-with-lines">
                      <span>Password Recovery Questions</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata" style={{ marginRight: "1rem" }}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>
                  <Box className="your-box" sx={{ typography: "body1" }}>
                    <div>
                      <span>Manage Options (Value of your Attribute)</span>
                    </div>
                  </Box>
                  <div className="your-button-container">
                    <button
                      type="button"
                      style={{ width: "30%" }}
                      onClick={(e) => handleAddOption(e)}
                    >
                      <i className="fa-duotone fa-plus"></i> Add Attributes
                      Options{" "}
                    </button>
                  </div>
                  <Box className="custom-box" style={{ marginLeft: "2rem" }}>
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Attribute Options</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="your-tbody">
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.question}
                                disabled
                              />
                            </td>
                            <td>
                              <i
                                class="fa-solid fa-pen-to-square"
                                onClick={() =>
                                  handleEditFunction(
                                    option.question,
                                    option.id
                                  )
                                }
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={() => handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                      />
                    </table>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
