import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ///////////
  applicant_name: "",
  division_id: "",
  district_id: "",
  taluka_id: "",
  magnet_crop_id: [],
  existing_crop_id: [],
  entity_type_id: "",
  existing_infrastructure: "",
  type_of_assistance: "",
  bank_id: "",
  //
  loan_amount: "",
  purpose_of_loan: "",
  interest_rate_pct: "",
  processing_fee_pct: "",
  sanction_date: "",
  disbursed_amount: "",
  outstanding_amount: "",
  disbursement_date: null,
  revised_interest_rate: "",
  addendum_date: null,

  // Validation
  validationErrors: {
    applicant_name: "",
    division_id: "",
    district_id: "",
    taluka_id: "",
    magnet_crop_id: [],
    existing_crop_id: [],
    entity_type_id: "",
    existing_infrastructure: "",
    type_of_assistance: "",
    //
    loan_amount: "",
    purpose_of_loan: "",
    interest_rate_pct: "",
    processing_fee_pct: "",
    sanction_date: "",
    disbursed_amount: "",
    outstanding_amount: "",
    disbursement_date: null,
    revised_interest_rate: "",
    addendum_date: null,
  },
};

const FILMasterSlice = createSlice({
  name: "FILMasterSlice",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataFILMaster: (state, action) => {
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataFILMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsFILMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setValidationErrorsFILMaster,
  resetValidation,
  setInputFieldData,
  updateApiDataFILMaster,
  resetDataFILMaster,
} = FILMasterSlice.actions;

export default FILMasterSlice.reducer;
