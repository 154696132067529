
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  "26": {
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',

    actual_date_1: '',
    actual_date_2: '',
    actual_date_3: '',
    actual_date_4: '',
  
    typedata: [],
  },

    // Validation
  validationErrors:{  
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
  
    mg_1st_tranche:'',
    mg_2nd_tranche:'',
    mg_3rd_tranche:'',
    mg_4th_tranche:'',
    actual_date_1:'',
    actual_date_2:'',
    actual_date_3:'',
    actual_date_4:'',
        }
    };

const SupportCommMasterSlice = createSlice({
  name: 'SupportCommMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["26"][action.payload.fieldName] = action.payload.value;
    },
    setInputActivity: (state, action) => {
      state["26"].typedata = action.payload;
    },
    // Add other reducers for different input fields if needed
    updateApiDataSupportCommMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["26"]) {
        if (payload[key] !== undefined) {
          // state["26"][key] = payload[key];
          if(payload[key] === null){
            state["26"][key] = '';
          }else{
            state["26"][key] = payload[key];
          }
        }
      }
    },


    resetDataSupportCommMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsSupportCommMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, setInputActivity, resetValidation, setInputFieldData, updateApiDataSupportCommMaster, resetDataSupportCommMaster } = SupportCommMasterSlice.actions;

export default SupportCommMasterSlice.reducer;
