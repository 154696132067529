import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  magnet_crop_id: [],
  tuc_pack_house: "",
  tuc_cold_storage: "",
  tuc_reefer_vans: "",
  tuc_frozen_storage: "",
  estimated_losses_pack_house: "",
  estimated_losses_cold_storage: "",
  estimated_losses_frozen_storage: "",
  estimated_losses_reefer_vans: "",
  total_estimated_losses: "",
  losses_percentage: "",

  validationErrors: {
    magnet_crop_id: [],
    tuc_pack_house: "",
    tuc_cold_storage: "",
    tuc_reefer_vans: "",
    tuc_frozen_storage: "",
    estimated_losses_pack_house: "",
    estimated_losses_cold_storage: "",
    estimated_losses_frozen_storage: "",
    estimated_losses_reefer_vans: "",
  },
};


const BeneficiariesLossSlice = createSlice({
  name: "beneficiaryLossMaster",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },
    updateApiDataToBeneLoss: (state, action) => {
      const payload = action.payload;
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataBeneLoss: (state) => {
      return { ...initialState };
    },
    setValidationErrorsBeneLoss: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetBeneLossValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setValidationErrorsBeneLoss,
  resetBeneLossValidation,
  setInputFieldData,
  updateApiDataToBeneLoss,
  resetDataBeneLoss,
} = BeneficiariesLossSlice.actions;

export default BeneficiariesLossSlice.reducer;
