import React from 'react';
import { Box } from '@mui/material';
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import { Link } from 'react-router-dom';
import { FILListing } from "../../../../subcomponents/MasterFormTables/PrimaryTables/FILMaster/FILListing";
import '../../../../assets/css/masterdata.css'
import Button from '@mui/material/Button';

export const FILDetailListing = () =>{
    return(<>
    <Header/>
    <LeftNavigation/>
        <main id="main" class="main">
            <section class="section">
                <div class="row">
                    <div id="exTab3" class="contain" >	           
                        <div class="cardMaster" id="">
                            <div className="words-with-lines" id="Heading" >
                                <br/>
                                    <span>FIL Master</span>
                            </div>
                            {/* Add FIL/Back */}
                            <Box className='AddHeader' sx={{ typography: 'body1', }}>                
                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end",marginRight:'2rem',height:'70px'}}> 
                                <a href="./masterdata" class="back-link" style={{marginRight:'1rem'}}>
                                    <i class="fa-solid fa-arrow-left"></i>Back
                                </a>
                                <Link to="/AddFil">
                                    <Button class="add-fil-button">
                                        Add FIL
                                    </Button>
                                </Link>
                                <button class="caret-button"><i class="fa-solid fa-caret-down"></i></button>
                            </div>
                            </Box>   
                           
                            {/* Listing Table */}
                            <FILListing/>
                        </div>
                    </div> 
                </div> 
            </section>
        </main>
    </>)
}

