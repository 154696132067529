import React, { useState } from "react";
import { Offline, Online } from "react-detect-offline";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "../../../../assets/css/masterdata.css";
import {
  resetValidation,
  setInputFieldData,
} from "../../../../redux/slice/MSAMBMaster/MSAMBMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { getConfigWithToken } from "../../../../utils/Config/Config";

export const MSAMBBeneficiaryDetails = (props) => {
  const { MsambFacility, BeneficiaryFlagEdit, magnet_crop_id } = props;

  const dispatch = useDispatch();
  const msambMaster = useSelector((state) => state.msambMaster);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

// Function to handle clicking on the edit icon
const handleEditClick = () => {
  setIsUpdating(true);
  // Rest of your code...
};

// Function to handle updating
const handleUpdate = () => {
  // Rest of your code...
  setIsUpdating(false);
};

  const handleFieldMSAMBFacility = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "msamb_facility_id",
        value: event.target.value,
      })
    );
    props.onMsambFacilityChange(event);
  };

  const handleFieldWorkPackage = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "work_package_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldDivision = (event) => {
    const divisionId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "division_id", value: divisionId })
    );

    // Fetch districts based on the selected division
    axios
      .get(
        `${apiUrl}api/district-by-division?division_id=${divisionId}`,
        getConfigWithToken()
      )
      .then((response) => {
        console.log("SOmething: --------", response.data.data);
        setDistricts(response.data.data);
        setTalukas([]); // Reset talukas when division changes
      })
      .catch((error) => {
        console.error("Error fetching districts:", error);
      });
  };

  const handleFieldDistrict = (event) => {
    const districtId = event.target.value;
    dispatch(
      setInputFieldData({ fieldName: "district_id", value: districtId })
    );

    // Fetch talukas based on the selected district
    axios
      .get(
        `${apiUrl}api/get-taluka-by-district?district_id=${districtId}`,
        getConfigWithToken()
      )
      .then((response) => {
        setTalukas(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching talukas:", error);
      });
  };
  const handleFieldTaluka = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "taluka_id", value: event.target.value })
    );
  };

  const handleFieldMAGNETCrop = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "magnet_crop_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldArea = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "area", value: event.target.value })
    );
  };
  const handleFieldMale = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "male", value: event.target.value })
    );
  };
  const handleFieldFemale = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "female", value: event.target.value })
    );
  };
  const handleFieldShareHolders = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "shareholders",
        value: event.target.value,
      })
    );
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  useEffect(() => {
    console.log("Magnet Crop Data:", props.MagnetCrop);
    console.log("msambMaster.magnet_crop_id:", msambMaster.magnet_crop_id);
  }, [props.MagnetCrop, msambMaster.magnet_crop_id]);

  useEffect(() => {
    // Assuming there's some state management logic in your component
    setInputFieldData("magnet_crop_id", magnet_crop_id);
  }, [magnet_crop_id]);

  useEffect(() => {
    if (props.initialValues) {
      let magnetCropArray;
      if (
        typeof msambMaster.magnet_crop_id[0] === "string" &&
        msambMaster.magnet_crop_id[0].trim() !== ""
      ) {
        try {
          magnetCropArray = JSON.parse(msambMaster.magnet_crop_id[0]);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          console.log("JSON string:", msambMaster.magnet_crop_id[0]);
          magnetCropArray = [];
        }
      } else if (Array.isArray(msambMaster.magnet_crop_id)) {
        magnetCropArray = msambMaster.magnet_crop_id;
      } else if (typeof msambMaster.magnet_crop_id === "number") {
        magnetCropArray = [msambMaster.magnet_crop_id];
      } else {
        magnetCropArray = [];
      }

      const formattedMagnetCropArray = magnetCropArray.map((crop) =>
        typeof crop === "object" ? crop.id : crop
      );

      if (
        JSON.stringify(formattedMagnetCropArray) !==
        JSON.stringify(msambMaster.magnet_crop_id)
      ) {
        dispatch(
          setInputFieldData({
            fieldName: "magnet_crop_id",
            value: formattedMagnetCropArray,
          })
        );
      }
    }
  }, [msambMaster.magnet_crop_id, props.initialValues]);

  useEffect(() => {
    // Ensure that districts and talukas are fetched only after division and district are set

    if (msambMaster.division_id && msambMaster.district_id) {
      axios
        .get(
          `${apiUrl}api/district-by-division?division_id=${msambMaster.division_id}`,
          getConfigWithToken()
        )
        .then((response) => {
          setDistricts(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
        });
    }
  }, [msambMaster.division_id, msambMaster.district_id]);

  useEffect(() => {
    // Ensure that talukas are fetched only after district is set
    if (msambMaster.district_id) {
      axios
        .get(
          `${apiUrl}api/get-taluka-by-district?district_id=${msambMaster.district_id}`,
          getConfigWithToken()
        )
        .then((response) => {
          setTalukas(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching talukas:", error);
        });
    }
  }, [msambMaster.district_id]);

  useEffect(() => {
    console.log("msamb name", msambMaster.msamb_facility_id);
    console.log("taluka id", msambMaster.taluka_id);
    console.log("district id", msambMaster.district_id);
    console.log("division id", msambMaster.division_id);
    console.log("magnet crop", msambMaster.magnet_crop_id);
  }, [msambMaster.taluka_id, msambMaster.district_id, msambMaster.division_id]);

  return (
    <>
      {/* <Online> */}
        <div className="tab-pane">
          <form class="row g-3">
            {/* Name of the MSAMB Facility */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="MSAMBFacility">
                  Name of the MSAMB Facility
                </label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  {isUpdating ? (
                    // If in update mode, render the msamb_facility_name as read-only
                    <input
                      type="text"
                      value={
                        MsambFacility.find((facility) => facility.id === msambMaster.msamb_facility_id)
                          ?.msamb_facility_name || ''
                      }
                      readOnly
                    />
                  ) : (
                    // If not in edit mode, render the msamb_facility_id as a normal, editable input
                    <Select
                      name="MSAMBFacility"
                      className="customSelect"
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={msambMaster.msamb_facility_id}
                      onChange={handleFieldMSAMBFacility}
                      onFocus={handleResetFocus}
                    >
                      {MsambFacility.map((e) => (
                        <MenuItem value={e.id} key={e.id}>
                          {e.msamb_facility_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
            </div>

            {/* Work Package */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="WorkPackage">Work Package</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="WorkPackage"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={msambMaster.work_package_id}
                    onChange={handleFieldWorkPackage}
                    onFocus={handleResetFocus}
                  >
                    {props.WorkPackage.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.work_package}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Division */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Division">Division</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Division"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={msambMaster.division_id}
                    onChange={handleFieldDivision}
                    onFocus={handleResetFocus}
                  >
                    {props.Division.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.divisions}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* District */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="District">District</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="District"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={msambMaster.district_id}
                    onChange={handleFieldDistrict}
                    onFocus={handleResetFocus}
                  >
                    <MenuItem value="">Select District</MenuItem>
                    {districts.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.district}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Taluka */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Taluka">Taluka</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Taluka"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={msambMaster.taluka_id}
                    onChange={handleFieldTaluka}
                    onFocus={handleResetFocus}
                  >
                    {talukas.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.taluka}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Magnet Crop */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="MAGNET">MAGNET Crops</label>
                <FormControl style={{ width: "45%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="MAGNET"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      Array.isArray(msambMaster.magnet_crop_id)
                        ? msambMaster.magnet_crop_id
                        : [msambMaster.magnet_crop_id]
                    }
                    onChange={handleFieldMAGNETCrop}
                    onFocus={handleResetFocus}
                    multiple
                  >
                    {props.MagnetCrop.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.crop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Area */}
            <div className="primaryTableLabel">
              <label htmlFor="Area">Area</label>
              <input
                type="text"
                name="Area"
                value={msambMaster.area}
                onChange={handleFieldArea}
                // onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>
            {/* Male */}
            <div className="primaryTableLabel">
              <label htmlFor="Area">Male</label>
              <input
                type="number"
                name="Male"
                value={msambMaster.male}
                onChange={handleFieldMale}
                // onFocus={handleResetFocus}
                className="customInput"
                style={{ width: "30%" }}
              />
            </div>
            {/* Female */}
            <div className="primaryTableLabel">
              <label htmlFor="Female">Female</label>
              <input
                type="number"
                name="Female"
                value={msambMaster.female}
                onChange={handleFieldFemale}
                // onFocus={handleResetFocus}
                className="customInput"
                style={{ width: "30%" }}
              />
            </div>
            {/* Shareholders */}
            <div className="primaryTableLabel">
              <label htmlFor="Shareholders">Shareholders</label>
              <input
                type="number"
                name="Shareholders"
                value={msambMaster.shareholders}
                onChange={handleFieldShareHolders}
                // onFocus={handleResetFocus}
                className="customInput"
                style={{ width: "30%" }}
              />
            </div>
          </form>
        </div>
      {/* </Online>
      <Offline>You're Offline, Please Check your Connection</Offline> */}
    </>
  );
};
