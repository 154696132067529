import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the FullTime form
const initialState = {
  // SC
  sc: {
    male: "",
    female: "",
    total: "",
  },
  // ST
  st: {
    male: "",
    female: "",
    total: "",
  },
  // PWD
  pwd: {
    male: "",
    female: "",
    total: "",
  },
  
  // OBC/ Minority/ Open
  obcMinorityOpen: {
    male: "",
    female: "",
    total: "",
  },

  //Full Time Emp
  numFullTimeEmployment: {
    totalMale: "",
    totalFemale: "",
    totalMF: "",
  },
  // Total Employment Generated
  totalEmploymentGenerated: {
    male: "",
    female: "",
    totalTrainees: "",
  },

  validationErrors: {
    sc_male: "",
    sc_female: "",
    st_male: "",
    st_female: "",
    pwd_male: "",
    pwd_female: "",
    obcMinorityOpen_male: "",
    obcMinorityOpen_female: "",
  },
  // Add other input fields' initial values here
};

// Create a slice for FullTimeForm
const fullTimeForm = createSlice({
  name: "fullTimeForm",
  initialState,
  reducers: {
    setInputFieldConcFullEmpData: (state, action) => {
      const { category, field, value } = action.payload;
      state[category][field] = value;
    },
    setTotalSC: (state, action) => {
      state.sc.total = action.payload;
    },
    setTotalFullMaleEmp: (state, action) => {
      state.numFullTimeEmployment.totalMale = action.payload;
    },
    setTotalFullFemaleEmp: (state, action) => {
      state.numFullTimeEmployment.totalFemale = action.payload;
    },
    setTotalFullTotalEmp: (state, action) => {
      state.numFullTimeEmployment.totalMF = action.payload;
    },
    setTotalST: (state, action) => {
      state.st.total = action.payload;
    },
    setTotalPWD: (state, action) => {
      state.pwd.total = action.payload;
    },
    setTotalOBC: (state, action) => {
      state.obcMinorityOpen.total = action.payload;
    },

    setTotalGenMaleEmp: (state, action) => {
      state.totalEmploymentGenerated.male = action.payload;
    },
    setTotalGenFemaleEmp: (state, action) => {
      state.totalEmploymentGenerated.female = action.payload;
    },
    setTotalGenTotalEmp: (state, action) => {
      state.totalEmploymentGenerated.totalTrainees = action.payload;
    },

    resetDataFullConc: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    updateConcFullTime: (state, action) => {
      state.totalEmploymentGenerated.male =
        action.payload.full_time_emp_num_male +
        action.payload.part_time_emp_num_male;

      state.sc.male = action.payload.full_time_emp_num_sc_male;
      state.sc.female = action.payload.full_time_emp_num_sc_female;
      state.sc.total = action.payload.full_time_emp_num_sc_total;
      state.st.male = action.payload.full_time_emp_num_st_male;
      state.st.female = action.payload.full_time_emp_num_st_female;
      state.st.total = action.payload.full_time_emp_num_st_total;
      state.pwd.male = action.payload.full_time_emp_num_pwd_male;
      state.pwd.female = action.payload.full_time_emp_num_pwd_female;
      state.pwd.total = action.payload.full_time_emp_num_pwd_total;
      state.obcMinorityOpen.male =action.payload.full_time_emp_num_obc_minority_open_male;
      state.obcMinorityOpen.female =action.payload.full_time_emp_num_obc_minority_open_female;
      state.obcMinorityOpen.total =action.payload.full_time_emp_num_obc_minority_open_total;
      state.totalEmploymentGenerated.male =action.payload.full_time_emp_num_male +action.payload.part_time_emp_num_male;
      state.totalEmploymentGenerated.female =action.payload.full_time_emp_num_female +action.payload.part_time_emp_num_female;
      state.totalEmploymentGenerated.totalTrainees =action.payload.full_time_emp_num_total +action.payload.part_time_emp_num_total;
    },

    setValidationErrorsEmpConcFull: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state) => {
      // Reset the validation errors to an empty object
      state.validationErrors = {
        sc_male: "",
        sc_female: "",
        st_male: "",
        st_female: "",
        pwd_male: "",
        pwd_female: "",
        obcMinorityOpen_male: "",
        obcMinorityOpen_female: "",
        // Add keys for other fields with the same pattern
      };
    },
    // Add other reducers for different input fields if needed
  },
});

export const {
  resetValidation,
  setValidationErrorsEmpConcFull,
  setInputFieldConcFullEmpData,
  updateConcFullTime,
  setTotalSC,
  setTotalFullMaleEmp,
  setTotalFullFemaleEmp,
  setTotalFullTotalEmp,
  setTotalST,
  setTotalPWD,
  setTotalOBC,
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
  resetDataFullConc,
} = fullTimeForm.actions;
export default fullTimeForm.reducer;
