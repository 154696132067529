import { Offline, Online } from "react-detect-offline";
import { Header } from "../Header";
import { LeftNavigation } from "../LeftNavigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";

export const AddUser = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.state;

  const apiUrl = process.env.REACT_APP_API_URL;
  const [role, setRole] = useState();
  const [divisionHead, setDivisionHead] = useState(false);
  const [userRole, setUserRole] = useState();
  const [division, setDivision] = useState([]);
  const [selectDivision, setSelectedDivision] = useState([]);
  const [editUserFlag, setEditUserFlag] = useState(false);
  const [status, setStatus] = useState();

  const name = useRef(null);
  const number = useRef(null);
  const email = useRef(null);

  const handleRole = (value) => {
    setUserRole(value);
    if (value.value == 2) {
      setDivisionHead(true);
    } else {
      setDivisionHead(false);
    }
  };

  const handleDivision = (value) => {
    setSelectedDivision(value);
  };

  const toggleStatus = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleSaveUser = async () => {
    try {
      if (!editUserFlag) {
        const obj = {
          name: name.current.value,
          mobile: number.current.value,
          email: email.current.value,
          usertype: userRole.label,
          status: status ? "active" : "inactive",
          division_id: selectDivision.map((e) => e.value).join(","),
        };

        const response = await axios.post(
          `${apiUrl}api/user-create`,
          obj,
          getConfigWithToken()
        );

        // Handle the response accordingly

        if (response.data["status"] === true) {
          Swal.fire(`User Created Successfully`, "", "success");
        } else {
          console.log("dskjnkjdsbv");
          Swal.fire(`${response.data["message"]}`, "", "warning");
        }
      } else {
        const obj = {
          id: id,
          name: name.current.value,
          mobile: number.current.value,
          email: email.current.value,
          usertype: userRole.label,
          status:
            status === "active"
              ? "Active"
              : status === "inactive"
              ? "Inactive"
              : "Pending",
          division_id: selectDivision.map((e) => e.value).join(","),
        };

        console.warn("status: ", obj);

        const response = await axios.post(
          `${apiUrl}api/user-update`,
          obj,
          getConfigWithToken()
        );

        // Handle the response accordingly

        if (response.data["status"] === true) {
          Swal.fire(`User Edited Successfully`, "", "success");
        } else {
          console.log("dskjnkjdsbv");
          Swal.fire(`${response.data["message"]}`, "", "warning");
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    async function getData() {
      const divisi = await axios.get(
        `${apiUrl}api/getAllDivisions?all=1`,
        getConfigWithToken()
      );
      const getRole = await axios.get(
        `${apiUrl}api/get-all-roles?all=1`,
        getConfigWithToken()
      );
      const divi = divisi.data["data"].map((e) => ({
        value: e["id"],
        label: e["divisions"],
      }));
      const rol = getRole.data["data"].map((e) => ({
        value: e["id"],
        label: e["name"],
      }));
      console.log(rol, "udsbvs");

      setDivision(divi);
      setRole(rol);
      if (id) {
        setEditUserFlag(true);

        const editData = await axios.get(
          `${apiUrl}api/user-edit?id=${id}`,
          getConfigWithToken()
        );
        console.log("EDIT DATA: ", editData.data["data"][0]?.status);
        const editDta = editData.data["data"][0];
        setUserRole(rol.filter((e) => e.label === editDta.usertype)[0]);
        name.current.value = editDta.name;
        number.current.value = editDta.mobile;
        email.current.value = editDta.email;
        setStatus(editData.data["data"][0]?.status);

        if (editDta.usertype === "PIU") {
          setDivisionHead(true);
        } else {
          setDivisionHead(false);
        }
        if (editDta.division_id) {
          const division_er = editDta.division_id.split(",");
          const rtt = division_er.map((e) => parseInt(e));
          const uju = divi.filter((item) => rtt.includes(item.value));
          setSelectedDivision(uju);
        }
      }
    }
    getData();
  }, []);
  
  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div
              id="exTab3"
              class="contain"
              style={{
                width: "87%",
                position: "absolute",
                right: "0rem",
                top: "3.5rem",
              }}
            >
              <div
                class="card"
                style={{ height: "1000px", backgroundColor: "#F8F8F8" }}
              >
                {/* Add Beneficiary/Back */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "92%",
                    marginLeft: "5rem",
                    marginTop: "1rem",
                    padding: "1rem 4rem",
                    border: "1.5px solid #E8E8E8",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                      {id ? "Edit User" : "Add New User"}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#4e2683",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      <i
                        className="fa-solid fa-arrow-left"
                        style={{
                          marginTop: "0.5rem",
                          marginBottom: "2rem",
                          marginRight: "0.5rem",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      ></i>
                      Back
                    </span>
                    <button
                      style={{
                        backgroundColor: "#4e2683",
                        fontWeight: "bold",
                        color: "white",
                        padding: "5px 25px",
                        marginLeft: "15px",
                      }}
                      onClick={handleSaveUser}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* Listing Table */}
                {/* <UserListing /> */}
                <form
                  class="row g-3"
                  style={{ marginLeft: "7rem", marginTop: "3rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div class="col-7">
                      <label for="inputNanme4" class="form-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        ref={name}
                        class="form-control"
                        id="inputNanme4"
                      />
                    </div>
                  </div>
                  <div>
                    {/* <p>{userRole}</p> */}
                    <div class="col-7">
                      <label for="inputEmail4" class="form-label">
                        Mobile
                      </label>
                      <input
                        type="number"
                        ref={number}
                        class="form-control"
                        id="inputEmail4"
                      />
                    </div>
                    <div class="col-7">
                      <label for="inputPassword4" class="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        ref={email}
                        class="form-control"
                        id="inputPassword4"
                      />
                    </div>
                    <div class="col-7">
                      <label for="inputPassword4" class="form-label">
                        Role
                      </label>
                      <Select
                        options={role}
                        // isMulti
                        value={userRole ? userRole : null}
                        onChange={handleRole}
                      />
                    </div>

                    {divisionHead === true ? (
                      <div class="col-7">
                        <label>Division:</label>
                        <Select
                          options={division}
                          isMulti
                          value={selectDivision}
                          onChange={handleDivision}
                        />
                        <div></div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div class="col-7">
                      <label>Status</label>
                      <Select
                        options={[
                          { value: "active", label: "Active" },
                          { value: "inactive", label: "Inactive" },
                          { value: "pending", label: "Pending" },
                        ]}
                        value={{
                          value: status,
                          label:
                            status === "active"
                              ? "Active"
                              : status === "inactive"
                              ? "Inactive"
                              : "Pending",
                        }}
                        onChange={(selectedOption) =>
                          setStatus(selectedOption.value)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
     
    </>
  );
};
