import { InputField } from "../../InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetValidation,
  setInputFieldConcPartEmpData,
  setTotalSC,
  setTotalFullMaleEmp,
  setTotalFullFemaleEmp,
  setTotalFullTotalEmp,
  setTotalST,
  setTotalPWD,
  setTotalOBC,
} from "../../../redux/slice/InputTwo/EmpGenInputTwo/PartTimeSlice";
import {
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
} from "../../../redux/slice/InputTwo/EmpGenInputTwo/FullTimeSlice";
import "../../../assets/css/inputForm.css";

export function PartTime() {
  const dispatch = useDispatch();
  const [checkSet, setCheck] = useState(false);

  const fullTimeForm = useSelector((state) => state.fullTimeEmp);
  const partTimeForm = useSelector((state) => state.partTimeEmp);
  // Total of SC
  const totalSC =
    parseInt(partTimeForm.sc.male === "" ? 0 : partTimeForm.sc.male) +
    parseInt(partTimeForm.sc.female === "" ? 0 : partTimeForm.sc.female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(partTimeForm.st.male === "" ? 0 : partTimeForm.st.male) +
    parseInt(partTimeForm.st.female === "" ? 0 : partTimeForm.st.female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(partTimeForm.pwd.male === "" ? 0 : partTimeForm.pwd.male) +
    parseInt(partTimeForm.pwd.female === "" ? 0 : partTimeForm.pwd.female);
  dispatch(setTotalPWD(totalPWD));
 
  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      partTimeForm.obcMinorityOpen.male === ""
        ? 0
        : partTimeForm.obcMinorityOpen.male
    ) +
    parseInt(
      partTimeForm.obcMinorityOpen.female === ""
        ? 0
        : partTimeForm.obcMinorityOpen.female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));
  // Total of NumPartTimeEmployment
  const totalFullTimeEmpMale =
    parseInt(partTimeForm.sc.male === "" ? 0 : partTimeForm.sc.male) +
    parseInt(partTimeForm.st.male === "" ? 0 : partTimeForm.st.male) +
    parseInt(
      partTimeForm.obcMinorityOpen.male === ""
        ? 0
        : partTimeForm.obcMinorityOpen.male
    );
  dispatch(setTotalFullMaleEmp(totalFullTimeEmpMale));

  const totalFullTimeEmpFemale =
    parseInt(partTimeForm.sc.female === "" ? 0 : partTimeForm.sc.female) +
    parseInt(partTimeForm.st.female === "" ? 0 : partTimeForm.st.female) +
    parseInt(
      partTimeForm.obcMinorityOpen.female === ""
        ? 0
        : partTimeForm.obcMinorityOpen.female
    );
  dispatch(setTotalFullFemaleEmp(totalFullTimeEmpFemale));

  const totalFullTimeEmpTotal =
    parseInt(partTimeForm.sc.total === "" ? 0 : partTimeForm.sc.total) +
    parseInt(partTimeForm.st.total === "" ? 0 : partTimeForm.st.total) +
    parseInt(
      partTimeForm.obcMinorityOpen.total === ""
        ? 0
        : partTimeForm.obcMinorityOpen.total
    );
  dispatch(setTotalFullTotalEmp(totalFullTimeEmpTotal));

  console.log(
    fullTimeForm.numFullTimeEmployment.totalMale,
    "fullTimeForm.numFullTimeEmployment.totalMale"
  );
  const totalGenEmpMale =
    parseInt(
      fullTimeForm.numFullTimeEmployment.totalMale === ""
        ? 0
        : fullTimeForm.numFullTimeEmployment.totalMale
    ) +
    parseInt(
      partTimeForm.numFullTimeEmployment.totalMale === ""
        ? 0
        : partTimeForm.numFullTimeEmployment.totalMale
    );
  dispatch(setTotalGenMaleEmp(totalGenEmpMale));
  const totalGenEmpFemale =
    parseInt(
      fullTimeForm.numFullTimeEmployment.totalFemale === ""
        ? 0
        : fullTimeForm.numFullTimeEmployment.totalFemale
    ) +
    parseInt(
      partTimeForm.numFullTimeEmployment.totalFemale === ""
        ? 0
        : partTimeForm.numFullTimeEmployment.totalFemale
    );
  dispatch(setTotalGenFemaleEmp(totalGenEmpFemale));
  const totalGenEmpTotal =
    parseInt(
      fullTimeForm.numFullTimeEmployment.totalMF === ""
        ? 0
        : fullTimeForm.numFullTimeEmployment.totalMF
    ) +
    parseInt(
      partTimeForm.numFullTimeEmployment.totalMF === ""
        ? 0
        : partTimeForm.numFullTimeEmployment.totalMF
    );
  dispatch(setTotalGenTotalEmp(totalGenEmpTotal));

  // Dispatch functions for Total Employment Generated fields
  const handleFieldChangeFullMale = (value) => {
    dispatch(
      setInputFieldConcPartEmpData({
        category: "numFullTimeEmployment",
        field: "male",
        value,
      })
    );
  };
  const handleFieldChangeFullFemale = (value) => {
    dispatch(
      setInputFieldConcPartEmpData({
        category: "numFullTimeEmployment",
        field: "female",
        value,
      })
    );
  };
  const handlePositiveNumberChange = (value) => {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      return numericValue;
    } else {
      return ""; // Or you can return 0 or any other default value
    }
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleFieldChange = (category, field, value) => {
    setCheck(true);
    const newValue = handlePositiveNumberChange(value);
    dispatch(setInputFieldConcPartEmpData({ category, field, value: newValue }));
  };

  return (
    <>
      <div class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={partTimeForm.sc.male}
          onChange={(value) => handleFieldChange("sc", "male", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.sc_male}
          helperText={partTimeForm.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={partTimeForm.sc.female}
          onChange={(value) => handleFieldChange("sc", "female", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.sc_female}
          helperText={partTimeForm.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={partTimeForm.sc.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={partTimeForm.st.male}
          onChange={(value) => handleFieldChange("st", "male", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.st_male}
          helperText={partTimeForm.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={partTimeForm.st.female}
          onChange={(value) => handleFieldChange("st", "female", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.st_female}
          helperText={partTimeForm.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={partTimeForm.st.total}
          readOnly={true}
        />
        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={partTimeForm.obcMinorityOpen.male}
          onChange={(value) => handleFieldChange("obcMinorityOpen", "male", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.obcMinorityOpen_male}
          helperText={partTimeForm.validationErrors.obcMinorityOpen_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={partTimeForm.obcMinorityOpen.female}
          onChange={(value) => handleFieldChange("obcMinorityOpen", "female", value)}
          onFocus={handleResetFocus}
          error={!!partTimeForm.validationErrors.obcMinorityOpen_female}
          helperText={partTimeForm.validationErrors.obcMinorityOpen_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={partTimeForm.obcMinorityOpen.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total  Male"
          type="number"
          value={partTimeForm.numFullTimeEmployment.totalMale}
          onChange={handleFieldChangeFullMale}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={partTimeForm.numFullTimeEmployment.totalFemale}
          onChange={handleFieldChangeFullFemale}
        />
        <InputField
          col="3"
          label="Total Full Time"
          type="number"
          value={partTimeForm.numFullTimeEmployment.totalMF}
          readOnly={true}
        />
        <div style={{ marginTop: "3rem", marginLeft: "1px" }} class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={partTimeForm.pwd.male}
            onChange={(value) => handleFieldChange("pwd", "male", value)}
            onFocus={handleResetFocus}
            error={!!partTimeForm.validationErrors.pwd_male}
            helperText={partTimeForm.validationErrors.pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={partTimeForm.pwd.female}
            onChange={(value) => handleFieldChange("pwd", "female", value)}
            onFocus={handleResetFocus}
            error={!!partTimeForm.validationErrors.pwd_female}
            helperText={partTimeForm.validationErrors.pwd_female}
          />

          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={partTimeForm.pwd.total}
            readOnly={true}
          />
        </div>
            </div>
    </>
  );
}
