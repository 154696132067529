
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "8": {
    magnet_crop_id:[],

    location_taluka:'',
    no_of_farmers:'',
    total_project_cost:'',
    matching_grant: '',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',
    training_type_id: "8"
  },
    // Validation
  validationErrors:{  
    magnet_crop_id:[],

    location_taluka:'',
    no_of_farmers:'',
    total_project_cost:'',
    matching_grant: '',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',  
        }
    };

const SupportFruitMasterSlice = createSlice({
  name: 'SupportFruitMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["8"][action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataSupportFruitMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["8"]) {
        if (payload[key] === null) {
          state["8"][key] = '';
        }else{
          state["8"][key] = payload[key];
        }
      }
    },
    resetDataSupportFruitMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsSupportFruitMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldData, updateApiDataSupportFruitMaster, resetDataSupportFruitMaster } = SupportFruitMasterSlice.actions;

export default SupportFruitMasterSlice.reducer;
