import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import { SubDropDown } from "../../subcomponents/SubDropDown";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";



export const OutputTable = ({
  setDivision,
  financialYear,
  currentFinancialYear,
  currentQuarter,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinancialYear, setSelectFinancialYear] = useState("2024-2025");
  const [selectSubFilter, setSelectSubFilter] = useState("Q2");
  const [tableData, setTableData] = useState([]);
  const [grantSummary, setGrantSummary] = useState([]);

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    await fetchData(e.target.value, selectFinancialYear, selectSubFilter);
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    await fetchData(selectDivision, e.target.value, selectSubFilter);
  };

  const handleSubFilter = async (e) => {
    if (selectFinancialYear.length === 0) {
      Swal.fire(`Please Select Financial Year`, "", "warning");
      return;
    }
    setSelectSubFilter(e.target.value);
    await fetchData(selectDivision, selectFinancialYear, e.target.value);
  };

  const fetchData = async (division, year, filter) => {
    try {
      let url = `${apiUrl}api/PFPOverallPerformance?yearly=${year}`;
      if (filter !== "Yearly") {
        const quarr = ["Q1", "Q2", "Q3", "Q4"];
        if (quarr.includes(filter)) {
          url += `&quarter_no=${filter}`;
        } else {
          url += `&half_yearly=${filter}`;
        }
      }
      if (division) {
        url += `&division_id=${division}`;
      }
      const response = await axiosInstance.get(url, getConfigWithToken());
      const data = response.data.data[0];
      const summary = response.data.summary[0];
      const formattedSummary = {
        applicant_name: "Grand Total",
        component: "",
        total_project_cost: summary.total_project_cost || "0",
        promoters_contribution: summary.promoters_contribution || "0",
        term_loan: summary.term_loan || "0",
        matching_grant: summary.matching_grant || "0",
        amount_disbursement: summary.amount_disbursement || "0",
        total_exp_till_date: summary.total_exp_till_date || "0",
        physical_progress_till_date_pct: summary.physical_progress_till_date_pct || "0",
        exp_incurred: summary.exp_incurred || "0",
        progress_by_the_end_of_qtr_pct: summary.progress_by_the_end_of_qtr_pct || "0",
      };
      setGrantSummary(formattedSummary);
      setTableData(data);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        Swal.fire("An error occurred. Please refresh the Page", "", "error");
      } else {
        throw new Error("Data Not Found");
      }
    }
  };

  useEffect(() => {
    async function getData() {
      await fetchData(null, currentFinancialYear, currentQuarter);
      setDivisions(setDivision);
      setLoading(false);
    }
    getData();
  }, [setDivision, financialYear, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: "Component",
        accessor: "component",
      },
      {
        Header: "Total Project Cost (in_Lakhs)",
        accessor: "total_project_cost",
      },
      {
        Header: "Promoters Contribution (in_Lakhs)",
        accessor: "promoters_contribution",
      },
      {
        Header: "Matching Grant (in_Lakhs)",
        accessor: "matching_grant",
      },
      {
        Header: "Term Loan (in_Lakhs)",
        accessor: "term_loan",
      },
      {
        Header: "Total Exp Till Date (in_Lakhs)",
        accessor: "total_exp_till_date",
      },

      {
        Header: "Financial Progress Till Date (%)",
        accessor: "physical_progress_till_date_pct",
      },
      {
        Header: "Expenditure incurred (in Lakh) in this Quarter",
        accessor: "exp_incurred",
      },
      {
        Header: "Financial Progress by the End of Qtr (%)",
        accessor: "progress_by_the_end_of_qtr_pct",
      },

    ],
    []
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; 
  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData, 
    });

  const cellStyle = {
    padding: "8px",
    border: "1px solid #ccc", // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };



  // funtion to handle exporting the data to XL including the GrandSUmmary with the Grant Total name
  const exportToExcel = () => {
    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
    // Map table data and conditionally add the "Division" column
    const formattedData = tableData.map(row => {
      let formattedRow = {
        "Financial Year": selectFinancialYear,
        "Period": selectSubFilter,
      };
  
      if (divisionName !== 'All') {
        formattedRow["Division"] = divisionName;
      }
  
      columns.forEach(column => {
        formattedRow[column.Header] = row[column.accessor];
      });
  
      return formattedRow;
    });
  
    // Add the grant summary row without "Financial Year" and "Period" columns
    let grantSummaryRow = {
      // "Financial Year": selectFinnacialYear,
      // "Period": selectSubFilter,
    };
    // if (divisionName !== 'All') {
    //   grantSummaryRow["Division"] = divisionName;
    // }
    
    columns.forEach(column => {
      grantSummaryRow[column.Header] = grantSummary[column.accessor] || "";
    });
  
    // Include the applicant_name for the grand summary
    grantSummaryRow["Component"] = "Grand Total";
  
    formattedData.push(grantSummaryRow);
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");
  
    const fileName = `${selectFinancialYear}_${selectSubFilter}_${divisionName}_ComponentWise_OverAllPerformance.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };
  
  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  const stickyy = {
    position: "sticky",
    top: "0",
    zIndex: "1",
    background: "var(--stickyBackground)",
    boxShadow: "0 0 6px rgba(0,0,0,0.25)",
  };
  return (
    <>
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-5"></div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinancialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    {/* <MenuItem value="Yearly">Yearly</MenuItem> */}
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1 & Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3 & Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{width:'95%'}}>
                <Button
                  onClick={exportToExcel}
                  style={{ color: "#4e2683", fontWeight: "bold",fontSize:'13.4px' ,height: '53px',width: '155px' ,border:'0.9px solid #4e2683',marginLeft:'-9px' }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto", maxHeight: "445px" }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background:
                        (index === 9 &&
                          grantSummary["progress_by_the_end_of_qtr_pct"] >=
                            150) ||
                        (index === 7 &&
                          grantSummary["physical_progress_till_date_pct"] >=
                            150)
                          ? "red"
                          : "#f2f2f2", // Add grey background color
                      fontWeight: "bold", // Optionally make text bold
                      border: "2px solid #ccc",
                      ...cellStyle, // Add border style here
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle, // Add border style here
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {Object.keys(grantSummary).length > 0 && (
            <tfoot>
              <tr>
                <td
                  style={{
                    border: "2px solid #ccc",
                    fontWeight: "bold",
                    ...cellStyle,
                  }}
                >
                  {grantSummary.applicant_name}
                </td>
                {columns.slice(1).map((column) => (
                  <td
                    key={column.id}
                    style={{
                      border: "2px solid #ccc",
                      fontWeight: "bold",
                      ...cellStyle,
                    }}
                  >
                    {grantSummary[column.accessor] || ""}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    
    </>
  );
};
