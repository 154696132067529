import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInputFieldData } from "../../../redux/slice/InputTwoImpactForms/BeneficiaryLoss/BeneficiaryLossSlice";
import { InputField } from "../../InputField";
import { MultiSelect } from "../../MultiSelect";

export function BeneficiariesLoss(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const beneficiaryLossMaster = useSelector(
    (state) => state.beneficiaryLossMaster
  );
  console.log(beneficiaryLossMaster.magnet_crop_id[0], "slicesssss");
  const {
    validationErrors,
    losses_percentage,
    total_estimated_losses,
    estimated_losses_frozen_storage,
    estimated_losses_cold_storage,
    estimated_losses_reefer_vans,
    tuc_cold_storage,
    estimated_losses_pack_house,
    tuc_reefer_vans,
    tuc_frozen_storage,
    magnet_crop_id,
    tuc_pack_house,
  } = useSelector((state) => state.beneficiaryLossMaster);

  const updateTotalEstLosses = (
    estimated_losses_pack_house,
    estimated_losses_cold_storage,
    estimated_losses_reefer_vans,
    estimated_losses_frozen_storage
  ) => {
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      parseFloatOrZero(estimated_losses_pack_house) +
      parseFloatOrZero(estimated_losses_cold_storage) +
      parseFloatOrZero(estimated_losses_reefer_vans) +
      parseFloatOrZero(estimated_losses_frozen_storage);
    const roundedValue = newValue.toFixed(2);
    dispatch(
      setInputFieldData({
        fieldName: "total_estimated_losses",
        value: roundedValue,
      })
    );
    updateLossPercentage(
      tuc_pack_house,
      tuc_cold_storage,
      tuc_reefer_vans,
      tuc_frozen_storage,
      roundedValue
    );
  };

  const updateLossPercentage = (
    tuc_pack_house,
    tuc_cold_storage,
    tuc_reefer_vans,
    tuc_frozen_storage,
    total_estimated_losses
  ) => {
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const totalUtilizedCapacity =
      parseFloatOrZero(tuc_pack_house) +
      parseFloatOrZero(tuc_cold_storage) +
      parseFloatOrZero(tuc_reefer_vans) +
      parseFloatOrZero(tuc_frozen_storage);
    const newValue =
      totalUtilizedCapacity === 0
        ? 0
        : (parseFloatOrZero(total_estimated_losses) / totalUtilizedCapacity) *
          100;
    const roundedValue = newValue.toFixed(2);
    dispatch(
      setInputFieldData({
        fieldName: "losses_percentage",
        value: roundedValue,
      })
    );
  };

  const handleMultiMagnetCropChange = (data) => {
    dispatch(setInputFieldData({ fieldName: "magnet_crop_id", value: data }));
  };

  const handleTUCPackHouse = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "tuc_pack_house", value }));
      updateLossPercentage(
        newValue,
        tuc_cold_storage,
        tuc_reefer_vans,
        tuc_frozen_storage,
        total_estimated_losses
      );
    }
  };

  const handleTUCColdStorage = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "tuc_cold_storage", value }));
      updateLossPercentage(
        tuc_pack_house,
        newValue,
        tuc_reefer_vans,
        tuc_frozen_storage,
        total_estimated_losses
      );
    }
  };

  const handleTUCReeferVan = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "tuc_reefer_vans", value }));
      updateLossPercentage(
        tuc_pack_house,
        tuc_cold_storage,
        newValue,
        tuc_frozen_storage,
        total_estimated_losses
      );
    }
  };

  const handleTUCFrozenStorage = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "tuc_frozen_storage", value }));
      updateLossPercentage(
        tuc_pack_house,
        tuc_cold_storage,
        tuc_reefer_vans,
        newValue,
        total_estimated_losses
      );
    }
  };

  const handleELPackHouse = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({ fieldName: "estimated_losses_pack_house", value })
      );
      updateTotalEstLosses(
        newValue,
        estimated_losses_cold_storage,
        estimated_losses_reefer_vans,
        estimated_losses_frozen_storage
      );
    }
  };

  const handleELColdStorage = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({ fieldName: "estimated_losses_cold_storage", value })
      );
      updateTotalEstLosses(
        estimated_losses_pack_house,
        newValue,
        estimated_losses_reefer_vans,
        estimated_losses_frozen_storage
      );
    }
  };

  const handleELFrozenStorage = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "estimated_losses_frozen_storage",
          value,
        })
      );
      updateTotalEstLosses(
        estimated_losses_pack_house,
        estimated_losses_cold_storage,
        estimated_losses_reefer_vans,
        newValue
      );
    }
  };

  const handleELReeferVan = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "estimated_losses_reefer_vans",
          value,
        })
      );
      updateTotalEstLosses(
        estimated_losses_pack_house,
        estimated_losses_cold_storage,
        newValue,
        estimated_losses_frozen_storage
      );
    }
  };

  
  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <MultiSelect
            label="Target Crops"
            data={props.magentCrop}
            errorText={
              validationErrors.magnet_crop_id.length !== 0 ? true : false
            }
            // value={beneficiaryLossMaster.magnet_crop_id}
            value={beneficiaryLossMaster.magnet_crop_id}
            onChange={handleMultiMagnetCropChange}
          />
        </div>
        <InputField
          label="Total utilized capacity of Pack House"
          type="number"
          onChange={handleTUCPackHouse}
          value={tuc_pack_house ? tuc_pack_house.toString() : ""}
          error={!!validationErrors.tuc_pack_house}
          helperText={validationErrors.tuc_pack_house}
        />
        <InputField
          label="Total utilized capacity of cold storage"
          type="number"
          onChange={handleTUCColdStorage}
          value={tuc_cold_storage ? tuc_cold_storage.toString() : ""}
          error={!!validationErrors.tuc_cold_storage}
          helperText={validationErrors.tuc_cold_storage}
        />
        <InputField
          label="Total utilized capacity of reefer vans"
          type="number"
          onChange={handleTUCReeferVan}
          value={tuc_reefer_vans ? tuc_reefer_vans.toString() : ""}
          error={!!validationErrors.tuc_reefer_vans}
          helperText={validationErrors.tuc_reefer_vans}
        />
        <InputField
          label="Total utilized capacity of frozen storage"
          type="number"
          onChange={handleTUCFrozenStorage}
          value={tuc_frozen_storage ? tuc_frozen_storage.toString() : ""}
          error={!!validationErrors.tuc_frozen_storage}
          helperText={validationErrors.tuc_frozen_storage}
        />
        <InputField
          label="Estimated Losses of Pack house"
          type="number"
          onChange={handleELPackHouse}
          value={
            estimated_losses_pack_house
              ? estimated_losses_pack_house.toString()
              : ""
          }
          error={!!validationErrors.estimated_losses_pack_house}
          helperText={validationErrors.estimated_losses_pack_house}
        />
        <InputField
          label="Estimated Losses of cold storage"
          type="number"
          onChange={handleELColdStorage}
          value={
            estimated_losses_cold_storage
              ? estimated_losses_cold_storage.toString()
              : ""
          }
          error={!!validationErrors.estimated_losses_cold_storage}
          helperText={validationErrors.estimated_losses_cold_storage}
        />
        <InputField
          label="Estimated Losses of reefer vans"
          type="number"
          onChange={handleELReeferVan}
          value={
            estimated_losses_reefer_vans
              ? estimated_losses_reefer_vans.toString()
              : ""
          }
          error={!!validationErrors.estimated_losses_reefer_vans}
          helperText={validationErrors.estimated_losses_reefer_vans}
        />
        <InputField
          label="Estimated Losses of frozen storage"
          type="number"
          onChange={handleELFrozenStorage}
          value={
            estimated_losses_frozen_storage
              ? estimated_losses_frozen_storage.toString()
              : ""
          }
          error={!!validationErrors.estimated_losses_frozen_storage}
          helperText={validationErrors.estimated_losses_frozen_storage}
        />

        {/* Calculated fields */}
        <InputField
          label="Total estimated losses"
          type="number"
          value={
            total_estimated_losses ? total_estimated_losses.toString() : ""
          }
        />
        <InputField
          label="Losses"
          type="number"
          endAr="(%)"
          value={losses_percentage ? losses_percentage.toString() : ""}
        />
      </div>
    </div>
  );
}
