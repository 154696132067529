import { InputField } from "../../InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldConcFullEmpData,
  setTotalSC,
  setTotalFullMaleEmp,
  setTotalFullFemaleEmp,
  setTotalFullTotalEmp,
  setTotalST,
  setTotalPWD,
  resetValidation,
  setTotalOBC,
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
} from "../../../redux/slice/InputTwo/EmpGenInputTwo/FullTimeSlice";
import "../../../assets/css/inputForm.css";

export function FullTime() {
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const fullTimeForm = useSelector((state) => state.fullTimeEmp);
  const partTimeForm = useSelector((state) => state.partTimeEmp);

  // Total of SC
  const totalSC =
    parseInt(fullTimeForm.sc.male === "" ? 0 : fullTimeForm.sc.male) +
    parseInt(fullTimeForm.sc.female === "" ? 0 : fullTimeForm.sc.female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(fullTimeForm.st.male === "" ? 0 : fullTimeForm.st.male) +
    parseInt(fullTimeForm.st.female === "" ? 0 : fullTimeForm.st.female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(fullTimeForm.pwd.male === "" ? 0 : fullTimeForm.pwd.male) +
    parseInt(fullTimeForm.pwd.female === "" ? 0 : fullTimeForm.pwd.female);
  dispatch(setTotalPWD(totalPWD));
 
  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      fullTimeForm.obcMinorityOpen.male === ""
        ? 0
        : fullTimeForm.obcMinorityOpen.male
    ) +
    parseInt(
      fullTimeForm.obcMinorityOpen.female === ""
        ? 0
        : fullTimeForm.obcMinorityOpen.female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));
  // Total of NumFullTimeEmployment

  const totalFullTimeEmpMale =
    parseInt(fullTimeForm.sc.male === "" ? 0 : fullTimeForm.sc.male) +
    parseInt(fullTimeForm.st.male === "" ? 0 : fullTimeForm.st.male) +
    parseInt(
      fullTimeForm.obcMinorityOpen.male === ""
        ? 0
        : fullTimeForm.obcMinorityOpen.male
    );
  dispatch(setTotalFullMaleEmp(totalFullTimeEmpMale));

  const totalFullTimeEmpFemale =
    parseInt(fullTimeForm.sc.female === "" ? 0 : fullTimeForm.sc.female) +
    parseInt(fullTimeForm.st.female === "" ? 0 : fullTimeForm.st.female) +
    parseInt(
      fullTimeForm.obcMinorityOpen.female === ""
        ? 0
        : fullTimeForm.obcMinorityOpen.female
    );
  dispatch(setTotalFullFemaleEmp(totalFullTimeEmpFemale));

  const totalFullTimeEmpTotal =
    parseInt(fullTimeForm.sc.total === "" ? 0 : fullTimeForm.sc.total) +
    parseInt(fullTimeForm.st.total === "" ? 0 : fullTimeForm.st.total) +
    parseInt(
      fullTimeForm.obcMinorityOpen.total === ""
        ? 0
        : fullTimeForm.obcMinorityOpen.total
    );
  dispatch(setTotalFullTotalEmp(totalFullTimeEmpTotal));

  if (check) {
    const totalGenEmpMale =
      parseInt(
        fullTimeForm.numFullTimeEmployment.totalMale === ""
          ? 0
          : fullTimeForm.numFullTimeEmployment.totalMale
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalMale === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalMale
      );
    dispatch(setTotalGenMaleEmp(totalGenEmpMale));
    const totalGenEmpFemale =
      parseInt(
        fullTimeForm.numFullTimeEmployment.totalFemale === ""
          ? 0
          : fullTimeForm.numFullTimeEmployment.totalFemale
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalFemale === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalFemale
      );
    dispatch(setTotalGenFemaleEmp(totalGenEmpFemale));
    const totalGenEmpTotal =
      parseInt(
        fullTimeForm.numFullTimeEmployment.totalMF === ""
          ? 0
          : fullTimeForm.numFullTimeEmployment.totalMF
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalMF === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalMF
      );
    dispatch(setTotalGenTotalEmp(totalGenEmpTotal));
  }

  // Dispatch functions for Total Employment Generated fields
  const handleFieldChangeFullMale = (value) => {
    setCheck(true);
    dispatch(
      setInputFieldConcFullEmpData({
        category: "numFullTimeEmployment",
        field: "male",
        value,
      })
    );
  };
  const handleFieldChangeFullFemale = (value) => {
    setCheck(true);
    dispatch(
      setInputFieldConcFullEmpData({
        category: "numFullTimeEmployment",
        field: "female",
        value,
      })
    );
  };

  const handlePositiveNumberChange = (value) => {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      return numericValue;
    } else {
      return ""; // Or you can return 0 or any other default value
    }
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleFieldChange = (category, field, value) => {
    setCheck(true);
    const newValue = handlePositiveNumberChange(value);
    dispatch(setInputFieldConcFullEmpData({ category, field, value: newValue }));
  };

  return (
    <>
      <div class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeForm.sc.male}
          onChange={(value) => handleFieldChange("sc", "male", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.sc_male}
          helperText={fullTimeForm.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeForm.sc.female}
          onChange={(value) => handleFieldChange("sc", "female", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.sc_female}
          helperText={fullTimeForm.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={fullTimeForm.sc.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeForm.st.male}
          onChange={(value) => handleFieldChange("st", "male", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.st_male}
          helperText={fullTimeForm.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeForm.st.female}
          onChange={(value) => handleFieldChange("st", "female", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.st_female}
          helperText={fullTimeForm.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={fullTimeForm.st.total}
          readOnly={true}
        />

      
        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeForm.obcMinorityOpen.male}
          onChange={(value) =>
            handleFieldChange("obcMinorityOpen", "male", value)
          }
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.obcMinorityOpen_male}
          helperText={fullTimeForm.validationErrors.obcMinorityOpen_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeForm.obcMinorityOpen.female}
          onChange={(value) =>
            handleFieldChange("obcMinorityOpen", "female", value)
          }
          onFocus={handleResetFocus}
          error={!!fullTimeForm.validationErrors.obcMinorityOpen_female}
          helperText={fullTimeForm.validationErrors.obcMinorityOpen_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={fullTimeForm.obcMinorityOpen.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total  Male"
          type="number"
          value={fullTimeForm.numFullTimeEmployment.totalMale}
          onChange={handleFieldChangeFullMale}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={fullTimeForm.numFullTimeEmployment.totalFemale}
          onChange={handleFieldChangeFullFemale}
        />
        <InputField
          col="3"
          label="Total Full Time"
          type="number"
          value={fullTimeForm.numFullTimeEmployment.totalMF}
          readOnly={true}
        />

        <div style={{ marginTop: "3rem", marginLeft: "1px" }} class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={fullTimeForm.pwd.male}
            onChange={(value) => handleFieldChange("pwd", "male", value)}
            onFocus={handleResetFocus}
            error={!!fullTimeForm.validationErrors.pwd_male}
            helperText={fullTimeForm.validationErrors.pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={fullTimeForm.pwd.female}
            onChange={(value) => handleFieldChange("pwd", "female", value)}
            onFocus={handleResetFocus}
            error={!!fullTimeForm.validationErrors.pwd_female}
            helperText={fullTimeForm.validationErrors.pwd_female}
          />
          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={fullTimeForm.pwd.total}
            readOnly={true}
          />
        </div>

           </div>
    </>
  );
}
