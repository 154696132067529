import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldDataCertification} from "../../../../redux/slice/EntityMaster/CertificationCostMasterSlice";
import { useDispatch, useSelector } from "react-redux";

export const EntityCertificationCostGAP = (props) =>{ 

    const certificationCostMaster = useSelector(state => state.certificationCostMaster["7"]);
    const dispatch = useDispatch();

    const handleFieldMAGNETCrop = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'magnet_crop_id', value:event.target.value }));
    }
    const handleFieldLocationTaluka = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'location_taluka', value:event.target.value }));
    }
    const handleFieldAreaPlantation = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'area', value:event.target.value }));
    }
    const handleFieldFarmersCertificates = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'no_of_farmers', value:event.target.value }));
    }
    const handleFieldCertificationAgency = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'name_of_certification_agency_lab', value:event.target.value }));
    }
    const handleFieldCertificationCost = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'total_project_cost', value:event.target.value }));
    }
    const handleFieldSanctionGrant = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'matching_grant', value:event.target.value }));
    }
    const handleFieldDisbursementAmount = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'grant_disbursement_amt', value:event.target.value }));
    }
    const handleFieldDisbursementDate = (event) => {
        dispatch(setInputFieldDataCertification({ fieldName: 'grant_disbursement_date', value:event.target.value }));
    }
    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
  
    return(<>   
        {/* <Online> */}
        <div className='tab-pane'>
                <form class="row g-3" id='formid'>
                       <div></div>
                    {/* Magnet_crops */}
                    <div className='primaryTableLabel'>
                    <Box>
                        <label htmlFor="MAGNET" style={{width:"310px"}}>MAGNET Crops</label>
                        <FormControl style={{width:'60%'}} >
                        <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="MAGNET" 
                             className="customSelect" 
                             style={{width:"75%" }}
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={certificationCostMaster.magnet_crop_id}
                             onChange={handleFieldMAGNETCrop}   
                             onFocus={handleResetFocus}      
                             multiple
                                                          
                            >
                        {(props.Magnetcrop).map((e)=>(       
                            <MenuItem value={e.id} key={e.id}>{e.crop}</MenuItem>
                        ))} 
                           
                        </Select>
                        </FormControl>
                    </Box>
                    </div>
    
                {/* Location (Taluka) */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="LocationTaluka" style={{width:"310px"}}>Location (Taluka)</label>
                    <FormControl style={{width:'60%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="LocationTaluka" 
                        className="customSelect" 
                        style={{width:"75%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={certificationCostMaster.location_taluka}
                        onChange={handleFieldLocationTaluka} 
                        onFocus={handleResetFocus}                                         
                    >
                     {(props.taluka).map((e)=>(       
                             <MenuItem value={e.id} key={e.id}>{e.taluka}</MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div> 
            
                    {/* Nos. of Farmers availing Certificates * */}
                    <div className='primaryTableLabel' >
                        <label htmlFor="availingCertificates " style={{width:"310px"}}>Nos. of Farmers availing Certificates</label>
                        <input type="number" name="availingCertificates " 
                            value={certificationCostMaster.no_of_farmers}
                            onChange={handleFieldFarmersCertificates} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}  
                            
                        />
                    </div>
                                                                                      
                    {/* Area under Certification * */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Area " style={{width:"310px"}}>Area under Certification</label>
                        <input type="number" name="Area " 
                            value={certificationCostMaster.area}
                            onChange={handleFieldAreaPlantation} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}  
                        />
                    </div>
                                                                                         
                    {/* Name of Certification Agency */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Agency " style={{width:"310px"}}>Name of Certification Agency</label>
                        <input type="text" name="Agency " 
                            value={certificationCostMaster.name_of_certification_agency_lab}
                            onChange={handleFieldCertificationAgency} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}  
                        />
                    </div>
                                                         
                    {/* Total Cost of Certification */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="CertificationCost " style={{width:"310px"}}>Total Cost of Certification</label>
                        <input type="number" name="CertificationCost " 
                            value={certificationCostMaster.total_project_cost}
                            onChange={handleFieldCertificationCost} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                                                                                                                   
                    {/* Total Sanction Grant from MAGNET */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="sanctionGrant " style={{width:"310px"}}>Total Sanction Grant from MAGNET</label>
                        <input type="number" name="sanctionGrant " 
                            value={certificationCostMaster.matching_grant}
                            onChange={handleFieldSanctionGrant} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    {/* Grant Disbursement Amount */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Amount " style={{width:"310px"}}>Grant Disbursement Amount</label>
                        <input type="number" name="Amount " 
                            value={certificationCostMaster.grant_disbursement_amt}
                            onChange={handleFieldDisbursementAmount} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                    />
                    </div>
                    
                    {/* Grant Disbursement Date */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Date " style={{width:"310px"}}>Grant Disbursement Date</label>
                        <input type="date" name="Date " 
                            value={certificationCostMaster.grant_disbursement_date}
                            onChange={handleFieldDisbursementDate} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                    />
                    </div>
               </form>         
            </div>   
        {/* </Online>
        <Offline>
            You're Offline, Please Check your Connection
        </Offline> */}
        </>)
    }
    
