import React ,{ useState }from 'react';
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import {  Tab, Tabs, Box, Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, colors } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import clsx from 'clsx';
import "../../../../assets/css/masterdata.css";
import { getConfigWithToken } from '../../../../utils/Config/Config';
import axios from 'axios';
import { setInputFieldData } from '../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice';
import { resetDataCertificationCostMaster, updateApiDataCertificationCostMaster } from '../../../../redux/slice/EntityMaster/CertificationCostMasterSlice'
import { resetDataMRLMaster, updateApiDataMRLMaster } from '../../../../redux/slice/EntityMaster/MRLTestingMasterSlice'
import { resetDataSupportFruitMaster, updateApiDataSupportFruitMaster } from '../../../../redux/slice/EntityMaster/SupportFruitMasterSlice'
import { resetDataEntityMaster, updateApiDataEntityMaster } from '../../../../redux/slice/EntityMaster/EntityMasterSlice'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { EntityDetailsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityDetails";
import { EntityCertificationCostGAP } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/CertiCostGAP";
import { EntityMRLTesting } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/MRLTestingEntity";
import { EntitySupportFruitCareActivities } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/FruitCareAct";
import { useLocation } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const AddGAPMaster = () =>{
  const location = useLocation()
  const id = location.state

  const dd = useSelector(setInputFieldData);
    const dispatch = useDispatch();
    const [value, setValue] = useState('0');
    const [checkedPanels, setCheckedPanels] = useState(Array(3).fill(false));
    const [newCheckValue, setNewCheckValue] = useState([])
    const [prevCheckValue, setPrevCheckValue] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL;
    const [Applicant, setApplicant] = useState([])
    const [editEntityFlag, setEditEntityFlag] = useState(false)
    const [Division, setDivision] = useState([])
    const [MagnetCrop, setMagentCrop] = useState([])
    const [BeneficiaryType, setBeneficiaryType] = useState([])
    const [taluka, setTaluka] = useState([])
    const [LoanType, setLoanType] = useState([])
    const [handleBioWateData, setHandleBioWateData] = useState({})
   
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const entityChangeFunction = async (id) => {
      const panelNamesTron = {
        "Certification cost on GAP": "7",
        "MRL Testing": "6",
        "Support for fruit care activities": "8",
      }
        const editData = await axios.get(`${apiUrl}api/input-one-primary-data-edit-training-type?beneficiary_id=${id}`, getConfigWithToken())
        editData.data["flag"] ? setEditEntityFlag(true) : setEditEntityFlag(false)
        // Function to check if a key exists in the data object
        if(editData.data.status === true){
          const keyExists = (data, key) => Object.prototype.hasOwnProperty.call(data, key);
          // Extracting the objects with keys 6, 7, and 8 if they exist
          let trainingType6 = keyExists(editData.data.data[0], '6') ? editData.data.data[0]['6'] : null;
          let trainingType7 = keyExists(editData.data.data[0], '7') ? editData.data.data[0]['7'] : null;
          let trainingType8 = keyExists(editData.data.data[0], '8') ? editData.data.data[0]['8'] : null;

          // Creating a new object with the key 'training_type' and placing the extracted objects inside it
          let newData = {
                  ...editData.data.data[0],
                  training_type: {
                      ...(trainingType6 && { '6': trainingType6 }),
                      ...(trainingType7 && { '7': trainingType7 }),
                      ...(trainingType8 && { '8': trainingType8 })
                  }
              }

          const { training_type, ...newObj } = newData
          console.log(newObj,"ssssssssssssssssssssssssssssssssssssss")
          dispatch(updateApiDataEntityMaster(newObj))
          const editDta = newData["training_type"]

          function updateMagnetCropId(obj) {
            const processValue = (value) => {
                if (Array.isArray(value)) {
                    return value.map(item => processValue(item));
                } else if (typeof value === 'object' && value !== null) {
                    for (const key in value) {
                        if (value.hasOwnProperty(key)) {
                            value[key] = processValue(value[key]);
                        }
                    }
                    if ('magnet_crop_id' in value) {
                        try {
                            const magnetCropIdArray = JSON.parse(value.magnet_crop_id);
                            if (Array.isArray(magnetCropIdArray)) {
                                const ids = magnetCropIdArray.map(item => item.id);
                                value.magnet_crop_id = ids;
                            } else {
                                value.magnet_crop_id = [];
                            }
                        } catch (error) {
                            // If parsing as JSON fails or if it's an empty object, treat it as an empty array
                            value.magnet_crop_id = [];
                        }
                    }
                }
                return value;
            };
        
            return processValue(obj);
        }
        
        // Iterate through each object and update magnet_crop_id
        for (const key in editDta) {
            if (editDta.hasOwnProperty(key)) {
                editDta[key] = updateMagnetCropId(editDta[key]);
            }
        }
        
        console.log(editDta,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")

        const panelNames = [
          "Certification cost on GAP",
          "MRL Testing",
          "Support for fruit care activities",
      ];
      
      const eee = Object.keys(editDta)
      console.log(eee, "dcdsddssdcsddddd99999999999999999")
      setPrevCheckValue(eee)
      const keysFromPanelNamesTron = eee.map((value) => Object.keys(panelNamesTron).find((key) => panelNamesTron[key] === value));

      
      const indices = keysFromPanelNamesTron.map((key) => panelNames.indexOf(key));
      
      const updatedCheckedPanels = checkedPanels.map((value, index) => indices.includes(index) ? true : false);
      setCheckedPanels(updatedCheckedPanels);
          const certification = editDta["7"]
          const mrlTesting = editDta["6"]
          const fruitCare = editDta["8"]

          const updateAllMasters = () => {
            return async (dispatch) => {
              if (certification) {
                dispatch(updateApiDataCertificationCostMaster(certification));
              }
          
              if (mrlTesting) {
                dispatch(updateApiDataMRLMaster(mrlTesting));
              }
          
              if (fruitCare) {
                dispatch(updateApiDataSupportFruitMaster(fruitCare));
              }
            };
          };
          
          // Check if any of the objects are present before dispatching
          if (certification || mrlTesting || fruitCare) {
            // Dispatch the combined action using dispatch(updateAllMasters());
            dispatch(updateAllMasters());
          }else{
            const unlisten = () => {
              dispatch(resetDataCertificationCostMaster());
              dispatch(resetDataMRLMaster());
              dispatch(resetDataSupportFruitMaster());
              dispatch(resetDataEntityMaster());
              // Add more dispatches for other reset actions if needed
            }
            unlisten();
          }
          
          console.log(dd["payload"],"kjds nkjcndsjvndskjn")
      }
    }
  
    function getFormIdFromName(value){
      const panelNamesTron = {
        "Certification cost on GAP": "7",
        "MRL Testing": "6",
        "Support for fruit care activities": "8",
      }

      return panelNamesTron[value]
    }

    const handleCheckboxChange = (panelIndex) => {
        const panelName = panelNames[panelIndex];
        const idForm = getFormIdFromName(panelName)
        setNewCheckValue((prevArray) => [...prevArray, idForm])
        if (checkedPanels[panelIndex]) {
          // If the checkbox is currently unchecked, show the confirmation alert
          MySwal.fire({
            title: 'Are you sure?',
            text: `Do you want to Remove the ${panelName}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Remove it!',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              // User confirmed, update the checkbox state
              updateCheckbox(panelIndex);
            }
          });
        } else {
          // If the checkbox is currently checked, update the state directly
          updateCheckbox(panelIndex);
        }
      };
    
      const updateCheckbox = (panelIndex) => {
        // console.log(panelIndex,"PanelIndex")
        // console.log(checkedPanels,"checkedPanelsPanelIndex")
        setCheckedPanels((prev) => {
          const updatedCheckedPanels = [...prev];
          updatedCheckedPanels[panelIndex] = !prev[panelIndex];
          return updatedCheckedPanels;
        });
    
        // Close the SweetAlert (if it is open)
        MySwal.close();
      };

    function getJSONData (value) {
      console.log(value,"jdsbfjdsbijf")
      const jsonMagnetCrop = value.map(id => {
        const matchingObject = MagnetCrop.find(item => item.id === Number(id));
        if (matchingObject) {
          return {
            id: matchingObject.id,
            name: matchingObject.crop,
          };
        }
        return null; // Handle cases where there's no matching ID
      }).filter(item => item !== null); 
      return jsonMagnetCrop
    }

    
    
    const handleSaveChange = async (e) => {
      e.preventDefault()
      console.log(prevCheckValue, "dcdsddssdcsddddd99999999999999999")
      console.log(newCheckValue,"fdsknvkjfv")
      const panelNamesTron = {
        "Certification cost on GAP": "7",
        "MRL Testing": "6",
        "Support for fruit care activities": "8",
      }

      console.log(dd["payload"]["entityMaster"])
      const obj = {
        // ...dd["payload"]["entityMaster"],
        ...dd["payload"]["certificationCostMaster"],
        ...dd["payload"]["mrlTestingMaster"],
        ...dd["payload"]["supportFruitMaster"],
      }
      const selectedTabs = panelNames.filter((name, index) => checkedPanels[index]);
      console.log(selectedTabs,"hfyufyufyuf")

      const objid = selectedTabs.map((e)=>(panelNamesTron[e]))

      const filteredData = {};

      objid.forEach(key => {
        if (obj[key]) {
          filteredData[key] = obj[key];
        }
      });
      // getRequiredResult(selectedTabs, obj)
      console.log(objid,"kkkkkkkkkkkkkkkkkkkkkkk")
      if (filteredData["7"] && Array.isArray(filteredData["7"]["typedata"]) && filteredData["7"]["typedata"].length > 0) {
        filteredData["7"] = filteredData["7"]["typedata"][0];
      }
      if (filteredData["6"] && Array.isArray(filteredData["6"]["typedata"]) && filteredData["6"]["typedata"].length > 0) {
        filteredData["6"] = filteredData["6"]["typedata"][0];
      }
      if (filteredData["8"] && Array.isArray(filteredData["8"]["typedata"]) && filteredData["8"]["typedata"].length > 0) {
        filteredData["8"] = filteredData["8"]["typedata"][0];
      }
      
  
      function replaceMagnetCropIdValues(data, getJSONDataFn) {
        const newData = {};
    
        const processObject = (obj) => {
            const newObj = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                        newObj[key] = processObject(obj[key]);
                    } else if (key === "magnet_crop_id") {
                        const newValues = getJSONDataFn(obj[key]);
                        newObj[key] = newValues;
                    } else {
                        newObj[key] = obj[key];
                    }
                }
            }
            return newObj;
        };
    
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                newData[key] = processObject(data[key]);
            }
        }
    
        return newData;
    }
    // Call the function to replace values using getJSONData
    const sssss = replaceMagnetCropIdValues(filteredData, getJSONData);
    console.log(sssss,"ssssssssssssssssssssssssssss")

    const ddd = {
      ...dd["payload"]["entityMaster"],
      training_type: {
        ...sssss
      }
    }
    const payload = {
      ...dd["payload"]["entityMaster"],
      training_type: {
        ...sssss
      }
    }
    console.log(payload,"idsoidsjojdsoinvoidsnoi")

    const updatedCode = {};
    const new_entry = {};

    // Extract data for firstArr
    prevCheckValue.forEach(key => {
      if (ddd.training_type[key]) {
        updatedCode[key] = ddd.training_type[key];
        delete ddd.training_type[key];
      }
    });

    // Extract data for lastArr
    newCheckValue.forEach(key => {
      if (ddd.training_type[key]) {
        new_entry[key] = ddd.training_type[key];
        delete ddd.training_type[key];
      }
    });

    const newUpdatedData = {
      ...dd["payload"]["entityMaster"],
      training_type: {
        ...updatedCode,
      },
      new_entry: new_entry
    }

    console.log(newUpdatedData,"asfsadfdsfdf")
    
    if(!editEntityFlag){
      const entityCreate = await axios.post(`${apiUrl}api/input-one-primary-data-create-training-type`,payload ,getConfigWithToken())
    
      if(entityCreate.data["status"] === true){
          Swal.fire(
              `${entityCreate.data["message"]}`,
              '',
              'success'
          )
      }else{
          Swal.fire(
              `${entityCreate.data["message"]}`,
              '',
              'warning'
          )
      }
    }else{
      const entityUpdate = await axios.post(`${apiUrl}api/input-one-primary-data-update-training-type`,newUpdatedData ,getConfigWithToken())
    
      if(entityUpdate.data["status"] === true){
          Swal.fire(
              `${entityUpdate.data["message"]}`,
              '',
              'success'
          )
      }else{
          Swal.fire(
              `${entityUpdate.data["message"]}`,
              '',
              'warning'
          )
      }
    }
    }

    const panelNames = [
        "Certification cost on GAP",
        "MRL Testing",
        "Support for fruit care activities",
      ];
    
      const visiblePanelNames = panelNames.filter((_, index) => checkedPanels[index]);
      
      const renderTable = (items) => (
        <TableContainer component={Paper} style={{ margin: '16px 5rem 0rem 5rem', backgroundColor: '#E2E2E2', border: '2px solid #9C9C9C' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'black', fontSize: '18px', fontWeight: '700px', border: '1px solid #9C9C9C' }}>Forms</TableCell>
                <TableCell style={{ color: 'black', fontSize: '18px', fontWeight: '700px', border: '1px solid #9C9C9C' }}>Applicability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((panelName, panelIndex) => (
                <TableRow key={panelIndex}>
                  <TableCell style={{ border: '1px solid #9C9C9C' }}>{panelName}</TableCell>
                  <TableCell style={{ border: '1px solid #9C9C9C', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Checkbox
                      checked={checkedPanels[panelNames.indexOf(panelName)]}
                      onChange={() => handleCheckboxChange(panelNames.indexOf(panelName))}
                     
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
      const renderPanelContent = (panelName) => {
        switch (panelName) {
          case "Certification cost on GAP":
            return <EntityCertificationCostGAP Magnetcrop={MagnetCrop} taluka={taluka}/>;

          case "MRL Testing":
            return  <EntityMRLTesting Magnetcrop={MagnetCrop} taluka={taluka}/>;

          case "Support for fruit care activities":
            return <EntitySupportFruitCareActivities Magnetcrop={MagnetCrop} taluka={taluka}/>;
    
          default:
            return <div>{`${panelName} Content`}</div>;
        }
      };

  useEffect(()=>{
    dispatch(resetDataEntityMaster());
    const MGBeneficiaryData = async () => {
        try {
            const applicant = await axios.get(`${apiUrl}api/get-all-beneficiary-master?all=1`,getConfigWithToken());  
            const division = await axios.get(`${apiUrl}api/getAllDivisions?all=1`,getConfigWithToken());  
            const Magnetcrop = await axios.get(`${apiUrl}api/getAllCrop?all=1`,getConfigWithToken());  
            const beneficiaryType = await axios.get(`${apiUrl}api/get-all-beneficiary-type?all=1`,getConfigWithToken());  
            const loantype = await axios.get(`${apiUrl}api/getLoanType?all=1`,getConfigWithToken());  
            const taluka = await axios.get(`${apiUrl}api/get-all-taluka-with-village?all=1`, getConfigWithToken())

            const applicantData = applicant.data["data"].map((e)=>({"id":e["id"],"beneficiary_name":e["beneficiary_name"]}));
            const divisionData = division.data["data"].map((e)=>({"id":e["id"],"divisions":e["divisions"]}));
            const MagnetcropData = Magnetcrop.data["data"].map((e)=>({"id":e["id"],"crop":e["crop"]}));
            const beneficiaryTypeData = beneficiaryType.data["data"].map((e)=>({"id":e["id"],"beneficiary_type":e["beneficiary_type"]}));
            const loantypeData = loantype.data["data"].map((e)=>({"id":e["loan_id"],"loan_type":e["loan_type"]}));
            
            setApplicant(applicantData);
            setDivision(divisionData);
            setMagentCrop(MagnetcropData);
            setBeneficiaryType(beneficiaryTypeData);
            setLoanType(loantypeData);
            setTaluka(taluka.data.data)
            
            if(id){

              const panelNamesTron = {
                "Certification cost on GAP": "7",
                "MRL Testing": "6",
                "Support for fruit care activities": "8",
              }
                const editData = await axios.get(`${apiUrl}api/input-one-primary-data-edit-training-type?beneficiary_id=${id}`, getConfigWithToken())
                editData.data["flag"] ? setEditEntityFlag(true) : setEditEntityFlag(false)
                // Function to check if a key exists in the data object
                const keyExists = (data, key) => Object.prototype.hasOwnProperty.call(data, key);

                // Extracting the objects with keys 6, 7, and 8 if they exist
                let trainingType6 = keyExists(editData.data.data[0], '6') ? editData.data.data[0]['6'] : null;
                let trainingType7 = keyExists(editData.data.data[0], '7') ? editData.data.data[0]['7'] : null;
                let trainingType8 = keyExists(editData.data.data[0], '8') ? editData.data.data[0]['8'] : null;

                // Creating a new object with the key 'training_type' and placing the extracted objects inside it
                let newData = {
                        ...editData.data.data[0],
                        training_type: {
                            ...(trainingType6 && { '6': trainingType6 }),
                            ...(trainingType7 && { '7': trainingType7 }),
                            ...(trainingType8 && { '8': trainingType8 })
                        }
                    }

                const { training_type, ...newObj } = newData
                console.log(newObj,"ssssssssssssssssssssssssssssssssssssss")
                dispatch(updateApiDataEntityMaster(newObj))
                const editDta = newData["training_type"]

                function updateMagnetCropId(obj) {
                  const processValue = (value) => {
                      if (Array.isArray(value)) {
                          return value.map(item => processValue(item));
                      } else if (typeof value === 'object' && value !== null) {
                          for (const key in value) {
                              if (value.hasOwnProperty(key)) {
                                  value[key] = processValue(value[key]);
                              }
                          }
                          if ('magnet_crop_id' in value) {
                              try {
                                  const magnetCropIdArray = JSON.parse(value.magnet_crop_id);
                                  if (Array.isArray(magnetCropIdArray)) {
                                      const ids = magnetCropIdArray.map(item => item.id);
                                      value.magnet_crop_id = ids;
                                  } else {
                                      value.magnet_crop_id = [];
                                  }
                              } catch (error) {
                                  // If parsing as JSON fails or if it's an empty object, treat it as an empty array
                                  value.magnet_crop_id = [];
                              }
                          }
                      }
                      return value;
                  };
              
                  return processValue(obj);
              }
              
              // Iterate through each object and update magnet_crop_id
              for (const key in editDta) {
                  if (editDta.hasOwnProperty(key)) {
                      editDta[key] = updateMagnetCropId(editDta[key]);
                  }
              }
              
              console.log(editDta,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")

              const panelNames = [
                "Certification cost on GAP",
                "MRL Testing",
                "Support for fruit care activities",
            ];
            
            const eee = Object.keys(editDta)
            console.log(eee, "dcdsddssdcsddddd99999999999999999")
            setPrevCheckValue(eee)
            const keysFromPanelNamesTron = eee.map((value) => Object.keys(panelNamesTron).find((key) => panelNamesTron[key] === value));

            
            const indices = keysFromPanelNamesTron.map((key) => panelNames.indexOf(key));
            
            const updatedCheckedPanels = checkedPanels.map((value, index) => indices.includes(index) ? true : value);
            setCheckedPanels(updatedCheckedPanels);
                const certification = editDta["7"]
                const mrlTesting = editDta["6"]
                const fruitCare = editDta["8"]

                const updateAllMasters = () => {
                  return async (dispatch) => {
                    if (certification) {
                      dispatch(updateApiDataCertificationCostMaster(certification));
                    }
                
                    if (mrlTesting) {
                      dispatch(updateApiDataMRLMaster(mrlTesting));
                    }
                
                    if (fruitCare) {
                      dispatch(updateApiDataSupportFruitMaster(fruitCare));
                    }
                  };
                };
                
                // Check if any of the objects are present before dispatching
                if (certification || mrlTesting || fruitCare) {
                  // Dispatch the combined action using dispatch(updateAllMasters());
                  dispatch(updateAllMasters());
                }else{
                  const unlisten = () => {
                    dispatch(resetDataCertificationCostMaster());
                    dispatch(resetDataMRLMaster());
                    dispatch(resetDataSupportFruitMaster());
                    dispatch(resetDataEntityMaster());
                    // Add more dispatches for other reset actions if needed
                  }
                  unlisten();
                }
                
                console.log(dd["payload"],"kjds nkjcndsjvndskjn")
                

          }
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };
MGBeneficiaryData();
},[])

    return(<>
    <Header/>
    <LeftNavigation/>
    <main id="main" className="main">
          <section className="section">
            <div className="row">              
              <div id="exTab3" className="contain" >
                <div className="cardMaster" id="" style={{ height: '1500px' }}>
                <form class="row g-3">
                        <Box className="your-component" sx={{ typography: 'body1' }}>
                            <div className="words-with-lines" >
                            <span>Invitation based component master</span>
                            </div>
                            <div className="msambstyle">
                            <a href="./GAP-Master">
                                <i className="fa-solid fa-arrow-left"></i>Back
                            </a>
                            <button type="submit" className="save-btn" onClick={handleSaveChange}>Save</button>
                            <button className="dropdown-btn"><i className="fa-solid fa-caret-down" ></i></button>
                            </div>
                        </Box>
                            {/* Tabs */}
                            <Box sx={{ width: '92%', height:'500px', marginLeft:"5rem", marginTop:"3.5rem", typography: 'body1'}}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs style={{width: "70rem"}} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange}>
                                          <Tab label="Entity Details" value="0" className={clsx(value === '0' && 'activeClickCSS')} style={{border:'2px solid #4e2683',borderRadius:'4px'}}/>
                                          {visiblePanelNames.map((name, index) => (
                                              <Tab key={index} label={name} style={{border:'2px solid #4e2683',borderRadius:'4px'}} value={(index + 1).toString()} className={clsx(value === (index + 1).toString() && 'activeClickCSS')} />
                                          ))}
                                        </Tabs>
                                    </Box>
                                  <TabPanel value="0">
                                        <EntityDetailsMaster id={id} entityChangeFunction={entityChangeFunction} editFlag={editEntityFlag} MagnetCrop={MagnetCrop} Applicant={Applicant} Division={Division} BeneficiaryType={BeneficiaryType} />
                                        <br/>
                                    <Grid container spacing={10}>
                                    <Grid item xs={5}>
                                        {renderTable(panelNames.slice(0, 2))}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {renderTable(panelNames.slice(2))}
                                    </Grid>
                                    </Grid>
                                </TabPanel>
                                {visiblePanelNames.map((panelName, panelIndex) => (
                                    <TabPanel key={panelIndex} value={(panelIndex + 1).toString()}>
                                    <div style={{ maxHeight: '200px', maxWidth: '100%', marginBottom: '16px', marginLeft:'2rem'}}>
                                        {renderPanelContent(panelName)}
                                    </div>
                                    </TabPanel>
                                ))}
                                </TabContext>  
                            </Box>
                       </form>
                        </div>
                    </div> 
                </div> 
            </section>
        </main>
    </>)
}