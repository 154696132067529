import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/EntityMaster/HDPMasterSlice";
import { BioWasteEntityTable } from './EntityTables/BioWasteTable';
import { useDispatch, useSelector } from 'react-redux';
import { HDPTable } from './EntityTables/HDPTable';

export const EntityHDP = (props) =>{ 

    const hdpMaster = useSelector(state => state.hdpMaster["6"]);
    const dispatch = useDispatch();

    const handleFieldMAGNETCrop = (event) => {
        dispatch(setInputFieldData({ fieldName: 'magnet_crop_id', value:event.target.value }));
    }
    const handleFieldLocationTaluka = (event) => {
        dispatch(setInputFieldData({ fieldName: 'location_taluka', value:event.target.value }));
    }
    const handleFieldAreaPlantation = (event) => {
        dispatch(setInputFieldData({ fieldName: 'area', value:event.target.value }));
    }
    const handleFieldFarmersCertificates = (event) => {
        dispatch(setInputFieldData({ fieldName: 'no_of_farmers', value:event.target.value }));
    }
    const handleFieldCertificationCost = (event) => {
        dispatch(setInputFieldData({ fieldName: 'total_project_cost', value:event.target.value }));
    }
    const handleFieldSanctionGrant = (event) => {
        dispatch(setInputFieldData({ fieldName: 'matching_grant', value:event.target.value }));
    }
    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
  
    return(<>   
        {/* <Online> */}
        <div className='tab-pane'>
                <form class="row g-3" id='formid'>

                    {/* Magnet_crops */}
                    <div className='primaryTableLabel'>
                    <Box>
                        <label htmlFor="MAGNET" style={{width:'270px'}}>MAGNET Crops</label>
                        <FormControl style={{width:'70%'}} >
                        <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="MAGNET" 
                             className="customSelect" 
                             style={{width:"70%" }}
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={hdpMaster.magnet_crop_id}
                             onChange={handleFieldMAGNETCrop}   
                             onFocus={handleResetFocus}      
                             multiple
                                                          
                            >
                        {(props.Magnetcrop).map((e)=>(       
                            <MenuItem value={e.id} key={e.id}>{e.crop}</MenuItem>
                        ))} 
                           
                        </Select>
                        </FormControl>
                    </Box>
                    </div>
    
                {/* Location (Taluka) */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="LocationTaluka" style={{width:'270px'}}>Location (Taluka)</label>
                    <FormControl style={{width:'70%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="LocationTaluka" 
                        className="customSelect" 
                        style={{width:"70%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={hdpMaster.location_taluka}
                        onChange={handleFieldLocationTaluka} 
                        // onFocus={handleResetFocus}                                         
                    >
                     {(props.taluka).map((e)=>(       
                             <MenuItem value={e.id} key={e.id}>{e.taluka}</MenuItem>
                    ))} 
                    </Select>
                    </FormControl>
                </Box>
                </div> 

                    {/* Nos. of Farmers Demonstrated * */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="Demonstrated "  style={{width:'270px'}}>Nos. of Farmers Demonstrated</label>
                        <input type="number" name="Demonstrated " 
                            value={hdpMaster.no_of_farmers}
                            onChange={handleFieldFarmersCertificates} 
                            // onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"49%"}}  
                            
                        />
                    </div>
                                                                              
                    {/* Area of Plantation * */}
                    <div className='primaryTableLabel' >
                        <label htmlFor="Area "style={{width:'270px'}}>Area of Plantation</label>
                        <input type="number" name="Area " 
                            value={hdpMaster.area}
                            onChange={handleFieldAreaPlantation} 
                            // onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"49%"}}  
                        />
                    </div>
                                                                             
                    {/* Total Plantation Cost */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="PlantationCost " style={{width:'270px'}}>Total Plantation Cost</label>
                        <input type="number" name="PlantationCost " 
                            value={hdpMaster.total_project_cost}
                            onChange={handleFieldCertificationCost} 
                            // onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                                                                                                                   
                    {/* Total Sanction Grant from MAGNET */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="sanctionGrant " style={{width:'270px'}}>Total Sanction Grant from MAGNET</label>
                        <input type="number" name="sanctionGrant " 
                            value={hdpMaster.matching_grant}
                            onChange={handleFieldSanctionGrant} 
                            // onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                    <HDPTable setDisabled={props.setDisabled}  validateDates={props.validateDates}/>
               </form>         
            </div>   
        {/* </Online>
        <Offline>
            You're Offline, Please Check your Connection
        </Offline> */}
        </>)
    }
    
