/* eslint-disable default-case */
import '../assets/css/inputForm.css'
import { DatePick } from "../subcomponents/DatePick"
import { InputField } from "../subcomponents/InputField"
import pdf from '../assets/PDF_file_icon.svg.png'
import imgIcon from '../assets/image_icon-icons.com_50366.png'
import React, { useState } from 'react';
import { setValidationErrorsHighDensity, resetDataHighDensity, updateHighDensityForm } from '../redux/slice/InputOne/HighDensity/HighDensitySlice'
import { setValidationErrorsMarketAssist, resetDataMarketAssist, updateMarketAssistForm } from '../redux/slice/InputOne/MarketAssistence/MarketAssistenceSlice'
import { setValidationErrorsOtherCerti, resetDataOtherCerti, updateOtherCertiForm} from '../redux/slice/InputOne/OtherCertification/OtherCertificationSlice'
import { updateProductionPlantForm, resetDataProductionPlant, setValidationErrorsProductionPlant } from '../redux/slice/InputOne/ProductionPlant/ProductionPlantSlice';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { getConfigWithToken } from '../utils/Config/Config';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setInputFieldData } from '../redux/slice/InputTwo/MasterSubSlice';
import { MarketAssistence } from '../subcomponents/Input1/Input1MarketAssistence/MarketAssistence'
import { MultiSelect } from '../subcomponents/MultiSelect';
import { HighDensity } from '../subcomponents/Input1/Input1HDP/HighDensity';
import { OtherCertification } from '../subcomponents/Input1/Input1OtherCertificateCost/OtherCertificateCost';
import { ProductionPlant } from '../subcomponents/Input1/Input1ProductionPlant/ProductionPlant';
  

export const InvitationBasedForm = ({quarter, magentCrp, applicantDta}) =>{

    const apiUrl = process.env.REACT_APP_API_URL;
    const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const [MarketDevAss, setMarketDevAss] = useState([])
    const [OtherCert, setOtherCert] = useState([])
    const [ProdPlant, setProdPlant] = useState([])
    const [loadingg, setloadingg] = useState(true);
    const [value, setValue] = useState('5');
    const [quarters, setQuarter] = useState([])
    const [selectQuarter, setSelectedQuarters] = useState("")
    const [selectedGAP, setSelectedGAP] = useState("");
    const [selectedMarketLevel, setSelectedMarketLevel] = useState("");
    const [selectedOtherCertification, setSelectedOtherCertification] = useState("");
    const [selectedProductionPlant, setSelectedProductionPlant] = useState("");
    const [magentCrop, setMagentCrop] = useState([])

    const [hdpDensityFlagEdit, setHdpDensityFlagEdit] = useState(false)
    const [hdpDensityIdEdit, setHdpDensityIdEdit] = useState("")
    const [marketLevelFlagEdit, setMarketLevelFlagEdit] = useState(false)
    const [marketLevelIdEdit, setMarketLevelIdEdit] = useState("")
    const [otherCertFlagEdit, setOtherCertFlagEdit] = useState(false)
    const [otherCertIdEdit, setOtherCertIdEdit] = useState("")
    const [prodPlantFlagEdit, setProdPlantFlagEdit] = useState(false)
    const [prodPlantIdEdit, setProdPlantIdEdit] = useState("")

    const [beniFiApplicant, setBeniApplicant] = useState([])
    const [selectbeniFiApplicant, setSelectBeniApplicant] = useState("")
    const [hdpbeneficiary, setHdpbeneficiary] = useState([])
    const [errorSelectBeniApplicant, setErrorSelectBeniApplicant] = useState("")

    // Files State
    const [editInput1Files, setEditInput1Files] = useState([])
    const [selectCapacityFile, setSelectCapacityFile] = useState([])
    const [selectOtherCertificationFile, setSelectOtherCertificationFile] = useState([])
    const [errorCapacityFile, setErrorCapacityFile] = useState("")

    // setting beneficiary
    const [nurseryTissueBeneficiary, setNurseryTissueBeneficiary] = useState([])
    const [permission, setPermission] = useState([])
    const dd = useSelector(setInputFieldData);

    const checkFPOSelect = () =>{
        if(selectbeniFiApplicant.length === 0){
            setErrorSelectBeniApplicant("Please Select Applicant")
        }else{
            return true
        }
        return false
    }

    const resetAllState = () => {
        setSelectBeniApplicant("")
        setErrorSelectBeniApplicant("")
        setSelectedGAP("")
        setSelectedOtherCertification("")
        setSelectedProductionPlant("")
        setNurseryTissueBeneficiary([])
        setHdpbeneficiary([])
        setEditInput1Files([])
        dispatch(resetDataProductionPlant())
    }

    const handleChange = async (event, newValue) => {
        
        setValue(newValue)
        resetAllState()
    };

    const handleMultiFilesGAPChange = (event) => {
        setSelectCapacityFile(event.target.files)
    }

    const handleMultiFilesOtherCertifChange = (event) => {
        setSelectOtherCertificationFile(event.target.files)
    }


    const handleDeleteFile = async (id) =>{
        const deleteFile = await axios.get(`${apiUrl}api/delete-file-input-one-others?id=${id}`,getConfigWithToken())
        if(deleteFile.data["status"]){
            const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
            setEditInput1Files(newRemarks);
            Swal.fire(
                `File deleted Successfully`,
                '',
                'success'
            )
        }
    }

    
    const handleOptionsMarketLevel = async (event) =>{
        if(checkFPOSelect()){
            setSelectedMarketLevel(event.target.value)
            const getMarketAssistEditData = await axios.get(`${apiUrl}api/market-dev-ass-edit?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&training_type_id=${event.target.value}&form_id=17`,getConfigWithToken())
            
            if(getMarketAssistEditData.data["status"] === true){
                getMarketAssistEditData.data["flag"]?setMarketLevelFlagEdit(true):setMarketLevelFlagEdit(false)
                const data = getMarketAssistEditData.data["data"][0]

                setMarketLevelIdEdit(getMarketAssistEditData.data["data"][0]["id"])
                dispatch(updateMarketAssistForm(data))
            }else{
                setMarketLevelFlagEdit(false)
                dispatch(resetDataMarketAssist())
                const getDataFromBeneficiary = await axios.get(`${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=17`,getConfigWithToken())
                const data = getDataFromBeneficiary.data["data"]
                if(data!==null){
                    data["name_of_exhibition"] = data["name_of_certification_exhibition"]
                    dispatch(updateMarketAssistForm(data))
                }
            }
        }
    }

    const handleOptionsOtherCertCost = async (event) => {
        if(checkFPOSelect()){
            setSelectedOtherCertification(event.target.value)
            const getOtherCertEditData = await axios.get(`${apiUrl}api/other-cirtification-edit?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&training_type_id=${event.target.value}&form_id=15`,getConfigWithToken())
            
            if(getOtherCertEditData.data["status"] === true){
                getOtherCertEditData.data["flag"]?setOtherCertFlagEdit(true):setOtherCertFlagEdit(false)
                const data = getOtherCertEditData.data["data"][0]
                setEditInput1Files(data["remarks"])
                setOtherCertIdEdit(getOtherCertEditData.data["data"][0]["id"])
                dispatch(updateOtherCertiForm(data))
            }else{
                setOtherCertFlagEdit(false)
                dispatch(resetDataOtherCerti())
                const getDataFromBeneficiary = await axios.get(`${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=15`,getConfigWithToken())
                const data = getDataFromBeneficiary.data["data"]
                if(data!==null){
                    data["name_of_certification"] = data["name_of_certification_exhibition"]
                    dispatch(updateOtherCertiForm(data))
                }
            }
        }
    }

    const handleOptionsProductionPlant = async (event) => {
        if(checkFPOSelect()){
            setSelectedProductionPlant(event.target.value)
            const getProdPlantEditData = await axios.get(`${apiUrl}api/production-plant-cirtification-edit?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&training_type_id=${event.target.value}&form_id=16`,getConfigWithToken())
            
            if(getProdPlantEditData.data["status"] === true){
                getProdPlantEditData.data["flag"]?setProdPlantFlagEdit(true):setProdPlantFlagEdit(false)
                const data = getProdPlantEditData.data["data"][0]
                setEditInput1Files(data["remarks"])
                setProdPlantIdEdit(getProdPlantEditData.data["data"][0]["id"])
                dispatch(updateProductionPlantForm(data))
            }else{
                setProdPlantFlagEdit(false)
                dispatch(resetDataProductionPlant())
                const getDataFromBeneficiary = await axios.get(`${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&quarter=${selectQuarter}&component_id=${event.target.value}&form_id=16`,getConfigWithToken())
                const data = getDataFromBeneficiary.data["data"]
                if(data!==null){
                    data["name_of_certification"] = data["name_of_certification_exhibition"]
                    dispatch(updateProductionPlantForm(data))
                }
            }
        }
    }

    const handleHighDensityFPO = async (event) => {
        const applicantId = event.target.value
        setSelectBeniApplicant(applicantId)
        const getHighDensityEdit = await axios.get(`${apiUrl}api/getAllDetailsDemonstrationsOnHdp?quarter=${selectQuarter}&beneficiary_id=${applicantId}`, getConfigWithToken())
        
        if(getHighDensityEdit.data["status"] === true){
            getHighDensityEdit.data["flag"]?setHdpDensityFlagEdit(true):setHdpDensityFlagEdit(false)
            const data = getHighDensityEdit.data["data"][0]
            setHdpDensityIdEdit(data["id"])
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            
            dispatch(updateHighDensityForm(data))
            setHdpbeneficiary(data)
        }else{
            const getHighDensityEdit = await axios.get(`${apiUrl}api/get_primarydata_by_benificiary?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=6`, getConfigWithToken())
            const data = getHighDensityEdit.data["data"]
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            
            setHdpbeneficiary(data)
            setHdpDensityFlagEdit(false)
            dispatch(resetDataHighDensity())
        }
    }

// Change the API of Applicant with the Form Id and Beneficiary Id
    const handleApplicantDataByFormId = async (applicantId, form_id) => {
        try{
            const beneficiary_by_id = await axios.get(`${apiUrl}api/get_primarydata_by_benificiary?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,getConfigWithToken())
            const data = await beneficiary_by_id.data["data"]
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            setNurseryTissueBeneficiary(data)
            return await data
        }catch(err){

        }
    }

    const handleApplicantDataWithoutComponent = async (applicantId, form_id) => {
        try{
            const beneficiary_by_id = await axios.get(`${apiUrl}api/get_primary_data_for_non_cat_compo_forms?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,getConfigWithToken())
            const data = await beneficiary_by_id.data["data"]
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            setNurseryTissueBeneficiary(data)
            return await data
        }catch(err){

        }
    }


    const handleApplicantChangeESIFPO = async (event) =>{
        setErrorSelectBeniApplicant("")
        const applicantId = event.target.value
        setSelectBeniApplicant(applicantId)
        setErrorSelectBeniApplicant("")
        setSelectedGAP("")
        setSelectedOtherCertification("")
        setSelectedMarketLevel("")
        setSelectedProductionPlant("")
        try{
            
            switch(value){
                case '1':
                    handleApplicantDataByFormId(applicantId, 3)
                break;
                case '5':
                    handleApplicantDataByFormId(applicantId, 6)
                break;
                case '6':
                    handleApplicantDataByFormId(applicantId, 2)
                break;
                case '8':
                    handleApplicantDataByFormId(applicantId, 17)
                break;
                case '12':
                    handleApplicantDataByFormId(applicantId, 23)
                break;
                case '13':
                    handleApplicantDataByFormId(applicantId, 15)
                break;
                case '18':
                    handleApplicantDataByFormId(applicantId, 16)
                break;
                case '19':
                    handleApplicantDataByFormId(applicantId, 26)
                break;

            }

           
        }catch(error){
            Swal.fire(
                `${error.message}`,
                "",
                "warning"
              );
        }
    }
    
    const refershdata = () => {
        setSelectBeniApplicant('');
        setHdpbeneficiary([]);
        setNurseryTissueBeneficiary([]);
        setSelectedMarketLevel('');
        setSelectedOtherCertification('');
        setSelectedProductionPlant('');
        
        dispatch(resetDataHighDensity({}));

        setEditInput1Files([]);
        setSelectCapacityFile([]);
        setErrorCapacityFile('');

    }
    const handleQuarterChange = async (event) =>{
        refershdata()
        setHdpDensityFlagEdit("")
        setProdPlantFlagEdit("")
        setOtherCertFlagEdit("")
        setMarketLevelFlagEdit("")
        setSelectedQuarters(event.target.value)
    }

    const handleHighDensitySubmit = async (e) => {
        e.preventDefault()
        const formData = dd["payload"]["highDensity"];
        
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["highDensity"];
            const errorMessages = {
                sc_male: 'SC Male is required',
                sc_female: 'SC Female is required',
                st_male: 'ST Male is required',
                st_female: 'ST Female is required',
                pwd_male: 'PWD Male is required',
                pwd_female: 'PWD Female is required',
                obc_minority_open_male: 'OBC Minority Open Male is required',
                obc_minority_open_female: 'OBC Minority Open Female is required',
                beneficiaries_male: 'Beneficiaries Male is required',
                beneficiaries_female: 'Beneficiaries Female is required',
            };
        
            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsHighDensity(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const highDensity = dd["payload"]["highDensity"]
                
                const jsonMagnetCrop = hdpbeneficiary["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === Number(id));
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };
                
                const highDensityData = {...highDensity,
                    "quarter": selectQuarter,
                    "beneficiary_id": selectbeniFiApplicant,
                    "district_id": hdpbeneficiary.district.district_id,
                    "division_id": hdpbeneficiary.divisions.division_id,
                    "taluka_id": hdpbeneficiary.taluka.taluka_id,
                    "location_taluka": hdpbeneficiary.location.location_taluka_id,
                    "beneficiary_type_id": hdpbeneficiary.beneficiary_type.beneficiary_type_id,
                    "magnet_crop_id": JSON.stringify(resultMagnetCrop["data"]),
                    "total_saction_grant": hdpbeneficiary.total_saction_grant,
                    "total_plantation_cost": hdpbeneficiary.total_plantation_cost,
                    "area": hdpbeneficiary.area,
                    "payment_tranche": hdpbeneficiary.tranche ? hdpbeneficiary.tranche.payment_tranche: hdpbeneficiary.payment_tranche,
                    "no_of_farmers_demonstrated": hdpbeneficiary.no_of_farmers,
                    "form_id": "6",
                    "plantation_cost": hdpbeneficiary.total_plantation_cost,
                    "grant_disbursement_date": hdpbeneficiary.grant_disbursement_date,
                    "grant_disbursement_amt": hdpbeneficiary.grant_disbursement_amount,
                }
                

                try{
                    if(!hdpDensityFlagEdit){
                        const submitHighDensityData = await axios.post(`${apiUrl}api/demonHDP-inputs-create`, highDensityData, getConfigWithToken())
                        if(submitHighDensityData.data["status"] === true){
                            Swal.fire(
                                `${submitHighDensityData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataHighDensity())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitHighDensityData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        highDensityData.id=hdpDensityIdEdit
                        
                        const submitHighDensityData = await axios.post(`${apiUrl}api/demonHDP-inputs-update`, highDensityData, getConfigWithToken())
                        
                        if(submitHighDensityData.data["status"] === true){
                            Swal.fire(
                                `${submitHighDensityData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataHighDensity())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitHighDensityData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }

    }
    
    const handleMarketAssistSubmit = async (e) => {
        e.preventDefault()
        const formData = dd["payload"]["marketAssistence"];
        
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["marketAssistence"];
            const errorMessages = {
                disbursement_amt: "Disbursement Amount is Required",
                disbursement_date: "Disbursement Date is Required",
            };
        
            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsMarketAssist(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };


        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const marketAssistence = dd["payload"]["marketAssistence"]
                const formData = new FormData();
                for (const key in marketAssistence) {
                   formData.append(key, marketAssistence[key]!==null?marketAssistence[key]:"");
                 }
               
                 formData.append('quarter', selectQuarter);
                 formData.append('beneficiary_id', selectbeniFiApplicant);
                 formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                 formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                 formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                 formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                 formData.append('training_type_id', selectedMarketLevel);
                 formData.append('form_id', '17');
                  
                

                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }

                try{
                    if(!marketLevelFlagEdit){
                        const submitMarketAssistData = await axios.post(`${apiUrl}api/market-dev-ass-create`, formData, getConfigWithToken())
                        if(submitMarketAssistData.data["status"] === true){
                            Swal.fire(
                                `${submitMarketAssistData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataMarketAssist())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitMarketAssistData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", marketLevelIdEdit)
                        const submitMarketAssistData = await axios.post(`${apiUrl}api/market-dev-ass-update`, formData, getConfigWithToken())
                        
                        if(submitMarketAssistData.data["status"] === true){
                            Swal.fire(
                                `${submitMarketAssistData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataMarketAssist())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitMarketAssistData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleOtherCertificationSubmit = async (e) => {
        e.preventDefault()
        
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["otherCertification"];
            const errorMessages = {
                disbursement_amt: "Disbursement Amount is Required",
                disbursement_date: "Disbursement Date is Required",
            };
        
            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsOtherCerti(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        // validateForm()

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const capacityBuilding = dd["payload"]["otherCertification"]

                const formData = new FormData();
                for (const key in capacityBuilding) {
                    formData.append(key, capacityBuilding[key]!==null?capacityBuilding[key]:"");
                  }
                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('training_type_id', selectedOtherCertification);
                  formData.append('form_id', '15');


                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectOtherCertificationFile.length !== 0){
                    for(let i=0; i<selectOtherCertificationFile.length;i++){
                        size += selectOtherCertificationFile[i].size
                        formData.append("remarks[]", selectOtherCertificationFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }

                // 

                try{
                    if(!otherCertFlagEdit){
                   
                        const submitOtherCertiData = await axios.post(`${apiUrl}api/other-cirtification-create`, formData, getConfigWithToken())
                        if(submitOtherCertiData.data["status"] === true){
                            Swal.fire(
                                `${submitOtherCertiData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataOtherCerti())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitOtherCertiData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", otherCertIdEdit);
                        const submitOtherCertiData = await axios.post(`${apiUrl}api/other-cirtification-update`, formData, getConfigWithToken())
                        
                        if(submitOtherCertiData.data["status"] === true){
                            Swal.fire(
                                `${submitOtherCertiData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataOtherCerti())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitOtherCertiData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleProductionSubmit = async (e) => {
        e.preventDefault()
        const formData = dd["payload"]["productionPlant"];
        
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["productionPlant"];
            const errorMessages = {
                disbursement_amt: "Disbursement Amount is Required",
                disbursement_date: "Disbursement Date is Required",
            };
        
            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsProductionPlant(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };


        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const productionPlant = dd["payload"]["productionPlant"]
                const formData = new FormData();
                 for (const key in productionPlant) {
                    formData.append(key, productionPlant[key]!==null?productionPlant[key]:"");
                  }

                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('training_type_id', selectedProductionPlant);
                  formData.append('form_id', '16');

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }


                try{
                    if(!prodPlantFlagEdit){
                    // if(true){
                        const submitProductionPlant = await axios.post(`${apiUrl}api/production-plant-cirtification-create`, formData, getConfigWithToken())
                        if(submitProductionPlant.data["status"] === true){
                            Swal.fire(
                                `${submitProductionPlant.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataProductionPlant())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitProductionPlant.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", prodPlantIdEdit)
                        const submitProductionPlant = await axios.post(`${apiUrl}api/production-plant-cirtification-update`, formData, getConfigWithToken())
                        
                        if(submitProductionPlant.data["status"] === true){
                            Swal.fire(
                                `${submitProductionPlant.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataProductionPlant())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitProductionPlant.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }
    const handleDeleteHDP= async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/demonHDP-inputs-delete?id=${hdpDensityIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setHdpDensityFlagEdit("");
              dispatch(resetDataHighDensity());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteMarkDev= async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/market-dev-ass-delete?id=${marketLevelIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setMarketLevelFlagEdit("");
              dispatch(resetDataMarketAssist());
              setLoading(false)
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false)
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
      
      const handleDeleteCertCost= async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/other-cirtification-delete?id=${otherCertIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setOtherCertFlagEdit("");
              dispatch(resetDataOtherCerti());
              setLoading(false)
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false)
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteProdPlantCert= async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/production-plant-cirtification-delete?id=${prodPlantIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setProdPlantFlagEdit("");
              dispatch(resetDataProductionPlant());
              setLoading(false)
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false)
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

    useEffect(()=>{
        const quarterData = async () => {
            try {
                const storedArrayAsString = localStorage.getItem('permission');
                setPermission(storedArrayAsString)
              
              const market = await axios.get(`${apiUrl}api/typeByCategory?category_id=17`,getConfigWithToken())
              const other = await axios.get(`${apiUrl}api/typeByCategory?category_id=15`,getConfigWithToken())
              const prod = await axios.get(`${apiUrl}api/typeByCategory?category_id=16`,getConfigWithToken())
              
              setQuarter(quarter);
              setMarketDevAss(market.data.data[0])
              setOtherCert(other.data.data[0])
              setProdPlant(prod.data.data[0])
            const selectedValues = quarter.filter(item => item.selected === "Selected").map(item => item.id);
              setSelectedQuarters(selectedValues[0])
              setMagentCrop(magentCrp)
              
              setBeniApplicant(applicantDta); // Pass applicantData to setApplicant
              setloadingg(false);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          
          quarterData();
        
        setloadingg(false);
    },[quarter, magentCrp])


    if (loadingg) {
        return (<>
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box>
        </>);
      }

    return(<>
       
            <main id="main" class="main">
            <section class="section">
            <div class="row">
                <div id="exTab3" class="contain">	
                
                <div class="card">
                <Box sx={{ width: '100%', typography: 'body1', bgcolor: 'background.paper' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange} sx={{ width: '100%', overflowX: 'auto' }}>
                            
                            <Tab label="Demonstrations: High-Density Plantation (HDP)" value="5" className={`${value === '5' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Production Plant" value="18" className={`${value === '18' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Other Certification Cost" value="13" className={`${value === '13' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Market Development Assistense" value="8" className={`${value === '8' ? 'activeClickCSS' : ''}`}/>
                            
                        </Tabs>
                        </Box>

                        
                        {/* Demonstrations: High-Density Plantation (HDP) */}
                        <TabPanel value='5'>
                            <div className='tab-pane'>
                                <form class="row g-3" onSubmit={handleHighDensitySubmit}>
                                <div className='quarterSelect'>
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                            label="Quarter"
                                                            onChange={handleQuarterChange}
                                                        >
                                                        {(quarters).map((e,key)=>(
                                                            <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                    </div>
                                    <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                            <Box sx={{ minWidth: "100%" }}>
                                                <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                    <InputLabel htmlFor="demo-simple-select-label">FPO/VCO</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectbeniFiApplicant}
                                                    label="FPO/VCO"
                                                    onChange={handleHighDensityFPO}
                                            
                                                    >
                                                    {(beniFiApplicant).map((e)=>(
                                                        <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                    ))}
                                                    </Select>
                                                    <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.divisions ? hdpbeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.district ? hdpbeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.taluka ? hdpbeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.beneficiary_type ? hdpbeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                        <MultiSelect label="Magnet Crops" data={magentCrop} value={hdpbeneficiary && hdpbeneficiary.magnet_crop_id ? hdpbeneficiary.magnet_crop_id: []}/>
                                    </div>
                                  
                                    <InputField label="Location(Taluka)" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.location ? hdpbeneficiary.location["location_taluka"].toString(): ''}/>
                                    <InputField label="Nos. of Farmers  Demonstrated" type="number" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.no_of_farmers ? hdpbeneficiary.no_of_farmers.toString(): ''}/>
                                    <InputField label="Area of Plantation" type="text" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.area ? hdpbeneficiary.area.toString(): ''}/>
                                    <InputField label="Total Plantation cost" type="number" endAr="(Lakhs)" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.total_plantation_cost ? hdpbeneficiary.total_plantation_cost.toString(): ''}/>
                                    <InputField label="Total Saction Grant from MAGNET" type="number"  endAr="(Lakhs)" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.total_saction_grant ? hdpbeneficiary.total_saction_grant.toString(): ''}/>
                                    <InputField label="Payment Tranche" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.tranche ? hdpbeneficiary.tranche.payment_tranche.toString() : hdpbeneficiary.payment_tranche}/>
                                    <InputField label="Grant Disbursement Amount (in lakhs)" type="number" readOnly={true} value={hdpbeneficiary && hdpbeneficiary.grant_disbursement_amount ? hdpbeneficiary.grant_disbursement_amount.toString(): ''}/>
                                    <DatePick label="Grant Disbursement Date" className="custom-date-picker" disbrushDate={hdpbeneficiary && hdpbeneficiary.grant_disbursement_date ? hdpbeneficiary.grant_disbursement_date.toString(): ''} readOnly={true}/>
                                    <div className="word-with-line">
                                        <span>Details for - High Density Plantation (HDP)</span>
                                        <i class="fa-solid fa-angle-down"  style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <HighDensity/>
                                <div style={{ textAlign: "center" }}>
                                {
                                        permission.includes("HDP-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{hdpDensityFlagEdit === true ? "Update":"Submit"}</button>
                                        {hdpDensityFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteHDP}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* Market Level Assistence */}
                        <TabPanel value='8'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleMarketAssistSubmit}>
                                        <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                <div className="word-with-line">
                                    <span>Beneficiary Information</span>
                                    <i
                                    class="fa-solid fa-angle-down"
                                    style={{ color: "#4e2683" }}
                                    ></i>
                                </div>
                                
                                <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>

                                <div className="word-with-line" style={{ margin: "1.5rem 0" }}>
                                    <span> Project Wise Information - {selectedMarketLevel && MarketDevAss.find(e=>e['id']===selectedMarketLevel).type}</span>
                                        <div>
                                            <Box sx={{ minWidth: 390 }} style={{ backgroundColor: "#ededed" }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Certification
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"                                                    
                                                        value={selectedMarketLevel}
                                                        onChange={handleOptionsMarketLevel}
                                                        label="Trainin"                                                   
                                                    >
                                                        {MarketDevAss.map((e) => (
                                                        <MenuItem value={e.id}>
                                                            {e.type}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                              
                          <div>
                                    {selectedMarketLevel && 
                                    <>
                                    <MarketAssistence compName={selectedMarketLevel && MarketDevAss.find(e=>e['id']===selectedMarketLevel).type}/>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>

                                <div style={{ textAlign: "center" }}>
                                {
                                        permission.includes("Market-Dev-Ass-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{marketLevelFlagEdit === true ? "Update":"Submit"}</button>
                                        {marketLevelFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteMarkDev}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* Other Certification Cost */}
                        <TabPanel value='13'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleOtherCertificationSubmit}>
                                        <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                    <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>

                                <div
                                    className="word-with-line"
                                    style={{ margin: "1.5rem 0" }}
                                >
                                    <span>
                                    Project Wise Information - {selectedOtherCertification && OtherCert.find(e=>e['id']===selectedOtherCertification).type}
                                    </span>
                                    <div>
                                    <Box
                                        sx={{ minWidth: 390 }}
                                        style={{ backgroundColor: "#ededed" }}
                                    >
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Certification
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedOtherCertification}
                                            onChange={handleOptionsOtherCertCost}
                                            label="Trainin"
                                        >
                                            {OtherCert.map((e) => (
                                            <MenuItem value={e.id}>
                                                {e.type}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                    </Box>
                                    </div>
                                </div>
                                <br />

                                <div>
                                    {selectedOtherCertification && <OtherCertification compName={selectedOtherCertification && OtherCert.find(e=>e['id']===selectedOtherCertification).type}/>}
                                </div>
                                {selectedOtherCertification && 

                                <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                    <label for="inputNumber" class="col-lg col-form-label">Remarks (Upload Progress Report / Photos)</label>
                                    <div class="col-sm-10">
                                        <input class="form-control" type="file" multiple id="formFile" onChange={handleMultiFilesOtherCertifChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                        <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                    </div>
                                </div>}
                               
                                <div style={{ textAlign: "center" }}>
                                    {
                                        permission.includes("Other-Cert-Cost-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{otherCertFlagEdit === true ? "Update":"Submit"}</button>
                                        {otherCertFlagEdit === true && (
                                        <button type="button" className="btn submitbtn"disabled={loading}  onClick={handleDeleteCertCost}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* Production Plant Certification */}
                        <TabPanel value='18'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleProductionSubmit}>
                                        <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                    <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                                    
                                <div
                                    className="word-with-line"
                                    style={{ margin: "1.5rem 0" }}
                                >
                                    <span>
                                    Project Wise Information - {selectedProductionPlant && ProdPlant.find(e=>e['id']===selectedProductionPlant).type}
                                    </span>
                                    <div>
                                    <Box
                                        sx={{ minWidth: 390 }}
                                        style={{ backgroundColor: "#ededed" }}
                                    >
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Certification
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedProductionPlant}
                                            onChange={handleOptionsProductionPlant}
                                            label="Trainin"
                                        >
                                            {ProdPlant.map((e) => (
                                            <MenuItem value={e.id}>
                                                {e.type}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                    </Box>
                                    </div>
                                </div>
                                <br />

                                <div>
                                    {selectedProductionPlant && 
                                    <>
                                    <ProductionPlant compName={selectedProductionPlant && ProdPlant.find(e=>e['id']===selectedProductionPlant).type}/>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                    
                                
                                
                                <div style={{ textAlign: "center" }}>
                                    {
                                        permission.includes("Production-Plant-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{prodPlantFlagEdit === true ? "Update":"Submit"}</button>
                                        {prodPlantFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteProdPlantCert}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                                </form>
                            </div>
                        </TabPanel>

                    </TabContext>
                </Box>
                </div>
            </div>

            </div>
        </section>
        </main>
    
    </>)
}