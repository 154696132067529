import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contract_no: '',
    component: '',
    area: '',
    supplier_contractor_name: '',
    contract_amount: '',
    contract_award_date: null,
    completion_date: null,
    extension_date: null,
    time_elapsed: '',
    contract_extension: '',
    price_increase: '',
    increased_amount: '',
    total_disbursed_amount: '',
    total_undisbursed_amount: '',
    total_exp_till_date: '',
    status_of_work_id: '',
    exp_incurred: '',

    civil_progress_till_date: '',
    electro_mechanical_progress_till_date: '',

    civil_progress_pct: '',
    electro_mechanical_progress_pct: '',
    action_points: '',

  validationErrors:{
    status_of_work_id: '',
    exp_incurred: '',
    civil_progress_pct: '',
    electro_mechanical_progress_pct: '',
  }
};

const physicalFinanceSlice = createSlice({
  name: 'physicalFinanceSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },

    // Add other reducers for different input fields if needed
    updatePhysicalFinForm: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"Payloadffff")
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];

        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    updateThreeFormData: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"Payload")
      state.civil_progress_till_date = payload.civil_progress_till_date
      state.electro_mechanical_progress_till_date = payload.electro_mechanical_progress_till_date
    },

    resetDataPhyscialFin: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsPhysicalFin: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {addSelectedFiles, setValidationErrorsPhysicalFin, resetValidation, setInputFieldData, updatePhysicalFinForm, updateThreeFormData, resetDataPhyscialFin, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants, setTotalFemaleParticipants, setTotalMaleParticipants } = physicalFinanceSlice.actions;

export default physicalFinanceSlice.reducer;
