import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  
  location_taluka: '',
  conducted_by_tot:'',
  no_of_tots:'',
  beneficiary_attended:'',
  magnet_crop_id:[],

  participants_male: '',
  participants_female: '',
  participants_total: '',


  validationErrors:{
    participants_male: '',
    participants_female: '',
    location_taluka: '',
    conducted_by_tot:'',
    no_of_tots:'',
    magnet_crop_id:'',
  }
};

const postHarvestSlice = createSlice({
  name: 'postHarvestSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    
    setTotalParticipants: (state, action) => {
        state.participants_total= action.payload;
      },
    // Add other reducers for different input fields if needed
    updatePostHarvestForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataPostHarvest: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsPostHarvest: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsPostHarvest, resetValidation, setInputFieldData, updatePostHarvestForm, resetDataPostHarvest, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants, setTotalFemaleParticipants, setTotalMaleParticipants } = postHarvestSlice.actions;

export default postHarvestSlice.reducer;
