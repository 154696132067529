import React, { useMemo } from 'react';
import { useTable} from 'react-table';
import '../../assets/css/OutputTable.css'

export const DashboardOutputThree = ({ apiData }) => {

    const columns = useMemo(
        () => [
          {
            Header: 'Name of MSAMB Facility',
            accessor: 'msamb_facility_name',
          },
          {
            Header: 'Magnet Crop',
            accessor: 'magnet_crop',
          },
          {
            Header: 'Work Package',
            accessor: 'work_package',
          },
          {
            Header: 'Name of Contractor/ Supplier',
            accessor: 'supplier_contractor_name',
          },
          {
            Header: 'Contract Award Date (YYYY MM DD)',
            accessor: 'contract_award_date',
          },
          {
            Header: 'Completion Date (YYYY MM DD)',
            accessor: 'completion_date',
          },
          {
            Header: 'Total Project Cost (in Lakh)',
            accessor: 'total_contract_cost',
          },
          {
            Header: 'Area (Sqm)',
            accessor: 'area',
          },
          {
            Header: 'Total Expenditure till date (in Lakh)',
            accessor: 'total_exp_till_date',
          },
          {
            Header: 'Physical Progress till date',
            accessor: 'physical_progress_td',
          },
        ],
        []
      );
  
  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: apiData, // Assuming data is an array with one element containing the data array.
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc', // Add border style here
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
  };

  return (
    <>
      <div style={{ width: '100%', overflowX: 'auto' }}>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                    style={{
                      borderBottom: '2px solid #4e2683',
                      background: '#4F2D7F', // Add grey background color
                      fontWeight: 'bold', // Optionally make text bold
                      // border: '2px solid #ccc', // Add border style here
                      color:'white',
                      padding:'15px'
                    }}>
                  {column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          border: '1px solid #ccc',
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> 
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            
          </tbody>
          </table>
      </div>
    </>
  );
};


