import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ErrorBoundary } from "react-error-boundary";
import { ServerPage } from "./ErrorPage/ServerPage";
import { OutputTableThreeUtilizationTPD } from "../components/OutputThreeImpactForm/OutputTableUtilizationTPD";
import { OutputTableThreeUtilizationColdStorage } from "../components/OutputThreeImpactForm/OutputTableUtilizationColdStorage";

export const OutputPage3ImpactForms = ({
  divi,
  currentQuter,
  finiYear,
  currentFiniYear,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [mainTabValue, setMainTabValue] = useState("0");

  const [permission, setPermission] = useState([]);

  const [divisions, setDivisions] = useState([]);
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [currentFinancialYear, setCurrentFinancialYear] = useState("");

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  useEffect(() => {
    async function getData() {
      setFinancialYear(finiYear);
      setCurrentFinancialYear(currentFiniYear);
      setCurrentQuarter(currentQuter);
      setDivisions(divi);
    }
    getData();
    const storedArrayAsString = localStorage.getItem("permission");
    setPermission(storedArrayAsString);
  }, [divi, currentQuter, finiYear, currentFiniYear]);
  return (
    <>
      <ErrorBoundary FallbackComponent={<ServerPage />}>
        <section class="section">
          <div class="row">
            <div
              id="exTab3"
              class="contain"
              style={{
                width: "80%",
                position: "absolute",
                right: "0rem",
                top: "3.5rem",
              }}
            >
              <div class="card">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={mainTabValue}>
                    <TabList
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      onChange={handleMainTabChange}
                    >
                     {
                        permission.includes("If-Utilisation-Facility-tpd-Output-Three-view") ? (
                          <Tab label="Utilization of Facility - TPD" value="0" className={`${mainTabValue === '0' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("If-Utilisation-Facility-Cold-Storage-Output-Three-view") ? (
                          <Tab label="Utilization of Facility - Cold Storage" value="1" className={`${mainTabValue === '1' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                     
                    </TabList>
                  </TabContext>
                  <br />

                  {/*Utilization - TPD*/}
                  {permission.includes("If-Utilisation-Facility-tpd-Output-Three-view") && mainTabValue === "0" && (
                    <TabContext>
                      <TabPanel>
                        <OutputTableThreeUtilizationTPD
                          setDivision={divisions}
                          currentQuarter={currentQuarter}
                          financialYear={financialYear}
                          currentFinancialYear={currentFinancialYear}
                        />
                      </TabPanel>
                    </TabContext>
                  )}
                  {/*Utilization - COld Storage*/}
                  {permission.includes("If-Utilisation-Facility-Cold-Storage-Output-Three-view") && mainTabValue === "1" && (
                    <TabContext>
                      <TabPanel>
                        <OutputTableThreeUtilizationColdStorage
                          setDivision={divisions}
                          currentQuarter={currentQuarter}
                          financialYear={financialYear}
                          currentFinancialYear={currentFinancialYear}
                        />
                      </TabPanel>
                    </TabContext>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
      </ErrorBoundary>
      
    </>
  );
};
