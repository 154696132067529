import "../assets/css/LoginPage.css";
import logo from "../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export const RegisterPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.state;

  const apiUrl = process.env.REACT_APP_API_URL;
  const [role, setRole] = useState();
  const [divisionHead, setDivisionHead] = useState(false);
  const [userRole, setUserRole] = useState();
  const [division, setDivision] = useState([]);
  const [selectDivision, setSelectedDivision] = useState([]);
  const [editUserFlag, setEditUserFlag] = useState(false);
  const [status, setStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [securityStatus, setSecurityStatus] = useState("");
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [questionIds, setQuestionIds] = useState({});

  const name = useRef(null);
  const number = useRef(null);
  const password = useRef(null);
  const password_confirmation = useRef(null);
  const email = useRef(null);
  const mobile = useRef(null);

  const handleRole = (value) => {
    setUserRole(value);
    if (value.value == 2) {
      setDivisionHead(true);
    } else {
      setDivisionHead(false);
    }
  };

  const handleDivision = (value) => {
    setSelectedDivision(value);
  };

  const toggleStatus = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleSaveUser = async () => {
    if (
      !name.current.value ||
      !mobile.current.value ||
      !email.current.value ||
      !password.current.value ||
      !password_confirmation.current.value ||
      !userRole ||
      (divisionHead && selectDivision.length === 0)||
      !answers[0] ||
      !answers[1] ||
      !answers[2]// Check if divisionHead is true and selectDivision is empty
    ) {
      // Find the first empty field
      let emptyField;
      if (!name.current.value) {
        emptyField = "Name";
      } else if (!mobile.current.value) {
        emptyField = "Mobile";
      } else if (!email.current.value) {
        emptyField = "Email";
      } else if (!password.current.value) {
        emptyField = "Password";
      } else if (!password_confirmation.current.value) {
        emptyField = "Confirm Password";
      } else if (!userRole) {
        emptyField = "Role";
      } else if (divisionHead && selectDivision.length === 0) {
        // Check if divisionHead is true and selectDivision is empty
        emptyField = "Division";
      }

     
      // Display SweetAlert message with the empty field name
      Swal.fire(
        `${emptyField} field is required. Please fill in all fields.`,
        "",
        "warning"
      );
      let ans;
      if (!answers[0]) {
        ans = "Answer to Security Question 1"; 
      }
      else if (!answers[1]) {
        ans = "Answer to Security Question 2"; 
      }
      else if (!answers[2]) {
        ans = "Answer to Security Question 3"; 
      }
      Swal.fire(
        `${ans}`,
        "",
        "warning"
      );
      return;
    }
    try {
      // Construct the user object based on input values
      const obj = {
        name: name.current.value,
        mobile: mobile.current.value,
        email: email.current.value,
        password: password.current.value,
        password_confirmation: password_confirmation.current.value,
        usertype: userRole.label, // Assuming this is correctly set
        division_id: selectDivision.map((e) => e.value).join(","),
        question_id_one: questionIds[0] || "",
        answer_one: answers[0] || "",
        question_id_two: questionIds[1] || "",
        answer_two: answers[1] || "",
        question_id_three: questionIds[2] || "",
        answer_three: answers[2] || "",
      };

      // Send a POST request to register the user
      const response = await axios.post(`${apiUrl}api/register`, obj);

      // Check the response status and handle accordingly
      if (response.data["status"] === true) {
        // Display success message
        Swal.fire({
          title: "Registered Successfully",
          text: "Let's wait for the account activation from the admin user.",
          icon: "success",
        }).then((result) => {
          // Navigate to login page after clicking "OK"
          if (result.isConfirmed || result.isDismissed) {
            navigate("/login");
          }
        });
      } else {
        // Check if details array exists and has elements
        if (
          response.data.details &&
          response.data.details.length > 0 &&
          (response.data.details[0].name ||
            response.data.details[0].mobile ||
            response.data.details[0].email ||
            response.data.details[0].password)
        ) {
          Swal.fire(
            `${
              response.data.details[0].name ||
              response.data.details[0].mobile ||
              response.data.details[0].email ||
              response.data.details[0].password
            }`,
            "",
            "warning"
          );
        } else {
          Swal.fire("An unknown error occurred.", "", "error");
        }
      }
    } catch (error) {
      // Log any errors that occur during the API request
      console.error("Error:", error);
      // Handle the error, e.g., display a generic error message
      Swal.fire("An error occurred. Please try again later.", "", "error");
    }
  };

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,
    }));
  };

  useEffect(() => {
    async function getData() {
      const divisi = await axios.get(
        `${apiUrl}api/get-all-divisions-without-login`
      );
      const getRole = await axios.get(
        `${apiUrl}api/get-all-roles-without-login?all=1`
      );
      const divi = divisi.data.data["data"].map((e) => ({
        value: e["id"],
        label: e["divisions"],
      }));
      const rol = getRole.data["data"].map((e) => ({
        value: e["id"],
        label: e["name"],
      }));

      const response = await axios.get(
        `${apiUrl}api/get-random-questions?all=1`
      );
      const SecurityQuestionsData = response.data["data"].map((e, index) => ({
        id: e["id"],
        question: e["question"],
        index: index,
      }));
      setSecurityQuestions(SecurityQuestionsData);

      const questionIds = {};
      SecurityQuestionsData.forEach((question, index) => {
        questionIds[index] = question.id;
      });
      setQuestionIds(questionIds);
      setDivision(divi);
      setRole(rol);
      console.log(response, "respo");
    }

    getData();
    console.log(getData, "dattttaaaa");
  }, []);
  return (
    <>
      
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div
             
              style={{
                width: "50%",
                position: "absolute",
                right: "25rem",
                left: "23rem",
                top: "5rem",
              }}
            >
              <div
                class="card"
                style={{ height: "1150px", backgroundColor: "#F8F8F8" }}
              >
                {/* Add Beneficiary/Back */}
                <div>
                  <div>
                    <form
                      class="row g-3"
                      style={{
                        marginLeft: "1rem",
                        marginTop: "2rem",
                        marginRight: "0rem",
                      }}
                    >
                      <div
                        class="col-12"
                        style={{ textAlign: "center", marginTop: "-1rem" }}
                      >
                        <img src={logo} alt="Logo" height="100px" />
                        <h3>Register</h3>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="inputNanme4" class="form-labels">
                          Your Name
                        </label>
                        <div class="col-9">
                          <input
                            type="text"
                            ref={name}
                            class="form-control"
                            id="inputNanme4"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="mobile" class="form-labels">
                          Mobile
                        </label>
                        <div class="col-9">
                          <input
                            type="number"
                            ref={mobile}
                            class="form-control"
                            id="mobile"
                        
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="email" class="form-labels">
                          Email
                        </label>
                        <div class="col-9">
                          <input
                            type="email"
                            ref={email}
                            class="form-control"
                            id="email"
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="password" class="form-labels">
                          Password
                        </label>
                        <div class="col-9">
                          <input
                            type="password"
                            ref={password}
                            class="form-control"
                            id="password"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="confirmPassword" class="form-labels">
                          Confirm Password
                        </label>
                        <div class="col-9">
                          <input
                            type="password"
                            ref={password_confirmation}
                            class="form-control"
                            id="confirmPassword"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label for="role" class="form-labels">
                          Role
                        </label>
                        <div class="col-9">
                          <Select
                            options={role}
                            value={userRole ? userRole : null}
                            onChange={handleRole}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {divisionHead === true ? (
                          <>
                            <label>Division:</label>
                            <div class="col-9">
                              <Select
                                options={division}
                                isMulti
                                value={selectDivision}
                                onChange={handleDivision}
                              />
                              <div></div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      
                      <div style={{ marginLeft: "0rem" }}>
                        <p
                          style={{
                            marginLeft: "0.7rem",
                            marginBottom: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          Security Questions
                        </p>

                        {securityQuestions.map((question, index) => (
                          <div
                            className="col-7"
                            key={index}
                            style={{ marginBottom: "0.3rem" }}
                          >
                            <p style={{ marginBottom: "0rem" }}>
                              {question.question}
                            </p>

                            <input
                              style={{ marginTop: "0.3rem" ,width:'150%'}}
                              type="text"
                              value={answers[index] || ""}
                              onChange={(e) =>
                                handleAnswerChange(index, e.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                        ))}
                      </div>
                    </form>
                    <button
                      style={{
                        backgroundColor: "#4e2683",
                        fontWeight: "bold",
                        color: "white",
                        padding: "5px 25px",
                        marginLeft: "247px",
                        marginTop: "20px",
                        borderRadius: "25px",
                      }}
                      onClick={handleSaveUser}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      
    </>
  );
};
