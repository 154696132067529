/* eslint-disable default-case */
import '../assets/css/inputForm.css'
import { DatePick } from "../subcomponents/DatePick"
import { InputField } from "../subcomponents/InputField"
import pdf from '../assets/PDF_file_icon.svg.png'
import imgIcon from '../assets/image_icon-icons.com_50366.png'
import React, { useState } from 'react';
import { updateApiDataToResearch, resetDataResearch, setValidationErrorsResearch } from '../redux/slice/InputOne/ResearchDev/researchDevSlice';
import { updateApiDataToInnovation, resetDataInnovation, setValidationErrorsInnovation } from '../redux/slice/InputOne/InnovationPack/InnovationSlice';
import { updateApiDataTobiowaste, resetDatabiowaste, setValidationErrorsbiowaste } from '../redux/slice/InputOne/BioWaste/BioWasteSlice';
import { updateApiDataToIntro, resetDataIntro, setValidationErrorsIntro } from '../redux/slice/InputOne/IntoToVariety/IntroVarietySlice';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { getConfigWithToken } from '../utils/Config/Config';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setInputFieldData } from '../redux/slice/InputTwo/MasterSubSlice';
import { ResearchDev } from '../subcomponents/Input1/Input1ResearchDev/ResearchDev'
import { InnovationPackaging } from '../subcomponents/Input1/Input1InnovationPackaging/InnovationPackaging'
import { IntroVariety } from '../subcomponents/Input1/Intput1IntroVariety/IntroVariety'
import { BioWaste } from '../subcomponents/Input1/Input1BioWaste/BioWaste'
import { MultiSelect } from '../subcomponents/MultiSelect'

export const MouBasedForm = ({quarter, magentCrp, applicantDta}) =>{

    const apiUrl = process.env.REACT_APP_API_URL;
    const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingg, setloadingg] = useState(true);
    const [value, setValue] = useState('17');
    const [quarters, setQuarter] = useState([])
    const [selectQuarter, setSelectedQuarters] = useState("")

    const [magentCrop, setMagentCrop] = useState([])
    const [researchDevFlagEdit, setResearchDevFlagEdit] = useState(false)
    const [researchDevIdEdit, setResearchDevIdEdit] = useState("")
    const [beniFiApplicant, setBeniApplicant] = useState([])
    const [selectbeniFiApplicant, setSelectBeniApplicant] = useState("")
    const [errorSelectBeniApplicant, setErrorSelectBeniApplicant] = useState("")

    // Files State
    const [editInput1Files, setEditInput1Files] = useState([])
    const [selectCapacityFile, setSelectCapacityFile] = useState([])
    const [errorCapacityFile, setErrorCapacityFile] = useState("")

    // setting beneficiary
    const [nurseryTissueBeneficiary, setNurseryTissueBeneficiary] = useState([])
    const [permission, setPermission] = useState([])
    const dd = useSelector(setInputFieldData);

    const checkFPOSelect = () =>{
        if(selectbeniFiApplicant.length === 0){
            setErrorSelectBeniApplicant("Please Select Applicant")
        }else{
            return true
        }
        return false
    }

    const resetAllState = () => {
        setSelectBeniApplicant("")
        setErrorSelectBeniApplicant("")
        setNurseryTissueBeneficiary([])
        setEditInput1Files([])
        setResearchDevFlagEdit(false)
        dispatch(resetDataResearch())
        dispatch(resetDatabiowaste())
    }

   

    const handleChange = async (event, newValue) => {
        
        setValue(newValue)
        resetAllState()
    };

    const handleMultiFilesGAPChange = (event) => {
        setSelectCapacityFile(event.target.files)
    }

    const handleDeleteFile = async (id) =>{
        const deleteFile = await axios.get(`${apiUrl}api/delete-file-input-one-others?id=${id}`,getConfigWithToken())
        if(deleteFile.data["status"]){
            const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
            setEditInput1Files(newRemarks);
            Swal.fire(
                `File deleted Successfully`,
                '',
                'success'
            )
        }
    }
// Change the API of Applicant with the Form Id and Beneficiary Id
    const handleApplicantDataByFormId = async (applicantId, form_id) => {
        try{
            const beneficiary_by_id = await axios.get(`${apiUrl}api/get_primarydata_by_benificiary?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,getConfigWithToken())
            const data = await beneficiary_by_id.data["data"]
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            setNurseryTissueBeneficiary(data)
            return await data
        }catch(err){

        }
    }

    const handleApplicantDataWithoutComponent = async (applicantId, form_id) => {
        try{
            const beneficiary_by_id = await axios.get(`${apiUrl}api/get_primary_data_for_non_cat_compo_forms?beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,getConfigWithToken())
            const data = await beneficiary_by_id.data["data"]
            data["magnet_crop_id"]?(data["magnet_crop_id"]= JSON.parse(data["magnet_crop_id"]).map((e)=>(e.id))):(data["magnet_crop_id"]=[])
            setNurseryTissueBeneficiary(data)
            return await data
        }catch(err){

        }
    }

    const handleApplicantChangeESIFPO = async (event) =>{
        setErrorSelectBeniApplicant("")
        const applicantId = event.target.value
        setSelectBeniApplicant(applicantId)
        setErrorSelectBeniApplicant("")
        try{
          
            switch(value){
                case '1':
                    handleApplicantDataByFormId(applicantId, 3)
                break;
                case '5':
                    handleApplicantDataByFormId(applicantId, 6)
                break;
                case '6':
                    handleApplicantDataByFormId(applicantId, 2)
                break;
                
                case '8':
                    handleApplicantDataByFormId(applicantId, 17)
                break;
                
                case '12':
                    handleApplicantDataByFormId(applicantId, 23)
                break;
                case '13':
                    handleApplicantDataByFormId(applicantId, 15)
                break;
                case '15':
                    const data = await handleApplicantDataWithoutComponent(applicantId, 19)
                    const downData = await axios.get(`${apiUrl}api/research-dev-edit?beneficiary_id=${applicantId}&form_id=19&quarter=${selectQuarter}`,getConfigWithToken())
                    if(downData.data["status"] === true){
                        const data = downData.data.data[0]
                        downData.data["flag"]?setResearchDevFlagEdit(true):setResearchDevFlagEdit(false)
                        setResearchDevIdEdit(data['id'])
                        setEditInput1Files(data["remarks"])
                        data["payment_tranche"] = data["tranche"]
                        dispatch(updateApiDataToResearch(data))
                    }else{
                        data["payment_tranche"] = data["tranche"]["tranche"]
                        data["tranche_id"] = data["tranche"]["tranche_id"]
                        dispatch(updateApiDataToResearch(data))
                        setEditInput1Files([])
                        setResearchDevFlagEdit(false)
                    }
                break;
                case '16':
                    const dataInova = await handleApplicantDataWithoutComponent(applicantId, 20)
                    const downDataInnovation = await axios.get(`${apiUrl}api/innovation-in-packaging-edit?beneficiary_id=${applicantId}&form_id=20&quarter=${selectQuarter}`,getConfigWithToken())
                    if(downDataInnovation.data["status"] === true){
                        const data = downDataInnovation.data.data[0]
                        downDataInnovation.data["flag"]?setResearchDevFlagEdit(true):setResearchDevFlagEdit(false)
                        setResearchDevIdEdit(data['id'])
                        setEditInput1Files(data["remarks"])
                        data["payment_tranche"] = data["tranche"]
                        dispatch(updateApiDataToInnovation(data))
                    }else{
                        dataInova["payment_tranche"] = dataInova["tranche"]["tranche"]
                        dataInova["tranche_id"] = dataInova["tranche"]["tranche_id"]
                        
                        dispatch(updateApiDataToInnovation(dataInova))
                        setEditInput1Files([])
                        setResearchDevFlagEdit(false)
                    }
                break;
                case '17':
                    const dataIntro = await handleApplicantDataWithoutComponent(applicantId, 21)
                    const downDataIntro = await axios.get(`${apiUrl}api/intro-to-new-variety-edit?beneficiary_id=${applicantId}&form_id=21&quarter=${selectQuarter}`,getConfigWithToken())
                    if(downDataIntro.data["status"] === true){
                        const data = downDataIntro.data.data[0]
                        downDataIntro.data["flag"]?setResearchDevFlagEdit(true):setResearchDevFlagEdit(false)
                        setResearchDevIdEdit(data['id'])
                        setEditInput1Files(data["remarks"])
                        data["payment_tranche"] = data["tranche"]
                        dispatch(updateApiDataToIntro(data))
                    }else{
                        
                        dataIntro["payment_tranche"] = dataIntro["tranche"]["tranche"]
                        dataIntro["tranche_id"] = dataIntro["tranche"]["tranche_id"]
                        dispatch(updateApiDataToIntro(dataIntro))
                        
                        setEditInput1Files([])
                        setResearchDevFlagEdit(false)
                    }
                break;
                case '18':
                    handleApplicantDataByFormId(applicantId, 16)
                break;
                case '19':
                    handleApplicantDataByFormId(applicantId, 26)
                break;
                case '20':
                    const dataBio = await handleApplicantDataWithoutComponent(applicantId, 18)
                    const downDataBio = await axios.get(`${apiUrl}api/bio-west-wealth-edit?beneficiary_id=${applicantId}&form_id=18&quarter=${selectQuarter}`,getConfigWithToken())
                    if(downDataBio.data["status"] === true){
                        const data = downDataBio.data.data[0]
                        downDataBio.data["flag"]?setResearchDevFlagEdit(true):setResearchDevFlagEdit(false)
                        setResearchDevIdEdit(data['id'])
                        setEditInput1Files(data["remarks"])
                        data["payment_tranche"] = data["tranche"]
                        dispatch(updateApiDataTobiowaste(data))
                    }else{
                        dataBio["payment_tranche"] = dataBio["tranche"]["tranche"]
                        dataBio["tranche_id"] = dataBio["tranche"]["tranche_id"]
                        // 
                        dispatch(updateApiDataTobiowaste(dataBio))
                        setEditInput1Files([])
                        setResearchDevFlagEdit(false)
                    }
                break;

            }


        }catch(error){
            Swal.fire(
                `${error.message}`,
                "",
                "warning"
              );
        }
    }
    
    const refershdata = () => {
        setSelectBeniApplicant('');
        setNurseryTissueBeneficiary('');

        dispatch(resetDataIntro({}));
        dispatch(resetDataResearch({}));
        dispatch(resetDataInnovation({}));
        dispatch(resetDatabiowaste({}));
        
        setEditInput1Files([]);
        setSelectCapacityFile([]);
        setErrorCapacityFile('');
    }
    const handleQuarterChange = async (event) =>{
        refershdata()
        setResearchDevFlagEdit("")
        setSelectedQuarters(event.target.value)
    }

    const handleResearchSubmit = async (e) => {
        e.preventDefault()

        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["researchDev"];
            const errorMessages = {
                beneficiary_exp_incurred: 'Promoter Expenditure Incurred is required',
                grant_exp_incurred:'Term Loan Expenditure Incurred is required',
                exp_incurred: 'Expenditure Incurred is Required'
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsResearch(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const TissueCulture = dd["payload"]["researchDev"]
                
                const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === id);
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };

                const formData = new FormData();
                 for (const key in TissueCulture) {
                    formData.append(key, TissueCulture[key]!==null?TissueCulture[key]:"");
                  }

                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('magnet_crop_id', JSON.stringify(resultMagnetCrop["data"]));
                  formData.append('sub_project_title', nurseryTissueBeneficiary.sub_project_title);
                  formData.append('signing_date', nurseryTissueBeneficiary.signing_date);
                  formData.append('form_id', '19');

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }


                try{
                    if(!researchDevFlagEdit){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false);
                            return
                        }
                        const submitNurseryDevData = await axios.post(`${apiUrl}api/research-dev-create`, formData, getConfigWithToken())
                        if(submitNurseryDevData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataResearch())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", researchDevIdEdit);
                        const submitNurseryData = await axios.post(`${apiUrl}api/research-dev-update`, formData, getConfigWithToken())
                        
                        if(submitNurseryData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataResearch())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleBioWasteSubmit = async (e) => {
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["bioWaste"]
           
            const errorMessages = {
                beneficiary_exp_incurred: 'Promoter Expenditure Incurred is required',
                grant_exp_incurred:'Term Loan Expenditure Incurred is required',
                exp_incurred: 'Expenditure Incurred is Required'
            };
          

            for (const field in errorMessages) {
                const value = formData[field];
               
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
           
            dispatch(setValidationErrorsbiowaste(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const BioWaste = dd["payload"]["bioWaste"]
                
                const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === id);
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };

                const formData = new FormData();
                 for (const key in BioWaste) {
                    formData.append(key, BioWaste[key]!==null?BioWaste[key]:"");
                  }

                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('magnet_crop_id', JSON.stringify(resultMagnetCrop["data"]));
                  formData.append('sub_project_title', nurseryTissueBeneficiary.sub_project_title);
                  formData.append('signing_date', nurseryTissueBeneficiary.signing_date);
                  formData.append('form_id', '18');

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }


                try{
                    if(!researchDevFlagEdit){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false);
                             return
                        }
                        const submitNurseryDevData = await axios.post(`${apiUrl}api/bio-west-wealth-create`, formData, getConfigWithToken())
                        if(submitNurseryDevData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDatabiowaste())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", researchDevIdEdit);
                        const submitNurseryData = await axios.post(`${apiUrl}api/bio-west-wealth-update`, formData, getConfigWithToken())
                        
                        if(submitNurseryData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDatabiowaste())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleInnovationSubmit = async (e) => {
        e.preventDefault()

        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["innovation"];
            const errorMessages = {
                beneficiary_exp_incurred: 'Promoter Expenditure Incurred is required',
                grant_exp_incurred:'Term Loan Expenditure Incurred is required',
                exp_incurred: 'Expenditure Incurred is Required'
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsInnovation(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const Innovation = dd["payload"]["innovation"]
                
                const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === id);
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };

                const formData = new FormData();
                 for (const key in Innovation) {
                    formData.append(key, Innovation[key]!==null?Innovation[key]:"");
                  }

                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('magnet_crop_id', JSON.stringify(resultMagnetCrop["data"]));
                  formData.append('sub_project_title', nurseryTissueBeneficiary.sub_project_title);
                  formData.append('signing_date', nurseryTissueBeneficiary.signing_date);
                  formData.append('form_id', '20');

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false)
                    return
                }


                try{
                    if(!researchDevFlagEdit){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false)
                            return
                        }
                        const submitNurseryDevData = await axios.post(`${apiUrl}api/innovation-in-packaging-create`, formData, getConfigWithToken())
                        if(submitNurseryDevData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataInnovation())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", researchDevIdEdit);
                        const submitNurseryData = await axios.post(`${apiUrl}api/innovation-in-packaging-update`, formData, getConfigWithToken())
                        
                        if(submitNurseryData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataInnovation())
                                setLoading(false);
                                });
                        }else{
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleIntroVarietySubmit = async (e) => {
        // 
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const formData = dd["payload"]["introVariety"];
            const errorMessages = {
                beneficiary_exp_incurred: 'Promoter Expenditure Incurred is required',
                grant_exp_incurred:'Term Loan Expenditure Incurred is required',
                exp_incurred: 'Expenditure Incurred is Required'
            };

            for (const field in errorMessages) {
                const value = formData[field];
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    errors[field] = errorMessages[field];
                }
            }
            dispatch(setValidationErrorsIntro(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        if(checkFPOSelect() && validateForm()){
            try{
                setLoading(true)
                const Innovation = dd["payload"]["introVariety"]
                
                const jsonMagnetCrop = nurseryTissueBeneficiary["magnet_crop_id"].map(id => {
                    const matchingObject = magentCrop.find(item => item.id === id);
                    if (matchingObject) {
                      return {
                        id: matchingObject.id,
                        name: matchingObject.crop,
                      };
                    }
                    return null; // Handle cases where there's no matching ID
                  }).filter(item => item !== null); // Remove any null entries
                
                const resultMagnetCrop = { data: jsonMagnetCrop };

                const formData = new FormData();
                 for (const key in Innovation) {
                    formData.append(key, Innovation[key]!==null?Innovation[key]:"");
                  }

                  formData.append('quarter', selectQuarter);
                  formData.append('beneficiary_id', selectbeniFiApplicant);
                  formData.append('district_id', nurseryTissueBeneficiary.district.district_id);
                  formData.append('division_id', nurseryTissueBeneficiary.divisions.division_id);
                  formData.append('taluka_id', nurseryTissueBeneficiary.taluka.taluka_id);
                  formData.append('beneficiary_type_id', nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id);
                  formData.append('magnet_crop_id', JSON.stringify(resultMagnetCrop["data"]));
                  formData.append('sub_project_title', nurseryTissueBeneficiary.sub_project_title);
                  formData.append('signing_date', nurseryTissueBeneficiary.signing_date);
                  formData.append('variety_name', nurseryTissueBeneficiary.variety_name);
                  formData.append('form_id', '21');

                
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectCapacityFile.length !== 0){
                    for(let i=0; i<selectCapacityFile.length;i++){
                        size += selectCapacityFile[i].size
                        formData.append("remarks[]", selectCapacityFile[i])
                    }
                }
                if(size >= maxSize){
                    setErrorCapacityFile("File size should be less than 5 MB")
                    setLoading(false)
                    return
                }


                try{
                    if(!researchDevFlagEdit){
                    // if(true){
                        if(selectCapacityFile.length === 0){
                            setErrorCapacityFile("Please Select File")
                            setLoading(false)
                            return
                        }
                        const submitNurseryDevData = await axios.post(`${apiUrl}api/intro-to-new-variety-create`, formData, getConfigWithToken())
                        if(submitNurseryDevData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataIntro())
                            resetAllState()
                                setLoading(false);
                                });
                            
                        }else{
                            Swal.fire(
                                `${submitNurseryDevData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        // also have to set Id in object
                        formData.append("id", researchDevIdEdit);
                        const submitNurseryData = await axios.post(`${apiUrl}api/intro-to-new-variety-update`, formData, getConfigWithToken())
                        
                        if(submitNurseryData.data["status"] === true){
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataIntro())
                            resetAllState()
                                setLoading(false);
                                });
                           
                        }else{
                            Swal.fire(
                                `${submitNurseryData.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                }catch(error){
                    Swal.fire(
                        `${error.message}`,
                        "",
                        "warning"
                      );
                }
            }catch(error){
                Swal.fire(
                    `${error.message}`,
                    "",
                    "warning"
                  );
            }
        }
    }

    const handleDeleteIntroVariety = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
        if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/intro-to-new-variety-delete?id=${researchDevIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setResearchDevFlagEdit("");
              dispatch(resetDataIntro());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
    
      const handleDeleteResDev = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
        if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/research-dev-delete?id=${researchDevIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setResearchDevFlagEdit("");
              dispatch(resetDataResearch());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
      const handleDeleteInnovation = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
        if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/innovation-in-packaging-delete?id=${researchDevIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setResearchDevFlagEdit("");
              dispatch(resetDataInnovation());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteBioWaste= async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
        if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/bio-west-wealth-delete?id=${researchDevIdEdit}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setResearchDevFlagEdit("");
              dispatch(resetDatabiowaste());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
              });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
    useEffect(()=>{
        const quarterData = async () => {
            try {
                const storedArrayAsString = localStorage.getItem('permission');
              setPermission(storedArrayAsString)

              setQuarter(quarter);
            const selectedValues = quarter.filter(item => item.selected === "Selected").map(item => item.id);
              setSelectedQuarters(selectedValues[0])
              setMagentCrop(magentCrp)
              
              setBeniApplicant(applicantDta); // Pass applicantData to setApplicant
              setloadingg(false);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          
          quarterData();
        
        setloadingg(false);
    },[quarter, applicantDta, magentCrp])


    if (loadingg) {
        return (<>
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box>
        </>);
      }

    return(<>
       
            <main id="main" class="main">
            <section class="section">
            <div class="row">
                <div id="exTab3" class="contain">	
                
                <div class="card">
                <Box sx={{ width: '100%', typography: 'body1', bgcolor: 'background.paper' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange} sx={{ width: '100%', overflowX: 'auto' }}>
                            
                            <Tab label="Intro to New Variety" value="17" className={`${value === '17' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Research Dev" value="15" className={`${value === '15' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Innovation & Packaging" value="16" className={`${value === '16' ? 'activeClickCSS' : ''}`}/>
                            <Tab label="Bio Waste" value="20" className={`${value === '20' ? 'activeClickCSS' : ''}`}/>

                        </Tabs>
                        </Box>
                        
                        {/* Research Dev */}
                        <TabPanel value='15'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleResearchSubmit}>
                                    <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                            <MultiSelect label="MAGNET Crops" data={magentCrop} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.magnet_crop_id ? nurseryTissueBeneficiary.magnet_crop_id :[] } readOnly={true}/>
                                        </div>
                                    <InputField label="Sub Project Title" type="text" value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.sub_project_title ? nurseryTissueBeneficiary.sub_project_title :''} readOnly={true} />
                                    <DatePick label="MOUSigningdate" className="custom-date-picker" disbrushDate={nurseryTissueBeneficiary && nurseryTissueBeneficiary.signing_date ? nurseryTissueBeneficiary.signing_date.toString(): ''} readOnly={true}/>
                                   
                                    <ResearchDev/>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    
                                    <div style={{ textAlign: "center" }}>
                                    {
                                        permission.includes("Research-Dev-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn"  disabled={loading}>{researchDevFlagEdit === true ? "Update":"Submit"}</button>
                                        {researchDevFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteResDev}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* Innovation & Packaging */}
                        <TabPanel value='16'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleInnovationSubmit}>
                                    <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                            <MultiSelect label="MAGNET Crops" data={magentCrop} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.magnet_crop_id ? nurseryTissueBeneficiary.magnet_crop_id :[] } readOnly={true}/>
                                        </div>
                                    <InputField label="Sub Project Title" type="text" value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.sub_project_title ? nurseryTissueBeneficiary.sub_project_title :''} readOnly={true} />
                                    <DatePick label="MOUSigningdate" className="custom-date-picker" disbrushDate={nurseryTissueBeneficiary && nurseryTissueBeneficiary.signing_date ? nurseryTissueBeneficiary.signing_date.toString(): ''} readOnly={true}/>
                                   
                                    <InnovationPackaging/>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    
                                    <div style={{ textAlign: "center" }}>
                                    {
                                        permission.includes("Innovation-Packaging-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn"  disabled={loading}>{researchDevFlagEdit === true ? "Update":"Submit"}</button>
                                        {researchDevFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteInnovation}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* Intro to New Variety */}
                        <TabPanel value='17'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleIntroVarietySubmit}>
                                    <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                        <MultiSelect label="MAGNET Crops" data={magentCrop} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.magnet_crop_id ? nurseryTissueBeneficiary.magnet_crop_id :[] } readOnly={true}/>
                                    </div>
                                    <InputField label="Variety Name" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.variety_name ? nurseryTissueBeneficiary.variety_name.toString(): ''}/>
                                    <InputField label="Sub Project Title" type="text" value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.sub_project_title ? nurseryTissueBeneficiary.sub_project_title :''} readOnly={true} />
                                    <DatePick label="MOUSigningdate" className="custom-date-picker" disbrushDate={nurseryTissueBeneficiary && nurseryTissueBeneficiary.signing_date ? nurseryTissueBeneficiary.signing_date.toString(): ''} readOnly={true}/>
                                    
                                    <IntroVariety/>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    
                                    <div style={{ textAlign: "center" }}>
                                       {
                                        permission.includes("Intro-To-New-Var-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn"  disabled={loading}>{researchDevFlagEdit === true ? "Update":"Submit"}</button>
                                        {researchDevFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading}  onClick={handleDeleteIntroVariety}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </TabPanel>

                        {/* BioWaste */}
                        <TabPanel value='20'>
                            <div className="tab-pane">
                                <form class="row g-3" onSubmit={handleBioWasteSubmit}>
                                    <div className="quarterSelect">
                                            <div className='support'>
                                            </div>
                                            <div className='col-md-4' style={{paddingRight:0}}>
                                                <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="word-with-line">
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorSelectBeniApplicant !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectbeniFiApplicant}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(beniFiApplicant).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["beneficiary_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorSelectBeniApplicant}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.divisions ? nurseryTissueBeneficiary.divisions["divisions"].toString(): ''}/>
                                    <InputField label="District" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.district ? nurseryTissueBeneficiary.district["district"].toString(): ''}/>
                                    <InputField label="Taluka" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.taluka ? nurseryTissueBeneficiary.taluka["taluka"].toString(): ''}/>
                                    <InputField label="Beneficiary Type" type="text" readOnly={true} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.beneficiary_type ? nurseryTissueBeneficiary.beneficiary_type["beneficiary_type"].toString(): ''}/>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                            <MultiSelect label="MAGNET Crops" data={magentCrop} value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.magnet_crop_id ? nurseryTissueBeneficiary.magnet_crop_id :[] } readOnly={true}/>
                                        </div>
                                    <InputField label="Sub Project Title" type="text" value={nurseryTissueBeneficiary && nurseryTissueBeneficiary.sub_project_title ? nurseryTissueBeneficiary.sub_project_title :''} readOnly={true} />
                                    <DatePick label="MOUSigningdate" className="custom-date-picker" disbrushDate={nurseryTissueBeneficiary && nurseryTissueBeneficiary.signing_date ? nurseryTissueBeneficiary.signing_date.toString(): ''} readOnly={true}/>
                                    
                                   
                                    <BioWaste/>
                                    <div style={{    display: "flex", alignItems: "center"}}>
                                        <div class="col-md-6" style={{position: "relative", right: "15px", bottom: "10px"}}>
                                            <label for="inputNumber" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                                <div class="col-sm-10">
                                                <input class="form-control" type="file" id="formFile" name="remarks" multiple onChange={handleMultiFilesGAPChange} onFocus={()=>{setErrorCapacityFile("")}}/>
                                                <span style={{color: 'red'}}>{errorCapacityFile}</span>
                                                </div>
                                        </div>
                                        <div>
                                                {(editInput1Files || []).map((e)=>(
                                                    <div className="icon-container">
                                                        <a href={`${fileUrl}${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                                        </a>
                                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleDeleteFile(e.id)}></i>
                                                    </div>
                                                ))
                                                }   
                                        </div>
                                    </div>
                                    
                                    <div style={{ textAlign: "center" }}>
                                    {
                                        permission.includes("Bio-Waste-Inp-One-create-edit") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn"  disabled={loading}>{researchDevFlagEdit === true ? "Update":"Submit"}</button>
                                        {researchDevFlagEdit === true && (
                                        <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteBioWaste}>
                                            Delete
                                        </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </TabPanel>

                    </TabContext>
                </Box>
                </div>
            </div>

            </div>
        </section>
        </main>
     
    </>)
}