import React from "react";
import { Box } from "@mui/material";
import { Header } from "../../../Header";
import { LeftNavigation } from "../../../LeftNavigation";
import { Link } from "react-router-dom";
import { EntityMasterListingTable } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityListingTable";
import '../../../../assets/css/masterdata.css'
import Button from '@mui/material/Button';

export const EntityMasterListing = () => {
  return(<>
    <Header/>
    <LeftNavigation/>
    <main id="main" class="main">
            <section class="section">
                <div class="row">
                    <div id="exTab3" class="contain" >	           
                        <div class="cardMaster" id="" style={{height:'max-content'}}>
                            <div className="words-with-lines" id="Heading" >
                            <br/>
                              <span>Entity Master</span>
                            </div>
                            {/* Add FIL/Back */}
                            <Box className='AddHeader' sx={{ typography: 'body1', }}>                
                            <div className="topNavCSSButton"> 
                                <div className="col-md-6"></div>
                                <div className="col-md-6 buttonCheck" >
                                    <a href="./masterdata" class="back-link" style={{marginRight:'1rem'}}>
                                        <i class="fa-solid fa-arrow-left"></i>Back
                                    </a>
                                    <Link to="/entitymasterdetails">
                                        <Button class="add-fil-button">
                                            Add Entity
                                        </Button>
                                    </Link>
                                    <button class="caret-button"><i class="fa-solid fa-caret-down"></i></button>
                                </div>
                            </div>
                            </Box>   
                           
                            {/* Listing Table */}
                            <EntityMasterListingTable/>
                        </div>
                    </div> 
                </div> 
            </section>
        </main>
    </>)
};
