// routes/index.js
import React from 'react';
import { PermifyProvider } from "@permify/react-role";
import {Route, Routes} from 'react-router-dom';
import { LoginPage } from '../pages/LoginPage';
import {MainDash} from '../pages/MainDash';
import { ForgotPage } from '../pages/ForgotPage';
import PrivateRoute from './PrivateRoute'
import { ResetPage } from '../pages/ResetPassword';
import { MasterDataPage } from '../pages/MasterDataPage';
import { NotFoundPage } from '../pages/ErrorPage/NotFoundPage';
import { UserList } from '../pages/UserList';
import { RoleList } from '../pages/RoleList';

// Attributes
import { MSAMBMasterAttribute } from '../components/MasterForm/Attributes/MSAMBMaster';
import { ComponentAttribute } from '../components/MasterForm/Attributes/AttibuteComponents';
import { BeneficiaryTypeAttribute } from '../components/MasterForm/Attributes/BeneficiaryType';
import { WorkPackageAttribute } from '../components/MasterForm/Attributes/WorkPackage';
import { DistrictAttribute } from '../components/MasterForm/Attributes/DistrictAttribute';
import { DivisionAttribute } from '../components/MasterForm/Attributes/DivisionAttribute';
import { TalukaAttribute } from '../components/MasterForm/Attributes/TalukaAttribute';
import { MagnetCropAttribute } from '../components/MasterForm/Attributes/MagnetCrops';
import { FacilityTypeAttribute } from '../components/MasterForm/Attributes/Facility Type';
import { EntityTypeAttribute } from '../components/MasterForm/Attributes/EntityType';
import { PowerSourceAttribute } from '../components/MasterForm/Attributes/PowerSource';
import { TechnicalComponentAttribute } from '../components/MasterForm/Attributes/TechCompInput1';
import { PaymentTrancheAttribute } from '../components/MasterForm/Attributes/PaymentTranche';
import { LoanTypeAttribute } from '../components/MasterForm/Attributes/Loantype';

// Primary Tables
import { MGBeneficiaryMasterListing } from '../components/MasterForm/PrimaryTable/MGBeneficiaryMaster/BeneficiaryListing';
import { AddBeneficiaryMaster } from '../components/MasterForm/PrimaryTable/MGBeneficiaryMaster/AddBeneficiary';

import { MSAMBMasterListing } from '../components/MasterForm/PrimaryTable/MSAMBMaster/MSAMBMasterListing';
import { AddBeneficiaryMSAMBMaster } from '../components/MasterForm/PrimaryTable/MSAMBMaster/MSAMBAddBeneficiary';

import { EntityMasterListing } from '../components/MasterForm/PrimaryTable/EntityMaster/EntityListing';
import { AddEntityMaster } from '../components/MasterForm/PrimaryTable/EntityMaster/AddEntityMaster';
import { AddUser } from '../components/User/AddUser';
import { AddRole } from '../components/User/AddRole';
import { InputForms } from '../pages/InputForms';
import { FILDetailListing } from '../components/MasterForm/PrimaryTable/FILMaster/FilDetailsListing';
import { AddFilMaster } from '../components/MasterForm/PrimaryTable/FILMaster/AddFil';
import { OutputForms } from '../pages/OutputForms';

// import { BeneficiaryListAttribute } from '../components/MasterForm/Attributes/BeneficiaryListAttribute';

import { EntityListAttribute } from '../components/MasterForm/Attributes/EntityListAttribute';
import { ApplicantListAttribute } from '../components/MasterForm/Attributes/ApplicantListAttribute';
import { GAPListing } from '../components/MasterForm/PrimaryTable/GAPMaster/GAPListing';
import { AddGAPMaster } from '../components/MasterForm/PrimaryTable/GAPMaster/AddGAPMaster';
import { CapacityBuildingListing } from '../components/MasterForm/PrimaryTable/CapacityBuildingMaster/CapacityBuildingList';
import { AddCapacityBuilding } from '../components/MasterForm/PrimaryTable/CapacityBuildingMaster/AddCapacityBuilding';
import { BankAttribute } from '../components/MasterForm/Attributes/BankAttribute';
import { RegisterPage } from '../pages/RegisterPage';
import { MyProfile } from '../pages/MyProfile';
import { CategoryAttribute } from '../components/MasterForm/Attributes/CategoryList';
import { SubCategoryAttribute } from '../components/MasterForm/Attributes/SubCategoryList';
import { ExisitingMagnetCropAttribute } from '../components/MasterForm/Attributes/ExistingMagnetCrops';
import { PasswordRecoveryAttribute } from '../components/MasterForm/Attributes/PasswordRecAttribute';
import SecurityQuestionsPage from '../pages/SecurityQuestionsPage';


const AppRouter = () => {
    return (
        <PermifyProvider>
          <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path='/forgotPassword' element={<ForgotPage/>}/>
            <Route path='/register' element={<RegisterPage/>}/>
            <Route path='/resetPassword' element={<ResetPage/>}/>
            <Route path='/VerifySQ' element={<ResetPage/>}/>
            <Route path='/myprofile' element={<MyProfile/>}/>
            <Route path="/security-questions" element={<SecurityQuestionsPage />} />
            <Route path="/dashboard" element={<PrivateRoute><MainDash /></PrivateRoute>} />
            <Route path='/inputform1-capacityBuilding' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform1-eoiBased' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform1-mouBased' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform1-invitationBased' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform1-events' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform1-physicalInput' element={<PrivateRoute><InputForms /></PrivateRoute>}/>

            <Route path='/inputform2' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform2impactforms' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform3impactforms' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/inputform3' element={<PrivateRoute><InputForms /></PrivateRoute>}/>
            <Route path='/outputpage2' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            <Route path='/outputpage2impactforms' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            <Route path='/outputpage3impactforms' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            <Route path='/outputpage1' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            <Route path='/outputpage3' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            <Route path='/filoutputreport' element={<PrivateRoute><OutputForms /></PrivateRoute>}/>
            {/* Add more routes as needed */}
            <Route path='/masterdata' element={<PrivateRoute><MasterDataPage /></PrivateRoute>}/>
            <Route path='/userlist' element={<PrivateRoute><UserList /></PrivateRoute>}/>
            <Route path='/rolelist' element={<PrivateRoute><RoleList /></PrivateRoute>}/>
            

            {/* Add User */}
            <Route path='/addUser' element={<PrivateRoute><AddUser /></PrivateRoute>}/>
            <Route path='/addRole' element={<PrivateRoute><AddRole /></PrivateRoute>}/>
            <Route path='/*' element={<PrivateRoute><NotFoundPage /></PrivateRoute>}/>

            {/* Master forms Attributes */}
            <Route path='/components' element={<PrivateRoute><ComponentAttribute /></PrivateRoute>}/>
            <Route path='/paymentTranche' element={<PrivateRoute><PaymentTrancheAttribute /></PrivateRoute>}/>
            <Route path='/magnetcrops' element={<PrivateRoute><MagnetCropAttribute /></PrivateRoute>}/>
            <Route path='/exisitngmagnetcrop' element={<PrivateRoute><ExisitingMagnetCropAttribute /></PrivateRoute>}/>
            <Route path='/beneficiary-type' element={<PrivateRoute><BeneficiaryTypeAttribute /></PrivateRoute>}/>
            <Route path='/msamb' element={<PrivateRoute><MSAMBMasterAttribute /></PrivateRoute>}/>
            <Route path='/workpackage' element={<PrivateRoute><WorkPackageAttribute /></PrivateRoute>}/>
            <Route path='/facilities' element={<PrivateRoute><FacilityTypeAttribute /></PrivateRoute>}/>
            <Route path='/entitytype' element={<PrivateRoute><EntityTypeAttribute /></PrivateRoute>}/>
            <Route path='/technicalcomponent' element={<PrivateRoute><TechnicalComponentAttribute /></PrivateRoute>}/>
            <Route path='/applicantlist' element={<PrivateRoute><ApplicantListAttribute /></PrivateRoute>}/>
            <Route path='/entitylist' element={<PrivateRoute><EntityListAttribute /></PrivateRoute>}/>
            <Route path='/loantype' element={<PrivateRoute><LoanTypeAttribute /></PrivateRoute>}/>
            <Route path='/powersource' element={<PrivateRoute><PowerSourceAttribute /></PrivateRoute>}/>
            <Route path='/district' element={<PrivateRoute><DistrictAttribute /></PrivateRoute>}/>
            <Route path='/division' element={<PrivateRoute><DivisionAttribute /></PrivateRoute>}/>
            <Route path='/taluka' element={<PrivateRoute><TalukaAttribute /></PrivateRoute>}/>
            <Route path='/bank' element={<PrivateRoute><BankAttribute /></PrivateRoute>}/>
            <Route path='/category' element={<PrivateRoute><CategoryAttribute /></PrivateRoute>}/>
            <Route path='/subcategory' element={<PrivateRoute><SubCategoryAttribute/></PrivateRoute>}/>
            <Route path='/passwordrecoveryQ' element={<PrivateRoute><PasswordRecoveryAttribute/></PrivateRoute>}/>
            

            {/* Master forms Primary */}
            <Route path='/MG-beneficiary-master' element={<PrivateRoute><MGBeneficiaryMasterListing /></PrivateRoute>}/>
            <Route path='/input1entitymaster' element={<PrivateRoute><EntityMasterListing /></PrivateRoute>}/>
            <Route path='/GAP-Master' element={<PrivateRoute><GAPListing /></PrivateRoute>}/>
            <Route path='/capacityBuildingMaster' element={<PrivateRoute><CapacityBuildingListing /></PrivateRoute>}/>
            <Route path='/MSAMB-Master' element={<PrivateRoute><MSAMBMasterListing /></PrivateRoute>}/>
            <Route path='/FIL-Master' element={<PrivateRoute>< FILDetailListing/></PrivateRoute>}/>


            <Route path='/MG-beneficiary' element={<PrivateRoute><AddBeneficiaryMaster /></PrivateRoute>}/>
            <Route path='/AddFil' element={<PrivateRoute><AddFilMaster /></PrivateRoute>}/>
            <Route path='/addBeneficiaryMSAMBMaster' element={<PrivateRoute><AddBeneficiaryMSAMBMaster /></PrivateRoute>}/>
            <Route path='/entitymasterdetails' element={<PrivateRoute><AddEntityMaster /></PrivateRoute>}/>
            <Route path='/addGAPDetail' element={<PrivateRoute><AddGAPMaster /></PrivateRoute>}/>
            <Route path='/addCapacityBuildingMaster' element={<PrivateRoute><AddCapacityBuilding /></PrivateRoute>}/>

          </Routes>
        </PermifyProvider>

      );
};

export default AppRouter;
