import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export function MultiSelect(props) {
  // const theme = useTheme();
  // const [personName, setPersonName] = React.useState([]);
  const workShop = useSelector(state => state.workShop);
  
  const handleChange = (event) => {
    props.onChange(event.target.value)
  };

  
  return (
    <div>
      <FormControl fullWidth error={props.errorText}>
        <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel>
        <Select
          // label={props.label}
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={props.value}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          MenuProps={MenuProps}
          readOnly={props.readOnly?true:false}
        >
          {props.data.map((e) => (
            <MenuItem
              key={e.id}
              value={e.id}
              // style={getStyles(e.crop, personName, theme)}
            >
              {e.crop}
            </MenuItem>
          ))}
        </Select>
        {/* <FormHelperText>{workShop.validationErrors.location_taluka}</FormHelperText> */}
      </FormControl>
    </div>
  );
}