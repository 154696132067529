import React, { useMemo, useState } from 'react';
import { useTable} from 'react-table';
import axios from 'axios';
import AxiosRetry from 'axios-retry';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { getConfigWithToken } from '../../utils/Config/Config';
import { useNavigate } from 'react-router-dom';

export const UserListing = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([])

  const handleEdit = (id) => {
    // Logic to handle editing based on the id
    
    navigate('/addUser',{state:id})
  };
  
  const handleDelete = (id) => {
    // Logic to handle deleting based on the id
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{
          const deleteUser = await axios.get(`${apiUrl}api/user-delete?id=${id}`,getConfigWithToken())
          if(deleteUser.data["status"] === true){
              Swal.fire({
                  title: "Deleted!",
                  text: "Attribute has been deleted.",
                  icon: "success"
                });
          }
          const updatedAttribute = tableData.filter((e)=>(e.id !== id))
          
          setTableData(updatedAttribute)
        }catch{
          Swal.fire(
              `Getting Error While Deleting`,
              '',
              'warning'
          )
        }
      }
    });
  };
  
  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });
  let count = 0

    const columns = useMemo(
        () => [
          {
            Header: 'Sr. No.',
            accessor: 'id',
          },
          {
            Header: 'Name', 
            accessor: 'name',
          },
          {
            Header: 'Email', 
            accessor: 'email',
          },
          {
            Header: 'Status',
            accessor: 'status',
          
          },
          {
            Header: 'Action', 
            accessor: 'actionId',
            Cell: ({ value }) => (
              <div>
                <i class="fa-solid fa-pen-to-square" onClick={()=>handleEdit(value)}
                    style={{ cursor: "pointer", color: "#4e2683" }}></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                <i className="fa-regular fa-trash-can" onClick={()=> handleDelete(value)}
                  style={{ cursor: "pointer", color: "#4e2683" }}></i>
              </div>
            ),
          }
        ],
        []
      );
  
  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData, // Assuming data is an array with one element containing the data array.
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc', // Add border style here
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
  };

  useEffect(()=>{
    try {
     async function getData(){
      const getAllUser = await axios.get(`${apiUrl}api/get-all-users`, getConfigWithToken())
      
      const getdb = getAllUser.data.data.map((e)=>{
        return {
          ...e,
          actionId: e.id
        }
      })
      setTableData(getdb)
      setLoading(false);
    }
    
    setTimeout(() => {
      
      getData()
      // setLoading(false);
    }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire(
          'An refresh the Page',
          '',
          'error'
      );
      } else {
        throw new Error('Data Not Found');
      }
    }
  },[])
  
  return (
    <>
     
        <div className="row" style={{ width: '90%', marginLeft:"5rem", marginTop:"1rem", typography: 'body1'}}>
            <table {...getTableProps()} className="my-table">
              <thead>
                  {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                      {headerGroup.headers.map((column) => (
                          <th
                          {...column.getHeaderProps()}
                          style={{
                            borderBottom: '2px solid #4e2683',
                            background: '#4F2D7F', // Add grey background color
                            fontWeight: 'bold', // Optionally make text bold
                            // border: '2px solid #ccc', // Add border style here
                            color:'white',
                            padding:'15px'
                          }}
                          >
                          {column.render('Header')}
                          </th>
                      ))}
                      </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                      prepareRow(row);
                      return (
                      <tr
                          {...row.getRowProps()}
                          style={{ borderBottom: '1px solid #ccc', backgroundColor: '#fff' }} // Add border style here
                      >
                          {row.cells.map((cell) => {
                            count += 1
                          return (
                              <td
                              {...cell.getCellProps()}
                              style={{
                                  padding: '8px',
                                  // border: '1px solid #ccc', // Add border style here
                              }}
                              >
                               {cell.column.Header === 'Status' ? (
                      cell.value === 'active' ? (
                        <span className="badge bg-success"><i className="bi bi-check-circle me-1"></i>Active</span>
                      ) : cell.value === 'pending' ? (
                        <span className="badge bg-warning text-dark"><i className="bi bi-exclamation-triangle me-1"></i> Pending</span>
                      ) : (
                        <span className="badge bg-danger"><i className="bi bi-x-circle me-1"></i> Inactive</span>
                      )
                    ) : (
                      cell.render('Cell')
                    )}
                              </td>
                          );
                          })}
                      </tr>
                      );
                  })}
              </tbody>
          </table>
      </div>
    </>
  );
};


