import React, { useState } from "react";
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import {
  Tab,
  Tabs,
  Box,
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  colors,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import clsx from "clsx";
import "../../../../assets/css/masterdata.css";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import axios from "axios";
import { setInputFieldData } from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import {
  resetDataBioWasteMaster,
  updateApiDataBioWasteMaster,
} from "../../../../redux/slice/EntityMaster/BioWasterMasterSlice";
import {
  resetDataHDPMaster,
  updateApiDataHDPMaster,
} from "../../../../redux/slice/EntityMaster/HDPMasterSlice";
import {
  resetDataInnovationMaster,
  updateApiDataInnovationMaster,
} from "../../../../redux/slice/EntityMaster/InnvoationMasterSlice";
import {
  resetDataIntroductionVarietyMaster,
  updateApiDataIntroductionVarietyMaster,
} from "../../../../redux/slice/EntityMaster/IntroductionNewVarietyMasterSlice";
import {
  resetDataMarketAssMaster,
  updateApiDataMarketAssMaster,
} from "../../../../redux/slice/EntityMaster/MarketDevAssisMasterSlice";
import {
  resetDataNurseryMaster,
  updateApiDataNurseryMaster,
} from "../../../../redux/slice/EntityMaster/NurseryDevMasterSlice";
import {
  resetDataOtherCertiMaster,
  updateApiDataOtherCertiMaster,
} from "../../../../redux/slice/EntityMaster/OtherCertificationCostMasterSlice";
import {
  resetDataProdPlantMaster,
  updateApiDataProdPlantMaster,
} from "../../../../redux/slice/EntityMaster/ProductionPlantMasterSlice";
import {
  resetDataResearchDevMaster,
  updateApiDataResearchDevMaster,
} from "../../../../redux/slice/EntityMaster/RandDDevMasterSlice";
import {
  resetDataSupportCommMaster,
  updateApiDataSupportCommMaster,
} from "../../../../redux/slice/EntityMaster/SupportCommMasterSlice";
import {
  resetDataTissueMaster,
  updateApiDataTissueMaster,
} from "../../../../redux/slice/EntityMaster/TissueCultureMasterSlice";
import {
  resetDataEntityMaster,
  updateApiDataEntityMaster,
} from "../../../../redux/slice/EntityMaster/EntityMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EntityBioWaste } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/BioWasteEntity";
import { EntityDetailsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityDetails";
import { EntityHDP } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityHDP";
import { EntityIntroNewVaritiesDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/IntroNewVarities";
import { EntityInnovationPackComm } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/InnovationPackComm";
import { EntityNurseryDevDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/NurseryDevDetails";
import { EntityTissueCultureDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/TissueCultureDetails";
import { EntityRandDNewProdDev } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/RandDEntity";
import { EntitySuppComNewTech } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/SuppComNewTech";
import { EntityMarketDevAssTable } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityTables/MarkDevAssTable";
import { EntityOtherCertificationCostTable } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityTables/OtherCertCostTable";
import { EntityProdPlantCertTable } from "../../../../subcomponents/MasterFormTables/PrimaryTables/EntityMaster/EntityTables/ProdPlantCertTable";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export const AddEntityMaster = () => {
  const location = useLocation();
  const id = location.state;

  const dd = useSelector(setInputFieldData);
  const dispatch = useDispatch();
  const [tabDisabled, setTabDisabled] = useState(false);
  const [value, setValue] = useState("0");
  const [disabled, setDisabled] = useState(false);
  const [checkedPanels, setCheckedPanels] = useState(Array(14).fill(false));
  const [newCheckValue, setNewCheckValue] = useState([]);
  const [prevCheckValue, setPrevCheckValue] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [Applicant, setApplicant] = useState([]);
  const [editEntityFlag, setEditEntityFlag] = useState(false);
  const [Division, setDivision] = useState([]);
  const [MagnetCrop, setMagentCrop] = useState([]);
  const [BeneficiaryType, setBeneficiaryType] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [LoanType, setLoanType] = useState([]);
  const [handleBioWateData, setHandleBioWateData] = useState({});

  const handleBioWasteActivity = (childInputActivity) => {
    setHandleBioWateData(childInputActivity);
    console.log(handleBioWateData, "handleEntityDatahandleEntityData");
  };

  const bioWasteMaster = useSelector((state) => state.bioWasteMaster["18"]);
  const hdpMaster = useSelector(state => state.hdpMaster["6"]);
  const introVarietyMaster = useSelector(state => state.introVarietyMaster["21"]);
  const innovationMaster = useSelector(state => state.innovationMaster["20"]);
  const researchDevMaster = useSelector(state => state.researchDevMaster["19"]);

  const validateDates = () => {
    const date1 = new Date(bioWasteMaster.actual_date_1);
    const date2 = new Date(bioWasteMaster.actual_date_2);
    const date3 = new Date(bioWasteMaster.actual_date_3);
    const date4 = new Date(bioWasteMaster.actual_date_4);

    const hdpDate1 = new Date(hdpMaster.actual_date_1);
    const hdpDate2 = new Date(hdpMaster.actual_date_2);
    const hdpDate3 = new Date(hdpMaster.actual_date_3);
    const hdpdate4 = new Date(hdpMaster.actual_date_4);

    const NewVarDate1 = new Date(introVarietyMaster.actual_date_1);
    const NewVarDate2 = new Date(introVarietyMaster.actual_date_2);
    const NewVarDate3 = new Date(introVarietyMaster.actual_date_3);
    const Newvardate4 = new Date(introVarietyMaster.actual_date_4);

    const Innovationdate1 = new Date(innovationMaster.actual_date_1);
    const InnovationDate2 = new Date(innovationMaster.actual_date_2);
    const InnovationDate3 = new Date(innovationMaster.actual_date_3);
    const InnovationDate4 = new Date(innovationMaster.actual_date_4);

    const resDevdate1 = new Date(researchDevMaster.actual_date_1);
    const resDevDate2 = new Date(researchDevMaster.actual_date_2);
    const resDevDate3 = new Date(researchDevMaster.actual_date_3);
    const resDevDate4 = new Date(researchDevMaster.actual_date_4);

    // bioWaste
    if (
      !bioWasteMaster.actual_date_1 &&
      (bioWasteMaster.actual_date_2 ||
        bioWasteMaster.actual_date_3 ||
        bioWasteMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T1 is mandatory before entering other dates",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (
      !bioWasteMaster.actual_date_2 &&
      (bioWasteMaster.actual_date_3 || bioWasteMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (!bioWasteMaster.actual_date_3 && bioWasteMaster.actual_date_4) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (date2 < date1) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (date3 < date1 || date3 < date2) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }
    if (date4 < date1 || date4 < date2 || date4 < date3) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }
    
// HDP
    if (
      !hdpMaster.actual_date_1 &&
      (hdpMaster.actual_date_2 ||
        hdpMaster.actual_date_3 ||
        hdpMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T1 is mandatory before entering other dates",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (
      !hdpMaster.actual_date_2 &&
      (hdpMaster.actual_date_3 || hdpMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
      });
      setDisabled(true);
      setTabDisabled(true);
      return false;
    }

    if (!hdpMaster.actual_date_3 && hdpMaster.actual_date_4) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
      });
       setDisabled(true);
      setTabDisabled(true);

      return false;
    }

    if (hdpDate2 < hdpDate1) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
      });
       setDisabled(true);
      setTabDisabled(true);

      return false;
    }

    if (hdpDate3 < hdpDate1 || hdpDate3 < hdpDate2) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
      });
       setDisabled(true);
      setTabDisabled(true);

      return false;
    }
    if (hdpdate4 < hdpDate1 || hdpdate4 < hdpDate2 || hdpdate4 < hdpDate3) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    // New Varities
    if (
      !introVarietyMaster.actual_date_1 &&
      (introVarietyMaster.actual_date_2 ||
        introVarietyMaster.actual_date_3 ||
        introVarietyMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T1 is mandatory before entering other dates",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (
      !introVarietyMaster.actual_date_2 &&
      (introVarietyMaster.actual_date_3 || introVarietyMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (!introVarietyMaster.actual_date_3 && introVarietyMaster.actual_date_4) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (NewVarDate2 < NewVarDate1) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (NewVarDate3 < NewVarDate1 || NewVarDate3 < NewVarDate2) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }
    if (Newvardate4 < NewVarDate1 || Newvardate4 < NewVarDate2 || Newvardate4 < NewVarDate3) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
      });
     setDisabled(true);
       setTabDisabled(true);      
       return false;
    }

    // Innovation
    if (
      !innovationMaster.actual_date_1 &&
      (innovationMaster.actual_date_2 ||
        innovationMaster.actual_date_3 ||
        innovationMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T1 is mandatory before entering other dates",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (
      !innovationMaster.actual_date_2 &&
      (innovationMaster.actual_date_3 || innovationMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (!innovationMaster.actual_date_3 && innovationMaster.actual_date_4) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (InnovationDate2 < Innovationdate1) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (InnovationDate3 < Innovationdate1 || InnovationDate3 < InnovationDate2) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
      });
       setDisabled(true);
       setTabDisabled(true);
      return false;
    }
    if (InnovationDate4 < Innovationdate1 || InnovationDate4 < InnovationDate2 || InnovationDate4 < InnovationDate3) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
      });
       setDisabled(true);
       setTabDisabled(true);      
       return false;
    
    }
    // ReserachDev
    if (
      !researchDevMaster.actual_date_1 &&
      (researchDevMaster.actual_date_2 ||
        researchDevMaster.actual_date_3 ||
        researchDevMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T1 is mandatory before entering other dates",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (
      !researchDevMaster.actual_date_2 &&
      (researchDevMaster.actual_date_3 || researchDevMaster.actual_date_4)
    ) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (!researchDevMaster.actual_date_3 && researchDevMaster.actual_date_4) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (resDevDate2 < resDevdate1) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }

    if (resDevDate3 < resDevdate1 || resDevDate3 < resDevDate2) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
      });
     setDisabled(true);
       setTabDisabled(true);
      return false;
    }
    if (resDevDate4 < resDevdate1 || resDevDate4 < resDevDate2 || resDevDate4 < resDevDate3) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
      });
     setDisabled(true);
       setTabDisabled(true);      
       return false;
    }
    setDisabled(false);
  
    return true;
  };

  const handleChange = (event, newValue) => {
    if (validateDates()) {
      setValue(newValue);
    }
  };

  const entityChangeFunction = async (id) => {
    resetData();
    setPrevCheckValue([]);
    setNewCheckValue([]);
    const panelNamesTron = {
      "Bio-Waste to Wealth": "18",
      "Demonstration on High density Plantation (HDP)": "6",
      "Introduction of New Varieties": "21",
      "Innovation in packing in all 11 commodities": "20",
      "Market development assistance": "17",
      "Nursery Development": "4",
      "Other certification cost for sustainable sourcing of raw materials":
        "15",
      "Production Plant Certification compliance cost (GMP, HACCP, etc.)": "16",
      "R&D for new product development in focused value chain": "19",
      "Support for commercialization of new technology": "26",
      "Tissue culture": "5",
    };
    const editData = await axios.get(
      `${apiUrl}api/input-one-primary-data-edit?beneficiary_id=${id}`,
      getConfigWithToken()
    );
    editData.data["flag"] ? setEditEntityFlag(true) : setEditEntityFlag(false);
    if (editData.data.status === true) {
      const { form_id, ...newObj } = editData.data["data"][0];

      dispatch(updateApiDataEntityMaster(newObj));
      const editDta = editData.data["data"][0]["form_id"];
      console.log(editDta, "zkxnvkdsnzvndsnv");

      function updateMagnetCropId(obj) {
        const processValue = (value) => {
          if (Array.isArray(value)) {
            return value.map((item) => processValue(item));
          } else if (typeof value === "object" && value !== null) {
            for (const key in value) {
              if (value.hasOwnProperty(key)) {
                value[key] = processValue(value[key]);
              }
            }
            if ("magnet_crop_id" in value) {
              try {
                const magnetCropIdArray = JSON.parse(value.magnet_crop_id);
                if (Array.isArray(magnetCropIdArray)) {
                  const ids = magnetCropIdArray.map((item) => item.id);
                  value.magnet_crop_id = ids;
                } else {
                  value.magnet_crop_id = [];
                }
              } catch (error) {
                // If parsing as JSON fails or if it's an empty object, treat it as an empty array
                value.magnet_crop_id = [];
              }
            }
          }
          return value;
        };

        return processValue(obj);
      }

      // Iterate through each object and update magnet_crop_id
      for (const key in editDta) {
        if (editDta.hasOwnProperty(key)) {
          editDta[key] = updateMagnetCropId(editDta[key]);
        }
      }

      console.log(editDta, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");

      const panelNames = [
        "Bio-Waste to Wealth",
        "Demonstration on High density Plantation (HDP)",
        "Introduction of New Varieties",
        "Innovation in packing in all 11 commodities",
        "Market development assistance",
        "Nursery Development",
        "Other certification cost for sustainable sourcing of raw materials",
        "Production Plant Certification compliance cost (GMP, HACCP, etc.)",
        "R&D for new product development in focused value chain",
        "Support for commercialization of new technology",
        "Tissue culture",
      ];

      const eee = Object.keys(editDta);
      console.log(eee, "dcdsddssdcsddddd99999999999999999");
      setPrevCheckValue(eee);
      const keysFromPanelNamesTron = eee.map((value) =>
        Object.keys(panelNamesTron).find((key) => panelNamesTron[key] === value)
      );
      console.log(
        keysFromPanelNamesTron,
        "keysFromPanelNamesTronkeysFromPanelNamesTron"
      );

      const indices = keysFromPanelNamesTron.map((key) =>
        panelNames.indexOf(key)
      );
      console.log(indices, "keysFromPanelNamesTronkeysFromPanelNamesTron");

      const updatedCheckedPanels = checkedPanels.map((value, index) =>
        indices.includes(index) ? true : false
      );
      console.log(
        updatedCheckedPanels,
        "updatedCheckedPanelsupdatedCheckedPanels"
      );
      setCheckedPanels(updatedCheckedPanels);

    
      const bioWaste = editDta["18"];
      const hdp = editDta["6"];
      const introVariety = editDta["21"];
      const innovation = editDta["20"];
      const marketAssistence = editDta["17"];
      const nurseryDev = editDta["4"];
      const otherCertification = editDta["15"];
      const productionPlant = editDta["16"];
      const rdev = editDta["19"];
      const commerNewTech = editDta["26"];
      const tissueCulture = editDta["5"];

      console.log(introVariety, "marketAssistencemarketAssistence");

      const updateAllMasters = () => {
        return async (dispatch) => {
          if (bioWaste) {
            dispatch(updateApiDataBioWasteMaster(bioWaste));
          }
          if (hdp) {
            dispatch(updateApiDataHDPMaster(hdp));
          }
          if (introVariety) {
            dispatch(updateApiDataIntroductionVarietyMaster(introVariety));
          }
          if (innovation) {
            dispatch(updateApiDataInnovationMaster(innovation));
          }
          if (nurseryDev) {
            dispatch(updateApiDataNurseryMaster(nurseryDev));
          }
          if (rdev) {
            dispatch(updateApiDataResearchDevMaster(rdev));
          }
          if (commerNewTech) {
            dispatch(updateApiDataSupportCommMaster(commerNewTech));
          }
          // Dispatch calls for special cases like market assistance, other certification, production plant
          if (marketAssistence) {
            dispatch(
              updateApiDataMarketAssMaster({
                category_id: 17,
                typedata: marketAssistence.typedata,
              })
            );
          }
          if (otherCertification) {
            dispatch(
              updateApiDataOtherCertiMaster({
                category_id: 15,
                typedata: otherCertification.typedata,
              })
            );
          }
          if (productionPlant) {
            dispatch(
              updateApiDataProdPlantMaster({
                category_id: 16,
                typedata: productionPlant.typedata,
              })
            );
          }
          if (tissueCulture) {
            dispatch(updateApiDataTissueMaster(tissueCulture));
          }
        };
      };

      // Dispatch the combined action using dispatch(updateAllMasters());
      dispatch(updateAllMasters());

      console.log(dd["payload"], "kjds nkjcndsjvndskjn");
    }
  };

  function getFormIdFromName(value) {
    const panelNamesTron = {
      "Bio-Waste to Wealth": "18",
      "Demonstration on High density Plantation (HDP)": "6",
      "Introduction of New Varieties": "21",
      "Innovation in packing in all 11 commodities": "20",
      "Market development assistance": "17",
      "Nursery Development": "4",
      "Other certification cost for sustainable sourcing of raw materials":
        "15",
      "Production Plant Certification compliance cost (GMP, HACCP, etc.)": "16",
      "R&D for new product development in focused value chain": "19",
      "Support for commercialization of new technology": "26",
      "Tissue culture": "5",
    };

    return panelNamesTron[value];
  }

  const resetData = () => {
    dispatch(resetDataBioWasteMaster());
    dispatch(resetDataHDPMaster());
    dispatch(resetDataInnovationMaster());
    dispatch(resetDataIntroductionVarietyMaster());
    dispatch(resetDataMarketAssMaster());
    dispatch(resetDataNurseryMaster());
    dispatch(resetDataOtherCertiMaster());
    dispatch(resetDataProdPlantMaster());
    dispatch(resetDataResearchDevMaster());
    dispatch(resetDataSupportCommMaster());
    dispatch(resetDataTissueMaster());
    dispatch(resetDataEntityMaster());
  };

  const handleCheckboxChange = (panelIndex) => {
    const panelName = panelNames[panelIndex];
    const idForm = getFormIdFromName(panelName);
    setNewCheckValue((prevArray) => [...prevArray, idForm]);
    if (checkedPanels[panelIndex]) {
      // If the checkbox is currently unchecked, show the confirmation alert
      MySwal.fire({
        title: "Are you sure?",
        text: `Do you want to Remove the ${panelName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Remove it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed, update the checkbox state
          updateCheckbox(panelIndex);
        }
      });
    } else {
      // If the checkbox is currently checked, update the state directly
      updateCheckbox(panelIndex);
    }
  };

  const updateCheckbox = (panelIndex) => {
    setCheckedPanels((prev) => {
      const updatedCheckedPanels = [...prev];
      updatedCheckedPanels[panelIndex] = !prev[panelIndex];
      return updatedCheckedPanels;
    });

    // Close the SweetAlert (if it is open)
    MySwal.close();
  };

  function getJSONData(value) {
    console.log(value, "jdsbfjdsbijf");
    const jsonMagnetCrop = value
      .map((id) => {
        const matchingObject = MagnetCrop.find(
          (item) => item.id === Number(id)
        );
        if (matchingObject) {
          return {
            id: matchingObject.id,
            name: matchingObject.crop,
          };
        }
        return null; // Handle cases where there's no matching ID
      })
      .filter((item) => item !== null);
    return jsonMagnetCrop;
  }


  const handleSaveChange = async (e) => {
    e.preventDefault();

    console.log(prevCheckValue, "dcdsddssdcsddddd99999999999999999");
    console.log(newCheckValue, "fdsknvkjfv");

    const panelNamesTron = {
      "Bio-Waste to Wealth": "18",
      "Demonstration on High density Plantation (HDP)": "6",
      "Introduction of New Varieties": "21",
      "Innovation in packing in all 11 commodities": "20",
      "Market development assistance": "17",
      "Nursery Development": "4",
      "Other certification cost for sustainable sourcing of raw materials":
        "15",
      "Production Plant Certification compliance cost (GMP, HACCP, etc.)": "16",
      "R&D for new product development in focused value chain": "19",
      "Support for commercialization of new technology": "26",
      "Tissue culture": "5",
    };

    console.log(dd["payload"]["entityMaster"]);
    const obj = {
      ...dd["payload"]["bioWasteMaster"],
      ...dd["payload"]["hdpMaster"],
      ...dd["payload"]["nurseryDevMaster"],
      ...dd["payload"]["introVarietyMaster"],
      ...dd["payload"]["innovationMaster"],
      ...dd["payload"]["researchDevMaster"],
      ...dd["payload"]["supportCommMaster"],
      ...dd["payload"]["tissueCultureMaster"],
      ...dd["payload"]["marketAssistenceMaster"],
      ...dd["payload"]["otherCertificationMaster"],
      ...dd["payload"]["prodPlantMaster"],
    };

    const selectedTabs = panelNames.filter(
      (name, index) => checkedPanels[index]
    );
    console.log(selectedTabs, "hfyufyufyuf");

    const objid = selectedTabs.map((e) => panelNamesTron[e]);

    const filteredData = {};

    objid.forEach((key) => {
      if (obj[key]) {
        filteredData[key] = obj[key];
      }
    });
    console.log(objid, "kkkkkkkkkkkkkkkkkkkkkkk");

    console.log(filteredData, "kjsbckjnsncoids");
    if (filteredData["17"]) {
      filteredData["17"] = filteredData["17"]["typedata"][0];
    }
    if (filteredData["15"]) {
      filteredData["15"] = filteredData["15"]["typedata"][0];
    }
    if (filteredData["16"]) {
      filteredData["16"] = filteredData["16"]["typedata"][0];
    }

    function replaceMagnetCropIdValues(data, getJSONDataFn) {
      const newData = {};

      const processObject = (obj) => {
        const newObj = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
              newObj[key] = processObject(obj[key]);
            } else if (key === "magnet_crop_id") {
              const newValues = getJSONDataFn(obj[key]);
              newObj[key] = newValues;
            } else {
              newObj[key] = obj[key];
            }
          }
        }
        return newObj;
      };

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newData[key] = processObject(data[key]);
        }
      }

      return newData;
    }
    // Call the function to replace values using getJSONData
    const sssss = replaceMagnetCropIdValues(filteredData, getJSONData);
    const ddd = {
      ...dd["payload"]["entityMaster"],
      form_id: {
        ...sssss,
      },
    };

    const payload = {
      ...dd["payload"]["entityMaster"],
      form_id: {
        ...sssss,
      },
    };
    console.log(payload, "idsoidsjojdsoinvoidsnoi");

    const updatedCode = {};
    const newEntry = {};

    // Extract data for firstArr
    prevCheckValue.forEach((key) => {
      if (ddd.form_id[key]) {
        updatedCode[key] = ddd.form_id[key];
        delete ddd.form_id[key];
      }
    });

    // Extract data for lastArr
    newCheckValue.forEach((key) => {
      if (ddd.form_id[key]) {
        newEntry[key] = ddd.form_id[key];
        delete ddd.form_id[key];
      }
    });

    const newUpdatedData = {
      ...dd["payload"]["entityMaster"],
      form_id: {
        ...updatedCode,
      },
      new_entry: newEntry,
    };

    
    if (!editEntityFlag) {
      const entityCreate = await axios.post(
        `${apiUrl}api/input-one-primary-data-create`,
        payload,
        getConfigWithToken()
      );

      if (entityCreate.data["status"] === true) {
        Swal.fire(`${entityCreate.data["message"]}`, "", "success");
      } else {
        Swal.fire(`${entityCreate.data["message"]}`, "", "warning");
      }
    } else {
      const entityCreate = await axios.post(
        `${apiUrl}api/input-one-primary-data-update`,
        newUpdatedData,
        getConfigWithToken()
      );

      if (entityCreate.data["status"] === true) {
        Swal.fire(`${entityCreate.data["message"]}`, "", "success");
      } else {
        Swal.fire(`${entityCreate.data["message"]}`, "", "warning");
      }
    }
  };

  const panelNames = [
    "Bio-Waste to Wealth",
    "Demonstration on High density Plantation (HDP)",
    "Introduction of New Varieties",
    "Innovation in packing in all 11 commodities",
    "Market development assistance",
    "Nursery Development",
    "Other certification cost for sustainable sourcing of raw materials",
    "Production Plant Certification compliance cost (GMP, HACCP, etc.)",
    "R&D for new product development in focused value chain",
    "Support for commercialization of new technology",
    "Tissue culture",
  ];

  const visiblePanelNames = panelNames.filter(
    (_, index) => checkedPanels[index]
  );

  const renderTable = (items) => (
    <TableContainer
      component={Paper}
      style={{
        margin: "16px 5rem 0rem 5rem",
        backgroundColor: "#E2E2E2",
        border: "2px solid #9C9C9C",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: "black",
                fontSize: "18px",
                fontWeight: "700px",
                border: "1px solid #9C9C9C",
              }}
            >
              Forms
            </TableCell>
            <TableCell
              style={{
                color: "black",
                fontSize: "18px",
                fontWeight: "700px",
                border: "1px solid #9C9C9C",
              }}
            >
              Applicability
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((panelName, panelIndex) => (
            <TableRow key={panelIndex}>
              <TableCell style={{ border: "1px solid #9C9C9C" }}>
                {panelName}
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid #9C9C9C",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checked={checkedPanels[panelNames.indexOf(panelName)]}
                  onChange={() =>
                    handleCheckboxChange(panelNames.indexOf(panelName))
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderPanelContent = (panelName) => {
    switch (panelName) {
      case "Bio-Waste to Wealth":
        return (
          <EntityBioWaste
            Magnetcrop={MagnetCrop}
            onInpuChange={handleBioWasteActivity}
            setDisabled={setDisabled}
            validateDates={validateDates}
          />
        );

      case "Demonstration on High density Plantation (HDP)":
        return (
          <EntityHDP
            Magnetcrop={MagnetCrop}
            taluka={taluka}
            setDisabled={setDisabled}
            validateDates={validateDates}
          />
        );

      case "Introduction of New Varieties":
        return (
          <EntityIntroNewVaritiesDetails
            Magnetcrop={MagnetCrop}
            taluka={taluka}
            setDisabled={setDisabled}
            validateDates={validateDates}
          />
        );

      case "Innovation in packing in all 11 commodities":
        return (
          <EntityInnovationPackComm
            Magnetcrop={MagnetCrop}
            setDisabled={setDisabled}
            validateDates={validateDates}
          />
        );

    
      case "Market development assistance":
        return <EntityMarketDevAssTable />;

      case "Nursery Development":
        return (
          <EntityNurseryDevDetails Magnetcrop={MagnetCrop} taluka={taluka} />
        );

      case "Other certification cost for sustainable sourcing of raw materials":
        return <EntityOtherCertificationCostTable />;

      case "Production Plant Certification compliance cost (GMP, HACCP, etc.)":
        return <EntityProdPlantCertTable />;

      case "R&D for new product development in focused value chain":
        return (
          <EntityRandDNewProdDev
            Magnetcrop={MagnetCrop}
            setDisabled={setDisabled}
            validateDates={validateDates}
          />
        );

      case "Support for commercialization of new technology":
        return <EntitySuppComNewTech Magnetcrop={MagnetCrop} />;

      case "Tissue culture":
        return (
          <EntityTissueCultureDetails Magnetcrop={MagnetCrop} taluka={taluka} />
        );

      default:
        return <div>{`${panelName} Content`}</div>;
    }
  };

  useEffect(() => {
    dispatch(resetDataEntityMaster());
    const MGBeneficiaryData = async () => {
      try {
        const applicant = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1`,
          getConfigWithToken()
        );
        const division = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        const Magnetcrop = await axios.get(
          `${apiUrl}api/getAllCrop?all=1`,
          getConfigWithToken()
        );
        const beneficiaryType = await axios.get(
          `${apiUrl}api/get-all-beneficiary-type?all=1`,
          getConfigWithToken()
        );
        const loantype = await axios.get(
          `${apiUrl}api/getLoanType?all=1`,
          getConfigWithToken()
        );
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );

        const applicantData = applicant.data["data"].map((e) => ({
          id: e["id"],
          beneficiary_name: e["beneficiary_name"],
        }));
        const divisionData = division.data["data"].map((e) => ({
          id: e["id"],
          divisions: e["divisions"],
        }));
        const MagnetcropData = Magnetcrop.data["data"].map((e) => ({
          id: e["id"],
          crop: e["crop"],
        }));
        const beneficiaryTypeData = beneficiaryType.data["data"].map((e) => ({
          id: e["id"],
          beneficiary_type: e["beneficiary_type"],
        }));
        const loantypeData = loantype.data["data"].map((e) => ({
          id: e["loan_id"],
          loan_type: e["loan_type"],
        }));

        setApplicant(applicantData);
        setDivision(divisionData);
        setMagentCrop(MagnetcropData);
        setBeneficiaryType(beneficiaryTypeData);
        setLoanType(loantypeData);
        setTaluka(taluka.data.data);

        if (id) {
          const panelNamesTron = {
            "Bio-Waste to Wealth": "18",
            "Demonstration on High density Plantation (HDP)": "6",
            "Introduction of New Varieties": "21",
            "Innovation in packing in all 11 commodities": "20",
            "Market development assistance": "17",
            "Nursery Development": "4",
            "Other certification cost for sustainable sourcing of raw materials":
              "15",
            "Production Plant Certification compliance cost (GMP, HACCP, etc.)":
              "16",
            "R&D for new product development in focused value chain": "19",
            "Support for commercialization of new technology": "26",
            "Tissue culture": "5",
          };
          const editData = await axios.get(
            `${apiUrl}api/input-one-primary-data-edit?beneficiary_id=${id}`,
            getConfigWithToken()
          );
          editData.data["flag"]
            ? setEditEntityFlag(true)
            : setEditEntityFlag(false);
          const { form_id, ...newObj } = editData.data["data"][0];

          dispatch(updateApiDataEntityMaster(newObj));
          const editDta = editData.data["data"][0]["form_id"];
          console.log(editDta, "zkxnvkdsnzvndsnv");

          function updateMagnetCropId(obj) {
            const processValue = (value) => {
              if (Array.isArray(value)) {
                return value.map((item) => processValue(item));
              } else if (typeof value === "object" && value !== null) {
                for (const key in value) {
                  if (value.hasOwnProperty(key)) {
                    value[key] = processValue(value[key]);
                  }
                }
                if ("magnet_crop_id" in value) {
                  try {
                    const magnetCropIdArray = JSON.parse(value.magnet_crop_id);
                    if (Array.isArray(magnetCropIdArray)) {
                      const ids = magnetCropIdArray.map((item) => item.id);
                      value.magnet_crop_id = ids;
                    } else {
                      value.magnet_crop_id = [];
                    }
                  } catch (error) {
                    // If parsing as JSON fails or if it's an empty object, treat it as an empty array
                    value.magnet_crop_id = [];
                  }
                }
              }
              return value;
            };

            return processValue(obj);
          }

          // Iterate through each object and update magnet_crop_id
          for (const key in editDta) {
            if (editDta.hasOwnProperty(key)) {
              editDta[key] = updateMagnetCropId(editDta[key]);
            }
          }

          console.log(editDta, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");

          const panelNames = [
            "Bio-Waste to Wealth",
            "Demonstration on High density Plantation (HDP)",
            "Introduction of New Varieties",
            "Innovation in packing in all 11 commodities",
            "Market development assistance",
            "Nursery Development",
            "Other certification cost for sustainable sourcing of raw materials",
            "Production Plant Certification compliance cost (GMP, HACCP, etc.)",
            "R&D for new product development in focused value chain",
            "Support for commercialization of new technology",
            "Tissue culture",
          ];

          const eee = Object.keys(editDta);
          console.log(eee, "dcdsddssdcsddddd99999999999999999");
          setPrevCheckValue(eee);
          const keysFromPanelNamesTron = eee.map((value) =>
            Object.keys(panelNamesTron).find(
              (key) => panelNamesTron[key] === value
            )
          );

          const indices = keysFromPanelNamesTron.map((key) =>
            panelNames.indexOf(key)
          );

          const updatedCheckedPanels = checkedPanels.map((value, index) =>
            indices.includes(index) ? true : value
          );
          setCheckedPanels(updatedCheckedPanels);
          // const updatedCheckedPanels = Object.keys(panelNamesTron).map(key => panelNamesTron[key] in editDta);
          // setCheckedPanels(updatedCheckedPanels);

          const bioWaste = editDta["18"];
          const hdp = editDta["6"];
          const introVariety = editDta["21"];
          const innovation = editDta["20"];
          const marketAssistence = editDta["17"];
          const nurseryDev = editDta["4"];
          const otherCertification = editDta["15"];
          const productionPlant = editDta["16"];
          const rdev = editDta["19"];
          const commerNewTech = editDta["26"];
          const tissueCulture = editDta["5"];

          console.log(introVariety, "marketAssistencemarketAssistence");

          const updateAllMasters = () => {
            return async (dispatch) => {
              if (bioWaste) {
                dispatch(updateApiDataBioWasteMaster(bioWaste));
              }
              if (hdp) {
                dispatch(updateApiDataHDPMaster(hdp));
              }
              if (introVariety) {
                dispatch(updateApiDataIntroductionVarietyMaster(introVariety));
              }
              if (innovation) {
                dispatch(updateApiDataInnovationMaster(innovation));
              }
              if (nurseryDev) {
                dispatch(updateApiDataNurseryMaster(nurseryDev));
              }
              if (rdev) {
                dispatch(updateApiDataResearchDevMaster(rdev));
              }
              if (commerNewTech) {
                dispatch(updateApiDataSupportCommMaster(commerNewTech));
              }
              // Dispatch calls for special cases like market assistance, other certification, production plant
              if (marketAssistence) {
                dispatch(
                  updateApiDataMarketAssMaster({
                    category_id: 17,
                    typedata: marketAssistence.typedata,
                  })
                );
              }
              if (otherCertification) {
                dispatch(
                  updateApiDataOtherCertiMaster({
                    category_id: 15,
                    typedata: otherCertification.typedata,
                  })
                );
              }
              if (productionPlant) {
                dispatch(
                  updateApiDataProdPlantMaster({
                    category_id: 16,
                    typedata: productionPlant.typedata,
                  })
                );
              }
              if (tissueCulture) {
                dispatch(updateApiDataTissueMaster(tissueCulture));
              }
            };
          };

          // Dispatch the combined action using dispatch(updateAllMasters());
          dispatch(updateAllMasters());

          console.log(dd["payload"], "kjds nkjcndsjvndskjn");
        } else {
          resetData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    MGBeneficiaryData();
  }, []);

  console.log("DISABLED ", disabled);
  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div id="exTab3" className="contain">
              <div className="cardMaster" id="" style={{ height: "1500px" }}>
                <form class="row g-3">
                  <Box className="your-component" sx={{ typography: "body1" }}>
                    <div className="words-with-lines">
                      <span>Entity - Master</span>
                    </div>
                    <div className="msambstyle">
                      <a href="./input1entitymaster">
                        <i className="fa-solid fa-arrow-left"></i>Back
                      </a>
                      <button
                        type="submit"
                        className="save-btn"
                        onClick={handleSaveChange}
                        disabled={disabled}
                      >
                        Save
                      </button>
                      <button className="dropdown-btn" disabled={disabled}>
                        <i className="fa-solid fa-caret-down"></i>
                      </button>
                    </div>
                  </Box>
                  {/* Tabs */}
                  <Box
                    sx={{
                      width: "92%",
                      height: "500px",
                      marginLeft: "5rem",
                      marginTop: "3.5rem",
                      typography: "body1",
                    }}
                  >
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          style={{ width: "70rem" }}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          value={value}
                          onChange={handleChange}
                        >
                          <Tab
                            label="Entity Details"
                            value="0"
                            className={clsx(value === "0" && "activeClickCSS")}
                            style={{
                              border: "2px solid #4e2683",
                              borderRadius: "4px",
                            }}
                          />
                          {visiblePanelNames.map((name, index) => (
                            <Tab
                              key={index}
                              label={name}
                              style={{
                                border: "2px solid #4e2683",
                                borderRadius: "4px",
                              }}
                              value={(index + 1).toString()}
                              className={clsx(
                                value === (index + 1).toString() &&
                                  "activeClickCSS"
                              )}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      <TabPanel value="0">
                        <EntityDetailsMaster
                          id={id}
                          entityChangeFunction={entityChangeFunction}
                          editFlag={editEntityFlag}
                          MagnetCrop={MagnetCrop}
                          Applicant={Applicant}
                          Division={Division}
                          BeneficiaryType={BeneficiaryType}
                        />
                        <br />
                        <Grid container spacing={10}>
                          <Grid item xs={5}>
                            {renderTable(panelNames.slice(0, 6))}
                          </Grid>
                          <Grid item xs={5}>
                            {renderTable(panelNames.slice(6))}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      {visiblePanelNames.map((panelName, panelIndex) => (
                        <TabPanel
                          key={panelIndex}
                          value={(panelIndex + 1).toString()}
                        >
                          <div
                            style={{
                              maxHeight: "200px",
                              maxWidth: "100%",
                              marginBottom: "16px",
                              marginLeft: "2rem",
                            }}
                          >
                            {renderPanelContent(panelName)}
                          </div>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
     </>
  );
};
