
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  msamb_facility_id: '',
  division_id: '',
  district_id:'',
  taluka_id:'',

  work_package_id: '',
  male: '',
  female:'',
  shareholders:'',
  magnet_crop_id:[],
  contract_no:'',
  component:'',
  area:'',
  supplier_contractor_name:'',
  contract_amount:'',
  contract_award_date:null,
  completion_date:null,
  extension_date:null,
  contract_extension:'',
  price_increase:'',
  increased_amount:'',
  total_disbursed_amount:'',
// Facilities
cold_storage:'',
pack_house_tpd:'',
secondary_processing_tpd:'',
tertiary_processing_tpd:'',

  validationErrors:{
    msamb_facility_id: '',
    division_id: '',
    district_id:'',
    taluka_id:'',
    work_package_id: '',
    male: '',
    female:'',
    shareholders:'',
    magnet_crop_id:'',

    contract_no:'',
    component:'',
    area:'',
    supplier_contractor_name:'',
    contract_amount:'',
    contract_award_date:null,
    completion_date:null,
    extension_date:null,
    contract_extension:'',
    price_increase:'',
    increased_amount:'',
    total_disbursed_amount:'',
    // Facilities
    cold_storage:'',
    pack_house_tpd:'',
    secondary_processing_tpd:'',
    tertiary_processing_tpd:'',
  }
};

const MSAMBMasterSlice = createSlice({
  name: 'MSAMBMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataMSAMBMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataMsambMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    
    setValidationErrorsMSAMBMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsMSAMBMaster, resetValidation, setInputFieldData, updateApiDataMSAMBMaster, resetDataMsambMaster } = MSAMBMasterSlice.actions;

export default MSAMBMasterSlice.reducer;
