import { Offline, Online } from "react-detect-offline";
import React from 'react';
import {resetValidation, setInputFieldData} from "../../../../../redux/slice/EntityMaster/HDPMasterSlice";
import { useDispatch, useSelector } from "react-redux";

import swal from "sweetalert2";
import { useEffect } from "react";

export const HDPTable = (props) =>{ 

    const hdpMaster = useSelector(state => state.hdpMaster["6"]);
    const dispatch = useDispatch();

    const validateDates = () => {
        const hdpDate1 = new Date(hdpMaster.actual_date_1);
        const hdpDate2 = new Date(hdpMaster.actual_date_2);
        const hdpDate3 = new Date(hdpMaster.actual_date_3);
        const hdpdate4 = new Date(hdpMaster.actual_date_4);
    
        if (
          !hdpMaster.actual_date_1 &&
          (hdpMaster.actual_date_2 ||
            hdpMaster.actual_date_3 ||
            hdpMaster.actual_date_4)
        ) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T1 is mandatory before entering other dates",
          });
          props.setDisabled(true);
    
          return false;
        }
    
        if (
          !hdpMaster.actual_date_2 &&
          (hdpMaster.actual_date_3 || hdpMaster.actual_date_4)
        ) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T2 is mandatory before entering Actual Disbursement date for T3 and T4",
          });
          props.setDisabled(true);
    
          return false;
        }
    
        if (!hdpMaster.actual_date_3 && hdpMaster.actual_date_4) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T3 is mandatory before entering Actual Disbursement date for T4",
          });
          props.setDisabled(true);
    
          return false;
        }
    
        if (hdpDate2 < hdpDate1) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T2 should be greater than Actual Disbursement date for T1",
          });
          props.setDisabled(true);
    
          return false;
        }
    
        if (hdpDate3 < hdpDate1 || hdpDate3 < hdpDate2) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T3 should be greater than Actual Disbursement date for T1/T2",
          });
          props.setDisabled(true);
    
          return false;
        }
        if (hdpdate4 < hdpDate1 || hdpdate4 < hdpDate2 || hdpdate4 < hdpDate3) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Actual Disbursement date for T4 should be greater than Actual Disbursement date for T1/T2/T3",
          });
          props.setDisabled(true);
          return false;
        }
        props.setDisabled(false);
        return true;
      };
    
      const handleTableInput = (name, value) => {
        dispatch(setInputFieldData({ fieldName: name, value }));
      };
      
      useEffect(() => {
        validateDates();
      }, [hdpMaster]);
      

    const containerStyle = {
        display: "flex",
      };
      const paymenttable = {
        margin: "-7px -7px -8px -6px",
        width: "102%",
        border: "25px white",
        height: "49px",
        padding: "27px",
        
    };
    const cellStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
        backgroundColor: "#E0E0E0",
      };
      const firstTableStyle = {
        marginRight: "20px", // Add margin-right to create space between tables
      };
    const paymentTrancheLabels = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
        backgroundColor: "#E7EFFF",
        fontWeight:"bold",
    }
    const cellStyle1 = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "center",
        backgroundColor: "#E0E0E0",
      };
      const tableStyle = {
        borderCollapse: "collapse",
        width: "100%",
      };  
    return(<>   
    {/* <Online> */}
       <div id="exTab3" class="contain">
       <div style={{marginBottom:'-20px',marginLeft:'3px',fontSize:'13px'}}>
               <p>All Values in Lakhs</p>
            </div>
                   <div style={containerStyle}>
                        <table style={{ ...tableStyle}}>
                        <thead>
                        <tr>
                            <th  colSpan={2} style={{ ...cellStyle,backgroundColor:"#4e2683",color:"white",padding:"10px", fontSize:"20px",textAlign:"center" }}>Payment Tranche</th>
                            
                        </tr>
                        <table style={{ ...tableStyle, ...firstTableStyle }}>
                        <thead>
                        <tr>
                            {/* <th style={cellStyle}>Forms</th>
                            <th style={cellStyle1}>Appicability</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={paymentTrancheLabels}>Matching Grant Tranche 1 Amount</td>
                            <td style={cellStyle1}>
                            <input type="number" name="grant1" style={paymenttable} value={hdpMaster.mg_1st_tranche} onChange={(e)=>handleTableInput("mg_1st_tranche",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Actual Disbursement date for T1</td>
                            <td style={cellStyle1}>
                            <input type="date" name="grant1" style={paymenttable} value={hdpMaster.actual_date_1} onChange={(e)=>handleTableInput("actual_date_1",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Matching Grant Tranche 2 Amount</td>
                            <td style={cellStyle1}>
                            <input type="number" name="grant1" style={paymenttable} value={hdpMaster.mg_2nd_tranche} onChange={(e)=>handleTableInput("mg_2nd_tranche",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Actual Disbursement date for T2</td>
                            <td style={cellStyle1}>
                            <input type="date" name="grant1" style={paymenttable} value={hdpMaster.actual_date_2} onChange={(e)=>handleTableInput("actual_date_2",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Matching Grant Tranche 3 Amount</td>
                            <td style={cellStyle1}>
                            <input type="number" name="grant1" style={paymenttable} value={hdpMaster.mg_3rd_tranche} onChange={(e)=>handleTableInput("mg_3rd_tranche",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Actual Disbursement date for T3</td>
                            <td style={cellStyle1}>
                            <input type="date" name="grant1"style={paymenttable} value={hdpMaster.actual_date_3} onChange={(e)=>handleTableInput("actual_date_3",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Matching Grant Tranche 4 Amount</td>
                            <td style={cellStyle1}>
                            <input type="number" name="grant1"style={paymenttable} value={hdpMaster.mg_4th_tranche} onChange={(e)=>handleTableInput("mg_4th_tranche",e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={paymentTrancheLabels}>Actual Disbursement date for T4</td>
                            <td style={cellStyle1}>
                            <input type="date" name="grant1"style={paymenttable} value={hdpMaster.actual_date_4} onChange={(e)=>handleTableInput("actual_date_4",e.target.value)}/>
                            </td>
                        </tr>

                        {/* ... Repeat the table content for the first table */}
                        </tbody>
                    </table>
                        </thead>
                      </table>
                    </div>
        </div>     
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

