import * as React from 'react';
import '../assets/css/components.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';


export const DatePick = (props) => {
  const workShop = useSelector(state => state.workShop);
  const [selectDate, setselectedDate] = useState(null)
  
  function handleChange(date) {
    // setselectedDate(date)
    const formattedDate = date.format('YYYY-MM-DD');
    console.log(typeof formattedDate,'typeof')
    console.log(formattedDate,'formatted date')
    if (props.onChange) {
      props.onChange(formattedDate); // Pass the selected date to the parent component
    }
    console.log(props,'props')
  }


  // useEffect(() => {
  //   if (props.disbrushDate) {
  //     // Set the date when it changes
  //     setselectedDate(dayjs(props.disbrushDate));
  //   }
  // }, [props.disbrushDate]);

  // const handleChange = (date) => {
  //   const formattedDate = date.format("YYYY-MM-DD");
  //   setselectedDate(date);
  //   if (props.onChange) {
  //     props.onChange(formattedDate);
  //   }
  // };

  return (
    <div className="col-md-4">
      <div className="form-floating">
        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ padding: 0 }}>
          <DemoContainer components={['DatePicker']} style={{ width: '100rem' }}>
            <DatePicker
              label={props.label}
              value={dayjs(props.disbrushDate)} // Use props.disbrushDate here
              // value={selectDate}
              readOnly={props.readOnly}
              onChange={handleChange}
              className="custom-date-picker"
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: props.errorText,
                  helperText: workShop.validationErrors.date,
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
};
