import { Header } from "../components/Header"
import { LeftNavigation } from "../components/LeftNavigation"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import { OutputPage1 } from "./OutputPage1";
import { OutputPage2 } from "./OutputPage2";
import { OutputPage3 } from "./OutputPage3";
import { OutputPage2ImpactForms } from "./OutputPage2Imapctform";
import { OutputPage3ImpactForms } from "./OutputPage3Impactform";
import { FILOutputReport } from "./FILOutputReport";

export const OutputForms = () => {
    
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const [divisions, setDivisions] = useState([])
    const [currentQuarter, setCurrentQuarter] = useState("")
    const [financialYear, setFinancialYear] = useState([])
    const [currentFinancialYear, setCurrentFinancialYear] = useState("")

    useEffect(()=>{
        
        async function getCommonData(){
            const currentQuarter = await axios.get(`${apiUrl}api/get-current-quarter`, getConfigWithToken())
            const divisionResponse = await axios.get(`${apiUrl}api/getAllDivisions?all=1`, getConfigWithToken())
            const fin = await axios.get(`${apiUrl}api/getAllFinancialYears`, getConfigWithToken())
            const divisionData = divisionResponse.data.data.map((e) => ({
              id: e.id,
              division_name: e.divisions,
            }));
            setFinancialYear(fin.data.data[0])
            setCurrentFinancialYear(fin.data["currentFinanciYear"])
            setCurrentQuarter(currentQuarter.data["data"][0])
            setDivisions(divisionData);

        }

        getCommonData()
        
    },[])

    return (
        <>
            <Header/>
            <LeftNavigation/>
            {location.pathname === "/outputpage1" && <OutputPage1 divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}
            {location.pathname === "/outputpage2" && <OutputPage2 divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}
            {location.pathname === "/outputpage2impactforms" && <OutputPage2ImpactForms divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}
            {location.pathname === "/outputpage3impactforms" && <OutputPage3ImpactForms divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}
            {location.pathname === "/outputpage3" && <OutputPage3 divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}
            {location.pathname === "/filoutputreport" && <FILOutputReport divi={divisions} currentQuter={currentQuarter} finiYear={financialYear} currentFiniYear={currentFinancialYear}/>}

        </>
    )
}