// utils/auth.js

export function isAuthenticated() {
    const token = localStorage.getItem('token');
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  
    // Check if both fields are present and token is valid
    return isLoggedIn && isValidToken(token);
  }
  
  function isValidToken(token) {
    return true
    // Implement token validation logic here
    // You can check token expiration and other security checks
    // Return true if the token is valid; otherwise, return false
  }
  