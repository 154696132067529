import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { setInputFieldData } from '../redux/slice/InputTwo/MasterSubSlice';
import { ErrorBoundary } from "react-error-boundary";
import { ServerPage } from "./ErrorPage/ServerPage";
import { OutputTablePhysicalStatus } from "../components/OutputThree/OutputTablePhysicalStatus";
import { OutputTableEmpGenConst } from "../components/OutputThree/OutputTableEmpGenConst";
import { OutputTableGenderInclusion } from "../components/OutputThree/OutputTableGenderInclusion";
import { OutputTableSafeguard } from "../components/OutputThree/OutputTableSafeguard";
import { OutputTableFacilityChanges } from "../components/OutputThree/OutputTableFacilityChanges";
import { useSelector } from "react-redux";
import { OutputTableEmpGenOper } from "../components/OutputThree/OutputTableEmpGenOper";

export const OutputPage3 = ({divi, currentQuter, finiYear, currentFiniYear}) => {
  const [value, setValue] = useState("0");
  const [permission, setPermission] = useState([])

  const [currentQuarter, setCurrentQuarter] = useState("")
  const [financialYear, setFinancialYear] = useState([])
  const [currentFinancialYear, setCurrentFinancialYear] = useState("")
  const [divisions, setDivisions] = useState([])
  const dd = useSelector(setInputFieldData);

  const handleMainTabChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(()=>{
    async function getDivision () {
      setFinancialYear(finiYear)
      setCurrentFinancialYear(currentFiniYear)
      setCurrentQuarter(currentQuter)
      setDivisions(divi);
    }
    getDivision()
    const storedArrayAsString = localStorage.getItem('permission');
    setPermission(storedArrayAsString)
  },[divi, currentQuter, finiYear, currentFiniYear])
  return (
    <>
    <ErrorBoundary FallbackComponent={<ServerPage/>}>
      <section class="section">
          <div class="row">
            <div id="exTab3" class="contain" style={{width: "80%", position: "absolute", right: "0rem", top: "3.5rem"}}>
              <div class="card">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <TabList variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" onChange={handleMainTabChange}>
                    {
                        permission.includes("Phy-Status-Outp-Three-view") ? (
                          <Tab label="Project Progress" value="0" className={`${value === '0' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Emp-Gen-Dur-Construction-Outp-Three-view") ? (
                          <Tab label="Facility wise Employment Generated- During construction" value="1" className={`${value === '1' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("") ? (
                          <Tab label="Facility wise Employment Generated- During Operation" value="5" className={`${value === '5' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Gen-Equity-Soc-Inclusion-Outp-Three-view") ? (
                          <Tab label="Gender Equality and Social Inclusion" value="2" className={`${value === '2' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                    {
                        permission.includes("Safeguard-Outp-Three-view") ? (
                          <Tab label="Safeguard" value="3" className={`${value === '3' ? 'activeClickCSS' : ''}`} />
                        ):(
                            ""
                        )
                    }
                      
                    </TabList>
                    <TabPanel value="0">
                      <OutputTablePhysicalStatus setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>      
                    </TabPanel>
                    <TabPanel value="1">
                        <OutputTableEmpGenConst setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                    </TabPanel>
                    <TabPanel value="5">
                        <OutputTableEmpGenOper setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <OutputTableGenderInclusion setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <OutputTableSafeguard setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                    </TabPanel>
                    <TabPanel value="4">
                        <OutputTableFacilityChanges setDivision={divisions} financialYear={financialYear} currentQuarter={currentQuarter} currentFinancialYear={currentFinancialYear}/>
                    </TabPanel>
                  </TabContext>
                  <br/>
                  
                </Box>
              </div>
            </div>
          </div>
        </section>
        </ErrorBoundary>
       
    </>
  );
};
