
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
 "18":{
    magnet_crop_id:[],

    sub_project_title:'',
    signing_date:'',
    total_project_cost:'',
    beneficiary_contribution:'',
    matching_grant:'',

    mg_1st_tranche:'',
    mg_2nd_tranche:'',
    mg_3rd_tranche:'',
    mg_4th_tranche:'',
    actual_date_1:'',
    actual_date_2:'',
    actual_date_3:'',
    actual_date_4:'',
    },

    // Validation
  validationErrors:{  
    magnet_crop_id:[],

    sub_project_title:'',
    signing_date:'',
    total_project_cost:'',
    beneficiary_contribution:'',
    matching_grant:'',
  
    mg_1st_tranche:'',
    mg_2nd_tranche:'',
    mg_3rd_tranche:'',
    mg_4th_tranche:'',
    actual_date_1:'',
    actual_date_2:'',
    actual_date_3:'',
    actual_date_4:'', 
        }
    };

const BioWasteMasterSlice = createSlice({
  name: 'BioWasteMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["18"][action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataBioWasteMaster: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"Payload")
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["18"]) {
        if (payload[key] !== undefined) {
          // state["18"][key] = payload[key];
          if(payload[key] === null){
            state["18"][key] = '';
          }else{
            state["18"][key] = payload[key];
          }
        }
      }
    },
    resetDataBioWasteMaster: (state) => {
      // Reset all fields to their initial values
      console.log("jnjnjnjnjnjn")
      return { ...initialState };
    },
    setValidationErrorsBioWasteMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldData, updateApiDataBioWasteMaster, resetDataBioWasteMaster } = BioWasteMasterSlice.actions;

export default BioWasteMasterSlice.reducer;
