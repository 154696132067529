
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';


export const BeneficiaryAssetsMaster = () =>{ 
    const dispatch = useDispatch();
    const mgBeneficiaryMaster = useSelector(state => state.mgBeneficiaryMaster);
    const LandOwnerShipStatus = [
        {
            'id': 1,
            'Status_Type':'Owned'
        },
        {
            'id': 2,
            'Status_Type': 'Leased'
        }
      ]
    const handleFieldLandArea = (event) => {
        dispatch(setInputFieldData({ fieldName: 'land_area', value:event.target.value }));
   }
   const handleFieldLatitude = (event) => {
    dispatch(setInputFieldData({ fieldName: 'latitude', value:event.target.value }));
    }
    const handleFieldLongitude = (event) => {
        dispatch(setInputFieldData({ fieldName: 'longitude', value:event.target.value }));
    }
    const handleFieldLandOwnershipStatus = (event) => {
        dispatch(setInputFieldData({ fieldName: 'land_ownership_status', value:event.target.value }));
    }
    const handleFieldLeasePeriod = (event) => {
        dispatch(setInputFieldData({ fieldName: 'lease_period_years', value:event.target.value }));
    }
   
   const handleResetFocus = () =>{
    dispatch(resetValidation())
   }
  

 return(<>   
    {/* <Online> */}
    <div className='tab-pane'>
            <form class="row g-3">

                {/* Land Area */}
                <div className='primaryTableLabel'>
                    <label htmlFor="land_area">Land Area (in sq.m.)</label>
                    <input type="number" name="land_area" 
                        value={mgBeneficiaryMaster.land_area}
                        onChange={handleFieldLandArea} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                    
                {/* Latitude */}
                <div className='primaryTableLabel'>
                    <label htmlFor="latitude">Latitude</label>
                    <input type="number" name="latitude" 
                        value={mgBeneficiaryMaster.latitude}
                        onChange={handleFieldLatitude} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                                    
                {/* longitude */}
                <div className='primaryTableLabel'>
                    <label htmlFor="longitude">Longitude</label>
                    <input type="number" name="longitude" 
                        value={mgBeneficiaryMaster.longitude}
                        onChange={handleFieldLongitude} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
                                    
                {/* Land Ownership Status */}
                <div className='primaryTableLabel'>
                <Box>
                    <label htmlFor="Status">Land Ownership Status</label>
                    <FormControl style={{width:'30%'}} >
                    <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                    <Select name="Status" 
                        className="customSelect" 
                        style={{width:"100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mgBeneficiaryMaster.land_ownership_status}
                        onChange={handleFieldLandOwnershipStatus} 
                        onFocus={handleResetFocus}                                         
                    >
                    {LandOwnerShipStatus.map((e) => (
                        <MenuItem value={e.Status_Type}>{e.Status_Type}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Box>
                </div>                                         
                {/* lease_period_years */}
                <div className='primaryTableLabel'>
                    <label htmlFor="lease_period_years">Lease Period Years</label>
                    <input type="number" name="lease_period_years" 
                        value={mgBeneficiaryMaster.lease_period_years}
                        onChange={handleFieldLeasePeriod} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"30%"}}   
                    />
                </div>
            </form>
        </div>
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

