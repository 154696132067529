import { InputField } from "../../InputField";
import React, { useEffect, useState } from "react";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormHelperText } from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/MarketPromotion/MarketPromotionSlice"; // Import your action creator

export function MarketPromotion() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const marketPromotion = useSelector((state) => state.marketPromotion);
  const [taluka, setTaluka] = useState([]);

  useEffect(() => {
    const workData = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    workData();
  }, []);

  const totalOBCMinorityOpen =
    parseInt(
      marketPromotion.participants_male === ""
        ? 0
        : marketPromotion.participants_male
    ) +
    parseInt(
      marketPromotion.participants_female === ""
        ? 0
        : marketPromotion.participants_female
    );
  dispatch(setTotalParticipants(totalOBCMinorityOpen));

  const handleFromDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleOptionstaluka = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: event.target.value,
      })
    );
  };

  const handleFieldNoOfBeneAttend = (value) => {
    dispatch(setInputFieldData({ fieldName: "beneficiary_attended", value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}


      <div class="row g-3">

        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            <FormControl
              fullWidth
              error={
                marketPromotion.validationErrors.location_taluka !== ""
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="demo-simple-select-label">
                Location (Taluka)
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marketPromotion.location_taluka}
                label="Location (Taluka)"
                onChange={handleOptionstaluka}
              >
                {taluka.map((e, key) => (
                  <MenuItem value={e.id} key={key}>
                    {e.taluka}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: "#d32f2f" }}>
              {marketPromotion.validationErrors.location_taluka}
            </FormHelperText>
          </Box>
        </div>
        <DatePick
          label="From Date"
          className="custom-date-picker"
          disbrushDate={marketPromotion.from_date}
          onChange={handleFromDateChange}
          errorText={!!marketPromotion.validationErrors.from_date}
        />
        <DatePick
          label="To Date"
          className="custom-date-picker"
          disbrushDate={marketPromotion.to_date}
          onChange={handleToDateChange}
          errorText={!!marketPromotion.validationErrors.to_date}
        />
      <InputField
        label="No. of Beneficiaries attended"
        type="number"
        value={marketPromotion.beneficiary_attended}
        onChange={handleFieldNoOfBeneAttend}
        onFocus={handleResetFocus}
        error={!!marketPromotion.validationErrors.beneficiary_attended}
        helperText={marketPromotion.validationErrors.beneficiary_attended}
      />
      
        <div></div>
        <div className="col-md-3 input1Heading">
          <span>Participants</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={marketPromotion.participants_male}
          onChange={(value)=>handleInputChange("participants_male",value)}
          onFocus={handleResetFocus}
          error={!!marketPromotion.validationErrors.participants_male}
          helperText={marketPromotion.validationErrors.participants_male}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={marketPromotion.participants_female}
          onChange={(value)=>handleInputChange("participants_female",value)}
          onFocus={handleResetFocus}
          error={!!marketPromotion.validationErrors.participants_female}
          helperText={marketPromotion.validationErrors.participants_female}
        />
        <InputField
          col="3"
          label="Total Participants"
          type="number"
          value={marketPromotion.participants_total}
          readOnly={true}
        />
      </div>
    </>
  );
}
