
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "17": {
    typedata:[]
  },

// Validation
  validationErrors:{  
    magnet_crop_id:[],
    location_taluka:'',
    area:'',
    capacity_total_plants:'',
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
        }
    };

const MarketAssMasterSlice = createSlice({
  name: 'MarketAssMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["17"][action.payload.fieldName] = action.payload.value;
    },
    setInputActivity: (state, action) => {
      state["17"].typedata = action.payload;
    },
    // Add other reducers for different input fields if needed
    updateApiDataMarketAssMaster: (state, action) => {
      const payload = action.payload;
      // const payload = { ["typedata"]: pay["typedata"] };
      // console.log(newObj);
      const existingIndex = state["17"].typedata.findIndex(item => item.id === payload.id);
    
      if (existingIndex !== -1) {
        // If the item with the same id exists, update it
        state["17"].typedata[existingIndex] = payload;
      } else {
        // If not, push the new item
        state["17"].typedata.push(payload);
      }
    },
    
    resetDataMarketAssMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsMarketAssMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, setInputActivity, setComponents, resetValidation, setInputFieldData, updateApiDataMarketAssMaster, resetDataMarketAssMaster } = MarketAssMasterSlice.actions;

export default MarketAssMasterSlice.reducer;
