import React, { useState } from "react";
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import "../../../../assets/css/masterdata.css";
import { BeneficiaryDetailsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryDetails";
import { BeneficiaryContactMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryContact";
import { BeneficiaryFinancialsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryFinancials";
import { BeneficiaryStakeHoldersMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryStakeholders";
import { BeneficiaryAssetsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryAssets";
import { BeneficiaryGrandDetailsMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryGrandDetails";
import { BeneficiaryFacilitiesMaster } from "../../../../subcomponents/MasterFormTables/PrimaryTables/MGBeneficiaryMaster/BeneficiaryFacilities";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import axios from "axios";
import {
  setInputFieldData,
  setValidationErrorsMGBeneficiaryMaster,
  resetDataMGBeneficiaryMaster,
  updateApiDataMGBeneficiaryMaster,
} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

export const AddBeneficiaryMaster = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dd = useSelector(setInputFieldData);
  const dispatch = useDispatch();
  const [value, setValue] = useState("0");

  const location = useLocation();
  const id = location.state;
  const [Applicant, setApplicant] = useState([]);
  const [Division, setDivision] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [magnetCrop, setMagentCrop] = useState([]);
  const [EntityType, setEntityType] = useState([]);
  const [LoanType, setLoanType] = useState([]);

  const [componentActivityData, setComponentActivityData] = useState([]);
  const [BeneficiaryFlagEdit, setBeneficiaryFlagEdit] = useState(false);

  const [activity, setActivity] = useState([]);
  const [inputActivity, setInputActivity] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChildInputActivity = (childInputActivity) => {
    setInputActivity(childInputActivity);
  };

  const handleNextForm = () => {
    const newValue = parseInt(value) + 1;
    setValue(String(newValue));
  };

  const handleBackForm = () => {
    const newValue = parseInt(value) - 1;
    setValue(String(newValue));
  };

  const Beneficiary_Status = [
    {
      id: 1,
      Status_mode: "Primary",
    },
    {
      id: 2,
      Status_mode: "Secondary",
    },
    {
      id: 3,
      Status_mode: "Tertiary",
    },
  ];

  const handleMsambFacilityChange = async (event) => {
    const selectedMsambFacilityId = event.target.value;

    // Reset the form data every time the applicant_id changes
    dispatch(resetDataMGBeneficiaryMaster());
    setBeneficiaryFlagEdit(false); // Set to false to ensure it's not in edit mode

    // Set the selected applicant_id in the form data
    dispatch(
      setInputFieldData({
        fieldName: "applicant_id",
        value: selectedMsambFacilityId,
      })
    );

    // Check if data already exists for the selected applicant_id
    const existingDataCheck = await axios.get(
      `${apiUrl}api/primary-data-edit?applicant_id=${selectedMsambFacilityId}`,
      getConfigWithToken()
    );

    console.log("Existing Data Check:", existingDataCheck.data);

    if (existingDataCheck.data && existingDataCheck.data.status) {
      // Data already exists, set it for pre-filling the form
      const existingData = existingDataCheck.data.data;

      if (existingData && existingData.length > 0) {
        const existingDta = existingData[0];
        console.log("Existing Data for Editing:", existingDta);

        // Pre-fill the form with existing data
        dispatch(updateApiDataMGBeneficiaryMaster(existingDta));

        // Set the BeneficiaryFlagEdit to true
        setBeneficiaryFlagEdit(true);

        // Parse magnet_crop_id as an array if it's not already an array
        let parsedMagnetCropId = [];
        let parsedProcessingId = [];

        if (existingDta.targeted_magnet_crops) {
          // Parse the stringified JSON array into a JavaScript object
          const magnetCropData = JSON.parse(existingDta.targeted_magnet_crops);
          parsedMagnetCropId = Array.isArray(magnetCropData)
            ? magnetCropData
            : [magnetCropData];
        }

        if (existingDta.processing) {
          // Parse the stringified JSON array into a JavaScript object
          const processingData = JSON.parse(existingDta.processing);
          parsedProcessingId = Array.isArray(processingData)
            ? processingData
            : [processingData];
        }
        // Set the values for magnet_crop_id
        dispatch(
          setInputFieldData({
            fieldName: "targeted_magnet_crops",
            value: parsedMagnetCropId.map((item) => item.id),
          })
        );
        dispatch(
          setInputFieldData({
            fieldName: "processing",
            value: parsedProcessingId.map((item) => item.id),
          })
        );
      }
    }
  };

  const handleMGBeneficiarySave = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["mgBeneficiaryMaster"];
      const errorMessages = {
        applicant_id: "Required",
        division_id: "Required",
        district_id: "Required",
        taluka_id: "Required",
        status: "Required",
        eoi: "Required",
        entity_type_id: "Required",
        pan_of_firm: "Required",
        udyam_reg_number: "Required",
        fssai_application_number: "Required",
        incorporation_date: "Required",
        targeted_magnet_crops: "Required",
        finished_products: "Required",
        reg_number_of_applicant: "Required",

        //Contact Information
        key_contact_person: "Required",
        email: "Required",
        mobile_number: "Required",

        // Fiancials
        paid_up_capital: "Required",
        turnover: "Required",
        net_worth: "Required",
        cash_and_bank: "Required",
        reserves_and_surplus: "Required",
        sales_fy1: "Required",
        variable_cost_fy1: "Required",
        contribution_fy1: "Required",
        contribution_pct_fy1: "Required",
        sales_fy5: "Required",
        variable_cost_fy5: "Required",
        contribution_fy5: "Required",
        contribution_pct_fy5: "Required",

        // Stakeholders
        women_led: "Required",
        male: "Required",
        female: "Required",
        shareholders: "Required",
        others: "Required",
        sc: "Required",
        st: "Required",

        // Assets
        land_area: "Required",
        latitude: "Required",
        longitude: "Required",
        land_ownership_status: "Required",
        lease_period_years: "Required",
        // Facilities
        cold_storage: "Required",
        pack_house_tpd: "Required",
        secondary_processing_tpd: "Required",
        tertiary_processing_tpd: "Required",

        // Grand Details
        lending_bank_name: "Required",
        loan_type_id: "Required",
        financial_year_approval_date: "Required",
        tranch_released_id: "Required",
        processing: "Required",
        total_project_cost: "Required",
        promoter_equity: "Required",
        bank_term_loan: "Required",
        matching_grant: "Required",

        // table
        total_project_cost: "Required",
        promoter_equity: "Required",
        bank_term_loan: "Required",
        matching_grant: "Required",
        mg_1st_tranche: "Required",
        mg_2nd_tranche: "Required",
        mg_3rd_tranche: "Required",
      };

      let isEmpty = false;

      for (const field in errorMessages) {
        const value = formData[field];

        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[field] = "Required";
          isEmpty = true;
        }
      }

      dispatch(setValidationErrorsMGBeneficiaryMaster(errors));

      if (isEmpty) {
        Swal.fire("All fields are required", "", "warning");
      }

      return !isEmpty;
    };

    try {
      const formData = dd["payload"]["mgBeneficiaryMaster"];
      const jsonMagnetCrop = Array.isArray(formData["targeted_magnet_crops"])
        ? formData["targeted_magnet_crops"]
            .map((id) => {
              const matchingObject = magnetCrop.find((item) => item.id === id);
              if (matchingObject) {
                return {
                  id: matchingObject.id,
                  name: matchingObject.crop,
                };
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];

      const jsonProcessing = Array.isArray(formData["processing"])
        ? formData["processing"]
            .map((id) => {
              const matchingObject = Beneficiary_Status.find(
                (item) => item.id === id
              );
              if (matchingObject) {
                return {
                  id: matchingObject.id,
                  Status_mode: matchingObject.Status_mode,
                };
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];

      const resultMagnetCrop = { data: jsonMagnetCrop };
      const resultprocessing = { data: jsonProcessing };
      console.log(resultprocessing, "processing");

      const obj = {
        ...formData,
        targeted_magnet_crops: JSON.stringify(resultMagnetCrop["data"]),
        processing: JSON.stringify(resultprocessing["data"]),
      };
      if (BeneficiaryFlagEdit) {
        const updateEndpoint = `${apiUrl}api/primary-data-update?id=${id}`;
        console.log("Update URL:", updateEndpoint);
        console.log("Update Data:", obj);

        const checkEdit = await axios.post(
          updateEndpoint,
          obj,
          getConfigWithToken()
        );

        if (checkEdit.data["status"] === true) {
          Swal.fire("Edited Successfully", "", "success");
          dispatch(resetDataMGBeneficiaryMaster());
        } else {
          Swal.fire(
            "Record Not Found",
            "The record you are trying to edit/update does not exist.",
            "warning"
          );
        }
      } else {
        const checkCreate = await axios.post(
          `${apiUrl}api/primary-data-create`,
          obj,
          getConfigWithToken()
        );

        if (checkCreate.data["status"] === true) {
          Swal.fire(`${checkCreate.data["message"]}`, "", "success");
          dispatch(resetDataMGBeneficiaryMaster());
        } else {
          Swal.fire(`${checkCreate.data["message"]}`, "", "warning");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    dispatch(resetDataMGBeneficiaryMaster());
    const MGBeneficiaryData = async () => {
      try {
        const applicant = await axios.get(
          `${apiUrl}api/get-all-applicants?all=1`,
          getConfigWithToken()
        );
        const division = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        const district = await axios.get(
          `${apiUrl}api/get-all-district?all=1`,
          getConfigWithToken()
        );
        const taluka = await axios.get(
          `${apiUrl}api/get-taluka-by-district?district_id=${id}`,
          getConfigWithToken()
        );
        const magnetcrop = await axios.get(
          `${apiUrl}api/getAllCrop?all=1`,
          getConfigWithToken()
        );
        const entitytype = await axios.get(
          `${apiUrl}api/getEntityType?all=1`,
          getConfigWithToken()
        );
        const loantype = await axios.get(
          `${apiUrl}api/getLoanType?all=1`,
          getConfigWithToken()
        );

        const applicantData = applicant.data["data"].map((e) => ({
          id: e["id"],
          applicant_name: e["applicant_name"],
        }));
        const divisionData = division.data["data"].map((e) => ({
          id: e["id"],
          divisions: e["divisions"],
        }));
        const districtData = district.data["data"].map((e) => ({
          id: e["id"],
          district: e["district"],
        }));
        const talukaData = taluka.data["data"].map((e) => ({
          id: e["id"],
          taluka: e["taluka"],
        }));
        const magnetcropData = magnetcrop.data["data"].map((e) => ({
          id: e["id"],
          crop: e["crop"],
        }));
        const entitytypeData = entitytype.data["data"].map((e) => ({
          id: e["entity_type_id"],
          entity_type: e["entity_type"],
        }));
        const loantypeData = loantype.data["data"].map((e) => ({
          id: e["loan_id"],
          loan_type: e["loan_type"],
        }));

        setApplicant(applicantData);
        setDivision(divisionData);
        setDistrict(districtData);
        setTaluka(talukaData);
        setMagentCrop(magnetcropData);
        setEntityType(entitytypeData);
        setLoanType(loantypeData);

        if (id && id.id) {
          const applicantId = id.id;
          console.log("Selected Applicant ID:", applicantId);

          // Fetch existing data for editing
          const editDataResponse = await axios.get(
            `${apiUrl}api/primary-data-edit?applicant_id=${applicantId}`,
            getConfigWithToken()
          );
          editDataResponse.data["flag"]
            ? setBeneficiaryFlagEdit(true)
            : setBeneficiaryFlagEdit(false);

          console.log("Edit Data Response:", editDataResponse.data);

          if (editDataResponse.data && editDataResponse.data.status) {
            const editData = editDataResponse.data.data;

            if (editData && editData.length > 0) {
              const editDta = editData[0];
              console.log("Data for Editing:", editDta);

              const { ...newObj } = editDta;

              if (newObj["targeted_magnet_crops"]) {
                let parsedData = JSON.parse(newObj["targeted_magnet_crops"]);
                if (Array.isArray(parsedData)) {
                  newObj["targeted_magnet_crops"] = parsedData.map((e) => e.id);
                } else {
                  console.error(
                    "targeted_magnet_crops is not an array:",
                    parsedData
                  );
                }
              }
              if (newObj["processing"]) {
                let parsedData = JSON.parse(newObj["processing"]);
                if (Array.isArray(parsedData)) {
                  console.log("processing is  an array:", parsedData);
                  newObj["processing"] = parsedData.map((e) => e.id);
                } else {
                  console.error("processing is not an array:", parsedData);
                }
              }

              console.log("New Object for Redux:", newObj);
              dispatch(updateApiDataMGBeneficiaryMaster(newObj));
              console.log(newObj, "newobj");
            } else {
              console.error("No data found for editing");
            }
          } else {
            console.error("Invalid request:", editDataResponse.data.message);
          }
        } else {
          console.error("Invalid id:", id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    MGBeneficiaryData();
  }, [id, dispatch]);

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div id="exTab3" className="contain">
              <div
                className="cardMaster"
                id=""
                style={{ height: "1500px", marginTop: "1rem" }}
              >
                <form class="row g-3">
                  <Box className="your-component" sx={{ typography: "body1" }}>
                    <div className="words-with-lines">
                      <span>MG Beneficiary Master</span>
                    </div>
                    <div className="msambstyle">
                      <a href="./MG-beneficiary-master">
                        <i className="fa-solid fa-arrow-left"></i>Back
                      </a>
                      <button
                        type="submit"
                        className="save-btn"
                        onClick={handleMGBeneficiarySave}
                      >
                        Save
                      </button>
                      <button className="dropdown-btn">
                        <i className="fa-solid fa-caret-down"></i>
                      </button>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      width: "92%",
                      height: "500px",
                      marginLeft: "5rem",
                      marginTop: "3.5rem",
                      typography: "body1",
                    }}
                  >
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          value={value}
                          onChange={handleChange}
                        >
                          <Tab
                            label="Beneficiary Details"
                            value="0"
                            className={`${
                              value === "0" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Contact"
                            value="1"
                            className={`${
                              value === "1" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Financials"
                            value="2"
                            className={`${
                              value === "2" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Stakeholders"
                            value="3"
                            className={`${
                              value === "3" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Assets"
                            value="4"
                            className={`${
                              value === "4" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Facilities"
                            value="5"
                            className={`${
                              value === "5" ? "activeClickCSS" : ""
                            }`}
                          />
                          <Tab
                            label="Grant Details"
                            value="6"
                            className={`${
                              value === "6" ? "activeClickCSS" : ""
                            }`}
                          />
                        </Tabs>
                      </Box>

                      {/* Beneficiary Deatils */}
                      <TabPanel value="0">
                        <BeneficiaryDetailsMaster
                          editFlag={BeneficiaryFlagEdit}
                          Applicant={Applicant}
                          Division={Division}
                          District={District}
                          Taluka={Taluka}
                          EntityType={EntityType}
                          MagnetCrop={magnetCrop}
                          onMsambFacilityChange={handleMsambFacilityChange}
                        />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* Contact  */}
                      <TabPanel value="1">
                        <BeneficiaryContactMaster />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* Financials */}
                      <TabPanel value="2">
                        <BeneficiaryFinancialsMaster />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* Stakeholders  */}
                      <TabPanel value="3">
                        <BeneficiaryStakeHoldersMaster />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* Assets */}
                      <TabPanel value="4">
                        <BeneficiaryAssetsMaster />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* Facilities  */}
                      <TabPanel value="5">
                        <BeneficiaryFacilitiesMaster />
                        <div style={{ textAlign: "right", marginTop: "2rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                          <button
                            type="button"
                            onClick={handleNextForm}
                            className="btn submitbtn"
                          >
                            Next
                            <i
                              className="fa-solid fa-angles-right"
                              style={{ color: "white", marginLeft: "0.5rem" }}
                            ></i>
                          </button>
                        </div>
                      </TabPanel>

                      {/* GrandDetails */}
                      <TabPanel value="6">
                        <div style={{ textAlign: "right", marginTop: "-1rem" }}>
                          <button
                            type="button"
                            onClick={handleBackForm}
                            className="btn submitbtn"
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: "white",
                                marginRight: "0.5rem",
                              }}
                            ></i>
                            Previous
                          </button>
                        </div>
                        <BeneficiaryGrandDetailsMaster
                          LoanType={LoanType}
                          Beneficiary_Status={Beneficiary_Status}
                        />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
