
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "4": {
    magnet_crop_id:[],
    location_taluka:'',
    area: undefined,
    form:'',
    capacity_total_plants:'',
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
    actual_date_1: '',
    actual_date_2: '',
    actual_date_3: '',
    actual_date_4: '',
    typedata: [],
  },

// Validation
  validationErrors:{  
    magnet_crop_id:[],
    location_taluka:'',
    area:'',
    capacity_total_plants:'',
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
        }
    };

const NurseryMasterSlice = createSlice({
  name: 'NurseryMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["4"][action.payload.fieldName] = action.payload.value;
    },

    setInputActivity: (state, action) => {
      state["4"].typedata = action.payload;
    },
    // Add other reducers for different input fields if needed
    updateApiDataNurseryMaster: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"PayloadNursery")
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["4"]) {
        if (payload[key] !== undefined) {
          // state["4"][key] = payload[key];
          if(payload[key] === null){
            state["4"][key] = '';
          }else{
            state["4"][key] = payload[key];
          }
        }
      }
    },
    resetDataNurseryMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsNurseryMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, setInputActivity, setComponents, resetValidation, setInputFieldData, updateApiDataNurseryMaster, resetDataNurseryMaster } = NurseryMasterSlice.actions;

export default NurseryMasterSlice.reducer;
