import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the FullTime form
const initialState = {
  // Full Time Employement
  full_time_emp_num_male: '',
  full_time_emp_num_female: '',
  full_time_emp_num_total: '',
  full_time_emp_num_sc_male: '',
  full_time_emp_num_sc_female: '',
  full_time_emp_num_sc_total: '',
  full_time_emp_num_st_male: '',
  full_time_emp_num_st_female: '',
  full_time_emp_num_st_total: '',
  full_time_emp_num_pwd_male: '',
  full_time_emp_num_pwd_female: '',
  full_time_emp_num_pwd_total: '',
  full_time_emp_num_obc_minority_open_male: '',
  full_time_emp_num_obc_minority_open_female: '',
  full_time_emp_num_obc_minority_open_total: '',

  // Part Time Employement
  part_time_emp_num_male: '',
  part_time_emp_num_female: '',
  part_time_emp_num_total: '',
  part_time_emp_num_sc_male: '',
  part_time_emp_num_sc_female: '',
  part_time_emp_num_sc_total: '',
  part_time_emp_num_st_male: '',
  part_time_emp_num_st_female: '',
  part_time_emp_num_st_total: '',
  part_time_emp_num_pwd_male: '',
  part_time_emp_num_pwd_female: '',
  part_time_emp_num_pwd_total: '',
  part_time_emp_num_obc_minority_open_male: '',
  part_time_emp_num_obc_minority_open_female: '',
  part_time_emp_num_obc_minority_open_total: '',
  total_emp_generated_male: '',
  total_emp_generated_female: '',
  total_emp_generated_total: '',

  validationErrors:{
    // Full Time Employement
    full_time_emp_num_male: '',
    full_time_emp_num_female: '',
    full_time_emp_num_sc_male: '',
    full_time_emp_num_sc_female: '',
    full_time_emp_num_st_male: '',
    full_time_emp_num_st_female: '',
    full_time_emp_num_pwd_male: '',
    full_time_emp_num_pwd_female: '',
    full_time_emp_num_obc_minority_open_male: '',
    full_time_emp_num_obc_minority_open_female: '',

    // Part Time Employement
    part_time_emp_num_male: '',
    part_time_emp_num_female: '',
    part_time_emp_num_sc_male: '',
    part_time_emp_num_sc_female: '',
    part_time_emp_num_st_male: '',
    part_time_emp_num_st_female: '',
    part_time_emp_num_pwd_male: '',
    part_time_emp_num_pwd_female: '',
    part_time_emp_num_obc_minority_open_male: '',
    part_time_emp_num_obc_minority_open_female: '',
  }
  // Add other input fields' initial values here
};

// Create a slice for FullTimeForm
const fullPartTimeForm = createSlice({
  name: 'fullPartTimeForm',
  initialState,
  reducers: {
    setInputFieldFullPartData: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },

    setTotalFullMaleEmp: (state, action) =>{
      state.full_time_emp_num_male = action.payload
    },
    setTotalFullFemaleEmp: (state, action) =>{
      state.full_time_emp_num_female = action.payload
    },
    setTotalFullTotalEmp: (state, action) =>{
      state.full_time_emp_num_total = action.payload
    },

    setTotalPartMaleEmp: (state, action) =>{
      state.part_time_emp_num_male = action.payload
    },
    setTotalPartFemaleEmp: (state, action) =>{
      state.part_time_emp_num_female = action.payload
    },
    setTotalPartTotalEmp: (state, action) =>{
      state.part_time_emp_num_total = action.payload
    },

    setTotalFullSC: (state, action) => {
      state.full_time_emp_num_sc_total = action.payload;
    },
    setTotalFullST: (state, action) => {
      state.full_time_emp_num_st_total = action.payload;
    },
    setTotalFullPWD: (state, action) => {
      state.full_time_emp_num_pwd_total = action.payload;
    },
    
    setTotalFullOBC: (state, action) => {
      state.full_time_emp_num_obc_minority_open_total = action.payload;
    },

    setTotalPartSC: (state, action) => {
      state.part_time_emp_num_sc_total = action.payload;
    },
    setTotalPartST: (state, action) => {
      state.part_time_emp_num_st_total = action.payload;
    },
    setTotalPartPWD: (state, action) => {
      state.part_time_emp_num_pwd_total = action.payload;
    },
   
    setTotalPartOBC: (state, action) => {
      state.part_time_emp_num_obc_minority_open_total = action.payload;
    },

    setTotalGenMaleEmp: (state, action) =>{
      state.total_emp_generated_male = action.payload;
    },
    setTotalGenFemaleEmp: (state, action) =>{
      state.total_emp_generated_female = action.payload;
    },
    setTotalGenTotalEmp: (state, action) =>{
      state.total_emp_generated_total = action.payload;
    },

    resetDataFullPart: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },

    updateFullPartTime: (state,action) => {
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },

    setValidationErrorsEmpFullPartTime: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state) => {
      state.validationErrors = { ...initialState.validationErrors };
    }
    // Add other reducers for different input fields if needed
  },
});

export const {resetValidation, setValidationErrorsEmpFullPartTime, setInputFieldFullPartData, updateFullPartTime, setTotalFullMaleEmp, 
  setTotalFullFemaleEmp, setTotalFullTotalEmp, setTotalPartMaleEmp, setTotalPartFemaleEmp, setTotalPartTotalEmp, setTotalPartSC, setTotalPartST, setTotalPartPWD, setTotalPartBPL, setTotalPartOBC,setTotalFullSC, setTotalFullST, setTotalFullPWD, setTotalFullBPL, setTotalFullOBC, setTotalGenMaleEmp, setTotalGenFemaleEmp, setTotalGenTotalEmp, resetDataFullPart } = fullPartTimeForm.actions;
export default fullPartTimeForm.reducer;
