// genderEqualitySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    num_of_women_have_farm_land_in_family_name: '',
    num_of_sc_women: '',
    num_of_st_women: '',
    num_of_disabled_women: '',
    num_of_women_below_poverty_line: '',
    total_num_of_toilets_men: '',
    total_num_of_toilets_women: '',
    total_num_of_toilets_disabled: '',
    ramps_for_disabled: '',
    work_env_safe_for_women: '',
    separate_changing_rooms: '',
    committee_of_women: '',
    practice_of_forced_labor: '',
    compensation_act_enforced: '',
    safety_reg_followed: '',
    labor_law_followed: '',
    workplace_clean_and_safe: '',
    equal_pay_for_equal_work_followed: '',
    dispute_resolved: '',
    bonus_paid: '',
    validationErrors:{
      total_num_of_toilets_men: '',
      total_num_of_toilets_women: '',
      total_num_of_toilets_disabled: '',
      ramps_for_disabled: '',
      work_env_safe_for_women: '',
      separate_changing_rooms: '',
      committee_of_women: '',
      practice_of_forced_labor: '',
      compensation_act_enforced: '',
      safety_reg_followed: '',
      labor_law_followed: '',
      workplace_clean_and_safe: '',
      equal_pay_for_equal_work_followed: '',
      dispute_resolved: '',
      bonus_paid: ''
    }
};

const genderEqualitySlice = createSlice({
  name: 'genderEqualitySlice',
  initialState,
  reducers: {
    setAnswer(state, action) {
      const { question, answer } = action.payload;
      state[question] = answer;
    },
    setField: (state, action) => {
        const { field, value } = action.payload;
        // Update the state with the new value for the specified field
        state[field] = value;
      },
      setInputField: (state, action) => {
        state[action.payload.fieldName] = action.payload.value;
      },
    resetDataGenderForm: (state) => {
        // Reset all fields to their initial values
        return { ...initialState };
      },
    updateGenderForm: (state, action) =>{
      return {
        ...state,
        total_num_of_toilets_men: action.payload.total_num_of_toilets_men,
        total_num_of_toilets_women: action.payload.total_num_of_toilets_women,
        total_num_of_toilets_disabled: action.payload.total_num_of_toilets_disabled,
        ramps_for_disabled: action.payload.ramps_for_disabled,
        work_env_safe_for_women: action.payload.work_env_safe_for_women,
        separate_changing_rooms: action.payload.separate_changing_rooms,
        committee_of_women: action.payload.committee_of_women,
        practice_of_forced_labor: action.payload.practice_of_forced_labor,
        compensation_act_enforced: action.payload.compensation_act_enforced,
        safety_reg_followed: action.payload.safety_reg_followed,
        labor_law_followed: action.payload.labor_law_followed,
        workplace_clean_and_safe: action.payload.workplace_clean_and_safe,
        equal_pay_for_equal_work_followed: action.payload.equal_pay_for_equal_work_followed,
        dispute_resolved: action.payload.dispute_resolved,
        bonus_paid: action.payload.bonus_paid
      };
    },
    setValidationErrorsGender: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsGender, resetValidation, updateGenderForm, resetDataGenderForm, setAnswer, setField,setInputField } = genderEqualitySlice.actions;
export default genderEqualitySlice.reducer;
