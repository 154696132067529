import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import {
  Box,
  CircularProgress,
  FormControl,
  Button,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";


export const OutputTablePhysicalStatus = ({
  setDivision,
  financialYear,
  currentFinancialYear,
  currentQuarter,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [workPackage, setWorkPackage] = useState([])
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectWorkPackage, setselectedworkPackage] = useState("");
  const [selectSubFilter, setSelectSubFilter] = useState("Q3");
  const [tableData, setTableData] = useState([]);
  // const [grantSummary, setGrantSummary] = useState([])

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  function filterTransformData(data) {
    const transformedData = data.map((entry) => {
      const magnetCropIds = JSON.parse(entry.magnet_crop_id);
      const magnetCrops = magnetCropIds.map((crop) => `${crop.name}, `);
      return { ...entry, magnet_crop_id: magnetCrops };
    });
    return transformedData;
  }

  const handleApplicantChangeWorkPackage = async (e) => {
    setselectedworkPackage(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (selectSubFilter === "Yearly") {
      const WorkPackageData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?work_package=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
        setTableData(filterTransformData(WorkPackageData.data.data[0]));
    } else if (quarr.includes(selectSubFilter)) {
      const WorkPackageData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?work_package=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(WorkPackageData.data.data[0]));
    } else {
      const WorkPackageData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?work_package=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(WorkPackageData.data.data[0]));
    }
     };

     

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (selectSubFilter === "Yearly") {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${e.target.value}&yearly=${selectFinnacialYear}&work_package=${selectWorkPackage}`,
        getConfigWithToken()
      );
        setTableData(filterTransformData(divisionData.data.data[0]));
    } else if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}&work_package=${selectWorkPackage}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}&work_package=${selectWorkPackage}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(divisionData.data.data[0]));
    }
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (selectSubFilter === "Yearly") {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&yearly=${e.target.value}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(divisionData.data.data[0]));
    } else if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(divisionData.data.data[0]));
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(divisionData.data.data[0]));
    }
  };

  const handleSubFilter = async (e) => {
    if (selectFinnacialYear.length === 0) {
      Swal.fire(`Please Select Financial Year`, "", "warning");
      return;
    }
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    const HalfYearly = ["Q1, Q2", "Q3, Q4"];
    if (quarr.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(filterData.data.data[0]));
    } else if (HalfYearly.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(filterData.data.data[0]));
    } else {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/physicalFinanceOutput?division_id=${selectDivision}&work_package=${selectWorkPackage}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
  
      setTableData(filterTransformData(filterData.data.data[0]));
    }
  };

  useEffect(() => {
    try {
      async function getData() {
        const workPackage = await axios.get(`${apiUrl}api/work-package-listing?all=1`,getConfigWithToken());
        setWorkPackage(workPackage.data["data"])

        const overallPhyData = await axiosInstance.get(
          `${apiUrl}api/physicalFinanceOutput?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`,
          getConfigWithToken()
        );

        setSelectFinancialYear(currentFinancialYear);
        setSelectSubFilter(currentQuarter);
        setTableData(filterTransformData(overallPhyData.data.data[0]));
        setDivisions(setDivision);
        setLoading(false);
      }

      setTimeout(() => {
        getData();
        // setLoading(false);
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire("An refresh the Page", "", "error");
      } else {
        throw new Error("Data Not Found");
      }
    }
  }, [setDivision, financialYear, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: "Work Package",
        accessor: "work_package",
      },
      {
        Header: "Name of MSAMB Facility",
        accessor: "msamb_facility_name",
      },
      {
        Header: "Division",
        accessor: "divisions",
      },
      {
        Header: "District",
        accessor: "district",
      },
      {
        Header: "Taluka",
        accessor: "taluka",
      },
      {
        Header: "MAGNET Crop",
        accessor: "magnet_crop_id",
      },
      {
        Header: "Contract No.",
        accessor: "contract_no",
      },
      {
        Header: "Components as per accepted Tener",
        accessor: "component",
      },
      {
        Header: "Area (Sqm)",
        accessor: "area",
      },
      {
        Header: "Supplier/Contract Name",
        accessor: "supplier_contractor_name",
      },
      {
        Header: "Contract Amount (in Lakh)",
        accessor: "contract_amount",
      },
      {
        Header: "Contract Award Date (DD MMM YYYY)",
        accessor: "contract_award_date",
      },
      {
        Header: "Completion Date (DD MMM YYYY)",
        accessor: "completion_date",
      },
      {
        Header: "Extension Date",
        accessor: "extension_date",
      },
      {
        Header: "Time Elapsed (as on Date systems date) Duration",
        accessor: "time_elapsed",
      },
      {
        Header: "Contract Extension",
        accessor: "contract_extension",
      },
      {
        Header: "Price Increase",
        accessor: "price_increase",
      },
      {
        Header: "Increased Amount (added in total Contract Amount)",
        accessor: "increased_amount",
      },
      {
        Header: "Total Disbursed Amount on Contract",
        accessor: "total_disbursed_amount",
      },
      {
        Header: "Total Undisbursed Amount",
        accessor: "total_undisbursed_amount",
      },
      {
        Header: "Total Expenditure Till Date",
        accessor: "total_exp_till_date",
      },
      {
        Header: "Civil Progress Till Date",
        accessor: "civil_progress_till_date",
      },
      {
        Header: "Electro Mechnical Progress Till Date",
        accessor: "electro_mechanical_progress_till_date",
      },
      {
        Header: "Status of Work",
        accessor: "status_of_work_id",
      },
      {
        Header: "Expenditure incurred in this quarter",
        accessor: "exp_incurred",
      },
      {
        Header: "Civil Progress",
        accessor: "civil_progress_pct",
      },
      {
        Header: "Electro Mechnical Progress",
        accessor: "electro_mechanical_progress_pct",
      },
      {
        Header: "Action point/ Recommendation made",
        accessor: "action_points",
      },
 
    ],
    []
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData, // Assuming data is an array with one element containing the data array.
    });

  const cellStyle = {
    padding: "8px",
    // border: '1px solid #ccc', // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };

  const exportToExcel = () => {
    const selectedDivision = divisions.find(
      (division) => division.id === selectDivision
    );
    const divisionName = selectedDivision
      ? selectedDivision.division_name
      : "All";

    let formattedData;

    if (tableData.length === 0) {
      // Create a single row with only headers if there's no data
      formattedData = [
        {
          "Financial Year": selectFinnacialYear,
          Period: selectSubFilter,
          ...(divisionName !== "All" && { Division: divisionName }),
        },
      ];

      columns.forEach((column) => {
        formattedData[0][column.Header] = ""; // Empty value for the headers
      });
    } else {
      // Map table data and conditionally add the "Division" column
      formattedData = tableData.map((row) => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          Period: selectSubFilter,
        };

        if (divisionName !== "All") {
          formattedRow["Division"] = divisionName;
        }

        columns.forEach((column) => {
          formattedRow[column.Header] = row[column.accessor];
        });

        return formattedRow;
      });
    }

    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    const fileName = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_MSAMB_PhysicalStatus.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };



  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  const stickyy = {
    position: "sticky",
    top: "0",
    zIndex: "1",
    background: "var(--stickyBackground)",
    boxShadow: "0 0 6px rgba(0,0,0,0.25)",
  };

  return (
    <>
    
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center the container
          marginBottom: "10px",
          // marginLeft: "5rem",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1200px" }}>
          {" "}
          {/* Center the content and set max width */}
          <div className="row">
            <div className="col-md-2">
              {" "}
              <Box>
                <FormControl
                  fullWidth
                >
                  <InputLabel htmlFor="demo-simple-select-label">
                    Work Package
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectWorkPackage}
                    label="Work Package"
                    onChange={handleApplicantChangeWorkPackage}
                  >
                    {workPackage.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e["work_package"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1 & Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3 & Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "155px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto", maxHeight: "445px" }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2",
                      // background: ((index===9 && grantSummary['progress_by_the_end_of_qtr_pct']>=150) || (index===7 && grantSummary['physical_progress_till_date_pct']>=150) )?'red':'#f2f2f2', // Add grey background color
                      fontWeight: "bold", // Optionally make text bold
                      border: "2px solid #ccc",
                      ...cellStyle, // Add border style here
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle, // Add border style here
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
         
        </table>
      </div>
     
    </>
  );
};
