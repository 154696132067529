import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ErrorBoundary } from "react-error-boundary";
import { ServerPage } from "./ErrorPage/ServerPage";
import { FilOutputReport } from "../components/FILOutputReport/FILOutputReport";

export const FILOutputReport = ({
  divi,
  currentQuter,
  finiYear,
  currentFiniYear,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [mainTabValue, setMainTabValue] = useState("0");

  const [permission, setPermission] = useState([]);

  const [divisions, setDivisions] = useState([]);
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [currentFinancialYear, setCurrentFinancialYear] = useState("");

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  useEffect(() => {
    async function getData() {
      setFinancialYear(finiYear);
      setCurrentFinancialYear(currentFiniYear);
      setCurrentQuarter(currentQuter);
      setDivisions(divi);
    }
    getData();
    const storedArrayAsString = localStorage.getItem("permission");
    setPermission(storedArrayAsString);
  }, [divi, currentQuter, finiYear, currentFiniYear]);
  return (
    <>
      <ErrorBoundary FallbackComponent={<ServerPage />}>
        <section class="section">
          <div class="row">
            <div
              id="exTab3"
              class="contain"
              style={{
                width: "80%",
                position: "absolute",
                right: "0rem",
                top: "3.5rem",
              }}
            >
              <div class="card">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={mainTabValue}>
                    <TabList
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      onChange={handleMainTabChange}
                    >
                      {/* Fil-view */}
                      {permission.includes("Fil-view") ? (
                        <Tab
                          label="FIL Report"
                          value="0"
                          className={`${
                            mainTabValue === "0" ? "activeClickCSS" : ""
                          }`}
                        />
                      ) : (
                        ""
                      )}
                      </TabList>
                  </TabContext>
                  <br />

                  {/*FIL Report*/}
                  {permission.includes("Fil-view") && mainTabValue === "0" && (
                    <TabContext>
                      <TabPanel>
                        <FilOutputReport
                          setDivision={divisions}
                          currentQuarter={currentQuarter}
                          financialYear={financialYear}
                          currentFinancialYear={currentFinancialYear}
                        />
                      </TabPanel>
                    </TabContext>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </section>
      </ErrorBoundary>
      
    </>
  );
};
