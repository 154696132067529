import React, { useEffect, useMemo, useState } from 'react';
import { useTable} from 'react-table';
import '../../assets/css/OutputTable.css'
import { Box, CircularProgress,Button, FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { getConfigWithToken } from '../../utils/Config/Config';
import Swal from 'sweetalert2';
import AxiosRetry from 'axios-retry';
import * as XLSX from 'xlsx';

export const OutputTableMRLTesting = ({ setDivision, currentQuarter, financialYear, currentFinancialYear }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([])
  const [selectDivision, setSelectDivision] = useState([])
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024")
  const [selectSubFilter, setSelectSubFilter] = useState("Q3")
  const [tableData, setTableData] = useState([])

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  function filterTransformData(data){

    const transformedData = data.map(entry => {
      const magnetCropIds = JSON.parse(entry.magnet_crop_id);
      const magnetCrops = magnetCropIds.map(crop => `${crop.name}, `);
  
      return {...entry, magnet_crop_id: magnetCrops}
  })
  return transformedData  
  }

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }else{
      const divisionData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }
  }

  const handleSelectFinancialYear = async (e) =>{
    setSelectFinancialYear(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }else{
      const divisionData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }
  }

  const handleSubFilter = async (e) =>{
    if(selectFinnacialYear.length === 0){
      Swal.fire(
        `Please Select Financial Year`,
        '',
        'warning'
      )
      return
    }
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(e.target.value)){
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(filterData.data.data[0]))
      
    }else{
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      
      setTableData(filterTransformData(filterData.data.data[0]))
    }
  }

  useEffect(()=>{
     try{
      async function getData(){
        const empFPOComponentResponse = await axiosInstance.get(`${apiUrl}api/supportToFarmersMrlTestingOutput?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`, getConfigWithToken())
        setSelectSubFilter(currentQuarter)
        setSelectFinancialYear(currentFinancialYear)
        setTableData(filterTransformData(empFPOComponentResponse.data.data[0]))
        setDivisions(setDivision);
        setLoading(false);
      }
      setTimeout(() => {
        getData()
        // setLoading(false);
      }, 2000);
      
     }catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire(
          'An refresh the Page',
          '',
          'error'
      );
      } else {
        throw new Error('Data Not Found');
      }
    }
     
  },[setDivision, financialYear, currentFinancialYear, currentQuarter])

  const columns = useMemo(
    () => [
     
      {
          Header: " ",
          columns: [
             
                {
                  Header: 'Beneficiary Name', 
                  accessor: 'beneficiary_name',
                },
                {
                  Header: 'MAGNET Crops(Multiple selection)', 
                  accessor: 'magnet_crop_id',
                },
                {
                  Header: 'Division', 
                  accessor: 'divisions',
                },
                {
                  Header: 'District', 
                  accessor: 'district',
                },
                {
                  Header: 'Taluka', 
                  accessor: 'taluka',
                },
                {
                  Header: 'Nos. of Farmers applying for MRL Testing', 
                  accessor: 'no_of_farmers',
                },
                {
                  Header: 'Name of Certification Testing Laboritory',
                  accessor: 'name_of_certification_agency_lab',
                },
                {
                  Header: 'Total Testing Cost',
                  accessor: 'total_cost',
                },
               
                {
                  Header: 'Total Sanction Grant from MAGNET (Lakh)',
                  accessor: 'total_saction_grant',
                },
                {
                    Header: 'Grant Disbursement Date ',
                    accessor: 'grant_disbursement_date',
                  },
                {
                  Header: 'Grant Disbursement Amount',
                  accessor: 'grant_disbursement_amt',
                },
              
          ]
      },
      {
          Header: "Vulnerable groups”& “vulnerability”",
          columns: [
            {
              Header: "Beneficiaries",
              columns: [
                  
                  {
                      Header: 'Beneficiaries (Male)',
                      accessor: 'beneficiaries_male',
                    },
                    {
                      Header: 'Beneficiaries (Female)',
                      accessor: 'beneficiaries_female',
                    },
                    {
                      Header: 'Beneficiaries (Total)',
                      accessor: 'beneficiaries_total',
                    },
              ]
          },
              {
                  Header: "SC",
                  columns: [
                      
                      {
                          Header: 'SC (Male)',
                          accessor: 'sc_male',
                        },
                        {
                          Header: 'SC (Female)',
                          accessor: 'sc_female',
                        },
                        {
                          Header: 'SC (Total)',
                          accessor: 'sc_total',
                        },
                  ]
              },
              {
                  Header: "ST",
                  columns: [
                      {
                          Header: 'ST (Male)',
                          accessor: 'st_male',
                        },
                        {
                          Header: 'ST (Female)',
                          accessor: 'st_female',
                        },
                        {
                          Header: 'ST (Total)',
                          accessor: 'st_total',
                        },
                      
                  ]
              },
             
              {
                Header: "OBC/ Minority/ Open",
                columns: [
                    {
                        Header: 'OBC/Minority/Open (Male)',
                        accessor: 'obc_minority_open_male',
                      },
                      {
                        Header: 'OBC/Minority/Open (Female)',
                        accessor: 'obc_minority_open_female',
                      },
                      {
                        Header: 'OBC/Minority/Open (Total)',
                        accessor: 'obc_minority_open_total',
                      },
                ]
            },
            {
              Header: "PWD",
              columns: [
                  {
                      Header: 'PWD (Male)',
                      accessor: 'pwd_male',
                    },
                    {
                      Header: 'PWD (Female)',
                      accessor: 'pwd_female',
                    },
                    {
                      Header: 'PWD (Total)',
                      accessor: 'pwd_total',
                    },
              ]
          },
          
          ]
      },
      
    ],
    []
  );

  
  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  const cellStyle = {
    padding: '8px',
    // border: '1px solid #ccc', // Add border style here
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
  };

  const exportToExcel = () => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const selectedDivision = divisions.find(
      (division) => division.id === selectDivision
    );
    const divisionName = selectedDivision
      ? selectedDivision.division_name
      : "All";
  
    // Header structure with merged cells
    const headers = [
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Vulnerable groups”& “vulnerability",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Beneficiary",
        "",
        "",
        "SC",
        "",
        "",
        "ST",
        "",
        "",
        "OBC/ Minority/ Open",
        "",
        "",
        "PWD",
        "",
        "",
      ],
      [
        "Financial Year",
        "Period",
        "FPO/VCO Name",
        "MAGNET Crops(Multiple selection)",
        "Division",
        "District",
        "Taluka",
        "Nos. of Farmers applying for MRL Testing",
        "Name of Certification Testing Laboritory",
        "Total Testing Cost",
        "Total Sanction Grant from MAGNET (Lakh)",
        "Grant Disbursement Date",
        "Grant Disbursement Amount",
        "Beneficiaries (Male)",
        "Beneficiaries (Female)",
        "Beneficiaries (Total)",
        "SC (Male)",
        "SC (Female)",
        "SC (Total)",
        "ST (Male)",
        "ST (Female)",
        "ST (Total)",
        "OBC/Minority/Open (Male)",
        "OBC/Minority/Open (Female)",
        "OBC/Minority/Open (Total)",
        "PWD (Male)",
        "PWD (Female)",
        "PWD (Total)",
      ],
    ];
  
    const merge = [
      { s: { r: 0, c: 13 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 13 }, e: { r: 1, c: 15 } },
      { s: { r: 1, c: 16 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 24 } },
      { s: { r: 1, c: 25 }, e: { r: 1, c: 27 } },
    ];
  
    // Convert the table data
    const data = [
      ...headers,
      ...tableData.map((row) => [
        selectFinnacialYear,
        selectSubFilter,
        row.beneficiary_name,
        Array.isArray(row.magnet_crop_id) ? row.magnet_crop_id.join("") : row.magnet_crop_id,
        row.divisions,
        row.district,
        row.taluka,
        row.no_of_farmers,
        row.name_of_certification_agency_lab,
        row.total_cost,
        row.total_saction_grant,
        row.grant_disbursement_date,
        row.grant_disbursement_amt,
        row.beneficiaries_male,
        row.beneficiaries_female,
        row.beneficiaries_total,
        row.sc_male,
        row.sc_female,
        row.sc_total,
        row.st_male,
        row.st_female,
        row.st_total,
        row.obc_minority_open_male,
        row.obc_minority_open_female,
        row.obc_minority_open_total,
        row.pwd_male,
        row.pwd_female,
        row.pwd_total,
      ]),
    ];
  
    // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
  
    // Apply merges
    ws["!merges"] = merge;
  
    // Apply styles (center align headers)
    const centerAlign = {
      alignment: { horizontal: "center", vertical: "center" },
    };
  
    headers[0].forEach((header, index) => {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (cell) {
        cell.s = { ...cell.s, ...centerAlign };
      } else {
        ws[XLSX.utils.encode_cell({ r: 0, c: index })] = {
          v: header,
          t: "s",
          s: centerAlign,
        };
      }
    });
  
    headers[1].forEach((header, index) => {
      const cell = ws[XLSX.utils.encode_cell({ r: 1, c: index })];
      if (cell) {
        cell.s = { ...cell.s, ...centerAlign };
      } else {
        ws[XLSX.utils.encode_cell({ r: 1, c: index })] = {
          v: header,
          t: "s",
          s: centerAlign,
        };
      }
    });
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "MRLTesting");
  
    // Generate a buffer for the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_MRLTesting.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  

  if (loading) {
    return (<>
        <Box sx={{ display: 'flex' }}>
        <CircularProgress />
        </Box>
    </>);
  }
  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
      <div className='col-md-5'>

      </div>
          <div className='col-md-7'>
            <div className='row'>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Financial Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectFinnacialYear}
                      label="Financial Year"
                      onChange={handleSelectFinancialYear}
                    >
                      {
                        financialYear.map((e)=>(
                          <MenuItem value={e}>{e}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-native-select">Period:</InputLabel>
                    <Select defaultValue="" id="grouped-select" label="Period" 
                    value={selectSubFilter} 
                    onChange={handleSubFilter}>
                      <ListSubheader>Half Yearly</ListSubheader>
                      <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                      <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                      <ListSubheader>Quarterly</ListSubheader>
                      <MenuItem value="Q1">Q1</MenuItem>
                      <MenuItem value="Q2">Q2</MenuItem>
                      <MenuItem value="Q3">Q3</MenuItem>
                      <MenuItem value="Q4">Q4</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-3'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Division</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectDivision}
                      label="Division"
                      onChange={handleSelectDivision}
                    >
                      {
                        divisions.map((e)=>(
                          <MenuItem value={e.id} key={e.id}>{e.division_name}</MenuItem>
                        ))
                      }
                    </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "160px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', overflowX: 'auto' , maxHeight: '445px'}}>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                    style={{
                      borderBottom: '2px solid #ccc',
                      background: '#f2f2f2', // Add grey background color
                      fontWeight: 'bold', // Optionally make text bold
                      border: column.Header === 'PWD' || (column.parent && column.parent.Header === 'PWD' && ['PWD (Male)', 'PWD (Female)', 'PWD (Total)'].includes(column.Header)) ? '4px solid #ccc' : '2px solid #ccc', // Thicker border for "PWD" and its children "Male", "Female", and "Total", normal border for others
                      ...cellStyle
                  }}>
                  {column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          border: '1px solid #ccc',
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
           
          </tbody>
          </table>
      </div>
    </>
  );
};


