import { Offline, Online } from "react-detect-offline";
import { Box } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../../../../utils/Config/Config";
import { setInputActivity } from "../../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useEffect, useState } from "react";
import "../../../../../assets/css/masterdata.css";
import { useDispatch, useSelector } from "react-redux";

export const GrandDetailsListingTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const { component } = useSelector((state) => state.mgBeneficiaryMaster);
  // const [GrandDetails, setGrandDetails] = useState([]);
  const [activityComponent, setActivityComponent] = useState([]);
  

  const submitasssss = (e) => {
    e.preventDefault();
    console.log(component, "idshoidsh");
  };

  const handleActivityValue = (id, technicalComponent, index, fieldName, value) => {
    const updatedInputActivity = component.map(item => {
        if (item.id === id) {
            let activityExists = false;
            const updatedActivity = item.activity.map(activityItem => {
                if (activityItem.activity === fieldName) {
                    activityExists = true;
                    return {
                        ...activityItem,
                        value: value
                    };
                }
                return activityItem;
            });
            if (!activityExists) {
                updatedActivity.push({
                    id: index,
                    activity: fieldName,
                    value: value
                });
            }
            return {
                ...item,
                activity: updatedActivity
            };
        }
        return item;
    });

    dispatch(setInputActivity(updatedInputActivity));
};


  const renderInputValue = (item, fieldName) => {
    const existingType = component.find((t) => t.id === item.id);

    if (existingType && existingType.activity && existingType.activity !== undefined) {
      const existingTypeActivity = existingType["activity"]
      // console.log(existingTypeActivity,"ssssssssssssssssssssss")
      const getAns = existingTypeActivity.find((t)=> t.activity === fieldName)
      return getAns.value;
    }
  
    return '';
  };

  const calculateSummary = () => {
    let totalProjectCost = 0;
    let promoterEquity = 0;
    let bankTermLoan = 0;
    let matchinggrant = 0;
    let tranch1 = 0;
    let tranch2 = 0;
    let tranch3 = 0;
   
  
    component.forEach(item => {
      const totalProjectCostActivity = item.activity && item.activity.find(a => a.activity === "total_project_cost");
      const promoterEquityActivity = item.activity && item.activity.find(a => a.activity === "promoter_equity");
      const bankTermLoanActivity = item.activity && item.activity.find(a => a.activity === "bank_term_loan");
      const MatchingGrantActivity = item.activity && item.activity.find(a=> a.activity === "matching_grant");
      const TranchActivity1 = item.activity && item.activity.find(a=> a.activity === "mg_1st_tranche");
      const TranchActivity2 = item.activity && item.activity.find(a=> a.activity === "mg_2nd_tranche");
      const TranchActivity3 = item.activity && item.activity.find(a=> a.activity === "mg_3rd_tranche");

      if (totalProjectCostActivity && !isNaN(totalProjectCostActivity.value) && totalProjectCostActivity.value.trim() !== "") {
        totalProjectCost += parseFloat(totalProjectCostActivity.value);
      }
      if (promoterEquityActivity && !isNaN(promoterEquityActivity.value) && promoterEquityActivity.value.trim() !== "") {
        promoterEquity += parseFloat(promoterEquityActivity.value);
      }
      if (bankTermLoanActivity && !isNaN(bankTermLoanActivity.value)&& bankTermLoanActivity.value.trim() !== "") {
        bankTermLoan += parseFloat(bankTermLoanActivity.value);
      }
      if (MatchingGrantActivity && !isNaN(MatchingGrantActivity.value)&& MatchingGrantActivity.value.trim() !== "") {
        matchinggrant += parseFloat(MatchingGrantActivity.value);
      }
      if (TranchActivity1 && !isNaN(TranchActivity1.value) && TranchActivity1.value.trim() !== "" ){
        tranch1 += parseFloat(TranchActivity1.value);
      }
      if (TranchActivity2 && !isNaN(TranchActivity2.value) && TranchActivity2.value.trim() !== "" ){
        tranch2 += parseFloat(TranchActivity2.value);
      }
      if (TranchActivity3 && !isNaN(TranchActivity3.value) && TranchActivity3.value.trim() !== "" ){
        tranch3 += parseFloat(TranchActivity3.value);
      }
    });
  
    return {
        totalProjectCost: totalProjectCost.toFixed(2),
        promoterEquity: promoterEquity.toFixed(2),
        bankTermLoan: bankTermLoan.toFixed(2),
        matchinggrant: matchinggrant.toFixed(2),
        tranch1: tranch1.toFixed(2),
        tranch2: tranch2.toFixed(2),
        tranch3: tranch3.toFixed(2),
    };
  };
console.log(calculateSummary().totalProjectCost,'calculateSummary().totalProjectCost')
  
  useEffect(() => {
    async function getData() {
      try {
        const tt = await axios.get(
          `${apiUrl}api/get-all-components-with-activity?all=1`,
          getConfigWithToken()
        );

        const compData = tt.data.data
        setActivityComponent(compData);

        let updatedData = compData.map(obj => ({
          ...obj,
          activity: obj.activity.map(act => ({...act, value: ""}))
      }));
      console.log(updatedData, "ssssssssssssssssssssssssssssssssss")
      if(component.length === 0){
        dispatch(setInputActivity(updatedData));
      }
        // Assuming "Master" is an array of objects in the response
        // setGrandDetails(masterdata.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Network Issue. Please try again.",
        });
      }
    }
    getData();
  }, [dispatch]);


  

  return (
    <>
      {/* <Online> */}
        <Box
          sx={{
            width: "100%",
            height: "maxContent",
            typography: "body1",
            border: "3px solid #E8E8E8",
            backgroundColor: "#FFFFFF",
          }}
        >
          {/* <button onClick={submitasssss}>Submit</button> */}

          <table
            style={{
              border: "1px solid white",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr style={{   textAlign: "left",   color: "white",   backgroundColor: "#4e2683", }}>
                <th style={{ textAlign: "center", width: "35%" }}>Components</th>
                {/* Add other header columns as needed */}
                <th style={{ textAlign: "center" }}>Total Project Cost</th>
                <th style={{ textAlign: "center" }}>Promoters Equity</th>
                <th style={{ textAlign: "center" }}>Bank Term Loan</th>
                <th style={{ textAlign: "center" }}>Matching Grant</th>
                <th style={{ textAlign: "center" }}>Matching Grant Tranche 1</th>
                <th style={{ textAlign: "center" }}>Matching Grant Tranche 2</th>
                <th style={{ textAlign: "center" }}>Matching Grant Tranche 3</th>
              </tr>
            </thead>
            <tbody>
              {activityComponent.map((item) => (
                  <tr key={item.id}>
                      <td style={{ border: "1px solid black" }}>{item.component}</td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"total_project_cost")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 1, "total_project_cost", event.target.value)
                              }
                          />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"promoter_equity")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 2, "promoter_equity", event.target.value)
                              }
                          />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"bank_term_loan")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 3, "bank_term_loan", event.target.value)
                              }
                          />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"matching_grant")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 4, "matching_grant", event.target.value)
                              }
                          />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"mg_1st_tranche")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 5, "mg_1st_tranche", event.target.value)
                              }
                            />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"mg_2nd_tranche")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 6, "mg_2nd_tranche", event.target.value)
                              }
                          />
                      </td>
                      <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                            type="number"
                            value={renderInputValue(item,"mg_3rd_tranche")}
                            onChange={(event) =>
                                handleActivityValue(item.id, item.component, 7, "mg_3rd_tranche", event.target.value)
                             }
                          />
                      </td>
                      {/* <td>
                          <input
                              style={{
                                  height: "25px",
                                  width: "100%",
                                  borderRadius: "7px",
                                  border: "1px solid #E2E2E2",
                                  backgroundColor: "#FFFFFF",
                              }}
                              type="number"
                              value={renderInputValue(item,"mg_4th_tranche")}
                              onChange={(event) =>
                                  handleActivityValue(item.id, item.component, 8, "mg_4th_tranche", event.target.value)
                              }
                          />
                      </td> */}
                  </tr>
                ))}
                 <tr style={{   textAlign: "center", border:'1px solid black' }}>
                    <td style={{   fontWeight: "bold", border:'1px solid black'  }}>Component Summary</td>
                    <td style={{   textAlign: "left", border:'1px solid black'  }}>{calculateSummary().totalProjectCost}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().promoterEquity}</td>
                    <td style={{   textAlign: "left", border:'1px solid black'  }}>{calculateSummary().bankTermLoan}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().matchinggrant}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().tranch1}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().tranch2}</td>
                    <td style={{   textAlign: "left", border:'1px solid black'  }}>{calculateSummary().tranch3}</td>
                  </tr>
            </tbody>
          </table>
        </Box>
      {/* </Online>
      <Offline>You're Offline, Please Check your Connection</Offline> */}
    </>
  );
};
