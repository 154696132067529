import { Link } from "react-router-dom"

export const MainNav = (props) =>{
    return(<>
        <li className="nav-item">
                {/* <a className="nav-link collapsed" href="index.html"> */}
                <Link className="nav-link collapsed" to={props.link}>
                <i className={props.icon}></i>
                <span>{props.navName}</span>
                </Link>
                {/* </a> */}
            </li>
    </>)
}