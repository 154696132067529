import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswer,
  setField,
  resetValidation,
  setInputField,
} from "../../../redux/slice/InputThree/SafeGuardInputThree/EnvSafeguardSlice";
import { InputField } from "../../InputField";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export const InputThreeSafeGuard = (props) => {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.envSafeGuard);
  const {
    validationErrors,
    power_source_id,
    total_power_augumented_in_grid,
    power_bill_saved,
    m_cub_of_rain_water_harvested,
    estimated_bill,
  } = useSelector((state) => state.envSafeGuard);

  const handlePowerSourceOption = (value) => {
    dispatch(setField({ field: "power_source_id", value }));
  };
  const handleOptionChange = (question, answer) => {
    // Dispatch the setAnswer action with the question and answer
    dispatch(setAnswer({ question, answer }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputField({ fieldName, value }));
  };

  return (
    <>
      <InputField
        label="Power Augumented in Grid"
        endAr="(in No.)"
        type="number"
        value={total_power_augumented_in_grid}
        onChange={(value)=>handleInputChange("total_power_augumented_in_grid",value)}
        onFocus={handleResetFocus}
        
      />
      <InputField
        label="Power Bill Saved"
        type="number"
        endAr="(in Thousand)"
        value={power_bill_saved}
        onChange={(value)=>handleInputChange("power_bill_saved",value)}
        onFocus={handleResetFocus}
      />
      <InputField
        label="M Cub of Rain water Harvested"
        type="number"
        endAr="(in ltr)"
        value={m_cub_of_rain_water_harvested}
        onChange={(value)=>handleInputChange("m_cub_of_rain_water_harvested",value)}
        onFocus={handleResetFocus}
        
      />
      <InputField
        label="Estimated bill"
        type="number"
        endAr="(in Thousand)"
        value={estimated_bill}
        onChange={(value)=>handleInputChange("estimated_bill",value)}
        onFocus={handleResetFocus}
       
      />
      <div className="col-md-4">
        <Box sx={{ maxWidth: "100%" }}>
          <FormControl
            fullWidth
            error={validationErrors.power_source_id !== "" ? true : false}
          >
            <InputLabel id="demo-simple-select-label">Power Source</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={power_source_id}
              onChange={(e) => handlePowerSourceOption(e.target.value)}
              label="powersources"
              onFocus={handleResetFocus}
            >
              {props.powerSource.map((option) => (
                <MenuItem value={option.id}>{option.power_source}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <FormControl error={validationErrors.safe_work !== "" ? true : false}>
        <div className="row" style={{ marginTop: "15px", marginLeft: "0px" }}>
          <div className={`col-md-8`}>
            <div className="form-floating">
              <p> Is it a safe working environment ?</p>
            </div>
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["safe_work"] === "Yes"}
              onChange={() => handleOptionChange("safe_work", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["safe_work"] === "No"}
              onChange={() => handleOptionChange("safe_work", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.safe_work}</FormHelperText>
      </FormControl>
      <FormControl error={validationErrors.sanitation !== "" ? true : false}>
        <div className="row" style={{ marginLeft: "0px" }}>
          <div className={`col-md-8`}>
            <div className="form-floating">
              <p>Is sanitation done ?</p>
            </div>
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["sanitation"] === "Yes"}
              onChange={() => handleOptionChange("sanitation", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["sanitation"] === "No"}
              onChange={() => handleOptionChange("sanitation", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.sanitation}</FormHelperText>
      </FormControl>
      {/* Question 2 */}
      <FormControl
        error={validationErrors.rainwater_harvesting !== "" ? true : false}
      >
        <div className="row" style={{ marginLeft: "0px" }}>
          <div className={`col-md-8`}>
            <div className="form-floating">
              <p>Is rainwater harvesting done ?</p>
            </div>
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="Yes"
              checked={selectedOption["rainwater_harvesting"] === "Yes"}
              onChange={() => handleOptionChange("rainwater_harvesting", "Yes")}
              onFocus={handleResetFocus}
            />
          </div>
          <div className={`col-md-2`}>
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="No"
              checked={selectedOption["rainwater_harvesting"] === "No"}
              onChange={() => handleOptionChange("rainwater_harvesting", "No")}
              onFocus={handleResetFocus}
            />
          </div>
        </div>
        <FormHelperText>{validationErrors.rainwater_harvesting}</FormHelperText>
      </FormControl>
    </>
  );
};
