import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import '../../assets/css/OutputTable.css';
import { Box, CircularProgress, Button, FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { getConfigWithToken } from '../../utils/Config/Config';
import Swal from 'sweetalert2';
import AxiosRetry from 'axios-retry';
import * as XLSX from 'xlsx';

export const OutputTablePhysicalStatus = ({ currentFinancialYear, financialYear, currentQuarter }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectSubFilter, setSelectSubFilter] = useState("");
  const [tableData, setTableData] = useState([]);

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  function filterTransformData(inputData) {
    const rearrangedData = inputData.flatMap(subArray => 
      subArray.map(entry => ({
        category: entry.category,
        sub_category: entry.sub_category,
        unit: entry.unit,
        target_units: entry.target_units,
        achievement_till_date: entry.achievement_till_date,
        // achievements_in_quarter: entry.achievements_in_quarter,
        target_remains: entry.target_remains,
        no_of_farmers: entry.no_of_farmers,
        total_cost: entry.total_cost,
        expenditure_till_date: entry.expenditure_till_date,
        // expenditure_till_current_quarter: entry.expenditure_till_current_quarter
      }))
    );
    return rearrangedData;
  }

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4'];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(`${apiUrl}api/physical-progress-input-one-output?&quarter_no=${selectSubFilter}&yearly=${e.target.value}`, getConfigWithToken());
      setTableData(filterTransformData(divisionData.data.data));
    } else {
      const divisionData = await axios.get(`${apiUrl}api/physical-progress-input-one-output?&half_yearly=${selectSubFilter}&yearly=${e.target.value}`, getConfigWithToken());
      setTableData(filterTransformData(divisionData.data.data));
    }
  }

  const handleSubFilter = async (e) => {
    if (selectFinnacialYear.length === 0) {
      Swal.fire(
        `Please Select Financial Year`,
        '',
        'warning'
      )
      return;
    }
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4'];
    if (quarr.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(`${apiUrl}api/physical-progress-input-one-output?&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`, getConfigWithToken());
      setTableData(filterTransformData(filterData.data.data));
    } else {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(`${apiUrl}api/physical-progress-input-one-output?&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`, getConfigWithToken());
      setTableData(filterTransformData(filterData.data.data));
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        const empFPOComponentResponse = await axiosInstance.get(`${apiUrl}api/physical-progress-input-one-output?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`, getConfigWithToken());
        setSelectFinancialYear(currentFinancialYear);
        setSelectSubFilter(currentQuarter);
        setTableData(filterTransformData(empFPOComponentResponse.data.data));
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          Swal.fire(
            'An refresh the Page',
            '',
            'error'
          );
        } else {
          throw new Error('Data Not Found');
        }
      }
    }

    setTimeout(() => {
      getData();
    }, 2000);
  }, [currentFinancialYear, financialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Sub Category',
        accessor: 'sub_category',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
      },
      {
        Header: 'Target (Units in number)',
        accessor: 'target_units',
      },
      {
        Header: 'Achievements till date (Units)',
        accessor: 'achievement_till_date',
      },
     
      {
        Header: 'Target Remains',
        accessor: 'target_remains',
      },
      {
        Header: 'Total Nos. of Farmers/Participants',
        accessor: 'no_of_farmers',
      },
      {
        Header: 'Total Cost (in Lakhs INR)',
        accessor: 'total_cost',
      },
      {
        Header: 'Expenditure till date (in Lakhs INR)',
        accessor: 'expenditure_till_date',
      },
     
    ],
    []
  );

  const tableHeight = useMemo(() => {
    const rowCount = tableData.length;
    const rowHeight = 40; // Adjust as needed
    const maxHeight = 500; // Maximum height for the table
    const calculatedHeight = rowCount * rowHeight;
    return calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
  }, [tableData]);

  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc',
    textAlign: 'center',
    verticalAlign: 'middle',
  };

  const exportToExcel = () => {
    let formattedData;

    if (tableData.length === 0) {
      formattedData = [{
        "Financial Year": selectFinnacialYear,
        "Period": selectSubFilter,
      }];
      columns.forEach(column => {
        formattedData[0][column.Header] = "";
      });
    } else {
      formattedData = tableData.map(row => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          "Period": selectSubFilter,
        };
        columns.forEach(column => {
          formattedRow[column.Header] = row[column.accessor];
        });
        return formattedRow;
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    const fileName = `${selectFinnacialYear}_${selectSubFilter}_PhysicalStatus.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  const stickyy = {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  };

  return (
    <>
      <div style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
        <div className='col-md-6'></div>
        <div className='col-md-5'>
          <div className='row'>
            <div className='col-md-4'>
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">Financial Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e} key={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className='col-md-4'>
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">Period:</InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-4">
              <Box sx={{width:'95%'}}>
                <Button
                  onClick={exportToExcel}
                  style={{ color: "#4e2683", fontWeight: "bold", fontSize: '13.4px', height: '53px', width: '160px', border: '0.9px solid #4e2683', marginLeft: '-9px' }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', overflowX: 'auto', maxHeight: '445px'}}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} style={{ borderBottom: '2px solid #ccc', background: '#f2f2f2', fontWeight: 'bold', border: '2px solid #ccc', ...cellStyle }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td {...cell.getCellProps()} style={{ padding: '8px', border: '1px solid #ccc', ...cellStyle }}>
                        {cellIndex === 0 && row.index > 0 && row.original.category === rows[row.index - 1].original.category ? (
                          <span></span>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
