import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  is_facility_in_opration: "",
  total_quantity_handled: "",
  use_by_msamb_type: "",

  total_capacity_utilised_frozan_storage: "",
  total_capacity_utilised_cold_storage: "",

  capacity_utilised_captive_frozen_storage: "",
  capacity_utilised_captive_cold_storage: "",

  capacity_utilised_job_frozen_storage: "",
  capacity_utilised_job_cold_storage: "",

  service_frozen_charges: "",
  service_cold_charges: "",

  number_of_farmers: "",
  members_of_fpo_small: "",
  members_of_fpo_medium: "",
  members_of_fpo_others: "",
  non_members_small: "",
  non_members_medium: "",
  non_members_others: "",

  validationErrors: {
    is_facility_in_opration: "",
    use_by_msamb_type: "",
    total_quantity_handled: "",
    total_capacity_utilised_frozan_storage: "",
    total_capacity_utilised_cold_storage: "",
    capacity_utilised_captive_frozen_storage: "",
    capacity_utilised_job_cold_storage: "",
    service_frozen_charges: "",
    service_cold_charges: "",
  },
};

const UtilizationColdStorageThreeSlice = createSlice({
  name: "utilizationColdStorageThree",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },
    updateApiDataToColdStorageThree: (state, action) => {
      const payload = action.payload;
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataColdStorageThree: (state) => {
      return { ...initialState };
    },
    setValidationErrorsColdStorageThree: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetColdStorageThreeValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setValidationErrorsColdStorageThree,
  resetColdStorageThreeValidation,
  setInputFieldData,
  updateApiDataToColdStorageThree,
  resetDataColdStorageThree,
} = UtilizationColdStorageThreeSlice.actions;

export default UtilizationColdStorageThreeSlice.reducer;
