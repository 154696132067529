/* eslint-disable no-fallthrough */
import '../assets/css/inputForm.css'
import axios from 'axios';
import { InputField } from '../subcomponents/InputField'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { setInputFieldData, updateApiDataToMaster, resetData, setValidationErrorsMaster } from '../redux/slice/InputTwo/MasterSubSlice';
import { updateConcFullTime, resetDataFullConc, setTotalGenMaleEmp, setTotalGenFemaleEmp, setTotalGenTotalEmp, setValidationErrorsEmpConcFull  } from '../redux/slice/InputTwo/EmpGenInputTwo/FullTimeSlice'
import { updateConcPartTime, resetDataPartConc, setValidationErrorsEmpConcPart } from '../redux/slice/InputTwo/EmpGenInputTwo/PartTimeSlice'
import { updateOperFullTime, resetDataFullOper, setValidationErrorsEmpOperFull, setTotalOperGenMaleEmp, setTotalOperGenFemaleEmp, setTotalOperGenTotalEmp } from '../redux/slice/InputTwo/OperGenInputTwo/FullTimeOperSlice'
import { updateOperPartTime, resetDataPartOper, setValidationErrorsEmpOperPart } from '../redux/slice/InputTwo/OperGenInputTwo/PartTimeOperSlice'
import { updateGESIForm, resetDataGesiForm, setValidationErrorsESI } from '../redux/slice/InputTwo/ESIInputTwo/radioSlice';
import { updateSafeGuardForm, resetDataSafeGuard } from '../redux/slice/InputTwo/SafeGuardInputTwo/safeGuardSlice';
import { updateFacilitiesGESIForm, resetDataFacilities } from '../redux/slice/InputTwo/FacilityInputTwo/FacilitySlice';
import { setValidationErrors } from '../redux/slice/InputTwo/FacilityInputTwo/FacilitySlice'
import { setValidationErrorsSafeGuard } from '../redux/slice/InputTwo/SafeGuardInputTwo/safeGuardSlice'

import { InputTwoTabThree} from '../subcomponents/Input2/Input2TabFormData/InputTwoTabThree';
import { FormHelperText, Tabs } from '@mui/material';
import { FullTime } from '../subcomponents/Input2/Input2EmpGen/FullTime';
import {PartTime} from '../subcomponents/Input2/Input2EmpGen/PartTime';
import { FullTimeOper } from '../subcomponents/Input2/Input2EmpGen/FullTimeOper';
import {PartTimeOper } from '../subcomponents/Input2/Input2EmpGen/PartTimeOper';
import Swal from 'sweetalert2'
import pdf from '../assets/PDF_file_icon.svg.png'
import imgIcon from '../assets/image_icon-icons.com_50366.png'
import { InputTwoTabFour } from '../subcomponents/Input2/Input2TabFourSafeGuard/InputTwoTabFour';
import { MasterSub } from '../subcomponents/Input2/Input2SubForm/MasterSub';
import { getConfigWithToken } from '../utils/Config/Config'

export const InputForm2 = ({quarter, facili, powerSou}) =>{
    const dd = useSelector(setInputFieldData);
    const dispatch = useDispatch();
    const [Loadingcir, setLoadingcir] = useState(true);
    const [loading, setLoading] = useState(false);
    const [quarters, setQuarter] = useState([])
    const [selectQuarter,setSelectedQuarters] = useState("")
    const [applicants, setApplicants] = useState([])
    const [components, setComponents] = useState([])
    const [isMatchingGrant, setIsMatchingGrant] = useState("")
    const [powerSource, setPowerSource] = useState([])
    const [facilities, setFacilities] = useState([])
    const [esiFpoApplicants, setEsiFpoApplicants] = useState("")
    const [errorFPOSelect, setErrorFPOSelect] = useState("")
    const [errorComponentSelect, setErrorCompSelect] = useState("")

    const [phySelectComponent, setPhySelectComponent] = useState("");
    const [phySelectData, setPhySelectData] = useState({});
    const [empConcSelectData, setEmpConcSelectData] = useState({});
    const [empOperSelectData, setEmpOperSelectData] = useState({});
    const [value, setValue] = useState('0');
    const [esiDivision, setEsiDivision] = useState({})
    const [esiDistrict, setEsiDistrict] = useState({})
    const [esiTaluka, setEsiTaluka] = useState({})
    const [totalNoMember, setTotalNoMember] = useState("")
    const [totalNoWomen, setTotalNoWomen] = useState("")
    const [paymentTranche, setPaymentTranche] = useState({})
    const [disbrushmentDate, setDisbrushmentDate] = useState("")
    const [amountDisbursement, setAmountDisbursement] = useState("")
    const [tranche_id, setTrancheID] = useState("")
    const [empType, setEmpType] = useState('Full Time Employment');
    const [empOperType, setEmpOperType] = useState('Full Time Employment');
    const [selectedFile, setSelectedFile] = useState([]);
    const [errorPhySelectFile, setErrorPhySelectFile] = useState("")
    const [selectEmpFile, setSelectEmpFile] = useState([]);
    const [errorEmpSelectFile, setErrorEmpSelectFile] = useState("")
    const [selectEmpOperFile, setSelectEmpOperFile] = useState([]);
    const [errorEmpOperSelectFile, setErrorEmpOperSelectFile] = useState("")
    const [phyFlagEdit, setPhyFlagEdit] = useState(false)
    const [empConcFlagEdit, setEmpConcFlagEdit] = useState(false)
    const [empOperFlagEdit, setEmpOperFlagEdit] = useState(false)
    const [gesiFlagEdit, setGesiFlagEdit] = useState(false)
    const [safguardFlagEdit, setSafguardFlagEdit] = useState(false)
    const [facilitiesGesiFlagEdit, setFacilitiesGesiFlagEdit] = useState(false)
    const [idPhysicalFormData, setIdPhysicalFormData] = useState("")
    const [idEmpConcFullFormData, setIdEmpConcFullFormData] = useState("")
    const [idEmpOperFullFormData, setIdEmpOperFullFormData] = useState("")
    const [idGESIFormData, setIdGESIFormData] = useState("")
    const [idSafeguardFormData, setIdSafeguardFormData] = useState("")
    const [idFacilitieGESIFormData, setIdFacilitieGESIFormData] = useState("")
    const [Facility,setFacility]=useState('Type of Facilities')
    const [permission, setPermission] = useState([])

    const apiUrl = process.env.REACT_APP_API_URL;
    const fileUrl = process.env.REACT_APP_INPUT2_API_FILE_URL;


    
    const checkFPOSelect = () =>{
        
        if(esiFpoApplicants.length === 0){
            setErrorFPOSelect("Please Select Applicant")
        }else{
            return true
        }
        return false
    }

    const checkComponentSelect = () =>{
        if(phySelectComponent.length === 0){
            setErrorCompSelect("Please Select Component")
        }else{
            return true
        }
        return false
    }

    const handleFileChange = (data) => {
        
        setSelectedFile(data);
        setErrorPhySelectFile("")
    };
    

    const handleEmpFile = async (event) => {
        
        setErrorEmpSelectFile("")
        setSelectEmpFile(event.target.files)
    }

    const handleEmpFileDeleteFile = async (id) =>{
        const deleteFile = await axios.get(`${apiUrl}api/remark-delete?id=${id}`,getConfigWithToken())
        if(deleteFile.data["status"]){
          const newRemarks = empConcSelectData.remarks.filter((remark) => remark.file_id !== id);
          // Update the state with the new remarks data
          setEmpConcSelectData({
            remarks: newRemarks,
          });
          Swal.fire(
            `File deleted Successfully`,
            '',
            'success'
        )
        }
    }


    const handleEmpOperFile = async (event) => {
        setSelectEmpOperFile(event.target.files)
    }

    const refershdata = () => {
        setEsiFpoApplicants(''); 
        setEsiDivision({});
        setEsiDistrict({});
        setEsiTaluka({});
        setPhySelectComponent('');
        
        setTotalNoMember("");
        setTotalNoWomen("");

        dispatch(resetDataFullConc());
        dispatch(resetDataPartConc());
        dispatch(resetDataFullOper());
        dispatch(resetDataPartOper());

        setEmpConcSelectData({})
        setEmpOperSelectData({});

        dispatch(resetDataGesiForm({}));

        dispatch(resetDataSafeGuard({}));
    }
    const handleQuarterChange = async (event) =>{
        refershdata()
        setPhyFlagEdit("")
        setEmpConcFlagEdit("")
        setEmpOperFlagEdit("")
        setGesiFlagEdit("")
        setSafguardFlagEdit("")
        setSelectedQuarters(event.target.value)
    }

    const handleResetButtonClick = () => {
        // Dispatch the action to reset the data in the Redux slice
        dispatch(resetData());
    };

    const handleComponentChangePhy = async (event) => {
        setErrorCompSelect("");
        if (esiFpoApplicants === "") {
            Swal.fire(
                `Please Select the Applicant First`,
                '',
                'warning'
            );
            return;
        }
        try {
            const checkEdit = await axios.get(`${apiUrl}api/get-PhyFinInpTwoBy-Com-App-Qua?quarter=${selectQuarter}&applicant_id=${esiFpoApplicants}&component_id=${event.target.value}`, getConfigWithToken());
            
            setPhySelectComponent(event.target.value);
            if (checkEdit.data["status"] === true) {
                const data = checkEdit.data["data"][0];
                setPhyFlagEdit(!!checkEdit.data["flag"]);
                setIdPhysicalFormData(data["id"]);
                setAmountDisbursement(data["amount_disbursement"]);
                setTrancheID(data["tranche_id"]); // Ensure this is set correctly
                setPhySelectData(data);
                setIsMatchingGrant(data["is_matching_grant"]);
                dispatch(updateApiDataToMaster(data));
                
                dispatch(setInputFieldData({ fieldName: 'promotersExpenditureInc', value: data["promoters_exp_incurred"] }));
                dispatch(setInputFieldData({ fieldName: 'matchingGrantExpenditureInc', value: data["matching_grant_exp_incurred"] }));
                dispatch(setInputFieldData({ fieldName: 'termLoanExpenditureInc', value: data["term_loan_exp_incurred"] }));
                dispatch(setInputFieldData({ fieldName: 'expenditureIncurred', value: data["exp_incurred"] }));
            } else {
                setPhyFlagEdit(false);
                const data = await axios.get(`${apiUrl}api/get_primarydata_by_applicantid_componentid?applicant_id=${esiFpoApplicants}&quarter=${selectQuarter}&component_id=${event.target.value}`, getConfigWithToken());
                
                setAmountDisbursement(data.data["data"]["amount_disbursement"]);
                setTrancheID(data.data["data"]["tranche_id"]); // Ensure this is set correctly
                setPhySelectData(data.data["data"]);
                setIsMatchingGrant(data.data["data"]["is_matching_grant"]);
                dispatch(updateApiDataToMaster(data.data["data"]));
                
                dispatch(setInputFieldData({ fieldName: 'promotersExpenditureInc', value: '' }));
                dispatch(setInputFieldData({ fieldName: 'matchingGrantExpenditureInc', value: '' }));
                dispatch(setInputFieldData({ fieldName: 'termLoanExpenditureInc', value: '' }));
                dispatch(setInputFieldData({ fieldName: 'expenditureIncurred', value: '' }));
            }
        } catch (err) {
            Swal.fire(
                'An error occurred on Selecting the Component',
                'Please refresh the Page',
                'error'
            );
        }
    };

    const handleEmpChange = (event) =>{
        setEmpType(event.target.value)
    }

    const handleEmpOperChange = (event) =>{
        setErrorEmpOperSelectFile("")
        setEmpOperType(event.target.value)
    }

    const resetValue = () => {
        setEsiTaluka({})
        setEsiDistrict({})
        setEsiDivision({})
        setPaymentTranche({})
        setDisbrushmentDate("")
        setEmpConcSelectData({})
        setTotalNoMember("")
        setTotalNoWomen("")
        setEsiFpoApplicants("")
        setPhySelectComponent("")
        dispatch(resetDataFullConc())
        dispatch(resetDataFullOper())
        dispatch(resetDataGesiForm())
        dispatch(resetDataPartConc())
        dispatch(resetDataPartOper())
        dispatch(resetDataSafeGuard())
    }

    const handleChange = async (event, newValue) => {
        resetValue()
       
        setValue(newValue);
    };


    const handleFacility = (event) => {
        setFacility(event.target.value);
    }; 

    const handlePhySubmit = async (event) =>{
        event.preventDefault();
      
        console.log(dd["payload"]["masterFormData"],'payload')
        const validateForm = () => {
            const errors = {};
            const safe = dd["payload"]["masterFormData"]
            
            const errorMessages = {
             
            };

            if(isMatchingGrant === true){
                errorMessages["matchingGrantExpenditureInc"]= 'Matching Grant Expenditure Incurred is required'
            }
              
            for (const field in errorMessages) {
                
                const value = safe[field];
                if (value === '') {
                  errors[field] = errorMessages[field];
                }
              }
            
            dispatch(setValidationErrorsMaster(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };

        
        if(checkFPOSelect() && checkComponentSelect() && validateForm()){
            setLoading(true);
            const formData = new FormData();
            console.log(formData,'formadtaa')
            formData.append("quarter", selectQuarter);
            formData.append("applicant_id", esiFpoApplicants);
            formData.append("component_id", phySelectComponent);
            formData.append("district_id", esiDistrict.district_id);
            formData.append("division_id", esiDivision.division_id);
            formData.append("taluka_id", esiTaluka.taluka_id);
            formData.append("amount_disbursement", amountDisbursement || '');
        

        const masterData = dd["payload"]["masterFormData"];
        formData.append("tranche_id", masterData["tranche"]||'');
        formData.append("disbursement_date", masterData["disbursement_date"] || '');
       
            formData.append("total_project_cost", dd["payload"]["masterFormData"]["totalProjectCost"]);
            formData.append("promoters_contribution", dd["payload"]["masterFormData"]["totalPromotersContribution"]);
            formData.append("matching_grant", dd["payload"]["masterFormData"]["totalMatchingGrant"]);
            formData.append("term_loan", dd["payload"]["masterFormData"]["totalTermLoan"]);
            formData.append("total_exp_till_date", dd["payload"]["masterFormData"]["totalExpenditureTD"]);
            formData.append("physical_progress_till_date_pct", dd["payload"]["masterFormData"]["physicalProgressTD"]);
            formData.append("exp_incurred", dd["payload"]["masterFormData"]["expenditureIncurred"]);
            formData.append("physical_progress", dd["payload"]["masterFormData"]["physical_progress"]);
            formData.append("physical_progress_tcv_td", dd["payload"]["masterFormData"]["physical_progress_tcv_td"]);
            formData.append("quarter_year", selectQuarter.split(' ').pop());

            
            // Append the selectedFile to the formData object
            // formData.append("remarks", selectedFile);
            let size = 0
            const maxSize = 5 * 1024 * 1024;
            if(selectedFile.length !== 0){
                for(let i=0; i<selectedFile.length;i++){
                    size += selectedFile[i].size
                    formData.append("remarks[]", selectedFile[i])
                }
            }
            if(size >= maxSize){
                setErrorPhySelectFile("File size should be less than 5 MB")
                setLoading(false);
                return
            }
            

            if(phyFlagEdit === false){
                
                try {
                    if(selectedFile.length === 0){
                        setErrorPhySelectFile("Please Select File")
                        setLoading(false);
                        return
                    }
                    const token = localStorage.getItem("token");
                    const config = {
                        headers: {
                        
                          Authorization: `Bearer ${token}`,
                        },
                      };
                    const phyFormFill = await axios.post(`${apiUrl}api/physical_financialinputs-create`,formData, config)
                    
                    const phyComponent = components.find(e=>e['id']===phySelectComponent).component  
                    if(phyFormFill.data["status"] === true){
                        Swal.fire(
                            `Physical Finance Submitted Successfully for ${phyComponent}`,
                            '',
                            'success'
                        ).then(() => {
                            handleFileChange(null);
                        handleResetButtonClick()
                            setLoading(false);
                          });
            
                        
                    }else{
                        Swal.fire(
                            `${phyFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
            
                    }
                }catch(err){
                    Swal.fire(
                        `${err.message}`,
                        "",
                        "warning"
                      );
                }
            }else{
                
                formData.append("id", idPhysicalFormData);
                try{
                    const phyFormFill = await axios.post(`${apiUrl}api/physical_financialinputs-update`,formData,getConfigWithToken())
                    
                    const phyComponent = components.find(e=>e['id']===phySelectComponent).component  
                    if(phyFormFill.data["status"] === true){
                        Swal.fire(
                            `Physical Finance Updated Successfully for ${phyComponent}`,
                            '',
                            'success'
                        ).then(() => {
                            handleResetButtonClick()
                            setLoading(false);
                          });
                    }else{
                        Swal.fire(
                            `${phyFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
            
                    }
                }catch(err){
                    Swal.fire(
                        `${err.message}`,
                        "",
                        "warning"
                      );
                }
                
            }
        }
    
    }

    const handleEmpSubmit = async (e) =>{
        e.preventDefault();
        const validateForm = () => {
            const errorsfull = {};
            const errorsPart = {};
            const full = dd["payload"]["fullTimeEmp"]
            const part = dd["payload"]["partTimeEmp"]
            
            const errorMessagesfull = {
                'sc_male': 'SC Male is required',
                'sc_female': 'SC Female is required',
                'st_male': 'ST Male is required',
                'st_female': 'ST Female is required',
                'pwd_male': 'PWD Male is required',
                'pwd_female': 'PWD Female is required',
                'obcMinorityOpen_male': 'OBC/Minority/Open Male is required',
                'obcMinorityOpen_female': 'OBC/Minority/Open Female is required',
              };
            const errorMessagesPart = {
                'sc_male': 'SC Male is required',
                'sc_female': 'SC Female is required',
                'st_male': 'ST Male is required',
                'st_female': 'ST Female is required',
                'pwd_male': 'PWD Male is required',
                'pwd_female': 'PWD Female is required',
                'obcMinorityOpen_male': 'OBC/Minority/Open Male is required',
                'obcMinorityOpen_female': 'OBC/Minority/Open Female is required',
              };
            for (const field in errorMessagesfull) {
                const [category, gender] = field.split('_');
                const value = full[category][gender];
                if (value === '') {
                    errorsfull[field] = errorMessagesfull[field];
                }
            }
            for (const field in errorMessagesPart) {
                const [category, gender] = field.split('_');
                const value = part[category][gender];
                if (value === '') {
                  errorsPart[field] = errorMessagesPart[field];
                }
            }
            dispatch(setValidationErrorsEmpConcFull(errorsfull));
            dispatch(setValidationErrorsEmpConcPart(errorsPart))
            // Return true if the form is valid, otherwise return false
            return (Object.keys(errorsfull).length === 0) && (Object.keys(errorsPart).length === 0);
        };

        
        if(checkFPOSelect() && validateForm()){
            setLoading(true);
            const fullTime = dd["payload"]["fullTimeEmp"]
            const partTime = dd["payload"]["partTimeEmp"]
    
            const formData = new FormData();
                formData.append("applicant_id", esiFpoApplicants);
                formData.append("division_id", esiDivision.division_id);
                formData.append("district_id", esiDistrict.district_id);
                formData.append("taluka_id", esiTaluka.taluka_id);
                formData.append("quarter", selectQuarter);
                formData.append("full_time_emp_num_male", fullTime["numFullTimeEmployment"]["totalMale"])
                formData.append("full_time_emp_num_female", fullTime["numFullTimeEmployment"]["totalFemale"])
                formData.append("full_time_emp_num_total", fullTime["numFullTimeEmployment"]["totalMF"])
                formData.append("full_time_emp_num_sc_male", fullTime["sc"]["male"])
                formData.append("full_time_emp_num_sc_female", fullTime["sc"]["female"])
                formData.append("full_time_emp_num_sc_total", fullTime["sc"]["total"])
                formData.append("full_time_emp_num_st_male", fullTime["st"]["male"])
                formData.append("full_time_emp_num_st_female", fullTime["st"]["female"])
                formData.append("full_time_emp_num_st_total", fullTime["st"]["total"])
                formData.append("full_time_emp_num_pwd_male", fullTime["pwd"]["male"])
                formData.append("full_time_emp_num_pwd_female", fullTime["pwd"]["female"])
                formData.append("full_time_emp_num_pwd_total", fullTime["pwd"]["total"])
                formData.append("full_time_emp_num_obc_minority_open_male", fullTime["obcMinorityOpen"]["male"])
                formData.append("full_time_emp_num_obc_minority_open_female", fullTime["obcMinorityOpen"]["female"])
                formData.append("full_time_emp_num_obc_minority_open_total", fullTime["obcMinorityOpen"]["total"])
                formData.append("total_emp_generated", fullTime["totalEmploymentGenerated"]["totalTrainees"])
                formData.append("part_time_emp_num_male", partTime["numFullTimeEmployment"]["totalMale"])
                formData.append("part_time_emp_num_female", partTime["numFullTimeEmployment"]["totalFemale"])
                formData.append("part_time_emp_num_total", partTime["numFullTimeEmployment"]["totalMF"])
                formData.append("part_time_emp_num_sc_male", partTime["sc"]["male"])
                formData.append("part_time_emp_num_sc_female", partTime["sc"]["female"])
                formData.append("part_time_emp_num_sc_total", partTime["sc"]["total"])
                formData.append("part_time_emp_num_st_male", partTime["st"]["male"])
                formData.append("part_time_emp_num_st_female", partTime["st"]["female"])
                formData.append("part_time_emp_num_st_total", partTime["st"]["total"])
                formData.append("part_time_emp_num_pwd_male", partTime["pwd"]["male"])
                formData.append("part_time_emp_num_pwd_female", partTime["pwd"]["female"])
                formData.append("part_time_emp_num_obc_minority_open_male", partTime["obcMinorityOpen"]["male"])
                formData.append("part_time_emp_num_obc_minority_open_female", partTime["obcMinorityOpen"]["female"])
                formData.append("part_time_emp_num_obc_minority_open_total", partTime["obcMinorityOpen"]["total"])
    
                let size = 0
                const maxSize = 5 * 1024 * 1024;
                if(selectEmpFile.length !== 0){
                    for(let i=0; i<selectEmpFile.length;i++){
                        size += selectEmpFile[i].size
                        formData.append("remarks[]", selectEmpFile[i])
                    }
                }


                if(size >= maxSize){
                    setErrorEmpSelectFile("File size should be less than 5 MB")
                    setLoading(false);
                    return
                }
    
                try {
    
                    if(empConcFlagEdit !== true){
                       
                        if(selectEmpFile.length === 0){
                            setErrorEmpSelectFile("Please Select File")
                            setLoading(false);
                            return
                        }
                        const empFormFill = await axios.post(`${apiUrl}api/getEmpGenConsInpTwo-create`,formData,getConfigWithToken())
                        
                        if(empFormFill.data["status"] === true){
                            Swal.fire(
                                `${empFormFill.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullConc());
                                dispatch(resetDataPartConc());
                                setSelectEmpFile([])
                                setLoading(false);
                              });
                            
                        }else{
                            Swal.fire(
                                `${empFormFill.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }else{
                        formData.append("id", idEmpConcFullFormData);
                        const empFormFill = await axios.post(`${apiUrl}api/getEmpGenConsInpTwo-update`,formData,getConfigWithToken())
                        
                        if(empFormFill.data["status"] === true){
                            Swal.fire(
                                `${empFormFill.data["message"]}`,
                                '',
                                'success'
                            ).then(() => {
                                dispatch(resetDataFullConc());
                            dispatch(resetDataPartConc());
                            setSelectEmpFile([])
                                setLoading(false);
                                });
                          
                        }else{
                            Swal.fire(
                                `${empFormFill.data["message"]}`,
                                '',
                                'warning'
                            ).then(() => {
                                setLoading(false);
                                });
                        }
                    }
                    
                }catch(err){
                    Swal.fire(
                        `${err.message}`,
                        "",
                        "warning"
                      );
                }
        }
        

    }

    const handleEmpOperSubmit = async (e) =>{
        e.preventDefault();
        const validateForm = () => {
            const errorsfull = {};
            const errorsPart = {};
            const full = dd["payload"]["fullTimeOperEmp"]
            const part = dd["payload"]["partTimeOperEmp"]
            
            const errorMessagesfull = {
                'sc_male': 'SC Male is required',
                'sc_female': 'SC Female is required',
                'st_male': 'ST Male is required',
                'st_female': 'ST Female is required',
                'pwd_male': 'PWD Male is required',
                'pwd_female': 'PWD Female is required',
                'obcMinorityOpen_male': 'OBC/Minority/Open Male is required',
                'obcMinorityOpen_female': 'OBC/Minority/Open Female is required',
              };
            const errorMessagesPart = {
                'sc_male': 'SC Male is required',
                'sc_female': 'SC Female is required',
                'st_male': 'ST Male is required',
                'st_female': 'ST Female is required',
                'pwd_male': 'PWD Male is required',
                'pwd_female': 'PWD Female is required',
                'obcMinorityOpen_male': 'OBC/Minority/Open Male is required',
                'obcMinorityOpen_female': 'OBC/Minority/Open Female is required',
              };
            for (const field in errorMessagesfull) {
                const [category, gender] = field.split('_');
                const value = full[category][gender];
                if (value === '') {
                    errorsfull[field] = errorMessagesfull[field];
                }
            }
            for (const field in errorMessagesPart) {
                const [category, gender] = field.split('_');
                const value = part[category][gender];
                if (value === '') {
                  errorsPart[field] = errorMessagesPart[field];
                }
            }
            dispatch(setValidationErrorsEmpOperFull(errorsfull));
            dispatch(setValidationErrorsEmpOperPart(errorsPart))
            // Return true if the form is valid, otherwise return false
            return (Object.keys(errorsfull).length === 0) && (Object.keys(errorsPart).length === 0);
        };
      
        if(checkFPOSelect() && validateForm()){
            setLoading(true);
            const fullTime = dd["payload"]["fullTimeOperEmp"]
            const partTime = dd["payload"]["partTimeOperEmp"]

            const formData = new FormData();
            formData.append("applicant_id", esiFpoApplicants);
            formData.append("division_id", esiDivision.division_id);
            formData.append("district_id", esiDistrict.district_id);
            formData.append("taluka_id", esiTaluka.taluka_id);
            formData.append("quarter", selectQuarter);
            formData.append("full_time_emp_num_male", fullTime["numFullTimeEmployment"]["totalMale"])
            formData.append("full_time_emp_num_female", fullTime["numFullTimeEmployment"]["totalFemale"])
            formData.append("full_time_emp_num_total", fullTime["numFullTimeEmployment"]["totalMF"])
            formData.append("full_time_emp_num_sc_male", fullTime["sc"]["male"])
            formData.append("full_time_emp_num_sc_female", fullTime["sc"]["female"])
            formData.append("full_time_emp_num_sc_total", fullTime["sc"]["total"])
            formData.append("full_time_emp_num_st_male", fullTime["st"]["male"])
            formData.append("full_time_emp_num_st_female", fullTime["st"]["female"])
            formData.append("full_time_emp_num_st_total", fullTime["st"]["total"])
            formData.append("full_time_emp_num_pwd_male", fullTime["pwd"]["male"])
            formData.append("full_time_emp_num_pwd_female", fullTime["pwd"]["female"])
            formData.append("full_time_emp_num_pwd_total", fullTime["pwd"]["total"])
            formData.append("full_time_emp_num_obc_minority_open_male", fullTime["obcMinorityOpen"]["male"])
            formData.append("full_time_emp_num_obc_minority_open_female", fullTime["obcMinorityOpen"]["female"])
            formData.append("full_time_emp_num_obc_minority_open_total", fullTime["obcMinorityOpen"]["total"])
            formData.append("total_emp_generated", fullTime["totalEmploymentGenerated"]["totalTrainees"])
            formData.append("part_time_emp_num_male", partTime["numFullTimeEmployment"]["totalMale"])
            formData.append("part_time_emp_num_female", partTime["numFullTimeEmployment"]["totalFemale"])
            formData.append("part_time_emp_num_total", partTime["numFullTimeEmployment"]["totalMF"])
            formData.append("part_time_emp_num_sc_male", partTime["sc"]["male"])
            formData.append("part_time_emp_num_sc_female", partTime["sc"]["female"])
            formData.append("part_time_emp_num_sc_total", partTime["sc"]["total"])
            formData.append("part_time_emp_num_st_male", partTime["st"]["male"])
            formData.append("part_time_emp_num_st_female", partTime["st"]["female"])
            formData.append("part_time_emp_num_st_total", partTime["st"]["total"])
            formData.append("part_time_emp_num_pwd_male", partTime["pwd"]["male"])
            formData.append("part_time_emp_num_pwd_female", partTime["pwd"]["female"])
            formData.append("part_time_emp_num_pwd_total", partTime["pwd"]["total"])
            formData.append("part_time_emp_num_obc_minority_open_male", partTime["obcMinorityOpen"]["male"])
            formData.append("part_time_emp_num_obc_minority_open_female", partTime["obcMinorityOpen"]["female"])
            formData.append("part_time_emp_num_obc_minority_open_total", partTime["obcMinorityOpen"]["total"])

            // Append the selectedFile to the formData object
            // formData.append("remarks", selectEmpOperFile);

            let size = 0
            const maxSize = 5 * 1024 * 1024;
            if(selectEmpOperFile.length !== 0){
                for(let i=0; i<selectEmpOperFile.length;i++){
                    size += selectEmpOperFile[i].size
                    formData.append("remarks[]", selectEmpOperFile[i])
                }
            }

            if(size >= maxSize){
                setErrorEmpOperSelectFile("File size should be less than 5 MB")
                setLoading(false);
                 return
            }

            try {

                if(empOperFlagEdit !== true){
                   
                    if(selectEmpOperFile.length === 0){
                        setErrorEmpOperSelectFile("Please Select File")
                        setLoading(false);
                        return
                    }

                    const empFormFill = await axios.post(`${apiUrl}api/getEmpGenOprInpTwo-create`,formData,getConfigWithToken())
                    
                    if(empFormFill.data["status"] === true){
                        Swal.fire(
                            `${empFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataFullOper())
                        dispatch(resetDataPartOper())
                        setErrorEmpOperSelectFile("")
                            setLoading(false);
                            });
                      
                    }else{
                        Swal.fire(
                            `${empFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }else{
                    formData.append("id", idEmpOperFullFormData);
                    const empFormFill = await axios.post(`${apiUrl}api/getEmpGenOprInpTwo-update`,formData,getConfigWithToken())
                    
                    if(empFormFill.data["status"] === true){
                        Swal.fire(
                            `${empFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataFullOper())
                            dispatch(resetDataPartOper())
                            setLoading(false);
                            });
                       
                    }else{
                        Swal.fire(
                            `${empFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }
                
            }catch(err){
                Swal.fire(
                    `${err.message}`,
                    "",
                    "warning"
                  );
            }
        }
        

    }

    const handleEsiFPOSubmit = async (e) => {
        e.preventDefault();
        const validateForm = () => {
            const errors = {};    
            
            const ESI = dd["payload"]["esiFpoRadio"]
            const errorMessages = {
                num_of_women_have_farm_land_in_family_name: 'This field is required',
                num_of_sc_women: 'This field is required',
                num_of_st_women: 'This field is required',
                num_of_disabled_women: 'This field is required',
                num_of_women_below_poverty_line: 'This field is required',
                total_num_of_toilets_men: 'This field is required',
                total_num_of_toilets_women: 'This field is required',
                total_num_of_toilets_disabled: 'This field is required',
                ramps_for_disabled:'This field is required',
                work_env_safe_for_women:'This field is required',
                separate_changing_rooms:'This field is required',
                committee_of_women:'This field is required',
                practice_of_forced_labor:'This field is required',
                compensation_act_enforced:'This field is required',
                safety_reg_followed:'This field is required',
                labor_law_followed:'This field is required',
                workplace_clean_and_safe:'This field is required',
                equal_pay_for_equal_work_followed:'This field is required',
                dispute_resolved:'This field is required',
                bonus_paid:'This field is required',
            };
            for (const field in errorMessages) {
                const value = ESI[field];
                if (value === '') {
                errors[field] = errorMessages[field];
                }
            }
            
            dispatch(setValidationErrorsESI(errors));
        // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };
        
        
        if(checkFPOSelect() && validateForm()){
            setLoading(true);
            const ESI = dd["payload"]["esiFpoRadio"]
            const newESIObj = {...ESI, 
                "quarter": selectQuarter,
                "applicant_id": esiFpoApplicants,
                "district_id": esiDistrict.district_id,
                "division_id": esiDivision.division_id,
                "taluka_id": esiTaluka.taluka_id,
                "total_num_of_members": totalNoMember,
                "total_num_of_women": totalNoWomen
            }
            
            try {
                if(gesiFlagEdit !== true){
                    const esiFormFill = await axios.post(`${apiUrl}api/getEmpGsiFpoVcoInpTwo-create`, newESIObj, getConfigWithToken())
                    

                    if(esiFormFill.data["status"] === true){
                        Swal.fire(
                            `${esiFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataGesiForm())
                            setLoading(false);
                            });
                    }else{
                        Swal.fire(
                            `${esiFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }else{
                    newESIObj["id"] = idGESIFormData
                    const esiFormFill = await axios.post(`${apiUrl}api/getEmpGsiFpoVcoInpTwo-update`, newESIObj, getConfigWithToken())
                    

                    if(esiFormFill.data["status"] === true){
                        Swal.fire(
                            `${esiFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataGesiForm())
                            setLoading(false);
                            });
                    }else{
                        Swal.fire(
                            `${esiFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }
                
            }catch(err){
                Swal.fire(
                    `${err.message}`,
                    "",
                    "warning"
                  );
            }
        }
        

    }

    const handleSafeGuardSubmit = async (e) =>{ 
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const safe = dd["payload"]["safeGuard"]
            
            
            // eslint-disable-next-line default-case
            const errorMessages = {
                safe_work: 'Safe Work is required',
                sanitation: 'Sanitation is required',
                power_source_id: 'Power Source Facilities',
                rainwater_harvesting: 'Rainwater Harvesting is required',
                total_power_augumented_in_grid: 'Total Power Augmented is required',
                power_bill_saved: 'Power Bill is required',
                m_cub_of_rain_water_harvested: 'Rain Water is required',
                estimated_bill: 'Estimated Bill is required',
              };
            for (const field in errorMessages) {
                const value = safe[field];
                if (value === '') {
                  errors[field] = errorMessages[field];
                }
              }
            
            dispatch(setValidationErrorsSafeGuard(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };
        if(checkFPOSelect() && validateForm()){
            setLoading(true);
            const safeGuard = dd["payload"]["safeGuard"]
            const newSafeGuardPayload = {...safeGuard, 
                "quarter": selectQuarter,
                "applicant_id": esiFpoApplicants,
                "district_id": esiDistrict.district_id,
                "division_id": esiDivision.division_id,
                "taluka_id": esiTaluka.taluka_id,
            }
            
            try {
                if(safguardFlagEdit !== true){
                    const safeGuardFormFill = await axios.post(`${apiUrl}api/safeguard-inputdata-create`, newSafeGuardPayload, getConfigWithToken())
                    

                    if(safeGuardFormFill.data["status"] === true){
                        Swal.fire(
                            `${safeGuardFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                        dispatch(resetDataSafeGuard())
                            setLoading(false);
                            });

                    }else{
                        Swal.fire(
                            `${safeGuardFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }else{
                    newSafeGuardPayload["id"] = idSafeguardFormData
                    const safeGuardFormFill = await axios.post(`${apiUrl}api/safeguard-inputdata-update`, newSafeGuardPayload, getConfigWithToken())
                    

                    if(safeGuardFormFill.data["status"] === true){
                        Swal.fire(
                            `${safeGuardFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataSafeGuard())
                            setLoading(false);
                            });
                    }else{
                        Swal.fire(
                            `${safeGuardFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }
                
            }catch(err){
                Swal.fire(
                    `${err.message}`,
                    "",
                    "warning"
                  );
            }
        }

    }

    const handleFacilitySubmit = async (e) =>{
        e.preventDefault()
        const validateForm = () => {
            const errors = {};
            const fac = dd["payload"]["facility"]
            
            if (fac.capacity === '') {
                errors.capacity = 'Capacity is required';
            }
            if(fac.progress === ''){
                errors.progress = 'Progress is required';
            }
            if(fac.facility_id.length === 0){
                errors.facility_id = 'Select Facilities';
            }
            if(fac.action_taken.trim() === ''){
                errors.action_taken = 'Action is required';
            }
            if(fac.suggestions_changes_made.trim() === ''){
                errors.suggestions_changes_made = 'Suggestion is required';
            }
            
            dispatch(setValidationErrors(errors));
            // Return true if the form is valid, otherwise return false
            return Object.keys(errors).length === 0;
        };
        
        
        
        if(checkFPOSelect() && validateForm()){
            setLoading(true);
            const facilities = dd["payload"]["facility"]
            const newFacility = {...facilities, 
                "quarter": selectQuarter,
                "applicant_id": esiFpoApplicants,
                "district_id": esiDistrict.district_id,
                "division_id": esiDivision.division_id,
                "taluka_id": esiTaluka.taluka_id,
            }

            try {
                if(facilitiesGesiFlagEdit !== true){
                    const facilityFormFill = await axios.post(`${apiUrl}api/changein-facility-create`, newFacility, getConfigWithToken())

                    if(facilityFormFill.data["status"] === true){
                        Swal.fire(
                            `${facilityFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            setLoading(false);
                            dispatch(resetDataFacilities())
                        handleResetButtonClick()
                            });
                        
                    }else{
                        Swal.fire(
                            `${facilityFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }else{
                    newFacility["id"] = idFacilitieGESIFormData
                    const facilityFormFill = await axios.post(`${apiUrl}api/changein-facility-update`, newFacility, getConfigWithToken())

                    if(facilityFormFill.data["status"] === true){
                        Swal.fire(
                            `${facilityFormFill.data["message"]}`,
                            '',
                            'success'
                        ).then(() => {
                            dispatch(resetDataFacilities())
                        handleResetButtonClick()
                            setLoading(false);
                            });
                       
                    }else{
                        Swal.fire(
                            `${facilityFormFill.data["message"]}`,
                            '',
                            'warning'
                        ).then(() => {
                            setLoading(false);
                            });
                    }
                }
            }catch(err){
                Swal.fire(
                    `${err.message}`,
                    "",
                    "warning"
                  );
            }
        }
        
    }
    

    const handleApplicantChangeESIFPO = async (event) =>{
        setErrorFPOSelect("")
        const applicantId = event.target.value
        setEsiFpoApplicants(applicantId)
        setPhySelectComponent("")
        try{
            const applicant = await axios.get(`${apiUrl}api/get_primarydata_by_applicantid?applicant_id=${applicantId}`,getConfigWithToken())
          
            setEsiTaluka(applicant.data["data"]["taluka"])
            setEsiDistrict(applicant.data["data"]["district"])
            setEsiDivision(applicant.data["data"]["divisions"])
            setPaymentTranche(applicant.data["data"]["tranche"])
            setDisbrushmentDate(applicant.data["data"]["disbursement_date"])

            if(value ==='2'){
                const requestData = {
                    applicant_id: applicantId, // Replace with your actual value
                  };
                const response = await axios.get(`${apiUrl}api/getTotalMembersAllWomen`, {
                    headers: getConfigWithToken().headers,
                    params: requestData, // Send the data as query parameters
                  })
                
                const res = response.data["data"][0]
                if(response.data["data"].length !== 0){
                    setTotalNoMember(res.shareholders)
                    setTotalNoWomen(res.female)
                }else{
                    setTotalNoMember("")
                    setTotalNoWomen("")
                }

                const gesiResponse = await axios.get(`${apiUrl}api/getGesiFpoVcoInpTwoByAppQua?quarter=${selectQuarter}&applicant_id=${applicantId}`,getConfigWithToken())
                if(gesiResponse.data["status"] === true){
                    gesiResponse.data["flag"]?setGesiFlagEdit(true):setGesiFlagEdit(false)
                    setIdGESIFormData(gesiResponse.data["data"][0]["id"])
                    dispatch(updateGESIForm(gesiResponse.data["data"][0]))
                }else{
                    setGesiFlagEdit(false)
                    dispatch(resetDataGesiForm())
                }
                
            }
            if(value === '1'){
                const response = await axios.get(`${apiUrl}api/getEmpGenConsInpTwoByAppQua?quarter=${selectQuarter}&applicant_id=${applicantId}`,getConfigWithToken())
                
                if(response.data["status"] === true){
                    response.data["flag"]?setEmpConcFlagEdit(true):setEmpConcFlagEdit(false)
                    setIdEmpConcFullFormData(response.data["data"][0]["id"])
                    setEmpConcSelectData(response.data["data"][0])
                    dispatch(updateConcFullTime(response.data["data"][0]))
                    dispatch(updateConcPartTime(response.data["data"][0]))
                    
                    const mm = response.data["data"][0]["full_time_emp_num_male"] + response.data["data"][0]["part_time_emp_num_male"]
                    const ff = response.data["data"][0]["full_time_emp_num_female"] + response.data["data"][0]["part_time_emp_num_female"]
                    const tt = response.data["data"][0]["full_time_emp_num_total"] + response.data["data"][0]["part_time_emp_num_total"]
                    console.log(mm, ff, tt, "totallllllllllll")
                    dispatch(setTotalGenMaleEmp(mm))
                    dispatch(setTotalGenFemaleEmp(ff))
                    dispatch(setTotalGenTotalEmp(tt))
                }else{
                    setEmpConcFlagEdit(false)
                    setEmpConcSelectData({})
                    dispatch(resetDataFullConc())
                    dispatch(resetDataPartConc())
                }
            }
            if(value === '3'){
                const response = await axios.get(`${apiUrl}api/getSafeGuardInpTwoByAppQua?quarter=${selectQuarter}&applicant_id=${applicantId}`,getConfigWithToken())
                
                if(response.data["status"] === true){
                    response.data["flag"]?setSafguardFlagEdit(true):setSafguardFlagEdit(false)
                    setIdSafeguardFormData(response.data["data"][0]["id"])
                    dispatch(updateSafeGuardForm(response.data["data"][0]))
                }else{
                    setSafguardFlagEdit(false)
                    dispatch(resetDataSafeGuard())
                }
            }
            if(value === '4'){
                const response = await axios.get(`${apiUrl}api/getChangesInFacGesInpTwoByAppQua?quarter=${selectQuarter}&applicant_id=${applicantId}`,getConfigWithToken())
                
                if(response.data["status"] === true){
                    response.data["flag"]?setFacilitiesGesiFlagEdit(true):setFacilitiesGesiFlagEdit(false)
                    setIdFacilitieGESIFormData(response.data["data"][0]["id"])
                    dispatch(updateFacilitiesGESIForm(response.data["data"][0]))
                }else{
                    setFacilitiesGesiFlagEdit(false)
                    dispatch(resetDataFacilities())
                }
            }
            if(value === '6'){
                const response = await axios.get(`${apiUrl}api/getEmpGenOperInpTwoByAppQua?quarter=${selectQuarter}&applicant_id=${applicantId}`,getConfigWithToken())
                
                if(response.data["status"] === true){
                    response.data["flag"]?setEmpOperFlagEdit(true):setEmpOperFlagEdit(false)
                    setIdEmpOperFullFormData(response.data["data"][0]["id"])
                    setEmpOperSelectData(response.data["data"][0])
                    dispatch(updateOperFullTime(response.data["data"][0]))
                    dispatch(updateOperPartTime(response.data["data"][0]))

                    const mm = response.data["data"][0]["full_time_emp_num_male"] + response.data["data"][0]["part_time_emp_num_male"]
                    const ff = response.data["data"][0]["full_time_emp_num_female"] + response.data["data"][0]["part_time_emp_num_female"]
                    const tt = response.data["data"][0]["full_time_emp_num_total"] + response.data["data"][0]["part_time_emp_num_total"]
                    console.log(mm, ff, tt, "totallllllllllll")
                    dispatch(setTotalOperGenMaleEmp(mm))
                    dispatch(setTotalOperGenFemaleEmp(ff))
                    dispatch(setTotalOperGenTotalEmp(tt))
                }else{
                    setEmpOperSelectData({})
                    setEmpOperFlagEdit(false)
                    dispatch(resetDataFullOper())
                    dispatch(resetDataPartOper())
                }
            }

        }catch(err){
            Swal.fire(
                `${err.message}`,
                "",
                "warning"
              );
        }
        
    }

    const handleDeletePhyProgress = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/physical_financialinputs-delete?id=${idPhysicalFormData}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setPhyFlagEdit("");
              dispatch(resetData());
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            );
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
};

      const handleDeleteEMPConst = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/getEmpGenConsInpTwo-delete?id=${idEmpConcFullFormData}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setEmpConcFlagEdit("");
              dispatch(resetDataFullConc());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
                });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
      const handleDeleteEMPOper = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/getEmpGenOprInpTwo-delete?id=${idEmpOperFullFormData}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setEmpOperFlagEdit("");
              dispatch(resetDataFullOper());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
                });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteGESI = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/getEmpGsiFpoVcoInpTwo-delete?id=${idGESIFormData}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setGesiFlagEdit("");
              dispatch(resetDataGesiForm());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
                });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };

      const handleDeleteSafeGuard = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
          });
        
          if (result.isConfirmed) {
        try {
          const response = await axios.get(
            `${apiUrl}api/safeguard-inputdata-delete?id=${idSafeguardFormData}`,
            getConfigWithToken()
          );
    
          if (response.data.status === true) {
            Swal.fire(
              `${response.data.message}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setSafguardFlagEdit("");
              dispatch(resetDataSafeGuard());
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${response.data.message}`,
              "",
              "warning"
            ).then(() => {
                setLoading(false);
                });
          }
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      };
    };
      
    useEffect(()=>{ 
        const quarterData = async () => {
            try {
            const storedArrayAsString = localStorage.getItem('permission');
              setPermission(storedArrayAsString)
              
              const applicant = await axios.get(`${apiUrl}api/get-all-applicants?all=1`,getConfigWithToken());
              const component = await axios.get(`${apiUrl}api/get-all-components?all=1`,getConfigWithToken());
              const applicantData = applicant.data["data"].map((e)=>({"id":e["id"],"applicant_name":e["applicant_name"]}))
              const componentData = component.data["data"].map((e) =>({"id":e["id"],"component":e["component"]}));

              setQuarter(quarter);
            const selectedValues = quarter.filter(item => item.selected === "Selected").map(item => item.id);
            setSelectedQuarters(selectedValues[0])
              setApplicants(applicantData); // Pass applicantData to setApplicants
              setComponents(componentData)
              setPowerSource(powerSou)
              setFacilities(facili)
              setLoadingcir(false);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          
          quarterData();
          
    },[quarter])
  
    
    const EMPType = {
        'Full Time Employment':<FullTime empConcFlagEdit={empConcFlagEdit}/>,
        'Part Time Employment':<PartTime />
    }

    const EMPOperType = {
        'Full Time Employment':<FullTimeOper />,
        'Part Time Employment':<PartTimeOper />
    }

    const Facilities={
        'Cold Storage':<selectoptions/>,
        'Pack House (TPD)':<selectoptions/>,
        'Secondary Processing (TPD)':<selectoptions/>,
        'Tertiary Processing (TPD)':<selectoptions/>
    }
    if (Loadingcir) {
        return (<>
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box>
        </>);
      }

    


    return(<>
       
        <main id="main" class="main">
        <section class="section">
        <div class="row">
            <div id="exTab3" class="contain">	
            
            <div class="card">
            <Box sx={{ width: '100%', typography: 'body1', bgcolor: 'background.paper' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" value={value} onChange={handleChange} >
                        <Tab label="Project Progress" value="0" className={`${value === '0' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="Job Generated During Construction" value="1" className={`${value === '1' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="Job Generated During Operation" value="6" className={`${value === '6' ? 'activeClickCSS' : ''}`}/>
                        <Tab label="GESI" value="2" className={`${value === '2' ? 'activeClickCSS' : ''}`} />
                        <Tab label="Safeguard" value="3" className={`${value === '3' ? 'activeClickCSS' : ''}`}/>
                            
                    </Tabs>
                    </Box>
                    {/* Physical Finance */}
                    <TabPanel value="0">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handlePhySubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorFPOSelect !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={esiFpoApplicants}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(applicants).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["applicant_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorFPOSelect}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    {/* <SelectField selectData={farmerCompanies}/> */}
                                    <InputField label="Division" type="text" readOnly={true} value={esiDivision && esiDivision.divisions ? esiDivision.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={esiDistrict && esiDistrict.district ? esiDistrict.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={esiTaluka && esiTaluka.taluka ? esiTaluka.taluka.toString() : ''}/>
                                
                                <br/>
                                    <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                            <span>Sanctioned cost break-up {phySelectComponent && `for ${components.find(e=>e['id']===phySelectComponent).component}`}</span>
                                        <div>
                                            {/* <label>Select an Option:</label> */}
                                            <Box sx={{ minWidth: 390 }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth error={errorComponentSelect !== ""?true:false}>
                                                    <InputLabel id="demo-simple-select-label">Component</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={phySelectComponent} 
                                                    onChange={handleComponentChangePhy}
                                                    label="Comp"
                                                    >
                                                    {components.map((e) => (
                                                        <MenuItem key={e.id} value={e.id}>{e["component"]}</MenuItem>
                                                    ))}
                                                    </Select>
                                                    <FormHelperText>{errorComponentSelect}</FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                <br/>
                              
                                    <div>
                                    {phySelectComponent && 
                                        <div>
                                            <MasterSub errorSelectFile={errorPhySelectFile} compName={components.find(e=>e['id']===phySelectComponent).component}   
                                           amountDisbursement={amountDisbursement}  tranche={tranche_id} fileChange={handleFileChange} matchingGrant={isMatchingGrant} phySelectData={phySelectData} setPhySelectData={setPhySelectData}/>
                                        </div>}</div>
                                
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Project-Prog-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{phyFlagEdit === true ? "Update":"Submit"}</button>
                                        {phyFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeletePhyProgress}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Employment Generated During Construction Inputs */}
                    <TabPanel value="1">
                        <div className='tab-pane'>
                        <form class="row g-3" onSubmit={handleEmpSubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                        <FormControl fullWidth error={errorFPOSelect !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={esiFpoApplicants}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(applicants).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["applicant_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorFPOSelect}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={esiDivision && esiDivision.divisions ? esiDivision.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={esiDistrict && esiDistrict.district ? esiDistrict.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={esiTaluka && esiTaluka.taluka ? esiTaluka.taluka.toString() : ''}/>
                                    
                                    <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                        <span>Details of Current Quarter for - {empType} </span>
                                        <div>
                                            <Box sx={{ minWidth: 390 }} style={{backgroundColor:"#ededed"}}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Employment Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={empType} onChange={handleEmpChange}
                                                        label="Employm"
                                                    >
                                                    {Object.keys(EMPType).map((option) => (
                                                        <MenuItem value={option}>{option}</MenuItem>
                                                        
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                            <br/>
                             <div>
                                {empType && (
                                <div>
                                    {EMPType[empType]}
                                </div>
                                )}
                                <div className='word-with-line' style={{margin: "2rem 0rem"}}>
                                        <span>Total Employment Generated</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                <div className='row' style={{display:"flex"}}>
                                    <InputField  label="Male" type="number" readOnly={true} value={dd["payload"]["fullTimeEmp"].totalEmploymentGenerated.male}/>
                                    <InputField  label="Female" type="number" readOnly={true} value={dd["payload"]["fullTimeEmp"].totalEmploymentGenerated.female}/>
                                    <InputField  label="Total Full time & Part time" type="number" readOnly={true} value={dd["payload"]["fullTimeEmp"].totalEmploymentGenerated.totalTrainees}/>  
                                </div>
                            </div>
                            <div class="col-md-6" style={{position: "relative", right: "10px", bottom: "10px"}}>
                                <label for="text" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="file" id="formFile" multiple onChange={handleEmpFile}/>
                                    <span style={{color: 'red'}}>{errorEmpSelectFile}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                {empConcSelectData?
                                empConcSelectData["remarks"]?(
                                    (empConcSelectData["remarks"]).map((e)=>
                                    (
                                    <div className="icon-container">
                                        <a href={`${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                        </a>
                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleEmpFileDeleteFile(e.file_id)}></i>
                                    </div>
                                    ))):""
                                    :
                                    ""
                                }   
                            </div>
                                <div style={{textAlign:"center"}}>
                                {
                                    permission.includes("Job-Gen-Dur-Construction-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{empConcFlagEdit === true ? "Update":"Submit"}</button>
                                        {empConcFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading}  onClick={handleDeleteEMPConst}>
                                            Delete
                                            </button>
                                        )}
                                        </>
                                    ):(
                                        <></>
                                    )
                                }
                                
                                </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Employment Generated During Construction Inputs */}
                    <TabPanel value="6">
                        <div className='tab-pane'>
                        <form class="row g-3" onSubmit={handleEmpOperSubmit}>
                                <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                        <FormControl fullWidth error={errorFPOSelect !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={esiFpoApplicants}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(applicants).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["applicant_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorFPOSelect}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={esiDivision && esiDivision.divisions ? esiDivision.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={esiDistrict && esiDistrict.district ? esiDistrict.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={esiTaluka && esiTaluka.taluka ? esiTaluka.taluka.toString() : ''}/>
                                    
                                    <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                    <span>Details of Current Quarter for - {empOperType} </span>
                                    <div>
                                        <Box sx={{ minWidth: 390 }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Employment Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={empOperType} onChange={handleEmpOperChange}
                                                    label="Employm"
                                                >
                                                {Object.keys(EMPOperType).map((option) => (
                                                    <MenuItem value={option}>{option}</MenuItem>
                                                    
                                                ))}
                                                </Select>
                                            </FormControl>
                                            </Box>
                                  
                                    </div>
                                </div>
                            <br/>
                             <div>
                                {empOperType && (
                                <div>
                                    {EMPOperType[empOperType]}
                                </div>
                                )}
                                <div className='word-with-line' style={{margin: "2rem 0rem"}}>
                                        <span>Total Employment Generated</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                <div className='row' style={{display:"flex"}}>
                                    <InputField  label="Male" type="number" readOnly={true} value={dd["payload"]["fullTimeOperEmp"].totalEmploymentGenerated.male}/>
                                    <InputField  label="Female" type="number" readOnly={true} value={dd["payload"]["fullTimeOperEmp"].totalEmploymentGenerated.female}/>
                                    <InputField  label="Total Full time & Part time" type="number" readOnly={true} value={dd["payload"]["fullTimeOperEmp"].totalEmploymentGenerated.totalTrainees}/>  
                                </div>
                            </div>
                            <div class="col-md-6" style={{position: "relative", right: "10px", bottom: "10px"}}>
                                <label for="text" class="col-lg col-form-label">Upload supporting document. (e.g QPR, Photos etc.)</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="file" id="formFile" onChange={handleEmpOperFile} multiple/>
                                    <span style={{color: 'red'}}>{errorEmpOperSelectFile}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                {empOperSelectData?
                                empOperSelectData["remarks"]?(
                                    (empOperSelectData["remarks"]).map((e)=>
                                    (
                                    <div className="icon-container">
                                        <a href={`${e.file_name}`} rel="noreferrer" target="_blank" style={{marginRight:"10px"}}>
                                        <img src={(e.type==="pdf")?pdf:imgIcon} alt="" height= "30px" width= "26px" />
                                        </a>
                                        <i class="fa-regular fa-circle-xmark" style={{cursor:"pointer"}} onClick={()=>handleEmpFileDeleteFile(e.file_id)}></i>
                                    </div>
                                    ))):""
                                    :
                                    ""
                                }   
                            </div>
                                <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Job-Gen-Dur-Operation-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{empOperFlagEdit === true ? "Update":"Submit"}</button>
                                        {empOperFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading}  onClick={handleDeleteEMPOper}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* Output-2-ESI_FPO VCO/CMRC */}
                    <TabPanel value="2">
                        <div className='tab-pane'>
                            <form class="row g-3" onSubmit={handleEsiFPOSubmit}>
                                    <div className='quarterSelect'>
                                        <div className='support'>
                                        </div>
                                        <div className='col-md-4' style={{paddingRight:0}}>
                                            <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                            <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                        <FormControl fullWidth error={errorFPOSelect !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={esiFpoApplicants}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                        
                                                >
                                                {(applicants).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["applicant_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorFPOSelect}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={esiDivision && esiDivision.divisions ? esiDivision.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={esiDistrict && esiDistrict.district ? esiDistrict.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={esiTaluka && esiTaluka.taluka ? esiTaluka.taluka.toString() : ''}/>

                                    <InputField label="Total No.of Members" value={totalNoMember} readOnly={true} type="number"/>
                                    <InputField label="Total No.of women" value={totalNoWomen} readOnly={true} type="number"/>
                                    
                                <br/>
                                    <div className='word-with-line' style={{margin: "1.5rem 0"}}>
                                        <span>Details of GESI for Current Quarter</span>
                                    </div>
                                    <InputTwoTabThree/>
                                <br/>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Gesi-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{gesiFlagEdit === true ? "Update":"Submit"}</button>
                                        {gesiFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteGESI}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                    </div>
                            </form>
                        </div>
                    </TabPanel>

                    {/* SafeGuard */}
                    <TabPanel value='3'>
                        <div className='tab-pane'>
                        <form class="row g-3" onSubmit={handleSafeGuardSubmit}>
                                <div className='quarterSelect'>
                                    <div className='support'>
                                    </div>
                                    <div className='col-md-4' style={{paddingRight:0}}>
                                        <Box sx={{ minWidth: "100%" }} style={{backgroundColor:"#ededed"}}>
                                        <FormControl fullWidth>
                                                    <InputLabel htmlFor="demo-simple-select-label">Quarter</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectQuarter}
                                                        label="Quarter"
                                                        onChange={handleQuarterChange}
                                                    >
                                                    {(quarters).map((e,key)=>(
                                                        <MenuItem value={e.id} key={key}>{e.value}</MenuItem>
                                                    ))}
                                                    </Select>
                                                </FormControl>
                                        </Box>
                                    </div>
                                </div>
                                <div className='word-with-line'>
                                        <span>Beneficiary Information</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    <div className='col-md-4'>
                                        <Box sx={{ minWidth: "100%" }}>
                                            <FormControl fullWidth error={errorFPOSelect !== ""?true:false}>
                                                <InputLabel htmlFor="demo-simple-select-label">FPO/VCO/CMRC</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={esiFpoApplicants}
                                                label="FPO/VCO/CMRC"
                                                onChange={handleApplicantChangeESIFPO}
                                                >
                                                {(applicants).map((e)=>(
                                                    <MenuItem value={e.id} key={e.id}>{e["applicant_name"]}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>{errorFPOSelect}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <InputField label="Division" type="text" readOnly={true} value={esiDivision && esiDivision.divisions ? esiDivision.divisions.toString() : ''}/>
                                    <InputField label="District" type="text"  readOnly={true} value={esiDistrict && esiDistrict.district ? esiDistrict.district.toString() : ''}/>
                                    <InputField label="Taluka" type="text"  readOnly={true} value={esiTaluka && esiTaluka.taluka ? esiTaluka.taluka.toString() : ''}/>
                                    <div className='word-with-line'>
                                        <span>Details of information for Current Quarter of Safegaurd</span>
                                        <i class="fa-solid fa-angle-down" style={{color: "#4e2683"}}></i>
                                    </div>
                                    
                                    <InputTwoTabFour powerSource={powerSource}/>
                                    <div style={{textAlign:"center"}}>
                                    {
                                        permission.includes("Safeguard-Inp-Two-view") ? (
                                        <>
                                        <button type="submit" class="btn submitbtn" disabled={loading}>{safguardFlagEdit === true ? "Update":"Submit"}</button>
                                        {safguardFlagEdit === true && (
                                            <button type="button" className="btn submitbtn" disabled={loading} onClick={handleDeleteSafeGuard}>
                                            Delete
                                            </button>
                                        )}
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </TabPanel>
                  
                </TabContext>
            </Box>
            </div>
        </div>

        </div>
    </section>
    </main>
   
    </>)
}