import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import "../assets/css/components.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";

export const Header = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const userType = localStorage.getItem("userType");
  const name = localStorage.getItem("name");
  const [isLessThan, setIsLessThan] = useState(true);
  const toggleNavigation = () => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("toggle-sidebar");
      setIsLessThan((prevState) => !prevState); // Toggle the state
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/")
  };
  const handleProfileClick = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}api/isUserSubmitedSecurityQuestions`,
        getConfigWithToken()
      );

      // No need to parse the JSON, axios already does it
      const result = response.data;

      // Navigate to /myprofile regardless of the response
      navigate("/myprofile");
    } catch (error) {
      console.error("Error checking user security questions:", error);
      // Still navigate to /myprofile in case of error
      navigate("/myprofile");
    }
  };
  return (
    <>
      <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
          <Link to="/dashboard" class="logo d-flex align-items-center">
            <img style={{ maxHeight: "48px" }} src={logo} alt="" />
            <span class="d-none d-lg-block">MAGNET</span>
          </Link>
         
          <div>
            <div
              className={`icon-circle ${
                isLessThan ? "less-than" : "greater-than"
              }`}
              onClick={toggleNavigation}
            >
              {/* You can use your icons here */}
              <i
                className={`fas ${
                  isLessThan ? "fa-angle-double-left" : "fa-angle-double-right"
                }`}
              ></i>
            </div>
          </div>
        </div>


        <nav class="header-nav ms-auto">
          <ul class="d-flex align-items-center">
            <li class="nav-item d-block d-lg-none">
              <a class="nav-link nav-icon search-bar-toggle ">
                <i class="bi bi-search"></i>
              </a>
            </li>

            <li class="nav-item dropdown pe-3">
              <a
                class="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i
                  class="fa-solid fa-circle-user fa-lg"
                  style={{ fontSize: "25px", color: "#fff" }}
                ></i>
                <span class="d-none d-md-block dropdown-toggle ps-2">
                  {name}
                </span>
              </a>

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6>{name}</h6>
                  <span>{userType}</span>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    onClick={handleProfileClick}
                  >
                    <i class="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

              
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <div
                    class="dropdown-item d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      class="dropdown-item d-flex align-items-center"
                      href="/login"
                    >
                      <i
                        class="bi bi-box-arrow-right"
                        style={{ marginLeft: "-11px" }}
                      ></i>
                      <span onClick={handleLogout}>Sign Out</span>
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
