import { combineReducers } from "redux";
import masterSliceReducer from "./slice/InputTwo/MasterSubSlice";
import FullTimeEmpReducer from "./slice/InputTwo/EmpGenInputTwo/FullTimeSlice";
import PartTimeEmpReducer from "./slice/InputTwo/EmpGenInputTwo/PartTimeSlice";

import FullTimeOperEmpReducer from "./slice/InputTwo/OperGenInputTwo/FullTimeOperSlice";
import PartTimeOperEmpReducer from "./slice/InputTwo/OperGenInputTwo/PartTimeOperSlice";

import radioSliceReducer from "./slice/InputTwo/ESIInputTwo/radioSlice";
import safeGuardReducer from "./slice/InputTwo/SafeGuardInputTwo/safeGuardSlice";
import facilityReducer from "./slice/InputTwo/FacilityInputTwo/FacilitySlice";
import filSliceReducer from "./slice/InputTwo/FILInputTwo/FilSlice";

import workShopReduer from "./slice/InputOne/WorkShop/WorkShopSlice";
import postHarvestReducer from "./slice/InputOne/PostHarvestTrainingSlice/PostHarvestSlice";
import valueChainReducer from "./slice/InputOne/ValueChain/ValueChainSlice";
import TotTrainingReducer from "./slice/InputOne/TotTraining/TotTrainingSlice";
import highDensityReducer from "./slice/InputOne/HighDensity/HighDensitySlice";
import capacityBuildingReducer from "./slice/InputOne/CapacityBuilding/CapacityBuildingSlice";
import smeLinkageReducer from "./slice/InputOne/SMELinkage/smeLinkageSlice";
import MarketAssistenceReducer from "./slice/InputOne/MarketAssistence/MarketAssistenceSlice";
import OtherCertificationReducer from "./slice/InputOne/OtherCertification/OtherCertificationSlice";

import NurseryDevReducer from "./slice/InputOne/NurseryDevelopment/NurseryDevSlice";
import TissueCultureReducer from "./slice/InputOne/TissueCulture/TissueCultureSlice";
import researchDevReducer from "./slice/InputOne/ResearchDev/researchDevSlice";
import InnovationReducer from "./slice/InputOne/InnovationPack/InnovationSlice";
import IntroVarietyReducer from "./slice/InputOne/IntoToVariety/IntroVarietySlice";
import ProductionPlantReducer from "./slice/InputOne/ProductionPlant/ProductionPlantSlice";
import SupportTechReducer from "./slice/InputOne/SupportTechnology/SupportTechSlice";
import BioWasteReducer from "./slice/InputOne/BioWaste/BioWasteSlice";
import StudyTourExposureReducer from "./slice/InputOne/StudyTourExposure/StudyTourExposureSlice";
import StudyTourFarmerReducer from "./slice/InputOne/StudyTourFarmer/StudyTourFarmerSlice";
import PhysicalFinanceReducer from "./slice/InputThree/Input3PhysicalFinance/PhysicalFinanceSlice";
import FullPartTimeReducer from "./slice/InputThree/EmpGenInputThree/FullPartTimeSlice";
import ProgressFacilityReducer from "./slice/InputThree/FacilityInputThree/ProgressFacilitySlice";
import GenderEqualityReducer from "./slice/InputThree/GenderEqualityInputThree/GenderEqualitySlice";
import EnvSafeGuardReducer from "./slice/InputThree/SafeGuardInputThree/EnvSafeguardSlice";
import TradeFairReducer from "./slice/InputOne/TradeFairExhibition/TradeFairSlice";
import MarketPromotionReducer from "./slice/InputOne/MarketPromotion/MarketPromotionSlice";
import MSAMBMasterSlice from "./slice/MSAMBMaster/MSAMBMasterSlice";
import MGBeneficiaryMasterSlice from "./slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import FILMasterSlice from "./slice/FILMaster/FILMasterSlice";
import EntityMasterSlice from "./slice/EntityMaster/EntityMasterSlice";
import BioWasterMasterSlice from "./slice/EntityMaster/BioWasterMasterSlice";
import HDPMasterSlice from "./slice/EntityMaster/HDPMasterSlice";
import CertificationCostMasterSlice from "./slice/EntityMaster/CertificationCostMasterSlice";
import NurseryDevMasterSlice from "./slice/EntityMaster/NurseryDevMasterSlice";
import IntroductionNewVarietyMasterSlice from "./slice/EntityMaster/IntroductionNewVarietyMasterSlice";
import InnvoationMasterSlice from "./slice/EntityMaster/InnvoationMasterSlice";
import MRLTestingMasterSlice from "./slice/EntityMaster/MRLTestingMasterSlice";
import RandDDevMasterSlice from "./slice/EntityMaster/RandDDevMasterSlice";
import SupportFruitMasterSlice from "./slice/EntityMaster/SupportFruitMasterSlice";
import SupportCommMasterSlice from "./slice/EntityMaster/SupportCommMasterSlice";
import TissueCultureMasterSlice from "./slice/EntityMaster/TissueCultureMasterSlice";
import ProductionPlantMasterSlice from "./slice/EntityMaster/ProductionPlantMasterSlice";
import OtherCertificationCostMasterSlice from "./slice/EntityMaster/OtherCertificationCostMasterSlice";
import MarketDevAssisMasterSlice from "./slice/EntityMaster/MarketDevAssisMasterSlice";
import EntityMasterCapacityBuildingSlice from "./slice/CapacityBuildingMaster/EntityMasterCapacityBuildingSlice";
import FullPartTimeOperSlice from "./slice/InputThree/EmpGenOperInpuThree/FullPartTimeOperSlice";
import BeneficiaryProfitSlice from "./slice/InputTwoImpactForms/BeneficiaryProfit/BeneficiaryProfitSlice";
import BeneficiaryLossSlice from "./slice/InputTwoImpactForms/BeneficiaryLoss/BeneficiaryLossSlice";
import UitlizationTPDTwoSlice from "./slice/InputTwoImpactForms/UtilizationTPDTwo/UitlizationTPDTwoSlice";
import UitlizationColdStorageTwoSlice from "./slice/InputTwoImpactForms/UtilizationColdStorageTwo/UitlizationColdStorageTwoSlice";
import UtilizationThreeTPDSlice from "./slice/InputThreeImpactForms/UtilizationThreeTPD/UtilizationThreeTPDSlice";
import UtilizationThreeColdStorageSlice from "./slice/InputThreeImpactForms/UtilizationThreeColdStorage/UtilizationThreeColdStorageSlice";

const rootReducer = combineReducers({
  masterFormData: masterSliceReducer,
  // Add other reducers here if needed

  // Input Two Second Tab
  fullTimeEmp: FullTimeEmpReducer,
  partTimeEmp: PartTimeEmpReducer,
  // Input Two Third Tab
  fullTimeOperEmp: FullTimeOperEmpReducer,
  partTimeOperEmp: PartTimeOperEmpReducer,

  // Input Two Third Tab
  esiFpoRadio: radioSliceReducer,

  // Input Two Four Tab
  safeGuard: safeGuardReducer,

  // Input Two Five Tab
  facility: facilityReducer,

  // Input Two Six Tab
  filSlice: filSliceReducer,

  /***********************  Input One Reducers  ******************************/
  workShop: workShopReduer,

  postHarvest: postHarvestReducer,

  valueChain: valueChainReducer,

  totTraining: TotTrainingReducer,

  highDensity: highDensityReducer,

  capacityBuilding: capacityBuildingReducer,

  smeLinkage: smeLinkageReducer,

  marketAssistence: MarketAssistenceReducer,

  otherCertification: OtherCertificationReducer,

  nurseryDev: NurseryDevReducer,

  tissueCulture: TissueCultureReducer,

  researchDev: researchDevReducer,

  innovation: InnovationReducer,

  introVariety: IntroVarietyReducer,

  productionPlant: ProductionPlantReducer,

  supportTech: SupportTechReducer,

  bioWaste: BioWasteReducer,

  studyTourExpo: StudyTourExposureReducer,

  studyTourFarmer: StudyTourFarmerReducer,

  tradeFair: TradeFairReducer,

  marketPromotion: MarketPromotionReducer,

  /***********************  Input Three Reducers  ******************************/

  physicalFinance: PhysicalFinanceReducer,

  fullPartTime: FullPartTimeReducer,

  FullPartTimeOper: FullPartTimeOperSlice,

  progressFacility: ProgressFacilityReducer,

  genderEquality: GenderEqualityReducer,

  envSafeGuard: EnvSafeGuardReducer,

  /*********************** MSAMB Master  ************************************/

  msambMaster: MSAMBMasterSlice,

  mgBeneficiaryMaster: MGBeneficiaryMasterSlice,

  filMaster: FILMasterSlice,

  entityMaster: EntityMasterSlice,

  /*********************** Entity Master  ************ */

  bioWasteMaster: BioWasterMasterSlice,
  hdpMaster: HDPMasterSlice,
  certificationCostMaster: CertificationCostMasterSlice,
  nurseryDevMaster: NurseryDevMasterSlice,

  prodPlantMaster: ProductionPlantMasterSlice,
  otherCertificationMaster: OtherCertificationCostMasterSlice,
  marketAssistenceMaster: MarketDevAssisMasterSlice,

  introVarietyMaster: IntroductionNewVarietyMasterSlice,
  innovationMaster: InnvoationMasterSlice,
  mrlTestingMaster: MRLTestingMasterSlice,
  researchDevMaster: RandDDevMasterSlice,
  supportFruitMaster: SupportFruitMasterSlice,
  supportCommMaster: SupportCommMasterSlice,
  tissueCultureMaster: TissueCultureMasterSlice,

  entityMasterCapacityBuildingMaster: EntityMasterCapacityBuildingSlice,

  /*********************** Input Two Impact forms   ************ */
  beneficiaryProfitMaster: BeneficiaryProfitSlice,
  beneficiaryLossMaster: BeneficiaryLossSlice,
  utilizationTPDTwo: UitlizationTPDTwoSlice,
  utilizationColdStorageTwo: UitlizationColdStorageTwoSlice,

  /*********************** Input Three Impact forms   ************ */
  utilizationTPDThree: UtilizationThreeTPDSlice,
  utilizationColdStorageThree: UtilizationThreeColdStorageSlice,
});

export default rootReducer;
