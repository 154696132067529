import { InputField } from "../../InputField";
import {
  setInputFieldData,
  resetValidation,
  setTotalSC,
  setTotalST,
  setTotalPWD,
  setTotalBPL,
  setTotalOBC,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/HighDensity/HighDensitySlice";
import { useDispatch, useSelector } from "react-redux";
export const HighDensity = () => {
  const dispatch = useDispatch();
  const highDensity = useSelector((state) => state.highDensity);

  // Total of SC
  const totalSC =
    parseInt(highDensity.sc_male === "" ? 0 : highDensity.sc_male) +
    parseInt(highDensity.sc_female === "" ? 0 : highDensity.sc_female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(highDensity.st_male === "" ? 0 : highDensity.st_male) +
    parseInt(highDensity.st_female === "" ? 0 : highDensity.st_female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(highDensity.pwd_male === "" ? 0 : highDensity.pwd_male) +
    parseInt(highDensity.pwd_female === "" ? 0 : highDensity.pwd_female);
  dispatch(setTotalPWD(totalPWD));
  
  const totalOBCMinorityOpen =
    parseInt(
      highDensity.obc_minority_open_male === ""
        ? 0
        : highDensity.obc_minority_open_male
    ) +
    parseInt(
      highDensity.obc_minority_open_female === ""
        ? 0
        : highDensity.obc_minority_open_female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));

  // Total of OBC/Minority/Open
  const totalParticipants =
    parseInt(
      highDensity.beneficiaries_male === "" ? 0 : highDensity.beneficiaries_male
    ) +
    parseInt(
      highDensity.beneficiaries_female === ""
        ? 0
        : highDensity.beneficiaries_female
    );
  dispatch(setTotalParticipants(totalParticipants));

  


  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  
const handleInputChange = (fieldName, value) => {
  if (value < 0) {
    value = 0;
  }
  dispatch(setInputFieldData({ fieldName, value }));
};

  return (
    <>
      <div className="col-md-3 input1Heading">
        <span>SC</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={highDensity.sc_male}
        onChange={(value)=>handleInputChange("sc_male",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.sc_male}
        helperText={highDensity.validationErrors.sc_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={highDensity.sc_female}
        onChange={(value)=>handleInputChange("sc_female",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.sc_female}
        helperText={highDensity.validationErrors.sc_female}
      />
      <InputField
        col="3"
        label="Total SC"
        type="number"
        value={highDensity.sc_total}
        readOnly={true}
      />

      <div className="col-md-3 input1Heading">
        <span>ST</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={highDensity.st_male}
        onChange={(value)=>handleInputChange("st_male",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.st_male}
        helperText={highDensity.validationErrors.st_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={highDensity.st_female}
        onChange={(value)=>handleInputChange("st_female",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.st_female}
        helperText={highDensity.validationErrors.st_female}
      />
      <InputField
        col="3"
        label="Total ST"
        type="number"
        value={highDensity.st_total}
        readOnly={true}
      />
     
      <div className="col-md-3 input1Heading">
        <span>General (Open, OBC and others)</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={highDensity.obc_minority_open_male}
        onChange={(value)=>handleInputChange("obc_minority_open_male",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.obc_minority_open_male}
        helperText={highDensity.validationErrors.obc_minority_open_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={highDensity.obc_minority_open_female}
        onChange={(value)=>handleInputChange("obc_minority_open_female",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.obc_minority_open_female}
        helperText={highDensity.validationErrors.obc_minority_open_female}
      />
      <InputField
        col="3"
        label="Total OBC/ Minority/ Open"
        type="number"
        value={highDensity.obc_minority_open_total}
        readOnly={true}
      />
      <div className="col-md-3 input1Heading">
        <span>Beneficiaries</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={highDensity.beneficiaries_male}
        onChange={(value)=>handleInputChange("beneficiaries_male",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.beneficiaries_male}
        helperText={highDensity.validationErrors.beneficiaries_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={highDensity.beneficiaries_female}
        onChange={(value)=>handleInputChange("beneficiaries_female",value)}
        onFocus={handleResetFocus}
        error={!!highDensity.validationErrors.beneficiaries_female}
        helperText={highDensity.validationErrors.beneficiaries_female}
      />
      <InputField
        col="3"
        label="Total Trainers"
        type="number"
        value={highDensity.beneficiaries_total}
        readOnly={true}
      />

      <div style={{ marginTop: "3rem" }} class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>PWD</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={highDensity.pwd_male}
          onChange={(value)=>handleInputChange("pwd_male",value)}
          onFocus={handleResetFocus}
          error={!!highDensity.validationErrors.pwd_male}
          helperText={highDensity.validationErrors.pwd_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={highDensity.pwd_female}
          onChange={(value)=>handleInputChange("pwd_female",value)}
          onFocus={handleResetFocus}
          error={!!highDensity.validationErrors.pwd_female}
          helperText={highDensity.validationErrors.pwd_female}
        />
        <InputField
          col="3"
          label="Total PWD"
          type="number"
          value={highDensity.pwd_total}
          readOnly={true}
        />
      </div>
    </>
  );
};
