import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/FILMaster/FILMasterSlice";
import { useDispatch, useSelector } from "react-redux";

export const FILLoanDetails = (props) =>{ 

    const dispatch = useDispatch();
    const filMaster = useSelector(state => state.filMaster);

    const handleFieldLoanAmount = (event) => {
        dispatch(setInputFieldData({ fieldName: 'loan_amount', value:event.target.value }));
   }
   const handleFieldInterestRatePct = (event) => {
    dispatch(setInputFieldData({ fieldName: 'interest_rate_pct', value:event.target.value }));
    }
    const handleFieldProcessingFee = (event) => {
        dispatch(setInputFieldData({ fieldName: 'processing_fee_pct', value:event.target.value }));
    }
    const handleFieldSanctionDate = (event) => {
        dispatch(setInputFieldData({ fieldName: 'sanction_date', value:event.target.value }));
    }
    const handleFieldDisbursementAmount = (event) => {
        dispatch(setInputFieldData({ fieldName: 'disbursed_amount', value:event.target.value }));
    }
    const handleFieldOutstandingAmount = (event) => {
        dispatch(setInputFieldData({ fieldName: 'outstanding_amount', value:event.target.value }));
    }
    const handleFieldDisbursementDate = (event) => {
        dispatch(setInputFieldData({ fieldName: 'disbursement_date', value:event.target.value }));
    }
    const handleFieldRevisedInterestRate = (event) => {
        dispatch(setInputFieldData({ fieldName: 'revised_interest_rate', value:event.target.value }));
    } 
    const handleFieldAddendumDate = (event) => {
        dispatch(setInputFieldData({ fieldName: 'addendum_date', value:event.target.value }));
    }  
    const handleFieldLoanPurpose = (event) => {
        dispatch(setInputFieldData({ fieldName: '', value:event.target.value }));
    }
    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
    


 return(<>   
    {/* <Online> */}
        <div className='tab-pane'>
            <form class="row g-3">
                
                {/* Loan Amount (INR Lakh) */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="LoanAmt">Loan Amount (INR Lakh)</label>
                    <input type="number" name="LoanAmt" 
                        value={filMaster.loan_amount}
                        onChange={handleFieldLoanAmount} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"50%"}}
                    />
                </div>

                {/* Interest Rate %  */}
                <div className='primaryTableLabel'>
                    <label htmlFor="InterestRate">Interest Rate % </label>
                    <input type="number" name="InterestRate" 
                        value={filMaster.interest_rate_pct}
                        onChange={handleFieldInterestRatePct} 
                        onFocus={handleResetFocus}
                        className="custom" 
                        style={{width:"21%"}}   
                    />
                </div>

                  {/* Processing Fee %  */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="Fee">Processing Fee % </label>
                    <input type="number" name="Fee" 
                        value={filMaster.processing_fee_pct}
                        onChange={handleFieldProcessingFee} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                
                  {/* Sanction Date */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="Date">Sanction Date</label>
                    <input type="date" name="Date" 
                        value={filMaster.sanction_date}
                        onChange={handleFieldSanctionDate} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                                
                  {/* Disbursed amount */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="DisbursedAmt">Disbursed amount</label>
                    <input type="number" name="DisbursedAmt" 
                        value={filMaster.disbursed_amount}
                        onChange={handleFieldDisbursementAmount} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                                                
                  {/* Outstanding Amount */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="OutstandingAmt">Outstanding Amount</label>
                    <input type="number" name="OutstandingAmt" 
                        value={filMaster.outstanding_amount}
                        onChange={handleFieldOutstandingAmount} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                                                                
                  {/* Disbursement Date */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="DisbursmentDate">Disbursement Date</label>
                    <input type="date" name="DisbursmentDate" 
                        value={filMaster.disbursement_date}
                        onChange={handleFieldDisbursementDate} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                                                                                
                  {/* Revised Interest Rate */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="RevisedRate">Revised Interest Rate</label>
                    <input type="number" name="RevisedRate" 
                        value={filMaster.revised_interest_rate}
                        onChange={handleFieldRevisedInterestRate} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
                                                                                
                  {/* Addendum Date */}
                  <div className='primaryTableLabel'>
                    <label htmlFor="AddendumDate">Addendum Date</label>
                    <input type="date" name="AddendumDate" 
                        value={filMaster.addendum_date}
                        onChange={handleFieldAddendumDate} 
                        onFocus={handleResetFocus}
                        className="customInput" 
                        style={{width:"21%"}}
                    />
                </div>
           </form>
          
        </div>
           
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

