import { DatePick } from "../../DatePick";
import { InputField } from "../../InputField";
import {
  resetValidation,
  setInputFieldData,
} from "../../../redux/slice/InputOne/IntoToVariety/IntroVarietySlice";

import { useDispatch, useSelector } from "react-redux";

export const IntroVariety = () => {
  const dispatch = useDispatch();
  const introVariety = useSelector((state) => state.introVariety);

  const handleFieldProjectCost = (value) => {
    dispatch(setInputFieldData({ fieldName: "Total_Project_Cost", value }));
  };

  const handleFieldBeneContribution = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "Beneficiary_Contribution", value })
    );
  };

  const handleFieldSanctionGrant = (value) => {
    dispatch(setInputFieldData({ fieldName: "Total_SanctionGrant", value }));
  };

  const handleFieldPayment = (value) => {
    dispatch(setInputFieldData({ fieldName: "Payment_Tranche", value }));
  };

  const handleFieldAmtDisburesement = (value) => {
    dispatch(setInputFieldData({ fieldName: "Amt_Disbursement", value }));
  };

  const handleFieldDisbursementDate = (value) => {
    dispatch(setInputFieldData({ fieldName: "Disbursement_Date", value }));
  };

  const handleFieldTotalExpediture = (value) => {
    dispatch(setInputFieldData({ fieldName: "Total_Expediture", value }));
  };

  const handleFieldFinancialProgress = (value) => {
    dispatch(setInputFieldData({ fieldName: "Financial_Progress", value }));
  };

  const handleFieldBeneficiaryExpenditure = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "Beneficiary_Expenditure", value })
    );
  };

  const handleFieldTotalGrantExpenditure = (value) => {
    dispatch(setInputFieldData({ fieldName: "Total_GrantExpenditure", value }));
  };


  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      <div className="word-with-line">
        <span>Sanctioned Break-up Cost</span>
        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
      </div>
      <div class="row g-3">
        <InputField
          label="Total project Cost"
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.total_project_cost}
          readOnly={true}
          onChange={handleFieldProjectCost}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Total_Project_Cost}
          helperText={introVariety?.validationErrors.Total_Project_Cost}
        />
        <InputField
          label="Beneficiary Contribution"
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.beneficiary_contribution}
          readOnly={true}
          onChange={handleFieldBeneContribution}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Beneficiary_Contribution}
          helperText={introVariety?.validationErrors.Beneficiary_Contribution}
        />
        <InputField
          label="Total Sanction Grant from MAGNET"
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.total_saction_grant}
          readOnly={true}
          onChange={handleFieldSanctionGrant}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Total_SanctionGrant}
          helperText={introVariety?.validationErrors.Total_SanctionGrant}
        />

        <div className="word-with-line">
          <span>Project Wise Information</span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <InputField
          label="Payment/Tranche"
          type="text"
          value={introVariety?.payment_tranche}
          readOnly={true}
          onChange={handleFieldPayment}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Payment_Tranche}
          helperText={introVariety?.validationErrors.Payment_Tranche}
        />
        <InputField
          label="Amount Disbursement"
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.amount_disbursement}
          readOnly={true}
          onChange={handleFieldAmtDisburesement}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Amt_Disbursement}
          helperText={introVariety?.validationErrors.Amt_Disbursement}
        />
        <DatePick
          label="Disbursement Date"
          className="custom-date-picker"
          disbrushDate={introVariety?.disbursement_date}
          readOnly={true}
          onChange={handleFieldDisbursementDate}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Disbursement_Date}
          helperText={introVariety?.validationErrors.Disbursement_Date}
        />
        <InputField
          label="Total expenditure till date "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.total_exp_till_date}
          readOnly={true}
          onChange={handleFieldTotalExpediture}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Total_Expediture}
          helperText={introVariety?.validationErrors.Total_Expediture}
        />
        <InputField
          label="Financial Progress till date "
          type="number"
          endAr="%"
          value={introVariety?.financial_progress_till_date_pct}
          readOnly={true}
          onChange={handleFieldFinancialProgress}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Financial_Progress}
          helperText={introVariety?.validationErrors.Financial_Progress}
        />
        <InputField
          label="Total Beneficiary expenditure till date "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.total_beneficiary_exp_till_date}
          readOnly={true}
          onChange={handleFieldBeneficiaryExpenditure}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Beneficiary_Expenditure}
          helperText={introVariety?.validationErrors.Beneficiary_Expenditure}
        />
        <InputField
          label="Total Grant expenditure till date "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.total_grant_exp_till_date}
          readOnly={true}
          onChange={handleFieldTotalGrantExpenditure}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.Total_GrantExpenditure}
          helperText={introVariety?.validationErrors.Total_GrantExpenditure}
        />

        <div className="word-with-line">
          <span>Details of expenditure for Current Quarter</span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <InputField
          label="Beneficiary expenditure incurred "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.beneficiary_exp_incurred}
          onChange={(value)=>handleInputChange("beneficiary_exp_incurred",value)}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.beneficiary_exp_incurred}
          helperText={introVariety?.validationErrors.beneficiary_exp_incurred}
        />
        <InputField
          label="Grant expenditure incurred "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.grant_exp_incurred}
        onChange={(value)=>handleInputChange("grant_exp_incurred",value)}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.grant_exp_incurred}
          helperText={introVariety?.validationErrors.grant_exp_incurred}
        />
        <InputField
          label="Expenditure incurred "
          type="number"
          endAr="(in lakhs)"
          value={introVariety?.exp_incurred}
        onChange={(value)=>handleInputChange("exp_incurred",value)}
          onFocus={handleResetFocus}
          error={!!introVariety?.validationErrors.exp_incurred}
          helperText={introVariety?.validationErrors.exp_incurred}
        />
      </div>
    </>
  );
};
