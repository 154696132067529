import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../utils/Config/Config";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";

export const OutputTableBeneficiaryLoss = ({
  setDivision,
  financialYear,
  currentFinancialYear,
  currentQuarter,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([])
  const [selectDivision, setSelectDivision] = useState([])
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024")
  const [selectSubFilter, setSelectSubFilter] = useState("Q3")
  const [tableData, setTableData] = useState([])

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }else{
      const divisionData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }
  }

  const handleSelectFinancialYear = async (e) =>{
    setSelectFinancialYear(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }else{
      const divisionData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(divisionData.data.data[0])
    }
  }

  const handleSubFilter = async (e) =>{
    if(selectFinnacialYear.length === 0){
      Swal.fire(
        `Please Select Financial Year`,
        '',
        'warning'
      )
      return
    }
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(e.target.value)){
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterData.data.data[0])
      
    }else{
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/if-beneficiary-loss-output?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterData.data.data[0])
    }
    
  }

  useEffect(()=>{
     try{
      async function getData(){
        const empFPOComponentResponse = await axiosInstance.get(`${apiUrl}api/if-beneficiary-loss-output?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`, getConfigWithToken())
        setTableData(empFPOComponentResponse.data.data[0])
        setDivisions(setDivision);
        setSelectFinancialYear(currentFinancialYear)
        setSelectSubFilter(currentQuarter)
        setLoading(false);
      }
      
      setTimeout(() => {
        getData()
      }, 2000);
      
     }catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire(
          'An refresh the Page',
          '',
          'error'
      );
      } else {
        throw new Error('Data Not Found');
      }
    }
     
  },[setDivision, financialYear, currentFinancialYear, currentQuarter])
  const columns = useMemo(
    () => [
      {
        Header: "FPO/VCO Name",
        accessor: "applicant_name",
      },
      {
        Header: "Division",
        accessor: "divisions",
      },
      {
        Header: "Total utilized capacity of Pack House",
        accessor: "tuc_pack_house",
      },
      {
        Header: "Total utilized capacity of cold storage",
        accessor: "tuc_cold_storage",
      },
      {
        Header: "Total utilized capacity of reefer vans",
        accessor: "tuc_reefer_vans",
      },
      {
        Header: "Total utilized capacity of frozen storage",
        accessor: "tuc_frozen_storage",
      },

      {
        Header: "Estimated Losses of Pack house",
        accessor: "estimated_losses_pack_house",
      },
      {
        Header: "Estimated Losses of Cold Storage",
        accessor: "estimated_losses_cold_storage",
      },
      {
        Header: "Estimated Losses of reefer vans",
        accessor: "estimated_losses_reefer_vans",
      },
      {
        Header: "Estimated Losses of  frozen storage",
        accessor: "estimated_losses_frozen_storage",
      },
      {
        Header: "Total estimated losses",
        accessor: "total_estimated_losses",
      },
      {
        Header: "Losses(%)",
        accessor: "losses_percentage",
      },
    
    ],
    []
  );
console.log(columns,'columns')
  const totalWidthForEqualColumns = 800;
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });
console.log(tableData,'tdata')
  const cellStyle = {
    padding: "8px",
    border: "1px solid #ccc",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const exportToExcel = () => {
    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
    let formattedData;
  
    if (tableData.length === 0) {
      // Create a single row with only headers if there's no data
      formattedData = [{
        "Financial Year": selectFinnacialYear,
        "Period": selectSubFilter,
        ...(divisionName !== 'All' && { "Division": divisionName })
      }];
  
      columns.forEach(column => {
        formattedData[0][column.Header] = ""; // Empty value for the headers
      });
    } else {
      // Map table data and conditionally add the "Division" column
      formattedData = tableData.map(row => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          "Period": selectSubFilter,
        };
  
        if (divisionName !== 'All') {
          formattedRow["Division"] = divisionName;
        }
  
        columns.forEach(column => {
          formattedRow[column.Header] = row[column.accessor];
        });
  
        return formattedRow;
      });
    }
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BeneficiaryLoss");
  
    const fileName = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_Impact2_BeneficiaryLoss.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };


  if (loading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }
  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-5"></div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e, idx) => (
                      <MenuItem key={idx} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "155px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto", maxHeight: '445px'  }}>
       
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}  style={stickyy}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2",
                      fontWeight: "bold",
                      border: "2px solid #ccc",
                      ...cellStyle,
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.column.id}
                      style={{
                        padding: "8px",
                        border: "1px solid #ccc",
                        ...cellStyle,
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
