
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "21": {
    magnet_crop_id:[],
    variety_name: '',
    sub_project_title:'',
    signing_date:'',
    total_project_cost:'',
    beneficiary_contribution:'',
    matching_grant:'',

    mg_1st_tranche:'',
    mg_2nd_tranche:'',
    mg_3rd_tranche:'',
    mg_4th_tranche:'',
    actual_date_1:'',
    actual_date_2:'',
    actual_date_3:'',
    actual_date_4:'',
  },

    // Validation
  validationErrors:{  
    magnet_crop_id:[],
    location_taluka:'',
    no_of_farmers:'',
    name_of_certification_exhibition:'',
    area:'',
    total_project_cost: '',
    matching_grant: '',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',
        }
    };

const IntroductionVarietyMasterSlice = createSlice({
  name: 'IntroductionVarietyMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["21"][action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataIntroductionVarietyMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["21"]) {
        if (payload[key] !== undefined) {
          // state["21"][key] = payload[key];
          if(payload[key] === null){
            state["21"][key] = '';
          }else{
            state["21"][key] = payload[key];
          }
        }
      }
    },
    resetDataIntroductionVarietyMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsIntroductionVarietyMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldData, updateApiDataIntroductionVarietyMaster, resetDataIntroductionVarietyMaster } = IntroductionVarietyMasterSlice.actions;

export default IntroductionVarietyMasterSlice.reducer;
