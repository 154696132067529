import { Offline, Online } from "react-detect-offline";
import { Box } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../../../../utils/Config/Config";
import { useEffect } from "react";
import "../../../../../assets/css/masterdata.css";
import { useSelector } from "react-redux";
import { setInputActivity } from "../../../../../redux/slice/EntityMaster/SupportCommMasterSlice";
import { useDispatch } from 'react-redux';
import { useState } from "react";

export const SupportCommNewTechTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const [components, setComponents] = useState([])
    const { typedata } = useSelector((state) => state.supportCommMaster["26"]);

    const handleActivityValue = (id, technicalComponent, index, fieldName, value) => {
        console.log(id, technicalComponent, index, fieldName, value);
    
        const updatedInputActivity = JSON.parse(JSON.stringify(typedata)); // Deep copy
        const existingComponentIndex = updatedInputActivity.findIndex((item) => item.id === id);
    
        if (existingComponentIndex !== -1) {
          const existingComponent = updatedInputActivity[existingComponentIndex];
    
          existingComponent.certificate = {
            ...existingComponent.certificate,
            [fieldName]: value,
          };
        } else {
          updatedInputActivity.push({
            id,
            technical_component: technicalComponent,
            certificate: {
              [fieldName]: value,
            },
          });
        }
    
        dispatch(setInputActivity(updatedInputActivity));
      };

    useEffect(() => {
        async function getData() {
            try {
                const response = await axios.get(`${apiUrl}api/getEoiBasedTrainingTypeDataByForm?form_id=26`, getConfigWithToken());
                // console.log(response.data.data, "responseeeee")
                setComponents(response.data.data)
                if(typedata.length === 0){
                dispatch(setInputActivity(response.data.data));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Network Issue. Please try again.",
                });
            }
        }
        getData();
    }, [dispatch]);

    const submitAssessment = (e) => {
        e.preventDefault();
        console.log(typedata, "inputActivity");
    };

    const renderInputValue = (item, fieldName) => {
        const existingType = typedata.find((t) => t.id === item.id);
      
        if (existingType && existingType.certificate && existingType.certificate[fieldName] !== undefined) {
          return existingType.certificate[fieldName];
        }
      
        return '';
      };

      const calculateSummary = () => {
        let technologyCost = 0;
        let promoterEquity = 0;
        let matchingGrant = 0;
        let bankTermLoan = 0;
        let mg1stTranche = 0;
        let mg2ndTranche = 0;
        let mg3rdTranche = 0;
      
        typedata.forEach(item => {
          if (item.certificate) {
            technologyCost += parseFloat(item.certificate.total_project_cost || 0);
            promoterEquity += parseFloat(item.certificate.promoter_equity || 0);
            matchingGrant += parseFloat(item.certificate.matching_grant || 0);
            bankTermLoan += parseFloat(item.certificate.bank_term_loan || 0);
            mg1stTranche += parseFloat(item.certificate.mg_1st_tranche || 0);
            mg2ndTranche += parseFloat(item.certificate.mg_2nd_tranche || 0);
            mg3rdTranche += parseFloat(item.certificate.mg_3rd_tranche || 0);
          }
        });
      
        return {
          technologyCost:technologyCost.toFixed(2),
          promoterEquity:promoterEquity.toFixed(2),
          matchingGrant:matchingGrant.toFixed(2),
          bankTermLoan:bankTermLoan.toFixed(2),
          mg1stTranche:mg1stTranche.toFixed(2),
          mg2ndTranche:mg2ndTranche.toFixed(2),
          mg3rdTranche:mg3rdTranche.toFixed(2),
        };
      };
      


    return (
        <>
            {/* <Online> */}
                <Box
                    sx={{
                        width: "100%",
                        height: "maxContent",
                        typography: "body1",
                        border: "3px solid #E8E8E8",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                       <div style={{marginBottom:'-20px',marginLeft:'3px',fontSize:'13px'}}>
               <p>All Values in Lakhs</p>
            </div>
            
                    {/* <button onClick={submitAssessment}>Submit</button> */}
                    <table style={{ border: "1px solid white", backgroundColor: "white", width: "100%" }}>
                        <thead>
                            <tr style={{ textAlign: "left", color: "white", backgroundColor: "#4e2683" }}>
                            <th style={{ textAlign: "center", width: "25%" }}>Component</th>
                                <th style={{ textAlign: "center" }}>Cost of Technology</th>
                                <th style={{ textAlign: "center" }}>Promoters Contirbution</th>
                                <th style={{ textAlign: "center" }}>Term Loans</th>
                                <th style={{ textAlign: "center" }}>Term Grant</th>
                                <th style={{ textAlign: "center" }}>Matching Grant Amount Tranche 1</th>
                                {/* <th style={{ textAlign: "center" }}>Actual Disbursement Date for T1</th> */}
                                <th style={{ textAlign: "center" }}>Matching Grant Amount Tranche 2</th>
                                {/* <th style={{ textAlign: "center" }}>Actual Disbursement Date for T2</th> */}
                                <th style={{ textAlign: "center" }}>Matching Grant Amount Tranche 3</th>
                                {/* <th style={{ textAlign: "center" }}>Actual Disbursement Date for T3</th> */}
                                {/* <th style={{ textAlign: "center" }}>Matching Grant Amount Tranche 4</th> */}
                                {/* <th style={{ textAlign: "center" }}>Actual Disbursement Date for T4</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {components.map((item) => (
                                <tr key={item.id}>
                                    <td style={{ border: "1px solid black" }}>{item.technical_component}</td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"total_project_cost")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 1, "total_project_cost", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"promoter_equity")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 2, "promoter_equity", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"bank_term_loan")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 3, "bank_term_loan", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"matching_grant")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 4, "matching_grant", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"mg_1st_tranche")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 5, "mg_1st_tranche", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"mg_2nd_tranche")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 6, "mg_2nd_tranche", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"mg_3rd_tranche")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 7, "mg_3rd_tranche", event.target.value)
                                            }
                                        />
                                    </td>
                                    {/* <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"mg_4th_tranche")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 7, "mg_4th_tranche", event.target.value)
                                            }
                                        />
                                    </td> */}
                                    {/* <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item,"actual_date_4")}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.technical_component, 8, "actual_date_4", event.target.value)
                                            }
                                        />
                                    </td> */}
                                </tr>
                            ))}
                            {/* {components.map((item) => {
                                const correspondingTypeData = typeData.find((dataItem) => dataItem.id === item.id);

                                return (
                                    <tr key={item.id}>
                                        <td style={{ border: "1px solid black" }}>{item.technical_component}</td>
                                        <td>
                                            <input
                                                style={{
                                                    height: "25px",
                                                    width: "100%",
                                                    borderRadius: "7px",
                                                    border: "1px solid #E2E2E2",
                                                    backgroundColor: "#FFFFFF",
                                                }}
                                                type="number"
                                                value={correspondingTypeData ? correspondingTypeData.certificate.mg_1st_tranche : ""}
                                                onChange={(event) =>
                                                    handleActivityValue(item.id, item.technical_component, 1, "mg_1st_tranche", event.target.value)
                                                }
                                            />
                                        </td>
                                        
                                    </tr>
                                );
                            })} */}

                            <tr style={{   textAlign: "center", border:'1px solid black' }}>
                                <td style={{   fontWeight: "bold", border:'1px solid black'  }}>Component Summary</td>
                                <td style={{   textAlign: "left", border:'1px solid black'  }}>{calculateSummary().technologyCost}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().promoterEquity}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().bankTermLoan}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().matchingGrant}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().mg1stTranche}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().mg2ndTranche}</td>
                                <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().mg3rdTranche}</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </Box>
            {/* </Online>
            <Offline>You're Offline, Please Check your Connection</Offline> */}
        </>
    );
};
