import { InputField } from "../../InputField";
import React, { useEffect, useState } from "react";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormHelperText } from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/TradeFairExhibition/TradeFairSlice"; // Import your action creator

export function TradeFareExhibition(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const tradeFair = useSelector((state) => state.tradeFair);
  const [states, setState] = useState([]);
  const [country_temp, setCountry] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {
        const state = await axios.get(
          `${apiUrl}api/get-all-states?all=1`,
          getConfigWithToken()
        );
        const country = await axios.get(
          `${apiUrl}api/country-listing?all=1`,
          getConfigWithToken()
        );
        setState(state.data["data"]);
        setCountry(country.data["data"]);
      } catch (error) {}
    };

    valueChain();
  }, []);

  const totalOBCMinorityOpen =
    parseInt(
      tradeFair.participants_male === "" ? 0 : tradeFair.participants_male
    ) +
    parseInt(
      tradeFair.participants_female === "" ? 0 : tradeFair.participants_female
    );
  dispatch(setTotalParticipants(totalOBCMinorityOpen));

  const handleCountryIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "country_id", value: event.target.value })
    );
  };

  const handleStateIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "state_id", value: event.target.value })
    );
  };

  const handleFromDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleLocationAddressChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handlePurposeChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "name_of_trade_fair_exhibition", value })
    );
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}

      <div class="row g-3">
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            {props.compValue === 21 ? (
              <>
                <FormControl
                  fullWidth
                  error={
                    tradeFair.validationErrors.country_id !== "" ? true : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tradeFair.country_id}
                    onChange={handleCountryIdChange}
                    label="location(taluka)"
                  >
                    {country_temp.map((e) => (
                      <MenuItem value={e.id}>{e.country}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {tradeFair.validationErrors.country_id}
                </FormHelperText>
              </>
            ) : (
              <>
                <FormControl
                  fullWidth
                  error={
                    tradeFair.validationErrors.state_id !== "" ? true : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">States</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tradeFair.state_id}
                    onChange={handleStateIdChange}
                    label="location(taluka)"
                  >
                    {states.map((e) => (
                      <MenuItem value={e.id}>{e.state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {tradeFair.validationErrors.state_id}
                </FormHelperText>
              </>
            )}
          </Box>
        </div>
        <InputField
          label="Location"
          type="text"
          value={tradeFair.location_address}
          onChange={handleLocationAddressChange}
          onFocus={handleResetFocus}
          error={!!tradeFair.validationErrors.location_address}
          helperText={tradeFair.validationErrors.location_address}
        />
        <DatePick
          label="From Date"
          className="custom-date-picker"
          disbrushDate={tradeFair.from_date}
          onChange={handleFromDateChange}
          errorText={!!tradeFair.validationErrors.from_date}
        />
        <DatePick
          label="To Date"
          className="custom-date-picker"
          disbrushDate={tradeFair.to_date}
          onChange={handleToDateChange}
          errorText={!!tradeFair.validationErrors.to_date}
        />

        <InputField
          label="Name of the Trade Fair and Exhibition"
          type="text"
          value={tradeFair.name_of_trade_fair_exhibition}
          onChange={handlePurposeChange}
          onFocus={handleResetFocus}
          error={!!tradeFair.validationErrors.name_of_trade_fair_exhibition}
          helperText={tradeFair.validationErrors.name_of_trade_fair_exhibition}
        />
        <div></div>
        <div className="col-md-3 input1Heading">
          <span>Participants</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={tradeFair.participants_male}
          onChange={(value)=>handleInputChange("participants_male",value)}
          onFocus={handleResetFocus}
          error={!!tradeFair.validationErrors.participants_male}
          helperText={tradeFair.validationErrors.participants_male}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={tradeFair.participants_female}
          onChange={(value)=>handleInputChange("participants_female",value)}
          onFocus={handleResetFocus}
          error={!!tradeFair.validationErrors.participants_female}
          helperText={tradeFair.validationErrors.participants_female}
        />
        <InputField
          col="3"
          label="Total Participants"
          type="number"
          value={tradeFair.participants_total}
          readOnly={true}
        />
      </div>
    </>
  );
}
