
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  "7": {
    magnet_crop_id:[],
    location_taluka:'',
    no_of_farmers:'',
   
    area:'',
    name_of_certification_agency_lab:'',
    total_project_cost: '',
    matching_grant:'',
    grant_disbursement_amt:'',
    grant_disbursement_date:'',
    training_type_id: "7"
  },
    // Validation
  validationErrors:{  
    magnet_crop_id:[],
  location_taluka:'',
  no_of_farmers:'',
 
  area:'',
  name_of_certification_agency_lab:'',
  total_project_cost: '',
  matching_grant:'',
  grant_disbursement_amt:'',
  grant_disbursement_date:'',
  
        }
    };

const CertificationCostMasterSlice = createSlice({
  name: 'CertificationCostMasterSlice',
  initialState,
  reducers: {
    setInputFieldDataCertification: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["7"][action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataCertificationCostMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["7"]) {
        if (payload[key] === null) {
          state["7"][key] = '';
        }else{
          state["7"][key] = payload[key];
        }
      }
    },
    resetDataCertificationCostMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsCertificationCostMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputFieldDataCertification, updateApiDataCertificationCostMaster, resetDataCertificationCostMaster } = CertificationCostMasterSlice.actions;

export default CertificationCostMasterSlice.reducer;
