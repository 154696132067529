import { InputField } from "../../InputField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldFullPartOperData,
  setTotalFullMaleEmp,
  setTotalFullFemaleEmp,
  setTotalFullTotalEmp,
  setTotalFullSC,
  setTotalFullST,
  setTotalFullPWD,
  setTotalFullBPL,
  resetValidationOper,
  setTotalFullOBC,
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
} from "../../../redux/slice/InputThree/EmpGenOperInpuThree/FullPartTimeOperSlice";
import "../../../assets/css/inputForm.css";

export function FullTimeOper(props) {
  const dispatch = useDispatch();
  const fullPartTimeOperForm = useSelector((state) => state.FullPartTimeOper);

  useEffect(() => {
    const calculateTotals = () => {
      // Calculate total SC
      const totalSC =
        parseInt(fullPartTimeOperForm.full_time_emp_num_sc_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_sc_female || 0);
      dispatch(setTotalFullSC(totalSC));

      // Calculate total ST
      const totalST =
        parseInt(fullPartTimeOperForm.full_time_emp_num_st_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_st_female || 0);
      dispatch(setTotalFullST(totalST));

      // Calculate total PWD
      const totalPWD =
        parseInt(fullPartTimeOperForm.full_time_emp_num_pwd_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_pwd_female || 0);
      dispatch(setTotalFullPWD(totalPWD));

      // Calculate total OBC/Minority/Open
      const totalOBCMinorityOpen =
        parseInt(fullPartTimeOperForm.full_time_emp_num_obc_minority_open_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_obc_minority_open_female || 0);
      dispatch(setTotalFullOBC(totalOBCMinorityOpen));

      // Calculate total full time male employees
      const totalFullTimeEmpMale =
        parseInt(fullPartTimeOperForm.full_time_emp_num_sc_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_st_male || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_obc_minority_open_male || 0);
      dispatch(setTotalFullMaleEmp(totalFullTimeEmpMale));

      // Calculate total full time female employees
      const totalFullTimeEmpFemale =
        parseInt(fullPartTimeOperForm.full_time_emp_num_sc_female || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_st_female || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_obc_minority_open_female || 0);
      dispatch(setTotalFullFemaleEmp(totalFullTimeEmpFemale));

      // Calculate total full time employees
      const totalFullTimeEmpTotal =
        parseInt(fullPartTimeOperForm.full_time_emp_num_sc_total || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_st_total || 0) +
        parseInt(fullPartTimeOperForm.full_time_emp_num_obc_minority_open_total || 0);
      dispatch(setTotalFullTotalEmp(totalFullTimeEmpTotal));

      // Calculate total general male employees
      const totalGenEmpMale =
        parseInt(fullPartTimeOperForm.full_time_emp_num_male || 0) +
        parseInt(fullPartTimeOperForm.part_time_emp_num_male || 0);
      dispatch(setTotalGenMaleEmp(totalGenEmpMale));

      // Calculate total general female employees
      const totalGenEmpFemale =
        parseInt(fullPartTimeOperForm.full_time_emp_num_female || 0) +
        parseInt(fullPartTimeOperForm.part_time_emp_num_female || 0);
      dispatch(setTotalGenFemaleEmp(totalGenEmpFemale));

      // Calculate total general employees
      const totalGenEmpTotal =
        parseInt(fullPartTimeOperForm.full_time_emp_num_total || 0) +
        parseInt(fullPartTimeOperForm.part_time_emp_num_total || 0);
      dispatch(setTotalGenTotalEmp(totalGenEmpTotal));
    };

    calculateTotals();
  }, [dispatch, fullPartTimeOperForm]);

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldFullPartOperData({ fieldName, value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidationOper());
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_sc_male}
          onChange={(value) => handleInputChange("full_time_emp_num_sc_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_sc_male}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_sc_female}
          onChange={(value) => handleInputChange("full_time_emp_num_sc_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_sc_female}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_st_male}
          onChange={(value) => handleInputChange("full_time_emp_num_st_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_st_male}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_st_female}
          onChange={(value) => handleInputChange("full_time_emp_num_st_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_st_female}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_st_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_obc_minority_open_male}
          onChange={(value) => handleInputChange("full_time_emp_num_obc_minority_open_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_obc_minority_open_male}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_obc_minority_open_female}
          onChange={(value) => handleInputChange("full_time_emp_num_obc_minority_open_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_obc_minority_open_female}
          helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_obc_minority_open_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_obc_minority_open_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_male}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_female}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Full Time"
          type="number"
          value={fullPartTimeOperForm.full_time_emp_num_total}
          readOnly={true}
        />

        <div style={{ marginTop: "3rem", marginLeft: "1px" }} className="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={fullPartTimeOperForm.full_time_emp_num_pwd_male}
            onChange={(value) => handleInputChange("full_time_emp_num_pwd_male", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_pwd_male}
            helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={fullPartTimeOperForm.full_time_emp_num_pwd_female}
            onChange={(value) => handleInputChange("full_time_emp_num_pwd_female", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeOperForm.validationErrors.full_time_emp_num_pwd_female}
            helperText={fullPartTimeOperForm.validationErrors.full_time_emp_num_pwd_female}
          />
          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={fullPartTimeOperForm.full_time_emp_num_pwd_total}
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
}
