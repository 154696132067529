import React, { useEffect, useState } from "react";
import { InputField } from "../../InputField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePick } from "../../DatePick";
import {
  setInputFieldData,
  resetValidation,
  setTotalSC,
  setTotalST,
  setTotalPWD,
  setTotalOBC,
  setTotalParticipants,
  setTotalMaleParticipants,
  setTotalFemaleParticipants,
} from "../../../redux/slice/InputOne/CapacityBuilding/CapacityBuildingSlice";
import "../../../assets/css/inputForm.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, FormHelperText } from "@mui/material";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { MultiSelect } from "../../MultiSelect";
import { DatePickerCapacityBuilding } from "../../DatePickerCapacityBuilding";

export function GapTraining(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const capacityBuilding = useSelector((state) => state.capacityBuilding);
  console.log(
    props.disbrushDate,
    capacityBuilding.date,
    "disbrushDate",
    "capacityBuilding.date"
  );

  const [taluka, setTaluka] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  console.log(capacityBuilding, "cap Building");

  useEffect(() => {
    const workData = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    workData();
    const savedDate = localStorage.getItem("date");
    if (savedDate) {
      dispatch(setInputFieldData({ fieldName: "date", value: savedDate }));
    }
  }, []);

  // Total of SC
  const totalSC =
    parseInt(capacityBuilding.sc_male === "" ? 0 : capacityBuilding.sc_male) +
    parseInt(
      capacityBuilding.sc_female === "" ? 0 : capacityBuilding.sc_female
    );
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(capacityBuilding.st_male === "" ? 0 : capacityBuilding.st_male) +
    parseInt(
      capacityBuilding.st_female === "" ? 0 : capacityBuilding.st_female
    );
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(capacityBuilding.pwd_male === "" ? 0 : capacityBuilding.pwd_male) +
    parseInt(
      capacityBuilding.pwd_female === "" ? 0 : capacityBuilding.pwd_female
    );
  dispatch(setTotalPWD(totalPWD));

  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      capacityBuilding.obc_minority_open_male === ""
        ? 0
        : capacityBuilding.obc_minority_open_male
    ) +
    parseInt(
      capacityBuilding.obc_minority_open_female === ""
        ? 0
        : capacityBuilding.obc_minority_open_female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));

  
  const totalMaleParticipants =
    parseInt(capacityBuilding.sc_male || 0) +
    parseInt(capacityBuilding.st_male || 0) +
    +parseInt(capacityBuilding.obc_minority_open_male || 0);
  dispatch(setTotalMaleParticipants(totalMaleParticipants));

  const totalFemaleParticipants =
    parseInt(
      capacityBuilding.sc_female === "" ? 0 : capacityBuilding.sc_female
    ) +
    parseInt(
      capacityBuilding.st_female === "" ? 0 : capacityBuilding.st_female
    ) +
    +parseInt(
      capacityBuilding.obc_minority_open_female === ""
        ? 0
        : capacityBuilding.obc_minority_open_female
    );
  dispatch(setTotalFemaleParticipants(totalFemaleParticipants));

  const totalParticipants =
    parseInt(capacityBuilding.sc_total === "" ? 0 : capacityBuilding.sc_total) +
    parseInt(capacityBuilding.st_total === "" ? 0 : capacityBuilding.st_total) +
    +parseInt(
      capacityBuilding.obc_minority_open_total === ""
        ? 0
        : capacityBuilding.obc_minority_open_total
    );
  dispatch(setTotalParticipants(totalParticipants));

  
  const handleFieldResourcePerson = (value) => {
    dispatch(setInputFieldData({ fieldName: "resource_person", value }));
  };
  const handleFieldTopicCovered = (value) => {
    dispatch(setInputFieldData({ fieldName: "topic_covered", value }));
  };


  const refresh = () => {
    props.resetdata(); // Call the function
  };
  const handleOptionstaluka = (event) => {
    refresh();
    const locationTaluka = event.target.value;
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: locationTaluka,
      })
    );
    console.log("Location Taluka:", locationTaluka);
    if (props.compValue === 5) {
      // const date = capacityBuilding.date;
      const date = selectedDate || capacityBuilding.date;
      console.log("Date:", date);
      props.handleOptionsGAP5(event, locationTaluka, date);
    }
  };

  const handleMultiMagnetCropChange = (data) => {
    dispatch(setInputFieldData({ fieldName: "magnet_crop_id", value: data }));
  };


  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}

      <div class="row g-3">
       
        {props.compValue === 5 ? (
         
          <DatePickerCapacityBuilding
            label="Date"
            disbrushDate={capacityBuilding.date} // Pass the date from state
            onChange={(date) => setSelectedDate(date)} // Handle date change
            errorText={!!capacityBuilding.validationErrors.date}
          />
        ) : (
          ""
        )}

        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            <FormControl
              fullWidth
              error={
                capacityBuilding.validationErrors.location_taluka !== ""
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="demo-simple-select-label">
                Location (Taluka)
              </InputLabel>
              <Select
                readOnly={props.compValue === 5 ? false : true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={capacityBuilding.location_taluka}
                label="Location (Taluka)"
                onChange={handleOptionstaluka}
              >
                {taluka.map((e, key) => (
                  <MenuItem value={e.id} key={key}>
                    {e.taluka}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: "#d32f2f" }}>
              {capacityBuilding.validationErrors.location_taluka}
            </FormHelperText>
          </Box>
        </div>

        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <MultiSelect
            readOnly={props.compValue === 5 ? false : true}
            label="MAGNET Crops"
            data={props.magentCrop}
            errorText={
              capacityBuilding.validationErrors.magnet_crop_id.length !== 0
                ? true
                : false
            }
            value={capacityBuilding.magnet_crop_id}
            onChange={handleMultiMagnetCropChange}
          />
        </div>

        {props.compValue === 7 ? (
          <>
            <InputField
              label="Nos. of Farmers availing certificates"
              type="number"
              value={capacityBuilding.no_of_farmers}
              readOnly={true}
            />
            <InputField
              label="Area under Certifications"
              type="number"
              endAr="(Acres)"
              value={capacityBuilding.area}
              readOnly={true}
            />
            <InputField
              label="Name of Certification Agency"
              type="text"
              value={capacityBuilding.name_of_certification_agency_lab}
              readOnly={true}
            />
            <InputField
              label="Total Cost of Certification"
              type="number"
              value={capacityBuilding.total_cost}
              readOnly={true}
            />
          </>
        ) : props.compValue === 6 ? (
          <>
            <InputField
              label="Nos. of Farmers applying of MRL Testing"
              type="number"
              value={capacityBuilding.no_of_farmers}
              readOnly={true}
            />
            <InputField
              label="Name of Certification Testing Laboritory"
              type="text"
              value={capacityBuilding.name_of_certification_agency_lab}
              readOnly={true}
            />
            <InputField
              label="Total Testing Cost"
              type="number"
              value={capacityBuilding.total_cost}
              readOnly={true}
            />
          </>
        ) : props.compValue === 8 ? (
          <>
            <InputField
              label="Nos. of Farmers covered"
              type="number"
              value={capacityBuilding.no_of_farmers}
              readOnly={true}
            />
            <InputField
              label="Total Fruit care cost"
              type="text"
              value={capacityBuilding.total_cost}
              readOnly={true}
            />
          </>
        ) : (
          ""
        )}

        {props.compValue !== 5 ? (
          <>
            <InputField
              label="Total Saction Grant from MAGNET"
              type="number"
              endAr="(Lakhs)"
              value={capacityBuilding.total_saction_grant}
              readOnly={true}
            />
            <InputField
              label="Grant Disbursement Amount"
              type="number"
              endAr="(Lakhs)"
              value={capacityBuilding.grant_disbursement_amt}
              readOnly={true}
            />
            <DatePick
              label="Grant Disbursement Date"
              className="custom-date-picker"
              disbrushDate={capacityBuilding.grant_disbursement_date}
              readOnly={true}
            />
          </>
        ) : (
          ""
        )}
        {props.compValue === 5 ? (
          <>
            <InputField
              label="Resource Person/ Agency"
              type="text"
              value={capacityBuilding.resource_person}
              onChange={handleFieldResourcePerson}
              onFocus={handleResetFocus}
              error={!!capacityBuilding.validationErrors.resource_person}
              helperText={capacityBuilding.validationErrors.resource_person}
            />
            <InputField
              label="Topic Covered"
              type="text"
              value={capacityBuilding.topic_covered}
              onChange={handleFieldTopicCovered}
              onFocus={handleResetFocus}
              error={!!capacityBuilding.validationErrors.topic_covered}
              helperText={capacityBuilding.validationErrors.topic_covered}
            />
          </>
        ) : (
          ""
        )}

        {/* <InputField label="PH" type="text"/> */}
        <div className="word-with-line" style={{ margin: "1.5rem 0" }}>
          <span>Vulnerable groups & Vulnerability</span>
        </div>
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={capacityBuilding.sc_male}
          onChange={(value) => handleInputChange("sc_male", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.sc_male}
          helperText={capacityBuilding.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={capacityBuilding.sc_female}
          onChange={(value) => handleInputChange("sc_female", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.sc_female}
          helperText={capacityBuilding.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={capacityBuilding.sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={capacityBuilding.st_male}
          onChange={(value) => handleInputChange("st_male", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.st_male}
          helperText={capacityBuilding.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={capacityBuilding.st_female}
          onChange={(value) => handleInputChange("st_female", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.st_female}
          helperText={capacityBuilding.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={capacityBuilding.st_total}
          readOnly={true}
        />

       
        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={capacityBuilding.obc_minority_open_male}
          onChange={(value) =>
            handleInputChange("obc_minority_open_male", value)
          }
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.obc_minority_open_male}
          helperText={capacityBuilding.validationErrors.obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={capacityBuilding.obc_minority_open_female}
          onChange={(value) =>
            handleInputChange("obc_minority_open_female", value)
          }
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.obc_minority_open_female}
          helperText={
            capacityBuilding.validationErrors.obc_minority_open_female
          }
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={capacityBuilding.obc_minority_open_total}
          readOnly={true}
        />
        <div className="col-md-3 input1Heading">
          <span>No of Trainee's</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={capacityBuilding.beneficiaries_male}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={capacityBuilding.beneficiaries_female}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Trainee's"
          type="number"
          value={capacityBuilding.beneficiaries_total}
          readOnly={true}
        />
      </div>

      <div style={{ marginTop: "3rem" }} class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>PWD</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={capacityBuilding.pwd_male}
          onChange={(value) => handleInputChange("pwd_male", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.pwd_male}
          helperText={capacityBuilding.validationErrors.pwd_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={capacityBuilding.pwd_female}
          onChange={(value) => handleInputChange("pwd_female", value)}
          onFocus={handleResetFocus}
          error={!!capacityBuilding.validationErrors.pwd_female}
          helperText={capacityBuilding.validationErrors.pwd_female}
        />
        <InputField
          col="3"
          label="Total PWD"
          type="number"
          value={capacityBuilding.pwd_total}
          readOnly={true}
        />
      </div>
      
    </>
  );
}
