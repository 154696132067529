import { Offline, Online } from "react-detect-offline"
import '../../assets/css/rolePermission.css'
import { Header } from "../Header"
import { LeftNavigation } from "../LeftNavigation"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import axios from "axios"
import { getConfigWithToken } from "../../utils/Config/Config"
import Swal from "sweetalert2"
import { Box, Pagination } from "@mui/material"

export const AddRole = () => {
    const location = useLocation()
    const navigate = useNavigate();

    const id = location.state

    const apiUrl = process.env.REACT_APP_API_URL;
    const [editRoleFlag, setEditRoleFlag] = useState(false)
    const [masterPermission, setMasterPermission] = useState([])
    const [inputOnePermission, setInputOnePermission] = useState([])
    const [inputTwoPermission, setInputTwoPermission] = useState([])
    const [inputThreePermission, setInputThreePermission] = useState([])
    const [outputPermission, setOutputPermission] = useState([])
    const [prevValueCheck, setPrevValueCheck] = useState([])
    const [activeTab, setActiveTab] = useState(1);
    const [valuesToCheck, setValuesToCheck] = useState([])

    const name = useRef(null)

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };


    // Function to handle checkbox click
    const handleCheckboxClick = (permissionAction) => {
        if (valuesToCheck.includes(permissionAction)) {
            setValuesToCheck((prevValues) => prevValues.filter(item => item !== permissionAction));
        } else {
            setValuesToCheck((prevValues) => [...prevValues, permissionAction]);
        }
    };
    

    const handleSavePermission = async () => {
        try {
            if(!editRoleFlag){
                
                const obj = {
                    name: name.current.value,
                    permission: valuesToCheck
                }
                
                const response = await axios.post(`${apiUrl}api/role-create`, obj,getConfigWithToken());
            
                // Handle the response accordingly
                
                if(response.data["status"] === true){
                    Swal.fire(
                        `Role Permissions Created Successfully`,
                        '',
                        'success'
                    )
                }
            }else{
                
                const obj = {
                    id:id,
                    name: name.current.value,
                    permission: valuesToCheck

                }
                
                const response = await axios.post(`${apiUrl}api/role-update`, obj ,getConfigWithToken());
            
                // Handle the response accordingly
                
                if(response.data["status"] === true){
                    Swal.fire(
                        `Role Permissions Edited Successfully`,
                        '',
                        'success'
                    )
                }
            }
        }catch (error) {
            console.error('Error making API request:', error);
        }
            
    }
    const attributeOptions =[]


    useEffect(()=>{
        
        async function getData(){
            const masterdata = await axios.get(`${apiUrl}api/permission-listing?all=1`, getConfigWithToken())
            console.log(masterdata.data.data["Master"],"iudsviudsv")
            setMasterPermission(masterdata.data.data["Master"])
            setInputOnePermission(masterdata.data.data["InputOne"])
            setInputTwoPermission(masterdata.data.data["InputTwo"])
            setInputThreePermission(masterdata.data.data["InputThree"])
            setOutputPermission(masterdata.data.data["Output"])
            if(id){
                setEditRoleFlag(true)
                const editData = await axios.get(`${apiUrl}api/role-edit?id=${id}`, getConfigWithToken())
                const editDta = editData.data["data"]
                name.current.value = editDta["role"]["name"]
                const permission = editDta["permissions"].map((e)=>(e.name))
                
                setValuesToCheck(permission)
            }
        }
        getData()
        
    },[])

    const handleColumnCheckboxClick = (columnName) => {
        const permissionArray = Object.values(masterPermission).flatMap(permissionList =>
            permissionList.filter(permission => permission.name.includes(columnName)).map(permission => permission.name)
          );
        console.log(permissionArray,"iudsnvds")
        if (permissionArray.every(permission => valuesToCheck.includes(permission))) {
            // If any of the permissions in permissionArray is already present, remove them
            const updatedValues = valuesToCheck.filter(permission => !permissionArray.includes(permission));
            setValuesToCheck(updatedValues);
        } else {
            // Otherwise, add all permissions from permissionArray
            setValuesToCheck([...valuesToCheck, ...permissionArray]);
        }
      };
      
    const isColumnChecked = (columnName) => {
        const permissionArray = Object.values(masterPermission).flatMap(permissionList =>
            permissionList.filter(permission => permission.name.includes(columnName)).map(permission => permission.name)
          );
        return permissionArray.every((item) => valuesToCheck.includes(item));
      };
      
    return (
        <>
            <Header/>
            <LeftNavigation/>
            <main id="main" class="main">
                <section class="section">
                    <div class="row">
                    <div id="exTab3" class="contain" style={{ width: "87%", position: "absolute", right: "0rem", top: "3.5rem"}}>
                        <div class="card" style={{backgroundColor: "#F8F8F8" }}>
                        {/* Add Beneficiary/Back */}
                        <div style={{display: "flex", justifyContent: "space-between",
                            width: "92%",marginLeft: "5rem", marginTop: "1rem",
                            padding:"1rem 4rem", border: "1.5px solid #E8E8E8"}}>
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "2rem"}}>{id?"Edit Role":"Add Role"}</span>
                            </div>
                            <div>
                                <span style={{color: "#4e2683", fontWeight: "bold", cursor:"pointer"}} onClick={()=> navigate(-1)}>
                                <i className="fa-solid fa-arrow-left"
                                    style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "2rem",
                                        marginRight: "0.5rem",
                                        color: "black",
                                        cursor: "pointer"
                                    }}
                                    onClick={()=> navigate(-1)}
                                    ></i>
                                    Back
                                </span>
                                <button
                                    style={{backgroundColor: "#4e2683",fontWeight: "bold",color: "white",padding: "5px 25px",marginLeft: "15px"}}
                                    onClick={handleSavePermission}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        {/* Listing Table */}
                        {/* <UserListing /> */}
                        <section>
                            <div style={{left: "5rem", position:"relative"}}>
                                <div class="col-5" style={{right: '17px'}}>
                                    <label for="inputNanme4" class="form-label">Name</label>
                                    <input type="text" ref={name} class="form-control" id="inputNanme4"/>
                                </div>
                                <div>
                                    <h4 style={{lineHeight: '3'}} >All Module</h4>
                                </div>
                                
                                <div>
                                    <ul className="tabs">
                                        <li className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>Input</li>
                                        <li className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}>Output</li>
                                        <li className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabClick(3)}>Master</li>
                                    </ul>

                                    <div className="tab-content-role">
                                        {activeTab === 1 && <p>
                                            <div class="accordion" id="accordionExample">
                                                <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Input 1
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                    <Box sx={{ width: "100%", height: "maxContent", typography: "body1", border: "3px solid #E8E8E8", backgroundColor: "#FFFFFF" }}>
                                                        <table style={{border: "1px solid black",backgroundColor: "#E2E2E2",width: "100%"}}>
                                                            <thead>
                                                                <tr style={{ textAlign: "left" }}>
                                                                <th style={{ textAlign: "center" }}>Form List</th>
                                                                <th style={{ textAlign: "center" }}>View</th>
                                                                <th style={{ textAlign: "center" }}>Edit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(inputOnePermission).map((key) => (
                                                                    <tr key={key}>
                                                                        <td style={{ border: "1px solid black" }}>{key}</td>
                                                                        {inputOnePermission[key].map((option) => (
                                                                            <td key={option.id} style={{ border: "1px solid black", width: "10%" }}>
                                                                                <input
                                                                                    style={{
                                                                                        height: "25px",
                                                                                        width: "15%",
                                                                                        borderRadius: "7px",
                                                                                        border: "1px solid #E2E2E2",
                                                                                        backgroundColor: "#FFFFFF"
                                                                                    }}
                                                                                    type="checkbox"
                                                                                    value={option.name}
                                                                                    onChange={() => handleCheckboxClick(`${option.name}`)}
                                                                                    checked={valuesToCheck.includes(option.name)}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Input 2
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <Box sx={{ width: "100%", height: "maxContent", typography: "body1", border: "3px solid #E8E8E8", backgroundColor: "#FFFFFF" }}>
                                                            <table style={{border: "1px solid black",backgroundColor: "#E2E2E2",width: "100%"}}>
                                                                <thead>
                                                                    <tr style={{ textAlign: "left" }}>
                                                                    <th style={{ textAlign: "center" }}>Form List</th>
                                                                    <th style={{ textAlign: "center" }}>View</th>
                                                                    <th style={{ textAlign: "center" }}>Edit</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(inputTwoPermission).map((key) => (
                                                                        <tr key={key}>
                                                                            <td style={{ border: "1px solid black" }}>{key}</td>
                                                                            {inputTwoPermission[key].map((option) => (
                                                                                <td key={option.id} style={{ border: "1px solid black", width: "10%" }}>
                                                                                    <input
                                                                                        style={{
                                                                                            height: "25px",
                                                                                            width: "15%",
                                                                                            borderRadius: "7px",
                                                                                            border: "1px solid #E2E2E2",
                                                                                            backgroundColor: "#FFFFFF"
                                                                                        }}
                                                                                        type="checkbox"
                                                                                        value={option.name}
                                                                                        onChange={() => handleCheckboxClick(`${option.name}`)}
                                                                                        checked={valuesToCheck.includes(option.name)}
                                                                                    />
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </Box>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Input 3
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <Box sx={{ width: "100%", height: "maxContent", typography: "body1", border: "3px solid #E8E8E8", backgroundColor: "#FFFFFF" }}>
                                                            <table style={{border: "1px solid black",backgroundColor: "#E2E2E2",width: "100%"}}>
                                                                <thead>
                                                                    <tr style={{ textAlign: "left" }}>
                                                                    <th style={{ textAlign: "center" }}>Form List</th>
                                                                    <th style={{ textAlign: "center" }}>View</th>
                                                                    <th style={{ textAlign: "center" }}>Edit</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(inputThreePermission).map((key) => (
                                                                        <tr key={key}>
                                                                            <td style={{ border: "1px solid black" }}>{key}</td>
                                                                            {inputThreePermission[key].map((option) => (
                                                                                <td key={option.id} style={{ border: "1px solid black", width: "10%" }}>
                                                                                    <input
                                                                                        style={{
                                                                                            height: "25px",
                                                                                            width: "15%",
                                                                                            borderRadius: "7px",
                                                                                            border: "1px solid #E2E2E2",
                                                                                            backgroundColor: "#FFFFFF"
                                                                                        }}
                                                                                        type="checkbox"
                                                                                        value={option.name}
                                                                                        onChange={() => handleCheckboxClick(`${option.name}`)}
                                                                                        checked={valuesToCheck.includes(option.name)}
                                                                                    />
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </Box>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </p>}
                                        {activeTab === 2 && <p>
                                            <Box sx={{ width: "100%", height: "maxContent", typography: "body1", border: "3px solid #E8E8E8", backgroundColor: "#FFFFFF" }}>
                                                        <table style={{border: "1px solid black",backgroundColor: "#E2E2E2",width: "100%"}}>
                                                            <thead>
                                                                <tr style={{ textAlign: "left" }}>
                                                                <th style={{ textAlign: "center" }}>Form List</th>
                                                                <th style={{ textAlign: "center" }}>View</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(outputPermission).map((key) => (
                                                                    <tr key={key}>
                                                                        <td style={{ border: "1px solid black" }}>{key}</td>
                                                                        {outputPermission[key].map((option) => (
                                                                            <td key={option.id} style={{ border: "1px solid black", width: "10%" }}>
                                                                                <input
                                                                                    style={{
                                                                                        height: "25px",
                                                                                        width: "15%",
                                                                                        borderRadius: "7px",
                                                                                        border: "1px solid #E2E2E2",
                                                                                        backgroundColor: "#FFFFFF"
                                                                                    }}
                                                                                    type="checkbox"
                                                                                    value={option.name}
                                                                                    onChange={() => handleCheckboxClick(`${option.name}`)}
                                                                                    checked={valuesToCheck.includes(option.name)}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                            </p>}
                                        {activeTab === 3 && <p>
                                            <Box sx={{ width: "100%", height: "maxContent", typography: "body1", border: "3px solid #E8E8E8", backgroundColor: "#FFFFFF" }}>
                                                <table style={{border: "1px solid black",backgroundColor: "#E2E2E2",width: "100%"}}>
                                                    <thead>
                                                        <tr style={{ textAlign: "left" }}>
                                                        <th style={{ textAlign: "center" }}>Form List</th>
                                                        <th>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                            Listing
                                                            <input
                                                                style={{
                                                                height: "25px",
                                                                width: "15%",
                                                                borderRadius: "7px",
                                                                border: "1px solid #E2E2E2",
                                                                backgroundColor: "#FFFFFF"
                                                                }}
                                                                type="checkbox"
                                                                onChange={() => handleColumnCheckboxClick("-listing")}
                                                                checked={isColumnChecked("-listing")}
                                                            />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                            Create
                                                            <input
                                                                style={{
                                                                height: "25px",
                                                                width: "15%",
                                                                borderRadius: "7px",
                                                                border: "1px solid #E2E2E2",
                                                                backgroundColor: "#FFFFFF"
                                                                }}
                                                                type="checkbox"
                                                                onChange={() => handleColumnCheckboxClick("-create")}
                                                                checked={isColumnChecked("-create")}
                                                            />
                                                            </div>
                                                            </th>
                                                        <th style={{ textAlign: "center" }}>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                            Update
                                                            <input
                                                                style={{
                                                                height: "25px",
                                                                width: "15%",
                                                                borderRadius: "7px",
                                                                border: "1px solid #E2E2E2",
                                                                backgroundColor: "#FFFFFF"
                                                                }}
                                                                type="checkbox"
                                                                onChange={() => handleColumnCheckboxClick("-update")}
                                                                checked={isColumnChecked("-update")}
                                                            />
                                                            </div>
                                                            </th>
                                                        <th style={{ textAlign: "center" }}>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                            View
                                                            <input
                                                                style={{
                                                                height: "25px",
                                                                width: "15%",
                                                                borderRadius: "7px",
                                                                border: "1px solid #E2E2E2",
                                                                backgroundColor: "#FFFFFF"
                                                                }}
                                                                type="checkbox"
                                                                onChange={() => handleColumnCheckboxClick("-view")}
                                                                checked={isColumnChecked("-view")}
                                                            />
                                                            </div>
                                                            </th>
                                                        <th style={{ textAlign: "center" }}>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                            Delete
                                                            <input
                                                                style={{
                                                                height: "25px",
                                                                width: "15%",
                                                                borderRadius: "7px",
                                                                border: "1px solid #E2E2E2",
                                                                backgroundColor: "#FFFFFF"
                                                                }}
                                                                type="checkbox"
                                                                onChange={() => handleColumnCheckboxClick("-delete")}
                                                                checked={isColumnChecked("-delete")}
                                                            />
                                                            </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(masterPermission).map((key) => (
                                                            <tr key={key}>
                                                                <td style={{ border: "1px solid black" }}>{key}</td>
                                                                {masterPermission[key].map((option) => (
                                                                    <td key={option.id} style={{ border: "1px solid black", width: "10%" }}>
                                                                        <input
                                                                            style={{
                                                                                height: "25px",
                                                                                width: "15%",
                                                                                borderRadius: "7px",
                                                                                border: "1px solid #E2E2E2",
                                                                                backgroundColor: "#FFFFFF"
                                                                            }}
                                                                            type="checkbox"
                                                                            value={option.name}
                                                                            onChange={() => handleCheckboxClick(`${option.name}`)}
                                                                            checked={valuesToCheck.includes(option.name)}
                                                                        />
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Box>
                                        </p>}
                                    </div>
                                    </div>
                            </div>
                            
                        </section>
                        </div>
                    </div>
                    </div>
                </section>
            </main>
        </>
    )
}