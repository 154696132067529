import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/EntityMaster/SupportCommMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { SupportCommNewTechTable } from './EntityTables/SupportCommNewTechTable';


export const EntitySuppComNewTech = () =>{ 

    const supportCommMaster = useSelector(state => state.supportCommMaster["26"]);
    const dispatch = useDispatch();
    
    const handleFieldTechnologyCost = (event) => {
        dispatch(setInputFieldData({ fieldName: 'total_project_cost', value:event.target.value }));
    }
    const handleFieldTermLoan = (event) => {
        dispatch(setInputFieldData({ fieldName: 'bank_term_loan', value:event.target.value }));
    }
    const handleFieldTermGrant = (event) => {
        dispatch(setInputFieldData({ fieldName: 'matching_grant', value:event.target.value }));
    }

    const actual_date_1 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_1', value:event.target.value }));
    }

    const actual_date_2 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_2', value:event.target.value }));
    }
    const actual_date_3 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_3', value:event.target.value }));
    }

    const actual_date_4 = (event) => {
        dispatch(setInputFieldData({ fieldName: 'actual_date_4', value:event.target.value }));
    }

    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
    return(<>   
    {/* <Online> */}
       <div className='tab-pane'>
            <form class="row g-3" id='formid'>
                
                    {/* Cost of Technology */}
                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="TechnologyCost " style={{width:"290px"}}>Cost of Technology</label>
                        <input type="number" name="TechnologyCost " 
                            value={supportCommMaster.total_project_cost}
                            onChange={handleFieldTechnologyCost} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                    />
                    </div> */}
                
                    {/*Term Loans*/}
                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Term Loans</label>
                        <input type="number" name="TermLoan " 
                            value={supportCommMaster.bank_term_loan}
                            onChange={handleFieldTermLoan} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                    />
                    </div> */}

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T1</label>
                        <input type="date" name="TermLoan " 
                            value={supportCommMaster.actual_date_1}
                            onChange={actual_date_1} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T2</label>
                        <input type="date" name="TermLoan " 
                            value={supportCommMaster.actual_date_2}
                            onChange={actual_date_2} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T3</label>
                        <input type="date" name="TermLoan " 
                            value={supportCommMaster.actual_date_3}
                            onChange={actual_date_3} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>

                    {/* <div className='primaryTableLabel'>
                        <label htmlFor="TermLoan " style={{width:"290px"}}>Actual Disbursement Date for T4</label>
                        <input type="date" name="TermLoan " 
                            value={supportCommMaster.actual_date_4}
                            onChange={actual_date_4} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div> */}
<br/>
                    <SupportCommNewTechTable/>
            </form>
            {/* Add Payment Tranche Details */}
        </div>     
    {/* </Online>
    <Offline>
        You're Offline, Please Check your Connection
    </Offline> */}
    </>)
}

