import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  total_project_cost: '',
  beneficiary_contribution: '',
  total_saction_grant:'',
  payment_tranche: '',
  tranche_id:'',

  amount_disbursement: '',
  disbursement_date: null,
  financial_progress_till_date_pct:'',
  total_exp_till_date:'',
  total_beneficiary_exp_till_date:'',
  total_grant_exp_till_date:'',
  beneficiary_exp_incurred:'',
  grant_exp_incurred:'',
  exp_incurred:'',

  validationErrors:{
    beneficiary_exp_incurred:'',
    grant_exp_incurred:'',
    exp_incurred:'',
    // expenditureIncurred:'',
  }
};

const IntroVarietySlice = createSlice({
  name: 'IntroVarietySlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataToIntro: (state, action) =>{
      const payload = action.payload;
      state.financial_progress_till_date_pct = payload.financial_progress_till_date_pct ? payload.financial_progress_till_date_pct : 0
      state.total_exp_till_date = payload.total_exp_till_date ? payload.total_exp_till_date : 0
      state.total_beneficiary_exp_till_date = payload.total_beneficiary_exp_till_date ? payload.total_beneficiary_exp_till_date : 0
      state.total_grant_exp_till_date = payload.total_grant_exp_till_date ? payload.total_grant_exp_till_date : 0
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataIntro: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsIntro: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsIntro, resetValidation, setInputFieldData, updateApiDataToIntro, resetDataIntro } = IntroVarietySlice.actions;

export default IntroVarietySlice.reducer;
