import { Offline, Online } from "react-detect-offline";
import { Box } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../../../../utils/Config/Config";
import { useEffect } from "react";
import "../../../../../assets/css/masterdata.css";
import { useDispatch, useSelector } from "react-redux";
import { setInputActivity } from "../../../../../redux/slice/EntityMaster/MarketDevAssisMasterSlice";
import { useState } from "react";

export const EntityMarketDevAssTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const [components, setComponents] = useState([])
    const { typedata } = useSelector((state) => state.marketAssistenceMaster["17"]);
  
  
    
    const handleActivityValue = (id, category_id, type, index, fieldName, value) => {
      const updatedInputActivity = JSON.parse(JSON.stringify(typedata)); // Deep copy
    
      const existingCategoryIndex = updatedInputActivity.findIndex((item) => item.category_id === category_id);
    
      if (existingCategoryIndex !== -1) {
        const existingCategory = updatedInputActivity[existingCategoryIndex];
        const existingTypeIndex = existingCategory.typedata.findIndex((t) => t.type === type);
    
        if (existingTypeIndex !== -1) {
          const existingType = existingCategory.typedata[existingTypeIndex];
    
          const certificate = existingType.certificate || {};
    
          // Corrected field names for "country_of_exhibition" and "location_of_exhibition"
          const certificateOrder = [
            "name_of_certification_exhibition",
            "name_of_certification_agency_lab",
            "location_of_exhibition",  // Corrected field name
            "country_of_exhibition",   // Corrected field name
            "total_project_cost",
            "matching_grant",
            "beneficiary_contribution",
          ];
    
          certificateOrder.forEach((property) => {
            if (fieldName === property) {
              certificate[fieldName] = value;
            } else if (certificate.hasOwnProperty(property)) {
              certificate[property] = certificate[property];
            }
          });
    
          existingCategory.typedata[existingTypeIndex] = {
            ...existingType,
            certificate: certificate,
            id: id, // Add item.id to the typeData array
          };
        } else {
          existingCategory.typedata.push({
            type,
            certificate: {
              [fieldName]: value,
            },
            id: id, // Add item.id to the typeData array
          });
        }
    
        updatedInputActivity[existingCategoryIndex] = existingCategory;
      } else {
        updatedInputActivity.push({
          category_id,
          typedata: [
            {
              type,
              certificate: {
                [fieldName]: value,
              },
              id: id, // Add item.id to the typeData array
            },
          ],
        });
      }
    
      dispatch(setInputActivity(updatedInputActivity));
    };
    
    
    useEffect(() => {
      async function getData() {
        try {
          const response = await axios.get(`${apiUrl}api/getCapacityBuildingTrainingTypeDataByForm?form_id=17`, getConfigWithToken());
          setComponents(response.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
  
          if (error.response) {
            console.error("Server responded with an error:", error.response.data);
          } else if (error.request) {
            console.error("No response received from the server.");
          } else {
            console.error("Error setting up the request:", error.message);
          }
  
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Network Issue. Please try again. Check console for details.",
          });
        }
      }
  
      getData();
    }, []);
  

    const submitasssss = (e) => {
        e.preventDefault();
        console.log(typedata, "inputActivity");
    };
    const renderInputValue = (item, fieldName) => {
      const existingCategory = typedata.find((t) => t.category_id === item.category_id);
    
      if (existingCategory) {
        const existingType = existingCategory.typedata.find((t) => t.type === item.type);
    
        if (existingType && existingType.certificate && existingType.certificate[fieldName] !== undefined) {
          return existingType.certificate[fieldName];
        }
      }
    
      return '';
    };
    const calculateSummary = () => {
      let totalProjectCost = 0;
      let matchinggrant = 0;
      let beneficiaryContribution = 0;
    
      typedata.forEach(category => {
        category.typedata.forEach(item => {
          if (item.certificate) {
            totalProjectCost += parseFloat(item.certificate.total_project_cost || 0);
            matchinggrant += parseFloat(item.certificate.matching_grant || 0);
            beneficiaryContribution += parseFloat(item.certificate.beneficiary_contribution || 0);
          }
        });
      });
    
      return {
        totalProjectCost:totalProjectCost.toFixed(2),
        matchinggrant:matchinggrant.toFixed(2),
        beneficiaryContribution:beneficiaryContribution.toFixed(2),
      };
    };
    console.log(calculateSummary().totalProjectCost,'calculateSummary().totalProjectCost')
    
    
    return (
        <>
            {/* <Online> */}
                <Box
                    sx={{
                        width: "100%",
                        height: "maxContent",
                        typography: "body1",
                        border: "3px solid #E8E8E8",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                <div style={{marginBottom:'-20px',marginLeft:'3px',fontSize:'13px'}}>
                    <p>All Values in Lakhs</p>
                  </div>
                    {/* <button onClick={submitasssss}>Submit</button> */}
                    <table style={{ border: "1px solid white", backgroundColor: "white", width: "100%" }}>
                        <thead>
                            <tr style={{ textAlign: "left", color: "white", backgroundColor: "#4e2683" }}>
                                <th style={{ textAlign: "center", width: "25%" }}>Exhibition</th>
                                <th style={{ textAlign: "center" }}>Name of Exhibition/Fair</th>
                                <th style={{ textAlign: "center" }}>Location of Exhibition/Fair</th>
                                <th style={{ textAlign: "center" }}>Country of Exhibition</th>
                                <th style={{ textAlign: "center" }}>Total Cost</th>
                                <th style={{ textAlign: "center" }}>Total Sanction Grant from MAGNET</th>
                                <th style={{ textAlign: "center" }}>Beneficiary Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            {components.map((item) => (
                                <tr key={item.id}>
                                    <td style={{ border: "1px solid black" }}>{item.type}</td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="text"
                                            value={renderInputValue(item, 'name_of_certification_exhibition')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 1, "name_of_certification_exhibition", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="text"
                                            value={renderInputValue(item, 'location_of_exhibition')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 2, "location_of_exhibition", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="text"
                                            value={renderInputValue(item, 'country_of_exhibition')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 3, "country_of_exhibition", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item, 'total_project_cost')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 4, "total_project_cost", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item, 'matching_grant')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 5, "matching_grant", event.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{
                                                height: "25px",
                                                width: "100%",
                                                borderRadius: "7px",
                                                border: "1px solid #E2E2E2",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="number"
                                            value={renderInputValue(item, 'beneficiary_contribution')}
                                            onChange={(event) =>
                                                handleActivityValue(item.id, item.category_id, item.type, 6, "beneficiary_contribution", event.target.value)
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                              <tr style={{   textAlign: "center", border:'1px solid black' }}>
                    <td style={{   fontWeight: "bold", border:'1px solid black'  }}>Summary</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{   textAlign: "left", border:'1px solid black'  }}>{calculateSummary().totalProjectCost}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().matchinggrant}</td>
                    <td style={{   textAlign: "left",border:'1px solid black'   }}>{calculateSummary().beneficiaryContribution}</td>
                    
                  </tr>
                        </tbody>
                    </table>
                </Box>
            {/* </Online>
            <Offline>You're Offline, Please Check your Connection</Offline> */}
        </>
    );
};