import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  name_of_certification: '',
  name_of_certification_agency: '',
  location_taluka: '',

  total_cost: "",
  total_saction_grant: "",
  beneficiary_contribution: "",
  disbursement_amt: "",
  disbursement_date: null,


  validationErrors:{
    disbursement_amt: "",
    disbursement_date: "",
  }
};

const otherCertificationSlice = createSlice({
  name: 'otherCertificationSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateOtherCertiForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataOtherCerti: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsOtherCerti: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsOtherCerti, resetValidation, setInputFieldData, updateOtherCertiForm, resetDataOtherCerti} = otherCertificationSlice.actions;

export default otherCertificationSlice.reducer;
