import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total_revenue: "",
  net_profit: "",
  profit: "",

  validationErrors: {
    total_revenue: "",
    net_profit: "",
  },
};

const BeneficiariesProfitSlice = createSlice({
  name: "beneficiaryProfitMaster",
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },
    updateApiDataToBeneProfit: (state, action) => {
      const payload = action.payload;
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    updateImpactFormData: (state, action) =>{
      const payload = action.payload;
      console.log(payload,"Payload")
      state.net_profit = payload.net_profit
      state.total_revenue = payload.total_revenue
    },
    resetDataBeneProfit: (state) => {
      return { ...initialState };
    },
    setValidationErrorsBeneProfit: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetBeneProfitValidation: (state, action) => {
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {
  setValidationErrorsBeneProfit,
  resetBeneProfitValidation,
  setInputFieldData,
  updateApiDataToBeneProfit,
  resetDataBeneProfit,
  updateImpactFormData,
} = BeneficiariesProfitSlice.actions;

export default BeneficiariesProfitSlice.reducer;
