import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  expenditureIncurred:'',
  promotersExpenditureInc:'',
  matchingGrantExpenditureInc:'',
  termLoanExpenditureInc:'',

  amountDisbrushment: '',
  tranche_id: '',
  tranche:'',
  disbursement_date: '',
  physicalProgressTD:'',
  physical_progress_tcv_td: '',

  // Current Quarter
  totalProjectCost: '',
  totalPromotersContribution: '',
  totalMatchingGrant:'',
  totalTermLoan:'',
  physical_progress: '',
  validationErrors:{
   
  }
};

const masterDataSlice = createSlice({
  name: 'masterDataSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataToMaster: (state, action) =>{
      state.totalProjectCost = action.payload.total_project_cost !== undefined ? action.payload.total_project_cost : 0;
      state.totalPromotersContribution = action.payload.promoters_contribution !== undefined ? action.payload.promoters_contribution : 0;
      state.totalTermLoan = action.payload.bank_term_loan !== undefined ? action.payload.bank_term_loan : 0;

      state.amountDisbrushment = action.payload.amount_disbursement !== undefined ? action.payload.amount_disbursement : 0;
      state.disbursement_date = action.payload.disbursement_date !== undefined ? action.payload.disbursement_date : 0;
      state.tranche = action.payload.tranche !== undefined ? action.payload.tranche : 0;

      state.totalMatchingGrant = action.payload.matching_grant !== undefined ? action.payload.matching_grant : 0;
      state.totalExpenditureTD = action.payload.total_exp_td !== undefined ? action.payload.total_exp_td : 0;
      state.totalPromotorsExpenditureTD = action.payload.total_promoter_exp_td !== undefined ? action.payload.total_promoter_exp_td : 0;
      state.totalMatchingGrantExpenditureTD = action.payload.total_matching_grant_exp_td !== undefined ? action.payload.total_matching_grant_exp_td : 0;
      state.totalTermLoanTD = action.payload.total_term_loan_exp_td !== undefined ? action.payload.total_term_loan_exp_td : 0;
      state.physicalProgressTD = action.payload.physical_progress_td !== undefined ? action.payload.physical_progress_td : 0;
      state.physical_progress = action.payload.physical_progress !== undefined ? action.payload.physical_progress : 0;
      state.physical_progress_tcv_td = action.payload.physical_progress_tcv_td !== undefined ? action.payload.physical_progress_tcv_td : 0;
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },
    resetData: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsMaster, resetValidation, setInputFieldData, updateApiDataToMaster, resetData } = masterDataSlice.actions;

export default masterDataSlice.reducer;
