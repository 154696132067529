import "../assets/css/LoginPage.css";
import logo from "../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import Swal from "sweetalert2";

export const ForgotPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!email) {
      setErrorMessage("Email Field is required.");
    } else if (!isValidEmail(email)) {
      setErrorMessage("Invalid email.");
    } else if (!modal) {
      setModal(true);
    }

    try {
      const response = await axios.post(
        `${apiUrl}api/get_user_security_quiz_by_email`,
        { email }
      );
      console.log(response, "email response");

      if (response.data["status"] === true) {
        
          navigate("/security-questions", {
            state: { questions: response.data.data, email },
          });
      
      } else {
        Swal.fire(`${response.data["message"]}`, "", "warning");
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const isValidEmail = (email) => {
    return validator.isEmail(email);
  };

  const handleModal = () => {
    setModal("");
  };

  return (
    <>
      <div className="container">
        <div style={{ position: "relative", top: "3rem" }}>
          <div className="form-group">
            <img src={logo} alt="Logo" height="100px" />
            <h3>Forgot Password?</h3>
          </div>
          <div>
            <div className="email">
              <TextField
                id="Email"
                label="Registered Email"
                variant="outlined"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </div>
            {errorMessage && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
                style={{ margin: "8px 0" }}
              >
                {errorMessage}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            {successMessage && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
                style={{ margin: "8px 0" }}
              >
                {successMessage}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            <button className="login-button-reset" onClick={handleSubmit}>
              Verify Email
            </button>
            <div style={{ marginTop: "10px" }}>
              <span>
                Back to <Link to="/">Sign In</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
