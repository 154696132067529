import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import axios from "axios";
import AxiosRetry from "axios-retry";
import Swal from "sweetalert2";
import {
  Box,
  CircularProgress,
  Button,
  Pagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import { useNavigate } from "react-router-dom";

export const MGBeneficiaryListing = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [totalRecords, setTotalRecords] = useState(0);

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
    }
  };

  const handleEdit = (id) => {
    navigate("/MG-beneficiary", { state: { id } });
  };

  const handleDeleteSelected = async () => {
    if (selectedRows.length === 0) {
      Swal.fire("No rows selected for deletion", "", "warning");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete selected rows!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deletePromises = selectedRows.map((id) =>
            axios.get(
              `${apiUrl}api/primary-data-delete?applicant_id=${id}`,
              getConfigWithToken()
            )
          );

          const deleteResponses = await Promise.all(deletePromises);

          const successfulDeletions = deleteResponses.filter(
            (response) => response.data.status === true
          );

          if (successfulDeletions.length > 0) {
            Swal.fire({
              title: "Deleted!",
              text: "Selected rows have been deleted.",
              icon: "success",
            });

            const updatedAttribute = tableData.filter(
              (e) => !selectedRows.includes(e.id)
            );
            setTableData(updatedAttribute);
            setSelectedRows([]);
            setTotalRecords(updatedAttribute.length); // Update total records
          } else {
            Swal.fire("Error while deleting selected rows", "", "warning");
          }
        } catch {
          Swal.fire("Error while deleting selected rows", "", "warning");
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "idForDelete",
        Cell: ({ cell: { value } }) => (
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(value)}
            style={{ marginLeft: "8px" }}
          />
        ),
      },
      {
        Header: "Name of the Applicant",
        accessor: "applicant_name",
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
      {
        Header: "Division",
        accessor: "divisions",
      },
      {
        Header: "District",
        accessor: "district",
      },
      {
        Header: "Taluka",
        accessor: "taluka",
      },
      {
        Header: "Total Project Cost",
        accessor: "total_project_cost",
      },
      {
        Header: "Promoter Equity",
        accessor: "promoter_equity",
      },
      {
        Header: "Bank Term Loan",
        accessor: "bank_term_loan",
      },
      {
        Header: "Matching Grant",
        accessor: "matching_grant",
      },
      {
        Header: "Edit",
        accessor: "actionId",
        Cell: ({ value }) => (
          <div>
            <i
              className="fa-solid fa-pen-to-square"
              onClick={() => handleEdit(value)}
              style={{ cursor: "pointer", color: "#4e2683" }}
            ></i>
          </div>
        ),
      },
    ],
    []
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800;

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable({
    columns,
    data: tableData,
  });


  const cellStyle = {
    padding: "8px",
    border: "1px solid #ccc",
    textAlign: "center",
    verticalAlign: "middle",
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(
          `${apiUrl}api/getAllPrimaryData?page=${currentPage}`,
          getConfigWithToken()
        );
        const data = response.data.data;
        const formattedData = data.data.map((e) => {
          return {
            ...e,
            idForDelete: e.applicant_id,
            actionId: e.applicant_id,
          };
        });
        setTableData(formattedData);
        setTotalRecords(data.total);
        setCurrentPage(data.current_page);
        setPageSize(data.per_page);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          Swal.fire("An error occurred, please refresh the page", "", "error");
        } else {
          throw new Error("Data Not Found");
        }
      }
    }
    getData();
  }, [currentPage]);
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  

  const pageCount = Math.ceil(totalRecords / pageSize);

  if (loading) {
    return (
      <Box sx={{ display: "flex", width: "1000px" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div
        className="row"
        style={{
          width: "90%",
          marginLeft: "4rem",
          marginTop: "1rem",
          typography: "body1",
        }}
      >
        <div className="col-md-1" style={{ marginBottom: "15px" }}>
          <Box sx={{ width: "75%" }}>
            <Button
              onClick={handleDeleteSelected}
              style={{
                color: "#4e2683",
                fontWeight: "bold",
                fontSize: "11.4px",
                backgroundColor: "#8080801f",
                height: "47px",
                width: "103px",
                border: "0.5px solid #4e2683",
                marginLeft: "-9px",
              }}
            >
              Delete
            </Button>
          </Box>
        </div>

        <div className="col-md-2" style={{ marginTop: "1rem", width: "35%" }}>
          <p style={{ fontSize: "15px", marginLeft: "40px" }}>{totalRecords} Records found</p>
        </div>

        <div className="col-md-3"></div>

        <table {...getTableProps()} className="my-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={column.id}
                    style={{
                      borderBottom: "2px solid #4e2683",
                      background: "#4F2D7F",
                      fontWeight: "bold",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    style={{
                      borderBottom: "1px solid #ccc",
                      backgroundColor: "#fff",
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        key={cell.column.id}
                        style={{
                          padding: "5px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {rowIndex === rows.length - 1 && (
                    <tr style={{ height: "50px" }}>
                      {columns.map((column, columnIndex) => (
                        <td key={columnIndex} style={{ padding: "5px" }} />
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </div>
    </>
  );
};
