import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePick } from "../../DatePick";
import { InputField } from "../../InputField";
import { useEffect, useState } from "react";
import {
  setInputFieldData,
  resetValidation,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/TotTraining/TotTrainingSlice";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/inputForm.css";
import { MultiSelect } from "../../MultiSelect";

export function TotTraining() {
  const dispatch = useDispatch();
  const totTraining = useSelector((state) => state.totTraining);
  const [taluka, setTaluka] = useState([]);
  const [division, setDivision] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const totTraining = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        const divisi = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        const divisionData = divisi.data["data"].map((e) => ({
          id: e.id,
          crop: e.divisions,
        }));
        setDivision(divisionData);
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    totTraining();
  }, []);

  // Total of OBC/Minority/Open
  const totalParticipants =
    parseInt(
      totTraining.participants_male === "" ? 0 : totTraining.participants_male
    ) +
    parseInt(
      totTraining.participants_female === ""
        ? 0
        : totTraining.participants_female
    );
  dispatch(setTotalParticipants(totalParticipants));

  // Dispatch functions for Participants fields
  const handleFromDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleFieldLocationAddress = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handleOptionstaluka = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: event.target.value,
      })
    );
  };

  const handleFieldNameOfOrganization = (value) => {
    dispatch(setInputFieldData({ fieldName: "name_of_organization", value }));
  };

  const handleFieldNominationByDivision = (value) => {
    dispatch(setInputFieldData({ fieldName: "nomination_by_division", value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };
  return (
    <>
      <InputField
        label="Name of the Organization"
        type="text"
        value={totTraining.name_of_organization}
        onChange={handleFieldNameOfOrganization}
        onFocus={handleResetFocus}
        error={!!totTraining.validationErrors.name_of_organization}
        helperText={totTraining.validationErrors.name_of_organization}
      />
      <DatePick
        label="From Date"
        className="custom-date-picker"
        disbrushDate={totTraining.from_date}
        onChange={handleFromDateChange}
        errorText={!!totTraining.validationErrors.from_date}
      />
      <DatePick
        label="To Date"
        className="custom-date-picker"
        disbrushDate={totTraining.to_date}
        onChange={handleToDateChange}
        errorText={!!totTraining.validationErrors.to_date}
      />
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <Box sx={{ minWidth: "100%" }}>
          <FormControl
            fullWidth
            error={
              totTraining.validationErrors.location_taluka !== "" ? true : false
            }
          >
            <InputLabel htmlFor="demo-simple-select-label">
              Location (Taluka)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={totTraining.location_taluka}
              label="Location (Taluka)"
              onChange={handleOptionstaluka}
            >
              {taluka.map((e, key) => (
                <MenuItem value={e.id} key={key}>
                  {e.taluka}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: "#d32f2f" }}>
            {totTraining.validationErrors.location_taluka}
          </FormHelperText>
        </Box>
      </div>
      <InputField
        label="Location(Address)"
        type="text"
        value={totTraining.location_address}
        onChange={handleFieldLocationAddress}
        onFocus={handleResetFocus}
        error={!!totTraining.validationErrors.location_address}
        helperText={totTraining.validationErrors.location_address}
      />
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <MultiSelect
          label="Nominated by Division"
          data={division}
          errorText={totTraining.validationErrors.nomination_by_division}
          value={totTraining.nomination_by_division}
          onChange={handleFieldNominationByDivision}
        />
      </div>
     
      <div className="word-with-line"></div>

      <div className="col-md-3 input1Heading">
        <span>Participants- MSAMB, PIU, KVK</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={totTraining.participants_male}
        onChange={(value)=>handleInputChange("participants_male",value)}
        onFocus={handleResetFocus}
        error={!!totTraining.validationErrors.participants_male}
        helperText={totTraining.validationErrors.participants_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={totTraining.participants_female}
        onChange={(value)=>handleInputChange("participants_female",value)}
        onFocus={handleResetFocus}
        error={!!totTraining.validationErrors.participants_female}
        helperText={totTraining.validationErrors.participants_female}
      />
      <InputField
        col="3"
        label="Total Participants"
        type="number"
        value={totTraining.participants_total}
        readOnly={true}
      />
    </>
  );
}
