import { useEffect } from "react";
import { Dashboard } from "../components/Dashboard"
import { Header } from "../components/Header"
import { LeftNavigation } from "../components/LeftNavigation"
import {  useNavigate } from "react-router-dom";

export const MainDash = () =>{
    return(<>
        <Header/>
        <LeftNavigation/>
        <Dashboard/>
    </>)
}