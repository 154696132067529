import { InputField } from "../../InputField";
import React, { useState } from "react";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
} from "../../../redux/slice/InputOne/OtherCertification/OtherCertificationSlice";
import axios from "axios";
import { useEffect } from "react";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { Box, FormHelperText } from "@mui/material";

export function OtherCertification(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const otherCertification = useSelector((state) => state.otherCertification);
  const [taluka, setTaluka] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    valueChain();
  }, []);

 
  const handleDisbursementDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "disbursement_date", value }));
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };
  return (
    <>
      {/* othe certification cost */}
      <div class="row g-3">
        <InputField
          label="Certification Name"
          type="text"
          readOnly={true}
          value={otherCertification.name_of_certification}
        />
        <InputField
          label="Certification Agency"
          type="text"
          readOnly={true}
          value={otherCertification.name_of_certification_agency}
        />
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="demo-simple-select-label">
                Location (Taluka)
              </InputLabel>
              <Select
                readOnly={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={otherCertification.location_taluka}
                label="Location (Taluka)"
              >
                {taluka.map((e, key) => (
                  <MenuItem value={e.id} key={key}>
                    {e.taluka}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <InputField
          label="Total Cost"
          type="number"
          endAr="(Lakhs)"
          readOnly={true}
          value={otherCertification.total_cost}
        />
        <InputField
          label="Total Saction Grant from MAGNET"
          type="text"
          endAr="(Lakhs)"
          readOnly={true}
          value={otherCertification.total_saction_grant}
        />

        <InputField
          label="Beneficiary Contribution"
          type="number"
          endAR="(Lakhs)"
          readOnly={true}
          value={otherCertification.beneficiary_contribution}
        />
        <div className="word-with-line">
          <span>Details of Current Quarter - {props.compName}</span>

          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <InputField
          label="Grant Disbursement"
          type="number"
          value={otherCertification.disbursement_amt}
          onChange={(value)=>handleInputChange("disbursement_amt",value)}
          onFocus={handleResetFocus}
          error={!!otherCertification.validationErrors.disbursement_amt}
          helperText={otherCertification.validationErrors.disbursement_amt}
        />
        <DatePick
          label="Disbursement Date"
          className="custom-date-picker"
          disbrushDate={otherCertification.disbursement_date}
          onChange={handleDisbursementDateChange}
          onFocus={handleResetFocus}
          errorText={!!otherCertification.validationErrors.disbursement_date}
          helperText={otherCertification.validationErrors.disbursement_date}
        />
      </div>
    </>
  );
}
