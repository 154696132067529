import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";

export const MagnetCropAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({})
  const [page, setPage] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;

// Add new Attibute
  const handleAddOption = async () => {
    try {
      const { value: enterValue } = await Swal.fire({
        title: "Enter Magnet Crop Attribute",
        input: "text",
        inputLabel: "Magnet Crop Attribute",
        inputValue: "",
        showCancelButton: true,
        confirmButtonText: "Save",
        inputValidator: (value) => {
          if (!value) {
            return "Enter Magnet Crop Attribute";
          }
        },
      });

      if (enterValue) {
        const response = await axios.post(
          `${apiUrl}api/crop-create`,
          {
            crop: enterValue,
          },
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire(`Attribute Added Successfully`, "", "success");
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error adding Magnet Crop attribute:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Edit the exisiting attibute
  const handleEditFunction = async (value, id) => {
    try {
      const { value: editValue } = await Swal.fire({
        title: "Edit Magnet Crop Attribute",
        input: "text",
        inputLabel: "Your Magnet Crop Attribute",
        inputValue: value,
        showCancelButton: true,
        confirmButtonText: "Edit",
        inputValidator: (value) => {
          if (!value) {
            return "Enter Magnet Crop Attribute";
          }
        },
      });

      if (editValue) {
        const response = await axios.post(
          `${apiUrl}api/crop-update`,
          {
            crop: editValue,
            crop_id: id,
          },
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire(`Attribute Edited Successfully`, "", "success");
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error editing Magnet Crop attribute:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Delete exisiting Attribute
  const handleDeleteFunction = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await axios.get(
          `${apiUrl}api/crop-delete?id=${id}`,
          getConfigWithToken()
        );

        if (response.data && response.data.status === true) {
          Swal.fire({
            title: "Deleted!",
            text: "Attribute has been deleted.",
            icon: "success",
          });
          await fetchData();
        } else {
          throw new Error("Unexpected response structure");
        }
      }
    } catch (error) {
      console.error("Error deleting Magnet Crop attribute:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // onChange of Pagination
  const handleChange = async (event, value) => {
    try {
      const getTalukas = await axios.get(
        `${apiUrl}api/getAllCrop?page=${value}`,
        getConfigWithToken()
      );
      const talukas = getTalukas.data["data"]["data"].map((e) => ({
        id: e.id,
        crop: e.crop,
      }));
      setAttributeOptions(talukas);
      setGetAllData(getTalukas.data.data);
      setPage(value);
    } catch (error) {
      console.error("Error fetching Talukas:", error);
    }
  };
  
  // Fetching the exisiting data
  const fetchData = async () => {
    try {
      const getTalukas = await axios.get(
        `${apiUrl}api/getAllCrop`,
        getConfigWithToken()
      );
      if (getTalukas.data && getTalukas.data.data && Array.isArray(getTalukas.data.data.data)) {
        const talukas = getTalukas.data.data.data.map((e) => ({
          id: e.id,
          crop: e.crop,
         
        }));
        setAttributeOptions(talukas);
        setGetAllData(getTalukas.data.data);
      } else {
        console.error('Unexpected response structure or empty data:', getTalukas.data);
        
      }
    } catch (error) {
      console.error('Error fetching Talukas:', error);
      
    }
  };

  useEffect(() => {
    async function getTalukasData() {
      try {
        const getTalukas = await axios.get(
          `${apiUrl}api/getAllCrop`,
          getConfigWithToken()
        );
        if (getTalukas.data && getTalukas.data.data && Array.isArray(getTalukas.data.data.data)) {
          const talukas = getTalukas.data.data.data.map((e) => ({
            id: e.id,
            crop: e.crop,
           
          }));
          setAttributeOptions(talukas);
          setGetAllData(getTalukas.data.data);
        } else {
          console.error('Unexpected response structure or empty data:', getTalukas.data);

        }
      } catch (error) {
        console.error('Error fetching Talukas:', error);
      }
    }

    getTalukasData();
  }, []);



  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div id="exTab3" className="contain" style={{ width: '100%', overflow: 'hidden' }}> 
            <div className="cardMaster" id="" style={{ width: '100%', marginTop: '0.5rem' }}>
              <form className="row g-3" style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}>
                <Box className="your-component" sx={{ typography: 'body1', width: "88%" }}>
                <div className="words-with-lines">
                      <span>Magnet Crop</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata" style={{marginRight:'1rem'}}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>
                  <Box className="your-box" sx={{typography: "body1"}}>
                    <div>
                      <span>Manage Options (Value of your Attribute)</span>
                    </div>
                  </Box>
                  <div className="your-button-container">
                    <button type="button" style={{width:"30%"}} onClick={(e) => handleAddOption(e)}>
                      <i className="fa-duotone fa-plus"></i> Add Attributes
                      Options{" "}
                    </button>
                  </div>
                  <Box className='custom-box' style={{marginLeft:'2rem'}} >
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Attribute Options</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="your-tbody">
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{border: "1px solid black"}}>
                              <input type="text" value={option.crop}  disabled/>
                            </td>
                            <td>
                              <i
                                class="fa-solid fa-pen-to-square"
                                onClick={()=>handleEditFunction(option.crop, option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={()=> handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                        />
                    </table>
                    
                  </Box>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
     
    </>
  );
};
