import { InputField } from "../../InputField";
import React from "react";
import "../../../assets/css/inputForm.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
} from "../../../redux/slice/InputOne/NurseryDevelopment/NurseryDevSlice";
import { usePermify } from "@permify/react-role";
import { DatePick } from "../../DatePick";
import { DatePickInput3 } from "../../DatePickInput3";

export function NurseryMasterForm(props) {
  const dispatch = useDispatch();
  const {
    exp_incurred,
    promoters_exp_incurred,
    matching_grant_exp_incurred,
    term_loan_exp_incurred,
    total_project_cost,
    promoters_contribution,
    matching_grant,
    total_term_loan_exp_till_date,
    total_exp_till_date,
    physical_progress_till_date_pct,
    total_promotors_exp_till_date,
    total_matching_grant_exp_till_date,
    term_loan,
    validationErrors,
    amount_disbursement,
    disbursement_date,
    payment_tranche,
  } = useSelector((state) => state.nurseryDev);

  const updateExpenditure = (
    termLoan,
    matching_grant_exp_incurred,
    promoters
  ) => {
    // const newValue = parseFloat(termLoan) + parseFloat(matching_grant_exp_incurred) + parseFloat(promoters);
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      parseFloat(termLoan) +
      parseFloatOrZero(matching_grant_exp_incurred) +
      parseFloat(promoters);
    dispatch(setInputFieldData({ fieldName: "exp_incurred", value: newValue }));
  };

  
  const handlePromotersExpenditureChange = (value) => {
    // Parse value to float
    const newValue = parseFloat(value);
    // Check if newValue is a positive number
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "promoters_exp_incurred", value }));
      updateExpenditure(
        newValue,
        matching_grant_exp_incurred,
        term_loan_exp_incurred
      );
    }
  };

  const handleMatchingGrantExpenditureChange = (value) => {
    // Parse value to float
    const newValue = parseFloat(value);
    // Check if newValue is a positive number
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({ fieldName: "matching_grant_exp_incurred", value })
      );
      updateExpenditure(
        promoters_exp_incurred,
        newValue,
        term_loan_exp_incurred
      );
    }
  };
  
  const handleTermLoanExpenditureChange = (value) => {
    // Parse value to float
    const newValue = parseFloat(value);
    // Check if newValue is a positive number
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(setInputFieldData({ fieldName: "term_loan_exp_incurred", value }));
      updateExpenditure(
        promoters_exp_incurred,
        matching_grant_exp_incurred,
        newValue
      );
    }
  };

  const handleexp_incurredChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "exp_incurred", value }));
  };

  // const handlePromotersExpenditureChange = (value) => {
  //   const newValue = parseFloat(value);
  //   dispatch(setInputFieldData({ fieldName: "promoters_exp_incurred", value }));
  //   updateExpenditure(
  //     term_loan_exp_incurred,
  //     matching_grant_exp_incurred,
  //     newValue
  //   );
  // };

  // const handleMatchingGrantExpenditureChange = (value) => {
  //   const newValue = parseFloat(value);
  //   dispatch(
  //     setInputFieldData({ fieldName: "matching_grant_exp_incurred", value })
  //   );
  //   updateExpenditure(term_loan_exp_incurred, newValue, promoters_exp_incurred);
  // };

  // const handleTermLoanExpenditureChange = (value) => {
  //   const newValue = parseFloat(value);
  //   dispatch(setInputFieldData({ fieldName: "term_loan_exp_incurred", value }));
  //   updateExpenditure(
  //     newValue,
  //     matching_grant_exp_incurred,
  //     promoters_exp_incurred
  //   );
  // };

  const handleTermLoanChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "total_term_loan_exp_till_date", value })
    );
  };

  const handleTotalExpenditureChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "total_exp_till_date", value }));
  };

  const handlePhysicalProgressChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "physical_progress_till_date_pct", value })
    );
  };

  const handleTotalPromotersExpenditureChange = (value) => {
    dispatch(
      setInputFieldData({ fieldName: "total_promotors_exp_till_date", value })
    );
  };

  const handleTotalMatchingGrantExpenditureChange = (value) => {
    dispatch(
      setInputFieldData({
        fieldName: "total_matching_grant_exp_till_date",
        value,
      })
    );
  };

  const handleTotalProjectCostChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "total_project_cost", value }));
  };

  const handlePromotersContributionChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "promoters_contribution", value }));
  };

  const handleMatchingGrantChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "matching_grant", value }));
  };

  const handleTotalTermLoanChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "term_loan", value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    const newValue = parseFloat(value);

   
    
  };

  return (
    <>
      <div class="row g-3">
        {/* <p className="subHeading">Current Quarter</p> */}
        <InputField
          label="Total Project Cost"
          type="number"
          endAr="(in lakhs)"
          value={total_project_cost ? total_project_cost.toString() : ""}
          onChange={handleTotalProjectCostChange}
          readOnly={true}
        />
        <InputField
          label="Total Promoters Contribution"
          type="number"
          endAr="(in lakhs)"
          value={
            promoters_contribution ? promoters_contribution.toString() : ""
          }
          onChange={handlePromotersContributionChange}
          readOnly={true}
        />
        <InputField
          label="Total Matching Grant"
          type="number"
          endAr="(in lakhs)"
          value={matching_grant ? matching_grant.toString() : ""}
          onChange={handleMatchingGrantChange}
          readOnly={true}
        />
        <InputField
          label="Total Term Loan"
          type="number"
          endAr="(in lakhs)"
          value={term_loan ? term_loan.toString() : ""}
          onChange={handleTermLoanChange}
          readOnly={true}
        />
        {/* <InputField label="Total Progress by the end of this Quarter" type="number" endAr="%" value={progressByEnd} onChange={handleProgressByEndChange} /> */}
      </div>
      <br />
      <div className="word-with-line">
        <span>Component wise Information</span>
        <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
      </div>
      <br />
      <div className="componentSub">
        <div class="row g-3">
          <InputField
            label="Payment/ Tranche"
            type="text"
            value={
              payment_tranche && payment_tranche
                ? payment_tranche.toString()
                : ""
            }
            readOnly={true}
          />
          <InputField
            label="Amount Disbursement"
            type="number"
            endAr="(in lakhs)"
            value={amount_disbursement ? amount_disbursement.toString() : 0}
            readOnly={true}
          />
          {/* <DatePick label="Disbursement Date" className="custom-date-picker" disbrushDate={disbursement_date ? disbursement_date.toString() : null} readOnly={true}/> */}
          <DatePickInput3
            label="Disbursement Date"
            className="custom-date-picker"
            disbrushDate={
              disbursement_date ? disbursement_date.toString() : null
            }
            readOnly={true}
          />
          {/* <p className="subHeading">Till Date</p> */}
          <InputField
            label="Total expenditure till date"
            type="number"
            endAr="(in lakhs)"
            value={total_exp_till_date ? total_exp_till_date.toString() : ''}
            onChange={handleTotalExpenditureChange}
            readOnly={true}
          />
          <InputField
            label="Financial Progress till date"
            type="number"
            endAr="%"
            value={
              physical_progress_till_date_pct
                ? physical_progress_till_date_pct
                : 0
            }
            onChange={handlePhysicalProgressChange}
            readOnly={true}
          />
          {/* <InputField
            label="Total Promotors expenditure till date"
            type="number"
            endAr="₹"
            value={
              total_promotors_exp_till_date
                ? total_promotors_exp_till_date.toString()
                : ""
            }
            onChange={handleTotalPromotersExpenditureChange}
            readOnly={true}
          />
          <InputField
            label="Total Matching Grant expenditure till date"
            type="number"
            endAr="₹"
            value={
              total_matching_grant_exp_till_date
                ? total_matching_grant_exp_till_date.toString()
                : ""
            }
            onChange={handleTotalMatchingGrantExpenditureChange}
            readOnly={true}
          />
          <InputField
            label="Total Term Loan expenditure till date"
            type="number"
            endAr="₹"
            value={
              total_term_loan_exp_till_date
                ? total_term_loan_exp_till_date.toString()
                : ""
            }
            onChange={handleTotalTermLoanChange}
            readOnly={true}
          />
        </div>
        <br />
           */}
         
        <div className="word-with-line">
          <span>
            Details of expenditure for Current Quarter - {props.compName}
          </span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>
        <br />
          {/*
        <div class="row g-3">
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputField
              label="Promoters expenditure incurred"
              type="number"
              endAr="₹"
              value={
                promoters_exp_incurred ? promoters_exp_incurred.toString() : ""
              }
              onChange={handlePromotersExpenditureChange}
              onFocus={handleResetFocus}
              error={!!validationErrors.promoters_exp_incurred}
              helperText={validationErrors.promoters_exp_incurred}
            />
            <span>+</span>
            <InputField
              label="Matching Grant expenditure incurred"
              type="number"
              endAr="₹"
              readOnly={props.matchingGrant === "True" ? false : true}
              value={
                matching_grant_exp_incurred
                  ? matching_grant_exp_incurred.toString()
                  : ""
              }
              onChange={handleMatchingGrantExpenditureChange}
              onFocus={handleResetFocus}
              error={!!validationErrors.matching_grant_exp_incurred}
              helperText={validationErrors.matching_grant_exp_incurred}
            />
            <span>+</span>
            <InputField
              label="Term Loan expenditure incurred"
              type="number"
              endAr="₹"
              value={
                term_loan_exp_incurred ? term_loan_exp_incurred.toString() : ""
              }
              onChange={handleTermLoanExpenditureChange}
              onFocus={handleResetFocus}
              error={!!validationErrors.term_loan_exp_incurred}
              helperText={validationErrors.term_loan_exp_incurred}
            />
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <span style={{ marginLeft: "15px" }}>=</span> */}
            <InputField
                label="Expenditure incurred"
                type="number"
                endAr="(in lakhs)"
                value={exp_incurred ? exp_incurred.toString() : ""}
                onChange={handleexp_incurredChange}
                onFocus={handleResetFocus}
                error={!!validationErrors.exp_incurred}
                helperText={validationErrors.exp_incurred}
              />
          </div>
        </div>
      </div>
    </>
  );
}
