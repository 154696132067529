import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  exp_incurred:'',
  promoters_exp_incurred:'',
  matching_grant_exp_incurred:'',
  term_loan_exp_incurred:'',

  total_term_loan_exp_till_date:'',
  total_exp_till_date:'',
  physical_progress_till_date_pct:'',
  total_promotors_exp_till_date:'',
  total_matching_grant_exp_till_date:'',

  // Current Quarter
  total_project_cost: '',
  promoters_contribution: '',
  matching_grant:'',
  term_loan:'',
  
  payment_tranche: '',
  tranche_id: '',

  amount_disbursement: '',
  disbursement_date: null,

  validationErrors:{
    promoters_exp_incurred:'',
    matching_grant_exp_incurred:'',
    term_loan_exp_incurred:'',
  }
};

const TissueCultureSlice = createSlice({
  name: 'TissueCultureSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    // Add other reducers for different input fields if needed
    updateApiDataToTissue: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      // state.payment_tranche = payload.tranch.payment_tranche
      // state.tranche_id = payload.tranch.tranche_id
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataTissue: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsTissue: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsTissue, resetValidation, setInputFieldData, updateApiDataToTissue, resetDataTissue } = TissueCultureSlice.actions;

export default TissueCultureSlice.reducer;
