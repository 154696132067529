import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import {
  Box,
  CircularProgress,
  FormControl,
  Button,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";
import AxiosRetry from "axios-retry";
import * as XLSX from 'xlsx';

export const OutputTableFinAccInfra = ({
  setDivision,
  currentQuarter,
  financialYear,
  currentFinancialYear,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectSubFilter, setSelectSubFilter] = useState("Q1");
  const [tableData, setTableData] = useState([]);
  const [selectFormId, setSelectFormId] = useState("4");

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });
  function filterTransformData(data) {
    const transformedData = data.map((entry) => {
      if (entry.magnet_crop_id) {
        let magnetCrops;
        try {
          // Try parsing as JSON
          const magnetCropIds = JSON.parse(entry.magnet_crop_id);
          magnetCrops = magnetCropIds.map((crop) => `${crop.name}, `).join("");
        } catch (error) {
          // If parsing fails, treat it as a plain string and split
          magnetCrops = entry.magnet_crop_id
            .split(",")
            .map((crop) => `${crop.trim()}, `)
            .join("");
        }
        return { ...entry, magnet_crop_id: magnetCrops };
      } else {
        // If magnet_crop_id field is missing, return the entry as it is
        return entry;
      }
    });

    return transformedData;
  }

  const handleSelectFormType = async (event) => {
    setSelectFormId(event.target.value);
    await fetchData(
      event.target.value,
      selectDivision,
      selectSubFilter,
      selectFinnacialYear
    );
  };

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    await fetchData(
      selectFormId,
      e.target.value,
      selectSubFilter,
      selectFinnacialYear
    );
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    await fetchData(
      selectFormId,
      selectDivision,
      selectSubFilter,
      e.target.value
    );
  };

  const handleSubFilter = async (e) => {
    if (!selectFinnacialYear) {
      Swal.fire("Please Select Financial Year", "", "warning");
      return;
    }
    setSelectSubFilter(e.target.value);
    await fetchData(
      selectFormId,
      selectDivision,
      e.target.value,
      selectFinnacialYear
    );
  };

  const fetchData = async (formId, divisionId, subFilter, financialYear) => {
    setLoading(true);
    try {
      const quarr = ["Q1", "Q2", "Q3", "Q4"];
      let url = `${apiUrl}api/accessToFinanceForInfrastructure?form_id=${formId}&division_id=${divisionId}&yearly=${financialYear}`;
      url += quarr.includes(subFilter)
        ? `&quarter_no=${subFilter}`
        : `&half_yearly=${subFilter}`;

      const response = await axiosInstance.get(url, getConfigWithToken());
      const data = response.data.data.flat(); 
      setTableData(filterTransformData(data));
    } catch (error) {
      Swal.fire("Data Fetch Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      await fetchData(
        selectFormId,
        selectDivision,
        currentQuarter,
        currentFinancialYear
      );
      setDivisions(setDivision);
    };
    initializeData();
  }, [setDivision, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: "Beneficiary Name",
        accessor: "beneficiary_name",
      },
      ...(selectFormId !== "4" && selectFormId !== "5"
        ? [
          {
        Header: "MAGNET Crops(Multiple selection)",
        accessor: "magnet_crop_id",
      },
      ]
    : []),
      {
        Header: "Division",
        accessor: "divisions",
      },
      {
        Header: "District",
        accessor: "district",
      },
      {
        Header: "Taluka",
        accessor: "taluka",
      },
      ...(selectFormId !== "4" && selectFormId !== "5"
        ? [
          {
            Header: "Area of Nursery in (Ha)",
            accessor: "area",
          },
          {
            Header: "Capacity (Total Plants)",
            accessor: "capacity_total_plants",
          },
          ]
        : []),
      
      {
        Header: "Payment/ Tranche",
        accessor: "tranche",
      },
      {
        Header: "Disbursement Date",
        accessor: "disbursement_date",
      },
      {
        Header: "Amount Disbursement (In Lakh)",
        accessor: "amount_disbursement",
      },
      {
        Header: "Total project Cost (in Lakh)",
        accessor: "total_project_cost",
      },
      {
        Header: "Promoters Contribution (in Lakh)",
        accessor: "promoters_contribution",
      },
      {
        Header: "Matching Grant (in Lakh)",
        accessor: "matching_grant",
      },
      {
        Header: "Term Loan (in Lakh)",
        accessor: "term_loan",
      },
      
      
      ...(selectFormId !== "4" && selectFormId !== "5"
        ? [
          {
            Header: "Total expenditure till date (in Lakh)",
            accessor: "total_exp_till_date",
          },
          {
            Header: "Financial Progress till date (%)",
            accessor: "financial_progress_till_date",
          },
          ]
        : []),
      {
        Header: "Expenditure incurred (in Lakh) in this Quarter",
        accessor: "exp_incurred",
      },
      ...(selectFormId !== "4" && selectFormId !== "5"
        ? [
          {
        Header: "Financial Progress by the end of this Quarter (%)",
        accessor: "financial_progress_by_the_end_of_qtr",
      },
    ]
  : []),
    
    ],
    []
  );

  const totalWidthForEqualColumns = 800; 

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData, 
    });

  const cellStyle = {
    padding: "8px",
    border: "1px solid #ccc", // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };

  const exportToExcel = () => {
    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
        // Get report name based on selectFormTraining
        const reportNames = {
          "4": "Nursery Development",
          "5": "Tissue Culture Unit",
          "26": "Support for commercialization of new and innovative technology",
        };
    
        const reportName = reportNames[selectFormId] || "";

    let formattedData;
  
    if (tableData.length === 0) {
      // Create a single row with only headers if there's no data
      formattedData = [{
        "Financial Year": selectFinnacialYear,
        "Period": selectSubFilter,
        ...(divisionName !== 'All' && { "Division": divisionName })
      }];
  
      columns.forEach(column => {
        formattedData[0][column.Header] = ""; // Empty value for the headers
      });
    } else {
      // Map table data and conditionally add the "Division" column
      formattedData = tableData.map(row => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          "Period": selectSubFilter,
          "Reports":reportName,
        };
  
        if (divisionName !== 'All') {
          formattedRow["Division"] = divisionName;
        }
  
        columns.forEach(column => {
          formattedRow[column.Header] = row[column.accessor];
        });
  
        return formattedRow;
      });
    }
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");
  
    const fileName = `${selectFinnacialYear}_${selectSubFilter}_${reportName}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  const stickyy = {
    position: "sticky",
    top: "0",
    zIndex: "1",
    background: "var(--stickyBackground)",
    boxShadow: "0 0 6px rgba(0,0,0,0.25)",
  };
  
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center the container
          marginBottom: "10px",
          // marginLeft: "5rem",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1200px" }}>
          {" "}
          {/* Center the content and set max width */}
          <div className="row">
            <div className="col-md-2">
              {" "}
              {/* Set equal width for all columns */}
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Reports
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFormId}
                    label="Reports"
                    onChange={handleSelectFormType}
                  >
                    <MenuItem value="4">Nursery Development</MenuItem>
                    <MenuItem value="5">Tissue Culture Unit</MenuItem>
                    <MenuItem value="26">
                      Support for commercialization of new and innovative
                      technology
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "160px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto", maxHeight: "445px" }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2", // Add grey background color
                      fontWeight: "bold", // Optionally make text bold
                      border: "2px solid #ccc",
                      ...cellStyle, // Add border style here
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
