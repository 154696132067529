
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  "5": {
    magnet_crop_id:[],
    location_taluka:'',
    area:'',
    capacity_total_plants:'',
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
    actual_date_1: '',
    actual_date_2: '',
    actual_date_3: '',
    actual_date_4: '',
    typedata: [],
  },
 
  validationErrors:{  
    magnet_crop_id:[],
    location_taluka:'',
    area:'',
    capacity_total_plants:'',
    total_project_cost:'',
    bank_term_loan:'',
    matching_grant:'',
        }
    };

const TissueMasterSlice = createSlice({
  name: 'TissueMasterSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state["5"][action.payload.fieldName] = action.payload.value;
    },
    setInputActivity: (state, action) => {
      state["5"].typedata = action.payload;
    },

    // Add other reducers for different input fields if needed
    updateApiDataTissueMaster: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState["5"]) {
        if (payload[key] !== undefined) {
          state["5"][key] = payload[key];
          if(payload[key] === null){
            state["5"][key] = '';
          }else{
            state["5"][key] = payload[key];
          }
        }
      }
    },
    resetDataTissueMaster: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsTissueMaster: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsEntityMaster, resetValidation, setInputActivity, setInputFieldData, updateApiDataTissueMaster, resetDataTissueMaster } = TissueMasterSlice.actions;

export default TissueMasterSlice.reducer;
