/* eslint-disable default-case */
import "../assets/css/inputForm.css";
import { InputField } from "../subcomponents/InputField";
import React, { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import Swal from "sweetalert2";

export const PhysicalInputForm = ({ quarter }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [value, setValue] = useState("2");
  const [selectQuarter, setSelectedQuarters] = useState("");
  const [quarters, setQuarter] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectCategory, setSelectedCategory] = useState("");
  const [selectSubCategory, setSelectedSubCategory] = useState("");
  const [categoryData, setCategoryData] = useState({});
  const [editFlag, setEditFlag] = useState(false);
  const [formId, setFormId] = useState("");
  const [loading, setLoading] = useState(false);
  //
  const [target, setTarget] = useState("");
  const [achievementTillDate, setAchievementTillDate] = useState("");

  const [targetRemain, setTargetRemain] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [expenditureTillDate, setExpenditureTillDate] = useState("");

  const [isCountParticipants, setIsCountParticipants] = useState(false);
  const [achievementQuarter, setAchievementQuarter] = useState("");
  const [expIncuQuarter, setExpIncurQuarter] = useState("");

  const [errorSelectBeniApplicant, setErrorSelectBeniApplicant] = useState("");
  const [errorSelectSubCategory, setErrorSelectSubCategory] = useState("");
  const [permission, setPermission] = useState([]);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckCategoryOption = () => {
    if (selectCategory === "") {
      setErrorSelectBeniApplicant("Please Select Category");
      return false;
    } else {
      return true;
    }
  };

  const handleCheckSubCategoryOption = () => {
    if (selectSubCategory === "") {
      setErrorSelectSubCategory("Please Select Sub Category");
      return false;
    } else {
      return true;
    }
  };

  const handleCategoryOption = async (event) => {
    const categoryId = event.target.value;

    setSelectedCategory(categoryId);
    setSelectedSubCategory("");
    setErrorSelectBeniApplicant("");
    setTarget("");
    setTotalCost("");
    setAchievementTillDate("");
    setTargetRemain("");
    setIsCountParticipants("");
    setExpenditureTillDate("");
    setCategoryData({});
    setAchievementQuarter("");
    setExpIncurQuarter("");
    setEditFlag("");

    try {
      const subcategory = await axios.get(
        `${apiUrl}api/get-subcategories-by-catid?category_id=${categoryId}`,
        getConfigWithToken()
      );
      const subCat = subcategory.data["data"].map((e) => ({
        id: e["id"],
        subCategory: e["sub_category"],
      }));

      setSubCategory(subCat);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleSubCategoryOption = async (event) => {
    setCategoryData({});
    const subCat = event.target.value;
    setSelectedSubCategory(subCat);
    setErrorSelectSubCategory("");
    setTargetRemain("");
    setTotalCost("");
    setAchievementQuarter("");
    setExpIncurQuarter("");
    setEditFlag("");
    setIsCountParticipants("");
    try {
      const checkEdit = await axios.get(
        `${apiUrl}api/physical-status-input-one-edit?category_id=${selectCategory}&sub_category_id=${event.target.value}&quarter=${selectQuarter}`,
        getConfigWithToken()
      );
      if (checkEdit.data["status"] === true) {
        checkEdit.data.flag ? setEditFlag(true) : setEditFlag(false);
        const data = checkEdit.data["data"][0];
        setFormId(data["id"]);
        setCategoryData(data);
        setTarget(data["target_units"]);
        // Check if catInfo exists in the data object
        if (data["catInfo"]) {
          setAchievementTillDate(
            data["catInfo"]["sum_achievement_till_date"] !== undefined
              ? data["catInfo"]["sum_achievement_till_date"]
              : 0
          );
        } else {
          setAchievementTillDate(
            data["sum_achievement_till_date"] !== undefined
              ? data["sum_achievement_till_date"]
              : 0
          );
        }
        setAchievementQuarter(data["achievement_till_date"]);
        setExpIncurQuarter(data["expenditure_till_date"]);
        setTargetRemain(data["target_remains"]);
        setTotalCost(data["total_cost"]);
        setExpenditureTillDate(data["sum_expenditure_till_date"]);
      } else {
        setEditFlag(false);
        const subcategory = await axios.get(
          `${apiUrl}api/get-unit-by-subcatid?sub_category_id=${event.target.value}&quarter=${selectQuarter}`,
          getConfigWithToken()
        );
        if (subcategory.data["status"] === true) {
          setCategoryData(subcategory.data["data"]);
          setIsCountParticipants(
            subcategory.data["data"].is_count_pariticipants === 1
          );
        } else {
          setCategoryData({});
          setIsCountParticipants(false); // Assuming default behavior when data is not available
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 const refershdata = () => {
    setSelectedCategory("");
    setSelectedSubCategory("");
    setCategoryData({});
    setTarget("");
    setAchievementTillDate("");
    setTargetRemain("");
    setTotalCost("");
    setAchievementQuarter("");
    setExpIncurQuarter("");
    setEditFlag("");
    setExpenditureTillDate("");
  };

  const handleQuarterChange = async (event) => {
    refershdata();
    setSelectedQuarters(event.target.value);
  };

  const handleAchievementQuarter = (value) => {
    setAchievementQuarter(value);
  };

  const handleExpIncurQuarter = (value) => {
    setExpIncurQuarter(value);
  };

  const handlePhysicalInputSubmit = async (e) => {
    e.preventDefault();

    console.log(handleCheckCategoryOption(), "dsoijodsjoejfjo");
    console.log(handleCheckSubCategoryOption(), "dsoijodsjoejfjo");
    // return
    if (handleCheckCategoryOption() && handleCheckSubCategoryOption()) {
      let formData;

      if (categoryData.catInfo) {
        formData = {
          quarter: selectQuarter,
          category_id: selectCategory,
          sub_category_id: selectSubCategory,
          unit: categoryData && categoryData.unit,
          target_units: categoryData && categoryData.target_units,
          total_cost: categoryData && categoryData.total_cost,
          expenditure_till_date: expIncuQuarter,
          achievement_till_date: achievementQuarter,
          sum_achievement_till_date:
            categoryData &&
            categoryData.catInfo &&
            categoryData.catInfo["sum_achievement_till_date"] === "INPUTTEXT"
              ? achievementTillDate
              : categoryData.catInfo["sum_achievement_till_date"],
          target_remains:
            categoryData &&
            categoryData.catInfo &&
            categoryData.catInfo["target_remains"] === "INPUTTEXT"
              ? targetRemain
              : categoryData.catInfo["target_remains"],

          sum_expenditure_till_date:
            categoryData &&
            categoryData.catInfo &&
            categoryData.catInfo["sum_expenditure_till_date"] === "INPUTTEXT"
              ? expenditureTillDate
              : categoryData.catInfo["sum_expenditure_till_date"],
        };
      } else {
        formData = {
          quarter: selectQuarter,
          category_id: selectCategory,
          sub_category_id: selectSubCategory,
          unit: categoryData && categoryData.unit,
          no_of_farmers: categoryData["no_of_farmers"],
          target_units: target,
          sum_achievement_till_date: achievementTillDate,
          target_remains: targetRemain,
          total_cost: totalCost,
          sum_expenditure_till_date: expenditureTillDate,
          expenditure_till_date: expIncuQuarter,
          achievement_till_date: achievementQuarter,
        };
      }

      try {
        if (!editFlag) {
          const submitNurseryDevData = await axios.post(
            `${apiUrl}api/physical-status-input-one-create`,
            formData,
            getConfigWithToken()
          );
          if (submitNurseryDevData.data["status"] === true) {
            Swal.fire(
              `${submitNurseryDevData.data["message"]}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${submitNurseryDevData.data["message"]}`,
              "",
              "warning"
            ).then(() => {
              setLoading(false);
            });
          }
        } else {
          // also have to set Id in object
          formData["id"] = formId;
          const submitNurseryData = await axios.post(
            `${apiUrl}api/physical-status-input-one-update`,
            formData,
            getConfigWithToken()
          );

          if (submitNurseryData.data["status"] === true) {
            Swal.fire(
              `${submitNurseryData.data["message"]}`,
              "",
              "success"
            ).then(() => {
              refershdata();
              setLoading(false);
            });
          } else {
            Swal.fire(
              `${submitNurseryData.data["message"]}`,
              "",
              "warning"
            ).then(() => {
              setLoading(false);
            });
          }
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/physical-status-input-one-delete?id=${formId}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            setLoading(false);
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
          setLoading(false);
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  useEffect(() => {
    const quarterData = async () => {
      try {
        const storedArrayAsString = localStorage.getItem("permission");
        setPermission(storedArrayAsString);

        const category = await axios.get(
          `${apiUrl}api/category-input-one-listing?all=1`,
          getConfigWithToken()
        );

        const cate = category.data["data"].map((e) => ({
          id: e["id"],
          category: e["category"],
        }));

        setQuarter(quarter);
        setCategory(cate);
        const selectedValues = quarter
          .filter((item) => item.selected === "Selected")
          .map((item) => item.id);
        setSelectedQuarters(selectedValues[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    quarterData();
  }, [quarter]);


  return (
    <>
     
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div id="exTab3" class="contain">
              <div class="card">
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    bgcolor: "background.paper",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        value={value}
                        onChange={handleChange}
                        sx={{ width: "100%", overflowX: "auto" }}
                      >
                      
                        <Tab
                          label="Physical Input"
                          value="2"
                          className={`${value === "2" ? "activeClickCSS" : ""}`}
                        />
                      </Tabs>
                    </Box>

                    {/* Physical Input updated */}
                    <TabPanel value="2">
                      <div className="tab-pane">
                        <form
                          className="row g-3"
                          onSubmit={handlePhysicalInputSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Category Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  Category
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectCategory}
                                  label="Category"
                                  onChange={handleCategoryOption}
                                >
                                  {category.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["category"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectSubCategory !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  Sub Category
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectSubCategory}
                                  label="Sub Category"
                                  onChange={handleSubCategoryOption}
                                >
                                  {subCategory.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["subCategory"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectSubCategory}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Units"
                            type="text"
                            readOnly={true}
                            value={
                              categoryData && categoryData.unit
                                ? categoryData.unit
                                : ""
                            }
                          />
                          <InputField
                            label="Target (in units)"
                            type="text"
                            readOnly={true}
                            value={
                              categoryData && categoryData.target_units
                                ? categoryData.target_units
                                : ""
                            }
                          />
                          <InputField
                            label="Achievement Till date (in units)"
                            type="text"
                            readOnly={true}
                            value={
                              categoryData &&
                              categoryData.catInfo &&
                              categoryData.catInfo.sum_achievement_till_date !==
                                undefined
                                ? categoryData.catInfo.sum_achievement_till_date
                                : categoryData &&
                                  categoryData.sum_achievement_till_date !==
                                    undefined
                                ? categoryData.sum_achievement_till_date
                                : ""
                            }
                          />

                          <InputField
                            label="Target Remains (in units)"
                            type="text"
                            readOnly={true}
                            value={
                              categoryData &&
                              categoryData.catInfo &&
                              categoryData.catInfo.target_remains !== undefined
                                ? categoryData.catInfo.target_remains
                                : categoryData &&
                                  categoryData.target_remains !== undefined
                                ? categoryData.target_remains
                                : ""
                            }
                          />
                          <InputField
                            label="Total Cost (in Lakhs)"
                            type="text"
                            readOnly={true}
                            value={
                              categoryData && categoryData.total_cost
                                ? categoryData.total_cost
                                : ""
                            }
                          />
                          <InputField
                            label="Expenditure till date (in Lakhs INR)"
                            value={
                              categoryData &&
                              categoryData.catInfo &&
                              categoryData.catInfo.sum_expenditure_till_date !==
                                undefined
                                ? categoryData.catInfo.sum_expenditure_till_date
                                : categoryData &&
                                  categoryData.sum_expenditure_till_date !==
                                    undefined
                                ? categoryData.sum_expenditure_till_date
                                : ""
                            }
                          />
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>Details of current Quarter -</span>
                          </div>

                          <div class="row g-3" style={{ marginTop: "1px" }}>
                            <InputField
                              label="Achievement (in units)"
                              type="number"
                              value={achievementQuarter}
                              onChange={handleAchievementQuarter}
                            />
                            {/* Hide this field if it is N/A */}
                            {isCountParticipants && (
                              <InputField
                                label="Total Nos. of Farmers / Participants"
                                type="number"
                                value={expIncuQuarter}
                                onChange={handleExpIncurQuarter}
                              />
                            )}

                            <InputField
                              label="Expenditure(in Lakhs INR)"
                              type="number"
                              value={expIncuQuarter}
                              onChange={handleExpIncurQuarter}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Physical-Input-Input-One-view"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {editFlag === true ? "Update" : "Submit"}
                                </button>
                                {editFlag === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    disabled={loading}
                                    onClick={handleDelete}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

                           
                          </div>
                        </form>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
     
    </>
  );
};
