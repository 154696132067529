import '../assets/css/LoginPage.css';
import logo from '../assets/images/logo.png';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

export const ResetPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { email } = location.state || {}; // Provide a fallback

  if (!email) {
    // If email is not available, redirect to the SecurityQuestionsPage
    Swal.fire("Invalid access. Redirecting to security questions.", "", "warning").then(() => {
      navigate("/securityQuestionsPage"); // Adjust the path as necessary
    });
    return null;
  }

  const handleSubmit = async () => {
    if (!password || !confirmPassword) {
      setErrorMessage('Both fields are required.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Password and confirmPassword does not match.');
      return;
    }

    try {
      const obj = {
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      };

      console.log("Sending payload to API:", obj);

      const response = await axios.post(
        `${apiUrl}api/change_password`,
        obj
      );

      console.log("API response:", response);

      if (response.data["status"] === true) {
        Swal.fire({
          title: "Password Set Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        const errorMessage = response.data.errors?.password?.[0] || response.data.message;
        Swal.fire(errorMessage, "", "warning");
         }
        } catch (error) {
            console.error("Error setting password:", error);
            // Extract and show error message from the response
            const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
            Swal.fire(errorMessage, "", "error");
          }
        };

  return (
    <div className="container">
      <div style={{ position: "relative", top: "1.7rem" }}>
        <div className="form-group">
          <img src={logo} alt="Logo" height="100px" />
          <h3>Reset Password?</h3>
        </div>
        <div>
          <div className="email">
            <TextField
              id="password"
              label="New Password"
              variant="outlined"
              fullWidth
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage('');
              }}
            />
          </div>
          <div className="email">
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              fullWidth
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrorMessage('');
              }}
            />
          </div>
          {errorMessage && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ margin: "8px 0" }}>
              {errorMessage}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
          <button className="login-button-reset" onClick={handleSubmit}>Reset Password</button>
        </div>
      </div>
    </div>
  );
}
