import { InputField } from "../../InputField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldFullPartOperData,
  setTotalPartMaleEmp,
  setTotalPartFemaleEmp,
  setTotalPartTotalEmp,
  setTotalPartSC,
  setTotalPartST,
  setTotalPartPWD,
  setTotalPartBPL,
  resetValidationOper,
  setTotalPartOBC,
  setTotalGenMaleEmp,
  setTotalGenFemaleEmp,
  setTotalGenTotalEmp,
} from "../../../redux/slice/InputThree/EmpGenOperInpuThree/FullPartTimeOperSlice";
import "../../../assets/css/inputForm.css";

export function PartTimeOper(props) {
  const dispatch = useDispatch();
  const fullPartTimeOperForm = useSelector((state) => state.FullPartTimeOper);

  useEffect(() => {
    // Total of SC
    const totalSC =
      parseInt(fullPartTimeOperForm.part_time_emp_num_sc_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_sc_female || 0);
    dispatch(setTotalPartSC(totalSC));

    // Total of ST
    const totalST =
      parseInt(fullPartTimeOperForm.part_time_emp_num_st_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_st_female || 0);
    dispatch(setTotalPartST(totalST));

    // Total of PWD
    const totalPWD =
      parseInt(fullPartTimeOperForm.part_time_emp_num_pwd_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_pwd_female || 0);
    dispatch(setTotalPartPWD(totalPWD));

    // Total of OBC/Minority/Open
    const totalOBCMinorityOpen =
      parseInt(fullPartTimeOperForm.part_time_emp_num_obc_minority_open_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_obc_minority_open_female || 0);
    dispatch(setTotalPartOBC(totalOBCMinorityOpen));

    // Total of NumFullTimeEmployment
    const totalFullTimeEmpMale =
      parseInt(fullPartTimeOperForm.part_time_emp_num_sc_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_st_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_obc_minority_open_male || 0);
    dispatch(setTotalPartMaleEmp(totalFullTimeEmpMale));

    const totalFullTimeEmpFemale =
      parseInt(fullPartTimeOperForm.part_time_emp_num_sc_female || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_st_female || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_obc_minority_open_female || 0);
    dispatch(setTotalPartFemaleEmp(totalFullTimeEmpFemale));

    const totalFullTimeEmpTotal =
      parseInt(fullPartTimeOperForm.part_time_emp_num_sc_total || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_st_total || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_obc_minority_open_total || 0);
    dispatch(setTotalPartTotalEmp(totalFullTimeEmpTotal));

    const totalGenEmpMale =
      parseInt(fullPartTimeOperForm.full_time_emp_num_male || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_male || 0);
    dispatch(setTotalGenMaleEmp(totalGenEmpMale));

    const totalGenEmpFemale =
      parseInt(fullPartTimeOperForm.full_time_emp_num_female || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_female || 0);
    dispatch(setTotalGenFemaleEmp(totalGenEmpFemale));

    const totalGenEmpTotal =
      parseInt(fullPartTimeOperForm.full_time_emp_num_total || 0) +
      parseInt(fullPartTimeOperForm.part_time_emp_num_total || 0);
    dispatch(setTotalGenTotalEmp(totalGenEmpTotal));
  }, [dispatch, fullPartTimeOperForm]);

  const handleResetFocus = () => {
    dispatch(resetValidationOper());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldFullPartOperData({ fieldName, value }));
  };

  return (
    <>
      <div className="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_sc_male}
          onChange={(value) => handleInputChange("part_time_emp_num_sc_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_sc_male}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_sc_female}
          onChange={(value) => handleInputChange("part_time_emp_num_sc_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_sc_female}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_st_male}
          onChange={(value) => handleInputChange("part_time_emp_num_st_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_st_male}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_st_female}
          onChange={(value) => handleInputChange("part_time_emp_num_st_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_st_female}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_st_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_obc_minority_open_male}
          onChange={(value) => handleInputChange("part_time_emp_num_obc_minority_open_male", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_obc_minority_open_male}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_obc_minority_open_female}
          onChange={(value) => handleInputChange("part_time_emp_num_obc_minority_open_female", value)}
          onFocus={handleResetFocus}
          error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_obc_minority_open_female}
          helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_obc_minority_open_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_obc_minority_open_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_male}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_female}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Part Time"
          type="number"
          value={fullPartTimeOperForm.part_time_emp_num_total}
          readOnly={true}
        />

        <div style={{ marginTop: "3rem", marginLeft: "1px" }} className="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={fullPartTimeOperForm.part_time_emp_num_pwd_male}
            onChange={(value) => handleInputChange("part_time_emp_num_pwd_male", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_pwd_male}
            helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={fullPartTimeOperForm.part_time_emp_num_pwd_female}
            onChange={(value) => handleInputChange("part_time_emp_num_pwd_female", value)}
            onFocus={handleResetFocus}
            error={!!fullPartTimeOperForm.validationErrors.part_time_emp_num_pwd_female}
            helperText={fullPartTimeOperForm.validationErrors.part_time_emp_num_pwd_female}
          />
          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={fullPartTimeOperForm.part_time_emp_num_pwd_total}
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
}
