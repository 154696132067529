import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Button,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";

export const OutputTableCapacityBuildingGAP = ({
  setDivision,
  currentQuarter,
  financialYear,
  currentFinancialYear,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectSubFilter, setSelectSubFilter] = useState("Q3");
  const [tableData, setTableData] = useState([]);
  const [selectFormTraining, setSelectFormTraining] = useState("3,5");
  const [selectFormId, setSelectFormId] = useState("3");
  const [selectTraining, setSelectTrainingId] = useState("5");

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  function filterTransformData(data) {
    const transformedData = data.map((entry) => {
      if (entry.magnet_crop_id) {
        let magnetCrops;
        try {
          // Try parsing as JSON
          const magnetCropIds = JSON.parse(entry.magnet_crop_id);
          magnetCrops = magnetCropIds.map((crop) => `${crop.name}, `);
        } catch (error) {
          // If parsing fails, treat it as a plain string and split
          magnetCrops = entry.magnet_crop_id
            .split(",")
            .map((crop) => `${crop.trim()}, `);
        }
        return { ...entry, magnet_crop_id: magnetCrops };
      } else {
        // If magnet_crop_id field is missing, return the entry as it is
        return entry;
      }
    });

    return transformedData;
  }

  const handleSelectFormTrainingType = async (event) => {
    setSelectFormTraining(event.target.value);
    const ss = event.target.value.split(",");
    setSelectFormId(ss[0]);
    setSelectTrainingId(ss[1]);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${ss[0]}&training_type=${ss[1]}&division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${ss[0]}&training_type=${ss[1]}&division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    }
  };

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    }
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
    }
  };

  const handleSubFilter = async (e) => {
    if (selectFinnacialYear.length === 0) {
      Swal.fire(`Please Select Financial Year`, "", "warning");
      return;
    }
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(filterData.data.data[0]));
    } else {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${selectFormId}&training_type=${selectTraining}&division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );

      setTableData(filterTransformData(filterData.data.data[0]));
    }
  };

  useEffect(() => {
    try {
      async function getData() {
        const empFPOComponentResponse = await axiosInstance.get(
          `${apiUrl}api/capacityBuildingInputOnesOutpts?gap=1&form_id=${3}&training_type=${5}&quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`,
          getConfigWithToken()
        );
        setTableData(filterTransformData(empFPOComponentResponse.data.data[0]));
        setSelectFinancialYear(currentFinancialYear);
        setDivisions(setDivision);
        setSelectSubFilter(currentQuarter);
        setLoading(false);
      }

      setTimeout(() => {
        getData();
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire("An refresh the Page", "", "error");
      } else {
        throw new Error("Data Not Found");
      }
    }
  }, [setDivision, financialYear, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          ...(selectFormTraining !== "3,5"
            ? [
                {
                  Header: "Division",
                  accessor: "divisions",
                },
              ]
            : []),

          {
            Header:
              selectFormTraining !== "2,"
                ? "Beneficiary Name"
                : "Organization Name",
            accessor: "beneficiary_name",
          },
          ...(selectFormTraining !== "1,1" && selectFormTraining !== "1,2"
            ? [
                {
                  Header: "MAGNET Crops",
                  accessor: "magnet_crop_id",
                },
              ]
            : []),

          {
            Header: "Location(Taluka)",
            accessor: "location_taluka",
          },
          ...(selectFormTraining !== "2,"
            ? [
                {
                  Header: "Resource Person/Agency",
                  accessor: "resource_person",
                },
              ]
            : []),

          ...(selectFormTraining !== "3,5" &&
          selectFormTraining !== "23," &&
          selectFormTraining !== "7," &&
          selectFormTraining !== "22,"
            ? [
                {
                  Header: "Beneficiaries attended",
                  accessor: "beneficiary_attended",
                },
              ]
            : []),

          ...(selectFormTraining !== "23," &&
          selectFormTraining !== "1,1" &&
          selectFormTraining !== "1,2" &&
          selectFormTraining !== "2,"
            ? [
                {
                  Header: "Topic Covered",
                  accessor: "topic_covered",
                },
              ]
            : []),

          {
            Header: "From Date",
            accessor: "from_date",
          },
          {
            Header: "To Date",
            accessor: "to_date",
          },
        ],
      },
      {
        Header: "Vulnerable groups”& “vulnerability”",
        columns: [
          {
            Header: "Nos. of Trainees",
            columns: [
              {
                Header: "Nos. of Trainees (Male)",
                accessor: "beneficiaries_male",
              },
              {
                Header: "Nos. of Trainees (Female)",
                accessor: "beneficiaries_female",
              },
              {
                Header: "Nos. of Trainees (Total)",
                accessor: "beneficiaries_total",
              },
            ],
          },
          ...(selectFormTraining !== "23," && selectFormTraining !== "2,"
            ? [
                {
                  Header: "SC",
                  columns: [
                    { Header: "SC (Male)", accessor: "sc_male" },
                    { Header: "SC (Female)", accessor: "sc_female" },
                    { Header: "SC (Total)", accessor: "sc_total" },
                  ],
                },

                {
                  Header: "ST",
                  columns: [
                    {
                      Header: "ST (Male)",
                      accessor: "st_male",
                    },
                    {
                      Header: "ST (Female)",
                      accessor: "st_female",
                    },
                    {
                      Header: "ST (Total)",
                      accessor: "st_total",
                    },
                  ],
                },
                {
                  Header: "OBC/ Minority/ Open",
                  columns: [
                    {
                      Header: "OBC/Minority/Open (Male)",
                      accessor: "obc_minority_open_male",
                    },
                    {
                      Header: "OBC/Minority/Open (Female)",
                      accessor: "obc_minority_open_female",
                    },
                    {
                      Header: "OBC/Minority/Open (Total)",
                      accessor: "obc_minority_open_total",
                    },
                  ],
                },

                {
                  Header: "PWD",
                  columns: [
                    {
                      Header: "PWD (Male)",
                      accessor: "pwd_male",
                    },
                    {
                      Header: "PWD (Female)",
                      accessor: "pwd_female",
                    },
                    {
                      Header: "PWD (Total)",
                      accessor: "pwd_total",
                    },
                  ],
                },
              ]
            : []),
        ],
      },
      ...(selectFormTraining !== "23," &&
      selectFormTraining !== "2," &&
      selectFormTraining !== "7,"
        ? [
            {
              Header: "Action points/ Recommendation made",
              accessor: "action_points",
            },
          ]
        : []),
    ],
    [selectFormTraining]
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

  const cellStyle = {
    padding: "8px",
    // border: '1px solid #ccc', // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };

  const exportToExcel = () => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const selectedDivision = divisions.find(
      (division) => division.id === selectDivision
    );
    const divisionName = selectedDivision
      ? selectedDivision.division_name
      : "All";

    // Get report name based on selectFormTraining
    const reportNames = {
      "3,5": "Training on Good Agricultural Practices",
      "23,": "Training of Trainers (ToT) - 5 Days",
      "2,": "Training on Post Harvest Management (PHM) - 3 Days",
      "7,": "Lead firm SME - Capacity Building Workshop",
      "1,1": "Training of BoD of FPOs",
      "1,2": "Horticulture Export Training Course",
      "22,": "Capacity Building and Training workshop on Project Compliances",
    };

    const reportName = reportNames[selectFormTraining] || "";

    // Define headers based on conditions
    const baseHeaders = [
      "Financial Year",
      "Period",
      "Reports",
      ...(selectFormTraining !== "3,5" ? ["Division"] : []),
      selectFormTraining !== "2," ? "Beneficiary Name" : "Organization Name",
      "MAGNET Crops",
      "Location(Taluka)",
      "Resource Person/Agency",
      "Topic Covered",
      "From Date",
      "To Date",
    ];

    const vulnerableGroupHeaders = [
      "Nos. of Trainees (Male)",
      "Nos. of Trainees (Female)",
      "Nos. of Trainees (Total)",
      ...(selectFormTraining !== "23," && selectFormTraining !== "2,"
        ? [
            "SC (Male)",
            "SC (Female)",
            "SC (Total)",
            "ST (Male)",
            "ST (Female)",
            "ST (Total)",
            "OBC/Minority/Open (Male)",
            "OBC/Minority/Open (Female)",
            "OBC/Minority/Open (Total)",
            "PWD (Male)",
            "PWD (Female)",
            "PWD (Total)",
          ]
        : []),
    ];

    // Merge structure based on headers
    const merge = [
      {
        s: { r: 0, c: baseHeaders.length },
        e: { r: 0, c: baseHeaders.length + vulnerableGroupHeaders.length - 1 },
      }, // Vulnerable groups”& “vulnerability
      {
        s: { r: 1, c: baseHeaders.length },
        e: { r: 1, c: baseHeaders.length + 2 },
      }, // Nos. of Trainees
      ...(selectFormTraining !== "23," && selectFormTraining !== "2,"
        ? [
            {
              s: { r: 1, c: baseHeaders.length + 3 },
              e: { r: 1, c: baseHeaders.length + 5 },
            }, // SC
            {
              s: { r: 1, c: baseHeaders.length + 6 },
              e: { r: 1, c: baseHeaders.length + 8 },
            }, // ST
            {
              s: { r: 1, c: baseHeaders.length + 9 },
              e: { r: 1, c: baseHeaders.length + 11 },
            }, // OBC
            {
              s: { r: 1, c: baseHeaders.length + 12 },
              e: { r: 1, c: baseHeaders.length + 14 },
            }, // PWD
          ]
        : []),
    ];

    // Header rows with merged cells
    const headers = [
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ...Array(vulnerableGroupHeaders.length).fill(
          "Vulnerable groups”& “vulnerability”"
        ),
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Nos. of Trainees",
        "Nos. of Trainees",
        "Nos. of Trainees",
        ...(selectFormTraining !== "23," && selectFormTraining !== "2,"
          ? [
              "SC",
              "SC",
              "SC",
              "ST",
              "ST",
              "ST",
              "OBC/Minority/Open",
              "OBC/Minority/Open",
              "OBC/Minority/Open",
              "PWD",
              "PWD",
              "PWD",
            ]
          : []),
      ],
      [...baseHeaders, ...vulnerableGroupHeaders],
    ];

    // Combine the header rows with the table data
    const data = [
      ...headers,
      ...tableData.map((row) => [
        selectFinnacialYear,
        selectSubFilter,
        reportName,
        ...(selectFormTraining !== "3,5" ? [row.divisions] : []),
        row.beneficiary_name,
        Array.isArray(row.magnet_crop_id)
          ? row.magnet_crop_id.join("")
          : row.magnet_crop_id,
        row.location_taluka,
        row.resource_person,
        row.topic_covered,
        row.from_date,
        row.to_date,
        row.beneficiaries_male,
        row.beneficiaries_female,
        row.beneficiaries_total,
        ...(selectFormTraining !== "23," && selectFormTraining !== "2,"
          ? [
              row.sc_male,
              row.sc_female,
              row.sc_total,
              row.st_male,
              row.st_female,
              row.st_total,
              row.obc_minority_open_male,
              row.obc_minority_open_female,
              row.obc_minority_open_total,
              row.pwd_male,
              row.pwd_female,
              row.pwd_total,
            ]
          : []),
      ]),
    ];

    // Convert the combined data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Apply merges
    ws["!merges"] = merge;

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "GAP");

    // Generate a buffer for the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_${reportName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  const stickyy = {
    position: "sticky",
    top: "0",
    zIndex: "1",
    background: "var(--stickyBackground)",
    boxShadow: "0 0 6px rgba(0,0,0,0.25)",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center the container
          marginBottom: "10px",
          // marginLeft: "5rem",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1200px" }}>
          {" "}
          {/* Center the content and set max width */}
          <div className="row">
            <div className="col-md-2">
              {" "}
              {/* Set equal width for all columns */}
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Reports
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFormTraining}
                    label="Reports"
                    onChange={handleSelectFormTrainingType}
                  >
                    <MenuItem value="3,5">
                      Training on Good Agricultural Practices
                    </MenuItem>
                    <MenuItem value="23,">
                      Training of Trainers (ToT) - 5 Days
                    </MenuItem>
                    <MenuItem value="2,">
                      Training on Post Harvest Management (PHM) - 3 Days
                    </MenuItem>
                    <MenuItem value="7,">
                      Lead firm SME - Capacity Building Workshop
                    </MenuItem>
                    <MenuItem value="1,1">Training of BoD of FPOs</MenuItem>
                    <MenuItem value="1,2">
                      Horticulture Export Training Course
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "160px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: "100%", overflowX: "auto", maxHeight: "445px" }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2", // Add grey background color
                      fontWeight: "bold", // Optionally make text bold
                      border:
                        column.Header === "PWD" ||
                        (column.parent &&
                          column.parent.Header === "PWD" &&
                          [
                            "PWD (Male)",
                            "PWD (Female)",
                            "PWD (Total)",
                          ].includes(column.Header))
                          ? "4px solid #ccc"
                          : "2px solid #ccc", // Thicker border for "PWD" and its children "Male", "Female", and "Total", normal border for others
                      ...cellStyle,
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
