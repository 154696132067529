import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////

  sc_male: '',
  sc_female: '',
  sc_total: '',

  st_male: '',
  st_female: '',
  st_total: '',

  pwd_male: '',
  pwd_female: '',
  pwd_total: '',

  obc_minority_open_male: '',
  obc_minority_open_female: '',
  obc_minority_open_total: '',

  beneficiaries_male: '',
  beneficiaries_female: '',
  beneficiaries_total: '',


  validationErrors:{
    sc_male: '',
    sc_female: '',

    st_male: '',
    st_female: '',

    pwd_male: '',
    pwd_female: '',

    obc_minority_open_male: '',
    obc_minority_open_female: '',

    beneficiaries_male: '',
    beneficiaries_female: '',
  }
};

const highDensitySlice = createSlice({
  name: 'highDensitySlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    setTotalSC: (state, action) => {
        state.sc_total = action.payload;
      },
    setTotalST: (state, action) => {
        state.st_total = action.payload;
      },
    setTotalPWD: (state, action) => {
        state.pwd_total = action.payload;
      },
 
    setTotalOBC: (state, action) => {
        state.obc_minority_open_total= action.payload;
      },
    
    setTotalParticipants: (state, action) => {
        state.beneficiaries_total= action.payload;
      },
    // Add other reducers for different input fields if needed
    updateHighDensityForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataHighDensity: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsHighDensity: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsHighDensity, resetValidation, setInputFieldData, updateHighDensityForm, resetDataHighDensity, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants } = highDensitySlice.actions;

export default highDensitySlice.reducer;
