
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';

function PrivateRoute({ children }) {
  console.log(isAuthenticated(),"isAuthenticated()")
  return isAuthenticated() ? (
     children
  ) : (
    <Navigate to="/" replace />
  );

};

export default PrivateRoute;
