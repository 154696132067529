import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Button,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../utils/Config/Config";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";

export const OutputTableThreeUtilizationTPD = ({
  setDivision,
  financialYear,
  currentFinancialYear,
  currentQuarter,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectSubFilter, setSelectSubFilter] = useState("Q3");
  const [tableData, setTableData] = useState([]);

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(divisionData.data.data[0]);
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(divisionData.data.data[0]);
    }
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(divisionData.data.data[0]);
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(divisionData.data.data[0]);
    }
  };

  const handleSubFilter = async (e) => {
    if (selectFinnacialYear.length === 0) {
      Swal.fire(`Please Select Financial Year`, "", "warning");
      return;
    }
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterData.data.data[0]);
    } else {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterData.data.data[0]);
    }
  };

  useEffect(() => {
    try {
      async function getData() {
        const empFPOComponentResponse = await axiosInstance.get(
          `${apiUrl}api/if-utilisation-facility-tpd-msamb-output?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`,
          getConfigWithToken()
        );
        setTableData(empFPOComponentResponse.data.data[0]);
        setDivisions(setDivision);
        setSelectFinancialYear(currentFinancialYear);
        setSelectSubFilter(currentQuarter);
        setLoading(false);
      }

      setTimeout(() => {
        getData();
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire("An refresh the Page", "", "error");
      } else {
        throw new Error("Data Not Found");
      }
    }
  }, [setDivision, financialYear, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: "Facility Name",
        accessor: "msamb_facility_name",
      },
      {
        Header:"Division",
        accessor:"divisions",
      },
      {
        Header: "Target Crops",
        accessor: "magnet_crop_id",
      },
      {
        Header: "Crops",
        accessor: "existing_crops",
      },
   
      {
        Header: "Is Facility in Operation",
        accessor: "is_facility_in_opration",
      },
      {
        Header: "MSAMB Type",
        accessor: "use_by_msamb_type",
      },
      {
        Header: "Total quantity handled TPD",
        accessor: "total_quantity_handled_tpd",
      },
      {
        Header: "Total Capacity TPD",
        accessor: "total_capacity_tpd",
      },
      {
        Header: "Total Capacity Utilised TPD",
        accessor: "total_capacity_utilised_tpd",
      },
      {
        Header: "Capacity Utilised Job Work",
        accessor: "capacity_utilised_job_work",
      },
      {
        Header: "Capacity Utilised Captive Used",
        accessor: "capacity_utilised_captive_used",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
      },
      {
        Header: "Service Rental Charges",
        accessor: "service_rental_charges",
      },
      {
        Header: "Processing Charges",
        accessor: "processing_charges",
      },
      {
        Header: "Members of FPO",
        columns: [
          {
            Header: "Small",
            accessor: "members_of_fpo_small",
          },
          {
            Header: "Medium",
            accessor: "members_of_fpo_medium",
          },
          {
            Header: "Others",
            accessor: "members_of_fpo_others",
          },
        ],
      },
      {
        Header: "Non Members/ Traders or other Corporates",
        columns: [
          {
            Header: "Small",
            accessor: "non_members_small",
          },
          {
            Header: "Medium",
            accessor: "non_members_medium",
          },
          {
            Header: "Others",
            accessor: "non_members_others",
          },
        ],
      },
      {
        Header: "Number of Farmers",
        accessor: "number_of_farmers",
      },
     
    ],
    []
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; 
  
  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData, 
    });

  const cellStyle = {
    padding: "8px",
    border: "1px solid #ccc", // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };
  const handleExport = () => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const selectedDivision = divisions.find(
      (division) => division.id === selectDivision
    );
    const divisionName = selectedDivision
      ? selectedDivision.division_name
      : "All";

    // Header structure with merged cells
    const headers = [
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Members of FPO",
        "Members of FPO",
        "Members of FPO",
        "Non Members/ Traders or other Corporates",
        "Non Members/ Traders or other Corporates",
        "Non Members/ Traders or other Corporates",
        "",
      ],
      [
        "Financial Year",
        "Period",
        "FPO/VCO Name",
        "Division",
        "Target Crops",
        "Crops",
        "Is Facility in Operation",
        "MSAMB Type",
        "Total quantity handled TPD",
        "Total Capacity TPD",
        "Total Capacity Utilised TPD",
        "Capacity Utilised Job Work",
        "Capacity Utilised Captive Used",
        "Total Amount",
        "Service Rental Charges",
        "Processing Charges",
        "small",
        "medium",
        "others",
        "small",
        "medium",
        "others",
        "Number of Farmers",
      ],
      
    ];

    const merge = [
      { s: { r: 0, c: 16 }, e: { r: 0, c: 18 } }, // Members of FPO
      { s: { r: 0, c: 19 }, e: { r: 0, c: 21 } }, // Non Members/ Traders or other Corporates
    ];

    // Combine the header rows with the table data
    const data = [
      ...headers,
      ...tableData.map((row) => [
        selectFinnacialYear,
        selectSubFilter,
        row.msamb_facility_name,
        row.divisions,
        row.magnet_crop_id,
        row.existing_crops,
        row.is_facility_in_opration,
        row.use_by_msamb_type,
        row.total_quantity_handled_tpd,
        row.total_capacity_tpd,
        row.total_capacity_utilised_tpd,
        row.capacity_utilised_job_work,
        row.capacity_utilised_captive_used,
        row.total_amount,
        row.service_rental_charges,
        row.processing_charges,
        row.members_of_fpo_small,
        row.members_of_fpo_medium,
        row.members_of_fpo_others,
        row.non_members_small,
        row.non_members_medium,
        row.non_members_others,
        row.number_of_farmers,
      ]),
    ];

    // Convert the combined data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Center-align the header row and merge cells
    const centerAlign = {
      alignment: { horizontal: "center", vertical: "center" },
    };

    // Apply alignment to the first header row
    headers[0].forEach((header, index) => {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
      if (cell) {
        cell.s = { ...cell.s, ...centerAlign };
      } else {
        ws[XLSX.utils.encode_cell({ r: 0, c: index })] = {
          v: header,
          t: "s",
          s: centerAlign,
        };
      }
    });

    // Apply alignment to the second header row
    headers[1].forEach((header, index) => {
      if (header) {
        const cell = ws[XLSX.utils.encode_cell({ r: 1, c: index })];
        if (cell) {
          cell.s = { ...cell.s, ...centerAlign };
        } else {
          ws[XLSX.utils.encode_cell({ r: 1, c: index })] = {
            v: header,
            t: "s",
            s: centerAlign,
          };
        }
      }
    });

    // Apply merges
    ws["!merges"] = merge;

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Utilization TPD");

    // Generate a buffer for the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_Impact3_UtilizationTPD.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }
  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-5"></div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={handleExport}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "155px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto", maxHeight: '445px' }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2", // Add grey background color
                      fontWeight: "bold", // Optionally make text bold
                      border: "2px solid #ccc",
                      ...cellStyle, // Add border style here
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
