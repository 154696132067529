import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import "../../assets/css/OutputTable.css";
import axios from "axios";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { getConfigWithToken } from "../../utils/Config/Config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AxiosRetry from "axios-retry";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";

export const OutputTableEmpDivisionPerformance = ({
  setDivision,
  financialYear,
  currentFinancialYear,
  currentQuarter,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([]);
  const [selectDivision, setSelectDivision] = useState([]);
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024");
  const [selectSubFilter, setSelectSubFilter] = useState("Q3");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [grantSummary, setGrantSummary] = useState([]);

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  const filterTransformData = (data) => {
    const convertedData = data.map((item) => ({
      ...item,
      divisions: item.get_division.divisions,
    }));
    return convertedData;
  };

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    }
  };

  const handleSelectFinancialYear = async (e) => {
    setSelectFinancialYear(e.target.value);
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(selectSubFilter)) {
      const divisionData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    } else {
      const divisionData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(divisionData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    }
  };

  const handleSubFilter = async (e) => {
    if (selectFinnacialYear.length === 0) {
      Swal.fire(`Please Select Financial Year`, "", "warning");
      return;
    }
    const quarr = ["Q1", "Q2", "Q3", "Q4"];
    if (quarr.includes(e.target.value)) {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(filterData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    } else {
      setSelectSubFilter(e.target.value);
      const filterData = await axios.get(
        `${apiUrl}api/divisionWiseEmpGenConsInpTwo?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      setTableData(filterTransformData(filterData.data.data[0]));
      const summary = await axios.get(
        `${apiUrl}api/empGenConsInpTwoSummary?division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,
        getConfigWithToken()
      );
      const summaryResponse = [
        "Grand Total",
        ...Object.values(summary.data["data"][0][0]),
      ];
      setGrantSummary(summaryResponse);
    }
  };

  useEffect(() => {
    try {
      async function getData() {
        const empdivisonComponentResponse = await axiosInstance.get(
          `${apiUrl}api/divisionWiseEmpGenConsInpTwo?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`,
          getConfigWithToken()
        );
        const summary = await axiosInstance.get(
          `${apiUrl}api/empGenConsInpTwoSummary?quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`,
          getConfigWithToken()
        );

        const eDC = empdivisonComponentResponse.data.data[0];
        const convertedData = eDC.map((item) => ({
          ...item,
          divisions: item.get_division.divisions,
        }));

        const summaryResponse = [
          "Grand Total",
          ...Object.values(summary.data["data"][0][0]),
        ];
        setGrantSummary(summaryResponse);
        setSelectFinancialYear(currentFinancialYear);
        setSelectSubFilter(currentQuarter);
        setTableData(convertedData);
        setDivisions(setDivision);
        setLoading(false);
      }

      setTimeout(() => {
        getData();
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire("An refresh the Page", "", "error");
      } else {
        throw new Error("Data Not Found");
      }
    }
  }, [setDivision, financialYear, currentFinancialYear, currentQuarter]);

  const columns = useMemo(
    () => [
      {
        Header: "Full Time Employment",
        columns: [
          {
            Header: "Division",
            accessor: "divisions",
          },
          {
            Header: "Nos. of Full Time Employment (Male)",
            accessor: "full_time_emp_num_male",
          },
          {
            Header: "Nos. of Full Time Employment (Female)",
            accessor: "full_time_emp_num_female",
          },
          {
            Header: "Nos. of Full Time Employment (Total)",
            accessor: "full_time_emp_num_total",
          },
          {
            Header: "SC",
            columns: [
              {
                Header: "Male",
                accessor: "full_time_emp_num_sc_male",
              },
              {
                Header: "Female",
                accessor: "full_time_emp_num_sc_female",
              },
              {
                Header: "Total",
                accessor: "full_time_emp_num_sc_total",
              },
            ],
          },
          {
            Header: "ST",
            columns: [
              {
                Header: "Male",
                accessor: "full_time_emp_num_st_male",
              },
              {
                Header: "Female",
                accessor: "full_time_emp_num_st_female",
              },
              {
                Header: "Total",
                accessor: "full_time_emp_num_st_total",
              },
            ],
          },

          {
            Header: "OBC/ Minority/ Open",
            columns: [
              {
                Header: "Male",
                accessor: "full_time_emp_num_obc_minority_open_male",
              },
              {
                Header: "Female",
                accessor: "full_time_emp_num_obc_minority_open_female",
              },
              {
                Header: "Total",
                accessor: "full_time_emp_num_obc_minority_open_total",
              },
            ],
          },
          {
            Header: "PWD",
            columns: [
              {
                Header: "Male",
                accessor: "full_time_emp_num_pwd_male",
              },
              {
                Header: "Female",
                accessor: "full_time_emp_num_pwd_female",
              },
              {
                Header: "Total",
                accessor: "full_time_emp_num_pwd_total",
              },
            ],
          },
        ],
      },
      {
        Header: "Part Time Employment",
        columns: [
          {
            Header: "Nos. of Part Time Employment (Male)",
            accessor: "part_time_emp_num_male",
          },
          {
            Header: "Nos. of Part Time Employment (Female)",
            accessor: "part_time_emp_num_female",
          },
          {
            Header: "Nos. of Part Time Employment (Total)",
            accessor: "part_time_emp_num_total",
          },
          {
            Header: "SC",
            columns: [
              {
                Header: "Male",
                accessor: "part_time_emp_num_sc_male",
              },
              {
                Header: "Female",
                accessor: "part_time_emp_num_sc_female",
              },
              {
                Header: "Total",
                accessor: "part_time_emp_num_sc_total",
              },
            ],
          },
          {
            Header: "ST",
            columns: [
              {
                Header: "Male",
                accessor: "part_time_emp_num_st_male",
              },
              {
                Header: "Female",
                accessor: "part_time_emp_num_st_female",
              },
              {
                Header: "Total",
                accessor: "part_time_emp_num_st_total",
              },
            ],
          },

          {
            Header: "OBC/ Minority/ Open",
            columns: [
              {
                Header: "Male",
                accessor: "part_time_emp_num_obc_minority_open_male",
              },
              {
                Header: "Female",
                accessor: "part_time_emp_num_obc_minority_open_female",
              },
              {
                Header: "Total",
                accessor: "part_time_emp_num_obc_minority_open_total",
              },
            ],
          },
          {
            Header: "PWD",
            columns: [
              {
                Header: "Male",
                accessor: "part_time_emp_num_pwd_male",
              },
              {
                Header: "Female",
                accessor: "part_time_emp_num_pwd_female",
              },
              {
                Header: "Total",
                accessor: "part_time_emp_num_pwd_total",
              },
            ],
          },

        ],
      },
      {
        Header: "Total Employment Generated",
        accessor: "total_emp_generated",
      },
    
    ],
    []
  );

  // Calculate the total width for equally sized columns
  const totalWidthForEqualColumns = 800; // Adjust as needed (reduced from 900)

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData, 
    });

  const cellStyle = {
    padding: "8px",
    // border: '1px solid #ccc', // Add border style here
    textAlign: "center", // Center text horizontally
    verticalAlign: "middle", // Center text vertically
  };


  const handleExport = () => {
    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();

    // Create the header rows
    const firstRow = [
      "",
      "",
      "",
      "Full Time Employment",
      "Full Time Employment",
      "Full Time Employment",
      "SC",
      "",
      "",
      "ST",
      "",
      "",
      "OBC/ Minority/ Open",
      "",
      "",
      "PWD",
      "",
      "",
      "Part Time Employment",
      "Part Time Employment",
      "Part Time Employment",
      "SC",
      "",
      "",
      "ST",
      "",
      "",
      "OBC/ Minority/ Open",
      "",
      "",
      "PWD",
      "",
      "",
      "",
    ];

    const secondRow = [
      "FY",
      "Period",
      "Division",
      "Nos. of Full Time Employment (Male)",
      "Nos. of Full Time Employment (Female)",
      "Nos. of Full Time Employment (Total)",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Nos. of Part Time Employment (Male)",
      "Nos. of Part Time Employment (Female)",
      "Nos. of Part Time Employment (Total)",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Male",
      "Female",
      "Total",
      "Total Employement Generated ",
    ];

    // Combine the header rows with the table data
    const exportData = [
      firstRow,
      secondRow,
      ...tableData.map((row) => {
        return [
          selectFinnacialYear,
          selectSubFilter,
          row.divisions,
          row.full_time_emp_num_male,
          row.full_time_emp_num_female,
          row.full_time_emp_num_total,
          row.full_time_emp_num_sc_male,
          row.full_time_emp_num_sc_female,
          row.full_time_emp_num_sc_total,
          row.full_time_emp_num_st_male,
          row.full_time_emp_num_st_female,
          row.full_time_emp_num_st_total,
          row.full_time_emp_num_obc_minority_open_male,
          row.full_time_emp_num_obc_minority_open_female,
          row.full_time_emp_num_obc_minority_open_total,
          row.full_time_emp_num_pwd_male,
          row.full_time_emp_num_pwd_female,
          row.full_time_emp_num_pwd_total,
          row.part_time_emp_num_male,
          row.part_time_emp_num_female,
          row.part_time_emp_num_total,
          row.part_time_emp_num_sc_male,
          row.part_time_emp_num_sc_female,
          row.part_time_emp_num_sc_total,
          row.part_time_emp_num_st_male,
          row.part_time_emp_num_st_female,
          row.part_time_emp_num_st_total,
          row.part_time_emp_num_obc_minority_open_male,
          row.part_time_emp_num_obc_minority_open_female,
          row.part_time_emp_num_obc_minority_open_total,
          row.part_time_emp_num_pwd_male,
          row.part_time_emp_num_pwd_female,
          row.part_time_emp_num_pwd_total,
          row.total_emp_generated,
        ];
      }),
    ];

    // Add the summary data if it's an array
    if (Array.isArray(grantSummary)) {
      exportData.push(["", "", "Grand Total", ...grantSummary.slice(1)]);
    }

    // Convert the combined data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(exportData);

    // Merge cells for the first row
    ws["!merges"] = [
      { s: { r: 0, c: 3 }, e: { r: 0, c: 5 } }, // FTE
      { s: { r: 0, c: 6 }, e: { r: 0, c: 8 } }, // SC
      { s: { r: 0, c: 9 }, e: { r: 0, c: 11 } }, // ST
      { s: { r: 0, c: 12 }, e: { r: 0, c: 14 } }, // OBC/ Minority/ Open
      { s: { r: 0, c: 15 }, e: { r: 0, c: 17 } }, // PWD
      { s: { r: 0, c: 18 }, e: { r: 0, c: 20 } }, // PTE
      { s: { r: 0, c: 21 }, e: { r: 0, c: 23 } }, // SC
      { s: { r: 0, c: 24 }, e: { r: 0, c: 26 } }, // ST
      { s: { r: 0, c: 27 }, e: { r: 0, c: 29 } }, // OBC/ Minority/ Open
      { s: { r: 0, c: 30 }, e: { r: 0, c: 32 } }, // PWD
    ];

    // Center-align the merged cells in the first row
    const centerAlign = {
      alignment: { horizontal: "center", vertical: "center" },
    };
    const headerStyles = [
      { r: 0, c: 3 },
      { r: 0, c: 6 },
      { r: 0, c: 9 },
      { r: 0, c: 12 },
      { r: 0, c: 15 },
      { r: 0, c: 18 },
      { r: 0, c: 21 },
      { r: 0, c: 24 },
      { r: 0, c: 27 },
      { r: 0, c: 30 },
    ];

    headerStyles.forEach((pos) => {
      const cell = ws[XLSX.utils.encode_cell(pos)];
      if (cell) {
        cell.s = { ...cell.s, ...centerAlign };
      } else {
        ws[XLSX.utils.encode_cell(pos)] = {
          v: firstRow[pos.c],
          t: "s",
          s: centerAlign,
        };
      }
    });

    

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Division Performance");

    // Generate a buffer for the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
  
    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectFinnacialYear}_${selectSubFilter}_${divisionName}_MG2_EMP_Construction_DivPerformance.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }
  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-5"></div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Financial Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFinnacialYear}
                    label="Financial Year"
                    onChange={handleSelectFinancialYear}
                  >
                    {financialYear.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="grouped-native-select">
                    Period:
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Period"
                    value={selectSubFilter}
                    onChange={handleSubFilter}
                  >
                    <ListSubheader>Half Yearly</ListSubheader>
                    <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                    <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                    <ListSubheader>Quarterly</ListSubheader>
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor="demo-simple-select-label">
                    Division
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDivision}
                    label="Division"
                    onChange={handleSelectDivision}
                  >
                    {divisions.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.division_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-3">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={handleExport}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "155px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", overflowX: "auto" , maxHeight: '445px' }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "2px solid #ccc",
                      background: "#f2f2f2",
                      fontWeight: "bold",
                      border:
                        column.Header === "PWD" ||
                        (column.parent &&
                          column.parent.Header === "PWD" &&
                          ["Male", "Female", "Total"].includes(column.Header))
                          ? "4px solid #ccc"
                          : "2px solid #ccc", // Thicker border for "PWD" and its children "Male", "Female", and "Total", normal border for others
                      ...cellStyle,
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          border: "1px solid #ccc",
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {grantSummary.map((cell) => {
                return (
                  <td
                    style={{
                      border: "2px solid #ccc",
                      fontWeight: "bold",
                      ...cellStyle,
                    }}
                  >
                    <span>{cell}</span>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
