import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  state_id: '',
  country_id: '',
  from_date: null,
  to_date: null,
  location_address: '',
  purpose: '',
  participants_male: '',
  participants_female: '',
  participants_total: '',

  validationErrors: {
    state_id: '',
    country_id: '',
    from_date: '',
    to_date: '',
    location_address: '',
    purpose: '',
    participants_male: '',
    participants_female: '',
    participants_total: '',
  }
};

const StudyTourExposureSlice = createSlice({
  name: 'StudyTourExposureSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    setTotalParticipants: (state, action) => {
      state.participants_total= action.payload;
    },
    // Add other reducers for different input fields if needed
    updateApiDataToStudy: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataStudy: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsStudy: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsStudy, setTotalParticipants, resetValidation, setInputFieldData, updateApiDataToStudy, resetDataStudy } = StudyTourExposureSlice.actions;

export default StudyTourExposureSlice.reducer;
