import React ,{useState} from 'react';
import { Offline, Online } from 'react-detect-offline';
import { Box,  FormControl, InputLabel,  MenuItem, Select } from '@mui/material';
import "../../../../assets/css/masterdata.css";
import {resetValidation, setInputFieldData} from "../../../../redux/slice/EntityMaster/InnvoationMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { InnovationTable } from './EntityTables/InnovationTable';


export const EntityInnovationPackComm = (props) =>{ 
    
    const innovationMaster = useSelector(state => state.innovationMaster["20"]);
    const dispatch = useDispatch();

    const handleFieldMAGNETCrop = (event) => {
        dispatch(setInputFieldData({ fieldName: 'magnet_crop_id', value:event.target.value }));
    }
    const handleFieldSubProjectTitle = (event) => {
        dispatch(setInputFieldData({ fieldName: 'sub_project_title', value:event.target.value }));
    }
    const handleFieldSigingDate = (event) => {
        dispatch(setInputFieldData({ fieldName: 'signing_date', value:event.target.value }));
    }
    const handleFieldProjectCost = (event) => {
        dispatch(setInputFieldData({ fieldName: 'total_project_cost', value:event.target.value }));
    }
    const handleFieldContribution = (event) => {
        dispatch(setInputFieldData({ fieldName: 'beneficiary_contribution', value:event.target.value }));
    }
    const handleFieldSanctionGrant = (event) => {
        dispatch(setInputFieldData({ fieldName: 'matching_grant', value:event.target.value }));
    }
    const handleResetFocus = () =>{
        dispatch(resetValidation())
    }
    return(<>   
        {/* <Online> */}
        <div className='tab-pane'>
            <form className="row g-3" id='formid'>
                    {/* Magnet_crops */}
                    <div className='primaryTableLabel'>
                    <Box>
                        <label htmlFor="MAGNET" style={{width:'270px'}}>MAGNET Crops</label>
                        <FormControl style={{width:'70%'}} >
                        <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                        <Select name="MAGNET" 
                             className="customSelect" 
                             style={{width:"70%" }}
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={innovationMaster.magnet_crop_id}
                             onChange={handleFieldMAGNETCrop}   
                             onFocus={handleResetFocus}      
                             multiple  
                                                          
                            >
                          {(props.Magnetcrop || []).map((e) => {
                            console.log('Mapped Crop:', e);
                            return (
                            <MenuItem value={e.id} key={e.id}>
                                {e.crop}
                            </MenuItem>
                            );
                        })}
                        </Select>
                        </FormControl>
                    </Box>
                    </div>
   
                    {/* Sub Project Title */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="SubProjectTitle " style={{width:'270px'}}>Sub Project Title</label>
                        <input type="text" name="SubProjectTitle " 
                            value={innovationMaster.sub_project_title}
                            onChange={handleFieldSubProjectTitle} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"49%"}}  
                        />
                    </div>
                                                                              
                    {/* MOU Signing Date */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="signDate " style={{width:'270px'}}>MOU Signing Date</label>
                        <input type="date" name="signDate " 
                            value={innovationMaster.signing_date}
                            onChange={handleFieldSigingDate} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}
                        />
                    </div>
                                                                             
                    {/* Total Project Cost */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="ProjectCost " style={{width:'270px'}}>Total Project Cost</label>
                        <input type="number" name="ProjectCost " 
                            value={innovationMaster.total_project_cost}
                            onChange={handleFieldProjectCost} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                                                                                               
                    {/* Beneficiary Contribution */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="contribution " style={{width:'270px'}}>Beneficiary Contribution</label>
                        <input type="number" name="contribution " 
                            value={innovationMaster.beneficiary_contribution}
                            onChange={handleFieldContribution} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                                                                                                                   
                    {/* Total Sanction Grant from MAGNET */}
                    <div className='primaryTableLabel'>
                        <label htmlFor="sanctionGrant " style={{width:'270px'}}>Total Sanction Grant from MAGNET</label>
                        <input type="number" name="sanctionGrant " 
                            value={innovationMaster.matching_grant}
                            onChange={handleFieldSanctionGrant} 
                            onFocus={handleResetFocus}
                            className="custom" 
                            style={{width:"30%"}}    
                        />
                    </div>
                    <InnovationTable setDisabled={props.setDisabled} validateDates={props.validateDates}/>
               </form>         
            </div>   
        {/* </Online>
        <Offline>
            You're Offline, Please Check your Connection
        </Offline> */}
        </>)
    }
    
