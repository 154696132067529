import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";
import '../../../assets/css/masterdata.css'

export const DistrictAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({});
  const [page, setPage] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [divisions, setDivision] = useState('');

  // Add new attribute
  const handleAddOption = async () => {
    let value = "";
    const { value: enterValue, isConfirmed } = await Swal.fire({
      title: "Enter District Attribute",
      html: `<div>
          <label for="district-select">Select District:</label>
          <select id="district-select" class="swal2-select">
            ${divisions.map((division) => `<option value="${division.id}">${division.divisions}</option>`)}
          </select>
        </div><br><br>
        <label for="taluka-input">Enter District Attribute:</label>
        <input type="text" id="swal-input1" class="swal2-input" placeholder="District Attribute" value="${value}">
        `,
      inputLabel: "District Attribute",
      inputValue: value,
      showCancelButton: true,
      confirmButtonText: "Save",
      preConfirm: () => {
        const inputValue = document.getElementById("swal-input1").value;
        const divisionSelect = document.getElementById("district-select");
        if (!inputValue || divisionSelect === null) {
          Swal.showValidationMessage("Please enter both values");
        }

        return {
          district: inputValue,
          divisionId: divisionSelect.value,
        };
      },
    });

    if (isConfirmed) {
      try {
        const editAttribute = await axios.post(
          `${apiUrl}api/district-create`,
          {
            district: enterValue.district,
            division_id: enterValue.divisionId,
          },
          getConfigWithToken()
        );

        if (editAttribute.data["status"] === true) {
          Swal.fire(`Attribute Added Successfully`, "", "success");
          // Fetch updated data after successful addition
          fetchData();
        } else {
          Swal.fire(`${editAttribute.data["message"]}`, "", "warning");
        }
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire(
          `${error.message}`,
          "",
          "warning"
        );
      }
    }
  };

  // Editing the exisiting attribute value
  const handleEditFunction = async (value, id, district_id, option) => {
    try {
      // Fetch the district information for the selected district
      const districtEditResponse = await axios.get(
        `${apiUrl}api/district-edit?id=${id}`,
        getConfigWithToken()
      );

      const districtData = districtEditResponse.data.data[0];
      const selectedDivisionId = districtData.division_id;

      const { value: editValue, isConfirmed } = await Swal.fire({
        title: "Edit District Attribute",
        html: `<div>
            <label for="division-select">Select Division:</label>
            <select id="division-select" class="swal2-select">
              ${divisions.map((division) => `<option value="${division.id}" ${division.id === selectedDivisionId ? 'selected' : ''}>${division.divisions}</option>`)}
            </select>
          </div><br><br>
          <label for="district-input">Edit District Attribute:</label>
          <input type="text" id="swal-input1" class="swal2-input" placeholder="District Attribute" value="${value}">
          `,
        inputLabel: "Your District Attribute",
        inputValue: value,
        showCancelButton: true,
        confirmButtonText: "Edit",
        preConfirm: () => {
          const inputValue = document.getElementById("swal-input1").value;
          const divisionSelect = document.getElementById("division-select");
          if (!inputValue || divisionSelect === null) {
            Swal.showValidationMessage("Please enter both values");
          }

          return {
            district: inputValue,
            division_id: divisionSelect.value,
          };
        },
        inputValidator: (value) => {
          if (!value) {
            return "Enter District Attribute";
          }
        },
      });

      if (isConfirmed) {
        const editAttribute = await axios.post(
          `${apiUrl}api/district-update`,
          {
            district: editValue.district,
            division_id: editValue.division_id,
            id: id,
          },
          getConfigWithToken()
        );

        if (editAttribute.data["status"] === true) {
          Swal.fire(`Attribute Edited Successfully`, "", "success");
          // Fetch updated data after successful edit
          fetchData();
        } else {
          Swal.fire(`Failed to edit attribute`, "", "error");
        }
      }
    } catch (error) {
      console.error("API Error:", error);
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Delete operation
  const handleDeleteFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteAttribute = await axios.get(`${apiUrl}api/district-delete?id=${id}`, getConfigWithToken())
          if (deleteAttribute.data["status"] === true) {
            Swal.fire({
              title: "Deleted!",
              text: "Attribute has been deleted.",
              icon: "success"
            });
            // Fetch updated data after successful deletion
            fetchData();
          }else {
              Swal.fire(`${deleteAttribute.data["message"]}`, "", "warning");
            
          }
        } catch {
          Swal.fire(
            `Getting Error While Deleting`,
            '',
            'warning'
          )
        }
      }
    });
  }

  // Onchange of Pagination
  const handleChange = async (event, value) => {
    try {
      const getDistricts = await axios.get(
        `${apiUrl}api/get-all-district?page=${value}`,
        getConfigWithToken()
      );
      const districts = getDistricts.data["data"]["data"].map((e) => ({
        id: e.id,
        district: e.district,
        division_id: e.division_id
      }));
      setAttributeOptions(districts);
      setGetAllData(getDistricts.data.data);
      setPage(value);
    } catch (error) {
      console.error("Error fetching Districts:", error);
    }
  };

  // Fetching the exisiting list
  const fetchData = async () => {
    try {
      const getDistricts = await axios.get(
        `${apiUrl}api/get-all-district`,
        getConfigWithToken()
      );
      if (getDistricts.data && getDistricts.data.data && Array.isArray(getDistricts.data.data.data)) {
        const districts = getDistricts.data.data.data.map((e) => ({
          id: e.id,
          district: e.district,
          division_id: e.division_id
        }));
        setAttributeOptions(districts);
        setGetAllData(getDistricts.data.data);
      } else {
        console.error('Unexpected response structure or empty data:', getDistricts.data);
       
      }
    } catch (error) {
      console.error('Error fetching Districts:', error);
     
    }
  };

  useEffect(() => {
    async function getDistrictsData() {
      try {
        const getDistricts = await axios.get(
          `${apiUrl}api/get-all-district`,
          getConfigWithToken()
        );

        const divisionResponse = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        const divisionResponseData = divisionResponse.data["data"].map((e) => ({
          "id": e["id"],
          "divisions": e["divisions"]
        }));
        setDivision(divisionResponseData);

        if (getDistricts.data && getDistricts.data.data && Array.isArray(getDistricts.data.data.data)) {
          const districts = getDistricts.data.data.data.map((e) => ({
            id: e.id,
            district: e.district,
            division_id: e.division_id
          }));
          setAttributeOptions(districts);
          setGetAllData(getDistricts.data.data);
        } else {
          console.error('Unexpected response structure or empty data:', getDistricts.data);
          
        }
      } catch (error) {
        console.error('Error fetching Districts:', error);
        
      }
    }

    getDistrictsData();
  }, []);

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div id="exTab3" className="contain" style={{ width: '100%', overflow: 'hidden' }}> 
            <div className="cardMaster" id="" style={{ width: '100%', marginTop: '0.5rem' }}>
              <form className="row g-3" style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}>
                <Box className="your-component" sx={{ typography: 'body1', width: "88%" }}>
                <div className="words-with-lines">
                      <span>District</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata" style={{marginRight:'1rem'}}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>
                  <Box className="your-box" sx={{typography: "body1"}}>
                    <div>
                      <span>Manage Options (Value of your Attribute)</span>
                    </div>
                  </Box>
                  <div className="your-button-container">
                    <button type="button" style={{width:'30%'}} onClick={(e) => handleAddOption(e)}>
                      <i className="fa-duotone fa-plus"></i> Add Attributes
                      Options{" "}
                    </button>
                  </div>
                  <Box className='custom-box' style={{marginLeft:'2rem'}}>
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Attribute Options</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="your-tbody">
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{border: "1px solid black"}}>
                              <input type="text" value={option.district}  disabled/>
                            </td>
                            <td>
                              <i
                                class="fa-solid fa-pen-to-square"
                                onClick={()=>handleEditFunction(option.district, option.id, option.division_id, option)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={()=> handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                        />
                    </table>
                  </Box>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>

    </>
  );
};
