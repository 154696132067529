import { InputField } from "../../InputField";
import React, { useEffect, useState } from "react";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormHelperText } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getConfigWithToken } from "../../../utils/Config/Config";
// Assuming you are using Redux Toolkit
import {
  setInputFieldData,
  resetValidation,
  setTotalSC,
  setTotalST,
  setTotalPWD,
  setTotalBPL,
  setTotalOBC,
  setTotalParticipants,
  setTotalMaleParticipants,
  setTotalFemaleParticipants,
} from "../../../redux/slice/InputOne/StudyTourFarmer/StudyTourFarmerSlice"; // Import your action creator
import { MultiSelect } from "../../MultiSelect";

export function StudyTourFarmer(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const studyTourFarmer = useSelector((state) => state.studyTourFarmer);
  const [state, setState] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [beniFiApplicant, setBeniApplicant] = useState([]);
  const [country, setCountry] = useState([]);
  const [division, setDivision] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {
       
        const districts = await axios.get(
          `${apiUrl}api/get-all-district?all=1`,
          getConfigWithToken()
        );
        const divisi = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        
        const applicant = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1`,
          getConfigWithToken()
        );
        const applicantData = applicant.data["data"].map((e) => ({
          id: e["id"],
          crop: e["beneficiary_name"],
        }));
        
        const state = await axios.get(`${apiUrl}api/get-all-states?all=1`, getConfigWithToken());
        const StateData = state.data["data"].map((e) => ({ id: e["id"], state_name: e["state"] }));
        setState(StateData);

        const Country = await axios.get(
          `${apiUrl}api/country-listing?all=1`,
          getConfigWithToken()
        );
        const CountryData = Country.data["data"].map((e) => ({
          id: e["id"],
          country_name: e["country"],
        }));

          console.log('State Response:', state.data["data"]);
          setBeniApplicant(applicantData); // Pass applicantData to setApplicant
          setCountry(CountryData); 
        setDivision(divisi.data["data"]);
        setDistrict(districts.data["data"]);
      } catch (error) {}
    };

    valueChain();
  }, []);

 

  // Total of SC
  const totalSC =
    parseInt(studyTourFarmer.sc_male === "" ? 0 : studyTourFarmer.sc_male) +
    parseInt(studyTourFarmer.sc_female === "" ? 0 : studyTourFarmer.sc_female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(studyTourFarmer.st_male === "" ? 0 : studyTourFarmer.st_male) +
    parseInt(studyTourFarmer.st_female === "" ? 0 : studyTourFarmer.st_female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(studyTourFarmer.pwd_male === "" ? 0 : studyTourFarmer.pwd_male) +
    parseInt(
      studyTourFarmer.pwd_female === "" ? 0 : studyTourFarmer.pwd_female
    );
  dispatch(setTotalPWD(totalPWD));
  
  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      studyTourFarmer.obc_minority_open_male === ""
        ? 0
        : studyTourFarmer.obc_minority_open_male
    ) +
    parseInt(
      studyTourFarmer.obc_minority_open_female === ""
        ? 0
        : studyTourFarmer.obc_minority_open_female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));

 
  const totalMaleParticipants =
    parseInt(studyTourFarmer.sc_male === "" ? 0 : studyTourFarmer.sc_male) +
    parseInt(studyTourFarmer.st_male === "" ? 0 : studyTourFarmer.st_male) +
  
    +parseInt(
      studyTourFarmer.obc_minority_open_male === ""
        ? 0
        : studyTourFarmer.obc_minority_open_male
    );
  dispatch(setTotalMaleParticipants(totalMaleParticipants));

  const totalFemaleParticipants =
    parseInt(studyTourFarmer.sc_female === "" ? 0 : studyTourFarmer.sc_female) +
    parseInt(studyTourFarmer.st_female === "" ? 0 : studyTourFarmer.st_female) +
    +parseInt(
      studyTourFarmer.obc_minority_open_female === ""
        ? 0
        : studyTourFarmer.obc_minority_open_female
    );
  dispatch(setTotalFemaleParticipants(totalFemaleParticipants));

  const totalParticipants =
    parseInt(studyTourFarmer.sc_total === "" ? 0 : studyTourFarmer.sc_total) +
    parseInt(studyTourFarmer.st_total === "" ? 0 : studyTourFarmer.st_total) +
   
    +parseInt(
      studyTourFarmer.obc_minority_open_total === ""
        ? 0
        : studyTourFarmer.obc_minority_open_total
    );
  dispatch(setTotalParticipants(totalParticipants));

  const handleMagnetCropIdChange = (data) => {
    dispatch(setInputFieldData({ fieldName: "magnet_crop_id", value: data }));
  };

  const handleFromDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleLocationAddressChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handleCountryIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "country_id", value: event.target.value })
    );
  };

  const handleStateIdChange = (event) => {
    dispatch(setInputFieldData({ fieldName: "state_id", value: event.target.value }));
  };

  const handleDistrictIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "district_id", value: event.target.value })
    );
  };

  const handlePurposeChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "purpose", value }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({fieldName, value}));
  };
  
  return (
    <>
      {/* <div className="componentSub"> */}

      <div class="row g-3">
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <MultiSelect
            label="Magnet Crop"
            data={props.magnetCrop}
            errorText={
              studyTourFarmer.validationErrors.magnet_crop_id.length !== 0
                ? true
                : false
            }
            value={studyTourFarmer.magnet_crop_id}
            onChange={handleMagnetCropIdChange}
          />
        </div>
        <DatePick
          label="From Date"
          className="custom-date-picker"
          disbrushDate={studyTourFarmer.from_date}
          onChange={handleFromDateChange}
          errorText={!!studyTourFarmer.validationErrors.from_date}
        />
        <DatePick
          label="To Date"
          className="custom-date-picker"
          disbrushDate={studyTourFarmer.to_date}
          onChange={handleToDateChange}
          errorText={!!studyTourFarmer.validationErrors.to_date}
        />
        <InputField
          label="Location"
          type="text"
          value={studyTourFarmer.location_address}
          onChange={handleLocationAddressChange}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.location_address}
          helperText={studyTourFarmer.validationErrors.location_address}
        />

        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            {props.compValue === 14 ? (
              <>
                <FormControl
                  fullWidth
                  error={
                    studyTourFarmer.validationErrors.district_id !== ""
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    District
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studyTourFarmer.district_id}
                    onChange={handleDistrictIdChange}
                    label="(state)"
                  >
                    {districts.map((e) => (
                      <MenuItem value={e.id}>{e.district}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            )}
            {props.compValue === 15 ? (
              <>
                <FormControl
                  fullWidth
                  error={
                    studyTourFarmer.validationErrors.state_id !== ""
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studyTourFarmer.state_id}
                    onChange={handleStateIdChange}
                    label="state"
                  >
                    {state.map((e) => (
                      <MenuItem value={e.id}>{e.state_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            )}
            {props.compValue === 16 ? (
              <>
                <FormControl
                  fullWidth
                  error={
                    studyTourFarmer.validationErrors.country_id !== ""
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studyTourFarmer.country_id}
                    onChange={handleCountryIdChange}
                    label="country"
                  >
                    {country.map((e) => (
                      <MenuItem value={e.id}>{e.country_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              ""
            )}
          </Box>
        </div>

        <InputField
          label="Purpose/Description Visit"
          type="text"
          value={studyTourFarmer.purpose}
          onChange={handlePurposeChange}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.purpose}
          helperText={studyTourFarmer.validationErrors.purpose}
        />
        <br />
        <div></div>
        <div className="word-with-line" style={{ margin: "1.5rem 0" }}>
          <span>Vulnerable groups & Vulnerability</span>
        </div>
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={studyTourFarmer.sc_male}
          onChange={(value) => handleInputChange("sc_male", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.sc_male}
          helperText={studyTourFarmer.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={studyTourFarmer.sc_female}
          onChange={(value) => handleInputChange("sc_female", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.sc_female}
          helperText={studyTourFarmer.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={studyTourFarmer.sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={studyTourFarmer.st_male}
          onChange={(value) => handleInputChange("st_male", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.st_male}
          helperText={studyTourFarmer.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={studyTourFarmer.st_female}
          onChange={(value) => handleInputChange("st_female", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.st_female}
          helperText={studyTourFarmer.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={studyTourFarmer.st_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={studyTourFarmer.obc_minority_open_male}
          onChange={(value) =>
            handleInputChange("obc_minority_open_male", value)
          }
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.obc_minority_open_male}
          helperText={studyTourFarmer.validationErrors.obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={studyTourFarmer.obc_minority_open_female}
          onChange={(value) =>
            handleInputChange("obc_minority_open_female", value)
          }
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.obc_minority_open_female}
          helperText={studyTourFarmer.validationErrors.obc_minority_open_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={studyTourFarmer.obc_minority_open_total}
          readOnly={true}
        />
        <div className="col-md-3 input1Heading">
          <span>Participants</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={studyTourFarmer.participants_male}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={studyTourFarmer.participants_female}
          readOnly={true}
        />
        <InputField
          col="3"
          label="Total Participants"
          type="number"
          value={studyTourFarmer.participants_total}
          readOnly={true}
        />
      </div>

      <div style={{ marginTop: "3rem" }} class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>PWD</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={studyTourFarmer.pwd_male}
          onChange={(value) => handleInputChange("pwd_male", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.pwd_male}
          helperText={studyTourFarmer.validationErrors.pwd_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={studyTourFarmer.pwd_female}
          onChange={(value) => handleInputChange("pwd_female", value)}
          onFocus={handleResetFocus}
          error={!!studyTourFarmer.validationErrors.pwd_female}
          helperText={studyTourFarmer.validationErrors.pwd_female}
        />
        <InputField
          col="3"
          label="Total PWD"
          type="number"
          value={studyTourFarmer.pwd_total}
          readOnly={true}
        />
      </div>
    </>
  );
}
