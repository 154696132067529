/* eslint-disable default-case */
import "../assets/css/inputForm.css";
import { InputField } from "../subcomponents/InputField";
import pdf from "../assets/PDF_file_icon.svg.png";
import imgIcon from "../assets/image_icon-icons.com_50366.png";
import React, { useState } from "react";
import {
  setValidationErrorsPostHarvest,
  resetDataPostHarvest,
  updatePostHarvestForm,
} from "../redux/slice/InputOne/PostHarvestTrainingSlice/PostHarvestSlice";
import {
  setValidationErrorsValueChain,
  resetDataValueChain,
  updateValueChainForm,
} from "../redux/slice/InputOne/ValueChain/ValueChainSlice";
import {
  setValidationErrorsCapacityBuilding,
  resetDataCapacityBuilding,
  updateCapacityBuildingForm,
  resetValidation,
} from "../redux/slice/InputOne/CapacityBuilding/CapacityBuildingSlice";
import {
  setValidationErrorsSMELinkage,
  resetDataSMELinkage,
  updateSMELinkageForm,
} from "../redux/slice/InputOne/SMELinkage/smeLinkageSlice";
import {
  updateTotTrainingForm,
  resetDataTotTraining,
  setValidationErrorsTotTraining,
} from "../redux/slice/InputOne/TotTraining/TotTrainingSlice";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setInputFieldData } from "../redux/slice/InputTwo/MasterSubSlice";
import { PHMtraining } from "../subcomponents/Input1/Input1PostHarvest/PHMTraining";
import { GapTraining } from "../subcomponents/Input1/Input1CapacityBuilding/TrainingOnGap";
import { WomenLedtraining } from "../subcomponents/Input1/Input1ValueChain/TrainingOfWomenLed";
import { TotTraining } from "../subcomponents/Input1/Input1Tot/TotTraining";
import { MultiSelect } from "../subcomponents/MultiSelect";
import { SMELinkage } from "../subcomponents/Input1/Input1SMELinkage/SMELinkage";
import { DatePick } from "../subcomponents/DatePick";
// import { GAPPH1, ValueChain } from '../utils/Input1Components';

export const CapacityBuildingForm = ({ quarter, magentCrp, divisi }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileUrl = process.env.REACT_APP_INPUT1_API_FILE_URL;

  const dispatch = useDispatch();
  const capacityBuilding = useSelector((state) => state.capacityBuilding);
  const [loading, setLoading] = useState(false);

  const valueChain = useSelector((state) => state.valueChain); // Assuming you have this in your state
  const beneficiary_id = valueChain.beneficiary_id || [];

  const [loadingg, setloadingg] = useState(true);
  const [value, setValue] = useState("1");
  const [quarters, setQuarter] = useState([]);
  const [ValueChain, setValueChain] = useState([]);
  const [GAPPH1, setGAPPH1] = useState([]);
  const [selectQuarter, setSelectedQuarters] = useState("");
  const [selectedGAP, setSelectedGAP] = useState("");
  const [locationTaluka, setlocationTaluka] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [beneStudyTourFarm, setBeneStudyTourFarm] = useState([]);
  const [selectDivision, setSelectDivision] = useState("");
  const [selectKPMGDivision, setSelectKPMGDivision] = useState("");
  const [selectedValueChain, setSelectedValueChain] = useState("");
  const [magentCrop, setMagentCrop] = useState([]);
  const [postHarvestFlagEdit, setPostHarvestFlagEdit] = useState(false);
  const [postHarvestIdEdit, setPostHarvestIdEdit] = useState("");
  const [capacityBuildingFlagEdit, setCapacityBuildingFlagEdit] =
    useState(false);
  const [capacityBuildingIdEdit, setcapacityBuildingIdEdit] = useState("");
  const [valueChainFlagEdit, setValueChainFlagEdit] = useState(false);
  const [valueChainIdEdit, setValueChainIdEdit] = useState("");
  const [smeLinkageFlagEdit, setSMELinkageFlagEdit] = useState(false);
  const [smeLinkageIdEdit, setSMELinkageIdEdit] = useState("");
  const [totTrainingFlagEdit, setTotTrainingFlagEdit] = useState(false);
  const [totTrainingIdEdit, setTotTrainingIdEdit] = useState("");
  const [tot_Magnet_crop_id, setTot_Magnet_Crop_Id] = useState([]);
  const [nameOfThePerson, setNameOfThePerson] = useState("");

  const [beniFiApplicant, setBeniApplicant] = useState([]);
  const [capacityActionPoint, setCapacityActionPoint] = useState("");
  const [selectbeniFiApplicant, setSelectBeniApplicant] = useState("");
  const [errorSelectBeniApplicant, setErrorSelectBeniApplicant] = useState("");
  const [editInput1Files, setEditInput1Files] = useState([]);

  // Files State
  const [selectCapacityFile, setSelectCapacityFile] = useState([]);
  const [errorCapacityFile, setErrorCapacityFile] = useState("");

  // setting beneficiary
  const [nurseryTissueBeneficiary, setNurseryTissueBeneficiary] = useState([]);
  const [studyFarmApplicant, setStudyFarmApplicant] = useState([]);
  const [division, setDivision] = useState([]);
  const [beniById, setBeniById] = useState({});
  const [permission, setPermission] = useState([]);

  const dd = useSelector(setInputFieldData);

  const checkFPOSelect = () => {
    if (selectbeniFiApplicant.length === 0) {
      setErrorSelectBeniApplicant("Please Select Applicant");
    } else {
      return true;
    }
    return false;
  };

  const resetAllState = () => {
    setSelectBeniApplicant("");
    setFromDate("");
    setToDate("");
    setCapacityActionPoint("");
    setSelectKPMGDivision("");
    setValueChainFlagEdit("");
    setPostHarvestFlagEdit("");
    setErrorSelectBeniApplicant("");
    setSelectedGAP("");
    setBeniById({});
    setNurseryTissueBeneficiary([]);
    setBeneStudyTourFarm([]);
    setEditInput1Files([]);
    setStudyFarmApplicant([]);
    dispatch(resetDataSMELinkage());
    dispatch(resetDataPostHarvest());
    dispatch(resetDataValueChain());
  };

  const handleFieldNameOfPerson = (value) => {
    setNameOfThePerson(value);
  };
  const handleTotMagnetCrop = async (data) => {
    setTot_Magnet_Crop_Id(data);
    try {
      const getDatatot = await axios.get(
        `${apiUrl}api/tot-inputs-edit?form_id=23&name_of_the_person=${nameOfThePerson}&quarter=${selectQuarter}&magnet_crop_id=${data.join(
          ","
        )}`,
        getConfigWithToken()
      );
      if (getDatatot.data["status"] === true) {
        getDatatot.data["flag"]
          ? setTotTrainingFlagEdit(true)
          : setTotTrainingFlagEdit(false);
        const data = getDatatot.data["data"][0];
        setEditInput1Files(data["remarks"]);
        setTotTrainingIdEdit(data["id"]);
        data["nomination_by_division"] =
          data["nomination_by_division"].split(",");
        dispatch(updateTotTrainingForm(data));
      } else {
        setEditInput1Files([]);
        setTotTrainingFlagEdit(false);
        dispatch(resetDataTotTraining());
      }
    } catch (err) {}
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    resetAllState();
    refershdata();
  };

  const handleMultiFilesGAPChange = (event) => {
    setSelectCapacityFile(event.target.files);
  };
  const handleFieldChangeActionPoints = (value) => {
    setCapacityActionPoint(value);
  };
  const handleFPOVCOUtilizationChange = async (event) => {
    const selectedApplicant = event.target.value;
    setBeneStudyTourFarm(selectedApplicant);

    // Resetting form data
    dispatch(resetDataValueChain()); // Clear form data
    setValueChainFlagEdit(false); // Ensure it is set to false to indicate creation mode
    setValueChainIdEdit(""); // Clear the id
    setEditInput1Files([]); // Clear any uploaded files
    setFromDate(null); // Clear from date
    setToDate(null); // Clear to date

    // Optionally, clear other state variables related to the form
    setCapacityActionPoint("");
    setSelectBeniApplicant("");
    setErrorSelectBeniApplicant("");
    setSelectCapacityFile([]);
    setErrorCapacityFile("");

    // Clear the form validation errors
    dispatch(setValidationErrorsValueChain({}));

  };

  const handleValuedates = async (beneficiary_id, from_date, to_date) => {
    if (!from_date || !to_date) return;

    console.log("value:", value); // Add this
    if (value === "9") {
      try {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/f23-inputs-edit?quarter=${selectQuarter}&beneficiary_id=${beneStudyTourFarm}&division_id=${selectKPMGDivision}&training_type_id=${selectedValueChain}&form_id=1&from_date=${from_date}&to_date=${to_date}`,
          getConfigWithToken()
        );

        console.log("API response:", getStudyTourEditData.data); // Add this

        if (getStudyTourEditData.data.status) {
          setValueChainFlagEdit(true); // Ensure this is set correctly
          const data = getStudyTourEditData.data.data[0];
          setEditInput1Files(data.remarks);
          setValueChainIdEdit(data.id);
          dispatch(updateValueChainForm(data));
        } else {
          setEditInput1Files([]);
          setValueChainFlagEdit(false);
          dispatch(resetDataValueChain());
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    if (value === "6") {
      try {
        const getStudyTourEditData = await axios.get(
          `${apiUrl}api/postHar-Cap-edit?quarter=${selectQuarter}&beneficiary_id=${beneStudyTourFarm}&division_id=${selectKPMGDivision}&training_type_id=${selectedValueChain}&form_id=2&from_date=${from_date}&to_date=${to_date}`,
          getConfigWithToken()
        );

        console.log("API response:", getStudyTourEditData.data); // Add this

        if (getStudyTourEditData.data.status) {
          setPostHarvestFlagEdit(true); // Ensure this is set correctly
          const data = getStudyTourEditData.data.data[0];
          data["magnet_crop_id"] = data["magnet_crop_id"].split(",");
          setEditInput1Files(data.remarks);
          setPostHarvestIdEdit(data.id);
          dispatch(updatePostHarvestForm(data));
        } else {
          setEditInput1Files([]);
          setPostHarvestFlagEdit(false);
          dispatch(resetDataPostHarvest());
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      console.log("Calling handleValuedates with dates", fromDate, toDate); // Add this
      handleValuedates(beneStudyTourFarm, fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const handleFromDateChange = (value) => {
    setFromDate(value);
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    setToDate(value);
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleDeleteFile = async (id) => {
    // delete-file-input-one-others
    const deleteFile = await axios.get(
      `${apiUrl}api/remark-delete?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);

      // Update the state with the new remarks data
      setEditInput1Files({
        ...editInput1Files,
        remarks: newRemarks,
      });
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };

  const handleDeleteCapacityFile = async (id) => {
    const deleteFile = await axios.get(
      `${apiUrl}api/delete-file-input-one-others?id=${id}`,
      getConfigWithToken()
    );
    if (deleteFile.data["status"]) {
      const newRemarks = editInput1Files.filter((remark) => remark.id !== id);
      setEditInput1Files(newRemarks);
      Swal.fire(`File deleted Successfully`, "", "success");
    }
  };

  const handleOptionsGAP = async (event, locationTaluka, date) => {
    // debugger;
    let getCapacityBuildingEditData;
    if (checkFPOSelect()) {
      setSelectedGAP(event.target.value);
      if (event.target.value !== 5) {
        getCapacityBuildingEditData = await axios.get(
          `${apiUrl}api/getAllDetailsCapacityBuildingGap?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&training_type_id=${event.target.value}&form_id=3`,
          getConfigWithToken()
        );
        if (getCapacityBuildingEditData.data["status"] === true) {
          getCapacityBuildingEditData.data["flag"]
            ? setCapacityBuildingFlagEdit(true)
            : setCapacityBuildingFlagEdit(false);
          const data = getCapacityBuildingEditData.data["data"][0];
          setEditInput1Files(getCapacityBuildingEditData.data["remarks"]);
          setcapacityBuildingIdEdit(
            getCapacityBuildingEditData.data["data"][0]["id"]
          );
        
          if (typeof data["magnet_crop_id"] === "string") {
            try {
              data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
                (e) => e.id
              );
            } catch (error) {
              console.error("Error parsing magnet_crop_id:", error);
              data["magnet_crop_id"] = [];
            }
          } else if (!Array.isArray(data["magnet_crop_id"])) {
            console.error(
              "magnet_crop_id is not a string or array:",
              data["magnet_crop_id"]
            );
            data["magnet_crop_id"] = [];
          }
          dispatch(updateCapacityBuildingForm(data));
        } else {
          setCapacityBuildingFlagEdit(false);
          dispatch(resetDataCapacityBuilding());
          const getDataFromBeneficiary = await axios.get(
            `${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&component_id=${event.target.value}&form_id=3`,
            getConfigWithToken()
          );
          const data = getDataFromBeneficiary.data["data"];
          if (data !== null) {
            if (typeof data["magnet_crop_id"] === "string") {
              try {
                data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
                  (e) => e.id
                );
              } catch (error) {
                console.error("Error parsing magnet_crop_id:", error);
                data["magnet_crop_id"] = [];
              }
            } else if (!Array.isArray(data["magnet_crop_id"])) {
              console.error(
                "magnet_crop_id is not a string or array:",
                data["magnet_crop_id"]
              );
              data["magnet_crop_id"] = [];
            }
            data["location_taluka"] = data["location"]["location_taluka_id"];
            dispatch(updateCapacityBuildingForm(data));
          }
        }
      }
    } else {
      dispatch(
        setInputFieldData({
          fieldName: "location_taluka",
          value: locationTaluka,
        })
      );
    }
  };

  const handleOptionsGAP5 = async (event, locationTaluka, date) => {
    if (checkFPOSelect()) {
      setSelectedGAP(5); // Set the selectedGAP to 5
      const getCapacityBuildingEditData = await axios.get(
        `${apiUrl}api/getAllDetailsCapacityBuildingGap?quarter=${selectQuarter}&beneficiary_id=${selectbeniFiApplicant}&training_type_id=5&form_id=3&location_taluka=${locationTaluka}&date=${date}`,
        getConfigWithToken()
      );
      if (getCapacityBuildingEditData.data["status"] === true) {
        getCapacityBuildingEditData.data["flag"]
          ? setCapacityBuildingFlagEdit(true)
          : setCapacityBuildingFlagEdit(false);
        const data = getCapacityBuildingEditData.data["data"][0];
        setEditInput1Files(getCapacityBuildingEditData.data["remarks"]);
        setcapacityBuildingIdEdit(
          getCapacityBuildingEditData.data["data"][0]["id"]
        );
        if (typeof data["magnet_crop_id"] === "string") {
          try {
            data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
              (e) => e.id
            );
          } catch (error) {
            console.error("Error parsing magnet_crop_id:", error);
            data["magnet_crop_id"] = [];
          }
        } else if (!Array.isArray(data["magnet_crop_id"])) {
          console.error(
            "magnet_crop_id is not a string or array:",
            data["magnet_crop_id"]
          );
          data["magnet_crop_id"] = [];
        }
        const formattedDate = new Date(data["date"]);
        data["date"] = formattedDate;

        data["location_taluka"] = locationTaluka;
        dispatch(updateCapacityBuildingForm(data));
      } else {
        setCapacityBuildingFlagEdit(false);
        dispatch(resetDataCapacityBuilding());
        const getDataFromBeneficiary = await axios.get(
          `${apiUrl}api/get_primarydata_by_beneficiary_componentid?beneficiary_id=${selectbeniFiApplicant}&component_id=${event.target.value}&form_id=3`,
          getConfigWithToken()
        );
        const data = getDataFromBeneficiary.data["data"];
        console.log(data, "else part");
        if (data !== null) {
          if (typeof data["magnet_crop_id"] === "string") {
            try {
              data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
                (e) => e.id
              );
            } catch (error) {
              console.error("Error parsing magnet_crop_id:", error);
              data["magnet_crop_id"] = [];
            }
          } else if (!Array.isArray(data["magnet_crop_id"])) {
            console.error(
              "magnet_crop_id is not a string or array:",
              data["magnet_crop_id"]
            );
            data["magnet_crop_id"] = [];
          }

          // const formattedDate = new Date(data["date"]);
          data["date"] = date;
          // console.log(formattedDate,'fromatted date else part')
          data["location_taluka"] = locationTaluka;

          console.log();
          dispatch(updateCapacityBuildingForm(data));
        }
      }
    }
  };

  const handleOptionsValueChain = async (event) => {
    setSelectKPMGDivision("");
    resetAllState();
    setSelectedValueChain(event.target.value);

  };

  const handleDivisionIdChange = (event) => {
    setSelectDivision(event.target.value);
    dispatch(resetDataTotTraining());
    refershdatafile();
    setTot_Magnet_Crop_Id([]);
  };

  const handleDivisionKMPGChange = async (event) => {
    resetAllState();
    setSelectKPMGDivision(event.target.value);
    try {
      const beneficiaryByFormDivision = await axios.get(
        `${apiUrl}api/get-beneficiary-by-divisionid?gap=1&division_id=${event.target.value}`,
        getConfigWithToken()
      );
      const studyFarm = beneficiaryByFormDivision.data.data[0].map((e) => ({
        id: e["beneficiary_id"],
        crop: e["beneficiary_name"],
      }));
      const studyFarm1 = beneficiaryByFormDivision.data.data[0].map((e) => ({
        id: e.beneficiary_id,
        crop: e.beneficiary_name,
      }));
      setStudyFarmApplicant(studyFarm);
    } catch (err) {}
  };

  const handleFieldChangeOBCMale = (value) => {
    dispatch(setInputFieldData({ fieldName: "beneficiary_id", value }));
  };

  const handleBenefiStudyTourFarm = async (beneficiary_id) => {
    setBeneStudyTourFarm(beneficiary_id);
    if (value === "9") {
      const getStudyTourEditData = await axios.get(
        `${apiUrl}api/f23-inputs-edit?quarter=${selectQuarter}&beneficiary_id=${beneficiary_id.join(
          ","
        )}&training_type_id=${selectedValueChain}&form_id=1`,
        getConfigWithToken()
      );
      if (getStudyTourEditData.data["status"] === true) {
        getStudyTourEditData.data["flag"]
          ? setValueChainFlagEdit(true)
          : setValueChainFlagEdit(false);
        const data = getStudyTourEditData.data["data"][0];
        setEditInput1Files(data["remarks"]);
        setValueChainIdEdit(data["id"]);
        dispatch(updateValueChainForm(data));
      } else {
        setEditInput1Files([]);
        setValueChainFlagEdit(false);
        dispatch(resetDataValueChain());
      }
    }
    if (value === "6") {
      const getStudyTourEditData = await axios.get(
        `${apiUrl}api/postHar-Cap-edit?quarter=${selectQuarter}&beneficiary_id=${beneficiary_id.join(
          ","
        )}&form_id=2`,
        getConfigWithToken()
      );
      if (getStudyTourEditData.data["status"] === true) {
        getStudyTourEditData.data["flag"]
          ? setPostHarvestFlagEdit(true)
          : setPostHarvestFlagEdit(false);
        const data = getStudyTourEditData.data["data"][0];
        data["magnet_crop_id"] = data["magnet_crop_id"].split(",");
        setEditInput1Files(data["remarks"]);
        setPostHarvestIdEdit(data["id"]);
        dispatch(updatePostHarvestForm(data));
      } else {
        setEditInput1Files([]);
        setPostHarvestFlagEdit(false);
        dispatch(resetDataPostHarvest());
      }
    }
  };

  // Change the API of Applicant with the Form Id and Beneficiary Id
  const handleApplicantDataByFormId = async (applicantId, form_id) => {
    try {
      const beneficiary_by_id = await axios.get(
        `${apiUrl}api/get_primarydata_by_benificiary?gap=1&beneficiary_id=${applicantId}&quarter=${selectQuarter}&form_id=${form_id}`,
        getConfigWithToken()
      );
      const data = await beneficiary_by_id.data["data"];
      data["magnet_crop_id"]
        ? (data["magnet_crop_id"] = JSON.parse(data["magnet_crop_id"]).map(
            (e) => e.id
          ))
        : (data["magnet_crop_id"] = []);
      setNurseryTissueBeneficiary(data);
      return await data;
    } catch (err) {}
  };

 

  const handleApplicantChangeESIFPO = async (event) => {
    setErrorSelectBeniApplicant("");
    setValueChainFlagEdit("")
    setSMELinkageFlagEdit("")
    setCapacityBuildingFlagEdit("")
    setPostHarvestFlagEdit("")
    setTotTrainingFlagEdit("")
    dispatch(resetDataCapacityBuilding({}));
    
    const applicantId = event.target.value;
    setSelectBeniApplicant(applicantId);
    setErrorSelectBeniApplicant("");
    setSelectedValueChain("");
    setSelectedGAP("");
    try {
    
      switch (value) {
        case "1":
          handleApplicantDataByFormId(applicantId, 3);
          break;
        case "5":
          handleApplicantDataByFormId(applicantId, 6);
          break;
        case "6":
          handleApplicantDataByFormId(applicantId, 2);
          break;
        case "7":
          handleApplicantDataByFormId(applicantId, 7);
          const smeLinkageEdit = await axios.get(
            `${apiUrl}api/getAllDetailsSme?quarter=${selectQuarter}&beneficiary_id=${applicantId}`,
            getConfigWithToken()
          );
          if (smeLinkageEdit.data["status"] === true) {
            smeLinkageEdit.data["flag"]
              ? setSMELinkageFlagEdit(true)
              : setSMELinkageFlagEdit(false);
            const data = smeLinkageEdit.data["data"][0];
            setSMELinkageIdEdit(data["id"]);
            dispatch(updateSMELinkageForm(data));
          } else {
            setSMELinkageFlagEdit(false);
            dispatch(resetDataSMELinkage());
          }
          break;
        case "8":
          handleApplicantDataByFormId(applicantId, 17);
          break;
        case "12":
          handleApplicantDataByFormId(applicantId, 23);
          break;
        case "13":
          handleApplicantDataByFormId(applicantId, 15);
          break;
        case "18":
          handleApplicantDataByFormId(applicantId, 16);
          break;
        case "19":
          handleApplicantDataByFormId(applicantId, 26);
          break;
      }


    } catch (error) {
      Swal.fire(`${error.message}`, "", "warning");
    }
  };

  const handleFieldAction = (value) => {
    dispatch(setInputFieldData({ fieldName: "action_points", value }));
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  const refershdatafile = () => {
    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const refershdata = () => {
    setSelectBeniApplicant(""); // Reset the FPO/VCO selection
    setSelectedGAP("");
    setFromDate("");
    setToDate("");
    setNurseryTissueBeneficiary({});
    setFromDate("");
    setToDate("");
    setNameOfThePerson("");
    setSelectDivision("");
    setTot_Magnet_Crop_Id([]);
    setCapacityActionPoint("");

    setSelectKPMGDivision("");
    setStudyFarmApplicant([]);
    setSelectBeniApplicant("");

    dispatch(resetDataCapacityBuilding({}));
    dispatch(resetDataTotTraining({}));
    dispatch(resetDataPostHarvest({}));
    dispatch(resetDataSMELinkage({}));
    dispatch(resetDataValueChain({}));

    setEditInput1Files([]);
    setSelectCapacityFile([]);
    setErrorCapacityFile("");
  };

  const handleQuarterChange = async (event) => {
    refershdata();
    setValueChainFlagEdit("")
    setSMELinkageFlagEdit("")
    setCapacityBuildingFlagEdit("")
    setPostHarvestFlagEdit("")
    setTotTrainingFlagEdit("")
    setSelectedQuarters(event.target.value);
  };

  const handlePostHarvest = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["postHarvest"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["postHarvest"];
      const errorMessages = {
        from_date: "From Date is a Required Field",
        to_date: "To Date is a Required Field",
        location_taluka: "Taluka is required",
        conducted_by_tot: "Conducted is required",
        magnet_crop_id: "Magnet Crop ID is required",
        participants_male: "Trainee Male is required",
        participants_female: "Trainee Female is required",
      };

      if (formData["conducted_by_tot"] === "Yes") {
        errorMessages["no_of_tots"] = "No of TOT is required";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsPostHarvest(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const postHarvest = dd["payload"]["postHarvest"];

        const formData = new FormData();
        for (const key in postHarvest) {
          if (key === "magnet_crop_id") {
            formData.append(
              "magnet_crop_id",
              postHarvest["magnet_crop_id"].join(",")
            );
          } else {
            formData.append(
              key,
              postHarvest[key] !== null ? postHarvest[key] : ""
            );
          }
        }

        formData.append("quarter", selectQuarter);
        formData.append("division_id", selectKPMGDivision);
        formData.append("beneficiary_id", beneStudyTourFarm);
        formData.append(
          "no_of_tots",
          postHarvest["no_of_tots"] ? postHarvest["no_of_tots"] : "0"
        );
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);
        formData.append("form_id", "2");
        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!postHarvestFlagEdit) {
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/postHar-Cap-create`,
              formData,
              getConfigWithToken()
            );
            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataPostHarvest());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData.append("id", postHarvestIdEdit);
            const submitPostHarvestData = await axios.post(
              `${apiUrl}api/postHar-Cap-update`,
              formData,
              getConfigWithToken()
            );

            if (submitPostHarvestData.data["status"] === true) {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataPostHarvest());
                setEditInput1Files([]);
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitPostHarvestData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleValueChainSubmit = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["valueChain"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["valueChain"];
      const errorMessages = {
        beneficiary_attended: "This field is required",
        location_taluka: "Taluka is required",
        location_address: "Address is required",
        resource_person: "Resource Person is required",
        action_points_recommendation_made: "Action points is required",
        obc_minority_open_male: "OBC Minority Open Male is required",
        obc_minority_open_female: "OBC Minority Open Female is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsValueChain(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const valueChain = dd["payload"]["valueChain"];

        const formData = new FormData();
        for (const key in valueChain) {
          formData.append(key, valueChain[key] !== null ? valueChain[key] : "");
        }

        formData.append("quarter", selectQuarter);
        formData.append("training_type_id", selectedValueChain);
        formData.append("division_id", selectKPMGDivision);
        formData.append("beneficiary_id", beneStudyTourFarm);
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);
        formData.append("form_id", "1");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!valueChainFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitWorkShopData = await axios.post(
              `${apiUrl}api/f23-inputs-create`,
              formData,
              getConfigWithToken()
            );
            if (submitWorkShopData.data["status"] === true) {
              Swal.fire(
                `${submitWorkShopData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataValueChain());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitWorkShopData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            formData.append("id", valueChainIdEdit);
            const submitWorkShopData = await axios.post(
              `${apiUrl}api/f23-inputs-update`,
              formData,
              getConfigWithToken()
            );
            if (submitWorkShopData.data["status"] === true) {
              Swal.fire(
                `${submitWorkShopData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataValueChain());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitWorkShopData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeleteGAP = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/capBuild-Gap-delete?id=${capacityBuildingIdEdit}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            dispatch(resetDataCapacityBuilding());
            setCapacityBuildingFlagEdit(false);
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeleteTOT = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/tot-inputs-delete?id=${totTrainingIdEdit}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            setTotTrainingFlagEdit("");
            dispatch(resetDataTotTraining());
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeletePHM = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/postHar-Cap-delete?id=${postHarvestIdEdit}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            setPostHarvestFlagEdit("");
            dispatch(resetDataPostHarvest());
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeleteSMELinkage = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/smelinkage-inputs-delete?id=${smeLinkageIdEdit}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            setSMELinkageFlagEdit("");
            dispatch(resetDataSMELinkage());
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleDeleteValueChain = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.get(
          `${apiUrl}api/f23-inputs-delete?id=${valueChainIdEdit}`,
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire(`${response.data.message}`, "", "success").then(() => {
            refershdata();
            setValueChainFlagEdit("");
            dispatch(resetDataValueChain());
          });
        } else {
          Swal.fire(`${response.data.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleCapacityBuildingSubmit = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["capacityBuilding"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["capacityBuilding"];
      const errorMessages = {
        magnet_crop_id: "Magnet Crop is required",
        location_taluka: "Loaction Taluka is Required",
        sc_male: "SC Male is required",
        sc_female: "SC Female is required",
        st_male: "ST Male is required",
        st_female: "ST Female is required",
        pwd_male: "PWD Male is required",
        pwd_female: "PWD Female is required",
        bpl_male: "BPL Male is required",
        bpl_female: "BPL Female is required",
        obc_minority_open_male: "OBC Minority Open Male is required",
        obc_minority_open_female: "OBC Minority Open Female is required",
        beneficiaries_male: "Beneficiaries Male is required",
        beneficiaries_female: "Beneficiaries Female is required",
      };

      if (selectedGAP === "5") {
        errorMessages["resource_person"] = "Resource Person is Required";
        errorMessages["topic_covered"] = "Topic Covered is Required";
        errorMessages["date"] = "Date is required";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
        console.log(value, "value");
        console.log(field, "field");
      }

      dispatch(setValidationErrorsCapacityBuilding(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    // Validate the form
    if (checkFPOSelect() && validateForm()) {
      try {
        setLoading(true);
        const capacityBuilding = dd["payload"]["capacityBuilding"];

        const formattedDate = capacityBuilding["date"]
          ? new Date(capacityBuilding["date"]).toISOString().split("T")[0]
          : null;

        const jsonMagnetCrop = capacityBuilding["magnet_crop_id"]
          .map((id) => {
            const matchingObject = magentCrop.find((item) => item.id === id);
            if (matchingObject) {
              return {
                id: matchingObject.id,
                name: matchingObject.crop,
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        const resultMagnetCrop = { data: jsonMagnetCrop };

        const formData = new FormData();
        for (const key in capacityBuilding) {
          formData.append(
            key,
            capacityBuilding[key] !== null ? capacityBuilding[key] : ""
          );
        }

        // Append formatted date to formData
        formData.append("date", formattedDate);

        // Append other form data
        formData.append("quarter", selectQuarter);
        formData.append("beneficiary_id", selectbeniFiApplicant);
        formData.append(
          "district_id",
          nurseryTissueBeneficiary.district.district_id
        );
        formData.append(
          "division_id",
          nurseryTissueBeneficiary.divisions.division_id
        );
        formData.append("taluka_id", nurseryTissueBeneficiary.taluka.taluka_id);
        formData.append(
          "beneficiary_type_id",
          nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id
        );
        formData.append("training_type_id", selectedGAP);
        formData.append("form_id", "3");
        formData.append("action_points", capacityActionPoint);
        formData.append(
          "magnet_crop_id",
          JSON.stringify(resultMagnetCrop["data"])
        );

        // Append file(s)
        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }

        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!capacityBuildingFlagEdit) {
            // Create operation
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitHighDensityData = await axios.post(
              `${apiUrl}api/capBuild-Gap-create`,
              formData,
              getConfigWithToken()
            );

            // Handle response
            if (submitHighDensityData.data["status"] === true) {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataCapacityBuilding());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // Update operation
            formData.append("id", capacityBuildingIdEdit);
            const submitHighDensityData = await axios.post(
              `${apiUrl}api/capBuild-Gap-update`,
              formData,
              getConfigWithToken()
            );

            // Handle response
            if (submitHighDensityData.data["status"] === true) {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataCapacityBuilding());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(
            "An error occurred while submitting WorkShop form",
            "Please try again later",
            "error"
          );
        }
      } catch (error) {
        Swal.fire(
          "An error occurred while submitting WorkShop form",
          "Please try again later",
          "error"
        );
      }
    }
  };

  const handleCapacityBuildingSubmit1 = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["capacityBuilding"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["capacityBuilding"];
      const errorMessages = {
        magnet_crop_id: "Magnet Crop is required",
        location_taluka: "Loaction Taluka is Required",
        sc_male: "SC Male is required",
        sc_female: "SC Female is required",
        st_male: "ST Male is required",
        st_female: "ST Female is required",
        pwd_male: "PWD Male is required",
        pwd_female: "PWD Female is required",
        bpl_male: "BPL Male is required",
        bpl_female: "BPL Female is required",
        obc_minority_open_male: "OBC Minority Open Male is required",
        obc_minority_open_female: "OBC Minority Open Female is required",
        beneficiaries_male: "Beneficiaries Male is required",
        beneficiaries_female: "Beneficiaries Female is required",
      };

      if (selectedGAP === "5") {
        errorMessages["resource_person"] = "Resource Person is Required";
        errorMessages["topic_covered"] = "Topic Covered is Required";
        errorMessages["date"] = "Date is required";
      }

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
        console.log(value, "value");
        console.log(field, "field");
      }

      dispatch(setValidationErrorsCapacityBuilding(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    // validateForm()

    if (checkFPOSelect() && validateForm()) {
      try {
        const capacityBuilding = dd["payload"]["capacityBuilding"];

        const jsonMagnetCrop = capacityBuilding["magnet_crop_id"]
          .map((id) => {
            const matchingObject = magentCrop.find((item) => item.id === id);
            if (matchingObject) {
              return {
                id: matchingObject.id,
                name: matchingObject.crop,
              };
            }
            return null; // Handle cases where there's no matching ID
          })
          .filter((item) => item !== null); // Remove any null entries
        const resultMagnetCrop = { data: jsonMagnetCrop };

        const formData = new FormData();
        for (const key in capacityBuilding) {
          formData.append(
            key,
            capacityBuilding[key] !== null ? capacityBuilding[key] : ""
          );
        }
        formData.append("quarter", selectQuarter);
        formData.append("beneficiary_id", selectbeniFiApplicant);
        formData.append(
          "district_id",
          nurseryTissueBeneficiary.district.district_id
        );
        formData.append(
          "division_id",
          nurseryTissueBeneficiary.divisions.division_id
        );
        formData.append("taluka_id", nurseryTissueBeneficiary.taluka.taluka_id);
        formData.append(
          "beneficiary_type_id",
          nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id
        );
        formData.append("training_type_id", selectedGAP);
        formData.append("form_id", "3");
        formData.append("action_points", capacityActionPoint);
        formData.append(
          "magnet_crop_id",
          JSON.stringify(resultMagnetCrop["data"])
        );

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          return;
        }

        try {
          if (!capacityBuildingFlagEdit) {
            // if(true){
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              return;
            }
            const submitHighDensityData = await axios.post(
              `${apiUrl}api/capBuild-Gap-create`,
              formData,
              getConfigWithToken()
            );
            if (submitHighDensityData.data["status"] === true) {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "success"
              );
              dispatch(resetDataCapacityBuilding());
            } else {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "warning"
              );
            }
          } else {
            // also have to set Id in object
            formData.append("id", capacityBuildingIdEdit);
            const submitHighDensityData = await axios.post(
              `${apiUrl}api/capBuild-Gap-update`,
              formData,
              getConfigWithToken()
            );

            if (submitHighDensityData.data["status"] === true) {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "success"
              );
              dispatch(resetDataCapacityBuilding());
            } else {
              Swal.fire(
                `${submitHighDensityData.data["message"]}`,
                "",
                "warning"
              );
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleSMELinkageSubmit = async (e) => {
    e.preventDefault();
    const formData = dd["payload"]["smeLinkage"];
    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["smeLinkage"];
      const errorMessages = {
        date: "Date is required",
        location_taluka: "Location Taluka is Required",
        location_address: "Location Address is Required",
        resource_person: "Resource Person is Required",
        topic_covered: "Topic Covered is Required",
        sc_male: "SC Male is required",
        sc_female: "SC Female is required",
        st_male: "ST Male is required",
        st_female: "ST Female is required",
        pwd_male: "PWD Male is required",
        pwd_female: "PWD Female is required",
        obc_minority_open_male: "OBC Minority Open Male is required",
        obc_minority_open_female: "OBC Minority Open Female is required",
        beneficiaries_male: "Beneficiaries Male is required",
        beneficiaries_female: "Beneficiaries Female is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsSMELinkage(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (checkFPOSelect() && validateForm()) {
      try {
        setLoading(true);
        const smeLinkage = dd["payload"]["smeLinkage"];

        const formData = {
          ...smeLinkage,
          quarter: selectQuarter,
          beneficiary_id: selectbeniFiApplicant,
          district_id: nurseryTissueBeneficiary.district.district_id,
          division_id: nurseryTissueBeneficiary.divisions.division_id,
          taluka_id: nurseryTissueBeneficiary.taluka.taluka_id,
          beneficiary_type_id:
            nurseryTissueBeneficiary.beneficiary_type.beneficiary_type_id,
          form_id: "7",
        };

        try {
          if (!smeLinkageFlagEdit) {
            // if(true){
            const submitSMELinkageData = await axios.post(
              `${apiUrl}api/smelinkage-inputs-create`,
              formData,
              getConfigWithToken()
            );
            if (submitSMELinkageData.data["status"] === true) {
              Swal.fire(
                `${submitSMELinkageData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataSMELinkage());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitSMELinkageData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          } else {
            // also have to set Id in object
            formData["id"] = smeLinkageIdEdit;
            const submitSMELinkageData = await axios.post(
              `${apiUrl}api/smelinkage-inputs-update`,
              formData,
              getConfigWithToken()
            );

            if (submitSMELinkageData.data["status"] === true) {
              Swal.fire(
                `${submitSMELinkageData.data["message"]}`,
                "",
                "success"
              ).then(() => {
                dispatch(resetDataSMELinkage());
                setLoading(false);
              });
            } else {
              Swal.fire(
                `${submitSMELinkageData.data["message"]}`,
                "",
                "warning"
              ).then(() => {
                setLoading(false);
              });
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  const handleTrainingOfTrainerSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      const errors = {};
      const formData = dd["payload"]["totTraining"];
      const errorMessages = {
        from_date: "From Date is a Required Field",
        to_date: "To Date is a Required Field",
        location_taluka: "Taluka is required",
        location_address: "Address is required",
        name_of_organization: "Name of Organisation is required",
        nomination_by_division: "Nomination of Division is required",
        participants_male: "Participant Male is required",
        participants_female: "Participant Female is required",
      };

      for (const field in errorMessages) {
        const value = formData[field];
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          errors[field] = errorMessages[field];
        }
      }
      dispatch(setValidationErrorsTotTraining(errors));
      // Return true if the form is valid, otherwise return false
      return Object.keys(errors).length === 0;
    };

    if (validateForm()) {
      try {
        setLoading(true);
        const totTraining = dd["payload"]["totTraining"];

        const formData = new FormData();
        for (const key in totTraining) {
          if (key === "nomination_by_division") {
            formData.append(key, totTraining[key].join(","));
          } else {
            formData.append(
              key,
              totTraining[key] !== null ? totTraining[key] : ""
            );
          }
        }

        formData.append("quarter", selectQuarter);
        formData.append("division_id", selectDivision);
        formData.append("name_of_the_person", nameOfThePerson);
        formData.append("magnet_crop_id", tot_Magnet_crop_id.join(","));
        formData.append("form_id", "23");

        let size = 0;
        const maxSize = 5 * 1024 * 1024;
        if (selectCapacityFile.length !== 0) {
          for (let i = 0; i < selectCapacityFile.length; i++) {
            size += selectCapacityFile[i].size;
            formData.append("remarks[]", selectCapacityFile[i]);
          }
        }
        if (size >= maxSize) {
          setErrorCapacityFile("File size should be less than 5 MB");
          setLoading(false);
          return;
        }

        try {
          if (!totTrainingFlagEdit) {
            if (selectCapacityFile.length === 0) {
              setErrorCapacityFile("Please Select File");
              setLoading(false);
              return;
            }
            const submitToTData = await axios.post(
              `${apiUrl}api/tot-inputs-create`,
              formData,
              getConfigWithToken()
            );
            if (submitToTData.data["status"] === true) {
              Swal.fire(`${submitToTData.data["message"]}`, "", "success").then(
                () => {
                  dispatch(resetDataTotTraining());
                  setLoading(false);
                }
              );
            } else {
              Swal.fire(`${submitToTData.data["message"]}`, "", "warning").then(
                () => {
                  setLoading(false);
                }
              );
            }
          } else {
            // also have to set Id in object
            formData.append("id", totTrainingIdEdit);
            const submitToTData = await axios.post(
              `${apiUrl}api/tot-inputs-update`,
              formData,
              getConfigWithToken()
            );
            if (submitToTData.data["status"] === true) {
              Swal.fire(`${submitToTData.data["message"]}`, "", "success").then(
                () => {
                  dispatch(resetDataTotTraining());
                  setEditInput1Files([]);
                  setLoading(false);
                }
              );
            } else {
              Swal.fire(`${submitToTData.data["message"]}`, "", "warning").then(
                () => {
                  setLoading(false);
                }
              );
            }
          }
        } catch (error) {
          Swal.fire(`${error.message}`, "", "warning");
        }
      } catch (error) {
        Swal.fire(`${error.message}`, "", "warning");
      }
    }
  };

  useEffect(() => {
    const quarterData = async () => {
      try {
        const storedArrayAsString = localStorage.getItem("permission");
        setPermission(storedArrayAsString);

        const applicant = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?all=1&gap=1`,
          getConfigWithToken()
        );

        const val = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=1`,
          getConfigWithToken()
        );
        const gap = await axios.get(
          `${apiUrl}api/typeByCategory?category_id=3`,
          getConfigWithToken()
        );

        const applicantData = applicant.data["data"].map((e) => ({
          id: e["id"],
          beneficiary_name: e["beneficiary_name"],
        }));
        const studyFarm = applicant.data["data"].map((e) => ({
          id: e["id"],
          crop: e["beneficiary_name"],
        }));

        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setlocationTaluka(taluka.data["data"]);

        setStudyFarmApplicant(studyFarm);
        setQuarter(quarter);
        const selectedValues = quarter
          .filter((item) => item.selected === "Selected")
          .map((item) => item.id);
        setSelectedQuarters(selectedValues[0]);
        setValueChain(val.data.data[0]);
        setGAPPH1(gap.data.data[0]);
        console.log(magentCrp);
        setMagentCrop(magentCrp);
        setBeniApplicant(applicantData); 
        setDivision(divisi);
        setloadingg(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    quarterData();

    setloadingg(false);
  }, [quarter, magentCrp]);


  if (loadingg) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
    
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div id="exTab3" class="contain">
              <div class="card">
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    bgcolor: "background.paper",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        value={value}
                        onChange={handleChange}
                        sx={{ width: "100%", overflowX: "auto" }}
                      >
                        <Tab
                          label="Capacity Building on GAP"
                          value="1"
                          className={`${value === "1" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Training of Trainers (ToT) - 5 Days"
                          value="12"
                          className={`${
                            value === "12" ? "activeClickCSS" : ""
                          }`}
                        />
                        <Tab
                          label="Capacity building on post-harvest management"
                          value="6"
                          className={`${value === "6" ? "activeClickCSS" : ""}`}
                        />
                        <Tab
                          label="Capacity building on SME Linkages"
                          value="7"
                          className={`${value === "7" ? "activeClickCSS" : ""}`}
                        />

                        <Tab
                          label="ValueChain acceleration Services"
                          value="9"
                          className={`${value === "9" ? "activeClickCSS" : ""}`}
                        />
                      </Tabs>
                    </Box>

                    {/*  Capacity Building on GAP  */}
                    <TabPanel value="1">
                      <div className="tab-pane">
                        <form
                          className="row g-3"
                          onSubmit={handleCapacityBuildingSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectbeniFiApplicant}
                                  label="FPO/VCO"
                                  onChange={handleApplicantChangeESIFPO}
                                >
                                  {beniFiApplicant.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["beneficiary_name"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Division"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.divisions
                                ? nurseryTissueBeneficiary.divisions[
                                    "divisions"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="District"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.district
                                ? nurseryTissueBeneficiary.district[
                                    "district"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Taluka"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.taluka
                                ? nurseryTissueBeneficiary.taluka[
                                    "taluka"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Beneficiary Type"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.beneficiary_type
                                ? nurseryTissueBeneficiary.beneficiary_type[
                                    "beneficiary_type"
                                  ].toString()
                                : ""
                            }
                          />

                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              {selectedGAP === 5 || selectedGAP === ""
                                ? "Details for"
                                : "Project Wise Information"}{" "}
                              -{" "}
                              {selectedGAP &&
                                GAPPH1.find((e) => e["id"] === selectedGAP)
                                  .type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedGAP}
                                    onChange={handleOptionsGAP}
                                    label="Trainin"
                                  >
                                    {GAPPH1.map((option) => (
                                      <MenuItem value={option.id}>
                                        {option.type}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>

                          <div>
                            {selectedGAP && (
                              <GapTraining
                                magentCrop={magentCrop}
                                compValue={selectedGAP}
                                handleOptionsGAP5={handleOptionsGAP5}
                                resetdata={refershdatafile}
                              />
                            )}
                          </div>

                          {selectedGAP && (
                            <>
                             
                              <InputField
                                col="4"
                                label="Action points/ Recommendation made"
                                type="text"
                                value={capacityActionPoint}
                                onChange={handleFieldChangeActionPoints}
                              />

                              <div
                                class="col-md-6"
                                style={{
                                  position: "relative",
                                  right: "15px",
                                  bottom: "10px",
                                }}
                              >
                                <label
                                  for="inputNumber"
                                  class="col-lg col-form-label"
                                >
                                  Choose File- upload GAP Report/Photos
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    class="form-control"
                                    type="file"
                                    multiple
                                    id="formFile"
                                    onChange={handleMultiFilesGAPChange}
                                    onFocus={() => {
                                      setErrorCapacityFile("");
                                    }}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errorCapacityFile}
                                  </span>
                                </div>
                              </div>
                              <div>
                                {(editInput1Files || []).map((e) => (
                                  <div className="icon-container">
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      class="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleDeleteCapacityFile(e.id)
                                      }
                                    ></i>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                         
                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Cap-Building-GAP-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  className="btn submitbtn"
                                  disabled={loading}
                                >
                                  {capacityBuildingFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {capacityBuildingFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    onClick={handleDeleteGAP}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* 1.(2) TOT */}
                    <TabPanel value="12">
                      <div className="tab-pane">
                        <form
                          class="row g-3"
                          onSubmit={handleTrainingOfTrainerSubmit}
                        >
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>
                              Trainer training (ToT) GAP & PH- Input Records
                            </span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <InputField
                            label="Name of the Person"
                            type="text"
                            value={nameOfThePerson}
                            onChange={handleFieldNameOfPerson}
                          />
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectDivision}
                                  onChange={handleDivisionIdChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <MultiSelect
                              label="MAGNET Crops"
                              data={magentCrop}
                              value={tot_Magnet_crop_id}
                              onChange={handleTotMagnetCrop}
                            />
                          </div>
                          <TotTraining />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Upload supporting document. (e.g QPR, Photos
                                etc.)
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>
                            <div>
                              {editInput1Files &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container">
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      class="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleDeleteCapacityFile(e.id)
                                      }
                                    ></i>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {permission.includes("TOT-Inp-One-create-edit") ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {totTrainingFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {totTrainingFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    onClick={handleDeleteTOT}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Capacity building on post-harvest management */}
                    <TabPanel value="6">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handlePostHarvest}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of - PH
                              training for 3 days
                            </span>
                          </div>
                          <br />
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectKPMGDivision}
                                  onChange={handleDivisionKMPGChange}
                                  label="Division"
                                >
                                  {division.map((e) => (
                                    <MenuItem value={e.id}>
                                      {e.divisions}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="beneficiary-select-label">
                                  Organization Name
                                </InputLabel>
                                <Select
                                  labelId="beneficiary-select-label"
                                  id="beneficiary-select"
                                  multiple
                                  value={beneStudyTourFarm}
                                  onChange={handleFPOVCOUtilizationChange}
                                  label="organization name"
                                  renderValue={(selected) =>
                                    selected
                                      .map((value) => {
                                        const selectedBeneficiary =
                                          studyFarmApplicant.find(
                                            (e) => e.id === value
                                          );
                                        return selectedBeneficiary
                                          ? selectedBeneficiary.crop
                                          : "";
                                      })
                                      .join(", ")
                                  }
                                >
                                  {studyFarmApplicant.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                      {e.crop}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <DatePick
                            label="From Date"
                            className="custom-date-picker"
                            disbrushDate={fromDate}
                            onChange={handleFromDateChange}
                          />
                          <DatePick
                            label="To Date"
                            className="custom-date-picker"
                            disbrushDate={toDate}
                            onChange={handleToDateChange}
                          />
                          <PHMtraining magentCrop={magentCrop} />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              class="col-md-6"
                              style={{
                                position: "relative",
                                right: "15px",
                                bottom: "10px",
                              }}
                            >
                              <label
                                for="inputNumber"
                                class="col-lg col-form-label"
                              >
                                Upload supporting document. (e.g QPR, Photos
                                etc.)
                              </label>
                              <div class="col-sm-10">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="formFile"
                                  name="remarks"
                                  multiple
                                  onChange={handleMultiFilesGAPChange}
                                  onFocus={() => {
                                    setErrorCapacityFile("");
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {errorCapacityFile}
                                </span>
                              </div>
                            </div>
                            <div>
                              {editInput1Files &&
                                editInput1Files.map((e) => (
                                  <div className="icon-container">
                                    <a
                                      href={`${fileUrl}${e.file_name}`}
                                      rel="noreferrer"
                                      target="_blank"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={e.type === "pdf" ? pdf : imgIcon}
                                        alt=""
                                        height="30px"
                                        width="26px"
                                      />
                                    </a>
                                    <i
                                      class="fa-regular fa-circle-xmark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleDeleteCapacityFile(e.id)
                                      }
                                    ></i>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Post-Harvest-Manag-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {postHarvestFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {postHarvestFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    onClick={handleDeletePHM}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* Lead firm SME linkages */}
                    <TabPanel value="7">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleSMELinkageSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div className="word-with-line">
                            <span>Beneficiary Information</span>
                            <i
                              class="fa-solid fa-angle-down"
                              style={{ color: "#4e2683" }}
                            ></i>
                          </div>
                          <div className="col-md-4">
                            <Box sx={{ minWidth: "100%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errorSelectBeniApplicant !== "" ? true : false
                                }
                              >
                                <InputLabel htmlFor="demo-simple-select-label">
                                  FPO/VCO
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectbeniFiApplicant}
                                  label="FPO/VCO"
                                  onChange={handleApplicantChangeESIFPO}
                                >
                                  {beniFiApplicant.map((e) => (
                                    <MenuItem value={e.id} key={e.id}>
                                      {e["beneficiary_name"]}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorSelectBeniApplicant}
                                </FormHelperText>
                              </FormControl>
                            </Box>
                          </div>
                          <InputField
                            label="Division"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.divisions
                                ? nurseryTissueBeneficiary.divisions[
                                    "divisions"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="District"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.district
                                ? nurseryTissueBeneficiary.district[
                                    "district"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Taluka"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.taluka
                                ? nurseryTissueBeneficiary.taluka[
                                    "taluka"
                                  ].toString()
                                : ""
                            }
                          />
                          <InputField
                            label="Beneficiary Type"
                            type="text"
                            readOnly={true}
                            value={
                              nurseryTissueBeneficiary &&
                              nurseryTissueBeneficiary.beneficiary_type
                                ? nurseryTissueBeneficiary.beneficiary_type[
                                    "beneficiary_type"
                                  ].toString()
                                : ""
                            }
                          />
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details for - Capacity building on SME Linkages
                            </span>
                          </div>

                          <SMELinkage />

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "SME-Linkages-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {smeLinkageFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {smeLinkageFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    onClick={handleDeleteSMELinkage}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>

                    {/* ValueChain acceleration Services */}
                    <TabPanel value="9">
                      <div className="tab-pane">
                        <form class="row g-3" onSubmit={handleValueChainSubmit}>
                          <div className="quarterSelect">
                            <div className="support"></div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <Box
                                sx={{ minWidth: "100%" }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel htmlFor="demo-simple-select-label">
                                    Quarter
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectQuarter}
                                    label="Quarter"
                                    onChange={handleQuarterChange}
                                  >
                                    {quarters.map((e, key) => (
                                      <MenuItem value={e.id} key={key}>
                                        {e.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <div
                            className="word-with-line"
                            style={{ margin: "1.5rem 0" }}
                          >
                            <span>
                              Details of trainings for Current Quarter of -{" "}
                              {selectedValueChain &&
                                ValueChain.find(
                                  (e) => e["id"] === selectedValueChain
                                ).type}
                            </span>
                            <div>
                              <Box
                                sx={{ minWidth: 390 }}
                                style={{ backgroundColor: "#ededed" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Training Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValueChain}
                                    onChange={handleOptionsValueChain}
                                    label="Trainin"
                                  >
                                    {ValueChain.map((e) => (
                                      <MenuItem value={e.id}>{e.type}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          </div>
                          <br />

                          <div>
                            {selectedValueChain && (
                              <>
                                <div
                                  class="row g-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Division
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectKPMGDivision}
                                          onChange={handleDivisionKMPGChange}
                                          label="Division"
                                        >
                                          {division.map((e) => (
                                            <MenuItem value={e.id}>
                                              {e.divisions}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>

                                  <div
                                    className="col-md-4"
                                    style={{ paddingRight: 0 }}
                                  >
                                    <Box sx={{ minWidth: "100%" }}>
                                      <FormControl fullWidth>
                                        <InputLabel id="beneficiary-select-label">
                                          Organization Name
                                        </InputLabel>
                                        <Select
                                          labelId="beneficiary-select-label"
                                          id="beneficiary-select"
                                          multiple
                                          value={beneStudyTourFarm}
                                          onChange={
                                            handleFPOVCOUtilizationChange
                                          }
                                          label="organization name"
                                          renderValue={(selected) =>
                                            selected
                                              .map((value) => {
                                                const selectedBeneficiary =
                                                  studyFarmApplicant.find(
                                                    (e) => e.id === value
                                                  );
                                                return selectedBeneficiary
                                                  ? selectedBeneficiary.crop
                                                  : "";
                                              })
                                              .join(", ")
                                          }
                                        >
                                          {studyFarmApplicant.map((e) => (
                                            <MenuItem key={e.id} value={e.id}>
                                              {e.crop}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>

                                  <DatePick
                                    label="From Date"
                                    className="custom-date-picker"
                                    disbrushDate={fromDate}
                                    onChange={handleFromDateChange}
                                  />
                                  <DatePick
                                    label="To Date"
                                    className="custom-date-picker"
                                    disbrushDate={toDate}
                                    onChange={handleToDateChange}
                                  />
                                  <WomenLedtraining magentCrop={magentCrop} />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      class="col-md-6"
                                      style={{
                                        position: "relative",
                                        right: "25px",
                                      }}
                                    >
                                      <label
                                        for="inputNumber"
                                        class="col-lg col-form-label"
                                      >
                                        Upload supporting document. (e.g QPR,
                                        Photos etc.)
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          class="form-control"
                                          type="file"
                                          id="formFile"
                                          name="remarks"
                                          multiple
                                          onChange={handleMultiFilesGAPChange}
                                          onFocus={() => {
                                            setErrorCapacityFile("");
                                          }}
                                        />
                                        <span style={{ color: "red" }}>
                                          {errorCapacityFile}
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      {editInput1Files &&
                                        editInput1Files.map((e) => (
                                          <div className="icon-container">
                                            <a
                                              href={`${fileUrl}${e.file_name}`}
                                              rel="noreferrer"
                                              target="_blank"
                                              style={{ marginRight: "10px" }}
                                            >
                                              <img
                                                src={
                                                  e.type === "pdf"
                                                    ? pdf
                                                    : imgIcon
                                                }
                                                alt=""
                                                height="30px"
                                                width="26px"
                                              />
                                            </a>
                                            <i
                                              class="fa-regular fa-circle-xmark"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleDeleteCapacityFile(e.id)
                                              }
                                            ></i>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div style={{ textAlign: "center" }}>
                            {permission.includes(
                              "Value-Chain-Inp-One-create-edit"
                            ) ? (
                              <>
                                <button
                                  type="submit"
                                  class="btn submitbtn"
                                  disabled={loading}
                                >
                                  {valueChainFlagEdit === true
                                    ? "Update"
                                    : "Submit"}
                                </button>
                                {valueChainFlagEdit === true && (
                                  <button
                                    type="button"
                                    className="btn submitbtn"
                                    onClick={handleDeleteValueChain}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
