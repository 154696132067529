import "../assets/css/LoginPage.css";
import logo from "../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import { useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import validator from "validator"; // Import the validator.js library
import { isAuthenticated } from "../utils/auth";
import { useDispatch } from "react-redux";
import { getConfigWithToken } from "../utils/Config/Config";

export function LoginPage() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (!email || !password) {
      setErrorMessage("Both fields are required.");
    } else if (!isValidEmail(email)) {
      setErrorMessage("Wrong email.");
    } else {
      try {
        const response = await axios.post(`${apiUrl}api/login`, {
          email: email,
          password: password,
        });

        
        if (!response.data.status) {
          // Handle error message returned from the server
          const errorMessage = response.data.error
            ? response.data.error.message
            : "An error occurred while logging in.";
          setErrorMessage(errorMessage);
          return;
        }
        const { token, status, data } = response.data;

        // Set the token in your session storage or state as needed
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", status);
        localStorage.setItem("name", data.name);
        localStorage.setItem("userType", data.usertype);
        localStorage.setItem("mobile", data.mobile);
        localStorage.setItem("email", data.email);
        localStorage.setItem("id", data.id);

        setErrorMessage("");
        const permission = await axios.get(
          `${apiUrl}api/role-wise-permission?id=${data.role}`,
          getConfigWithToken()
        );
        console.log(permission.data.data, "kjndskjdsn");
        const permissionNames = permission.data.data.permissions.map(
          (permission) => permission.name
        );
      
        localStorage.setItem("permission", permissionNames);

        navigate("/dashboard");
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("An error occurred while logging in.");
      }
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, []);

  const isValidEmail = (email) => {
    return validator.isEmail(email); // Use validator.isEmail for email validation
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container" style={{ height: "max-content" }}>
        <div className="form-group" style={{ margin: 0 }}>
          <img src={logo} alt="Logo" height="100px" />
          <h3>Login</h3>
          <div className="email">
            <TextField
              id="Email"
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="pass">
            <TextField
              id="Password"
              label="Password"
              name="password"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              fullWidth
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
        </div>
        {errorMessage && (
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
            style={{ margin: "8px 0" }}
          >
            {errorMessage}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
       
        <div className="checkbox">
          <Link to="/register">Are you a new user? Register here</Link>
        </div>
        <div className="checkbox">
          <Link to="/forgotPassword">ForgotPassword?</Link>
        </div>
        <button className="login-button" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
