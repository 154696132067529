import React, { useEffect, useState } from "react";
import { InputField } from "../../InputField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import {
  setInputFieldData,
  resetValidation,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/PostHarvestTrainingSlice/PostHarvestSlice";
import { Box, FormHelperText } from "@mui/material";
import { MultiSelect } from "../../MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";

export function PHMtraining(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const postHarvest = useSelector((state) => state.postHarvest);
  const [taluka, setTaluka] = useState([]);

  useEffect(() => {
    const workData = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    workData();
  }, []);

  const totalParticipants =
    parseInt(
      postHarvest.participants_male === "" ? 0 : postHarvest.participants_male
    ) +
    parseInt(
      postHarvest.participants_female === ""
        ? 0
        : postHarvest.participants_female
    );
  dispatch(setTotalParticipants(totalParticipants));

  // Dispatch functions for Participants fields
  

  const handleFieldNoOfTot = (value) => {
    dispatch(setInputFieldData({ fieldName: "no_of_tots", value }));
  };
  const handleFieldNoOfBeneAttend = (value) => {
    dispatch(setInputFieldData({ fieldName: "beneficiary_attended", value }));
  };
  const handleFieldConductedByTot = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "conducted_by_tot",
        value: event.target.value,
      })
    );
  };

  const handleOptionstaluka = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: event.target.value,
      })
    );
  };

  const handleMultiMagnetCropChange = (data) => {
    dispatch(setInputFieldData({ fieldName: "magnet_crop_id", value: data }));
  };

 

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}
     
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <Box sx={{ minWidth: "100%" }}>
          <FormControl
            fullWidth
            error={
              postHarvest.validationErrors.location_taluka !== "" ? true : false
            }
          >
            <InputLabel htmlFor="demo-simple-select-label">
              Location (Taluka)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={postHarvest.location_taluka}
              label="Location (Taluka)"
              onChange={handleOptionstaluka}
            >
              {taluka.map((e, key) => (
                <MenuItem value={e.id} key={key}>
                  {e.taluka}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: "#d32f2f" }}>
            {postHarvest.validationErrors.location_taluka}
          </FormHelperText>
        </Box>
      </div>
      <div className="col-md-4">
        <Box>
          <FormControl
            fullWidth
            error={
              postHarvest.validationErrors.conducted_by_tot !== ""
                ? true
                : false
            }
          >
            <InputLabel htmlFor="demo-simple-select-label">
              Conducted by TOT
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={postHarvest.conducted_by_tot}
              label="Conducted by TOT"
              onChange={handleFieldConductedByTot}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
            <FormHelperText style={{ color: "#d32f2f" }}>
              {postHarvest.validationErrors.conducted_by_tot}
            </FormHelperText>
          </FormControl>
        </Box>
      </div>
      <InputField
        label="No. of TOT"
        type="number"
        readOnly={postHarvest.conducted_by_tot === "Yes" ? false : true}
        value={postHarvest.no_of_tots}
        onChange={handleFieldNoOfTot}
        onFocus={handleResetFocus}
        error={!!postHarvest.validationErrors.no_of_tots}
        helperText={postHarvest.validationErrors.no_of_tots}
      />
      <div className="col-md-4" style={{ paddingRight: 0 }}>
        <MultiSelect
          label="MAGNET Crops"
          data={props.magentCrop}
          errorText={
            postHarvest.validationErrors.magnet_crop_id.length !== 0
              ? true
              : false
          }
          value={postHarvest.magnet_crop_id}
          onChange={handleMultiMagnetCropChange}
        />
      </div>
      <InputField
        label="No. of Beneficiaries attended"
        type="number"
        value={postHarvest.beneficiary_attended}
        onChange={handleFieldNoOfBeneAttend}
        onFocus={handleResetFocus}
        error={!!postHarvest.validationErrors.beneficiary_attended}
        helperText={postHarvest.validationErrors.beneficiary_attended}
      />
      <div className="word-with-line"></div>

      <div className="col-md-3 input1Heading">
        <span>Participants</span>
      </div>
      <InputField
        col="3"
        label="Male"
        type="number"
        value={postHarvest.participants_male}
        onChange={(value)=>handleInputChange("participants_male",value)}
        onFocus={handleResetFocus}
        error={!!postHarvest.validationErrors.participants_male}
        helperText={postHarvest.validationErrors.participants_male}
      />
      <InputField
        col="3"
        label="Female"
        type="number"
        value={postHarvest.participants_female}
        onChange={(value)=>handleInputChange("participants_female",value)}
        onFocus={handleResetFocus}
        error={!!postHarvest.validationErrors.participants_male}
        helperText={postHarvest.validationErrors.participants_male}
      />
      <InputField
        col="3"
        label="Total Participants"
        type="number"
        value={postHarvest.participants_total}
        readOnly={true}
      />
    </>
  );
}
