import { InputField } from "../../InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetValidation,
  setInputFieldData,
  setTotalSC,
  setTotalFullMaleEmp,
  setTotalFullFemaleEmp,
  setTotalFullTotalEmp,
  setTotalST,
  setTotalPWD,
  setTotalOBC,
  setTotalOperGenMaleEmp,
  setTotalOperGenFemaleEmp,
  setTotalOperGenTotalEmp,
} from "../../../redux/slice/InputTwo/OperGenInputTwo/FullTimeOperSlice";
import "../../../assets/css/inputForm.css";

export function FullTimeOper() {
  const [checkSet, setCheckSet] = useState(false);
  const dispatch = useDispatch();
  const fullTimeFormOper = useSelector((state) => state.fullTimeOperEmp);
  const partTimeForm = useSelector((state) => state.partTimeOperEmp);

  // Total of SC
  const totalSC =
    parseInt(fullTimeFormOper.sc.male === "" ? 0 : fullTimeFormOper.sc.male) +
    parseInt(
      fullTimeFormOper.sc.female === "" ? 0 : fullTimeFormOper.sc.female
    );
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(fullTimeFormOper.st.male === "" ? 0 : fullTimeFormOper.sc.male) +
    parseInt(
      fullTimeFormOper.st.female === "" ? 0 : fullTimeFormOper.st.female
    );
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(fullTimeFormOper.pwd.male === "" ? 0 : fullTimeFormOper.pwd.male) +
    parseInt(
      fullTimeFormOper.pwd.female === "" ? 0 : fullTimeFormOper.pwd.female
    );
  dispatch(setTotalPWD(totalPWD));
 
  // // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      fullTimeFormOper.obcMinorityOpen.male === ""
        ? 0
        : fullTimeFormOper.obcMinorityOpen.male
    ) +
    parseInt(
      fullTimeFormOper.obcMinorityOpen.female === ""
        ? 0
        : fullTimeFormOper.obcMinorityOpen.female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));
  // Total of NumFullTimeEmployment
  const totalFullTimeEmpMale =
    parseInt(fullTimeFormOper.sc.male === "" ? 0 : fullTimeFormOper.sc.male) +
    parseInt(fullTimeFormOper.st.male === "" ? 0 : fullTimeFormOper.sc.male) +
    parseInt(
      fullTimeFormOper.obcMinorityOpen.male === ""
        ? 0
        : fullTimeFormOper.obcMinorityOpen.male
    );
  dispatch(setTotalFullMaleEmp(totalFullTimeEmpMale));

  const totalFullTimeEmpFemale =
    parseInt(
      fullTimeFormOper.sc.female === "" ? 0 : fullTimeFormOper.sc.female
    ) +
    parseInt(
      fullTimeFormOper.st.female === "" ? 0 : fullTimeFormOper.st.female
    ) +
    parseInt(
      fullTimeFormOper.obcMinorityOpen.female === ""
        ? 0
        : fullTimeFormOper.obcMinorityOpen.female
    );
  dispatch(setTotalFullFemaleEmp(totalFullTimeEmpFemale));

  const totalFullTimeEmpTotal =
    parseInt(fullTimeFormOper.sc.total === "" ? 0 : fullTimeFormOper.sc.total) +
    parseInt(fullTimeFormOper.st.total === "" ? 0 : fullTimeFormOper.st.total) +
    parseInt(
      fullTimeFormOper.obcMinorityOpen.total === ""
        ? 0
        : fullTimeFormOper.obcMinorityOpen.total
    );
  dispatch(setTotalFullTotalEmp(totalFullTimeEmpTotal));

  if (checkSet) {
    const totalGenEmpMale =
      parseInt(
        fullTimeFormOper.numFullTimeEmployment.totalMale === ""
          ? 0
          : fullTimeFormOper.numFullTimeEmployment.totalMale
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalMale === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalMale
      );
    dispatch(setTotalOperGenMaleEmp(totalGenEmpMale));
    const totalGenEmpFemale =
      parseInt(
        fullTimeFormOper.numFullTimeEmployment.totalFemale === ""
          ? 0
          : fullTimeFormOper.numFullTimeEmployment.totalFemale
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalFemale === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalFemale
      );
    dispatch(setTotalOperGenFemaleEmp(totalGenEmpFemale));
    const totalGenEmpTotal =
      parseInt(
        fullTimeFormOper.numFullTimeEmployment.totalMF === ""
          ? 0
          : fullTimeFormOper.numFullTimeEmployment.totalMF
      ) +
      parseInt(
        partTimeForm.numFullTimeEmployment.totalMF === ""
          ? 0
          : partTimeForm.numFullTimeEmployment.totalMF
      );
    dispatch(setTotalOperGenTotalEmp(totalGenEmpTotal));
  }

  // Dispatch functions for Total Employment Generated fields
  const handleFieldChangeFullMale = (value) => {
    dispatch(
      setInputFieldData({
        category: "numFullTimeEmployment",
        field: "male",
        value,
      })
    );
  };
  const handleFieldChangeFullFemale = (value) => {
    dispatch(
      setInputFieldData({
        category: "numFullTimeEmployment",
        field: "female",
        value,
      })
    );
  };

  const handlePositiveNumberChange = (value) => {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      return numericValue;
    } else {
      return ""; // Or you can return 0 or any other default value
    }
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleFieldChange = (category, field, value) => {
    setCheckSet(true);
    const newValue = handlePositiveNumberChange(value);
    dispatch(setInputFieldData({ category, field, value: newValue }));
  };

  return (
    <>
      <div class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeFormOper.sc.male}
          onChange={(value) => handleFieldChange("sc", "male", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.sc_male}
          helperText={fullTimeFormOper.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeFormOper.sc.female}
          onChange={(value) => handleFieldChange("sc", "female", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.sc_female}
          helperText={fullTimeFormOper.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={fullTimeFormOper.sc.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeFormOper.st.male}
          onChange={(value) => handleFieldChange("st", "male", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.st_male}
          helperText={fullTimeFormOper.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeFormOper.st.female}
          onChange={(value) => handleFieldChange("st", "female", value)}
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.st_female}
          helperText={fullTimeFormOper.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={fullTimeFormOper.st.total}
          readOnly={true}
        />

      
        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={fullTimeFormOper.obcMinorityOpen.male}
          onChange={(value) =>
            handleFieldChange("obcMinorityOpen", "male", value)
          }
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.obcMinorityOpen_male}
          helperText={fullTimeFormOper.validationErrors.obcMinorityOpen_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={fullTimeFormOper.obcMinorityOpen.female}
          onChange={(value) =>
            handleFieldChange("obcMinorityOpen", "female", value)
          }
          onFocus={handleResetFocus}
          error={!!fullTimeFormOper.validationErrors.obcMinorityOpen_female}
          helperText={fullTimeFormOper.validationErrors.obcMinorityOpen_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={fullTimeFormOper.obcMinorityOpen.total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>No. of Full Time Employment</span>
        </div>
        <InputField
          col="3"
          label="Total  Male"
          type="number"
          value={fullTimeFormOper.numFullTimeEmployment.totalMale}
          onChange={handleFieldChangeFullMale}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={fullTimeFormOper.numFullTimeEmployment.totalFemale}
          onChange={handleFieldChangeFullFemale}
        />
        <InputField
          col="3"
          label="Total Full Time"
          type="number"
          value={fullTimeFormOper.numFullTimeEmployment.totalMF}
          readOnly={true}
        />

        <div style={{ marginTop: "3rem", marginLeft: "1px" }} class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>PWD</span>
          </div>
          <InputField
            col="3"
            label="Male"
            type="number"
            value={fullTimeFormOper.pwd.male}
            onChange={(value) => handleFieldChange("pwd", "male", value)}
            onFocus={handleResetFocus}
            error={!!fullTimeFormOper.validationErrors.pwd_male}
            helperText={fullTimeFormOper.validationErrors.pwd_male}
          />
          <InputField
            col="3"
            label="Female"
            type="number"
            value={fullTimeFormOper.pwd.female}
            onChange={(value) => handleFieldChange("pwd", "female", value)}
            onFocus={handleResetFocus}
            error={!!fullTimeFormOper.validationErrors.pwd_female}
            helperText={fullTimeFormOper.validationErrors.pwd_female}
          />
          <InputField
            col="3"
            label="Total PWD"
            type="number"
            value={fullTimeFormOper.pwd.total}
            readOnly={true}
          />
        </div>

             </div>
    </>
  );
}
