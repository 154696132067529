import React, { useState } from 'react';
import { MainNav } from '../subcomponents/MainNav';
import { InputNav } from '../subcomponents/InputNav';
export const LeftNavigation = () =>{
    const userType = localStorage.getItem('userType');
    return (
        <>
            <aside id="sidebar" class="sidebar">
                <ul class="sidebar-nav" id="sidebar-nav">
                    <MainNav navName="Dashboard" link="/dashboard" icon="bi bi-speedometer"/>
                    
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-bs-target="#input-nav" data-bs-toggle="collapse" href="#input-nav" >
                            <i className="bi bi-menu-button-wide"></i>
                                <span>Input Form</span>
                            <i className="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="input-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li className="nav-item">
                                <a className="nav-link collapsed" data-bs-target="#input-form-nav" data-bs-toggle="collapse" href="#input-form-nav" >
                                    <i className="bi bi-menu-button-wide"></i>
                                        <span>Input Form 1</span>
                                    <i className="bi bi-chevron-down ms-auto" style={{fontSize: "medium"}}></i>
                                </a>
                                <ul id="input-form-nav" className="nav-content collapse " data-bs-parent="#input-form-nav" style={{marginLeft: "13px"}}>
                                    {
                                        userType === "PIU"? <></>:
                                        <InputNav name="Physical Input" link="/inputform1-physicalInput"/>
                                    }
                                    <InputNav name="Capacity Building" link="/inputform1-capacityBuilding"/>
                                    <InputNav name="EOI Based" link="/inputform1-eoiBased"/>
                                    <InputNav name="MoU Based" link="/inputform1-mouBased"/>
                                    <InputNav name="Invitation Based" link="/inputform1-invitationBased"/>
                                    <InputNav name="Events" link="/inputform1-events"/>
                                </ul>
                            </li>

                            <InputNav name="Input Form 2 - MG" link="/inputform2"/>
                            <InputNav name="Input Form 2 - ImpactForms" link="/inputform2impactforms"/>
                            <InputNav name="Input Form 3" link="/inputform3"/>
                            <InputNav name="Input Form 3 - ImpactForms" link="/inputform3impactforms"/>

                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-bs-target="#output-nav" data-bs-toggle="collapse" href="#output-nav">
                            <i className="bi bi-menu-button-wide"></i>
                                <span>Output</span>
                            <i className="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="output-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <InputNav name="Output 1" link="/outputpage1"/>
                            <InputNav name="Output 2" link="/outputpage2"/>
                            <InputNav name="Output 2-ImpactForms" link="/outputpage2impactforms"/>
                            <InputNav name="Output 3" link="/outputpage3"/>
                            <InputNav name="Output 3-ImpactForms" link="/outputpage3impactforms"/>
                            <InputNav name="FIL Output Report" link="/filoutputreport"/>

                        </ul>
                    </li>
                    {
                        userType === "PIU"? <></>:
                        <MainNav navName="Master Data" link="/masterdata" icon="bi bi-database"/>
                    }
                    
                    {
                        userType === "PIU"? <></>:
                        <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#user-nav" data-bs-toggle="collapse" href="#user-nav">
                                <i className="bi bi-people"></i>
                                    <span>User Settings</span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                            </a>
                            <ul id="user-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                                <InputNav name="User List" link="/userlist"/>
                                <InputNav name="Role List" link="/rolelist"/>
                            </ul>
                        </li>
                    }
                    
                </ul>

            </aside>
        </>
    )
}