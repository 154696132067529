import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { getConfigWithToken } from "../utils/Config/Config";
import Swal from "sweetalert2";
import { Header } from "../components/Header";
import { LeftNavigation } from "../components/LeftNavigation";

export const MyProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const name = localStorage.getItem("name");
  const mobile = localStorage.getItem("mobile");
  const email = localStorage.getItem("email");
  const userType = localStorage.getItem("userType");
  const id = localStorage.getItem("id");

  const apiUrl = process.env.REACT_APP_API_URL;
  const [role, setRole] = useState();
  const [divisionHead, setDivisionHead] = useState(false);
  const [userRole, setUserRole] = useState();
  const [division, setDivision] = useState([]);
  const [selectDivision, setSelectedDivision] = useState([]);
  const [editUserFlag, setEditUserFlag] = useState([]);
  const [status, setStatus] = useState();

  const [userName, setUserName] = useState(name);
  const [userMobile, setUserMobile] = useState(mobile);
  const [userEmail, setUserEmail] = useState(email);
  const [securityStatus, setSecurityStatus] = useState("");
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [questionIds, setQuestionIds] = useState({});
  const number = useRef(null);

  const handleRole = (value) => {
    setUserRole(value);
    if (value.value == 2) {
      setDivisionHead(true);
    } else {
      setDivisionHead(false);
    }
  };

  const handleDivision = (value) => {
    setSelectedDivision(value);
  };

  const toggleStatus = () => {
    setStatus((prevStatus) => !prevStatus);
  };

  const handleSaveUser = async () => {
    try {
      if (editUserFlag) {
        const obj = {
          id: id,
          name: userName,
          mobile: userMobile,
          email: email,
          question_id_one: questionIds[0] || "",
          answer_one: answers[0] || "",
          question_id_two: questionIds[1] || "",
          answer_two: answers[1] || "",
          question_id_three: questionIds[2] || "",
          answer_three: answers[2] || "",
        };

        const response = await axios.post(
          `${apiUrl}api/user-update`,
          obj,
          getConfigWithToken()
        );

        if (response.data["status"] === true) {
          Swal.fire({
            title: 'User Edited Successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Update the local storage values
              localStorage.setItem("name", userName);
              localStorage.setItem("mobile", userMobile);
              localStorage.setItem("email", email);

              // Refresh the page
              window.location.reload();
            }
          });
        } else {
          Swal.fire(`${response.data["message"]}`, "", "warning");
        }
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,
    }));
  };

  useEffect(() => {
    async function getSecurityQuestions() {
      const response = await axios.get(
        `${apiUrl}api/get-random-questions?all=1`,
        getConfigWithToken()
      );
      const SecurityQuestionsData = response.data["data"].map((e, index) => ({
        id: e["id"],
        question: e["question"],
        index: index
      }));
      setSecurityQuestions(SecurityQuestionsData);

      const questionIds = {};
      SecurityQuestionsData.forEach((question, index) => {
        questionIds[index] = question.id;
      });
      setQuestionIds(questionIds);
    }

    async function getSecurityQuestionsStatus() {
      try {
        const response = await axios.get(
          `${apiUrl}api/isUserSubmitedSecurityQuestions`,
          getConfigWithToken()
        );
        const result = response.data.message;
        setSecurityStatus(result);
      } catch (error) {
        console.error("Error checking user security questions status:", error);
      }
    }

    async function getData() {
      const divisi = await axios.get(
        `${apiUrl}api/getAllDivisions?all=1`,
        getConfigWithToken()
      );
      const getRole = await axios.get(
        `${apiUrl}api/get-all-roles?all=1`,
        getConfigWithToken()
      );
      const divi = divisi.data["data"].map((e) => ({
        value: e["id"],
        label: e["divisions"],
      }));
      const rol = getRole.data["data"].map((e) => ({
        value: e["id"],
        label: e["name"],
      }));
      setDivision(divi);
      setRole(rol);
      if (id) {
        setEditUserFlag(true);

        const editData = await axios.get(
          `${apiUrl}api/user-edit?id=${id}`,
          getConfigWithToken()
        );
        const editDta = editData.data["data"][0];
        setUserRole(rol.filter((e) => e.label === editDta.usertype)[0]);
        setStatus(editData.data["data"][0]?.status);

        if (editDta.usertype === "PIU") {
          setDivisionHead(true);
        } else {
          setDivisionHead(false);
        }
        if (editDta.division_id) {
          const division_er = editDta.division_id.split(",");
          const rtt = division_er.map((e) => parseInt(e));
          const uju = divi.filter((item) => rtt.includes(item.value));
          setSelectedDivision(uju);
        }
      }
    }

    getSecurityQuestionsStatus();
    getSecurityQuestions();
    getData();
  }, []);

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div
              id="exTab3"
              class="contain"
              style={{
                width: "87%",
                position: "absolute",
                right: "0rem",
                top: "3.5rem",
              }}
            >
              <div
                class="card"
                style={{ height: "1200px", backgroundColor: "#F8F8F8" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "92%",
                    marginLeft: "5rem",
                    marginTop: "1rem",
                    padding: "1rem 4rem",
                    border: "1.5px solid #E8E8E8",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                      My Profile
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: "#4e2683",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/dashboard")}
                    >
                      <i
                        className="fa-solid fa-arrow-left"
                        style={{
                          marginTop: "0.5rem",
                          marginBottom: "2rem",
                          marginRight: "0.5rem",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      ></i>
                      Back
                    </span>
                    <button
                      style={{
                        backgroundColor: "#4e2683",
                        fontWeight: "bold",
                        color: "white",
                        padding: "5px 25px",
                        marginLeft: "15px",
                      }}
                      onClick={handleSaveUser}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <form
                  class="row g-3"
                  style={{ marginLeft: "7rem", marginTop: "3rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div class="col-7">
                      <label for="inputNanme4" class="form-label">
                        Your Name
                      </label>
                      <input
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        class="form-control"
                        id="inputNanme4"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="col-7">
                      <label for="inputEmail4" class="form-label">
                        Mobile
                      </label>
                      <input
                        type="number"
                        value={userMobile}
                        onChange={(e) => setUserMobile(e.target.value)}
                        class="form-control"
                        id="inputNanme4"
                      />
                    </div>
                    <div class="col-7">
                      <label for="inputPassword4" class="form-label">
                        Email
                      </label>
                      {userType === "super_admin" ? (
                        <input
                          type="email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                          class="form-control"
                          id="inputPassword4"
                        />
                      ) : (
                        <input
                          type="email"
                          value={userEmail}
                          class="form-control"
                          id="inputPassword4"
                          readOnly
                        />
                      )}
                    </div>
                    <div class="col-7">
                      <label for="inputPassword4" class="form-label">
                        Role
                      </label>
                      <input
                        type="text"
                        value={userType}
                        class="form-control"
                        id="inputPassword4"
                        readOnly
                        onChange={handleRole}
                      />
                    </div>
                    {divisionHead === true ? (
                      <div class="col-7">
                        <label>Division:</label>
                        <Select
                          options={division}
                          isMulti
                          value={selectDivision}
                          onChange={handleDivision}
                          isDisabled={divisionHead}
                        />
                        <div></div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                 <div></div>
                  <div style={{marginLeft:'0.1rem'}}>
                  {securityStatus !== "Added" && securityQuestions.length > 0 && (
                    <>
                    <p style={{marginLeft:'0.7rem' , marginBottom:'1rem' , fontWeight:'bold'}}>Security Questions</p>

                      {securityQuestions.map((question, index) => (
                        <div className="col-7" key={index} style={{marginBottom:'0.3rem'}}>
                          
                          <p style={{marginBottom:'0rem'}}>{question.question}</p>
                         
                          <input
                          style={{marginTop:'0.3rem'}}
                            type="text"
                            value={answers[index] || ""}
                            onChange={(e) =>
                              handleAnswerChange(index, e.target.value)
                            }
                            className="form-control"
                          />
                        </div>
                      ))}
                    </>
                  )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      
    </>
  );
};
