import React, { useEffect, useMemo, useState } from 'react';
import { useTable} from 'react-table';
import '../../assets/css/OutputTable.css'
import { Box, CircularProgress,Button, FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { getConfigWithToken } from '../../utils/Config/Config';
import Swal from 'sweetalert2';
import AxiosRetry from 'axios-retry';
import * as XLSX from 'xlsx';

export const OutputTableInnovation = ({ setDivision, currentQuarter, financialYear, currentFinancialYear }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([])
  const [selectDivision, setSelectDivision] = useState([])
  const [selectFinnacialYear, setSelectFinancialYear] = useState("2023-2024")
  const [selectSubFilter, setSelectSubFilter] = useState("Q3")
  const [selectReports, setSelectReports] = useState("18")
  const [tableData, setTableData] = useState([])

  // Create an Axios instance with retry settings
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 15000, // Set a timeout for requests
  });

  // Apply retry settings to the instance
  AxiosRetry(axiosInstance, {
    retries: 3, // Number of retry attempts
    retryDelay: AxiosRetry.exponentialDelay, // Exponential backoff
  });

  function filterTransformData(data){

    const transformedData = data.map(entry => {
      const magnetCropIds = JSON.parse(entry.magnet_crop_id);
      const magnetCrops = magnetCropIds.map(crop => `${crop.name}, `);
      return {...entry, magnet_crop_id: magnetCrops}
    })
    return transformedData
  }

  const handleSelectReports = async (event) => {
    setSelectReports(event.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${event.target.value}&division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }else{
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${event.target.value}&division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }
   
  }

  const handleSelectDivision = async (e) => {
    setSelectDivision(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${e.target.value}&quarter_no=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }else{
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${e.target.value}&half_yearly=${selectSubFilter}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }
  }

  const handleSelectFinancialYear = async (e) =>{
    setSelectFinancialYear(e.target.value)
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(selectSubFilter)){
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${selectDivision}&quarter_no=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }else{
      const divisionData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${selectDivision}&half_yearly=${selectSubFilter}&yearly=${e.target.value}`,getConfigWithToken())
      setTableData(filterTransformData(divisionData.data.data[0]))
    }
  }

  const handleSubFilter = async (e) =>{
    if(selectFinnacialYear.length === 0){
      Swal.fire(
        `Please Select Financial Year`,
        '',
        'warning'
      )
      return
    }
    const quarr = ['Q1', 'Q2', 'Q3', 'Q4']
    if(quarr.includes(e.target.value)){
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${selectDivision}&quarter_no=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(filterData.data.data[0]))
      
    }else{
      
      setSelectSubFilter(e.target.value)
      const filterData = await axios.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=${selectReports}&division_id=${selectDivision}&half_yearly=${e.target.value}&yearly=${selectFinnacialYear}`,getConfigWithToken())
      setTableData(filterTransformData(filterData.data.data[0]))
    }
    
  }

  useEffect(()=>{
     try{
      async function getData(){
        const empFPOComponentResponse = await axiosInstance.get(`${apiUrl}api/innovationInputOnesOutpts?form_id=18&quarter_no=${currentQuarter}&yearly=${currentFinancialYear}`, getConfigWithToken())
        setSelectSubFilter(currentQuarter)
        setSelectFinancialYear(currentFinancialYear)
        setTableData(filterTransformData(empFPOComponentResponse.data.data[0]))
        setDivisions(setDivision);
        setLoading(false);
      }
      
      setTimeout(() => {
        getData()
        // setLoading(false);
        
      }, 2000);
      
     }catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle 429 errors (rate limiting) here
        // You can display an error message to the user or retry the request
        Swal.fire(
          'An refresh the Page',
          '',
          'error'
      );
      } else {
        throw new Error('Data Not Found');
      }
    }
     
  },[setDivision, financialYear, currentFinancialYear, currentQuarter])
    
  
  const columns = useMemo(
    () => [
      {
        Header: 'Beneficiary Name', 
        accessor: 'beneficiary_name',
      },
      {
        Header: 'Beneficiary Type', 
        accessor: 'beneficiary_type',
      },
      {
        Header: 'MAGNET Crops (Multiple selection)', 
        accessor: 'magnet_crop_id',
      },
     
      {
        Header: 'Variety Name', 
        accessor: 'variety_name',
      },
      {
        Header: 'Sub Project Title', 
        accessor: 'sub_project_title',
      },
      {
        Header: 'Division', 
        accessor: 'divisions',
      },
      {
        Header: 'District', 
        accessor: 'district',
      },
      {
        Header: 'Taluka', 
        accessor: 'taluka',
      },
      {
        Header: 'MOU Signing Date', 
        accessor: 'signing_date',
      },
      {
        Header: 'Total project Cost (in Lakh)', 
        accessor: 'total_project_cost',
      },
      {
        Header: 'Total Sanction Grant from MAGNET (Lakh)', 
        accessor: 'total_saction_grant',
      },
      {
        Header: 'Payment/ Tranche', 
        accessor: 'tranche',
      },
      {
        Header: 'Disbursement Date', 
        accessor: 'disbursement_date',
      },
      {
        Header: 'Amount Disbursement (In Lakh)', 
        accessor: 'amount_disbursement',
      },
      {
        Header: 'Beneficiary Contribution (in Lakh)"', 
        accessor: 'beneficiary_contribution',
      },
      {
        Header: 'Total expenditure till date (in Lakh)', 
        accessor: 'total_exp_till_date',
      },
      {
        Header: 'Financial Progress till date (%)', 
        accessor: 'financial_progress_till_date_pct',
      },
     
      {
        Header: 'Expenditure incurred (in Lakh) in this Quarter', 
        accessor: 'exp_incurred',
      },
      {
        Header: 'Financial Progress by the end of this Quarter (%)', 
        accessor: 'financial_progress_by_the_end_of_qtr',
      },  
      {
        Header: 'Total Beneficiary expenditure till date (in Lakh)")', 
        accessor: 'total_beneficiary_exp_till_date',
      },
      {
        Header: 'Beneficiary expenditure incurred (in Lakh) by the end of this Quarter")', 
        accessor: 'beneficiary_exp_incurred',
      },
      {
        Header: 'Total Grant expenditure till date (in Lakh)")', 
        accessor: 'total_grant_exp_till_date',
      },
      {
        Header: 'Grant expenditure incurred (in Lakh) by the end of this Quarter")', 
        accessor: 'grant_exp_incurred',
      },
   
    ],
    []
  );
  const totalWidthForEqualColumns = 800; 

  // Calculate the width for each equally sized column
  const equalColumnWidth = totalWidthForEqualColumns / (columns.length - 1);

  // Set the width for each column (excluding "Components")
  columns.forEach((column, index) => {
    if (index !== 0) {
      column.width = equalColumnWidth;
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData, 
  });

  const cellStyle = {
    padding: '8px',
    border: '1px solid #ccc', // Add border style here
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
  };

  const exportToExcel = () => {
    const selectedDivision = divisions.find(division => division.id === selectDivision);
    const divisionName = selectedDivision ? selectedDivision.division_name : 'All';
  
        // Get report name based on selectFormTraining
        const reportNames = {
          "21": "Introduction of new varieties",
          "19": "R&D for new product development in focused value chain",
          "20": "Innovation in packaging in all 11 commodities",
          "18": "Bio-waste to wealth",
        };
    
        const reportName = reportNames[selectReports] || "";

    let formattedData;
  
    if (tableData.length === 0) {
      // Create a single row with only headers if there's no data
      formattedData = [{
        "Financial Year": selectFinnacialYear,
        "Period": selectSubFilter,
        ...(divisionName !== 'All' && { "Division": divisionName })
      }];
  
      columns.forEach(column => {
        formattedData[0][column.Header] = ""; // Empty value for the headers
      });
    } else {
      // Map table data and conditionally add the "Division" column
      formattedData = tableData.map(row => {
        let formattedRow = {
          "Financial Year": selectFinnacialYear,
          "Period": selectSubFilter,
          "Reports":reportName,
        };
  
        if (divisionName !== 'All') {
          formattedRow["Division"] = divisionName;
        }
  
        columns.forEach(column => {
          formattedRow[column.Header] = row[column.accessor];
        });
  
        return formattedRow;
      });
    }
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");
  
    const fileName = `${selectFinnacialYear}_${selectSubFilter}_${reportName}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  if (loading) {
    return (<>
        <Box sx={{ display: 'flex' }}>
        <CircularProgress />
        </Box>
    </>);
  }

  const stickyy= {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'var(--stickyBackground)',
    boxShadow: '0 0 6px rgba(0,0,0,0.25)'
  }
  return (
    <>
     <div
        style={{
          display: "flex",
          justifyContent: "center", // Center the container
          marginBottom: "10px",
          // marginLeft: "5rem",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1200px" }}>
          {" "}
          {/* Center the content and set max width */}
          <div className="row">
            <div className="col-md-2">
              {" "}
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Reports</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectReports}
                      label="Reports"
                      onChange={handleSelectReports}
                    >
                      <MenuItem value="21">Introduction of new varieties</MenuItem>
                      <MenuItem value="19">R&D for new product development in focused value chain</MenuItem>
                      <MenuItem value="20">Innovation in packaging in all 11 commodities</MenuItem>
                      <MenuItem value="18">Bio-waste to wealth</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-2'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Financial Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectFinnacialYear}
                      label="Financial Year"
                      onChange={handleSelectFinancialYear}
                    >
                      {
                        financialYear.map((e)=>(
                          <MenuItem value={e}>{e}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-2'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="grouped-native-select">Period:</InputLabel>
                    <Select defaultValue="" id="grouped-select" label="Period" 
                    value={selectSubFilter} 
                    onChange={handleSubFilter}>
                      <ListSubheader>Half Yearly</ListSubheader>
                      <MenuItem value="Q1, Q2">Q1, Q2</MenuItem>
                      <MenuItem value="Q3, Q4">Q3, Q4</MenuItem>
                      <ListSubheader>Quarterly</ListSubheader>
                      <MenuItem value="Q1">Q1</MenuItem>
                      <MenuItem value="Q2">Q2</MenuItem>
                      <MenuItem value="Q3">Q3</MenuItem>
                      <MenuItem value="Q4">Q4</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-md-2'>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="demo-simple-select-label">Division</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectDivision}
                      label="Division"
                      onChange={handleSelectDivision}
                    >
                      {
                        divisions.map((e)=>(
                          <MenuItem value={e.id} key={e.id}>{e.division_name}</MenuItem>
                        ))
                      }
                    </Select>
                </FormControl>
              </Box>
            </div>
            <div className="col-md-2">
              <Box sx={{ width: "95%" }}>
                <Button
                  onClick={exportToExcel}
                  style={{
                    color: "#4e2683",
                    fontWeight: "bold",
                    fontSize: "13.4px",
                    height: "53px",
                    width: "160px",
                    border: "0.9px solid #4e2683",
                    marginLeft: "-9px",
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', overflowX: 'auto', maxHeight: '445px'}}>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} style={stickyy}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}
                    style={{
                      borderBottom: '2px solid #ccc',
                      background: '#f2f2f2', // Add grey background color
                      fontWeight: 'bold', // Optionally make text bold
                      border: '2px solid #ccc',
                      ...cellStyle // Add border style here
                  }}>
                  {column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '8px',
                          border: '1px solid #ccc',
                          ...cellStyle,
                        }}
                      >
                        {cellIndex === 0 && index === 0 ? (
                          <span>{cell.value}</span> // Display the applicant name only once
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          </table>
      </div>
    </>
  );
};


