import React, { useEffect, useState } from "react";
import "../../../assets/css/modal.css";
import { Box, Pagination } from "@mui/material";
import { Header } from "../../../components/Header";
import { LeftNavigation } from "../../../components/LeftNavigation";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import Swal from "sweetalert2";
import Select from "react-select";
import "../../../assets/css/masterdata.css";
import { Button } from "@mui/material";

export const EntityListAttribute = () => {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [getAllData, setGetAllData] = useState({});
  const [techCompData, setTechCompData] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false); // State for controlling the modal
  const [newBeneficiaryAttribute, setNewBeneficiaryAttribute] = useState("");
  const [editingAttribute, setEditingAttribute] = useState(null); // State to track the attribute being edited
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isStatusMode, setIsStatusMode] = useState("Active");

  const apiUrl = process.env.REACT_APP_API_URL;

  // Adding new attribute
  const handleAddOption = () => {
    setEditingAttribute(null);
    setNewBeneficiaryAttribute("");
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  // Save operation
  const handleSaveAttribute = async () => {
    try {
      if (!newBeneficiaryAttribute) {
        Swal.fire("Entity name is required", "", "warning");
        return;
      }

      let response;

      if (editingAttribute) {
        response = await axios.post(
          `${apiUrl}api/beneficiary-master-update`,
          {
            beneficiary_name: newBeneficiaryAttribute,
            id: editingAttribute.id,
            status: isStatusMode === "active" ? "1" : "0",
          },
          getConfigWithToken()
        );

        if (response.data["status"] === true) {
          Swal.fire("Attribute Edited Successfully.", "", "success");
          window.location.reload();
        }

        const updatedFacilities = attributeOptions.map((facility) => {
          if (facility.id === editingAttribute.id) {
            return { ...facility, beneficiary_name: newBeneficiaryAttribute };
          }
          return facility;
        });

        setAttributeOptions(updatedFacilities);
      } else {
        const statusValue = isStatusMode === "active" ? "1" : "0";
        response = await axios.post(
          `${apiUrl}api/beneficiary-master-create`,
          {
            beneficiary_name: newBeneficiaryAttribute,
          },
          getConfigWithToken()
        );

        if (response.data.status === true) {
          Swal.fire("Attribute Added Successfully", "", "success");

          const updatedAttribute = [...attributeOptions, response.data.data];
          setAttributeOptions(updatedAttribute);

          const getWorkpack = await axios.get(
            `${apiUrl}api/get-all-beneficiary-master?page=${page}`,
            getConfigWithToken()
          );
          const workpackage = getWorkpack.data["data"]["data"].map((e) => ({
            id: e.id,
            beneficiary_name: e.beneficiary_name,
            form: e.form,
            form_id: e.form_id,
            status: e.status,
          }));
          setAttributeOptions(workpackage);
          setGetAllData(getWorkpack.data.data);
        }
      }

      setOpen(false);
    } catch (error) {
      Swal.fire(
        `${error.message}`,
        "",
        "warning"
      );
    }
  };

  // Editing the exisiting data
  const handleEditFunction = async (attribute) => {
    setEditingAttribute(attribute);
    setNewBeneficiaryAttribute(attribute.beneficiary_name);
    setIsStatusMode(attribute.status);
    setOpen(true);
  };

  // Deleting the attribute
  const handleDeleteFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteAttribute = await axios.get(
            `${apiUrl}api/beneficiary-master-delete?id=${id}`,
            getConfigWithToken()
          );
          if (deleteAttribute.data["status"] === true) {
            Swal.fire({
              title: "Deleted!",
              text: "Attribute has been deleted.",
              icon: "success",
            });
          }
          const updatedAttribute = attributeOptions.filter((e) => e.id !== id);
          setAttributeOptions(updatedAttribute);
        } catch (error) {
          Swal.fire(
            `${error.message}`,
            "",
            "warning"
          );
        }
      }
    });
  };

  // OnChange of pagination
  const handleChange = async (event, value) => {
    const getWorkpack = await axios.get(
      `${apiUrl}api/get-all-beneficiary-master?page=${value}`,
      getConfigWithToken()
    );
    const workpackage = getWorkpack.data["data"]["data"].map((e) => ({
      id: e.id,
      beneficiary_name: e.beneficiary_name,
      status: e.status,
    }));
    setAttributeOptions(workpackage);
    setGetAllData(getWorkpack.data.data);
    setPage(value);
  };

  useEffect(() => {
    async function getFacilityType() {
      try {
        const getfacility = await axios.get(
          `${apiUrl}api/get-all-beneficiary-master?page=1`,
          getConfigWithToken()
        );
       
        if (getfacility.data && getfacility.data.data) {
          const facility = getfacility.data.data.data.map((e) => ({
            id: e.id,
            beneficiary_name: e.beneficiary_name,
            status: e.status,
          }));
          setAttributeOptions(facility);
          setGetAllData(getfacility.data.data);
        } else {
          console.error("Unexpected response structure:", getfacility.data);
        }
      } catch (error) {
        console.error("Error fetching facility type:", error);
      }
    }
    getFacilityType();
  }, []);

  const getStatusLabel = (status) => {
    if (status && typeof status === "string") {
      if (status === "1" || status.toLowerCase() === "active") {
        return "Active";
      } else if (status === "0" || status.toLowerCase() === "inactive") {
        return "Inactive";
      }
    }
    return "Unknown";
  };

  return (
    <>
      <Header />
      <LeftNavigation />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div
              id="exTab3"
              className="contain"
              style={{ width: "100%", overflow: "hidden" }}
            >
              <div
                className="cardMaster"
                id=""
                style={{ width: "100%", marginTop: "0.5rem" }}
              >
                <form
                  className="row g-3"
                  style={{ width: "100%", maxWidth: "100%", margin: "auto" }}
                >
                  <Box
                    className="your-component"
                    sx={{ typography: "body1", width: "88%" }}
                  >
                    <div className="words-with-lines">
                      <span>Entity List</span>
                    </div>
                    <div className="your-link">
                      <a href="./masterdata" style={{ marginRight: "1rem" }}>
                        <i className="fa-solid fa-arrow-left"></i>
                        Back
                      </a>
                    </div>
                  </Box>
                  <Box className="your-box" sx={{ typography: "body1" }}>
                    <div>
                      <span>Manage Options (Value of your Attribute)</span>
                    </div>
                  </Box>
                  <div className="your-button-container">
                    <button
                      type="button"
                      style={{ width: "30%" }}
                      onClick={handleAddOption}
                    >
                      <i className="fa-duotone fa-plus"></i> Add Attributes
                      Options{" "}
                    </button>
                  </div>
                  <Box className="custom-box" style={{ marginLeft: "2rem" }}>
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th>Attribute Options</th>
                          <th style={{ width: "15%" }}>status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="your-tbody">
                        {attributeOptions.map((option, index) => (
                          <tr key={index}>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={option.beneficiary_name}
                                disabled
                              />
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                type="text"
                                value={getStatusLabel(option.status)} // Use getStatusLabel function
                                style={{ width: "85%" }}
                                disabled
                              />
                            </td>
                            <td>
                              <i
                                className="fa-solid fa-pen-to-square"
                                onClick={() => handleEditFunction(option)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                              &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                              <i
                                className="fa-regular fa-trash-can"
                                onClick={() => handleDeleteFunction(option.id)}
                                style={{ cursor: "pointer", color: "#4e2683" }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        count={getAllData.last_page}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                      />
                    </table>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      {open && (
        <div className="overlay">
          <div className="custom-modal">
            <div className="modal-content">
              <span className="close" onClick={handleModalClose}>
                &times;
              </span>
              <h2>{editingAttribute ? "Edit Attribute" : "Add Attribute"}</h2>

              <label htmlFor="Components">Entity List :</label>
              <input
                type="text"
                id="Components"
                value={newBeneficiaryAttribute}
                onChange={(e) => setNewBeneficiaryAttribute(e.target.value)}
                style={{ border: "1px solid black", height: "2.625em" }}
              />
              <br />
              <div>
                {editingAttribute && ( // Conditionally render only if editingAttribute is truthy
                  <div>
                    <label>Status:</label>

                    <label>
                      <input
                        type="radio"
                        value="active"
                        checked={isStatusMode === "active"}
                        onChange={() => setIsStatusMode("active")}
                      />
                      Active
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="inactive"
                        checked={isStatusMode === "inactive"}
                        onChange={() => setIsStatusMode("inactive")}
                      />
                      Inactive
                    </label>
                  </div>
                )}
              </div>

              <div style={{ textAlign: "center" }}>
                <button
                  className="button-trigger"
                  onClick={handleSaveAttribute}
                >
                  {editingAttribute ? "Save Changes" : "Save"}
                </button>
                <button
                  className="button-trigger"
                  style={{ backgroundColor: "grey" }}
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
