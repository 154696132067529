import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePickInput3 } from "../../DatePickInput3";
import { InputField } from "../../InputField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  resetValidation,
  setInputFieldData,
} from "../../../redux/slice/InputTwoImpactForms/UtilizationColdStorageTwo/UitlizationColdStorageTwoSlice";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { MultiSelect } from "../../MultiSelect";

export const UtilizationColdStorageMG = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const utilizationColdStorageTwo = useSelector(
    (state) => state.utilizationColdStorageTwo
  );

  const {
    validationErrors,
    is_facility_in_opration,
    total_quantity_handled,
    total_capacity_utilised_frozan_storage,
    total_capacity_utilised_cold_storage,
    capacity_utilised_captive_frozen_storage,
    capacity_utilised_captive_cold_storage,
    capacity_utilised_job_frozen_storage,
    capacity_utilised_job_cold_storage,
    service_frozen_charges,
    service_cold_charges,
    number_of_farmers,
    members_of_fpo_small,
    members_of_fpo_medium,
    members_of_fpo_others,
    non_members_small,
    non_members_medium,
    non_members_others,
  } = useSelector((state) => state.utilizationColdStorageTwo);

  const FacilityOpr = [
    {
      id: 1,
      Status_mode: "Yes",
    },
    {
      id: 2,
      Status_mode: "No",
    },
  ];

  const updateTotalFarmersSupplying = (
    members_of_fpo_small,
    members_of_fpo_medium,
    members_of_fpo_others,
    non_members_small,
    non_members_medium,
    non_members_others
  ) => {
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      parseFloatOrZero(members_of_fpo_small) +
      parseFloatOrZero(members_of_fpo_medium) +
      parseFloatOrZero(members_of_fpo_others) +
      parseFloatOrZero(non_members_small) +
      parseFloatOrZero(non_members_medium) +
      parseFloatOrZero(non_members_others);
    const roundedValue = newValue.toFixed(2);
    dispatch(
      setInputFieldData({
        fieldName: "number_of_farmers",
        value: roundedValue,
      })
    );
  };

  const handleOptionFacilityOpr = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "is_facility_in_opration",
        value: event.target.value,
      })
    );
  };

  const handleTotalQtyHandTPD = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "total_quantity_handled",
          value,
        })
      );
    }
  };

 

  const handleMemFPOSmall = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "members_of_fpo_small",
          value,
        })
      );
      updateTotalFarmersSupplying(
        newValue,
        members_of_fpo_medium,
        members_of_fpo_others,
        non_members_small,
        non_members_medium,
        non_members_others
      );
    }
  };

  const handleMemFPOMedium = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "members_of_fpo_medium",
          value,
        })
      );
      updateTotalFarmersSupplying(
        members_of_fpo_small,
        newValue,
        members_of_fpo_others,
        non_members_small,
        non_members_medium,
        non_members_others
      );
    }
  };

  const handleMemFPOOthers = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "members_of_fpo_others",
          value,
        })
      );
      updateTotalFarmersSupplying(
        members_of_fpo_small,
        members_of_fpo_medium,
        newValue,
        non_members_small,
        non_members_medium,
        non_members_others
      );
    }
  };

  const handleNonMemFPOSmall = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "non_members_small",
          value,
        })
      );
      updateTotalFarmersSupplying(
        members_of_fpo_small,
        members_of_fpo_medium,
        members_of_fpo_others,
        newValue,
        non_members_medium,
        non_members_others
      );
    }
  };

  const handleNonMemFPOMedium = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "non_members_medium",
          value,
        })
      );
      updateTotalFarmersSupplying(
        members_of_fpo_small,
        members_of_fpo_medium,
        members_of_fpo_others,
        non_members_small,
        newValue,
        non_members_others
      );
    }
  };

  const handleNonMemFPOOthers = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "non_members_others",
          value,
        })
      );
      updateTotalFarmersSupplying(
        members_of_fpo_small,
        members_of_fpo_medium,
        members_of_fpo_others,
        non_members_small,
        non_members_medium,
        newValue
      );
    }
  };

  const handleTUCFrozan = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "total_capacity_utilised_frozan_storage",
          value,
        })
      );
    }
  };

  const handleTUCCold = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "total_capacity_utilised_cold_storage",
          value,
        })
      );
    }
  };

  const handleCUCFS = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "capacity_utilised_captive_frozen_storage",
          value,
        })
      );
    }
  };
  const handleCUCCS = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "capacity_utilised_captive_cold_storage",
          value,
        })
      );
    }
  };

  const handleCUJFS = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "capacity_utilised_job_frozen_storage",
          value,
        })
      );
    }
  };
  const handleCUJCS = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "capacity_utilised_job_cold_storage",
          value,
        })
      );
    }
  };

  const handleServiceFrozan = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "service_frozen_charges",
          value,
        })
      );
    }
  };
  const handleServiceCold = (value) => {
    const newValue = parseFloat(value);
    if (!isNaN(newValue) && newValue >= 0) {
      dispatch(
        setInputFieldData({
          fieldName: "service_cold_charges",
          value,
        })
      );
    }
  };
  return (
    <div>
      <div className="row g-3">
        <div className="col-md-4">
          <Box sx={{ minWidth: "100%" }}>
            <FormControl
              fullWidth
              //   error={errorWorkpackage !== "" ? true : false}
            >
              <InputLabel htmlFor="demo-simple-select-label">
                Is this facility in operation
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                errorText={
                  validationErrors.is_facility_in_opration.length !== 0
                    ? true
                    : false
                }
                value={is_facility_in_opration}
                label="Is this facility in operation"
                onChange={handleOptionFacilityOpr}
              >
                {FacilityOpr.map((e) => (
                  <MenuItem key={e.id} value={e.Status_mode}>
                    {e.Status_mode}
                  </MenuItem>
                ))}
              </Select>
              {/* <FormHelperText>{errorWorkpackage}</FormHelperText> */}
            </FormControl>
          </Box>
        </div>

        <InputField
          label="Total Qty Handled (MT)"
          type="number"
          onChange={handleTotalQtyHandTPD}
          value={
            total_quantity_handled ? total_quantity_handled.toString() : ""
          }
          error={!!validationErrors.total_quantity_handled}
          helperText={validationErrors.total_quantity_handled}
        />

        {/* calculated field ///Nos of Farmers supplying/// */}
        <InputField
          readOnly
          label="Nos of Farmers supplying"
          type="number"
          value={number_of_farmers ? number_of_farmers.toString() : ""}
        />

        <div class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>Members of FPO</span>
          </div>
          <InputField
            col="3"
            label="Small"
            type="number"
            onChange={handleMemFPOSmall}
            value={members_of_fpo_small ? members_of_fpo_small.toString() : ""}
            error={!!validationErrors.members_of_fpo_small}
            helperText={validationErrors.members_of_fpo_small}
          />

          <InputField
            col="3"
            label="Medium"
            type="number"
            onChange={handleMemFPOMedium}
            value={
              members_of_fpo_medium ? members_of_fpo_medium.toString() : ""
            }
            error={!!validationErrors.members_of_fpo_medium}
            helperText={validationErrors.members_of_fpo_medium}
          />
          <InputField
            col="3"
            label="Others"
            type="number"
            onChange={handleMemFPOOthers}
            value={
              members_of_fpo_others ? members_of_fpo_others.toString() : ""
            }
            error={!!validationErrors.members_of_fpo_others}
            helperText={validationErrors.members_of_fpo_others}
          />

          <div className="col-md-3 input1Heading">
            <span>Non Members/ Traders or other Corporates</span>
          </div>
          <InputField
            col="3"
            label="Small"
            type="number"
            onChange={handleNonMemFPOSmall}
            value={non_members_small ? non_members_small.toString() : ""}
            error={!!validationErrors.non_members_small}
            helperText={validationErrors.non_members_small}
          />
          <InputField
            col="3"
            label="Medium"
            type="number"
            onChange={handleNonMemFPOMedium}
            value={non_members_medium ? non_members_medium.toString() : ""}
            error={!!validationErrors.non_members_medium}
            helperText={validationErrors.non_members_medium}
          />
          <InputField
            col="3"
            label="Others"
            type="number"
            onChange={handleNonMemFPOOthers}
            value={non_members_others ? non_members_others.toString() : ""}
            error={!!validationErrors.non_members_others}
            helperText={validationErrors.non_members_others}
          />
        </div>

        <div class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>Total Capacity (In MT)</span>
          </div>
          <InputField
            col="3"
            label="Frozen Storage"
            type="number"
            onChange={handleTUCFrozan}
            value={total_capacity_utilised_frozan_storage ? total_capacity_utilised_frozan_storage.toString() : ""}
            error={!!validationErrors.total_capacity_utilised_frozan_storage}
            helperText={validationErrors.total_capacity_utilised_frozan_storage}
          />
          <InputField
            col="3"
            label="Cold Storage"
            type="number"
            onChange={handleTUCCold}
            value={total_capacity_utilised_cold_storage ? total_capacity_utilised_cold_storage.toString() : ""}
            error={!!validationErrors.total_capacity_utilised_cold_storage}
            helperText={validationErrors.total_capacity_utilised_cold_storage}
          />
        </div>

        <div class="row g-3">
          <div className="col-md-3 input1Heading">
            <span>Total Capacity Utilised for Captive(In MT)</span>
          </div>
          <InputField
            col="3"
            label="Frozen Storage"
            type="number"
            onChange={handleCUCFS}
            value={capacity_utilised_captive_frozen_storage ? capacity_utilised_captive_frozen_storage.toString() : ""}
            error={!!validationErrors.capacity_utilised_captive_frozen_storage}
            helperText={validationErrors.capacity_utilised_captive_frozen_storage}
          />
          <InputField
            col="3"
            label="Cold Storage"
            type="number"
            onChange={handleCUCCS}
            value={capacity_utilised_captive_cold_storage ? capacity_utilised_captive_cold_storage.toString() : ""}
            error={!!validationErrors.capacity_utilised_captive_cold_storage}
            helperText={validationErrors.capacity_utilised_captive_cold_storage}
          />
        </div>

        <div class="row g-3">
          <div className="col-md-3 input1Heading">
            <span> Capacity utilised for Service/Job Work (In MT)</span>
          </div>
          <InputField
            col="3"
            label="Frozen Storage"
            type="number"
            onChange={handleCUJFS}
            value={capacity_utilised_job_frozen_storage ? capacity_utilised_job_frozen_storage.toString() : ""}
            error={!!validationErrors.capacity_utilised_job_frozen_storage}
            helperText={validationErrors.capacity_utilised_job_frozen_storage}
          />
          <InputField
            col="3"
            label="Cold Storage"
            type="number"
            onChange={handleCUJCS}
            value={capacity_utilised_job_cold_storage ? capacity_utilised_job_cold_storage.toString() : ""}
            error={!!validationErrors.capacity_utilised_job_cold_storage}
            helperText={validationErrors.capacity_utilised_job_cold_storage}
          />
        </div>

        <div class="row g-3">
          <div className="col-md-3 input1Heading">
            <span> Service Charges (In Rs.)</span>
          </div>
          <InputField
            col="3"
            label="Frozen Storage"
            type="number"
            onChange={handleServiceFrozan}
            value={service_frozen_charges ? service_frozen_charges.toString() : ""}
            error={!!validationErrors.service_frozen_charges}
            helperText={validationErrors.service_frozen_charges}
          />
          <InputField
            col="3"
            label="Cold Storage"
            type="number"
            onChange={handleServiceCold}
            value={service_cold_charges ? service_cold_charges.toString() : ""}
            error={!!validationErrors.service_cold_charges}
            helperText={validationErrors.service_cold_charges}
          />
        </div>
      </div>
    </div>
  );
};
