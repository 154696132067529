import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ///////////
  from_date: null,
  to_date: null,
  location_taluka: '',
  participants_male: '',
  participants_female: '',
  participants_total: '',
  beneficiary_attended:'',

  validationErrors: {
    from_date: '',
    to_date: '',
    location_taluka: '',
    participants_male: '',
    participants_female: '',
    participants_total: '',
  }
};

const MarketPromotionSlice = createSlice({
  name: 'MarketPromotionSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    setTotalParticipants: (state, action) => {
        state.participants_total= action.payload;
      },
    // Add other reducers for different input fields if needed
    updateApiDataMarketProm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
    },
    resetDataMarketProm: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsMarketProm: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsMarketProm, setTotalParticipants, resetValidation, setInputFieldData, updateApiDataMarketProm, resetDataMarketProm } = MarketPromotionSlice.actions;

export default MarketPromotionSlice.reducer;
