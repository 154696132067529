import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePick } from "../../DatePick";
import { updateDataField } from "../../../redux/slice/InputTwo/FILInputTwo/FilSlice"; // Import your data slice
import { InputField } from "../../InputField";
import {
  resetBeneProfitValidation,
  setInputFieldData,
} from "../../../redux/slice/InputTwoImpactForms/BeneficiaryProfit/BeneficiaryProfitSlice";

export function BeneficiariesProfit() {
  const dispatch = useDispatch();
  const { validationErrors, total_revenue, net_profit, profit } = useSelector(
    (state) => state.beneficiaryProfitMaster
  );

  const updateProfit = (total_revenue, net_profit) => {
    // const newValue = parseFloat(total_revenue) + parseFloat(net_profit) + parseFloat(promoters);
    function parseFloatOrZero(value) {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
    const newValue =
      (parseFloat(net_profit) / parseFloatOrZero(total_revenue)) * 100;
      const roundedValue = newValue.toFixed(2);
    dispatch(setInputFieldData({ fieldName: "profit", value: roundedValue }));
  };

  const handleNetProfitChange = (value) => {
    if (value === "" || value === null) {
        dispatch(setInputFieldData({ fieldName: "net_profit", value: "" }));
        updateProfit(total_revenue, "");
    } else {
        const newValue = parseFloat(value);
        if (!isNaN(newValue) && newValue >= 0) {
            dispatch(setInputFieldData({ fieldName: "net_profit", value }));
            updateProfit(total_revenue, newValue); 
        }
    }
};

const handleTotalRevenueChange = (value) => {
    if (value === "" || value === null) {
        dispatch(setInputFieldData({ fieldName: "total_revenue", value: "" }));
        updateProfit("", net_profit); 
    } else {
        const newValue = parseFloat(value);
        if (!isNaN(newValue) && newValue >= 0) {
            dispatch(setInputFieldData({ fieldName: "total_revenue", value }));
            updateProfit(newValue, net_profit); 
        }
    }
};


  const handleResetFocus = () => {
    dispatch(resetBeneProfitValidation());
  };


  return (
    <div>
      <div class="row g-3">
        <InputField
          label="Total Revenue (Only of MAGNET sub-project)"
          value={
            total_revenue ? total_revenue.toString() : ""
          }
          onChange={handleTotalRevenueChange}
          onFocus={handleResetFocus}
          error={!!validationErrors.total_revenue}
          helperText={validationErrors.total_revenue}
        />

       
        <InputField
          label="Net Profit (Only of MAGNET sub-project)"
          type="number"
          value={
            net_profit ? net_profit.toString() : ""
          }
          onChange={handleNetProfitChange}
          onFocus={handleResetFocus}
          error={!!validationErrors.net_profit}
          helperText={validationErrors.net_profit}
        />
        
        <InputField
          label="Profit"
          type="number"
          endAr="(%)"
          value={profit ? profit.toString() : ""}
          readOnly={true}
        />
      </div>
    </div>
  );
}

