import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
  ///////////
 
  location_taluka: '',
  location_address: '',
  resource_person:'',
  action_points_recommendation_made:'',
  beneficiary_attended:'',

  sc_male: '',
  sc_female: '',
  sc_total: '',

  st_male: '',
  st_female: '',
  st_total: '',

  pwd_male: '',
  pwd_female: '',
  pwd_total: '',

  obc_minority_open_male: '',
  obc_minority_open_female: '',
  obc_minority_open_total: '',

  participants_male: '',
  participants_female: '',
  participants_total: '',




  validationErrors:{
    sc_male: '',
    sc_female: '',

    st_male: '',
    st_female: '',

    pwd_male: '',
    pwd_female: '',

    obc_minority_open_male: '',
    obc_minority_open_female: '',

    participants_male: '',
    participants_female: '',

    date: '',
    location_taluka: '',
    location_address: '',
    resource_person:'',
    bod:'',
    export:'',
    action_points_recommendation_made:'',
    magnet_crop_id:'',
    beneficiary_attended:'',
  }
};

const valueChain = createSlice({
  name: 'valueChain',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
    },
    setTotalSC: (state, action) => {
        state.sc_total = action.payload;
      },
    setTotalST: (state, action) => {
        state.st_total = action.payload;
      },
    setTotalPWD: (state, action) => {
        state.pwd_total = action.payload;
      },
    
    setTotalOBC: (state, action) => {
        state.obc_minority_open_total= action.payload;
      },
    
    setTotalParticipants: (state, action) => {
        state.participants_total= action.payload;
      },
    // Add other reducers for different input fields if needed
    updateValueChainForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataValueChain: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsValueChain: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
  },
});

export const {setValidationErrorsValueChain, resetValidation, setInputFieldData, updateValueChainForm, resetDataValueChain, setTotalOBC, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants } = valueChain.actions;

export default valueChain.reducer;
