import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/LoginPage.css";
import logo from "../assets/images/logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../utils/Config/Config";

const SecurityQuestionsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { questions, email } = location.state;

  const [answers, setAnswers] = useState({
    question_id_one: "",
    question_id_two: "",
    question_id_three: "",
  });

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSaveSQ = async () => {
    try {
      const obj = {
        email: email,
        question_id_one: questions.question_id_one,
        answer_one: answers.question_id_one,
        question_id_two: questions.question_id_two,
        answer_two: answers.question_id_two,
        question_id_three: questions.question_id_three,
        answer_three: answers.question_id_three,
      };

      console.log("Sending payload to API:", obj);

      const response = await axios.post(
        `${apiUrl}api/verify_user_by_security_quiz`,
        obj,{email}
      );

      console.log("API response:", response);

      if (response.data["status"] === true) {
        Swal.fire({
          title: "Security Questions Verified Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            // Update the local storage values or navigate to another page
          
            navigate("/resetPassword", {
              state: { email },
            });
          }
        });
      } else {
        Swal.fire(`${response.data["message"]}`, "", "warning");
      }
    } catch (error) {
      console.error("Error verifying security questions:", error);
      Swal.fire("An error occurred. Please try again.", "", "error");
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div
            style={{
              width: "50%",
              position: "absolute",
              right: "25rem",
              left: "23rem",
              top: "3rem",
            }}
          >
            <div
              className="card"
              style={{ height: "650px", backgroundColor: "#F8F8F8" }}
            >
              <div>
                <div>
                  <form
                    className="row g-3"
                    style={{
                      marginLeft: "1rem",
                      marginTop: "2rem",
                      marginRight: "0rem",
                    }}
                  >
                    <div
                      className="col-12"
                      style={{ textAlign: "center", marginTop: "-1rem" }}
                    >
                      <img src={logo} alt="Logo" height="100px" />
                      <h5 style={{ textAlign: "center", marginTop: "3rem" }}>
                        Security Questions
                      </h5>
                    </div>

                    <div className="security-question">
                      <p>{questions.question_one}</p>
                      <TextField
                        id="answer_one"
                        label="Answer"
                        variant="outlined"
                        fullWidth
                        value={answers.question_id_one}
                        onChange={(e) =>
                          handleAnswerChange("question_id_one", e.target.value)
                        }
                      />
                    </div>
                    <div className="security-question">
                      <p>{questions.question_two}</p>
                      <TextField
                        id="answer_two"
                        label="Answer"
                        variant="outlined"
                        fullWidth
                        value={answers.question_id_two}
                        onChange={(e) =>
                          handleAnswerChange("question_id_two", e.target.value)
                        }
                      />
                    </div>
                    <div className="security-question">
                      <p>{questions.question_three}</p>
                      <TextField
                        id="answer_three"
                        label="Answer"
                        variant="outlined"
                        fullWidth
                        value={answers.question_id_three}
                        onChange={(e) =>
                          handleAnswerChange(
                            "question_id_three",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </form>
                  <button
                    style={{
                      backgroundColor: "#4e2683",
                      fontWeight: "bold",
                      color: "white",
                      padding: "5px 25px",
                      marginLeft: "247px",
                      marginTop: "20px",
                      borderRadius: "25px",
                    }}
                    onClick={handleSaveSQ}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SecurityQuestionsPage;
