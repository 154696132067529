import React, { useState } from "react";
import { Header } from "../../../../components/Header";
import { LeftNavigation } from "../../../../components/LeftNavigation";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import "../../../../assets/css/masterdata.css";
import { FILMasterDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/FILMaster/FILDetails";
import { FILLoanDetails } from "../../../../subcomponents/MasterFormTables/PrimaryTables/FILMaster/FILLoanDetails";
import { useDispatch, useSelector } from "react-redux";
import {setInputFieldData,setValidationErrorsFILMaster,resetDataFILMaster, updateApiDataFILMaster} from "../../../../redux/slice/FILMaster/FILMasterSlice";
import { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { getConfigWithToken } from "../../../../utils/Config/Config";
import { useLocation } from "react-router-dom";

export const AddFilMaster = () => {
  const [value, setValue] = useState("0");
  const apiUrl = process.env.REACT_APP_API_URL;
  const dd = useSelector(setInputFieldData);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state;
  const [Division, setDivision] = useState([]);
  const [District, setDistrict] = useState([]);
  const [Taluka, setTaluka] = useState([]);
  const [magnetCrop, setMagentCrop] = useState([]);
  const [existingCrop, setExisitingCrop] = useState([]);
  const [EntityType, setEntityType] = useState([]);
  const [Bank, setBank] = useState([]);

  const [BeneficiaryEdit, setBeneficiaryEdit] = useState("");
  const [BeneficiaryFlagEdit, setBeneficiaryFlagEdit] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNextForm = () => {
    const newValue = parseInt(value) + 1;
    setValue(String(newValue));
  };

  const handleBackForm = () => {
    const newValue = parseInt(value) - 1;
    setValue(String(newValue));
  };
  

  const handleMGBeneficiarySave = async (e) => {
    e.preventDefault();
    
       const validateForm = () => {
        const errors = {};
        const formData = dd["payload"]["filMaster"];
        const errorMessages = {
          applicant_name: "Required",
          division_id: "Required",
          district_id: "Required",
          taluka_id: "Required",
          magnet_crop_id: "Required",
          existing_crop_id: "Required",
          entity_type_id: "Required",
          existing_infrastructure: "Required",
          type_of_assistance: "Required",
          loan_amount: "Required",
          purpose_of_loan: "Required",
          interest_rate_pct: "Required",
          processing_fee_pct: "Required",
          sanction_date: "Required",
          disbursed_amount: "Required",
          outstanding_amount: "Required",
          disbursement_date: "Required",
          revised_interest_rate: "Required",
          addendum_date: "Required",
        };
      
        let isEmpty = false;
      
        for (const field in errorMessages) {
          const value = formData[field];
      
          if (!value || (Array.isArray(value) && value.length === 0)) {
            errors[field] = "Required";
            isEmpty = true;
          }
        }
      
        dispatch(setValidationErrorsFILMaster(errors));
      
        if (isEmpty) {
          Swal.fire("All fields are required", "", "warning");
        }
      
        return !isEmpty;
      };

      const formData = dd["payload"]["filMaster"];

      const jsonMagnetCrop = Array.isArray(formData["magnet_crop_id"])
        ? formData["magnet_crop_id"]
            .map((id) => {
              const matchingObject = magnetCrop.find((item) => item.id === id);
              if (matchingObject) {
                return {
                  id: matchingObject.id,
                  name: matchingObject.crop,
                };
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];
      
      const resultMagnetCrop = { data: jsonMagnetCrop };
      
      const jsonExistingMagnetCrop = Array.isArray(formData["existing_crop_id"])
        ? formData["existing_crop_id"]
            .map((id) => {
              const matchingObject = existingCrop.find((item) => item.id === id);
              if (matchingObject) {
                return {
                  id: matchingObject.id,
                  name: matchingObject.crop,
                };
              }
              return null;
            })
            .filter((item) => item !== null)
        : [];
      
      const resultExistingMagnetCrop = { data: jsonExistingMagnetCrop };
      
      const obj = {
        ...formData,
        magnet_crop_id: JSON.stringify(resultMagnetCrop["data"]),
        existing_crop_id: JSON.stringify(resultExistingMagnetCrop["data"]),
      };
      
      obj.magnet_crop_id = obj.magnet_crop_id.length > 0 ? obj.magnet_crop_id : [];
      obj.existing_crop_id = obj.existing_crop_id.length > 0 ? obj.existing_crop_id : [];
      
      
    try {
      if (validateForm()) {
        if (BeneficiaryFlagEdit) {
          const checkEdit = await axios.post(
            `${apiUrl}api/fill-update?id=${id.id}`,
            obj,
            getConfigWithToken()
          );
  
          if (checkEdit.data["status"] === true) {
            Swal.fire("Edited Successfully", "", "success");
            dispatch(updateApiDataFILMaster(checkEdit.data["data"][0]));
          } else if (checkEdit.data["message"] === "Record Not Found") {
            Swal.fire(
              "Record Not Found",
              "The record you are trying to edit/update does not exist.",
              "warning"
            );
          } else {
            Swal.fire(`${checkEdit.data["message"]}`, "", "warning");
          }
        } else {
          const checkCreate = await axios.post(
            `${apiUrl}api/fill-create`,
            obj,
            getConfigWithToken()
          );
  
          if (checkCreate.data["status"] === true) {
            Swal.fire(`${checkCreate.data["message"]}`, "", "success");
            dispatch(resetDataFILMaster());
          } else {
            Swal.fire(`${checkCreate.data["message"]}`, "", "warning");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const FILMasterData = async () => {
      try {
        const division = await axios.get(
          `${apiUrl}api/getAllDivisions?all=1`,
          getConfigWithToken()
        );
        const district = await axios.get(
          `${apiUrl}api/get-all-district?all=1`,
          getConfigWithToken()
        );
        const taluka = await axios.get(
          `${apiUrl}api/get-taluka-by-district?district_id=${id}`,
          getConfigWithToken()
        );
        const magnetcrop = await axios.get(
          `${apiUrl}api/getAllCrop?all=1`,
          getConfigWithToken()
        );
        const exisitingcrop = await axios.get(
          `${apiUrl}api/getAllExistingCrop?all=1`,
          getConfigWithToken()
        );
        const entitytype = await axios.get(
          `${apiUrl}api/getEntityType?all=1`,
          getConfigWithToken()
        );
        const bank = await axios.get(
          `${apiUrl}api/get-all-banks?all=1`,
          getConfigWithToken()
        );
        const bankData = bank.data["data"].map((e) => ({
          id: e["id"],
          bank_name: e["bank_name"],
        }));

        const divisionData = division.data["data"].map((e) => ({
          id: e["id"],
          divisions: e["divisions"],
        }));
        const districtData = district.data["data"].map((e) => ({
          id: e["id"],
          district: e["district"],
        }));
        const talukaData = taluka.data["data"].map((e) => ({
          id: e["id"],
          taluka: e["taluka"],
        }));
        const magnetcropData = magnetcrop.data["data"].map((e) => ({
          id: e["id"],
          crop: e["crop"],
        }));
        const existingcropData = exisitingcrop.data["data"].map((e) => ({
          id: e["id"],
          crop: e["crop"],
        }));
        const entitytypeData = entitytype.data["data"].map((e) => ({
          id: e["entity_type_id"],
          entity_type: e["entity_type"],
        }));

        setBank(bankData);
        setDivision(divisionData);
        setDistrict(districtData);
        setTaluka(talukaData);
        setMagentCrop(magnetcropData);
        setExisitingCrop(existingcropData);
        setEntityType(entitytypeData);

        
        if (id && id.id) {
          const applicantId = id.id;

          console.log("Selected Applicant ID:", applicantId);

          setBeneficiaryFlagEdit(true);

          // Fetch existing data for editing
          const editDataResponse = await axios.get(
            `${apiUrl}api/fill-edit?id=${applicantId}`,
            getConfigWithToken()
          );

          console.log("Edit Data Response:", editDataResponse.data);

          if (editDataResponse.data && editDataResponse.data.status) {
            const editData = editDataResponse.data.data;

            if (editData && editData.length > 0) {
              const editDta = editData[0];
              console.log("Data for Editing:", editDta);

              setBeneficiaryEdit(editDta);
              dispatch(updateApiDataFILMaster(editDta));
            } else {
              console.error("No data found for editing");
            }
          } else {
            console.error("Invalid request:", editDataResponse.data.message);
          }
        } else {
          console.error("Invalid id:", id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    FILMasterData();
  }, []);

 
  return (
    <>
      <Header />
      <LeftNavigation />
        <main id="main" className="main">
          <section className="section">
            <div className="row">
              <div id="exTab3" className="contain">
                <div className="cardMaster" id="" style={{ height: "1000px" ,marginTop:'1rem'}}>
                  <form class="row g-3">
                    <Box
                      className="your-component"
                      sx={{ typography: "body1" }}
                    >
                      <div className="words-with-lines">
                        <span>FIL Master</span>
                      </div>
                      <div className="msambstyle">
                        <a href="./FIL-Master">
                          <i className="fa-solid fa-arrow-left"></i>Back
                        </a>
                        <button
                          type="submit"
                          onClick={handleMGBeneficiarySave}
                          className="save-btn"
                        >
                          Save
                        </button>
                        <button className="dropdown-btn">
                          <i className="fa-solid fa-caret-down"></i>
                        </button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        width: "92%",
                        height: "500px",
                        marginLeft: "5rem",
                        marginTop: "3.5rem",
                        typography: "body1",
                      }}
                    >
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            value={value}
                            onChange={handleChange}
                          >
                            <Tab
                              label="FIL Details"
                              value="0"
                              className={`${
                                value === "0" ? "activeClickCSS" : ""
                              }`}
                            />
                            <Tab
                              label="FIL Loan"
                              value="1"
                              className={`${
                                value === "1" ? "activeClickCSS" : ""
                              }`}
                            />
                          </Tabs>
                        </Box>

                        {/* Beneficiary Deatils */}
                        <TabPanel value="0">
                          <FILMasterDetails
                            Division={Division}
                            District={District}
                            Taluka={Taluka}
                            EntityType={EntityType}
                            Bank={Bank}
                            MagnetCrop={magnetCrop}
                            ExistingCrop={existingCrop}
                            initialValues={BeneficiaryEdit}
                          />
                          <div
                            style={{ textAlign: "right", marginTop: "2rem" }}
                          >
                            <button
                              type="button"
                              onClick={handleNextForm}
                              className="btn submitbtn"
                            >
                              Next
                              <i
                                className="fa-solid fa-angles-right"
                                style={{ color: "white", marginLeft: "0.5rem" }}
                              ></i>
                            </button>
                          </div>
                        </TabPanel>

                        {/* Contact  */}
                        <TabPanel value="1">
                          <FILLoanDetails />
                          <div
                            style={{ textAlign: "right", marginTop: "2rem" }}
                          >
                            <button
                              type="button"
                              onClick={handleBackForm}
                              className="btn submitbtn"
                            >
                              <i
                                className="fa-solid fa-angles-left"
                                style={{
                                  color: "white",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                              Previous
                            </button>
                          </div>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
    </>
  );
};
