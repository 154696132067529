import { InputField } from "../../InputField";
import React, { useEffect, useState } from "react";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormHelperText } from "@mui/material";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFieldData,
  resetValidation,
  setTotalParticipants,
} from "../../../redux/slice/InputOne/StudyTourExposure/StudyTourExposureSlice"; // Import your action creator

export function StudyTourMagnet(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const studyTourExpo = useSelector((state) => state.studyTourExpo);
  const [states, setState] = useState([]);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    const valueChain = async () => {
      try {

        const state = await axios.get(
          `${apiUrl}api/get-all-states?all=1`,
          getConfigWithToken()
        );
        const Country = await axios.get(
          `${apiUrl}api/country-listing?all=1`,
          getConfigWithToken()
        );
        const CountryData = Country.data["data"].map((e) => ({
          id: e["id"],
          country_name: e["country"],
        }));
        setCountry(CountryData); 
        setState(state.data["data"]);
      } catch (error) {}
    };

    valueChain();
  }, []);

  const totalOBCMinorityOpen =
    parseInt(
      studyTourExpo.participants_male === ""
        ? 0
        : studyTourExpo.participants_male
    ) +
    parseInt(
      studyTourExpo.participants_female === ""
        ? 0
        : studyTourExpo.participants_female
    );
  dispatch(setTotalParticipants(totalOBCMinorityOpen));

  

  const handleCountryIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "country_id", value: event.target.value })
    );
  };

  const handleStateIdChange = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "state_id", value: event.target.value })
    );
  };

  const handleFromDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "from_date", value }));
  };

  const handleToDateChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "to_date", value }));
  };

  const handleLocationAddressChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handlePurposeChange = (value) => {
    dispatch(setInputFieldData({ fieldName: "purpose", value }));
  };


  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}

      <div class="row g-3">
        <DatePick
          label="From Date"
          className="custom-date-picker"
          disbrushDate={studyTourExpo.from_date}
          onChange={handleFromDateChange}
          errorText={!!studyTourExpo.validationErrors.from_date}
        />
        <DatePick
          label="To Date"
          className="custom-date-picker"
          disbrushDate={studyTourExpo.to_date}
          onChange={handleToDateChange}
          errorText={!!studyTourExpo.validationErrors.to_date}
        />
        <InputField
          label="Location"
          type="text"
          value={studyTourExpo.location_address}
          onChange={handleLocationAddressChange}
          onFocus={handleResetFocus}
          error={!!studyTourExpo.validationErrors.location_address}
          helperText={studyTourExpo.validationErrors.location_address}
        />

        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            {props.compValue === 17 ? (
              <>
                <FormControl
                  fullWidth
                  error={
                    studyTourExpo.validationErrors.country_id !== ""
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studyTourExpo.country_id}
                    onChange={handleCountryIdChange}
                    label="country"
                  >
                    {country.map((e) => (
                      <MenuItem value={e.id}>{e.country_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {studyTourExpo.validationErrors.country_id}
                </FormHelperText>
              </>
            ) : (
              <>
                <FormControl
                  fullWidth
                  error={
                    studyTourExpo.validationErrors.state_id !== ""
                      ? true
                      : false
                  }
                >
                  <InputLabel id="demo-simple-select-label">States</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studyTourExpo.state_id}
                    onChange={handleStateIdChange}
                    label="states"
                  >
                    {states.map((e) => (
                      <MenuItem value={e.id}>{e.state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {studyTourExpo.validationErrors.state_id}
                </FormHelperText>
              </>
            )}
          </Box>
        </div>
        <InputField
          label="Purpose/Description Visit"
          type="text"
          value={studyTourExpo.purpose}
          onChange={handlePurposeChange}
          onFocus={handleResetFocus}
          error={!!studyTourExpo.validationErrors.purpose}
          helperText={studyTourExpo.validationErrors.purpose}
        />
        <div></div>
        <div className="col-md-3 input1Heading">
          <span>Participants</span>
        </div>
        <InputField
          col="3"
          label="Total Male"
          type="number"
          value={studyTourExpo.participants_male}
          onChange={(value)=>handleInputChange("participants_male",value)}
          onFocus={handleResetFocus}
          error={!!studyTourExpo.validationErrors.participants_male}
          helperText={studyTourExpo.validationErrors.participants_male}
        />
        <InputField
          col="3"
          label="Total Female"
          type="number"
          value={studyTourExpo.participants_female}
          onChange={(value)=>handleInputChange("participants_female",value)}
          onFocus={handleResetFocus}
          error={!!studyTourExpo.validationErrors.participants_female}
          helperText={studyTourExpo.validationErrors.participants_female}
        />
        <InputField
          col="3"
          label="Total Participants"
          type="number"
          value={studyTourExpo.participants_total}
          readOnly={true}
        />
      </div>
    </>
  );
}
