import React, { useEffect, useState } from "react";
import { InputField } from "../../InputField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DatePick } from "../../DatePick";
import "../../../assets/css/inputForm.css";
import {
  setInputFieldData,
  resetValidation,
  setTotalSC,
  setTotalST,
  setTotalPWD,
  setTotalBPL,
  setTotalOBC,
  setTotalParticipants,
  setTotalMaleParticipants,
  setTotalFemaleParticipants,
} from "../../../redux/slice/InputOne/SMELinkage/smeLinkageSlice";
import { Box, FormHelperText } from "@mui/material";
import { MultiSelect } from "../../MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getConfigWithToken } from "../../../utils/Config/Config";

export function SMELinkage(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const smeLinkage = useSelector((state) => state.smeLinkage);
  const [taluka, setTaluka] = useState([]);

  useEffect(() => {
    const workData = async () => {
      try {
        const taluka = await axios.get(
          `${apiUrl}api/get-all-taluka-with-village?all=1`,
          getConfigWithToken()
        );
        setTaluka(taluka.data["data"]);
      } catch (error) {}
    };

    workData();
  }, []);

  // Total of SC
  const totalSC =
    parseInt(smeLinkage.sc_male === "" ? 0 : smeLinkage.sc_male) +
    parseInt(smeLinkage.sc_female === "" ? 0 : smeLinkage.sc_female);
  dispatch(setTotalSC(totalSC));
  // Total of ST
  const totalST =
    parseInt(smeLinkage.st_male === "" ? 0 : smeLinkage.st_male) +
    parseInt(smeLinkage.st_female === "" ? 0 : smeLinkage.st_female);
  dispatch(setTotalST(totalST));
  // Total of PWD
  const totalPWD =
    parseInt(smeLinkage.pwd_male === "" ? 0 : smeLinkage.pwd_male) +
    parseInt(smeLinkage.pwd_female === "" ? 0 : smeLinkage.pwd_female);
  dispatch(setTotalPWD(totalPWD));
 
  // Total of OBC/Minority/Open
  const totalOBCMinorityOpen =
    parseInt(
      smeLinkage.obc_minority_open_male === ""
        ? 0
        : smeLinkage.obc_minority_open_male
    ) +
    parseInt(
      smeLinkage.obc_minority_open_female === ""
        ? 0
        : smeLinkage.obc_minority_open_female
    );
  dispatch(setTotalOBC(totalOBCMinorityOpen));

  const totalMaleParticipants =
    parseInt(smeLinkage.sc_male === "" ? 0 : smeLinkage.sc_male) +
    parseInt(smeLinkage.st_male === "" ? 0 : smeLinkage.st_male) +
    +parseInt(
      smeLinkage.obc_minority_open_male === ""
        ? 0
        : smeLinkage.obc_minority_open_male
    );
  dispatch(setTotalMaleParticipants(totalMaleParticipants));

  const totalFemaleParticipants =
    parseInt(smeLinkage.sc_female === "" ? 0 : smeLinkage.sc_female) +
    parseInt(smeLinkage.st_female === "" ? 0 : smeLinkage.st_female) +
    +parseInt(
      smeLinkage.obc_minority_open_female === ""
        ? 0
        : smeLinkage.obc_minority_open_female
    );
  dispatch(setTotalFemaleParticipants(totalFemaleParticipants));

  const totalParticipants =
    parseInt(smeLinkage.sc_total === "" ? 0 : smeLinkage.sc_total) +
    parseInt(smeLinkage.st_total === "" ? 0 : smeLinkage.st_total) +
    +parseInt(
      smeLinkage.obc_minority_open_total === ""
        ? 0
        : smeLinkage.obc_minority_open_total
    );
  dispatch(setTotalParticipants(totalParticipants));

  // Dispatch functions for SC fields
 
  // Dispatch functions for OBC/Minority/Open fields
 

  const handleFieldResourcePerson = (value) => {
    dispatch(setInputFieldData({ fieldName: "resource_person", value }));
  };

  const handleFieldTopicCovered = (value) => {
    dispatch(setInputFieldData({ fieldName: "topic_covered", value }));
  };

  const handleFieldLocationAddress = (value) => {
    dispatch(setInputFieldData({ fieldName: "location_address", value }));
  };

  const handleOptionstaluka = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "location_taluka",
        value: event.target.value,
      })
    );
  };

  const handleDateChange = (data) => {
    dispatch(setInputFieldData({ fieldName: "date", value: data }));
  };

  const handleResetFocus = () => {
    dispatch(resetValidation());
  };

  const handleInputChange = (fieldName, value) => {
    if (value < 0) {
      value = 0;
    }
    dispatch(setInputFieldData({ fieldName, value }));
  };

  return (
    <>
      {/* <div className="componentSub"> */}

      <div class="row g-3">
        <DatePick
          label="Date"
          className="custom-date-picker"
          disbrushDate={smeLinkage.date}
          onChange={handleDateChange}
          errorText={!!smeLinkage.validationErrors.date}
        />
        <div className="col-md-4" style={{ paddingRight: 0 }}>
          <Box sx={{ minWidth: "100%" }}>
            <FormControl
              fullWidth
              error={
                smeLinkage.validationErrors.location_taluka !== ""
                  ? true
                  : false
              }
            >
              <InputLabel htmlFor="demo-simple-select-label">
                Location (Taluka)
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={smeLinkage.location_taluka}
                label="Location (Taluka)"
                onChange={handleOptionstaluka}
              >
                {taluka.map((e, key) => (
                  <MenuItem value={e.id} key={key}>
                    {e.taluka}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: "#d32f2f" }}>
              {smeLinkage.validationErrors.location_taluka}
            </FormHelperText>
          </Box>
        </div>
        <InputField
          label="Location(Address)"
          type="text"
          value={smeLinkage.location_address}
          onChange={handleFieldLocationAddress}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.location_address}
          helperText={smeLinkage.validationErrors.location_address}
        />
        <InputField
          label="Resource Person Name"
          type="text"
          value={smeLinkage.resource_person}
          onChange={handleFieldResourcePerson}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.resource_person}
          helperText={smeLinkage.validationErrors.resource_person}
        />
        <InputField
          label="Topic Covered"
          type="text"
          value={smeLinkage.topic_covered}
          onChange={handleFieldTopicCovered}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.topic_covered}
          helperText={smeLinkage.validationErrors.topic_covered}
        />

        <div className="word-with-line">
          <span>Vulnerable Groups & Vulnerability</span>
          <i class="fa-solid fa-angle-down" style={{ color: "#4e2683" }}></i>
        </div>

        <div className="col-md-3 input1Heading">
          <span>SC</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={smeLinkage.sc_male}
          onChange={(value)=>handleInputChange("sc_male",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.sc_male}
          helperText={smeLinkage.validationErrors.sc_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={smeLinkage.sc_female}
          onChange={(value)=>handleInputChange("sc_female",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.sc_female}
          helperText={smeLinkage.validationErrors.sc_female}
        />
        <InputField
          col="3"
          label="Total SC"
          type="number"
          value={smeLinkage.sc_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>ST</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={smeLinkage.st_male}
          onChange={(value)=>handleInputChange("st_male",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.st_male}
          helperText={smeLinkage.validationErrors.st_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={smeLinkage.st_female}
          onChange={(value)=>handleInputChange("st_female",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.st_female}
          helperText={smeLinkage.validationErrors.st_female}
        />
        <InputField
          col="3"
          label="Total ST"
          type="number"
          value={smeLinkage.st_total}
          readOnly={true}
        />

        <div className="col-md-3 input1Heading">
          <span>General (Open, OBC and others)</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={smeLinkage.obc_minority_open_male}
          onChange={(value)=>handleInputChange("obc_minority_open_male",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.obc_minority_open_male}
          helperText={smeLinkage.validationErrors.obc_minority_open_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={smeLinkage.obc_minority_open_female}
          onChange={(value)=>handleInputChange("obc_minority_open_female",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.obc_minority_open_female}
          helperText={smeLinkage.validationErrors.obc_minority_open_female}
        />
        <InputField
          col="3"
          label="Total OBC/ Minority/ Open"
          type="number"
          value={smeLinkage.obc_minority_open_total}
          readOnly={true}
        />
        <div className="col-md-3 input1Heading">
          <span>Beneficiaries</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={smeLinkage.beneficiaries_male}
          onChange={(value)=>handleInputChange("beneficiaries_male",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.beneficiaries_male}
          helperText={smeLinkage.validationErrors.beneficiaries_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={smeLinkage.beneficiaries_female}
          onChange={(value)=>handleInputChange("beneficiaries_female",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.beneficiaries_female}
          helperText={smeLinkage.validationErrors.beneficiaries_female}
        />
        <InputField
          col="3"
          label="Total Trainers"
          type="number"
          value={smeLinkage.beneficiaries_total}
          readOnly={true}
        />
      </div>

      <div style={{ marginTop: "3rem" }} class="row g-3">
        <div className="col-md-3 input1Heading">
          <span>PWD</span>
        </div>
        <InputField
          col="3"
          label="Male"
          type="number"
          value={smeLinkage.pwd_male}
          onChange={(value)=>handleInputChange("pwd_male",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.pwd_male}
          helperText={smeLinkage.validationErrors.pwd_male}
        />
        <InputField
          col="3"
          label="Female"
          type="number"
          value={smeLinkage.pwd_female}
          onChange={(value)=>handleInputChange("pwd_female",value)}
          onFocus={handleResetFocus}
          error={!!smeLinkage.validationErrors.pwd_female}
          helperText={smeLinkage.validationErrors.pwd_female}
        />
        <InputField
          col="3"
          label="Total PWD"
          type="number"
          value={smeLinkage.pwd_total}
          readOnly={true}
        />
      </div>
    </>
  );
}
