import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import '../assets/css/components.css'

export function InputField(props) {
  const handleInputChange = (event) => {
    // Pass the event to the consumer's onChange handler
    props.onChange(event.target.value);
  };

  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  return (
    <div className={`col-md-${props.col || 4}`}>
      <div className="form-floating">
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          {props.endAr ? (
            <TextField
              id="outlined-basic"
              required
              name={props.label}
              label={props.label}
              variant="outlined"
              type="number"
              value={props.value}
              onChange={handleInputChange}
              onFocus={handleFocus}
              error={props.error}
              helperText={props.helperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">{props.endAr}</InputAdornment>
                
                ),
               
                readOnly: props.readOnly?true:false
              }}
              style={{color:"#000"}}
            />
          ) : (
            <TextField
              id="outlined-basic"
              required
              InputProps={{
                readOnly: props.readOnly?true:false, // This makes the input not editable
              }}
              name={props.label}
              label={props.label}
              variant="outlined"
              type={props.type}
              value={props.value}
              error={props.error}
              helperText={props.helperText}
              onChange={handleInputChange}
              onFocus={handleFocus}
              className={props.disable ? 'black-textfield' : ''}
              // style={{color:"#000"}}
            />
          )}
        </Box>
      </div>
    </div>
  );
}
