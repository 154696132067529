// import logo from './logo.svg';
// import './App.css';
// import AppRouter from './routes';

// function App() {
//   return (
//     <>
//       <AppRouter/>
//     </>
//   );
// }

// export default App;

import logo from './logo.svg';
import './App.css';
import AppRouter from './routes';
import { useEffect, useState } from 'react';

function App() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        if (!isOnline) {
          setIsOnline(true);
        }
      };

      const handleOfflineStatusChange = () => {
        if (isOnline) {
          setIsOnline(false);
        }
      };

      window.addEventListener('online', handleOnlineStatusChange);
      window.addEventListener('offline', handleOfflineStatusChange);

      return () => {
        window.removeEventListener('online', handleOnlineStatusChange);
        window.removeEventListener('offline', handleOfflineStatusChange);
      };
    }, [isOnline]); // Add isOnline as a dependency to useEffect


  return (
      <>
        {/* {isOnline ? <AppRouter/> : <p>You are offline</p>} */}
        <AppRouter/>
      </>
    );
};

export default App;
