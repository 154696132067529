import React from "react";
import { Offline, Online } from "react-detect-offline";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "../../../../assets/css/masterdata.css";
import {
  resetValidation,
  setInputFieldData,
} from "../../../../redux/slice/MGBeneficiaryMaster/MGBeneficiaryMasterSlice";
import { useDispatch, useSelector } from "react-redux";
import { GrandDetailsListingTable } from "./GrandDetails/GrandDetailsTable";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getConfigWithToken } from "../../../../utils/Config/Config";

export const BeneficiaryGrandDetailsMaster = (props) => {
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  const mgBeneficiaryMaster = useSelector((state) => state.mgBeneficiaryMaster);

  console.log(props.Beneficiary_Status,'status process')

  // const Beneficiary_Status = [
  //   {
  //     id: 1,
  //     Status_mode: "Primary",
  //   },
  //   {
  //     id: 2,
  //     Status_mode: "Secondary",
  //   },
  //   {
  //     id: 3,
  //     Status_mode: "Tertiary",
  //   },
  // ];

  // const [activity, setActivity] = useState([])
  // const [inputActivity, setInputActivity] = useState([]);

  // const handleActivityValue = (
  //   component_id,
  //   component_name,
  //   activity_id,
  //   activity_name,
  //   value
  // ) => {
  //   const updatedInputActivity = [...inputActivity];
  //   const existingComponentIndex = updatedInputActivity.findIndex(
  //     (item) => item.component_id === component_id
  //   );

  //   if (existingComponentIndex !== -1) {
  //     // Component exists, update the activity or add a new one
  //     const existingComponent = updatedInputActivity[existingComponentIndex];
  //     const existingActivityIndex = existingComponent.activities.findIndex(
  //       (act) => act.activity_id === activity_id
  //     );

  //     if (existingActivityIndex !== -1) {
  //       // Activity exists, update the value
  //       existingComponent.activities[existingActivityIndex].value = value;
  //     } else {
  //       // Activity does not exist, add a new one
  //       existingComponent.activities.push({ activity_id, value });
  //     }
  //   } else {
  //     // Component does not exist, add a new one
  //     updatedInputActivity.push({
  //       component_id,
  //       component_name,
  //       activities: [{ activity_id, value }],
  //     });
  //   }

  //   setInputActivity(updatedInputActivity);
  //   props.onInputActivityChange(updatedInputActivity);
  // };

  const handleFieldBankName = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "lending_bank_name",
        value: event.target.value,
      })
    );
  };
  const handleFieldLoanType = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "loan_type_id",
        value: event.target.value,
      })
    );
  };
  const handleFieldFinancialYear = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "financial_year_approval_date",
        value: event.target.value,
      })
    );
  };

  const handleFieldProcessing = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "processing", value: event.target.value })
    );
  };
  const handleFielDueDate1 = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "total_project_cost",
        value: event.target.value,
      })
    );
  };

  const handleFieldDueDate2 = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "due_date_2", value: event.target.value })
    );
  };
  const handleFieldDueDate3 = (event) => {
    dispatch(
      setInputFieldData({ fieldName: "due_date_3", value: event.target.value })
    );
  };

  const handleFieldActualDate1 = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "actual_date_1",
        value: event.target.value,
      })
    );
  };

  const handleFieldActualDate2 = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "actual_date_2",
        value: event.target.value,
      })
    );
  };
  const handleFieldActualDate3 = (event) => {
    dispatch(
      setInputFieldData({
        fieldName: "actual_date_3",
        value: event.target.value,
      })
    );
  };
  const handleResetFocus = () => {
    dispatch(resetValidation());
  };
  
  return (
    <>
      {/* <Online> */}
        <div className="tab-pane">
          <form class="row g-3">
            {/* Lending Bank Name */}
            <div className="primaryTableLabel">
              <label htmlFor="lending_bank_name">Lending Bank Name</label>
              <input
                type="text"
                name="lending_bank_name"
                value={mgBeneficiaryMaster.lending_bank_name}
                onChange={handleFieldBankName}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* Loan Type */}
            <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Loan Type">Loan Type</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Loan Type"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mgBeneficiaryMaster.loan_type_id}
                    onChange={handleFieldLoanType}
                    onFocus={handleResetFocus}
                  >
                    {props.LoanType.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.loan_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* processing */}
            {/* <div className="primaryTableLabel">
              <label htmlFor="processing">Processing</label>
              <input
                type="number"
                name="processing"
                value={mgBeneficiaryMaster.processing}
                onChange={handleFieldProcessing}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div> */}

 <div className="primaryTableLabel">
              <Box>
                <label htmlFor="Status">Processing</label>
                <FormControl style={{ width: "30%" }}>
                  <InputLabel htmlFor="demo-simple-select-label"></InputLabel>
                  <Select
                    name="Status"
                    className="customSelect"
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={mgBeneficiaryMaster.processing}
                    value={
                      Array.isArray(mgBeneficiaryMaster.processing)
                        ? mgBeneficiaryMaster.processing
                        : [mgBeneficiaryMaster.processing]
                    }
                    onChange={handleFieldProcessing}
                    onFocus={handleResetFocus}
                    multiple
                  >
                    {props.Beneficiary_Status.map((e) => (
                      <MenuItem value={e.id}>{e.Status_mode}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Date of Sub-Project Sanction */}
            <div className="primaryTableLabel">
              <label htmlFor="FinYear">Date of Sub-Project Sanction</label>
              <input
                type="date"
                name="FinYear"
                value={mgBeneficiaryMaster.financial_year_approval_date}
                onChange={handleFieldFinancialYear}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

  {/* Grant agreement signing date */}
  {/* <div className="primaryTableLabel">
              <label htmlFor="FinYear">Grant agreement signing date</label>
              <input
                type="date"
                name="FinYear"
                value={mgBeneficiaryMaster.financial_year_approval_date}
                onChange={handleFieldFinancialYear}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div> */}

              {/* Expected date of sub project completion */}
              {/* <div className="primaryTableLabel">
              <label htmlFor="FinYear">Expected date of sub project completion</label>
              <input
                type="date"
                name="FinYear"
                value={mgBeneficiaryMaster.financial_year_approval_date}
                onChange={handleFieldFinancialYear}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div> */}
            
            <div className="primaryTableLabel">
              <label htmlFor="due_date_2">Grant Disbursment Date T1</label>
              <input
                type="date"
                name="due_date_2"
                value={mgBeneficiaryMaster.actual_date_1}
                onChange={handleFieldActualDate1}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* due_date_2 */}
            <div className="primaryTableLabel">
              <label htmlFor="due_date_2">Due Date T2</label>
              <input
                type="date"
                name="due_date_2"
                value={mgBeneficiaryMaster.due_date_2}
                onChange={handleFieldDueDate2}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>
            {/* Grand Disbursment Date T2 */}
            <div className="primaryTableLabel">
              <label htmlFor="actual_date_2">Grant Disbursment Date T2</label>
              <input
                type="date"
                name="actual_date_2"
                value={mgBeneficiaryMaster.actual_date_2}
                onChange={handleFieldActualDate2}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* due_date_3 */}
            <div className="primaryTableLabel">
              <label htmlFor="due_date_3">Due Date T3</label>
              <input
                type="date"
                name="due_date_3"
                value={mgBeneficiaryMaster.due_date_3}
                onChange={handleFieldDueDate3}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>

            {/* Grand Disbursment Date T3 */}
            <div className="primaryTableLabel">
              <label htmlFor="actual_date_3">Grant Disbursment Date T3</label>
              <input
                type="date"
                name="actual_date_3"
                value={mgBeneficiaryMaster.actual_date_3}
                onChange={handleFieldActualDate3}
                onFocus={handleResetFocus}
                className="custom"
                style={{ width: "30%" }}
              />
            </div>
            <br />
     
            {/* <GrandDetailsListingTable/> */}
           
            <Box
              sx={{
                width: "100%",
                marginLeft: "-1rem",
                height: "maxContent",
                typography: "body1",
                border: "3px solid #E8E8E8",
                backgroundColor: "#FFFFFF",
              }}
            > 
             <div style={{marginBottom:'-20px',marginLeft:'3px',fontSize:'13px'}}>
               <p>All Values in Lakhs</p>
            </div>
              <GrandDetailsListingTable/>
            
              {/* <table
                style={{
                  border: "1px solid white",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <thead>
                  <tr
                    style={{
                      textAlign: "left",
                      color: "white",
                      backgroundColor: "#4e2683",
                    }}
                  >
                    <th style={{ textAlign: "center", width: "35%" }}>
                      Components
                    </th>
                    <th style={{ textAlign: "center" }}>Total Project Cost</th>
                    <th style={{ textAlign: "center" }}>Promoters Equity</th>
                    <th style={{ textAlign: "center" }}>Bank Term Loan</th>
                    <th style={{ textAlign: "center" }}>Matching Grant</th>
                    <th style={{ textAlign: "center" }}>
                      Matching Grant Tranche 1
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Matching Grant Tranche 2
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Matching Grant Tranche 3
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Matching Grant Tranche 4
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.activity.map((item) => (
                    <tr key={item.id}>
                      <td style={{ border: "1px solid black" }}>
                        {item.component}
                      </td>
                      {item.activity.map((key) => (
                        <td key={key.id}>
                          <input
                            style={{
                              height: "25px",
                              width: "100%",
                              borderRadius: "7px",
                              border: "1px solid #E2E2E2",
                              backgroundColor: "#FFFFFF",
                            }}
                            type="number"
                            onChange={(event) =>
                              handleActivityValue(
                                item.id,
                                item.component,
                                key.id,
                                key.activity,
                                event.target.value
                              )
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table> */}
              
            </Box>
          </form>
        </div>
      {/* </Online>
      <Offline>You're Offline, Please Check your Connection</Offline> */}
    </>
  );
};
