import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    magnet_crop_id: [],
    date: null,
    files: [],
    location_taluka: '',
    resource_person: '',
    topic_covered: '',
    area: '',
    no_of_farmers: '',
    name_of_certification_agency_lab: '',
    total_cost: '',
    total_saction_grant: '',
    grant_disbursement_amt: '',
    grant_disbursement_date: '',
  ///////////

  sc_male: '',
  sc_female: '',
  sc_total: '',

  st_male: '',
  st_female: '',
  st_total: '',

  pwd_male: '',
  pwd_female: '',
  pwd_total: '',

  obc_minority_open_male: '',
  obc_minority_open_female: '',
  obc_minority_open_total: '',

  beneficiaries_male: '',
  beneficiaries_female: '',
  beneficiaries_total: '',
  action_points: '',


  validationErrors:{
    sc_male: '',
    sc_female: '',

    st_male: '',
    st_female: '',

    pwd_male: '',
    pwd_female: '',

    obc_minority_open_male: '',
    obc_minority_open_female: '',

    magnet_crop_id: [],
    date: null,
    location_taluka: '',
    resource_person: '',
    topic_covered: '',
    action_points: '',
  }
};

const capacityBuildingSlice = createSlice({
  name: 'capacityBuildingSlice',
  initialState,
  reducers: {
    setInputFieldData: (state, action) => {
      // Use the action payload to update the corresponding input field
      state[action.payload.fieldName] = action.payload.value;
      // state.action_points = action.payload.value; 
    },
    addSelectedFiles: (state, action) => {
      const selectedFiles = action.payload.value;
      state.files.push(...selectedFiles);
    },
    setTotalSC: (state, action) => {
        state.sc_total = action.payload;
      },
    setTotalST: (state, action) => {
        state.st_total = action.payload;
      },
    setTotalPWD: (state, action) => {
        state.pwd_total = action.payload;
      },
   
    setTotalOBC: (state, action) => {
        state.obc_minority_open_total= action.payload;
      },
    setTotalParticipants: (state, action) => {
        state.beneficiaries_total= action.payload;
      },
    
      setTotalFemaleParticipants: (state, action) => {
        state.beneficiaries_female= action.payload;
      },
    
    setTotalMaleParticipants: (state, action) => {
        state.beneficiaries_male= action.payload;
      },
    // Add other reducers for different input fields if needed
    updateCapacityBuildingForm: (state, action) =>{
      const payload = action.payload;
      // Loop through the keys in initialState and update the corresponding fields
      for (const key in initialState) {
        if (payload[key] !== undefined) {
          state[key] = payload[key];
        }
      }
      // state.expenditureIncurred = action.payload.amount_disbursement;
    },

    resetDataCapacityBuilding: (state) => {
      // Reset all fields to their initial values
      return { ...initialState };
    },
    setValidationErrorsCapacityBuilding: (state, action) => {
      state.validationErrors = { ...state.validationErrors, ...action.payload };
    },
    resetValidation: (state, action) =>{
      state.validationErrors = { ...initialState.validationErrors };
    },
    setDate: (state, action) => {
      state.date = action.payload;
    }
  },
});

export const {addSelectedFiles, setValidationErrorsCapacityBuilding, resetValidation, setInputFieldData, updateCapacityBuildingForm, resetDataCapacityBuilding, setTotalOBC, setTotalBPL, setTotalPWD, setTotalST, setTotalSC, setTotalParticipants, setTotalFemaleParticipants, setTotalMaleParticipants ,setDate} = capacityBuildingSlice.actions;

export default capacityBuildingSlice.reducer;
